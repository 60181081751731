import React from "react";

import { useQuery } from "react-query";
import moment from "moment";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import SyncLoader from "react-spinners/SyncLoader";
import { getStaticAlotment } from "../../pages/college/ApiServices";

const StaticAllotment = ({ ids }) => {
  const { id, year, date } = useParams();

  const { data = {}, status } = useQuery(["getAlegeOnly23456", id], getStaticAlotment(id));

  const override = css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `;

  const Loader = () => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <SyncLoader color="#002147" css={override} size={15} />
    </div>
  );

  return (
    <div>
      {status === "loading" ? (
        <Loader />
      ) : (
        <div style={{ width: "99%", margin: "0 auto", borderRadius: "25px", padding: "1%" }}>
          <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px" }}>
              <img src={data?.collegeName?.[0]?.logo} alt="College Logo" style={{ width: "8%", height: "8%" }} />
              <div style={{ flex: 1, textAlign: "center" }}>
                <h2 style={{ fontSize: "30px", fontWeight: 900, color: "#002147" }}>{data?.collegeName?.[0]?.collegeName}</h2>
                <div className="font-size: 20px; color: #002147; text-align: center;">
                  <h3>Index Marks Report</h3>
                  <h3>Academic Year: {year}</h3>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
              <div style={{ flex: 1, borderRadius: "12%", margin: 5, backgroundColor: "#FF5C4D", textAlign: "center", padding: "10px" }}>
                <h4 style={{ color: "white", fontSize: "14px", fontWeight: "600" }}>Total Number of Seats</h4>
              </div>
              <div style={{ flex: 1, borderRadius: "12%", margin: 5, backgroundColor: "#FF9445", textAlign: "center", padding: "10px" }}>
                <h4 style={{ color: "white", fontSize: "14px", fontWeight: "600" }}>Total Number of Students Admitted</h4>
              </div>
              <div style={{ flex: 1, borderRadius: "12%", margin: 5, backgroundColor: "#263859", textAlign: "center", padding: "10px" }}>
                <h4 style={{ color: "white", fontSize: "14px", fontWeight: "600" }}>Total Remaining Available Seats</h4>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
              <div style={{ flex: 1, borderRadius: "12%", margin: 5, backgroundColor: "#FF5C4D", textAlign: "center", padding: "10px" }}>
                <h2 style={{ color: "white", fontSize: "30px", fontWeight: "700" }}>{data?.totals?.inTake || "--"}</h2>
              </div>
              <div style={{ flex: 1, borderRadius: "12%", margin: 5, backgroundColor: "#FF9445", textAlign: "center", padding: "10px" }}>
                <h2 style={{ color: "white", fontSize: "30px", fontWeight: "700" }}>{data?.totals?.inAdmitted || "--"}</h2>
              </div>
              <div style={{ flex: 1, borderRadius: "12%", margin: 5, backgroundColor: "#263859", textAlign: "center", padding: "10px" }}>
                <h2 style={{ color: "white", fontSize: "30px", fontWeight: "700" }}>{(data?.totals?.inTake || 0) - (data?.totals?.inAdmitted || 0)}</h2>
              </div>
            </div>
          </div>
          <h5 style={{ fontSize: "15px", color: "#002147", textAlign: "center", marginBottom: "20px" }}>Last Updated: {moment(new Date()).format("DD MMM YYYY hh:mm A")}</h5>
          <div>
            {data?.admissionDetails?.map((item, i) => (
              <div key={i} style={{ backgroundColor: "#C7D9EB", borderRadius: "12px", marginBottom: "20px", padding: "10px" }}>
                <h2 style={{ color: "#002147", textAlign: "center", fontSize: "40px", textAlign: "center", marginBottom: "10px" }}>{item?.className}</h2>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gridGap: "20px", borderRadius: "8px", padding: "20px" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontSize: "20px", color: "#004E98", textAlign: "center", marginBottom: "5px" }}>Total Seats</div>
                    <div style={{ fontSize: "30px", color: "#004E98", textAlign: "center", fontWeight: "700" }}>{item?.inTake || "--"}</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontSize: "20px", color: "#00833D", textAlign: "center", marginBottom: "5px" }}>Admitted</div>
                    <div style={{ fontSize: "30px", color: "#00833D", textAlign: "center", fontWeight: "700" }}>{item?.inAdmitted || "--"}</div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ fontSize: "20px", color: "#A13C63", textAlign: "center", marginBottom: "5px" }}>Available Seats</div>
                    <div style={{ fontSize: "30px", color: "#A13C63", textAlign: "center", fontWeight: "700" }}>{(item?.inTake || 0) - (item?.inAdmitted || 0)}</div>
                  </div>
                </div>
                <h3 style={{ fontSize: "24px", color: "#002147", textAlign: "center", marginBottom: "10px" }}>Showing Available Seats</h3>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(130px, 1fr))", gridGap: "5px", borderRadius: "8px", backgroundColor: "#F1F5F9", padding: "5px" }}>
                  {data?.keys?.map((keyitem, i) =>
                    item[keyitem]?.inTake ? (
                      <div key={i} style={{ borderRadius: "8px", backgroundColor: "#EDF2F7", padding: "20px" }}>
                        <div style={{ fontSize: "16px", fontWeight: "600", color: "#002147", marginBottom: "10px" }}>{keyitem}</div>
                        <div style={{ fontSize: "24px", fontWeight: "700", color: "#004E98", marginBottom: "5px" }}>{item[keyitem]?.inTake || 0}</div>
                        <div style={{ fontSize: "16px", fontWeight: "600", color: "#00833D", marginBottom: "5px" }}>Admitted: {item[keyitem]?.inAdmitted || 0}</div>
                        <div style={{ fontSize: "15px", fontWeight: "600", color: "#A13C63" }}>Remaining: {parseInt(item[keyitem]?.inTake) - (parseInt(item[keyitem]?.inAdmitted) || 0)}</div>
                        <div style={{ fontSize: "14px", fontWeight: "600", color: "#9CA3AF", marginTop: "10px" }}>Last Index: {item[keyitem]?.inLastIndex || "--"}</div>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default StaticAllotment;
