import { BrowserRouter } from "react-router-dom";
import FullScreenContextProvider from "./context/FullScreenContext";
import FirebaseContextProvider from "./context/FirebaseContext";
import Routes from "./Routes";
import "./static/scss/master.scss";
import { QueryClient, QueryClientProvider } from "react-query";

function NectosDash() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <FirebaseContextProvider>
        <FullScreenContextProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </FullScreenContextProvider>
      </FirebaseContextProvider>
    </QueryClientProvider>
  );
}

export default NectosDash;
