import React, { useEffect, useRef, useContext, useState } from "react";
import classNames from "./studentTable.module.scss";
import { Input, Table } from "antd";
import { useQuery } from "react-query";
import moment from "moment";
import { getCustomeCollectionWithCustomeKeyDrops, getHerberium } from '../../college/ApiServices';
import LoadingAnimation from "../../../components/LoadingAnimation";
import CustomDropDown from "../../../myComp/CustomDropDown";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import applicationForm from '../../../static/images/victoriaGVC.png'
import Button from '@mui/material/Button';

function AdmissionRegister({ refreshStatus }) {
  const { collegeId } = useParams();




  const [state, setState] = useState({
    page: 1,
    viewMode: false,
    selectedStudent: null,
    viewEnlarge: false,
    themeMode: 'light',
    typeMode: 'simple'
    // ... other state values
  });
  const [studentList, setStudentList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const { data, isLoading } = useQuery(['getHerberium', state.familyFilter, state.generaFilter, state.sortBy, state.sortType, state.typeStatusFilter, state.authorFilter, state.page, state.collectedFilter, searchTerm], getHerberium(collegeId, state, searchTerm));
  const { data: dropsAuthor } = useQuery(['getCustomeCollectionWithCustomeKeyDropsAuthor'], getCustomeCollectionWithCustomeKeyDrops(collegeId, 'auther', 'herbarium'));
  const { data: dropsFamily } = useQuery(['getCustomeCollectionWithCustomeKeyDropsFamily'], getCustomeCollectionWithCustomeKeyDrops(collegeId, 'family', 'herbarium'));
  const { data: dropsGenera } = useQuery(['getCustomeCollectionWithCustomeKeyDropsGenera'], getCustomeCollectionWithCustomeKeyDrops(collegeId, 'genara', 'herbarium'));
  const { data: dropsCollected } = useQuery(['getCustomeCollectionWithCustomeKeyDropsCollected'], getCustomeCollectionWithCustomeKeyDrops(collegeId, 'collectedBy', 'herbarium'));
  const { data: dropsTypeStatus } = useQuery(['getCustomeCollectionWithCustomeKeyDropsTypeStatus'], getCustomeCollectionWithCustomeKeyDrops(collegeId, 'typeStatus', 'herbarium'));

  useEffect(() => {
    if (data) {
      setStudentList(data.list || []);
    }
  }, [data]);
  const [selectedCard, setSelectedCard] = useState(null);


  const [inputStyle, setInputStyle] = useState({});


  const darkModeStyles = {
    backgroundColor: '#333',
    color: '#fff'
  };



  const toggleTheme = () => {
    setState({
      ...state,
      themeMode: state.themeMode === 'light' ? 'dark' : 'light'
    });
  };
  const toggleMaster = () => {
    setState({
      ...state,
      typeMode: state.typeMode === 'simple' ? 'master' : 'simple'
    });
  };

  useEffect(() => {
    const updateStyle = () => {
      if (window.innerWidth <= 768) { // Mobile devices
        setInputStyle({
          width: '100%',
          height: '50px',
          borderRadius: '25px',
          borderColor: state.themeMode === 'light' ? '#DADCE0' : '#7F8C8D',
          padding: '0 20px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        });
      } else { // Desktop
        setInputStyle({
          width: '800px',
          height: '50px',
          borderRadius: '25px',
          borderColor: state.themeMode === 'light' ? '#DADCE0' : '#7F8C8D',
          padding: '0 20px',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        });
      }
    };

    updateStyle();
    window.addEventListener('resize', updateStyle);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', updateStyle);
    };
  }, [state.themeMode]);


  useEffect(() => {
    setSelectedCard({})
    // setState({ ...state, count: '' });


  }, [searchTerm]);

  const viewButtonStyle = {
    cursor: "pointer",
    width: '100px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#2C3E50',
    color: '#ECF0F1',
    fontSize: '16px',
    fontWeight: '600',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s ease'
  };

  let tableColumns = [
    {
      title: "Sl. No.",
      render: (_, item, i) => (i + 1 + ((state.page * 20) - 20)),
      width: '7%',
    },
    {
      title: "Binomial",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
      width: '20%',
    },

    {
      title: "Author",
      dataIndex: "auther",
      key: "auther",
      sorter: (a, b) => a?.auther?.localeCompare(b?.auther),
      width: '15%',
    },
    {
      title: "Family",
      dataIndex: "family",
      key: "family",
      sorter: (a, b) => a?.family?.localeCompare(b?.family),
      width: '15%',
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          style={viewButtonStyle}
          onClick={() => {
            console.log({ state })
            setSelectedCard({ ...data, viewMode: true })
          }}
          onMouseOver={e => e.currentTarget.style.backgroundColor = '#34495E'}
          onMouseOut={e => e.currentTarget.style.backgroundColor = '#2C3E50'}
        >
          View
        </button>
      ),
      width: '10%',
    },
    {
      title: "Barcode",
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a, b) => a?.barcode?.localeCompare(b?.barcode),
      width: '15%',
    },
    {
      title: "Collection Date",
      render: (_, item) => moment(new Date(item?.date)).format('DD MMM YYYY'),
      key: "date",
      sorter: (a, b) => new Date(a?.date) - new Date(b?.date),
      width: '12%',
    },
    {
      title: "Collected By",
      dataIndex: "collectedBy",
      key: "collectedBy",
      sorter: (a, b) => a?.collectedBy?.localeCompare(b?.collectedBy),
      width: '15%',
    },
    {
      title: selectedCard?.sortBy && !['name', 'author', 'family', 'date', 'barcode'].includes(selectedCard?.sortBy)
        ? state.sortBy.charAt(0).toUpperCase() + state.sortBy.slice(1)
        : 'Uploaded By',
      dataIndex: selectedCard?.sortBy || 'uploadedBy',
      sorter: (a, b) => a[selectedCard?.sortBy]?.localeCompare(b[selectedCard?.sortBy]),
      width: '20%',
    }
  ];
  const pageButtonStyle = {
    padding: '8px 16px',
    fontSize: '14px',
    background: 'none',
    border: '1px solid #DADCE0',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: 'background 0.2s, color 0.2s',
    '&:hover': {
      background: '#EAEDED',
      color: '#202124'
    },
    '&:disabled': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  };

  const activePageButtonStyle = {
    ...pageButtonStyle,
    background: '#EAEDED',
    color: '#202124',
    fontWeight: 'bold'
  };



  const pageButtonDarkMode = {
    ...pageButtonStyle,
    backgroundColor: '#34495E',
    color: '#EAEDED',
    border: '1px solid #7F8C8D'
  };
  const contentsStyle = { color: 'navy', fontSize: 20, marginRight: 12, width: '35%' }
  const valueStyle = { marginLeft: 12, color: 'navy', fontSize: 20 }


  function renderDetailItem(title, value) {
    if (!value) return null;
    return (
      <div className={classNames.detailItem}>
        <span style={contentsStyle}>{title}</span>:
        <span style={valueStyle}>{value}</span>
      </div>
    );
  }


  const titleStyle = {
    color: '#FFFFFF',  // Changing the text color to white for better contrast
    fontWeight: '600',  // Making the font a bit bolder
    fontSize: '18px',  // Adjusting the font size
    backgroundColor: '#1F4F82',  // Navy blue background
    padding: '8px 12px',  // Adding some padding for spacing
    borderRadius: '8px',  // Rounding the corners for a smoother appearance
  }

  const closeButtonStyle = {
    height: '40px',
    width: '100px',
    position: 'absolute',
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)', // subtle shadow
    top: '1.9%',
    left: '2%',
    padding: '1px 0px',
    backgroundColor: '#2C3E50', // dark blue
    color: '#ECF0F1', // light gray
    fontSize: '15px',
    fontWeight: '600', // not too bold, just enough weight
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: '5px', // moderate border radius
    cursor: 'pointer', // indicates interactivity
    transition: '0.3s all ease-in-out' // smooth transition for hover effects

  };


  const lightModeStyles = {
    backgroundColor: '#EAEDED',
    color: '#2C3E50'
  };




  const cardLightMode = {
    display: 'flex',
    width: '60%',
    flexDirection: 'row',
    gap: '20px',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    alignItems: 'center',
    marginBottom: '20px',
    ':hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
    }
  };
  const enlargedCardStyle = {
    backgroundColor: state.themeMode === 'light' ? '#FFFFFF' : '#333333',
    border: '1px solid #DADCE0',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  };


  const cardDarkMode = {
    ...cardLightMode,
    backgroundColor: '#34495E',
    color: '#EAEDED'
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    objectFit: 'cover'
  };

  const infoLightMode = {
    width: '70%',
    flexDirection: 'column',
    gap: '10px'
  };

  const infoDarkMode = {
    ...infoLightMode,
    color: '#EAEDED'
  };
  return (
    state.typeMode === 'simple' ?
      <>

        <div style={{
          ...state.themeMode === 'light' ? lightModeStyles : darkModeStyles,
          padding: '20px',
          ...searchTerm ? {} : { minHeight: '100%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: searchTerm ? 'flex-start' : 'center'
        }}>
          <div style={{ position: 'absolute', right: '0%', top: '0%', padding: '20px', }}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleTheme}
              aria-label="Toggle Theme"
            >
              {state.themeMode === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={toggleMaster}
              aria-label="Toggle Master"
              style={{ marginLeft: '20px' }}
            >
              {state.typeMode === 'simple' ? 'Switch to Advanced Search' : 'Switch to Simple Search'}
            </Button>
          </div>
          {/* Logo */}
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <img src={applicationForm} alt="Your Logo" style={{ height: '220px' }} />
          </div>

          <div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Input
              style={{
                width: '800px',
                height: '50px',
                borderRadius: '25px',
                borderColor: state.themeMode === 'light' ? '#DADCE0' : '#7F8C8D',
                padding: '0 20px',
                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)'
              }}
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setState({ ...state, page: 1 });
              }}
            />

            {/* Displaying result count */}
            {searchTerm && (
              <>
                <div style={{ marginTop: '10px', color: state.themeMode === 'light' ? '#34495E' : '#EAEDED', fontSize: '14px' }}>
                  {data?.totalCount} results found over {Math.min((data?.totalCount / 20)?.toFixed(0))} pages
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
                  <button
                    onClick={() => setState({ ...state, page: Math.max(1, state.page - 1) })}
                    style={state.themeMode === 'light' ? pageButtonStyle : pageButtonDarkMode}
                    disabled={state.page === 1}
                  >
                    Previous
                  </button>

                  {state.page > 10 && (
                    <span style={{ marginRight: '10px', color: state.themeMode === 'light' ? '#34495E' : '#EAEDED' }}>...</span>
                  )}

                  {Array.from({ length: 10 }, (_, i) => (Math.floor((state.page - 1) / 10) * 10) + i + 1).map(pageNum => (
                    <button
                      key={pageNum}
                      onClick={() => setState({ ...state, page: pageNum })}
                      style={state.page === pageNum ? activePageButtonStyle : pageButtonStyle}
                    >
                      {pageNum}
                    </button>
                  ))}

                  {state.page <= (data?.totalCount / 20) - 10 && (
                    <>
                      <span style={{ marginLeft: '10px' }}>...</span>
                    </>
                  )}

                  <button
                    onClick={() => setState({ ...state, page: state.page + 1 })}
                    style={state.themeMode === 'light' ? pageButtonStyle : pageButtonDarkMode}
                    disabled={state.page === Math.ceil(data?.totalCount / 20)}
                  >
                    Next
                  </button>
                </div>
              </>
            )}

          </div>

          {searchTerm && (
            <>
              {isLoading ? <LoadingAnimation dark /> : (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingBottom: '10%', alignItems: 'center' }}>
                  {studentList.map((student, i) => (
                    <div
                      key={student.id}
                      style={state.themeMode === 'light' ? cardLightMode : cardDarkMode}
                      onClick={() => {
                        setState({
                          ...state, count: state.count === i ? '' : i
                        });
                        setSelectedCard({ ...student });

                      }}
                    >
                      {state.count === i ? (
                        <div style={{ width: '100%', flexDirection: 'column', display: 'flex', padding: '1%' }}>


                          <div style={{ overflow: 'scroll', flex: 1 }}>
                            {/* General Information */}
                            <div style={titleStyle}>General Information</div>
                            {renderDetailItem('Date', moment(selectedCard?.date).format('DD-MMM-YYYY'))}
                            {renderDetailItem('Barcode', selectedCard?.barcode)}
                            {renderDetailItem('Name', selectedCard?.name)}
                            {renderDetailItem('Author', selectedCard?.auther)}
                            {renderDetailItem('Family', selectedCard?.family)}
                            {renderDetailItem('Type Status', selectedCard?.typeStatus)}

                            <div style={titleStyle}>Photography</div>
                            <div>
                              <img src={selectedCard?.photoURL} style={{
                                borderRadius: '5%',
                                height: '98%',
                                marginRight: 15,
                                boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)'
                              }} />
                            </div>
                            {/* Location Details */}
                            <div style={titleStyle}>Location Details</div>
                            {renderDetailItem('Place', selectedCard?.place)}
                            {renderDetailItem('District', selectedCard?.district)}
                            {renderDetailItem('State', selectedCard?.state)}

                            {/* Biodiversity Information */}
                            <div style={titleStyle}>Biodiversity Information</div>
                            {renderDetailItem('Associated Species', selectedCard?.associatedSpecies)}
                            {renderDetailItem('Category', selectedCard?.catagory)}
                            {renderDetailItem('Elevation', selectedCard?.elevation)}
                            {renderDetailItem('Habit', selectedCard?.habit)}
                            {renderDetailItem('Habitat', selectedCard?.habitat)}
                            {renderDetailItem('Soil Type', selectedCard?.soilType)}

                            {/* Collection & Verification */}
                            <div style={titleStyle}>Collection & Verification</div>
                            {renderDetailItem('Collection No.', selectedCard?.collectionNo)}
                            {renderDetailItem('Verified', selectedCard?.verify)}
                            {renderDetailItem('Determined by', selectedCard?.detrminavit)}
                            {renderDetailItem('Collected By', selectedCard?.collectedBy)}

                            {/* System Information */}
                            <div style={titleStyle}>System Information</div>
                            {renderDetailItem('Uploaded By', selectedCard?.uploadedBy)}
                            {renderDetailItem('Created On', moment(selectedCard?.createdOn).format('DD-MMM-YYYY hh:mm A'))}
                          </div>
                        </div>
                      ) : (
                        <>
                          <img src={student.photoURL} alt={student.name} style={imageStyle} />
                          <div style={state.themeMode === 'light' ? infoLightMode : infoDarkMode}>
                            <h5>{(i + (state.page * 20) - 19) + '. ' + student.name}</h5>
                            <p><strong>Author:</strong> {student.auther}</p>
                            <p><strong>Family:</strong> {student.family}</p>
                            <p><strong>Barcode:</strong> {student.barcode}</p>
                            <p><strong>Place:</strong> {student.place}</p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {/* Pagination buttons */}
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
                    <button
                      onClick={() => setState({ ...state, page: Math.max(1, state.page - 1) })}
                      style={pageButtonStyle}
                      disabled={state.page === 1}
                    >
                      Previous
                    </button>

                    {state.page > 10 && (
                      <>
                        <span style={{ marginRight: '10px' }}>...</span>
                      </>
                    )}

                    {Array.from({ length: 10 }, (_, i) => (Math.floor((state.page - 1) / 10) * 10) + i + 1).map(pageNum => (
                      <button
                        key={pageNum}
                        onClick={() => setState({ ...state, page: pageNum })}
                        style={state.page === pageNum ? activePageButtonStyle : pageButtonStyle}
                      >
                        {pageNum}
                      </button>
                    ))}

                    {state.page <= (data?.totalCount / 20) - 10 && (
                      <>
                        <span style={{ marginLeft: '10px' }}>...</span>
                      </>
                    )}

                    <button
                      onClick={() => setState({ ...state, page: state.page + 1 })}
                      style={pageButtonStyle}
                      disabled={state.page === Math.ceil(data?.totalCount / 20)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}


            </>
          )}
        </div>
      </> :
      <>

        <div className={classNames.tableView}   >
          <div style={{ position: 'absolute', right: '0%', top: '0%', padding: '20px', }}>

            <Button
              variant="contained"
              color="secondary"
              onClick={toggleMaster}
              aria-label="Toggle Master"
              style={{ marginLeft: '20px' }}
            >
              {state.typeMode === 'simple' ? 'Switch to Advanced Search' : 'Switch to Simple Search'}
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <img src={applicationForm} alt="Your Logo" style={{ height: '120px' }} />
          </div>


          <div className={classNames.tableHeader}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                style={{
                  width: '300px',
                  height: '40px',
                  margin: '0 10px',
                  borderRadius: '20px',
                  borderColor: '#2C3E50',
                  padding: '0 15px'
                }}
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setState({ ...state, page: 1 });
                }}
              />


              <div style={{ width: 200 }}>
                <CustomDropDown
                  keyData={'familyFilter'}
                  titleData={'Family'}
                  dropValues={dropsFamily || []}
                  stateData={state}
                  setStateData={setState}
                />
              </div>
              <div style={{ width: 200 }}>
                <CustomDropDown
                  keyData={'generaFilter'}
                  titleData={'Genera'}
                  dropValues={dropsGenera || []}
                  stateData={state}
                  setStateData={setState}
                />
              </div>
              <div style={{ width: 180 }}>
                <CustomDropDown
                  keyData={'typeStatusFilter'}
                  titleData={'Type Status'}
                  dropValues={dropsTypeStatus || []}
                  stateData={state}
                  setStateData={setState}
                />
              </div>
              <div style={{ width: 180 }}>
                <CustomDropDown
                  keyData={'collectedFilter'}
                  titleData={'Collected'}
                  dropValues={dropsCollected || []}
                  stateData={state}
                  setStateData={setState}
                />
              </div>
              <div style={{ width: 180 }}>
                <CustomDropDown
                  keyData={'sortBy'}
                  titleData={'Sort By'}
                  conversionMap={{
                    "date": "Date",
                    "name": "Name",
                    "auther": "Author",
                    "family": "Family",
                    "collectionNo": "Collection No",
                    "verify": "Verify",
                    "place": "Place",
                    "associatedSpecies": "Associated Species",
                    "catagory": "Category",
                    "detrminavit": "Determinavit",
                    "district": "District",
                    "barcode": "Barcode",
                    "elevation": "Elevation",
                    "habit": "Habit",
                    "habitat": "Habitat",
                    "nationality": "Nationality",
                    "notes": "Notes",
                    "soilType": "Soil Type",
                    "state": "State",
                    "typeStatus": "Type Status",
                    "collectedBy2": "Collected By 2",
                    "collectedBy3": "Collected By 3",
                    "collectedBy": "Collected By",
                    "uploadedBy": "Uploaded By",
                    "createdOn": "Created On"
                  }}

                  dropValues={["date",
                    "name",
                    "auther",
                    "family",
                    "collectionNo",
                    "verify",
                    "place",
                    "associatedSpecies",
                    "catagory",
                    "detrminavit",
                    "district",
                    "barcode",
                    "elevation",
                    "habit",
                    "habitat",
                    "nationality",
                    "notes",
                    "soilType",
                    "state",
                    "typeStatus",
                    "collectedBy2",
                    "collectedBy3",
                    "collectedBy",
                    "uploadedBy",
                    "createdOn",]}
                  stateData={state}
                  setStateData={setState}
                />
              </div>

              <div style={{ width: 150 }}>
                <CustomDropDown
                  keyData={'sortType'}
                  titleData={'Sort Mode'}
                  dropValues={['Ascending', 'Descending']}
                  stateData={state}
                  setStateData={setState}
                />
              </div>

              <h4 style={{ margin: '0px' }}>Accesioned: {data?.totalCount}</h4>
            </div>
          </div>





          {isLoading ? <LoadingAnimation dark /> : <>
            {state.page > 1 && < Button style={{ width: '100%', backgroundColor: '#9acffc' }} onClick={() => {
              setState({ ...state, page: (state.page - 1) })
            }}>
              Load Previous 20 Plants
            </Button>}
            <div className={classNames.tableWrap + " " + classNames.tableContainer}>
              <div className={classNames.userTable} >
                <Table
                  columns={tableColumns}
                  dataSource={studentList && studentList.length > 0 ? studentList : []}
                  pagination={false}
                />
              </div>
            </div>

            <Button style={{ width: '100%', backgroundColor: '#9acffc' }} onClick={() => {
              setState({ ...state, page: (state.page + 1) })
            }}>
              Load Next 20 Plants
            </Button>


            {selectedCard?.viewMode && (
              <div
                style={{
                  flexDirection: 'row',
                  position: 'absolute',
                  width: '55%',
                  height: '86%',
                  left: '43%',
                  top: '11.5%',
                  borderRadius: '20px',
                  display: 'flex',
                  zIndex: 100,
                  backgroundColor: '#e8eef7',  // A soft shade of blue
                  border: '3px solid #DAA520',  // Retaining the golden border for consistency
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)', // Soft shadow
                  overflow: 'hidden',
                  padding: '15px',  // Padding inside the modal
                }}
              >
                <div style={{ width: '100%', flexDirection: 'row', display: 'flex', padding: '1%' }}>
                  <div>
                    <img src={selectedCard?.photoURL} style={{
                      borderRadius: '5%',
                      height: '98%',
                      marginRight: 15,
                      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1)'
                    }} />
                  </div>
                  <div style={{ overflow: 'scroll', flex: 1 }}>
                    {/* General Information */}
                    <div style={titleStyle}>General Information</div>
                    {renderDetailItem('Date', moment(selectedCard?.date).format('DD-MMM-YYYY'))}
                    {renderDetailItem('Barcode', selectedCard?.barcode)}
                    {renderDetailItem('Name', selectedCard?.name)}
                    {renderDetailItem('Author', selectedCard?.auther)}
                    {renderDetailItem('Family', selectedCard?.family)}
                    {renderDetailItem('Type Status', selectedCard?.typeStatus)}

                    {/* Location Details */}
                    <div style={titleStyle}>Location Details</div>
                    {renderDetailItem('Place', selectedCard?.place)}
                    {renderDetailItem('District', selectedCard?.district)}
                    {renderDetailItem('State', selectedCard?.state)}

                    {/* Biodiversity Information */}
                    <div style={titleStyle}>Biodiversity Information</div>
                    {renderDetailItem('Associated Species', selectedCard?.associatedSpecies)}
                    {renderDetailItem('Category', selectedCard?.catagory)}
                    {renderDetailItem('Elevation', selectedCard?.elevation)}
                    {renderDetailItem('Habit', selectedCard?.habit)}
                    {renderDetailItem('Habitat', selectedCard?.habitat)}
                    {renderDetailItem('Soil Type', selectedCard?.soilType)}

                    {/* Collection & Verification */}
                    <div style={titleStyle}>Collection & Verification</div>
                    {renderDetailItem('Collection No.', selectedCard?.collectionNo)}
                    {renderDetailItem('Verified', selectedCard?.verify)}
                    {renderDetailItem('Determined by', selectedCard?.detrminavit)}
                    {renderDetailItem('Collected By', selectedCard?.collectedBy)}

                    {/* System Information */}
                    <div style={titleStyle}>System Information</div>
                    {renderDetailItem('Uploaded By', selectedCard?.uploadedBy)}
                    {renderDetailItem('Created On', moment(selectedCard?.createdOn).format('DD-MMM-YYYY hh:mm A'))}
                  </div>
                  <Button style={closeButtonStyle} onClick={() => { setSelectedCard({ ...state, viewMode: false }) }}>
                    Close
                  </Button>
                </div>
              </div>
            )}


          </>}

        </div >


      </>


  );
}
export default AdmissionRegister;
