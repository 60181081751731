import React, { useCallback, useContext, useState, StyleSheet } from "react";
import Scrollbars from "react-custom-scrollbars";
import message from "antd/lib/message";
import classNames from "./addBookModal.module.scss";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import CustomInput from "../../../../../myComp/CustomInput";
import CustomDropDown from "../../../../../myComp/CustomDropDown";



function SettupQuestionnaire({ onSuccess, itemData, onCloseModal, questionnaireId, refectOrgin }) {


  const { collegeId } = useContext(FirebaseContext);
  const [state, setState] = useState({
    mainQ: 1
  });

  const loadOldData = useCallback(async () => {
    let newData = {}

    newData.mainQ = parseInt(itemData?.questionnaire?.length);

    itemData?.questionnaire?.forEach((item, c) => {

      newData[c + '~question'] = item.question;
      newData[c + 'subQ'] = parseInt(item?.subQuestions?.length);
      item?.subQuestions?.forEach((data, i) => {
        newData[(c + '') + (i + '') + '~subQuestion'] = data.subQuestion;
        newData[(c + '') + (i + '') + '~type'] = data.type;
        newData[(c + '') + (i + '') + '~nature'] = data.nature;
        if (data.type === 'Options') {
          newData[(c + '') + (i + '') + '~option1'] = data.option1;
          newData[(c + '') + (i + '') + '~option2'] = data.option2;
          newData[(c + '') + (i + '') + '~option3'] = data.option3;
          newData[(c + '') + (i + '') + '~option4'] = data.option4;
          newData[(c + '') + (i + '') + '~option5'] = data.option5;
        }
        if (data.type === 'Likert Scale') {
          newData[(c + '') + (i + '') + '~likert1'] = data.likert1;
          newData[(c + '') + (i + '') + '~likert5'] = data.likert5;
        }
      });
    })
    setState({ ...newData })
  }, [state]);

  console.log({ state });


  let mainCount = Array(state?.mainQ).fill(1)
  console.log({ mainCount });


  mainCount?.forEach((item, i) => {
    if (state[i + 'subQ'] > 0) {

    } else {
      setState({
        ...state,
        [i + 'subQ']: 1
      })
    }
  })
  const postQuestionnaire = useCallback(async () => {
    let finalData = []
    mainCount?.forEach((item, c) => {
      let subQuestions = []
      Array(state[c + 'subQ']).fill(1)?.forEach((data, i) => {
        let subQuestion = state[(c + '') + (i + '') + '~subQuestion']
        let type = state[(c + '') + (i + '') + '~type']
        let nature = state[(c + '') + (i + '') + '~nature']
        let option1 = state[(c + '') + (i + '') + '~option1']
        let option2 = state[(c + '') + (i + '') + '~option2']
        let option3 = state[(c + '') + (i + '') + '~option3']
        let option4 = state[(c + '') + (i + '') + '~option4']
        let option5 = state[(c + '') + (i + '') + '~option5']
        let likert1 = state[(c + '') + (i + '') + '~likert1']
        let likert5 = state[(c + '') + (i + '') + '~likert5']
        subQuestions.push({
          subQuestion,
          type,
          nature,
          ...option1 && { option1: option1 },
          ...option2 && { option2: option2 },
          ...option3 && { option3: option3 },
          ...option4 && { option4: option4 },
          ...option5 && { option5: option5 },
          ...likert1 && { likert1: likert1 },
          ...likert5 && { likert5: likert5 },
        })
        console.log({ subQuestions });
      });
      finalData.push({
        question: state[c + '~question'],
        subQuestions
      })
    })

    try {
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/iqac/postQuestionnair", {
        collegeId,
        _id: questionnaireId,
        questionnaire: finalData
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        onSuccess()
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      refectOrgin()
      onSuccess();
      setState({})

    }
  }, [state]);

  return (
    <div className={classNames.addBookModal}>
      <div className={classNames.head}>
        <span>Set-Up Questionnaire</span>
        <button onClick={() => loadOldData()} className={classNames.load} >Load Selected Book Data </button>
        <button onClick={onCloseModal} className={classNames.cancel} >Cancel</button>
      </div>
      <Scrollbars style={{ padding: 10 }}>
        <div style={{ flexDirection: 'column', display: 'flex' }}>
          <span>{itemData.title}</span>
          {mainCount.map((x, c) => (
            <div className={classNames.titlesSub} style={{ width: '100%' }}>
              < CustomInput
                keyData={c + '~question'}
                titleData={'Title of Questionnaire'}
                typeData={'text'}
                stateData={state}
                setStateData={setState}
              />
              {(Array(state[c + 'subQ']) || 1).fill(1).map((x, i) => (
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <>
                    <div style={{ width: 700 }}>
                      < CustomInput
                        keyData={(c + '') + (i + '') + '~subQuestion'}
                        titleData={'Question'}
                        typeData={'text'}
                        stateData={state}
                        setStateData={setState}
                      />
                    </div>
                    <div style={{ width: 200 }}>
                      <CustomDropDown
                        keyData={(c + '') + (i + '') + '~type'}
                        titleData={'Catogory'}
                        dropValues={['Paragraph', 'Options', 'Likert Scale']}
                        stateData={state}
                        setStateData={setState}
                      />
                    </div>
                    <div style={{ width: 200 }}>
                      <CustomDropDown
                        keyData={(c + '') + (i + '') + '~nature'}
                        titleData={'Nature'}
                        dropValues={['Mandatory', 'Optional']}
                        stateData={state}
                        setStateData={setState}
                      />
                    </div>
                    {state[(c + '') + (i + '') + '~type'] === 'Likert Scale' &&
                      <>
                        <div style={{ width: 200 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~likert1'}
                            titleData={'Value 1'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>

                        <div style={{ width: 200 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~likert5'}
                            titleData={'Value 5'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>
                      </>
                    }
                    {state[(c + '') + (i + '') + '~type'] === 'Options' &&
                      <>
                        <div style={{ width: 150 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~option1'}
                            titleData={'Option 1'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>

                        <div style={{ width: 150 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~option2'}
                            titleData={'Option 2'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>
                        <div style={{ width: 150 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~option3'}
                            titleData={'Option 3'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>
                        <div style={{ width: 150 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~option4'}
                            titleData={'Option 4'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>
                        <div style={{ width: 150 }}>
                          < CustomInput
                            keyData={(c + '') + (i + '') + '~option5'}
                            titleData={'Option 5'}
                            typeData={'text'}
                            stateData={state}
                            setStateData={setState}
                          />
                        </div>
                      </>
                    }
                  </>

                </div>

              ))}
              <button className={classNames.subQ} onClick={() => setState({ ...state, [c + 'subQ']: parseInt(state[c + 'subQ']) - 1 })}   >-</button>
              <button className={classNames.subQ} onClick={() => setState({ ...state, [c + 'subQ']: parseInt(state[c + 'subQ']) + 1 })}   >+</button>
            </div>
          ))}
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <button className={classNames.mainQ} onClick={() => setState({ ...state, mainQ: parseInt(state.mainQ) - 1 })}   >-</button>
            <button className={classNames.mainQ} onClick={() => setState({ ...state, mainQ: parseInt(state.mainQ) + 1 })}   >+</button>
          </div>
          <button className={classNames.submit} onClick={() => postQuestionnaire()}   >Submit</button>

        </div>
      </Scrollbars>
    </div>
  );
}

export default SettupQuestionnaire;

const styles = {
  buttonStyle: {
    backgroundColor: 'green', color: 'white', fontSize: 40, borderRadius: 12, height: 50
  }



};


