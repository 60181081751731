import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import ModalWrapper from "../../../../components/ModalWrapper";
import NewQuestionnaire from "./NewQuestionnaire";
import { getQuestionnair } from "../../ApiServices";
import moment from "moment";
import SettupQuestionnaire from "./SettupQuestionnaire";
import { EditDataSheet } from "./EditData";
import { Body, Footer, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../../../NecttosComp/Table/Table";
import Button from "../../../../NecttosComp/Button/Button";
import Input from "../../../../NecttosComp/Input/Input";
import { DataSheet } from "./DataSheet/DataSheet";
import SettupQuestionnaire2 from "../NaacDataSurvey/SettupQuestionnaire";

export const NaacDataCollection = ({ onClose }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [state, setState] = React.useState({
    filterValue: "",
    page: 0,
  });

  const componentRef = useRef();
  let [items, setItems] = React.useState({
    addBook: false,
  });
  const { data = [], isLoading, error, refetch } = useQuery(["getQuestionnair", collegeId, state.type], getQuestionnair(collegeId, state.filterType));

  const filteredData = (data ?? []).filter((item) => item?.title?.toLowerCase().includes((state.searchValue ?? "").trim().toLowerCase()));

  const { tableItems, requestSort, getIcon } = useSortableData(filteredData);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <>
      <Main height="90vh" width="95vw" title="NAAC CUSTOM DATA COLLECTOR">
        <Header>
          <div className="flex justify-center items-center">
            <Button
              type="search"
              onClick={() => {
                setItems({ ...items, newQuestionnaire: true });
              }}
            >
              Create new Questionnair
            </Button>
            <Input fieldName="search" returnKey="searchValue" state={state} setState={setState} type="text" />
          </div>

          <div className="flex">
            <Button type="print" tableRef={componentRef.current}>
              Print
            </Button>
            <Button type="fetch" onClick={refetch}>
              Loard
            </Button>
            <Button type="excel" tableRef={componentRef.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          {isLoading ? (
            <LoadingAnimation dark />
          ) : (
            <>
              {data.length > 0 ? (
                <div ref={componentRef} id="scrollBarEnabletable" className=" h-[75vh] overflow-auto printable">
                  <Table width="100%" innerWidth={["5%", "15%", "15%", "15%", "10%", "10%", "10%", "20%"]}>
                    <Thead>
                      <Tr>
                        <Th position={0}>Sn</Th>
                        <Th position={1}>Criteria</Th>
                        <Th position={2} onClick={() => requestSort("title")} icon={getIcon("title")}>
                          Sub Criteria
                        </Th>
                        <Th position={4}>Responds</Th>
                        <Th position={6}>No of Questions</Th>
                        <Th position={7}>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tableItems?.map((item, i) => (
                        <>
                          <Tr>
                            <Td index={i} position={0}>
                              <div className="flex">{parseInt(state.page) * 50 + 1 + i}</div>
                            </Td>
                            <Td index={i} position={1}>
                              {item.title.slice(0, 1)}
                            </Td>
                            <Td index={i} position={2}>
                              {item.title}
                            </Td>

                            <Td index={i} position={4}>
                              {item.response || "0"}
                            </Td>

                            <Td index={i} position={6}>
                              {item.length || "0"}
                            </Td>

                            <Td index={i} position={7}>
                              <div className="flex">
                                <Button
                                  type="update"
                                  onClick={() => {
                                    setItems({
                                      ...items,
                                      editDataSheet: true,

                                      questionnaireId: item._id,

                                      itemData: {
                                        _id: item._id,
                                        collegeId: item.collegeId,
                                        title: item.title,
                                        effectFrom: moment(item.effectFrom).format("YYYY-MM-DD"),
                                        type: item.type,
                                        mode: item.mode,
                                        semester: item.semester,
                                        whoFill: item.whoFill,
                                        academicYear: item.academicYear,
                                        questionnaire: item.questionnaire,
                                        nature: item.nature,
                                        lastDate: moment(item.lastDate).format("YYYY-MM-DD"),
                                      },
                                    });
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  type="fetch"
                                  style={{ width: 200, backgroundColor: "green" }}
                                  onClick={() => {
                                    setItems({
                                      ...items,
                                      dataSheet: true,
                                      title: item?.title,
                                      questionnaireId: item._id,
                                    });
                                  }}
                                >
                                  Data Sheet
                                </Button>

                                <Button
                                  type="fetch"
                                  onClick={() => {
                                    setItems({
                                      ...items,
                                      settupQuestionnaire: true,
                                      questionnaireId: item._id,
                                      itemData: {
                                        _id: item._id,
                                        collegeId: item.collegeId,
                                        title: item.title,
                                        effectFrom: moment(item.effectFrom).format("YYYY-MM-DD"),
                                        type: item.type,
                                        mode: item.mode,
                                        semester: item.semester,
                                        whoFill: item.whoFill,
                                        academicYear: item.academicYear,
                                        questionnaire: item.questionnaire,
                                        nature: item.nature,
                                        lastDate: moment(item.lastDate).format("YYYY-MM-DD"),
                                      },
                                    });
                                  }}
                                >
                                  Add Q
                                </Button>

                                <Button
                                  type="save"
                                  className={classNames.reloadButton}
                                  style={{ width: 250, backgroundColor: "green" }}
                                  onClick={() => {
                                    setItems({
                                      ...items,
                                      settupAnswers: true,
                                      questionnaireId: item._id,
                                      itemData: {
                                        _id: item._id,
                                        collegeId: item.collegeId,
                                        title: item.title,
                                        effectFrom: moment(item.effectFrom).format("YYYY-MM-DD"),
                                        type: item.type,
                                        mode: item.mode,
                                        semester: item.semester,
                                        whoFill: item.whoFill,
                                        academicYear: item.academicYear,
                                        questionnaire: item.questionnaire,
                                        nature: item.nature,
                                        lastDate: moment(item.lastDate).format("YYYY-MM-DD"),
                                      },
                                    });
                                  }}
                                >
                                  Fill Up your Answer
                                </Button>
                              </div>
                            </Td>
                          </Tr>
                        </>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              ) : (
                <div>No data available or no matches found.</div>
              )}
            </>
          )}
        </Body>
        <div className={classNames.absoluteDiv}></div>
        {items.newQuestionnaire && (
          <ModalWrapper>
            <NewQuestionnaire
              onCloseModal={() => setItems({ ...items, newQuestionnaire: false })}
              itemData={items.itemData}
              refectOrgin={refetch}
              questionnaireId={items.questionnaireId}
              onSuccess={() => {
                setItems({ ...items, newQuestionnaire: false });
              }}
            />
          </ModalWrapper>
        )}

        <Footer />
      </Main>
      {items.editDataSheet && (
        <EditDataSheet
          onClose={() => setItems({ ...items, editDataSheet: false })}
          itemData={items.itemData}
          refectOrgin={refetch}
          questionnaireId={items.questionnaireId}
          onSuccess={() => {
            setItems({ ...items, statistics: false });
          }}
        />
      )}

      {items.dataSheet && (
        <DataSheet
          onClose={() => setItems({ ...items, dataSheet: false })}
          itemData={items.itemData}
          refectOrgin={refetch}
          titleOfHead={items?.title}
          questionnaireId={items.questionnaireId}
          onSuccess={() => {
            setItems({ ...items, statistics: false });
          }}
        />
      )}

      {items.settupAnswers && (
        <ModalWrapper>
          <SettupQuestionnaire2
            onCloseModal={() => setItems({ ...items, settupAnswers: false })}
            itemData={items.itemData}
            refectOrgin={refetch}
            questionnaireId={items.questionnaireId}
            onSuccess={() => {
              setItems({ ...items, settupQuestionnaire: false });
            }}
          />
        </ModalWrapper>
      )}

      {items.settupQuestionnaire && (
        <SettupQuestionnaire
          onCloseModal={() => setItems({ ...items, settupQuestionnaire: false })}
          itemData={items.itemData}
          refectOrgin={refetch}
          questionnaireId={items.questionnaireId}
          onSuccess={() => {
            setItems({ ...items, settupQuestionnaire: false });
          }}
        />
      )}
    </>
  );
};
