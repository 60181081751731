import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./detailView.module.scss";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { useProfileData } from "../../../../queryHooks";
import CustomInput from "../../../../myComp/CustomInput";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import moment from "moment";


function DetailsToUpload({ uid, isStudent, stateData, TimerFuncion, refectData, whoAdmin, setStateData, keyData }) {
  const { collegeId, user } = useContext(FirebaseContext);

  const {
    data: userDetail,
    status,
  } = useProfileData(uid, !isStudent);
  const [state, setState] = useState({})

  const Button = ({ color, text, onClick }) => {
    return (
      <button style={{ width: 180, margin: 20, borderColor: color, fontSize: 20, color: 'white', fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
        {text}
      </button>
    );
  };
  if (!uid) {
    return (
      <div className={classNames.detailView}>
        <div className={classNames.selectmessage}>Select A User First</div>
      </div>
    );
  }
  const handleCancel = () => {
    setStateData({ ...stateData, [userDetail._id + 'proceed']: false, verification: false, })
  };



  let memberData = keyData === 'department' ? userDetail?.pendingData : userDetail;

  const handleProceed = async () => {
    try {
      let data = {
        _id: userDetail?.uid,
        startDate: stateData[[userDetail?._id + 'timeStamp']],
        endDate: new Date(),
        collegeId,
        whoAdmin,
        key: keyData
      }
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/admissionProcess/verify", data);
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {

        refectData()
        setStateData({

          ...stateData,
          verification: false,
        })
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };
  const CopyButton = ({ text, title }) => {
    const inputRef = React.useRef(null);

    const copyText = () => {
      inputRef.current.select();
      document.execCommand('copy');
    };

    return (
      <div>
        <button style={{ height: 50, width: 200, fontSize: 20 }} onClick={copyText}>Copy {title}</button>
        <input style={{ height: 50, padding: 12, width: 400, fontSize: 20 }} type="text" value={text} ref={inputRef} readOnly />
      </div>
    );
  };





  return (
    <div className={classNames.container}>
      <div className={classNames.modal} style={{ overflow: 'scroll', margin: 20 }}>
        <div className={classNames.detailView}>
          {status === "loading" && <LoadingAnimation dark />}
          {status === "success" && (
            <>
              <div style={{ overflow: 'scroll', margin: 20 }}>
                <div className={classNames.dataWraper} style={{ overflow: 'scroll', margin: 5, textAlign: 'center', width: '100%' }}>
                  <>
                    <img src={userDetail?.photoURL} style={{
                      border: '3px solid navy',
                      borderRadius: '10%',
                      width: '20%',
                      margin: 20
                    }} />
                    <div className={classNames.subTitle} style={{ background: 'navy', color: 'white' }}>Details to E-POS</div>
                    <div style={{ margin: 12, }}>  <CopyButton text={memberData?.capId.slice(-6)} title={'CAP ID'} />  </div>
                    <div style={{ margin: 12, }}>  <CopyButton text={memberData?.name} title={'Name'} />  </div>
                    <div style={{ margin: 12, }}>  <CopyButton text={memberData?.email} title={'E-mail'} />  </div>
                    <div style={{ margin: 12, }}>  <CopyButton text={memberData?.phoneNumber?.slice(-10)} title={'Mobile'} />  </div>
                    <div style={{ margin: 12, }}>  <CopyButton text={moment(memberData?.dob).format('DD/MM/YYYY')} title={'DOB'} />  </div>
                    <view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
                      <Button color="#FF4136" text="Back" onClick={handleCancel} />
                      {keyData === 'university' && <Button color="green" text="Uploaded" onClick={handleProceed} />}
                    </view>
                  </>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DetailsToUpload;
