import React, { useContext, useState } from 'react';
import { message } from 'antd';
import { getAxiosTokenInstance } from '../../../../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../../../context/FirebaseContext';
import { getDayBook } from '../../../ApiServices';
import SearchableInput from '../../../LibraryModals/AddBook/DropDownComponent';
import CustomDropDownKey from '../../../../../myComp/CustomDropDownKey';
import CustomInput from '../../../../../myComp/CustomInput';
import { Body, Main } from '../../../../../NecttosComp/Layout/Layout';
import Button from '../../../../../NecttosComp/Button/Button';

export const exportPdfCollectionReport = async (data) => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.post(`/college/reportGeneration/generateCollectionReport`, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const JournalEntry = ({ onClose, datavalues, setStateData, stateData, setAutoFetch }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [date, setDate] = useState({});
	const { data = [], refetch } = useQuery('getDayBoook', getDayBook(collegeId, date.date));
	const [isLoading, setIsLoading] = useState(false);

	const fromAccounts = data.mainAccounts || [];
	const toAccounts = data.mainAccounts || [];

	const [state, setState] = useState({
		date: '',
		from: '',
		to: '',
		amount: '',
		naration: '',
		paidTo: '',
		linkId: '',
		...datavalues,
	});

	const makeEntry = async () => {
		try {
			const entryData = {
				collegeId,
				type: 'payment',
				date: state.date,
				from: state.from,
				to: state.to,
				amount: state.amount,
				naration: state.naration,
				paidTo: state.paidTo,
				linkId: state.linkId,
				amount: parseInt(state.amount),
			};

			setIsLoading(true);
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/fees/accounts', entryData);

			message.success(resp?.message || 'Journal Posted');
			setState({
				date: '',
				from: '',
				to: '',
				amount: '',
				naration: '',
				paidTo: '',
				linkId: '',
			});
			setStateData({
				...stateData,
				data: [],
				from: '',
				naration: '',
				paidTo: '',
				to: '',
				amount: '',
				linkId: '',
			});
			setAutoFetch(true);
			setStateData((prev) => ({ ...prev, showEdit: false }));
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
			// setAutoFetch(false)
		}
	};
	const clearForm = () => {
		setState({
			date: '',
			from: '',
			to: '',
			amount: '',
			naration: '',
			paidTo: '',
			linkId: '',
		});
		setStateData({
			...stateData,
			values: {},
		});
	};

	console.log('state data =>', { data });

	return (
		<Main title='Journal Entry' height='100vh' width='40vw' zIndex='300'>
			<div className='flex h-auto justify-end pb-2'>
				<Button type='fetch' onClick={clearForm}>
					Clear
				</Button>
				<Button type='close' onClick={onClose}>
					Close
				</Button>
			</div>
			<Body>
				<div className='py-6'>
					<CustomInput keyData='date' titleData='From Date' typeData='date' stateData={state} setStateData={setState} />
					<CustomDropDownKey keyData='from' keyObject='name' keyReturn='_id' titleData='From Account' dropValues={fromAccounts} stateData={state} setStateData={setState} />
					<CustomDropDownKey keyData='to' keyObject='name' keyReturn='_id' titleData='To Account' dropValues={toAccounts} stateData={state} setStateData={setState} />
					<CustomInput keyData='amount' titleData='Amount' typeData='number' stateData={state} setStateData={setState} />
					<SearchableInput keyData='paidTo' titleData='Paid To' collection='accounts' stateData={state} setStateData={setState} />
					<SearchableInput keyData='naration' titleData='Naration' collection='accounts' stateData={state} setStateData={setState} />
					<CustomInput keyData='receiptNo' titleData={`Receipt No ${state.billNumber || ''}`} typeData='number' stateData={state} setStateData={setState} />
					<div className='flex justify-center'>
						<Button type='submit' width='200px' height='50px' onClick={makeEntry} loading={isLoading}>
							Post Entry
						</Button>
					</div>
				</div>
			</Body>
		</Main>
	);
};
