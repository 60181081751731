import React, { useContext, useState } from 'react'
import { List, Avatar } from 'antd';
import classNames from "./fastCash.module.scss"
import { getFastCashTokens } from '../../feesManagementServices';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import LoadingAnimation from '../../../../components/LoadingAnimation';

export default function FastCash({ handleCollect }) {

    const { collegeId } = useContext(FirebaseContext);

    const [search, setSearch] = useState("");

    const { data = [], isFetching } = useQuery('fastCashTokens', getFastCashTokens(collegeId));

    return (
        <div className={classNames.fastCash}>
            {isFetching && <LoadingAnimation dark />}
            <div className={classNames.titlecontainer}>
                <h5>Fast Cash</h5>
                <div className={classNames.searchWrap}>
                    <input
                        type="text"
                        placeholder="Search Student"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>
            <div className={classNames.listContainer}>
                <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar className={classNames.avatar} size={60} src={item.photoURL} />}
                                title={<div className={classNames.title}>{item.userName}</div>}
                                description={item?.className}
                            />
                            <div style={{ flexDirection: 'column' }}>
                                {
                                    item?.groups?.map(grp => (
                                        <div className={classNames.feeDiv}>
                                            <p className={classNames.title}>{grp.feeName}</p>
                                            <p className={classNames.price}>{grp.totalAmount}</p>
                                        </div>
                                    ))
                                }
                            </div>
                            <button className={classNames.printButton} onClick={() => handleCollect(item)}>Collect</button>
                        </List.Item>
                    )}
                />,

            </div>

        </div>
    )
}
