import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import {
  AcademicYears,
  naacExcelGenerator,
  naacFetchData,
} from "../../ApiServices";

function C3_2_2({ val, onClose }) {
  const [values, setValues] = useState();
  const { collegeId } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  const [academicYear, setAcademicYear] = useState("2022-23");

  useEffect(() => {
    naacFetchData({
      data: { collegeId, academicYear },
      setLoading,
      setValues,
      url: "/college/NAAC/getNAAC_C3_2_2",
    });
  }, []);
  return (
    <>
      <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
       
        <table className="table">
          <th colSpan={5} style={{ background: "rgb(241 245 249)" }}>
            <h1 className="text-xl flex">
              3.2.2 Number of workshops/seminars conducted on Research
              Methodology, Intellectual Property Rights {"("}IPR{")"} and
              entrepreneurship during the year
              <div className="flex">
                <AcademicYears setAcademicYear={setAcademicYear} />
                <button
                  className="bg-green-500 text-sm"
                  onClick={() =>
                    naacExcelGenerator({
                      data: { collegeId, academicYear: "2022-23" },
                      url: "/college/NAAC/getNAAC_C2_7",
                      setLoading,
                    })
                  }
                >
                  Dowload
                </button>
                <button
                  className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]"
                  onClick={onClose}
                >
                  X
                </button>
              </div>
            </h1>
          </th>
          <tr>
            <th className="w-1/4">Year</th>
            <th className="w-1/4">Name of the workshop/ seminar</th>
            <th className="w-1/4">Number of Participants</th>
            <th className="w-1/4">Date From – To</th>
            <th className="w-1/4">
              Link to the Activity report on the website
            </th>
          </tr>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
            <td>2</td>
          </tr>
        </table>
      </div>
    </>
  );
}

export default C3_2_2;
