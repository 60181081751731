import React, { ReactNode, useEffect } from "react";
import "./Layout.css";
import Button from "../Button/Button";


interface MainProps {
  children?: ReactNode;
  width?: string;
  height?: string;
  style?:object;
  title?: string;
  className?: string;
  zIndex?: string;
  bgColor?: string;
  documentOnClick?: () => void;
}
export const Main = ({ children, width, height, title, className,zIndex, bgColor }: MainProps) => {
  return (
    <div className="app-window className max-h-[100%] max-w-[100%]" style={{zIndex:zIndex}}>
      <div className={`flex relative flex-col ${bgColor? 'bg-'+bgColor:'bg-white-blur blur-custom'} transition duration-500 autoWidthAnimation pt-4 max-h-[100%] max-w-[100%]`} style={{ width: width, height: height }}>
        <h1 className="modalNameMain">{title || "Necttos"}</h1>
        {children}
      </div>
    </div>
  );
};

export const Header = ({ children, width = "100%", height }: MainProps) => {
  return (
    <div className="flex justify-between flex-wrap items-center" style={{ width: width, height: height }}>
      {children}
    </div>
  );
};

export const Body = ({ children, height = `100%`, width = "100%", style }: MainProps) => {
  return <div style={{ width: width, height: height, overflow: "scroll", ...style }}>{children}</div>;
};

export const Footer = ({ children, width = "100%", height = "40px", documentOnClick }: MainProps) => {
  return (
    <div className="flex bg-[#73727260] absolute bottom-0 left-0 rounded-[0_0_10px_10px] blur-custom justify-between p-1 font-bold text-white your-custom-font-class" style={{ width: width, height: height }}>
      Powered By Necttos v 5.0
      <Button type="doc" onClick={documentOnClick}>
        Learn Necttos
      </Button>
    </div>
  );
};

export const closeAnimation = () => {
  const elem: any = document.getElementsByClassName("app-window");
  elem[elem?.length - 1].style.transform = "scaleX(0) scaleY(0) translateY(200vw)";
};

export const widthChangeAnimation = (val: string) => {
  const elem: any = document.getElementsByClassName("autoWidthAnimation");
  elem[elem?.length - 1].style.width = val;
};
