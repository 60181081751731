import React, { useContext, useEffect, useRef, useState } from 'react';
import message from 'antd/lib/message';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import LoadingAnimation from '../../components/LoadingAnimation';
import { FirebaseContext } from '../../context/FirebaseContext';
import { useAccountsHeads, useAllClasses } from '../../queryHooks';
import { getFeeStructure } from '../../pages/college/ApiServices';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { Header, Main } from '../../NecttosComp/Layout/Layout';

const createRadomId = (prefix = '') => {
	const uuid = uuidv4()?.toUpperCase();
	const parsedUuid = uuid.replace('-', '');
	return `${prefix ? `${prefix}-` : ''}${parsedUuid}`;
};

function calculateTotalAmountByGroup(data) {
	const result = {};

	data.forEach((item) => {
		if (result[item.mainAccountName]) {
			result[item.mainAccountName] += item.amount;
		} else {
			result[item.mainAccountName] = item.amount;
		}
	});

	return result;
}
const calculateGrandTotal = (groupTotals) => {
	return Object.values(groupTotals).reduce((sum, value) => sum + value, 0);
};
const feesTypess = [
	{ name: 'Goverment Fees', key: 'TuitionFee' },
	{ name: 'Examination Fees', key: 'ExamFee' },
	{ name: 'Other Fees', key: 'Others' },
];
const years = [
	{ name: 'First Year', key: '1y' },
	{ name: 'Second Year', key: '2y' },
	{ name: 'Third Year', key: '3y' },
	{ name: 'Fourth year', key: '4y' },
	{ name: 'Fifth Year', key: '5y' },
	{ name: 'Year not specified', key: 'yearNotSpecified' },
];
function sumWithKey(data, key) {
	return data?.reduce((x, y) => x + y[key], 0);
}

const ACTIONS = {
	SET_STATE: 'SET_STATE',
	RESET_STATE: 'RESET_STATE',
	UPDATE_DUES: 'UPDATE_DUES',
};

function reducer(state, action) {
	switch (action.type) {
		case ACTIONS.SET_STATE:
			return { ...state, ...action.payload };
		case ACTIONS.RESET_STATE:
			return action.payload;
		case ACTIONS.UPDATE_DUES:
			return {
				...state,
				dues: state.dues.map((due) => (due._id === action.payload._id ? { ...due, ...action.payload } : due)),
			};
		default:
			return state;
	}
}

function FeeStructure({ onClose, classData, userId, loadFee }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const allClasses = useAllClasses({ needAll: true, classOnly: false });
	const [isModalOpen, setIsModalOpen] = useState(true);
	const componentRef = useRef(null);
	const classList = allClasses?.data || [];
	const accounts = useAccountsHeads();
	const mapFeeAcounts = accounts?.data?.subAccounts || [];
	const mapMainAccounts = accounts?.data?.mainAccounts || [];
	const feeAccountMap = new Map(mapFeeAcounts?.map((account) => [account.value, account]));
	const mainAccountMap = new Map(mapMainAccounts?.map((account) => [account.value, account]));
	const [isLoading, setIsLoading] = useState(false);
	const [stateConst, setStateConst] = useState({ list: { dues: [] } });
	const [classId, setClassId] = useState(classData?.classId);
	const [classIdCopy, setClassIdCopy] = useState();

	const [state, setState] = useState({
		_id: '',
		title: '',
	});

	const [state2, setState2] = useState({});

	let { data } = useQuery(['getFeeStructure4345', classId, classData?.classId], getFeeStructure(collegeId, classId), { keepPreviousData: true });
	let { data: data2 } = useQuery(['getFeeStructure23454', classIdCopy], getFeeStructure(collegeId, classIdCopy), { keepPreviousData: true });

	useEffect(() => {
		if (state?.dueId) {
			const feeAccount = feeAccountMap.get(state?.dueId);
			const mainAccount = mainAccountMap.get(feeAccount?.mainId);
			if (feeAccount && mainAccount) setState2({ ...state2, title: feeAccount?.label, mainTitle: mainAccount?.label });
		}
	}, [state?.dueId]);

	useEffect(() => {
		if (state2?.title) {
			setState({ ...state, title: state2.title, mainTitle: state2.mainTitle });
		}
	}, [state2?.title]);

	const dataVal = { list: { dues: [] } };

	useEffect(() => {
		if (classList.length > 0 && classId) {
			const sel = classList?.find((x) => x.classId === classId);
			setState({ ...state, className: sel.batchName + ' ' + sel.shortName });
		}
	}, [classId, classList]);

	const setConst = () => {
		if (!data?.list?.dues) {
			setStateConst({ list: { dues: [] } });
			setState((prev) => ({
				...prev,
				yearTitle: 'First Year',
				year: '1y',
				'1y': true,
				'2y': true,
				'3y': true,
				'4y': true,
			}));
			return;
		}
		const updatedDues = data?.list?.dues.map((item) => {
			const feeAccount = feeAccountMap.get(item?.accountId);
			const mainAccount = mainAccountMap.get(feeAccount?.mainId);
			return {
				...item,
				subAccountName: feeAccount?.label,
				mainAccountName: mainAccount?.label,
			};
		});
		setStateConst({ list: { dues: updatedDues } });
		setState((prev) => ({
			...prev,
			yearTitle: 'First Year',
			year: '1y',
			'1y': true,
			'2y': true,
			'3y': true,
			'4y': true,
		}));
	};

	useEffect(() => {
		const data = mapFeeAcounts.find((x) => x.value === state?.dueid);
		if (data) {
			setState((prevState) => ({ ...prevState, amount: parseInt(data?.amount) }));
		}
	}, [state.dueid]);

	const copyConst = () => {
		setStateConst({ ...data2 });
		setState((prev) => ({
			...prev,
			yearTitle: 'First Year',
			['1y']: true,
			['2y']: true,
			['3y']: true,
			['4y']: true,
		}));
	};

	const feesClearence = async () => {
		if (state?._id?.length > 20) {
			stateConst.list.dues.forEach((x) => {
				if (x._id === state?._id) {
					x.feesType = state?.feesType;
					x.year = state?.feesYear;
					x.amount = parseInt(state?.amount);
					x.balance = parseInt(state?.amount);
					x.dueDate = state?.date;
					x.accountId = state?.dueId;
					x.title = state?.title;
					x.subAccountName = state?.title;
					x.mainAccountName = state?.mainTitle;
				}
			});
			setState({
				yearTitle: 'First Year',
				['1y']: true,
				['2y']: true,
				['3y']: true,
				['4y']: true,
			});
		} else {
			stateConst.list.dues.push({
				feeType: state?.feesType,
				year: state?.feesYear,
				amount: parseInt(state?.amount),
				paid: 0,
				balance: parseInt(state?.amount),
				dueDate: new Date(state?.date),
				accountId: state?.dueId,
				title: state?.title,
				subAccountName: state?.title,
				mainAccountName: state?.mainTitle,
				_id: createRadomId('FD'),
				createdOn: new Date(),
				createdBy: user?.uid,
			});
			setState({
				feesType: state?.feesType,
				feesYear: state?.feesYear,
				date: state?.date,
				yearTitle: 'First Year',
				['1y']: true,
				['2y']: true,
				['3y']: true,
				['4y']: true,
			});
		}
		setStateConst({ ...stateConst });
	};

	const deleteItems = async (_id) => {
		setStateConst((prevState) => {
			const newDues = prevState.list.dues.filter((due) => due._id !== _id);
			return { ...prevState, list: { dues: newDues } };
		});
	};

	const onAddFees = async () => {
		try {
			setIsLoading(true);
			let filterData = [];
			years?.forEach((item) => {
				if (state[item.key]) {
					filterData.push(item.key);
				}
			});
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/postFeeStructure', {
				...(loadFee ? { dues: stateConst?.list?.dues?.filter((x) => filterData.includes(x.year)) } : { dues: stateConst?.list?.dues }),
				collegeId,
				loadFee,
				userId,
				classId: classId,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setClassId('');
				setStateConst({ list: { dues: [] } });
				setState({});
				// onClose();
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
			setIsLoading(false);
		}
	};

	let feeDataStructure = [
		{
			title: 'First Year',
			value: stateConst?.list?.dues?.filter((x) => x.year === '1y'),
			sum: sumWithKey(
				stateConst?.list?.dues?.filter((x) => x.year === '1y'),
				'amount',
			),
			year: '1y',
		},
		{
			title: 'Second Year',
			value: stateConst?.list?.dues?.filter((x) => x.year === '2y'),
			sum: sumWithKey(
				stateConst?.list?.dues?.filter((x) => x.year === '2y'),
				'amount',
			),
			year: '2y',
		},
		{
			title: 'Third Year',
			value: stateConst?.list?.dues?.filter((x) => x.year === '3y'),
			sum: sumWithKey(
				stateConst?.list?.dues?.filter((x) => x.year === '3y'),
				'amount',
			),
			year: '3y',
		},
		{
			title: 'Fourth Year',
			value: stateConst?.list?.dues?.filter((x) => x.year === '4y'),
			sum: sumWithKey(
				stateConst?.list?.dues?.filter((x) => x.year === '4y'),
				'amount',
			),
			year: '4y',
		},
		{
			title: 'Fifth Year',
			value: stateConst?.list?.dues?.filter((x) => x.year === '5y'),
			sum: sumWithKey(
				stateConst?.list?.dues?.filter((x) => x.year === '5y'),
				'amount',
			),
			year: '5y',
		},
		{
			title: 'Year not Specified',
			value: stateConst?.list?.dues?.filter((x) => x.year === undefined),
			sum: sumWithKey(
				stateConst?.list?.dues?.filter((x) => x.year === undefined),
				'amount',
			),
			year: undefined,
		},
	];

	console.log({ feeDataStructure });

	const updateAllDueDates = () => {
		const dataForClear = stateConst.list.dues;
		dataForClear.forEach((item) => {
			if (item.year === state?.year) {
				item.dueDate = new Date(state?.newDueDate)?.toUTCString();
			}
		});

		setStateConst({ list: { dues: dataForClear } });
	};

	const feesClearanceClass = async () => {
		try {
			setIsLoading(true);

			const instance = await getAxiosTokenInstance();
			const { data } = await instance.post('/college/action/postFeeClearence', {
				collegeId,
				classId,
			});

			if (data.statusCode !== 200) {
				throw new Error(data.message || 'API Error');
			}

			// setClassId('');
			// onClose();
		} catch (error) {
			const errorMessage = error?.response?.data?.message || error.message || 'API Error';
			message.error(errorMessage);
		} finally {
			setIsLoading(false);
		}
	};

	const FeeSummary = ({ feeData }) => {
		const groupTotals = calculateTotalAmountByGroup(feeData);
		const grandTotal = calculateGrandTotal(groupTotals);

		return (
			<>
				<div className='p-6 shadow-md flex bg-slate-50 justify-between items-center'>
					<div className='w-2/3'>
						<h4 className='text-1xl font-bold text-gray-800 mb-2'>Fee Summary</h4>
						<div className='space-y-0 w-[60%]'>
							{Object.entries(groupTotals).map(([mainAccountName, totalAmount], index) => (
								<div key={index} className='flex justify-between items-center p-2 border-b border-gray-200'>
									<span className='text-lg font-medium text-gray-600'>{mainAccountName}</span>
									<span className='text-lg font-bold text-gray-700'>{totalAmount.toFixed(2)} /-</span>
								</div>
							))}
						</div>
					</div>
					<div
						className='w-1/3 flex justify-center items-center rounded-md p-6 shadow-inner'
						style={{
							backgroundColor: isModalOpen ? 'white' : '#5affde',
						}}>
						<div className='text-center'>
							<span className='text-xl font-bold text-gray-700 block'>Total Amount</span>
							<span className='text-4xl font-extrabold text-gray-900 mt-2 block'>{grandTotal.toFixed(2)} /-</span>
						</div>
					</div>
				</div>
				<div className='h-1 bg-[#ff0000]'></div>
				<div className=' p-6  bg-white'>
					<h4 className='text-lg font-bold text-gray-800 mb-4'>Authorized Signatures</h4>
					<div className='grid grid-cols-4 gap-4'>
						<div className='flex flex-col items-center'>
							<div className='w-full h-[50px] border-b border-gray-800'></div>
							<span className='text-sm font-medium text-gray-700 mt-2'>Cashier</span>
						</div>

						<div className='flex flex-col items-center'>
							<div className='w-full h-[50px] border-b border-gray-800'></div>
							<span className='text-sm font-medium text-gray-700 mt-2'>Head Accountant</span>
						</div>
						<div className='flex flex-col items-center'>
							<div className='w-full h-[50px] border-b border-gray-800'></div>
							<span className='text-sm font-medium text-gray-700 mt-2'>Superintendent</span>
						</div>
						<div className='flex flex-col items-center'>
							<div className='w-full h-[50px] border-b border-gray-800'></div>
							<span className='text-sm font-medium text-gray-700 mt-2'>Principal</span>
						</div>
					</div>
				</div>
			</>
		);
	};

	const handlePrint = () => {};

	return (
		<Main title='Fees Structure Management' width='100%' height='100%'>
			<Header>
				<div className='flex-grow ml-[200px]'>
					<div className='flex justify-center  flex-wrap gap-2 w-full'>
						{feeDataStructure?.map((yearData, index) => (
							<div className='flex items-center p-1 bg-gray-100 rounded-lg m-1 mx-6'>
								<label className='mr-2 m-1'>
									<input type='checkbox' className='h-5 w-5 text-blue-600' checked={state[yearData.year]} onChange={() => setState((prev) => ({ ...prev, yearTitle: yearData.title, year: yearData.year, [yearData.year]: !prev[yearData.year] }))} />
								</label>
								<div className='text-blue-700 cursor-pointer px-2 hover:text-blue-800' onClick={() => setState((prev) => ({ ...prev, yearTitle: yearData.title, year: yearData.year, [yearData.year]: !prev[yearData.year] }))}>
									{yearData?.title}: {yearData?.sum}
								</div>
							</div>
						))}
					</div>
				</div>
				<div className='flex gap-1'>
					<Button width='200px' color='#007BFF' onClick={() => setIsModalOpen(!isModalOpen)}>
						{isModalOpen ? 'Back to Action' : 'Print Preview'}
					</Button>
					{isModalOpen && (
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>
					)}
					<Button
						width='100%'
						color='#fcb603'
						onClick={() => {
							setState('');
							setStateConst('');
						}}>
						Clear
					</Button>

					<Button width='100px' type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<div className='flex flex-col md:flex-row w-full h-full overflow-y-auto'>
				<div className='w-full md:w-1/5 p-4'>
					{loadFee ? (
						<div style={{ fontSize: 20, margin: 10 }}>{classData.className}</div>
					) : (
						<>
							<Input width='100%' didntShowKey fieldName='Select Class to Show Fee Structure' optionDisplay='className' optionKey='_id' state={classId} setState={setClassId} options={classList} type='drop' />
						</>
					)}
					<Button
						width='100%'
						color='#007BFF'
						onClick={() => {
							setConst();
						}}>
						Show Fee (SET)
					</Button>
					<div className='mt-[70%]'>
						<Input width='100%' didntShowKey fieldName='Copy Fee Structure from Other Class' optionDisplay='className' optionKey='_id' state={classIdCopy} setState={setClassIdCopy} options={classList} type='drop' />
						<Button
							width='100%'
							color='#357574'
							onClick={() => {
								copyConst();
							}}>
							Copy Now
						</Button>
					</div>
					<div className=''>
						{state?.year && (
							<>
								<div style={{ fontSize: 15, marginTop: '25%', marginLeft: '3%' }}>Change All {state?.yearTitle} Dues Date</div>
								<div>
									<Input width='100%' fieldName='New Due Date' returnKey='newDueDate' state={state} setState={setState} type='date' />
									<Button color='green' onClick={updateAllDueDates}>
										Update All {state?.yearTitle} Due Dates
									</Button>
								</div>
							</>
						)}
					</div>
				</div>
				<div className='w-full md:w-3/5 p-4 '>
					<Scrollbars>
						<div className='w-full h-full printable' ref={componentRef}>
							{feeDataStructure.map(
								(yearData, index) =>
									state[yearData.year] &&
									yearData?.value.length > 0 && (
										<div key={index} style={{ width: '100%', marginBottom: '40px' }} className='break-before-page'>
											<div className='flex items-center justify-center bg-[white] p-4 border border-black rounded-md print:border-none'>
												<h2 className='text-3xl font-bold text-black text-center print:text-black'>
													{state?.className}, {yearData.title}
												</h2>
											</div>

											<div className='self-center'>
												<div className='overflow-x-auto'>
													<table className='min-w-[100%] justify-center self-center '>
														<thead>
															<tr>
																<th className='text-center text-white bg-[#5a5a5a] w-[10%] p-2' style={{ fontSize: '15px' }}>
																	Sl.No.
																</th>
																<th style={{ fontSize: '15px' }} className='text-left text-white  bg-[#5a5a5a]  w-[15%] p-2'>
																	Group System
																</th>
																<th style={{ fontSize: '15px' }} className='text-left text-white bg-[#5a5a5a]  w-[15%] p-2'>
																	Heads
																</th>
																<th style={{ fontSize: '15px' }} className='text-left text-white bg-[#5a5a5a] w-[20%] p-2'>
																	Title
																</th>
																<th style={{ fontSize: '15px' }} className='text-center text-white bg-[#5a5a5a]  w-[15%] p-2'>
																	Amount
																</th>
																<th style={{ fontSize: '15px' }} className='text-center text-white bg-[#5a5a5a] w-[20%] p-2'>
																	Due Date
																</th>
																{!isModalOpen && (
																	<th style={{ fontSize: '15px' }} className='text-center text-white bg-[#5a5a5a]  w-[20%] p-2'>
																		Actions
																	</th>
																)}
															</tr>
														</thead>
														<tbody>
															{yearData?.value?.map((item, idx) => (
																<tr key={idx} className={`hover:bg-white ${item.elective === 'Discontinued' ? 'bg-red-300' : 'bg-white'}`}>
																	<td style={{ fontSize: '15px' }} className='p-2 text-center font-bold bg-white w-[10%]'>
																		{idx + 1}
																	</td>
																	<td style={{ fontSize: '15px' }} className='p-2 text-left font-bold bg-white w-[15%]'>
																		{item?.feeType?.slice(0, 12)}
																	</td>
																	<td style={{ fontSize: '15px' }} className='p-2 text-left font-bold bg-white w-[15%]'>
																		{item.mainAccountName?.slice(0, 12)}
																	</td>
																	<td style={{ fontSize: '15px' }} className='p-2 text-left font-bold bg-white w-[20%]'>
																		{item.subAccountName}
																	</td>
																	<td style={{ fontSize: '16px' }} className='p-2 text-center font-bold bg-white w-[15%]'>
																		{item.amount}
																	</td>
																	<td style={{ fontSize: '15px' }} className='p-2 text-center font-bold bg-white w-[20%]'>
																		{moment(item.dueDate).format('DD  MMM  YYYY')}
																	</td>
																	{!isModalOpen && (
																		<td style={{ fontSize: '15px' }} className='p-2 bg-white w-[20%]'>
																			<div className='flex gap-2'>
																				<Button width='100%' color='#FF0000' onClick={() => deleteItems(item._id)} className='text-red-500 hover:text-red-700'>
																					Delete
																				</Button>
																				<Button
																					width='100%'
																					color='#007BFF'
																					onClick={() => {
																						setState((prevState) => ({
																							...prevState,
																							feesType: item.feeType,
																							feesTypeval: item.feeType,
																							dueId: item.accountId,
																							_id: item._id,
																							feesYear: item.year,
																							feesYearval: item.year,
																							dueIdval: item.title,
																							amount: item.amount,
																							date: moment(item.dueDate).format('YYYY-MM-DD'),
																							[item.year]: prevState[item.year],
																						}));
																					}}>
																					Edit
																				</Button>
																			</div>
																		</td>
																	)}
																</tr>
															))}
														</tbody>
													</table>
												</div>
											</div>
											<FeeSummary feeData={yearData?.value} />
										</div>
									),
							)}
						</div>
					</Scrollbars>
				</div>
				<div className='w-full md:w-1/5 p-4'>
					{Array.isArray(stateConst?.list?.dues) && stateConst?.list?.dues?.length > -1 && (
						<>
							<div className='bg-indigo-600 ms-1 rounded-lg flex items-center justify-center'>
								<h2 className='text-lg font-semibold mt-2 text-white'>Add Dues Form</h2>
							</div>
							<Input width='100%' fieldName='Select Fees Type' returnKey='feesType' optionDisplay='name' optionKey='key' state={state} setState={setState} options={feesTypess} type='drop' />
							<Input width='100%' fieldName='Select Fees Year' returnKey='feesYear' optionDisplay='name' optionKey='key' state={state} setState={setState} options={years} type='drop' />
							<Input width='100%' fieldName='Due Date' returnKey='date' state={state} setState={setState} type='date' />
							<Input width='100%' fieldName='Select Account ' returnKey='dueId' needCheck={true} didntShowKey optionDisplay='label' optionKey='value' state={state} setState={setState} options={mapFeeAcounts} type='drop' />
							<Input width='100%' fieldName='Amount' returnKey='amount' state={state} setState={setState} type='number' />
							<Button
								width='100%'
								type='save'
								onClick={() => {
									feesClearence();
								}}
								children='Add Due'
							/>
						</>
					)}
				</div>
			</div>
			<div className='flex items-center justify-between'>
				<Button width='200px' type='fetch' onClick={() => feesClearanceClass()}>
					Clear All Fees
				</Button>
				<Button width='200px' type='save' onClick={() => onAddFees()}>
					Final Submit
				</Button>
			</div>
			{isLoading && (
				<div>
					<LoadingAnimation />
				</div>
			)}
		</Main>
	);
}
export default FeeStructure;
