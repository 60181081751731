import React, { useContext, useEffect, useRef, useState } from "react";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import LoadingAnimation from "../../components/LoadingAnimation";
import Input from "../../NecttosComp/Input/Input";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import { Table, Tbody, Td, Th, Tr, Thead, useSortableData } from "../../NecttosComp/Table/Table";
import { Select } from "antd";
import Button from "../../NecttosComp/Button/Button";
import { getMainAccounts } from "../../pages/college/ApiServices";

export const MainAccounts = ({ onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const [showForm, setShowForm] = useState(false);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  let { data = [], refetch } = useQuery("getMainAccounts", getMainAccounts(collegeId));
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});
  const [filteredData, setFilteredData] = useState(data);
  const [tableItems, setTableItems] = useState(filteredData);

  const manageMainAccounts = async (deleteData, _id) => {
    console.log(deleteData, _id);
    try {
      setIsLoading(true);
      if (!state.name && !_id) {
        throw new Error("Account Name is Empty");
      }
      const instance = await getAxiosTokenInstance();
      let resp;
      resp = await instance.post("/college/action/postMainAccounts", {
        collegeId,
        name: state?.name,
        category: state?.category,
        subCategory: state?.subCategory,
        priority: state?.priority,
        debitCredit: state?.debitCredit,
        showAccount: state?.showAccount,
        deleteData,
        _id: state?._id || _id,
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setState({
          ...state,
          name: "",
          category: "",
          subCategory: "",
          priority: "",
          showAccount: "",
          debitCredit: "",
          _id: "",
        });
        refetch();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    } finally {
      setIsLoading(false);
    }
  };

  data = data?.sort((a, b) => a?.name?.localeCompare(b?.name));

  const handleFilterChange = (value) => {
    if (value === "All") {
      setFilteredData(data);
      setTableItems(data);
    } else {
      const filtered = data.filter((item) => item.name === value);
      setFilteredData(filtered);
      setTableItems(filtered);
    }
  };
  const handleSearch = (value) => {
    const filteredOptions = data.filter((item) => {
      const name = item.name ? item.name.toLowerCase() : "";
      const category = item.category ? item.category.toLowerCase() : "";
      const subCategory = item.subCategory ? item.subCategory.toLowerCase() : "";
      const priority = item.priority ? item.priority.toLowerCase() : "";
      const debitCredit = item.debitCredit ? item.debitCredit.toLowerCase() : "";
      const showAccount = item.showAccount !== null ? String(item.showAccount).toLowerCase() : "";

      return name.includes(value.toLowerCase()) || category.includes(value.toLowerCase()) || subCategory.includes(value.toLowerCase()) || priority.includes(value.toLowerCase()) || debitCredit.includes(value.toLowerCase()) || showAccount.includes(value.toLowerCase());
    });
    setFilteredData(filteredOptions);
    setTableItems(filteredOptions);
  };

  useEffect(() => {
    setFilteredData(data);
    setTableItems(data);
  }, [data]);

  const { requestSort, getIcon } = useSortableData(filteredData);
  return (
    <Main zInd={100} width="100%" height="100%" title="Main Accounts Management">
      {isLoading ? (
        <LoadingAnimation dark />
      ) : (
        <div className="flex flex-col md:flex-row w-full h-full">
          <div className="flex flex-col md:w-1/5 w-full p-2">
            <Button type="update" width="100%" onClick={() => setShowForm(!showForm)}>
              {showForm ? "Hide Form" : "Add New"}
            </Button>
            {showForm && (
              <>
                <Input typable width="100%" didntShow fieldName="Account Name" returnKey="name" state={state} setState={setState} distinctKey="name" collectionName={"mainAccounts"} type="drop" />
                <Input typable width="100%" didntShow fieldName="Category" returnKey="category" state={state} setState={setState} distinctKey="category" collectionName={"mainAccounts"} type="drop" />
                <Input typable width="100%" didntShow fieldName="Sub Category" returnKey="subCategory" state={state} setState={setState} distinctKey="subCategory" collectionName={"mainAccounts"} type="drop" />
                <Input typable width="100%" didntShow fieldName="Priority Order" returnKey="priority" state={state} setState={setState} distinctKey="priority" collectionName={"mainAccounts"} type="drop" />
                <Input typable width="100%" didntShow fieldName="Debit/Credit" returnKey="debitCredit" state={state} setState={setState} distinctKey="debitCredit" collectionName={"mainAccounts"} type="drop" />
                <div className="flex">
                  <label className="w-full flex flex-row fs-5 fw-bold bg-slate-200 p-1 rounded-md text-center ms-1">
                    Show Account
                    <div className="mt-2">
                      <input type="checkbox" style={{ height: 15, width: 15, marginLeft: 30 }} checked={state.showAccount} onChange={() => setState({ ...state, showAccount: !state.showAccount })} />
                    </div>
                  </label>
                </div>
                <div className="flex flex-row gap-2">
                  <Button
                    width="100px"
                    color="red"
                    onClick={() => {
                      setState({
                        ...state,
                        name: "",
                        category: "",
                        subCategory: "",
                        priority: "",
                        showAccount: "",
                        debitCredit: "",
                        _id: "",
                      });
                    }}
                  >
                    Clear Form
                  </Button>
                  <Button
                    type="submit"
                    width="100px"
                    color="blue"
                    onClick={() => {
                      manageMainAccounts();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="md:w-4/5 w-full mt-2 h-full md:ml-4">
            <Header>
              <div className="flex">
                <Select showSearch style={{ width: 250, marginBottom: 16 }} placeholder="Search..." onChange={handleFilterChange} filterOption={false} onSearch={handleSearch}>
                  <Select.Option value="All">See All</Select.Option>
                  {data.map((account) => (
                    <Select.Option key={account._id} value={account.name}>
                      {account.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="flex">
                <Button type="print" tableRef={componentRef.current}>
                  Print
                </Button>
                <Button color="orange" onClick={() => window.location.reload()}>
                  Reload
                </Button>
                {!isLoading && (
                  <Button type="close" onClick={onClose}>
                    Close
                  </Button>
                )}
              </div>
            </Header>

            <div className="w-full h-[99%]" ref={componentRef}>
              <Thead>
                <Tr>
                  <Th position={0}>S.N</Th>
                  <Th position={2} onClick={() => requestSort("_id")} icon={getIcon("_id")}>
                    Account ID
                  </Th>
                  <Th position={1} onClick={() => requestSort("name")} icon={getIcon("name")}>
                    Account Name
                  </Th>
                  <Th position={1} onClick={() => requestSort("category")} icon={getIcon("category")}>
                    Main Category
                  </Th>
                  <Th position={1} onClick={() => requestSort("subCategory")} icon={getIcon("subCategory")}>
                    Sub Category
                  </Th>
                  <Th position={1} onClick={() => requestSort("priority")} icon={getIcon("priority")}>
                    Priority Order
                  </Th>
                  <Th position={1} onClick={() => requestSort("debitCredit")} icon={getIcon("debitCredit")}>
                    Debit/Credit
                  </Th>
                  <Th position={1} onClick={() => requestSort("showAccount")} icon={getIcon("showAccount")}>
                    Account Show
                  </Th>
                  <Th position={2}>Action</Th>
                </Tr>
              </Thead>
              <Body height="75%" width="100%">
                <div className="h-full w-full">
                  <Table width="100%" innerWidth={["5%", "10%", "15%", "15%"]}>
                    <Tbody style={{ display: "block", height: "400px", overflowY: "scroll" }}>
                      {tableItems?.length === 0 ? (
                        <Tr>
                          <Td colSpan={9} className="text-center">
                            No Data Found
                          </Td>
                        </Tr>
                      ) : (
                        tableItems?.map((item, index) => (
                          <Tr key={index} style={{ display: "table", tableLayout: "fixed", width: "100%" }}>
                            <Td index={index} position={0} textAlign="center" fontSize="14px">
                              {index + 1}
                            </Td>
                            <Td index={index} position={2} textAlign="center" fontSize="14px">
                              {item._id}
                            </Td>
                            <Td index={index} position={1} textAlign="center" fontSize="14px">
                              {item.name}
                            </Td>
                            <Td index={index} position={1} textAlign="center" fontSize="14px">
                              {item.category}
                            </Td>
                            <Td index={index} position={1} textAlign="center" fontSize="14px">
                              {item.subCategory}
                            </Td>
                            <Td index={index} position={1} textAlign="center" fontSize="14px">
                              {item.priority}
                            </Td>
                            <Td index={index} position={1} textAlign="center" fontSize="14px">
                              {item.debitCredit}
                            </Td>
                            <Td index={index} position={1} textAlign="center" fontSize="14px">
                              {String(item.showAccount)}
                            </Td>
                            <Td index={index} position={2} textAlign="center" fontSize="14px">
                              <div className="flex justify-center gap-2">
                                <Button
                                  width="50px"
                                  type="edit"
                                  onClick={() => {
                                    setState({
                                      ...state,
                                      name: item?.name || "",
                                      category: item?.category || "",
                                      subCategory: item?.subCategory || "",
                                      priority: item?.priority || "",
                                      showAccount: item?.showAccount || "",
                                      debitCredit: item?.debitCredit || "",
                                      _id: item?._id || "",
                                    });
                                    setShowForm(true);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  width="50px"
                                  type="delete"
                                  onClick={() => {
                                    manageMainAccounts(true, item?._id);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </Td>
                          </Tr>
                        ))
                      )}
                    </Tbody>
                  </Table>
                </div>
              </Body>
            </div>
          </div>
        </div>
      )}
    </Main>
  );
};
