import React, { useEffect, useContext, useState } from "react";
import classNames from "./studentTable.module.scss";
import { Table, Popconfirm, Input } from "antd";
import Scrollbars from "react-custom-scrollbars";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useQuery } from "react-query";
import { getPenddingAdmission } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import moment from "moment";
import { Checkmark } from "react-checkmark";
import StudentData from "../StudentData";
import ModalWrapper from "../../../../components/ModalWrapper";
import GiveWay from "../GiveWay";
import Verification from "../Verification";

import { Button } from "antd";
import CallToken from "../CallToken";

function NodalVery({ refreshStatus, isTemp, onClose }) {
  const { collegeId, user } = useContext(FirebaseContext);

  let classId = "";
  let keyData = "nodal";

  const { data, refetch, status } = useQuery("getPenddingAdmissions", getPenddingAdmission(collegeId, classId, user.uid, keyData));
  const [state, setState] = useState({});

  let userList = data?.list?.filter((x) => x?.temp === (isTemp || undefined)) || [];

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [collegeId]);

  const Timer = ({ start, stop, date, style }) => {
    const [startTime, setStartTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
      let interval = null;

      if (isRunning) {
        interval = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isRunning]);

    const handleStart = (date) => {
      setStartTime(new Date(date));
      setCurrentTime(new Date());
      setIsRunning(true);
    };

    const handleStop = () => {
      setIsRunning(false);
      setStartTime(null);
      setCurrentTime(null);
    };

    useEffect(() => {
      if (start) {
        handleStart(date);
      }
    }, [start, date]);

    useEffect(() => {
      if (stop) {
        handleStop();
      }
    }, [stop]);

    const getElapsedTime = () => {
      if (!startTime || !currentTime) {
        return "00:00:00";
      }

      const elapsedMilliseconds = currentTime - startTime;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const hours = Math.floor(elapsedSeconds / 3600);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;

      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    return (
      <div>
        <label style={{ ...style }}>{getElapsedTime()}</label>
      </div>
    );
  };
  const [timerStart, setTimerStart] = useState(false);

  const handleStartTimer = () => {
    setTimerStart(true);
  };

  const handleStopTimer = () => {
    setTimerStart(false);
  };

  const [searchTerm, setSearchTerm] = useState("");

  let filteredUserList = userList;
  if (searchTerm) {
    filteredUserList = userList.filter((user) => user.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  let tableColumns = [
    {
      title: "Token",
      render: (_, item, i) => item?.tokenNumbers?.nodal,
      key: "number",
    },
    {
      title: "Dept V Time",
      render: (_, item) => <div style={{ alignContent: "center", cursor: "pointer", fontSize: 15, color: "red", fontWeight: 900 }}>{moment(item?.verification?.departmentend)?.format("DD - hh:mm A")}</div>,
      key: "number",
    },
    {
      title: "Name",
      render: (_, data) => <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "red", fontWeight: 900 }}>{data?.name.toUpperCase()}</div>,
    },
    {
      title: "Action",
      render: (_, data) => (
        <>
          <button
            // disabled={!(data?.percentageOfCompletion === 100) || state[data?._id + 'call']}
            onClick={() => {
              setState({ ...state, studentId: data._id, callToken: true, [data?._id + "call"]: true, [data?._id + "timeStamp"]: new Date() });
            }}
          >
            <div style={{ cursor: "pointer", width: 100, height: 50, borderRadius: 20, padding: 12, textAlign: "center", backgroundColor: state[data?._id + "call"] ? "red" : "green" }}>
              <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "white", fontWeight: 900 }}>{state[data?._id + "call"] ? "Called" : "Call"}</div>
            </div>
          </button>
        </>
      ),
    },
    {
      title: "Count Down",
      render: (_, data) => (data?.verification?.departmentVerifyOn ? state[data._id + "timeStamp"] && <Timer start={timerStart} style={{ fontSize: 30, fontFamily: "bold", color: "red", margin: 20 }} stop={!timerStart} date={state[data._id + "timeStamp"]} /> : "Temporory"),
    },

    {
      title: "Action",
      render: (_, data) => (
        <button
          disabled={!state[data._id + "call"]}
          onClick={() => {
            setState({ ...state, viewMode: true, studentId: data._id });
          }}
          style={{ cursor: "pointer", width: 100, height: 50, borderRadius: 20, padding: 10, textAlign: "center", backgroundColor: state[data._id + "call"] ? "green" : "grey" }}
        >
          <div>
            <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "white", fontWeight: 800 }}>View</div>
          </div>
        </button>
      ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          disabled={state[data._id + "proceed"]}
          onClick={() => {
            setState({ ...state, giveWay: true, studentId: data._id });
          }}
          style={{ cursor: "pointer", width: 100, height: 50, borderRadius: 20, padding: 10, textAlign: "center", backgroundColor: !state[data._id + "proceed"] ? "green" : "grey" }}
        >
          <div>
            <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "white", fontWeight: 800 }}>Give</div>
          </div>
        </button>
      ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          disabled={!data.temp && !state[data._id + "proceed"]}
          onClick={() => {
            setState({ ...state, verification: true, studentId: data._id, isTemp: data?.temp });
          }}
          style={{ cursor: "pointer", width: 190, height: 50, borderRadius: 20, padding: 10, textAlign: "center", backgroundColor: data.temp || state[data._id + "proceed"] ? "green" : "grey" }}
        >
          <div>
            <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "white", fontWeight: 800 }}>Verify & Finish</div>
          </div>
        </button>
      ),
    },

    {
      title: "Course",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "number",
    },
    {
      title: "RC",
      dataIndex: "admType",
      key: "admType",
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "number",
    },
  ];

  return (
    <>
      <div className={classNames.container}>
        <div className={classNames.modal}>
          <div className={classNames.tableView} style={{ backgroundColor: "white" }}>
            <div className={classNames.tableHeader}>
              <h2>{collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F" ? "Department Senior Verification" : "Nodal Verification"}</h2>
              <Input style={{ width: 500, height: 50, margin: 5, marginRight: 200 }} placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
            {status === "loading" && <LoadingAnimation dark />}
            {status === "success" && (
              <Scrollbars className={classNames.tableWrap}>
                <div className={classNames.userTable}>
                  <Table columns={tableColumns} dataSource={filteredUserList && filteredUserList.length > 0 ? filteredUserList : []} pagination={false} />
                </div>
              </Scrollbars>
            )}
          </div>
          {state.viewMode && (
            <ModalWrapper>
              <StudentData uid={state?.studentId} isStudent={true} keyData={keyData} TimerFuncion={Timer} stateData={state} setStateData={setState} />
            </ModalWrapper>
          )}
          {state.giveWay && (
            <ModalWrapper>
              <GiveWay uid={state?.studentId} isStudent={true} keyData={keyData} TimerFuncion={Timer} stateData={state} setStateData={setState} />
            </ModalWrapper>
          )}
          {state.callToken && (
            <ModalWrapper>
              <CallToken uid={state?.studentId} isStudent={true} keyData={keyData} handleStartTimer={handleStartTimer} TimerFuncion={Timer} stateData={state} setStateData={setState} />
            </ModalWrapper>
          )}
          {state.verification && (
            <ModalWrapper>
              <Verification uid={state?.studentId} isTemp={state?.isTemp} isStudent={true} keyData={keyData} refectData={refetch} TimerFuncion={Timer} stateData={state} setStateData={setState} />
            </ModalWrapper>
          )}
        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
}

export default NodalVery;
