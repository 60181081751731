import React, { useContext, useEffect, useState } from "react";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import SearchableUserInputPhoto from "../../myComp/SearchableUserInputPhoto";
import { getProfile } from "../../queryHooks";
import { getCollegeDetails } from "../Form/Register";
import { getOpenCourseDetails } from "../../pages/college/ApiServices";

interface invoiceManagementProps {
  onClose: () => void;
}

function ElectiveChanger({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>({});
  const { collegeId, user } = useContext(FirebaseContext);
  const [values, setValues] = useState<any>({});
  const { data, refetch } = useQuery([`profileData`, collegeId, addNew?._id], getProfile);
  const { data: classes } = useQuery([`collegeData`, collegeId], getCollegeDetails);

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);
  const { data: subjectDetails } = useQuery(["allSubjedddctsDATA", "OC-7D9F34CFA927-4CCB-99FB-44E9808B067B"], getOpenCourseDetails(collegeId, user.uid, "OC-7D9F34CFA927-4CCB-99FB-44E9808B067B"));

  let subjects = subjectDetails?.[0]?.subjects;

  const addNewForm = async () => {
    const instance = await getAxiosTokenInstance();
    const selectedOne = subjects.find((x: any) => x._id === values.subjectId);

    try {
      const resp = await instance.post("/college/openCourse/electiveChanger", {
        studentId: values?._id,
        collegeId,
        subjectId: values.subjectId,
        userId: user?.uid,
        incomingSubject: {
          subjectId: values.subjectId,
          students: [],
          teachers: selectedOne?.teachers.map((x: any) => ({
            teacherId: x.teacherId,
            workLoad: x.workLoad,
          })),
        },
      });
      if (resp?.data?.statusCode === 200) {
        setAddNew({});
      }
    } catch (error) {}
  };

  return (
    <>
      <Main title="Applications Initiated" width="100%" height="100%">
        <Header>
          <div className=" flex w-50%">
            <SearchableUserInputPhoto primoryKey={"applicationNo"} secondaryKey={"name"} tertiaryKey={"phoneNumber"} collection={"users"} stateData={addNew} setStateData={setAddNew} titleData={"Search Admision No or Name"} />
          </div>
          <div className="flex">
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        {addNew?._id && (
          <Body>
            <div className="max-w-[800px">
              <div className="flex flex-grow self-end">
                <Input fieldName="Priority 1" setState={setValues} state={values} didntShowKey returnKey="subjectId" optionDisplay="name" optionKey="_id" options={subjects} type="drop" width="500px" />
                <Button
                  width="150px"
                  height="60px"
                  fontSize="20px"
                  type="save"
                  onClick={() => {
                    addNewForm();
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Body>
        )}
      </Main>
    </>
  );
}

export default ElectiveChanger;
