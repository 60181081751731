import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import moment from "moment";
import SearchableUserInputPhoto from "../../myComp/SearchableUserInputPhoto";
import { getProfile } from "../../queryHooks";

// const getExaminationDetails = (collegeId: any) => async () => {
//   const instance = await getAxiosTokenInstance();
//   try {
//     const resp = await instance.get("/college/examProcess/getExamInit", {
//       params: { collegeId },
//     });
//     if (resp.data.statusCode === 400) {
//       throw new Error(resp.data.message || "API Error");
//     } else {
//       return resp?.data?.list;
//     }
//   } catch (error) {
//     throw new Error("API Error");
//   }
// };

const Modal = ({ isOpen,onClose, children }:any) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded p-4 w-1/2">
        {children}
        {/* <button onClick={onClose} className="bg-red-500 text-white rounded px-4 py-2 mt-4">
          Close
        </button> */}
      </div>
    </div>
  );
};

interface invoiceManagementProps {
  onClose: () => void;
}

function AnswerSheetBundleManagement({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>({});
  // const [showForm, setShowForm] = useState(false);
  const { collegeId, user } = useContext(FirebaseContext);
  const [values, setValues] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [bundleData, setBundleData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef<any>(null);
  // const [selectedBatchSem, setSelectedBatchSem] = useState<any>([]);
  const { data } = useQuery([`profileData`, collegeId, addNew?._id], getProfile);
  const [search,setsearch]=useState('')
  const [mode,setMode]=useState('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>(null);

  const initialFormData={
    startNumber:'',
    endNumber:'',
    description:"",
    teacherId:''
  }
 
  const [formData,setFormData]=useState(initialFormData)

  useEffect(() => {
    if (data) {
      setValues(data);
      setFormData({...formData,teacherId:data.uid})
    }
  }, [data]);

  console.log("prof", values)
  // console.log("user", user)

  // const { data: tableData = [], refetch } = useQuery("getExamInit", getExaminationDetails(collegeId));

  // const fields = [
  //   { id: "attendanceClosingDate", label: "Attendance Closing Date" },
  //   { id: "examNotificationDate", label: "Examination Notification Date" },
  //   { id: "condonationFeeDate", label: "Condonation with Fee Apply Date" },
  //   { id: "condonationLateFeeDate", label: "Condonation with Late Fee Apply Date" },
  //   { id: "examRegistrationStartDate", label: "Examination Registration Start Date" },
  //   { id: "registrationFineDate", label: "Examination Registration with Fine Date" },
  //   { id: "registrationSuperFineDate", label: "Examination Registration with Super Fine Date" },
  //   { id: "timeTableDistributionDate", label: "Time Table Distribution Date" },
  //   { id: "hallTicketDistributionDate", label: "Hall Ticket Distribution Date" },
  // ];

  // const addNewForm = async (mode?: any, ddd?: any) => {
  //   try {
  //     const instance = await getAxiosTokenInstance();
  //     instance
  //       .post("/college/examProcess/postExamInit", { ...addNew, _id: ddd, mode, userId: user.uid, collegeId, batches: selectedBatchSem })
  //       .then(() => {
  //         setAddNew({});
  //         refetch();
  //       })
  //       .catch((err: any) => {
  //         console.error(err);
  //       });
  //     setTimeout(() => setShowForm(false), 500);
  //   } catch (err) {
  //     throw new Error("API Error");
  //   }
  // };

  // const handleInputChange = (event: any) => {
  //   const { id, value } = event.target;
  //   setAddNew((prevState: any) => ({
  //     ...prevState,
  //     [id]: value,
  //   }));
  // };

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   addNewForm();
  //   console.log("Form Data:", addNew);
  // };

  // const batch_array: any[] = ["2024-2028:S1", "2024-2026:S1", "2024-2029:S1", "2024-2027:S1"];

  // const removeSubject = (subject: any) => {
  //   setSelectedBatchSem(selectedBatchSem.filter((s: any) => s !== subject));
  // };
  // const handleSubjectChange = (event: any) => {
  //   const value = event.target.value;
  //   if (!selectedBatchSem?.includes(value)) {
  //     setSelectedBatchSem([...(selectedBatchSem || []), value]);
  //   }
  // };

 
  const handleFormChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prv) => ({
      ...prv,
      [name]: value
    }));
  };

  const postAnswerSheetBundleManagement=async ()=>{
    try {
      setIsLoading(true)
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postAnswerSheetBundleManagement", { ...formData, userId: user.uid,mode, teacherId:values.uid,collegeId })
        .then(() => {
          setAddNew({});
          setFormData(initialFormData)
          setRefresh(!refresh)
          setIsLoading(false)
          // refetch();
        })
        .catch((err: any) => {
          setIsLoading(false)
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  }
  const updateAnswerSheetBundleManagement=async (id:any)=>{
    try {
      setIsLoading(true)
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postAnswerSheetBundleManagement", { ...formData, userId: user.uid,mode, teacherId:values.uid,_id:id })
        .then(() => {
          setRefresh(!refresh)
          setIsLoading(false)
          setIsModalOpen(false)
          setFormData(initialFormData)

          // setAddNew({});
          // refetch();
        })
        .catch((err: any) => {
          setIsLoading(false)
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  }
  const deleteAnswerSheetBundleManagement=async (id:any)=>{
    try {
      setIsLoading(true)
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postAnswerSheetBundleManagement", {mode:"delete",_id:id })
        .then(() => {
          setRefresh(!refresh)
          setIsLoading(false)

          // setAddNew({});
          // refetch();
        })
        .catch((err: any) => {
          console.error(err);
          setIsLoading(false)
        });
    } catch (err) {
      throw new Error("API Error");
    }
  }

  const handleFormSubmit= async (e:any)=>{
    e.preventDefault()
    console.log("formData", formData);
    postAnswerSheetBundleManagement()
  }
  const getBundleData= async ()=>{
    setIsLoading(true)
    console.log("formData", formData);
    try {
      const instance = await getAxiosTokenInstance();
      instance
      .get("/college/examProcess/getAnswerSheetBundleManagement",{params:{collegeId}})
      .then((res: any) => {
        console.log("bundleData",res.data);
        setBundleData(res.data.list);
        setIsLoading(false)

        // setBundleData(res?.data?.data?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name)));
      })
      .catch((err: any) => {
        setIsLoading(false)
        console.log(err);
      });
    } catch (err) {
      throw new Error("API Error Bundle Data");
    }
  }

  useEffect(()=>{
  getBundleData()
  },[refresh])

  const handleEditClick = (item: any) => {
    setEditItem(item);
    setFormData(item);
    setIsModalOpen(true);
  };

  const handleUpdateSubmit = (e: any) => {
    e.preventDefault();
    updateAnswerSheetBundleManagement(editItem._id);
  };

  const handleClose=()=>{
    setFormData(initialFormData)
    setIsModalOpen(false)
  }

  return (
    <>
      <Main title="Exam Sheet Bundle Management" width="100vw" height="100vw">
        <Header>
        <div className=" flex w-50%">
            <SearchableUserInputPhoto primoryKey={"applicationNo"} secondaryKey={"name"} tertiaryKey={"phoneNumber"} collection={"users"} stateData={addNew} setStateData={setAddNew} titleData={"Search Admision No or Name"} />
            {/* <SearchableUserInputPhoto primoryKey={"name"} secondaryKey={"applicationNo"} tertiaryKey={"phoneNumber"} collection={"users"} stateData={addNew} setStateData={setAddNew} titleData={"Search Admision No or Name"} /> */}
          </div>
          {/* <Button
            type="update"
            onClick={() => {
              setShowForm(true);
            }}
          >
            New Examination Init
          </Button> */}
          <div className="flex">
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          {values &&
        <div className="mt-2 font-bold">
  <div className="w-full h-56 bg-gray-200 rounded flex">
    <div className="">

            <div className="sm:flex gap-2 pt-6">
              {/* <input type="text" value={values.uid} required/> */}
            <div className="flex px-4 gap-2 content-center">
            <label htmlFor="search text-lg fw-bold">Start Number</label>
            <input type="number" id="search" className="w-auto mb-2 transparent border rounded shadow " value={formData.startNumber} name="startNumber" onChange={handleFormChange}/>
            </div>
            <div className="flex px-4 gap-2 content-center">
            <label htmlFor="search text-lg fw-bold">End Number</label>
            <input type="number" id="search" className="w-auto mb-2 transparent border rounded shadow " value={formData.endNumber} name="endNumber" onChange={handleFormChange}/>
            </div>
            </div>
            <div className="p-4 flex items-center gap-4">
              <label htmlFor="description">Description</label>
              <input className="border w-[480px] h-8 shadow rounded" name="description" id="description" value={formData.description} onChange={handleFormChange}></input>
               </div>
               <div className=" flex px-4 gap-8 md:ml-[350px]">
               <button className="bg-red-200 w-full md:w-24 rounded" onClick={()=>setFormData(initialFormData)}>Cancel</button>
               <button disabled={isLoading || formData.teacherId!==""} className="bg-green-700 w-full md:w-24 rounded text-light" onClick={handleFormSubmit}>Assign</button>
               </div>
    </div>
    <div className="m-2 p-2">
      <img src={values?.photoURL} alt="photo" className="w-28 h-24 object-cover" />
      <p className="mt-4 text-center">{values?.name}</p>
    </div>
          </div>
        </div>
        }
        {/* {bundleData.length>0 &&
        <div>
          <table className="table-fixed bg-white border">
  <thead>
    <tr>
      <th>Song</th>
      <th>Artist</th>
      <th>Year</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
      <td>Malcolm Lockyer</td>
      <td>1961</td>
    </tr>
    </tbody>
    </table>
        </div>
        } */}
{
  bundleData.length>0 &&
  <div className="my-2">
    <Table width="100%" innerWidth={["100px", "100px","100px", "100px","100px", "200px"]}>
              <Thead>
                <Tr>
                  <Th position={1}>SN</Th>
                  <Th position={1}>Start No.</Th>
                  <Th position={1}>End No.</Th>
                  <Th position={1}>Description</Th>
                  <Th position={1}>Teacher ID</Th>
                  <Th position={2}>Edit / Delete</Th>
                
                </Tr>
              </Thead>
              <Tbody height="auto">
                {bundleData?.map((item: any, i: number) => (
                  <Tr key={item._id}>
                    <Td index={i} position={1}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.startNumber}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.endNumber}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.description}
                    </Td>
                    <Td index={i} position={1}>
                      {item?.teacherName}
                    </Td>
                 
                    <Td index={i} position={2}>
                      <div className="flex gap-1">
                        <button
                        className="rounded bg-blue-400"
                          type="submit"
                          onClick={() => 
                            handleEditClick(item)
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="submit"
                          className="rounded bg-red-600 text-white"
                          onClick={() => {
                            setMode("delete");
                            deleteAnswerSheetBundleManagement(item._id)
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
  </div>
}
         
        </Body>
      </Main>
      <Modal isOpen={isModalOpen} onClose={handleClose}>
        <h2 className="text-xl mb-4">Edit Bundle</h2>
        <form onSubmit={handleUpdateSubmit}>
          <div className="mb-4">
            <label htmlFor="startNumber" className="block text-sm font-medium text-gray-700">Start Number</label>
            <input type="number" id="startNumber" name="startNumber" value={formData.startNumber} onChange={handleFormChange} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
          </div>
          <div className="mb-4">
            <label htmlFor="endNumber" className="block text-sm font-medium text-gray-700">End Number</label>
            <input type="number" id="endNumber" name="endNumber" value={formData.endNumber} onChange={handleFormChange} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
            <input type="text" id="description" name="description" value={formData.description} onChange={handleFormChange} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500" />
          </div>
          <div className="flex justify-end gap-1">
            <button type="submit" disabled={isLoading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Update
            </button>
            <button type="button" onClick={handleClose} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              Close
            </button>
          </div>
        </form>
      </Modal>
     
    </>
  );
}

export default AnswerSheetBundleManagement;
