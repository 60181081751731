import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import { EditDetails, getDropdownData } from "../../pages/college/ApiServices";
import Button from "../../NecttosComp/Button/Button";
import { Body, Footer, Header, Main, widthChangeAnimation } from "../../NecttosComp/Layout/Layout";
import Input from "../../NecttosComp/Input/Input";
import { useProfileData } from "../../queryHooks";
import { ProgressBar, calculateProfilepPercentage } from "../../NecttosComp/ProgressBar/ProgressBar";
import Loader from "../../NecttosComp/Loader/Loader";
import { ProfileDataDisplayKeyVlaue } from "../../FunctionalExport/Formate";

interface Props {
  onClose: () => void;
  studentId?: string | null | undefined;
}

function OfficialInfo({ onClose, studentId }: Props) {
  const [dropDowns, setDropDowns] = useState<any[]>([{}]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { collegeId } = useContext(FirebaseContext);
  const { data: profileData, refetch } = useProfileData();

  const [values, setValues] = useState({});

  useEffect(() => {
    setValues({
      stay: profileData.pendingData?.stay || profileData.stay || "",
      secondLanguage: profileData.pendingData?.secondLanguage || profileData.secondLanguage || "",
      capId: profileData.pendingData?.capId || profileData.capId || "",
      rollNo: profileData.pendingData?.rollNo || profileData.rollNo || "",
      lastSchoolName: profileData.pendingData?.lastSchoolName || profileData.lastSchoolName || "",
      lastTcNumber: profileData.pendingData?.lastTcNumber || profileData.lastTcNumber || "",
      lastTcDate: profileData.pendingData?.lastTcDate || profileData.lastTcDate || "",
      hallTicketNo: profileData.pendingData?.hallTicketNo || profileData.hallTicketNo || "",
      shortName: profileData.pendingData?.shortName || profileData.shortName || "",
      universityId: profileData.pendingData?.universityId || profileData.universityId || "",
      penNumber: profileData.pendingData?.penNumber || profileData.penNumber || "",
      dateOfJoin: profileData.pendingData?.dateOfJoin || profileData.dateOfJoin || "",
      designationStatus: profileData.pendingData?.designationStatus || profileData.designationStatus || "",
      designation: profileData.pendingData?.designation || profileData.designation || "",
    });
  }, [profileData]);

  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
      console.log(val);
    };
    getDropDowns();
  }, []);

  const [placeHoldValues, setPlaceHolderValues] = useState<{}>({});
  let userRole: string = "";
  type CollegeRole = {
    type: string;
  };

  const rolesArray: string[] = ["student", "teacher", "staff"];
  userRole = rolesArray.find((element) => profileData.collegeRoles.some((x: CollegeRole) => x.type === element)) || userRole;

  const staffKeys = {
    penNumber: "Pen Number",
    dateOfJoin: "Date Of Join",
    designationStatus: "Designation Status",
    designation: "Designation",
  };

  const studentsKeys = {
    stay: "Stay",
    secondLanguage: "Second Language",
    capId: "Cap Id",
    rollNo: "Roll No",
    lastSchoolName: "Last School Name",
    lastTcNumber: "Last TC Number",
    lastTcDate: "Last Tc Date",
    hallTicketNo: "Hall Ticket Number",
    shortName: "Short Name",
  };

  const teacherKeys = {
    shortName: "Short Name",
    universityId: "University Id",
    penNumber: "Pen Number",
    dateOfJoin: "Date Of Join",
    designationStatus: "Designation Status",
    designation: "Designation",
  };

  useEffect(() => {
    if (userRole === "student") {
      setPlaceHolderValues(studentsKeys);
    } else if (userRole === "teacher") {
      setPlaceHolderValues(teacherKeys);
    } else if (userRole === "staff") {
      setPlaceHolderValues(staffKeys);
    } else {
      setPlaceHolderValues(staffKeys);
    }
  }, [userRole]);

  return (
    <>
      <Main title="Official Information" height="90vh" width={"60vw"}>
        <Header width="100%" height="6%" title="Profile">
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="print" children="Print" />
            <Button type="pdf" pdfId="accounts" children="PDF" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body width="100%" height="85%">
          <div className="grid grid-flow-col gap-6">
            <div className="px-3 py-2 w-[28vw]  ">
              <ProgressBar title="Verified Data" progress={parseInt(calculateProfilepPercentage(profileData, placeHoldValues)?.percentage) || 0} />

              <ProfileDataDisplayKeyVlaue temp={profileData} placeHoldValues={placeHoldValues} title="Verified Data" />
              {!isEditing ? (
                <Button
                  onClick={async () => {
                    widthChangeAnimation("90vw");
                    setIsEditing(!isEditing);
                  }}
                  type="update"
                >
                  Edit
                </Button>
              ) : null}
            </div>
            <div>
              <ProgressBar title="are Updated" progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
              <ProfileDataDisplayKeyVlaue temp={profileData?.pendingData} placeHoldValues={placeHoldValues} title="Updated data" needVerification={true} />
            </div>

            {isEditing && (
              <div>
                <h5 className="text-blue-900 text-[1.6vw] font-bold">You can edit your data here !</h5>
                <div id="scrollBarEnable" className="px-3 py-2 w-[28vw] z-50 overflow-auto  max-h-[65vh]">
                  {userRole === "student" && (
                    <>
                      <Input width="98%" fieldName="Stay" returnKey="stay" options={dropDowns[14]?.[collegeId || "stayWhere"] || dropDowns[14]?.["stayWhere"]} setState={setValues} state={values} type="drop" />
                      <div className="relative z-[8]">
                        <Input width="98%" fieldName="Second Language" returnKey="secondLanguage" options={dropDowns[11]?.[collegeId || "secondLanguage"]} setState={setValues} state={values} type="drop" />
                      </div>
                      <Input width="98%" fieldName="Cap Id" returnKey="capId" setState={setValues} state={values} type="text" />
                      <Input width="98%" fieldName="Roll No" returnKey="rollNo" setState={setValues} state={values} type="text" />
                      <Input width="98%" fieldName="Last School Name" returnKey="lastSchoolName" setState={setValues} state={values} type="text" />
                      <Input width="98%" fieldName="Last TC Number" returnKey="lastTcNumber" setState={setValues} state={values} type="text" />
                      <Input width="98%" fieldName="Last TC Date" returnKey="lastTcDate" setState={setValues} state={values} type="date" />
                      <Input width="98%" fieldName="Hall Ticket Number" returnKey="hallTicketNo" setState={setValues} state={values} type="text" />
                    </>
                  )}

                  {userRole === "teacher" && (
                    <>
                      <Input width="98%" fieldName="Short Name" returnKey="shortName" setState={setValues} state={values} type="text" />
                      <Input width="98%" fieldName="University Id" returnKey="universityId" setState={setValues} state={values} type="text" />
                    </>
                  )}

                  <Input width="98%" fieldName="Pen Number" returnKey="penNumber" setState={setValues} state={values} type="text" />
                  <Input width="98%" fieldName="Date Of Join" returnKey="dateOfJoin" setState={setValues} state={values} type="date" />
                  <div className="relative z-[1]">
                    <Input width="98%" fieldName="Designation Status" returnKey="designationStatus" options={dropDowns[8]?.designationStatus?.teacher} setState={setValues} state={values} type="drop" />
                  </div>
                  <div className="relative z-[0]">
                    <Input width="98%" fieldName="Designation" returnKey="designation" options={dropDowns[8]?.designation?.teacher} setState={setValues} state={values} type="drop" />
                  </div>
                </div>

                <ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
                <Button
                  width="100%"
                  onClick={async () => {
                    setIsLoading(true);
                    await widthChangeAnimation("60vw");
                    await EditDetails(values);
                    await refetch();
                    setIsEditing(!isEditing);
                    setIsLoading(false);
                  }}
                  type="save"
                  children="Save"
                />
              </div>
            )}
          </div>
        </Body>
        <Footer width="100%" />
      </Main>
      {isLoading && <Loader />}
    </>
  );
}

export default OfficialInfo;
