import React, { useContext, useState } from 'react';
import LoadingAnimation from '../../components/LoadingAnimation';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Main } from '../../NecttosComp/Layout/Layout';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import Input from '../../NecttosComp/Input/Input';
import Button from '../../NecttosComp/Button/Button';
import TextArea from 'antd/lib/input/TextArea';
import { getStudentsFromRoles } from '../../queryHooks';
import { useQuery } from 'react-query';

export const AttendanceCorrectionBulk = ({ onClose }) => {
	const { collegeId, user } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(false);
	const [values, setValues] = useState({
		admissionNumber: '',
		reason: '',
		timeFrom: '09:00',
		type: 'rollNo',
		timeTo: '17:00',
		dateFrom: '',
		dateTo: '',
	});

	const handleChange = (key, value) => {
		setValues((prevValues) => ({ ...prevValues, [key]: value }));
	};

	const { data } = useQuery([`getStudentsFromRoles`, collegeId, values?.admissionNumber, values?.type], getStudentsFromRoles);
	console.log({ data });

	const handleExport = async () => {
		setLoading(true);
		const { admissionNumber, reason, dateFrom, timeFrom, dateTo, timeTo, type } = values;
		if (!admissionNumber || !reason || !dateFrom || !timeFrom || !dateTo || !timeTo) {
			alert('Please fill all the fields');
			setLoading(false);
			return;
		}
		try {
			const instance = await getAxiosTokenInstance();
			const res = await instance.post('/college/attendance/take/officeAttendanceCorrection', {
				uid: user.uid,
				selected: admissionNumber,
				type,
				collegeId,
				dateTimeFrom: `${dateFrom} ${timeFrom}`,
				dateTimeTo: `${dateTo} ${timeTo}`,
				reason,
			});
			if (res.statusCode === 200) {
				onClose();
			}
			console.log(res.data);
		} catch (err) {
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main height='80%' width='80%' title='Bulk Attendance Correction'>
			{loading ? (
				<LoadingAnimation dark />
			) : (
				<div className='flex flex-wrap'>
					<div className='w-full md:w-[50%] p-4 space-y-6'>
						<div>
							<label htmlFor='type' className='block text-sm font-medium text-gray-700'>
								Select Type
							</label>
							<select id='type' value={values.type} onChange={(e) => handleChange('type', e.target.value)} className='mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm h-12'>
								<option value='rollNo'>Roll Number</option>
								<option value='admisionNo'>Admission Number</option>
								<option value='phoneNumber'>Phone Number</option>
							</select>
						</div>

						<TextArea placeholder='Enter roll numbers, separated by commas' className='fs-3' autoSize={{ minRows: 3, maxRows: 12 }} value={values.admissionNumber} onChange={(e) => handleChange('admissionNumber', e.target.value)} />

						<TextArea placeholder='Enter reason for attendance correction' className='fs-3' autoSize={{ minRows: 4, maxRows: 8 }} value={values.reason} onChange={(e) => handleChange('reason', e.target.value)} />

						<div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
							<Input width='100%' fieldName='Date From *' returnKey='dateFrom' state={values} setState={setValues} type='date' value={values.dateFrom} />
							<Input width='100%' fieldName='Date To *' returnKey='dateTo' state={values} setState={setValues} type='date' value={values.dateTo} />
						</div>

						<div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
							<Input width='100%' fieldName='Time From *' returnKey='timeFrom' state={values} setState={setValues} type='time' value={values.timeFrom} />
							<Input width='100%' fieldName='Time To *' returnKey='timeTo' state={values} setState={setValues} type='time' value={values.timeTo} />
						</div>

						<div className='flex justify-between mt-6'>
							<Button type='save' onClick={handleExport}>
								Download
							</Button>
							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</div>

					<div className='w-full md:w-[50%] p-4 space-y-4 bg-slate-50 rounded-lg'>
						<span className='text-lg font-semibold'>Selected : {data?.message}</span>
						<span className='block text-sm text-gray-600'>Selected Numbers: {data?.filteredNumbers}</span>

						<div className='space-y-4'>
							{data?.data?.map((item, index) => (
								<div key={index} className='flex items-center space-x-4 border-b pb-4'>
									<img src={item.photoURL} alt={item.name} className='w-16 h-16 rounded-full object-cover' />
									<div className='flex flex-col'>
										<span className='text-md font-medium'>{item.name}</span>
										<span className='text-sm text-gray-500'>Roll No: {item.rollNo ?? 'Please select'}</span>
										<span className='text-sm text-gray-500'>Admn No: {item.admissionNo ?? 'Please select'}</span>
										<span className='text-sm text-gray-500'>{item.className ?? 'Please select'}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</Main>
	);
};
