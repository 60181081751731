import React, { useContext, useState } from "react";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { Main } from "../../../../../NecttosComp/Layout/Layout";
import Button from "../../../../../NecttosComp/Button/Button";
import { FirebaseContext } from "../../../../../context/FirebaseContext";

function ObjectiveEdit({ data, onClose }) {
  const [val, setVal] = useState(data);
  const { collegeId } = useContext(FirebaseContext);

  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/subject/addSubjects", {
          syllabus: {
            internalMark: val.internalMark,
            externalMark: val.externalMark,
            examHour: val.examHour,
            lectureHour: val.lectureHour,
            credit: val.credit,
            objectives: val.objectives,
            references: val.references,
          },
          partA: val.partA,
          partB: val.partB,
          partC: val.partC,
          shortName: val.shortName,
          name: val.name,
          year: val.year,
          subjectCode: val.subjectCode,
          collegeId,
          id: data?._id,
        })
        .then(() => {
          onClose();
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };

  return (
    <Main width="auto" height="auto">
      <div className="bg-gray-100 p-4 sm:p-6 md:max-w-4xl lg:max-w-5xl xl:max-w-6xl mx-auto rounded-lg shadow-lg">
        <div className="flex justify-end mb-4">
          <Button type="close" onClick={onClose} className="text-white bg-red-500 hover:bg-red-600 font-medium py-2 px-4 rounded">
            Close
          </Button>
        </div>

        <div className="space-y-6">
          <h1 className="text-2xl font-bold text-center text-gray-800">Edit Subject</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="objective" className="block text-sm font-medium text-gray-700">
                Objective
              </label>
              <textarea value={val?.objectives} id="objective" rows="10" className="mt-1 block w-full p-2.5 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" onChange={(e) => setVal({ ...val, objectives: e.target.value })} placeholder="Enter objective details"></textarea>
            </div>
            <div>
              <label htmlFor="references" className="block text-sm font-medium text-gray-700">
                References
              </label>
              <textarea value={val?.references} id="references" rows="10" className="mt-1 block w-full p-2.5 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500" placeholder="Enter references details" onChange={(e) => setVal({ ...val, references: e.target.value })}></textarea>
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <Button
              type="save"
              onClick={() => {
                addNewForm();
              }}
              className="text-white bg-blue-500 hover:bg-blue-600 font-medium py-2 px-4 rounded"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Main>
  );
}

export default ObjectiveEdit;
