import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import Faq from '../../components/Website/faq';
import GetInTouch from '../../components/Website/get-in-touch';
import Subscribe from '../../components/Website/subscribe';
import FooterBar from '../../components/Website/footerBar';

const FaqPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="FAQ" />
        <Faq customclass="pd-top-120 " />
        <GetInTouch />
        <Subscribe />
        <FooterBar />
    </div>
}

export default FaqPage

