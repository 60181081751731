import React, { useContext, useEffect, useRef, useState } from "react";
import "./C2_1.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { AcademicYears, naacExcelGenerator, naacFetchData } from "../../ApiServices";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../NecttosComp/Table/Table";
import { naacGET } from "../../../../NecttosComp/APICaller/APICaller";
import { useQuery } from "react-query";

function C2_5({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [academicYear, setAcademicYear] = useState("2022-23");
  const [analyze, setAnalyze] = useState();
  const ref = useRef(null);
  const { collegeId } = useContext(FirebaseContext);

  const { data: values = [] } = useQuery(["criteria2_1_2", academicYear], naacGET({ endPoint: "criteria2_1_2", params: { collegeId, academicYear } }));

  // useEffect(() => {
  //   naacFetchData({
  //     data: { collegeId, academicYear },
  //     url: "/college/NAAC/getNAAC_C2_1_2",
  //     setLoading,
  //     setValues,
  //   }).catch((err) => {
  //     setLoading(false);
  //   });
  // }, []);
  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]">
          <LoadingAnimation />
        </div>
      ) : null}
      <Main width="80vw" height="80vh" title=" 2.1.2 Number of seats filled against seats reserved for various categories.">
        <Header>
          <div className="flex">
            <AcademicYears setAcademicYear={setAcademicYear} />
          </div>
          <div className="flex">
            <Button pdfId="emp" type="pdf">
              PDF
            </Button>
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div className="printable h-[60vh] overflow-auto" ref={ref}>

          <Table width="100%" id="emp" innerWidth={["10%", "9%", "45%", "45%"]}>
            <Thead>
              <Tr>
                <Th position={0}>Year</Th>
                <Th position={2}>Number of seats earmarked for reserved category as per HOI or State Government rule</Th>
                <Th position={2}> Number of students admitted from th reserved category</Th>
              </Tr>
              <Tr>
                <Th></Th>
                <Th position={1}>SC</Th>
                <Th position={1}>ST</Th>
                <Th position={1}>OBC</Th>
                <Th position={1}>Gen</Th>
                <Th position={1}>Others</Th>
                <Th position={1}>SC</Th>
                <Th position={1}>ST</Th>
                <Th position={1}>OBC</Th>
                <Th position={1}>Gen</Th>
                <Th position={1}>Others</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td position={0}>{values?.year}</Td>
                <Td position={1}>{values?.seats?.sc}</Td>
                <Td position={1}>{values?.seats?.st}</Td>
                <Td position={1}>{values?.seats?.obc}</Td>
                <Td position={1}>{values?.seats?.gen}</Td>
                <Td position={1}>{values?.seats?.other}</Td>
                <Td position={1}>{values?.admitted?.sc}</Td>
                <Td position={1}>{values?.admitted?.st}</Td>
                <Td position={1}>{values?.admitted?.obc}</Td>
                <Td position={1}>{values?.admitted?.gen}</Td>
                <Td position={1}>{values?.admitted?.other}</Td>
              </Tr>
            </Tbody>
          </Table>
          </div>
        </Body>
      </Main>
      <div className="  z-10 w-4/5 bg-slate-100 h-4/5 px-3 overflow-auto">
        <table className="table">
          <thead>
            <tr className=" bg-white">
              <th colSpan={11} style={{ background: "rgb(241 245 249)", border: "none" }}>
                <h1 className="text-xl flex justify-between sticky ">
                  2.1.2 Number of seats filled against seats reserved for various categories {"(SC,ST,OBC,Divyangjan,etc). as per applicable reservation policy during year (exclusive of supernumerary seats)"}
                  <div className="flex justify-center items-center">
                    <AcademicYears setAcademicYear={setAcademicYear} />

                    <button className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]" onClick={onClose}>
                      X
                    </button>
                  </div>
                </h1>
              </th>
            </tr>
            <tr>
              <th className="w-1/4 border" rowSpan={2}>
                Year
              </th>
              <th className="w-1/4 border" colSpan={5}>
                Number of seats earmarked for reserved category as per HOI or State Government rule
              </th>
              <th className="w-1/4 border" colSpan={5}>
                Number of students admitted from th reserved category
              </th>
            </tr>
            <tr>
              <th className="border">SC</th>
              <th className="border">ST</th>
              <th className="border">OBC</th>
              <th className="border">Gen</th>
              <th className="border">Others</th>
              <th className="border">SC</th>
              <th className="border">ST</th>
              <th className="border">OBC</th>
              <th className="border">Gen</th>
              <th className="border">Others</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{values?.year}</td>
              <td>{values?.seats?.sc}</td>
              <td>{values?.seats?.st}</td>
              <td>{values?.seats?.obc}</td>
              <td>{values?.seats?.gen}</td>
              <td>{values?.seats?.other}</td>
              <td>{values?.admitted?.sc}</td>
              <td>{values?.admitted?.st}</td>
              <td>{values?.admitted?.obc}</td>
              <td>{values?.admitted?.gen}</td>
              <td>{values?.admitted?.other}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default C2_5;
