import SignUpCollege from "../../components/SignUpCollege";
import FooterBar from "../../components/Website/footerBar";

const Register = (props) => {
    return (
        <div>
             <SignUpCollege />

             
             <FooterBar />
        </div>

    )
}
export default Register;