import React, { useContext, useEffect, useRef, useState } from 'react';
import { getDropdownData } from '../../../pages/college/ApiServices';
import Button from '../../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../../NecttosComp/Layout/Layout';
import Input from '../../../NecttosComp/Input/Input';
import {  useProfileEditNew } from '../../../queryHooks';
import { ProgressBar, calculateProfilepPercentage } from '../../../NecttosComp/ProgressBar/ProgressBar';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';
import Loader from '../../../NecttosComp/Loader/Loader';
import Loader1 from '../../../NecttosComp/Loaders/Loader';
import { ProfileDataDisplay, postProfileSubmission } from '../../../FunctionalExport/Formate';
import CSVEditor from '../../../NecttosComp/CSVEditor/CSVEditor';
import { createRandomId } from '../../../FunctionalExport/Utils';
import { FirebaseContext } from '../../../context/FirebaseContext';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
  modal:Boolean
}

function Qualification({ onClose, studentId, onNext,modal}: Props) {
	const titleKey = 'qualifications';

	const [dropDowns, setDropDowns] = useState<any[]>([{}]);

	const [temp, setTemp] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [csv, setCSv] = useState(false);
	const [isTick, setIsTick] = useState<any>(null);
	const componentRef = useRef<HTMLTableElement>(null);
	const [mode, setMode] = useState<any>(false);
	const [addNew, setAddNew] = useState<any>({});
	const { user, collegeId } = useContext(FirebaseContext);
	const { data: profileData, refetch: refetch } = useProfileEditNew({ titleKey, userId: user?.uid });
	const [values, setValues] = useState<any[]>(profileData);
	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const callProfileSubmission = async (keyData: string) => {
		await postProfileSubmission({
			keyData,
			values,
			temp,
			addNew,
			setIsLoading,
			setTemp,
			setMode,
			setAddNew,
			refetch,
			titleKey,
		});
	};

	const [qualification, setQualification] = useState<any>();

	const [keysValues, setKeysValues] = useState<any>({});
	const percentageNotAvaialable = ['Ph.D', 'PDF'];

	useEffect(() => {
		if (percentageNotAvaialable.includes(addNew?.type)) {
			setKeysValues({ type: '', course: '', yearOfPass: '', university: '', specialisation: '' });
		} else {
			setKeysValues({ type: '', course: '', yearOfPass: '', university: '', specialisation: '', percentage: '', registerNo: '' });
		}
	}, [addNew]);

	const selectedQualification = ({ value }: { value: string }) => {
		value = value.replace(/\./g, '');
		setQualification(dropDowns[4].qualification[0]?.[value]);
	};

	useEffect(() => {
		setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
	}, [profileData]);

	return (
		<>
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
            {!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div ref={componentRef} className='w-full printable'>

								<Table id={'profilePDF'} width='100%' innerWidth={['10%', '7%', '18%', '18%', '20%', '9%', '5%', '13%']}>
									<Thead>
										<Tr>
											<Th position={0} children='Qualification' />
											<Th position={1} children='Reg. No.' />
											<Th position={2} children='Course' />
											<Th position={3} children='Specialisation' />
											<Th position={4} children='University' />
											<Th position={5} children='Percentage' />
											<Th position={6} children='Year' />
											<Th position={7} children='Action' />
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{values?.map((x: any, index: any) => (
											<Tr>
												<Td position={0} index={index}>
													<p >{x?.type}</p>
												</Td>
												<Td position={1} index={index}>
													<p >{x?.registerNo}</p>
												</Td>
												<Td position={2} index={index}>
													<p >{x?.course}</p>
												</Td>
												<Td position={3} index={index}>
													<p >{x?.specialisation}</p>
												</Td>
												<Td position={4} index={index}>
													<p >{x?.university}</p>
												</Td>
												<Td position={5} index={index}>
													<p >{x?.percentage}</p>
												</Td>
												<Td position={6} index={index}>
													<p >{x?.yearOfPass}</p>
												</Td>
												<Td position={7} index={index}>
													<div className='flex'>
														<Button
															type='update'
															onClick={() => {
																setMode('edit');
																setAddNew(x);
																setTemp(x);
															}}>
															Edit
														</Button>
														{!x?.deleted && (
															<Button
																type='delete'
																onClick={() => {
																	setMode('delete');
																	setTemp(x);
																}}>
																Delete
															</Button>
														)}
													</div>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>

							<Button
								onClick={async () => {
									setMode(!mode);
									setTemp({});
								}}
								type='update'>
								Add New Qualification
							</Button>
						</div>
					</div>
				</Body>
				<Footer width='100%' />
			</div>

			{mode && (
				<Main width='95%' height='100%'>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'></div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body width='100%' height='82vh'>
						<div className='grid grid-flow-col p-2 w-full h-full gap-2 printable'>
							{['delete', 'edit'].includes(mode) && (
								<div className=' p-5 rounded bg-white w-full h-full'>
									<ProfileDataDisplay temp={temp} />

									<div className='flex float-left items-center my-1'>
										{mode === 'delete' && (
											<Button
												type='delete'
												onClick={() => {
													callProfileSubmission('delete');
												}}>
												Delete
											</Button>
										)}
									</div>
								</div>
							)}
							{mode !== 'delete' && (
								<div id='scrollBarEnable' className='px-3 py-2 w-auto z-50 overflow-auto  max-h-[95vh] h-full bg-white rounded'>
									<h5 className='text-blue-900  font-bold'>You can edit your data here !</h5>
									<Input width='98%' onChange={selectedQualification} fieldName='Qualification' returnKey='type' setState={setAddNew} options={dropDowns?.[4]?.qualification?.[0]?.qualificationList} state={addNew} type='drop' />
									<div className='relative z-[3]'>
										<Input width='98%' fieldName='Course' returnKey='course' options={qualification?.Course} setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[2]'>
										<Input width='98%' fieldName='Specialisation' options={qualification?.Specialisation} returnKey='specialisation' setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName='University' returnKey='university' options={qualification?.University} setState={setAddNew} state={addNew} type='drop' />
									</div>
									<Input width='98%' fieldName='Year of Pass' returnKey='yearOfPass' setState={setAddNew} state={addNew} type='number' />
									{!percentageNotAvaialable?.includes(addNew?.type) && (
										<>
											<Input width='98%' fieldName='Register Number' returnKey='registerNo' setState={setAddNew} state={addNew} type='text' />
											<Input width='98%' fieldName='Percentage' returnKey='percentage' setState={setAddNew} state={addNew} type='text' />
										</>
									)}
									<ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, keysValues)?.percentage)} />
									<Button
										width='100%'
										onClick={async () => {
											console.log({ temp });
											if (temp?._id) {
												callProfileSubmission('edit');
											} else {
												callProfileSubmission('add');
											}
											closeAnimation();
											setTimeout(() => setMode(false), 250);
										}}
										type='save'
										children='Save'
									/>
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}

			{csv && (
				<CSVEditor
					keysNeeded={['yearOfPass', 'percentage', 'university', 'specialisation', 'course', 'registerNo', 'type']}
					setCsv={setCSv}
					returnData={async (data) => {
						if (data) {
							console.log(data);
							let val = [...values];
							setIsTick(false);
							for (let i = 0; i < data?.length; i++) {
								await postProfileSubmission({
									keyData: 'add',
									values: val,
									temp,
									addNew: { ...data[i] },
									setIsLoading,
									setTemp,
									setMode,
									setAddNew,
									refetch,
									titleKey,
								});

								val = [...val, { ...data[i], _id: createRandomId('EQ') }];
								console.log(i);
							}
							setIsTick(true);
							setTimeout(() => {
								setIsTick(null);
								setCSv(false);
							}, 2000);
						}
					}}
				/>
			)}

			{isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null}
			{isLoading && <Loader />}
		</>
	);
}

export default Qualification;
