import React, { useContext,  useRef, useState } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import { getAllAccademics } from "../../pages/college/ApiServices";
import moment from "moment";

interface invoiceManagementProps {
  onClose: () => void;
}


function MasterStockRegister({ onClose }: invoiceManagementProps) {

  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);

  const { collegeId } = useContext(FirebaseContext);


  const ref = useRef<any>(null);


  const { data: tableData = [], refetch } = useQuery("getAllAccadamicHolidays", getAllAccademics(collegeId));


  const handleDelete = async (_id: string) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .delete("/college/action/deleteAccadamicHoliday/" + _id, { params: { collegeId } })
        .then(() => {
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };



  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/action/postAccadamicHolidays", { ...addNew, collegeId })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
      closeAnimation();
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error("API Error");
    }
  };

  return (
    <>
      <Main title=" Master Stock Register" width="90vw" height="90vh">
        <Header>
          <Button
            type="update"
            onClick={() => {
              setShowForm(true);
            }}
          >
           New Stock
          </Button>
          <div className="flex">
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
             </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
            <Table width="100%" innerWidth={["5%", "5%", "10%", "10%", "10%", "7%", "7%", "7%", "7%","5%"]}>
              <Thead>""
                <Tr>


                  <Th position={0}>Sl.No.</Th>
                  <Th position={1}>Date</Th>
                  <Th position={2}>Particulurs(No.& Date of contigent Vocher,invoice ) </Th>
                  <Th position={3}>Rate</Th>
                  <Th position={4}>Qty Purchased</Th>
                  <Th position={5}>Stock No</Th>
                  <Th position={6}>total</Th>
                  <Th position={7}>Nos.Issued</Th>
                  <Th position={8}>Balance</Th>
                  <Th position={9}>Issued to Whom</Th>
                  <Th position={10}>Signature of the recipiets with seal</Th>
                </Tr>
               
              </Thead>
              <Tbody height="auto">
                {tableData?.map((x: any, i: number) => (
                  <Tr>
                    <Td index={i} position={0}>
                      {i + 1}
                    </Td>
                
                    <Td index={i} position={1}>
                      {moment(x?.startDate).format("ddd-DD-mm-yyyy")}
                    </Td>
                    <Td index={i} position={2}>
                      moment{x?.tittle}
                    </Td>
                    <Td index={i} position={3}>
                      {x?.tittle}
                    </Td>
                    <Td index={i} position={4}>
                      {x?.effected}
                    </Td>
                    <Td index={i} position={5}>
                      {x?.managedBy}
                    </Td>

                    <Td index={i} position={6}>
                      {x?.managedBy}
                    </Td>
                    <Td index={i} position={7}>
                      {x?.managedBy}

                    </Td>
                    
                    <Td index={i} position={8}>
                      {x?.managedBy}

                    </Td>
                    <Td index={i} position={9}>
                      {x?.managedBy}

                    </Td>
                  
                    <Td index={i} position={10}>
                      <div className="flex">
                        <Button
                          type="update"
                          onClick={() => {
                            setAddNew(x);
                            setShowForm(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button type="delete" onClick={() => handleDelete(x?._id)}>
                          Delete
                        </Button>
                      </div>
                    </Td>

                

                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        <Footer></Footer>
      </Main>

      {showForm && (
        <Main width="40vw">
          <Header>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(() => setShowForm(false), 500);
              }}
            >
              Close
            </Button>
          </Header>
          <Body>



            <div className="flex flex-col w-full">
              <Input width="100%" fieldName=" Date" returnKey="date" state={addNew} setState={setAddNew} type="date" />
              <Input width="100%" fieldName="particulurs" returnKey="" state={addNew} setState={setAddNew} type="date" />
              <Input width="100%" fieldName="Rate" returnKey="rate" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName="Stock NO" returnKey="stockNo" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName="Total" returnKey="total" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName=" Nos Issued" returnKey="nosIssued" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName="Balance" returnKey="balance" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName="Issued to whom " returnKey="issuedWhom" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName="Signature of the recipiets with seal " returnKey="signRecipiets" state={addNew} setState={setAddNew}  type="drop" />
              <Input width="100%" fieldName="Signature of the principal with seal " returnKey="signPrincipal" state={addNew} setState={setAddNew}  type="drop" />
             
              <Button
                type="save"
                onClick={() => {
                  addNewForm();
                }}
              >
                Save
              </Button>

            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default MasterStockRegister;
