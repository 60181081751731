import className from "./Popup.module.scss";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import LoadingAnimation from "../../../components/LoadingAnimation";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import Button from "antd/lib/button";
import {
  useProfileData,
  userAction,
  getCollegesReg,
  getDetailstoList,
} from "../../../queryHooks";
import { useContext } from "react";
import { FirebaseContext } from "../../../context/FirebaseContext";

const DeletePopUp = ({ setAction, uid, isStudent, refetch }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { data: userDetail, status } = useProfileData(uid, !isStudent);

  const deleteHandler = async () => {
    const res = await userAction({
      collegeId,
      userId: uid,
      action: "delete",
    });
    if (res?.resData) {
      setLoading(false);
      setSuccess("success");
    }
    if (res?.err) {
      setLoading(false);
      setSuccess("failed");
    }
  }

  return (
    <div className={className.deleteMain}>
      {success === "success" ? (
        <>
          <h1 style={{ color: "green" }}>Success</h1>
          <h3>Successfully deleted </h3>
          {!loading && (
            <button className={className.cancelBtn} onClick={() => {
              setAction('')
              refetch && refetch();
            }} >
              <span>Cancel</span>
            </button>
          )}
        </>
      ) : success === "failed" ? (
        <>
          <h1 style={{ color: "red" }}>Failed</h1>
          <h3> Please try again</h3>
          {!loading || status !== 'loading' ? (
            <button className={className.cancelBtn} onClick={() => setAction('')}>
              <span>Cancel</span>
            </button>
          ) : ""}
          {!loading || status !== 'loading' ? (
            <button className={className.deleteBtn} onClick={deleteHandler} >
              <span>Delete</span>
            </button>
          ) : ''}
        </>
      ) : (
        ""
      )}
      {success === false ? (
        <>
          <h1 style={{ color: "red" }}>Alert</h1>
          <h3>Are you sure to delete {userDetail?.name}</h3>
          {!loading || status !== 'loading' ? (
            <button
              className={className.cancelBtn}
              onClick={() => setAction("")}
            >
              <span>Cancel</span>
            </button>
          ) : ''}
          {!loading || status !== 'loading' ? (
            <button className={className.deleteBtn} onClick={deleteHandler}  >
              <span>Delete</span>
            </button>
          ) : ''}
        </>
      ) : (
        ""
      )}
      {loading && <Spin tip="Loading..."></Spin>}
    </div>
  );
};

const SuspendPopUp = ({ setAction, uid, isStudent, refetch }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [statusState, setStatus] = useState("");
  const [missingField, setMissingField] = useState("");
  const [remark, setRemark] = useState("");
  const [dateTo, setDateTo] = useState("");
  const { data: userDetail, status } = useProfileData(uid, !isStudent);

  useEffect(() => {
    setMissingField("");
  }, [remark, dateTo]);

  const suspendHandler = async () => {
    if (remark === "" || dateTo === "")
      return setMissingField("Missing field !!!");
    setLoading(true);
    const res = await userAction({
      collegeId,
      remark,
      dateTo,
      userId: uid,
      action: "suspend",
    });
    if (res.resData) {
      setLoading(false);
      setStatus("success");
    }
    if (res?.err) {
      setLoading(false);
      setStatus("error");
    }
  };

  return (
    <div className={className.suspendMain}>
      <div>
        <button className={className.cancelBtn} onClick={() => setAction("")}>
          Cancel
        </button>
        {missingField !== "" ? (
          <h1 style={{ color: "red" }}>{missingField}</h1>
        ) : (
          <h1>Suspend - {userDetail?.name}</h1>
        )}
        <button className={className.suspendBtn} onClick={suspendHandler}>
          Suspend
        </button>
      </div>
      <div className={className.labelDiv}>
        <label htmlFor="suspend">Suspended to</label>
        <input
          type="date"
          id="suspend"
          onChange={(e) => setDateTo(e.target.value)}
        />
      </div>
      {status !== "loading" || !loading ? (
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Suspension letter (Reason)"
          onChange={(e) => setRemark(e.target.value)}
        ></textarea>
      ) : (
        ""
      )}
      {status === "loading" || loading ? (
        <div className={className.loading}>
          <LoadingAnimation dark />
        </div>
      ) : (
        ""
      )}
      {statusState === "error" && (
        <div className={className.failedMsg}>
          <h1>Failed</h1>
          <h3>Please try again !!</h3>
          <button onClick={() => setStatus("")}>Close</button>
        </div>
      )}
      {statusState === "success" && (
        <div className={className.failedMsg}>
          <h1 style={{ color: "green" }}>Success</h1>
          <h3>Successfully suspened !!</h3>
          <button
            onClick={() => {
              setStatus("");
              setAction("");
              refetch && refetch();
            }}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

const DismissPopUp = ({ setAction, uid, isStudent }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [statusState, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [missingfield, setMissingField] = useState("");
  const { data: userDetail, status } = useProfileData(uid, !isStudent);

  useEffect(() => {
    setMissingField("");
  }, [remark]);

  const dismissHandler = async () => {
    if (!remark) return setMissingField("Missing field");
    setLoading(true);

    const res = await userAction({
      collegeId,
      remark,
      userId: uid,
      action: "dismissed",
    });
    if (res.resData) {
      setLoading(false);
      setStatus("success");
    }
    if (res?.err) {
      setLoading(false);
      setStatus("error");
    }
  };
  return (
    <div className={className.suspendMain}>
      <div>
        <button className={className.cancelBtn} onClick={() => setAction("")}>
          Cancel
        </button>
        {missingfield === "" ? (
          <h1>Dismiss - {userDetail?.name}</h1>
        ) : (
          <h1 style={{ color: "red" }}>{missingfield}</h1>
        )}
        <button className={className.suspendBtn} onClick={dismissHandler}>
          Dismiss
        </button>
      </div>

      {status !== "loading" && !loading ? (
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Dismissal letter (Reason)"
          onChange={(e) => setRemark(e.target.value)}
        ></textarea>
      ) : (
        ""
      )}
      {status === "loading" || loading ? (
        <div className={className.loading}>
          <LoadingAnimation dark />
        </div>
      ) : (
        ""
      )}
      {statusState === "error" && (
        <div className={className.failedMsg}>
          <h1>Failed</h1>
          <h3>Please try again !!</h3>
          <button onClick={() => setStatus("")}>Close</button>
        </div>
      )}
      {statusState === "success" && (
        <div className={className.failedMsg}>
          <h1 style={{ color: "green" }}>Success</h1>
          <h3>Successfully dismissed !!</h3>
          <button
            onClick={() => {
              setStatus("");
              setAction("");
            }}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

const DiscontinePopUp = ({ setAction, uid, isStudent, refetch }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(false);
  const [statusState, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [missingField, setMissingField] = useState("");
  const { data: userDetail, status } = useProfileData(uid, !isStudent);
  const discontinueHandler = async () => {
    if (!remark) return setMissingField("Missing Field !!");
    setLoading(true);
    const res = await userAction({
      collegeId,
      remark,
      userId: uid,
      action: "discontinue",
    });
    if (res.resData) {
      setLoading(false);
      setStatus("success");
    }
    if (res?.err) {
      setLoading(false);
      setStatus("error");
    }
  };
  return (
    <div className={className.suspendMain}>
      <div>
        <button className={className.cancelBtn} onClick={() => setAction("")}>
          Cancel
        </button>

        {missingField === "" ? (
          <h1>Discontinue - {userDetail?.name}</h1>
        ) : (
          <h1 style={{ color: "red" }}>{missingField}</h1>
        )}
        <button className={className.suspendBtn} onClick={discontinueHandler}>
          Confirm
        </button>
      </div>

      {status !== "loading" && !loading ? (
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder=" Remark (Reason)"
          onChange={(e) => setRemark(e.target.value)}
        ></textarea>
      ) : (
        ""
      )}
      {status === "loading" || loading ? (
        <div className={className.loading}>
          <LoadingAnimation dark />
        </div>
      ) : (
        ""
      )}
      {statusState === "error" && (
        <div className={className.failedMsg}>
          <h1>Failed</h1>
          <h3>Please try again !!</h3>
          <button onClick={() => setStatus("")}>Close</button>
        </div>
      )}
      {statusState === "success" && (
        <div className={className.failedMsg}>
          <h1 style={{ color: "green" }}>Success</h1>
          <h3>Successfully discontined !!</h3>
          <button
            onClick={() => {
              setStatus("");
              setAction("");
              refetch && refetch();
            }}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

const TranferPopUp = ({ setAction, uid, refetch }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [loadingType, setLoading] = useState("");
  const [statusState, setStatus] = useState("");
  const [colleges, setColleges] = useState([]);
  const [selectCollege, setSelectCollege] = useState("");
  const [classOrDpt, setClssOrDpt] = useState("");
  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState("");
  const [missingField, setMissingField] = useState('')
  useEffect(() => {
    setLoading("college");
    getCollegesReg()
      .then((d) => {
        setColleges(d.colleges);
        setLoading("");
      })
      .catch((e) => {
        setStatus("errorc");
        setLoading("");
      });
  }, []);
  useEffect(() => {
    setMissingField('')
    if (classOrDpt && selectCollege) {
      setLoading("details");
      getDetailstoList(classOrDpt, colleges[selectCollege]._id)
        .then((r) => {
          setLoading("");
          setList(r);
        })
        .catch((e) => {
          setLoading("");
          setStatus("errorc");
        });
    }
  }, [selectCollege, classOrDpt, colleges]);

  const menu = (
    <Menu
      onClick={({ key }) => {

        setSelectCollege(key);
      }}
    >
      {colleges?.map((coll, i) => {
        return <Menu.Item key={i}> {coll.collegeName}</Menu.Item>;
      })}
    </Menu>
  );
  const menu2 = (
    <Menu onClick={({ key }) => setSelectedList(key)}>
      {list?.map((li, i) => (
        <Menu.Item key={i}> {li.name}</Menu.Item>
      ))}
    </Menu>
  );
  const menu1 = (
    <Menu onClick={({ key }) => setClssOrDpt(key)}>
      <Menu.Item key="c"> class</Menu.Item>
      <Menu.Item key="d"> department</Menu.Item>
    </Menu>
  );
  const tranferHandler = async () => {
    if (!selectCollege || !selectedList) return setMissingField("Missing fields !!")

    setLoading("college");
    let payload = {
      collegeId,
      tranferCollegeId: colleges[selectCollege]._id,
      userId: uid,
      action: "tranfer",
    }
    if (classOrDpt === 'c') {
      payload.classId = list[selectedList]._id
    }
    if (classOrDpt === 'd') {
      payload.departmentId = list[selectedList]._id
    }
    const res = await userAction(payload);
    if (res.resData) {
      setLoading("");
      setStatus("success");
    }
    if (res?.err) {
      setLoading("");
      setStatus("error");
    }

  }

  return (
    <div className={className.tranferMain}>
      {
        missingField !== '' ? <h2 style={{ color: 'red', fontSize: '2.4rem' }}>{missingField}</h2>
          : <h1>Transfer board</h1>
      }
      {loadingType !== "college" ? (
        <>
          <div className={className.Dropdown}>
            <Dropdown overlay={menu}>
              <Button>
                {colleges[selectCollege]
                  ? colleges[selectCollege].collegeName
                  : "select college"}
              </Button>
            </Dropdown>
          </div>
          <div className={className.Dropdown}>
            <Dropdown overlay={menu1}>
              <Button>
                {classOrDpt === ""
                  ? "Select"
                  : classOrDpt === "d"
                    ? "Department"
                    : "class"}
              </Button>
            </Dropdown>
          </div>
          <div className={className.Dropdown}>
            {loadingType !== "details" ? (
              <Dropdown overlay={menu2}>
                <Button>{selectedList ? list[selectedList].name : classOrDpt === 'd' ? "Departments" : classOrDpt === 'c' ? "classes" : 'select'}</Button>
              </Dropdown>
            ) : (
              <LoadingAnimation />
            )}
          </div>
        </>
      ) : (
        <LoadingAnimation />
      )}
      <div className={className.btns}>
        <button className={className.cancelBtn} onClick={() => setAction("")}>
          Cancel
        </button>
        <button className={className.tranferBtn} onClick={tranferHandler}>Transfer</button>
      </div>
      {statusState === "error" || statusState === "errorc" ? (
        <div className={className.failedMsg}>
          <h2>Failed</h2>
          <h3>Please try again !!</h3>
          <button onClick={() => {
            if (statusState === "errorc") setAction('')
            setStatus('')

          }}>Close</button>
        </div>
      ) : (
        ""
      )}
      {statusState === "success" && (
        <div className={className.failedMsg}>
          <h2 style={{ color: 'green' }}>Success</h2>
          <h3>Successfully tranfer !!</h3>
          <button onClick={() => {
            setAction('')
            setStatus('')
            refetch && refetch();
          }} >Close</button>
        </div>
      )}
    </div>
  );
};

const ChangePopUp = ({ setAction, uid, refetch }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [loadingType, setLoading] = useState("");
  const [status, setStatus] = useState("");
  const [departments, setDepartments] = useState([])
  const [classes, setClasses] = useState([])
  const [selectedClass, setSelectedClass] = useState('')
  const [selectedDpt, setSelectedDpt] = useState('')
  const [missingfield, setMissingField] = useState('')
  useEffect(() => {
    getDetailstoList('d', collegeId)
      .then((r) => {
        setLoading("");
        setDepartments(r);
      })
      .catch((e) => {
        setLoading("");
        setStatus("errorc");
      });
    getDetailstoList('c', collegeId)
      .then((r) => {
        setLoading("");
        setClasses(r);
      })
      .catch((e) => {
        setLoading("");
        setStatus("errorc");
      });

  })
  const menu = (
    <Menu onClick={({ key }) => setSelectedDpt(key)}>
      {
        departments?.map((dept, i) => <Menu.Item key={i}>{dept.name}</Menu.Item>)
      }

    </Menu>
  );

  const menu2 = (
    <Menu onClick={({ key }) => setSelectedClass(key)}>
      {classes?.map((clss, i) => <Menu.Item key={i}>{clss.name}</Menu.Item>)}

    </Menu>
  );
  const changeHandler = async () => {
    if (selectedClass === '' && selectedDpt === '') return setMissingField('Missing field')
    let payload = {
      collegeId,
      userId: uid,
      action: "change",
    }
    if (selectedClass) {
      payload.classId = classes[selectedClass]._id
    }
    if (selectedDpt) {
      payload.departmentId = departments[selectedDpt]._id
    }
    const res = await userAction(payload);
    if (res.resData) {
      setLoading("");
      setStatus("success");
    }
    if (res?.err) {
      setLoading("");
      setStatus("error");
    }

  }
  return (
    <div className={className.tranferMain}>
      {
        missingfield === ''
          ? <h1>Transfer Class</h1>
          : <h2 style={{ color: 'red', fontSize: '2.4rem' }}>{missingfield}</h2>
      }
      {loadingType !== "department" ? (
        <>
          <div className={className.Dropdown}>
            <Dropdown overlay={menu}>
              <Button>{selectedDpt === '' ? "Select Department" : departments[selectedDpt].name}</Button>
            </Dropdown>
          </div>

          <div className={className.Dropdown}>
            {loadingType !== "class" ? (
              <Dropdown overlay={menu2}>
                <Button>{selectedClass === '' ? "Select Class" : classes[selectedClass].name}</Button>
              </Dropdown>
            ) : (
              <LoadingAnimation />
            )}
          </div>
        </>
      ) : (
        <LoadingAnimation />
      )}
      <div className={className.btns}>
        <button className={className.cancelBtn} onClick={() => setAction("")}>
          Cancel
        </button>
        <button className={className.tranferBtn} onClick={changeHandler}>Change</button>
      </div>
      {status === "error" || status === "errorc" ? (
        <div className={className.failedMsg}>
          <h2>Failed</h2>
          <h3>Please try again !!</h3>
          <button onClick={() => {
            if (status === "errorc") setAction('')
            setStatus('')
          }} >Close</button>
        </div>
      ) : ''}
      {status === "success" && (
        <div className={className.failedMsg}>
          <h2 style={{ color: "green" }}>Success</h2>
          <h3>Successfully change !!</h3>
          <button onClick={() => {
            setAction('')
            refetch && refetch();
          }}>Close</button>
        </div>
      )}
    </div>
  );
};

export {
  DeletePopUp,
  SuspendPopUp,
  DismissPopUp,
  DiscontinePopUp,
  TranferPopUp,
  ChangePopUp,
};
