import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import Button from '../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { EditRecieptNo } from './EditReceiptNo';
import { Tbody, Td, Th, Thead, Table, Tr, Tfoot, TfootTd, TfootTr } from '../../NecttosComp/Table/Table';
import { PulseLoader } from 'react-spinners';

export const exportPdfExam = async (olddata) => {
	const instance = await getAxiosTokenInstance();
	let data = {
		collegeId: olddata.collegeId,
		date: olddata.date,
	};
	let url = [`/college/reportGeneration/generateDFCR`];
	try {
		const resp = await instance.post(...url, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getIndividualAccounts = (data) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/fees/getSubsidiaryIndividualRegister', {
			params: { ...data },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const DFCRInduvidual = ({ data, date, onClose, setStateData, stateData }) => {
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const exportPdfMutationExam = useMutation(exportPdfExam);

	const [loading, setLoading] = useState(false);
	const [state, setState] = React.useState({
		isBillDelete: false,
		filterValue: '',
		page: 0,
		startDate: '',
		endDate: '',
	});

	console.log({ state });
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const { data: individualData, isFetching, refetch: refetchData } = useQuery(['DFCRoIndividual', state?.selectedDate, state?.countData], getIndividualAccounts({ collegeId, date }));
	const onInternalMark = async () => {
		setLoading(true);
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				collegeId,
				date,
			};

			link.download = 'Internal Mark';
			const pdfData = await exportPdfMutationExam.mutateAsync(postData);
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
			setLoading(false);
		} catch (e) {
			setLoading(false);
		}
	};

	const [showLeftArrowDetail, setShowLeftArrowDetail] = useState(false);
	const [showRightArrowDetail, setShowRightArrowDetail] = useState(true);

	function scrollLeft() {
		const container = document.getElementById('scrolls-container');
		container.scrollBy({ left: -200, behavior: 'smooth' });
	}

	function scrollRight() {
		const container = document.getElementById('scrolls-container');
		container.scrollBy({ left: 200, behavior: 'smooth' });
	}

	function handleScroll() {
		const container = document.getElementById('scrolls-container');
		if (container.scrollLeft === 0) {
			setShowLeftArrowDetail(false);
		} else {
			setShowLeftArrowDetail(true);
		}
		if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
			setShowRightArrowDetail(false);
		} else {
			setShowRightArrowDetail(true);
		}
	}
	console.log({ sta0000te: individualData?.subsidiaryRegisterData });

	return (
		<>
			<Main title={'DFCR'} height={'100vh'} width={'100vw'}>
				<Header width='100%'>
					<div className='flex flex-wrap items-center'>
						<Button type='fetch' onClick={refetchData}>
							Load
						</Button>
					</div>

					<div className='flex flex-wrap items-center'>
						<Button type='print' tableRef={componentRef.current}>
							Print
						</Button>
						<Button type='excel' tableRef={componentRef.current}>
							Excel
						</Button>
						<Button
							type='download'
							onClick={() => {
								onInternalMark();
							}}>
							PDF
						</Button>
						<Button
							type='close'
							onClick={() => {
								closeAnimation();
								setTimeout(onClose, 250);
							}}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={componentRef} className='printable '>
						<div class='flex flex-row items-center justify-between p-4 border-b-2'>
							<div class='flex items-center'>
								<img class='w-16 h-16 mr-4' src={selectedCollege?.logo} alt='College Logo' />
								<div>
									<h1 class='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
									<p class='text-md font-semibold h-1'>{selectedCollege?.accreditedGrade}</p>
									<p class='text-md font-semibold h-1'>{selectedCollege?.address}</p>
								</div>
							</div>

							<div class='text-right'>
								<p class='text-xl font-medium h-0'>DFCR</p>
								<p class='text-md font-medium h-1'>
									{date.type} - {date.subType}
								</p>
								<p class='text-sm font-medium h-1'>
									{moment(stateData.startDate).format('DD MMM YYYY')} to {moment(stateData.endDate).format('DD MMM YYYY')}
								</p>
								<p class='text-sm font-medium h-1'>Print at {moment(new Date()).format('DD MMM YYYY HH:MM A')}</p>
							</div>
						</div>
						{!isFetching ? (
							<div className='relative w-full h-full'>
								{showLeftArrowDetail && (
									<>
										<div className='absolute top-1/3 left-0 transform -translate-y-1/2 text-gray p-1 cursor-pointer z-10 text-3xl opacity-60' onClick={scrollLeft}>
											&#9664;
										</div>
									</>
								)}

								{showRightArrowDetail && (
									<>
										<div className='absolute top-1/3 right-0 transform -translate-y-1/2 text-gary p-1 cursor-pointer z-10 text-3xl opacity-60' onClick={scrollRight}>
											&#9654;
										</div>
									</>
								)}
								<div id='scrolls-container' className='w-full flex flex-col rounded-2xl p-2 overflow-scroll' onScroll={handleScroll}>
									<Table id='accounts' width='2945px'>
										<Thead>
											<Tr>
												<Th width='60px' textAlign='center'>
													SN
												</Th>
												<Th width='240px' textAlign='left'>
													Name
												</Th>
												<Th width='200px' textAlign='left'>
													Class
												</Th>
												<Th width='100px' textAlign='center'>
													Ad.No.
												</Th>
												<Th width='120px' textAlign='center'>
													Rt.No.
												</Th>

												{Array.from({ length: data?.totals.countHeads || 25 }, (_, i) => (
													<Th key={i} width='85px' textAlign='center'>
														{data?.keyValueData[`dailyFeeCollectionRegister${i + 1}`]}
													</Th>
												))}

												<Th width='100px' textAlign='center'>
													TOTAL
												</Th>
											</Tr>
										</Thead>
										<Tbody>
											{individualData?.subsidiaryRegisterData?.map((item, i) => (
												<Tr key={i}>
													<Td width='60px' textAlign='center'>
														{i + 1}
													</Td>
													<Td
														width='240px'
														textAlign='left'
														onClick={() => {
															setState({ ...state, isBillDelete: true, openModal: true, selectedDate: item.date, item: { _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, admisionNo: item?.admisionNo, feeId: item?.feeId, billNo: item?.billNo } });
														}}>
														{item?.userName}
													</Td>
													<Td width='200px' textAlign='left'>
														{item?.className}
													</Td>
													<Td width='100px' textAlign='center'>
														{item?.admisionNo}
													</Td>
													<Td
														width='120px'
														textAlign='center'
														onClick={() => {
															setState({ ...state, openModal: true, selectedDate: item.date, item: { userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister1 } });
														}}>
														{item?.billNo || '-'}
													</Td>

													{Array.from({ length: data?.totals.countHeads || 25 }, (_, j) => (
														<Td
															key={j}
															width='85px'
															textAlign='right'
															onClick={() =>
																setState({
																	...state,
																	openModal: true,
																	selectedDate: item.date,
																	item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item[`dailyFeeCollectionRegister${j + 1}`], keyData: data?.keyValueData[`dailyFeeCollectionRegister${j + 1}`] },
																})
															}>
															{item[`dailyFeeCollectionRegister${j + 1}`]?.amount}
														</Td>
													))}

													<Td width='100px' textAlign='right' onClick={() => setState({ ...state, openModal: true, selectedDate: item.date, item: { ...item } })}>
														{item?.totalAmount}
													</Td>
												</Tr>
											))}
										</Tbody>
										<Tfoot>
											<TfootTr>
												<TfootTd colSpan={5} textAlign='right' width='720px'>
													Total
												</TfootTd>
												{Array.from({ length: data?.totals.countHeads || 25 }, (_, i) => (
													<TfootTd key={i} width='85px' textAlign='right'>
														{data?.totals[`dailyFeeCollectionRegisterMainTotal${i + 1}`]}
													</TfootTd>
												))}
												<TfootTd width='100px' textAlign='right'>
													{data?.totals?.grandTotal}
												</TfootTd>
											</TfootTr>
										</Tfoot>
									</Table>
								</div>

								<div className='mt-3 text-right text-black text-xs'>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</div>
								<div className='flex justify-center'>
									<Table width='710px' border='1'>
										<Thead>
											<Tr>
												<Th width='45px' textAlign='center'>
													SN
												</Th>
												<Th width='105px' textAlign='left'>
													Head
												</Th>
												<Th width='210px' textAlign='left'>
													Title
												</Th>
												<Th width='105px' textAlign='center'>
													Amount
												</Th>
												<Th width='140px' textAlign='center'>
													Challan Number
												</Th>
												<Th width='105px' textAlign='center'>
													Remitted Date
												</Th>
											</Tr>
										</Thead>
										<Tbody>
											{individualData?.subTotalsDetails?.map((item, i) => (
												<Tr key={i} border='1'>
													<Td width='45px' textAlign='center'>
														{i + 1}
													</Td>
													<Td width='105px' textAlign='left'></Td>
													<Td width='210px' textAlign='left'>
														{item?.name}
													</Td>
													<Td width='105px' textAlign='center'>
														{item?.amount}
													</Td>
													<Td width='140px' textAlign='center'></Td>
													<Td width='105px' textAlign='center'></Td>
												</Tr>
											))}
										</Tbody>
										<Tfoot>
											<TfootTr>
												<TfootTd colSpan={3} textAlign='center' width='360px'>
													Total:
												</TfootTd>
												<TfootTd colSpan={1} textAlign='center' width='105px'>
													{data?.totals?.grandTotal}
												</TfootTd>
												<TfootTd colSpan={2} textAlign='center' width='245px'></TfootTd>
											</TfootTr>
										</Tfoot>
									</Table>
								</div>
							</div>
						) : (
							<div className='flex justify-center items-center pt-20'>{isFetching ? <PulseLoader color='#002147' size={15} /> : <img src='https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/nodatafound.png?alt=media&token=75763b23-2b98-4570-b308-963bc28c070b' alt='' />}</div>
						)}
					</div>
					{state.openModal && (
						<EditRecieptNo
							item={state?.item}
							isBillDelete={state?.isBillDelete}
							refetchDemo={refetchData}
							setStateData={setState}
							stateData={state}
							onClose={() => {
								setState({ ...state, openModal: false, isBillDelete: false });
							}}
						/>
					)}
				</Body>
			</Main>
		</>
	);
};
