import React, { useEffect, useRef, useState } from "react";
import { getDropdownData } from "../../pages/college/ApiServices";
import Button from "../../NecttosComp/Button/Button";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Input from "../../NecttosComp/Input/Input";
import { useProfileEdit } from "../../queryHooks";
import { ProgressBar, calculateProfilepPercentage } from "../../NecttosComp/ProgressBar/ProgressBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import Loader from "../../NecttosComp/Loader/Loader";
import Loader1 from "../../NecttosComp/Loaders/Loader";
import { ColorAbbreviationListOfProfile, ProfileDataDisplay, getProfileUpdateColors, postProfileSubmission } from "../../FunctionalExport/Formate";
import CSVEditor from "../../NecttosComp/CSVEditor/CSVEditor";
import { createRandomId } from "../../FunctionalExport/Utils";

interface Props {
  onClose: () => void;
  studentId?: string | null | undefined;
}

function Traveling({ onClose, studentId }: Props) {
  const titleKey = "travelDetails";
  const [dropDowns, setDropDowns] = useState<any[]>([{}]);
  const [csv, setCSv] = useState(false);
  const [isTick, setIsTick] = useState<any>(null);
  const [temp, setTemp] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentRef = useRef<HTMLTableElement>(null);
  const [mode, setMode] = useState<any>(false);
  const [addNew, setAddNew] = useState<any>();
  const { data: profileData, refetch } = useProfileEdit(titleKey);
  const [values, setValues] = useState<any[]>(profileData);
  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
    };
    getDropDowns();
  }, []);
  const callProfileSubmission = async (keyData: string) => {
    await postProfileSubmission({
      keyData,
      values,
      temp,
      addNew,
      setIsLoading,
      setTemp,
      setMode,
      setAddNew,
      refetch,
      titleKey,
    });
  };

  console.log(values);

  useEffect(() => {
    setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
  }, [profileData]);

  return (
    <>
      <Main title="Traveling Route (College <> Home)" height="90vh" width={"90vw"}>
        <Header width="100%" height="6%" title="Profile">
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="update" onClick={() => setCSv(true)}>
              CSV
            </Button>
            <Button type="print" tableRef={componentRef.current} children="Print" />
            <Button type="pdf" pdfId="profilePDF" children="PDF" />
            <Button type="excel" tableRef={componentRef.current} pdfId="profilePDF" children="EXCEL" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body width="100%" height="85%">
          <div className="w-full  overflow-auto">
            <div className="w-full float-left p-2">
              <div className="w-full ">
                <ColorAbbreviationListOfProfile />

                <Table ref={componentRef} id={"profilePDF"} width="100%" innerWidth={["10%", "6%", "14%", "14%", "9%", "10%", "10%", "10%", "10%"]}>
                  <Thead>
                    <Tr>
                      <Th position={0} children="Vehicle" />
                      <Th position={1} children="Fuel" />
                      <Th position={2} children="Travel From" />
                      <Th position={3} children="Travel To" />
                      <Th position={4} children="Distance" />
                      <Th position={5} children="Co-Traveler 1" />
                      <Th position={6} children="Co-Traveler 2" />
                      <Th position={7} children="Co-Traveler 3" />
                      <Th position={8} children="Action" />
                    </Tr>
                  </Thead>
                  <Tbody height="auto">
                    {values?.map((x: any, index: any) => (
                      <Tr>
                        <Td position={0} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.typeOfVehicle}</p>
                        </Td>
                        <Td position={1} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.typeOfEnergy}</p>
                        </Td>
                        <Td position={2} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.travelStart}</p>
                        </Td>
                        <Td position={3} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.travelTo}</p>
                        </Td>
                        <Td position={4} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.km}</p>
                        </Td>
                        <Td position={5} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.coTraveler1}</p>
                        </Td>
                        <Td position={6} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.coTraveler2}</p>
                        </Td>
                        <Td position={7} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.coTraveler3}</p>
                        </Td>
                        <Td position={8} index={index}>
                          <div className="flex">
                            <Button
                              type="update"
                              onClick={() => {
                                setMode("edit");
                                setAddNew(x);
                                setTemp(x);
                              }}
                            >
                              Edit
                            </Button>

                            <Button
                              type="delete"
                              onClick={() => {
                                setMode("delete");
                                setTemp(x);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>

              <Button
                onClick={async () => {
                  setMode(!mode);
                  setTemp({});
                }}
                type="update"
              >
                Add how to come to college
              </Button>
            </div>
          </div>
        </Body>
        <Footer width="100%" />
      </Main>

      {mode && (
        <Main>
          <Header width="100%" height="6%" title="Profile">
            <div className="flex flex-wrap items-center"></div>
            <div className="flex flex-wrap windows-center">
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setMode(false), 250);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div className="grid grid-flow-col p-2">
              {["delete", "edit"].includes(mode) && (
                <div className=" aspect-[2/1] p-5 rounded">
                  <ProfileDataDisplay temp={temp} />

                  <div className="flex float-right items-center my-1">
                    {mode === "delete" && (
                      <Button
                        type="delete"
                        onClick={() => {
                          callProfileSubmission("delete");
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {mode !== "delete" && (
                <div id="scrollBarEnable" className="px-3 py-2 w-[28vw] z-50 overflow-auto  max-h-[65vh]">
                  <h5 className="text-blue-900  font-bold">You can edit your data here !</h5>
                  <div className="relative z-[3]">
                    <Input width="98%" fieldName="Vehicle" options={dropDowns[7].vehicle} returnKey="typeOfVehicle" setState={setAddNew} state={addNew} type="drop" />
                  </div>
                  <div className="relative z-[2]">
                    <Input width="98%" fieldName="Fuel" returnKey="typeOfEnergy" options={dropDowns[7].engine} setState={setAddNew} state={addNew} type="drop" />
                  </div>
                  <div className="relative z-[1]">
                    <Input width="98%" fieldName="Travel Start" returnKey="travelStart" setState={setAddNew} state={addNew} type="text" />
                  </div>
                  <Input width="98%" fieldName="Travel To" returnKey="travelTo" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Distance" returnKey="km" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Co-Traveler 1" returnKey="coTraveler1" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Co-Traveler 2" returnKey="coTraveler2" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Co-Traveler 3" returnKey="coTraveler3" setState={setAddNew} state={addNew} type="text" />
                  <ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, { coTraveler3: "", coTraveler2: "", coTraveler1: "", km: "", travelTo: "", travelStart: "", typeOfEnergy: "", typeOfVehicle: "" })?.percentage)} />
                  <Button
                    width="100%"
                    onClick={async () => {
                      console.log({ temp });
                      if (temp?._id) {
                        callProfileSubmission("edit");
                      } else {
                        callProfileSubmission("add");
                      }
                      closeAnimation();
                      setTimeout(() => setMode(false), 250);
                    }}
                    type="save"
                    children="Save"
                  />
                </div>
              )}
            </div>
          </Body>
        </Main>
      )}

      {isLoading && <Loader />}
      {csv && (
        <CSVEditor
          keysNeeded={["coTraveler3", "coTraveler2", "coTraveler1", "km", "travelTo", "travelStart", "typeOfEnergy", "typeOfVehicle"]}
          setCsv={setCSv}
          returnData={async (data) => {
            if (data) {
              console.log(data);
              let val = [...values];
              setIsTick(false);
              for (let i = 0; i < data?.length; i++) {
                await postProfileSubmission({
                  keyData: "add",
                  values: val,
                  temp,
                  addNew: { ...data[i] },
                  setIsLoading,
                  setTemp,
                  setMode,
                  setAddNew,
                  refetch,
                  titleKey,
                });

                val = [...val, { ...data[i], _id: createRandomId("EQ") }];
                console.log(i);
              }
              setIsTick(true);
              setTimeout(() => {
                setIsTick(null);
                setCSv(false);
              }, 2000);
            }
          }}
        />
      )}
      {isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null}
      {isLoading && <Loader />}
    </>
  );
}

export default Traveling;
