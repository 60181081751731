import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import TestimonialV2 from '../../components/Website/testimonial';
import Team from '../../components/Website/team-page';
import Subscribe from '../../components/Website/subscribe';
import FooterBar from '../../components/Website/footerBar';

const TeamPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Team" />
        <Team />
        <TestimonialV2 />
        <Subscribe />
        <FooterBar />
    </div>
}

export default TeamPage

