import React, { useContext, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { Button, Select } from 'antd';
import { Field, Formik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import Scrollbars from 'react-custom-scrollbars';
import ReactToPrint from 'react-to-print';
import ModalWrapper from '../../../../components/ModalWrapper';
import NewQuestionnaire from './NewQuestionnaire';
import { getOpenCourse } from '../../ApiServices';
import moment from 'moment';
import SettupQuestionnaire from './SettupQuestionnaire';
import AnalysisQuestionnaire from './AnalysisQuestionnaire';
import { Statistics } from './Statistics';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';

export const exportPdfExam = async (olddata) => {
	const instance = await getAxiosTokenInstance();

	let data = {
		collegeId: olddata.collegeId,
		openCourseId: olddata.openCourseId,
		key: olddata.key,
	};

	let url = [`/college/reportGeneration/openCourseTrailAllotment`];
	try {
		const resp = await instance.post(...url, data, {
			responseType: 'blob',
		});

		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp.data;
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const OpenCourse = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const exportPdfMutationExam = useMutation(exportPdfExam);

	const [state, setState] = React.useState({
		filterValue: '',
		page: 0,
	});
	const componentRef = useRef();
	const [loading, setLoading] = useState(false);

	let [items, setItems] = React.useState({
		addBook: false,
	});
	const { data = [], refetch } = useQuery(['getOpenCourse', collegeId, state.type], getOpenCourse(collegeId, state.filterType));

	const { Option } = Select;

	const trailAllotment = async (openCourseId, key) => {
		setLoading(true);
		try {
			const link = document.createElement('a');
			link.target = '_blank';
			let postData = {
				collegeId,
				openCourseId,
				key,
			};
			link.download = 'Open Course';
			const pdfData = await exportPdfMutationExam.mutateAsync(postData);
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.click();
			setLoading(false);
		} catch (e) {}
		setLoading(false);
	};

	let filterTypeDrops = ['Student', 'Teacher', 'Statf'];

	return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				{loading ? (
					<LoadingAnimation dark />
				) : (
					<>
						<div className={classNames.listContainer2}>
							<div style={{ height: '90%' }}>
								<div ref={componentRef} className='printable'>
									<div style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
										<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}>Sn</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '30%' }}>Batch</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Start Date</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>End Date</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Filled %</h5>
											<h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '25%' }}></h5>
										</div>
										<div
											style={{
												height: '600px',
												width: '100%',
												position: 'relative',
											}}>
											<Scrollbars>
												{data?.map((item, i) => (
													<>
														<div style={{ width: '100%', display: 'flex', padding: '20px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '4%' }}>{parseInt(state.page) * 50 + 1 + i}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '30%' }}>{item.batchName}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{moment(item.effectFrom).format('DD MMM YYYY')}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{moment(item.lastDate).format('DD MMM YYYY')}</h5>
															<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.responds || '0%'}</h5>

															<Button
																className={classNames.reloadButton}
																style={{ width: 200, backgroundColor: 'green' }}
																onClick={() => {
																	setItems({
																		...items,
																		statistics: true,
																		questionnaireId: item._id,
																	});
																}}>
																Filled Details
															</Button>

															<Button
																className={classNames.reloadButton}
																style={{ width: 200, backgroundColor: 'green' }}
																onClick={() => {
																	trailAllotment(item._id);
																}}>
																Trial Allotment
															</Button>
															<Button
																className={classNames.reloadButton}
																style={{ width: 200, backgroundColor: 'green' }}
																onClick={() => {
																	trailAllotment(item._id, true);
																}}>
																Final Allotment
															</Button>
															<Button
																className={classNames.reloadButton}
																style={{ width: 200, backgroundColor: 'green' }}
																onClick={() => {
																	trailAllotment(item._id, 'real');
																}}>
																Real Class Romm List
															</Button>
														</div>
														<div
															style={{
																width: '100%',
																display: 'flex',
																height: 1,
																backgroundColor: 'black',
															}}></div>
													</>
												))}
											</Scrollbars>
										</div>
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			<div className={classNames.absoluteDiv}>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '30%' }}>
						<Select
							showSearch
							className={classNames.formFullWidth}
							style={{ overflow: 'hidden' }}
							onChange={(val) => {
								setState({ ...state, filterType: val });
							}}>
							{filterTypeDrops?.map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))}
						</Select>
						<label>Filter Type</label>
					</div>
				</Formik>
				<Formik enableReinitialize>
					<div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '60%' }}>
						<Field
							type='text'
							style={{ width: '100%' }}
							name='year'
							id='year'
							value={state.searchValue}
							onChange={(e) => {
								setState({ ...state, searchValue: e.target.value });
							}}
						/>
						<label>Search by {state?.searchType ? state?.searchType : 'All'}</label>
					</div>
				</Formik>
				<Button
					className={classNames.reloadButton}
					onClick={() => {
						setItems({ ...items, newQuestionnaire: true });
					}}>
					New
				</Button>
				<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
			{items.newQuestionnaire && (
				<ModalWrapper>
					{' '}
					<NewQuestionnaire
						onCloseModal={() => setItems({ ...items, newQuestionnaire: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, newQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.settupQuestionnaire && (
				<ModalWrapper>
					{' '}
					<SettupQuestionnaire
						onCloseModal={() => setItems({ ...items, settupQuestionnaire: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, settupQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.questionnaireAnalysis && (
				<ModalWrapper>
					{' '}
					<AnalysisQuestionnaire
						onCloseModal={() => setItems({ ...items, questionnaireAnalysis: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, settupQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.statistics && (
				<ModalWrapper>
					{' '}
					<Statistics
						onClose={() => setItems({ ...items, statistics: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, statistics: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
		</div>
	);
};
