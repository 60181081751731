import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getProfile } from '../../queryHooks';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { getAllMinor, getDropdownData, initializePaymentRazorpay } from '../../pages/college/ApiServices';
import Input from '../../NecttosComp/Input/Input';
import { getCollegeDetails } from './Register';
import { uploadFileToFirebase, uploadPhotoToFirebase } from '../../NecttosComp/APICaller/APICaller';
import Button from '../../NecttosComp/Button/Button';
import { DegreeSubjectInputSet, PercentageCalculatorProfile, ProgressCard, SectionHeader, SubjectInputSet, degree, getQuotaTypes, plustwo, quotaDocuments, quotaDocumentsfalse } from './Component';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import RenderRazorpay from './Razorpay';
import ApplicationPrint from './ApplicationPrint';
import MCAApplication from './MCAApplication';

interface ApplicationOpenProps {
	handleLogout?: () => void;
	initCollegeId: string;
	collegeData: any;
	type?: any;
	userId?: string;
	viewMode?: boolean;
	managedBy?: string;
	paramsRecieved?: any;
}
interface Amount {
	amount: number;
	currencyCode: string;
}

const ApplicationOpen: React.FC<ApplicationOpenProps> = ({ collegeData, paramsRecieved, userId, managedBy, viewMode, handleLogout, initCollegeId }) => {
	// console.log('params receieved', paramsRecieved);

	const [address, setAddress] = useState<boolean>(false);
	const [billAmount, setBillAmount] = useState<number>(300);
	const [isError, setIsError] = useState<boolean>(false);
	const [needNecttosPay, setNecttosPay] = useState<boolean>(false);
	const [params, setParams] = useState<{ type: string; id: string }>({
		type: '',
		id: '',
	});
	const [razorpay, setRazorpay] = useState<any>({ displayRazorpay: false });
	const [uploadProgress, setUploadProgress] = useState<any>();
	const [apiResponds, setApiResponds] = useState('');
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);

	const [autonumus, setAutonoumus] = useState<boolean>(false);
	useEffect(() => {
		if (collegeData?.autonomus) {
			setAutonoumus(true);
			if (params?.type?.toUpperCase() === 'MCA') {
				setAutonoumus(false);
			}
		}
	}, [collegeData?.autonomus, params]);

	const [neeedFYUGP, setNeedFYUGP] = useState<boolean>(false);

	useEffect(() => {
		if (['COL-AF7BBD488D81-439A-BC17-53A7FC0DC496']?.includes(initCollegeId)) {
			if (params?.type?.toUpperCase() === 'UG') {
				setNeedFYUGP(true);
			}
		}
	}, [initCollegeId, params]);

	const [values, setValues] = useState<any>({
		certificates: {
			class_10_marksheet: true,
			class_12_marksheet: true,
			community_caste_certificate: false,
			sports_quota_proof: false,
			cultural_quota_proof: false,
			disability_quota_certificate: false,
			ncc_a_certificate: false,
			ncc_b_certificate: false,
			nss_certificate: false,
			ex_military_certificate: false,
			scouts_guides_certificate: false,
			student_police_cadet_certificate: false,
			lakshadweep_nomination_letter: false,
			non_creamy_layer_certificate: false,
			ews_certificate: false,
			andaman_nicobar_quota_certificate: false,
			third_gender_quota_certificate: false,
			community_quota_certificate: false,
			other_boards_eligibility_certificate: false,
		},
		admissionto: {
			meritQuata: true,
		},
	});

	const [categoryData, setCategoryData] = useState([]);
	const [casteData, setCasteData] = useState([]);
	const [selectedReligion, setSelectedReligion] = useState('');
	const [optionalSubjects, setOptionalSubject] = useState<any[]>([]);
	const [diclarationFinal, setDiclarationFinal] = useState<boolean>(false);
	const [classData, setClassData] = useState([]);
	const [verification, setVerification] = useState<boolean>(false);

	let param: { id: string; type: string } = useParams();

	useEffect(() => {
		const newParams = param.type ? param : paramsRecieved;
		if (newParams) {
			setParams(newParams);
		}
	}, [param, paramsRecieved]);

	const { data, refetch } = useQuery([`profileData`, initCollegeId, userId], getProfile);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	useEffect(() => {
		setSelectedReligion(values?.religion);
		setCasteData([]);
		setCategoryData(dropDowns[2]?.RELIGION[0][values?.religion + 'RESERVATIONCATEGORY']);
		setValues({
			religion: values?.religion,
			category: '',
			caste: '',
			...values,
		});
	}, [values?.religion, dropDowns]);

	useEffect(() => {
		setCasteData(dropDowns[2]?.RELIGION?.[0]?.[selectedReligion + 'CAST']?.[0]?.[values?.category]);
		setValues({ category: values?.category, caste: '', ...values });
	}, [values?.category, dropDowns]);

	useEffect(() => {
		setValues({
			...data,
			certificates: {
				...data?.certificates,
				class_10_marksheet: true,
				class_12_marksheet: true,
			},
			admissionto: {
				...data?.admissionto,
				meritQuata: true,
			},
		});
	}, [data]);

	const finalyseAPICall = useCallback(async () => {
		try {
			const instance = await getAxiosTokenInstance();

			const resp = await instance.post('/college/admissionProcess/unfinalyse', {
				_id: values?._id,
				boolType: true,
				classId: values?.dsc_a || '',
				collegeId: initCollegeId,
				managedBy,
				type: params?.type?.toUpperCase(),
			});

			refetch();
			return resp.data;
		} catch (error) {
			refetch();
		}
	}, [values?._id, values?.dsc_a, initCollegeId, managedBy, getAxiosTokenInstance]);

	const saveData = useCallback(async () => {
		const instance = await getAxiosTokenInstance();
		try {
			if (viewMode) {
				console.log('eeerree');
			} else {
				console.log('vvvvvvv');
				const resp = await instance.post('/college/profile/autoUpdateProfile', values);
				if (resp?.data?.statusCode === 400) {
					setIsError(true);
					setApiResponds(`${moment(new Date()).format('hh:mm:ss A')}`);
				} else {
					setApiResponds(`${moment(new Date()).format('hh:mm:ss A')}`);
					setIsError(false);
					return resp?.data;
				}
			}
		} catch (error) {
			setIsError(true);
			setApiResponds(`${moment(new Date()).format('hh:mm:ss A')}`);
		}
	}, [values]);

	const debouncedSaveData = useCallback(
		debounce(saveData, 1000, {
			leading: false,
			trailing: true,
		}),
		[saveData],
	);
	useEffect(() => {
		debouncedSaveData();
		return () => debouncedSaveData.cancel();
	}, [debouncedSaveData]);

	useEffect(() => {
		if (address) {
			setValues({
				...values,
				houseName: values?.permanentHouseName,
				place: values?.permanentPlace,
				postOffice: values?.permanentPostOffice,
				district: values?.permanentDistrict,
				state: values?.permanentState,
				nationality: values?.permanentNationality,
				pinCode: values?.permanentPinCode,
				village: values?.permanentVillage,
				taluk: values?.permanentTaluk,
				panchayatMunicipalityCorporation: values?.permanentPanchayatMunicipalityCorporation,
			});
		}
	}, [address]);

	useEffect(() => {
		setOptionalSubject([values?.subject3Name, values?.subject4Name, values?.subject5Name, values?.subject6Name]);
	}, [values?.subject3Name, values?.subject4Name, values?.subject5Name, values?.subject6Name]);

	console.log({ params });

	const { data: classes } = useQuery([`collegeData`, initCollegeId, params?.type?.toUpperCase() === 'UG' ? '2024-2028' : '2024-2026', optionalSubjects, params?.type?.toUpperCase()], getCollegeDetails);
	const [splitUps, setSplitups] = useState<any[]>([]);

	useEffect(() => {
		if (values.dsc_a && classes) {
			const classSplit = classes.find((x: any) => x._id === values.dsc_a);
			if (classSplit) {
				setSplitups(classSplit.splitKey || []);
			} else {
				setSplitups([]);
			}
		} else {
			setSplitups([]);
		}
	}, [classes, values.dsc_a]);

	const courseKeys = ['preferedCourse1', 'preferedCourse2', 'preferedCourse3', 'preferedCourse4', 'preferedCourse5', 'preferedCourse6', 'preferedCourse7', 'preferedCourse8', 'preferedCourse9', 'preferedCourse10'];
	const dataValues = useMemo(() => {
		return courseKeys?.map((key) => values[key])?.filter(Boolean);
	}, [values]);

	const newClassData = useMemo(() => {
		return classes?.filter((classItem: any) => !dataValues.includes(classItem._id));
	}, [classes, dataValues]);

	useEffect(() => {
		if (newClassData?.length !== classData?.length) {
			setClassData(newClassData);
		}
	}, [newClassData, classData?.length]);

	const [isLoadingObj, setIsLoadingObj] = useState<any>({});

	const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>, documentId: string) => {
		const files = event.target.files;
		// console.log('photo-----------');

		if (files && files[0]) {
			setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: true }));
			setUploadProgress((prev: any) => ({ ...prev, [documentId]: 0 }));
			(async () => {
				try {
					const { url } = await uploadPhotoToFirebase(files[0], `users/${values?.uid}/avatar.jpg`, (progress) => {
						setUploadProgress((prev: any) => ({
							...prev,
							[documentId]: progress,
						}));
					});
					setValues((prev: any) => ({
						...prev,
						photoURL: url,
					}));
					setUploadProgress((prev: any) => ({ ...prev, [documentId]: 100 }));
					setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
				} catch (error) {
					setUploadProgress((prev: any) => ({ ...prev, [documentId]: 0 }));
					setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
				}
			})();
		} else {
			setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
		}
	};

	const handleSignatureChange = (event: React.ChangeEvent<HTMLInputElement>, documentId: string) => {
		const files = event.target.files;
		// console.log('signature-----------');

		if (files && files[0]) {
			setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: true }));
			setUploadProgress((prev: any) => ({ ...prev, [documentId]: 0 }));
			(async () => {
				try {
					const { url } = await uploadPhotoToFirebase(files[0], `users/${values?.uid}/signature.jpg`, (progress) => {
						setUploadProgress((prev: any) => ({
							...prev,
							[documentId]: progress,
						}));
					});
					setValues((prev: any) => ({
						...prev,
						signature: url,
					}));
					setUploadProgress((prev: any) => ({ ...prev, [documentId]: 100 }));
					setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
				} catch (error) {
					console.error('Error uploading file:', error);
					setUploadProgress((prev: any) => ({ ...prev, [documentId]: 0 }));
					setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
				}
			})();
		} else {
			setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
		}
	};

	const certificateOptions = params?.type?.toUpperCase() === 'MCA' ? dropDowns?.[21]?.certificatesMCA : params?.type?.toUpperCase() === 'PG' ? dropDowns?.[21]?.certificatesPG : dropDowns?.[21]?.certificates || [];

	const certificateBonusOptions = dropDowns?.[21]?.bonusCertificate || [];

	useEffect(() => {
		if (!autonumus) {
			certificateBonusOptions.push({
				key: 'cultural',
				title: 'Cultural State Level',
			});
		}
	}, [dropDowns?.[21]?.bonusCertificate]);

	useEffect(() => {
		const newState: { [key: string]: boolean } = {};

		certificateOptions?.forEach((element: any) => {
			newState[`${element.key}URL`] = false;
		});

		setIsLoadingObj(newState);
	}, [certificateOptions, setIsLoadingObj]);

	const handleCheckboxChange = (event: any) => {
		setValues({
			...values,
			certificates: {
				...values?.certificates,
				[event.target.name]: event.target.checked,
			},
		});
	};

	const handleCheckboxChangeAdmision = (event: any) => {
		const { name, checked } = event.target;

		const documentsToUpdate = checked ? quotaDocuments?.[name] : quotaDocumentsfalse?.[name];

		setValues((prevValues: any) => ({
			...prevValues,
			admissionto: {
				...prevValues?.admissionto,
				[name]: checked,
			},
			certificates: {
				...prevValues.certificates,
				class_10_marksheet: false,
				class_12_marksheet: false,
				...documentsToUpdate,
			},
		}));
	};

	const componentRef = useRef<any>();
	const componentRef2 = useRef<any>();

	const renderFilePreview = (fileURL: string, title: string) => {
		if (fileURL.includes('p1d2f3')) {
			return (
				// <object data={fileURL} type="application/pdf" width="100%" height="520px">
				<iframe src={fileURL} width='100%' height='400px'>
					<p>
						Your browser does not support PDFs. <a href={fileURL}>Download the PDF</a>.
					</p>
				</iframe>
				//</object>
			);
		} else {
			return <img src={fileURL} alt={`Uploaded ${title}`} className='max-w-xs rounded-md border border-gray-200' />;
		}
	};

	const degreeFields = [
		{ key: 'degreeInstitutionName', title: 'Institution Name' },
		{ key: 'degreeRegisterNo', title: 'Register No' },
		{
			key: 'degreeCourse',
			title: 'Course',
			options: dropDowns?.[4]?.qualification?.[0]?.UG?.Course,
		},
		{
			key: 'degreeUniversity',
			title: 'University',
			options: dropDowns?.[4]?.qualification?.[0]?.UG?.University,
		},
		{
			key: 'degreeSpecialisation',
			title: 'Specialisation',
			options: dropDowns?.[4]?.qualification?.[0]?.UG?.Specialisation,
		},
		{ key: 'degreeCoursePattern', title: 'Course Pattern' },
		{ key: 'degreeYearOfPass', title: 'Year of Pass' },
		{ key: 'degreePercentage', title: 'Percentage' },
		{ key: 'degreeMarksObtained', title: 'CGPA/AGREE.marks obtained' },
		{ key: 'degreeMaxMarks', title: 'Maximum CGPA/Marks' },
	];
	const fieldDefinitions = [
		{ key: 'name', title: 'Name (As per SSLC)' },
		{ key: 'aadhaarNumber', title: 'Aadhaar Number' },
		{ key: 'email', title: 'Email' },
		{ key: 'whatsappNumber', title: 'WhatsApp Number' },
		{ key: 'gender', title: 'Gender' },
		{ key: 'dob', title: 'Date of Birth' },
		{ key: 'motherTongue', title: 'Mother Tongue' },
		{ key: 'gardianName', title: 'Guardian Name' },
		{ key: 'guardianMobile', title: 'Guardian Mobile' },
		...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA'
			? [
					{ key: 'meritalStatus', title: 'Marital Status' },
					{ key: 'annualIncomeFigure', title: 'Annual Income in Figure' },
					{ key: 'bloodGroup', title: 'Blood Group' },
					{ key: 'placeOfBirth', title: 'Place of Birth' },
			  ]
			: []),
		{ key: 'disabilities', title: 'Do you have disabilities?' },
		...(values.disabilities === 'Yes' ? [{ key: 'disabilityStatus', title: 'Select your Disability' }] : []),
		{ key: 'religion', title: 'Religion' },
		{ key: 'category', title: 'Category' },
		{ key: 'caste', title: 'Caste' },
	];

	const addressFields = [
		{ key: 'houseName', title: 'House Name' },
		{ key: 'place', title: 'Place' },
		{ key: 'postOffice', title: 'Post Office' },
		...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA'
			? [
					{ key: 'village', title: 'Village' },
					{
						key: 'panchayatMunicipalityCorporation',
						title: 'Panchayat Municipality Corporation',
					},
			  ]
			: []),
		{ key: 'nationality', title: 'Nationality' },
		{ key: 'state', title: 'State' },
		{ key: 'district', title: 'District' },
		{ key: 'pinCode', title: 'Pincode' },
	];

	const permanentAddressFields = [
		{ key: 'permanentHouseName', title: 'House Name' },
		{ key: 'permanentPlace', title: 'Place' },
		{ key: 'permanentPostOffice', title: 'Post Office' },
		...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA'
			? [
					{ key: 'permanentVillage', title: 'Village' },
					{
						key: 'permanentPanchayatMunicipalityCorporation',
						title: 'Panchayat Municipality Corporation',
					},
			  ]
			: []),
		{ key: 'permanentNationality', title: 'Nationality' },
		{ key: 'permanentState', title: 'State' },
		{ key: 'permanentDistrict', title: 'District' },
		{ key: 'permanentPinCode', title: 'Pincode' },
	];

	const sslcFields = [{ key: 'sslcInstitutionName', title: 'Institution Name' }, { key: 'sslcRegisterNo', title: 'Register No' }, { key: 'sslcBoard', title: 'Selected Board' }, ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? [{ key: 'sslcPercentage', title: 'Percentage' }] : []), { key: 'sslcYearOfPass', title: 'Year of Pass' }];
	const plusTwoFields = [{ key: 'plusTwoInstitutionName', title: 'Institution Name' }, { key: 'plusTwoRegisterNo', title: 'Register No' }, { key: 'plusTwoState', title: 'Institution State' }, { key: 'plusTwoBoard', title: 'Course' }, { key: 'plusTwoStream', title: 'Stream' }, ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? [{ key: 'plusTwoPercentage', title: 'Percentage' }] : []), { key: 'totalMarkObtaioned', title: 'Total Mark Obtained' }, { key: 'totalMarks', title: 'Total Marks' }, { key: 'plusTwoYearOfPass', title: 'Year of Pass' }, { key: 'plusTwoNoOfChance', title: 'No of Chances (as given certificate)' }, { key: 'isBonusMark', title: 'Are You Eligible for Bonus Marks?' }];
	const basicKey = ['photoURL', 'name', 'aadhaarNumber', 'email', 'whatsappNumber', 'gender', 'dob', 'motherTongue', 'gardianName', 'guardianMobile', 'disabilities', 'religion', 'category', 'caste', 'guardianRelation', 'guardianOccupation', ...(values?.disabilities === 'Yes' ? ['disabilityStatus', 'disabilityPercentage'] : []), ...(values?.siblingsCollege === 'Yes' ? ['programOfSibling', 'nameOfSibling'] : []), ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? ['bloodGroup', 'meritalStatus', 'placeOfBirth', 'annualIncomeFigure', 'siblingsCollege'] : []), ...(params?.type?.toUpperCase() === 'MCA' ? ['signature'] : []), ...(values.lbsMcaTest === 'Yes' ? [''] : [])];
	const addressKey = ['houseName', 'place', 'postOffice', 'nationality', 'state', 'district', 'pinCode', ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? ['village', 'panchayatMunicipalityCorporation', 'taluk'] : [])];
	const permanentAddressKey = ['permanentHouseName', 'permanentPlace', 'permanentPostOffice', 'permanentNationality', 'permanentState', 'permanentDistrict', 'permanentPinCode', ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? ['permanentVillage', 'permanentPanchayatMunicipalityCorporation', 'permanentTaluk'] : [])];
	const sslcKey = ['sslcInstitutionName', 'sslcRegisterNo', 'sslcBoard', 'sslcYearOfPass', ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? ['sslcPercentage'] : [])];
	const plusTwoKey = ['plusTwoInstitutionName', 'plusTwoRegisterNo', 'plusTwoState', 'plusTwoBoard', 'plusTwoStream', 'totalMarkObtaioned', 'totalMarks', 'plusTwoYearOfPass', 'plusTwoNoOfChance', ...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA' ? ['plusTwoPercentage'] : [])];
	const degreeKey = ['degreeInstitutionName', 'degreeRegisterNo', 'degreeCourse', 'degreeUniversity', 'degreeSpecialisation', 'degreeYearOfPass', 'degreeScheme', 'degreeInstState', 'degreeMarkSystem', 'degreeNoOfChance', ...(params?.type?.toUpperCase() === 'MCA' ? ['degreePercentage'] : []), ...(params?.type?.toUpperCase() === 'MCA' ? ['degreeGrade'] : []), ...(params?.type?.toUpperCase() !== 'MCA' ? ['isBonusMark'] : [])];
	const preferenceKey = ['preferedCourse1'];

	const mdcPercentage = ['mdc_1', 'mdc_2', 'mdc_3'];
	const dscAPercentage = ['dsc_a', ...(params.type.toUpperCase() === 'MCA' ? ['admnType', 'lbsMcaMark', 'lbsRollNo', 'lbsMcaRank'] : [])];
	const dscbPercentage = ['dsc_b_1'];
	const dsccPercentage = ['dsc_c_1'];

	const familykey = ['name', 'qualification', 'relation', 'job', 'mobile'];
	const travelKey = ['typeOfVehicle', 'travelStart', 'travelTo', 'km'];
	const culturalKey = ['item', 'type', 'specialised'];

	const [showModal, setShowModal] = useState(false);
	const StandardButton = ({ onClick, children, className, disabled }: any) => (
		<button disabled={disabled} onClick={onClick} title={disabled ? 'This button is disabled' : ''} className={`whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border text-white border-transparent rounded-md shadow-sm text-base font-medium ${disabled ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${className}`}>
			{children}
		</button>
	);
	const StandardButton2 = ({ onClick, children, className, disabled }: any) => (
		<button disabled={disabled} onClick={onClick} title={disabled ? 'This button is disabled' : ''} className={`whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border text-white border-transparent rounded-md shadow-sm text-base font-medium ${disabled ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${className}`}>
			{children}
		</button>
	);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, documentId: string) => {
		const files = event.target.files;
		console.log('files', files);

		if (files && files[0]) {
			setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: true }));
			setUploadProgress((prev: any) => ({ ...prev, [documentId]: 0 }));
			(async () => {
				try {
					const { url, fileId } = await uploadFileToFirebase(files[0], values?._id, documentId, (progress) => {
						setUploadProgress((prev: any) => ({
							...prev,
							[documentId]: progress,
						}));
					});
					console.log('url', url);
					let updUrl = url;
					if (files && files[0]) {
						if (files[0].type === 'application/pdf') {
							updUrl += 'p1d2f3';
						} else {
							updUrl = updUrl;
						}
					}

					setValues((prev: any) => ({
						...prev,
						certificates: {
							...prev.certificates,
							[documentId]: updUrl,
						},
					}));
					setUploadProgress((prev: any) => ({ ...prev, [documentId]: 100 }));
					setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
				} catch (error) {
					console.error('Error uploading file:', error);
					setUploadProgress((prev: any) => ({ ...prev, [documentId]: 0 }));
					setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
				}
			})();
		} else {
			setIsLoadingObj((prev: any) => ({ ...prev, [documentId]: false }));
		}
	};

	const [appNo, setAppNo] = useState<string>('');

	useEffect(() => {
		let appNio = '';
		if (initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B') {
			appNio = values?.applicationNo?.toString();
		} else if (initCollegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F') {
			const paddedApplicationNo = values?.applicationNo?.toString().padStart(4, '0');
			appNio = params?.type?.toUpperCase() === 'UG' ? '241' + paddedApplicationNo : '242' + paddedApplicationNo;
		}
		setAppNo(appNio);
	}, [values?.applicationNo, initCollegeId, params.type]);

	useEffect(() => {
		let newBillAmount = 0;

		if (initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B') {
			newBillAmount = 300;
		} else if (initCollegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F') {
			newBillAmount = 250;
		}
		if (['SC', 'ST'].includes(values?.category)) {
			newBillAmount = initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B' ? 150 : 100;
		}
		if (values?.admissionto?.communityQuata) {
			newBillAmount += initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B' ? 200 : 0;
		}
		if (values?.admissionto?.managementQuata) {
			newBillAmount += initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B' ? 500 : 100;
			if (initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B') {
				if (!values?.admissionto?.meritQuata) newBillAmount = newBillAmount - 300;
			}
		}
		if (values?.admissionto?.communityQuata) {
			if (initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B') {
				if (!values?.admissionto?.meritQuata) newBillAmount = newBillAmount - 300;
			}
		}
		if (!autonumus) {
			newBillAmount = 400;
		}
		setBillAmount(newBillAmount);
	}, [values?.admissionto, values?.category, initCollegeId, autonumus]);

	const handlePaymentRazorpay = async () => {
		setIsLoadingObj((prev: any) => ({ ...prev, paymentLoadingRazor: false }));
		const amount: Amount = {
			amount: billAmount,
			currencyCode: 'INR',
		};
		const order = await initializePaymentRazorpay(amount, initCollegeId, values?._id, collegeData?.onlineFeeId);
		setRazorpay((prev: any) => ({ ...prev, order, displayRazorpay: true }));
		setIsLoadingObj((prev: any) => ({ ...prev, paymentLoading: false }));
	};

	const [documentKey, setDocumentKeys] = useState([]);
	const [documentBonafiedKey, setBonafied] = useState([]);

	useEffect(() => {
		if (!certificateBonusOptions || !Array.isArray(certificateBonusOptions)) {
			console.error('Invalid certificateBonusOptions:', certificateBonusOptions);
			return;
		}
		const newDocumentKeys = certificateBonusOptions.reduce((acc, certificate) => {
			if (certificate?.key && values?.certificates?.[certificate.key]) {
				acc.push(`${certificate.key}URL`);
			}
			return acc;
		}, []);
		setBonafied(newDocumentKeys);
	}, [values?.certificates, certificateBonusOptions]);

	useEffect(() => {
		if (!certificateOptions || !Array.isArray(certificateOptions)) {
			console.error('Invalid certificateBonusOptions:', certificateOptions);
			return;
		}
		const newDocumentKeys = certificateOptions.reduce((acc, certificate) => {
			if (certificate?.key && values?.certificates?.[certificate.key]) {
				acc.push(`${certificate.key}URL`);
			}
			return acc;
		}, []);
		setDocumentKeys(newDocumentKeys);
	}, [values?.certificates, certificateOptions]);

	const sections = [
		{ title: 'Personal Details', keyName: basicKey, color: '#7DCEA0' },
		{
			title: 'Permanent Address',
			keyName: permanentAddressKey,
			color: '#85C1E9',
		},
		{ title: 'Commun. Address', keyName: addressKey, color: '#F7DC6F' },
		...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA'
			? [
					{
						title: 'Family Details',
						keyName: familykey,
						array: 'family',
						color: '#D7BDE2',
					},
					{
						title: 'Mode of Travel',
						keyName: travelKey,
						array: 'travelDetails',
						color: '#76D7C4',
					},
			  ]
			: []),
		{ title: '10th Qualification', keyName: sslcKey, color: '#AED6F1' },
		{ title: 'Plus Two Qualification', keyName: plusTwoKey, color: '#A9CCE3' },
		...(['PG', 'MCA'].includes(params?.type?.toUpperCase())
			? [
					{
						title: 'Degree Qualification',
						keyName: degreeKey,
						color: '#E59866',
					},
			  ]
			: []),
		...(values?.isBonusMark === 'Yes'
			? [
					{
						title: 'Bonus Certificate',
						keyName: documentBonafiedKey,
						object: 'certificates',
						color: '#C39BD3',
					},
			  ]
			: []),
		{
			title: 'Certificates',
			keyName: documentKey,
			object: 'certificates',
			color: '#C39BD3',
		},
		...(autonumus
			? [
					{
						title: 'Programme Selection',
						keyName: preferenceKey,
						color: '#ABEBC6',
					},
			  ]
			: [
					...(neeedFYUGP
						? [
								{
									title: 'Programme Selection',
									keyName: [...mdcPercentage, ...dscbPercentage, ...dsccPercentage],
									color: '#ABEBC6',
								},
						  ]
						: [
								{
									title: 'Programme Selection',
									keyName: [...dscAPercentage],
									color: '#ABEBC6',
								},
						  ]),
			  ]),
		...(autonumus
			? [
					{
						title: 'Payment Status',
						keyName: ['recieved'],
						object: 'applicationAmount',
						color: '#EDBB99',
					},
			  ]
			: needNecttosPay
			? [
					{
						title: 'Payment Status',
						keyName: ['transactionIdOfPayemnt'],
						color: '#EDBB99',
					},
			  ]
			: []),
	];

	const percentageFilled = PercentageCalculatorProfile({
		values,
		keyName: [...basicKey, ...addressKey, ...permanentAddressKey, ...sslcKey, ...plusTwoKey, ...(needNecttosPay ? ['transactionIdOfPayemnt'] : []), ...(params?.type?.toUpperCase() === 'PG' ? [...degreeKey] : []), ...(autonumus ? [...preferenceKey] : [...(neeedFYUGP ? [...mdcPercentage, ...dscbPercentage, ...dsccPercentage] : [...dscAPercentage])])],
		...(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA'
			? {
					arrayKey: [
						{ title: 'family', values: familykey },
						{ title: 'travelDetails', values: travelKey },
					],
			  }
			: {}),
		bbbbdddd: [...(values?.isBonusMark === 'Yes' ? [{ values: documentBonafiedKey, title: 'certificates' }] : []), { values: documentKey, title: 'certificates' }, ...(autonumus ? [{ values: ['recieved'], title: 'applicationAmount' }] : [])],
	});

	const onAddClick = (type: string) => {
		const currentData = Array.isArray(values[type]) ? values[type] : [];
		console.log('====', type, currentData);

		if (type === 'family') {
			if (currentData.length === 0) {
				const newData = [...currentData, { relation: 'Father' }];
				setValues({ ...values, [type]: newData });
			} else {
				const newData = [...currentData, {}];
				setValues({ ...values, [type]: newData });
			}
		} else {
			const newData = [...currentData, {}];
			setValues({ ...values, [type]: newData });
		}
	};

	const onDelete = (index: any, arrayName: any) => {
		setValues((prevValues: any) => {
			const updatedArray = [...prevValues[arrayName]];
			updatedArray.splice(index, 1);
			return { ...prevValues, [arrayName]: updatedArray };
		});
	};

	useEffect(() => {
		setValues((prevValues: any) => ({
			...prevValues,
			percentageFilled: percentageFilled,
		}));
	}, [percentageFilled]);

	useEffect(() => {
		const updateProfile = async () => {
			try {
				const instance = await getAxiosTokenInstance();
				const resp = await instance.post('/college/profile/autoUpdateProfilePerc', { _id: values._id, percentageFilled });
			} catch (error) {
				console.error('Error updating profile:', error);
			}
		};

		if (values._id) {
			updateProfile();
		}
	}, [percentageFilled, values._id]);

	const updateProfilePercentage = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			const resp = await instance.post('/college/profile/autoUpdateProfilePerc', { _id: values._id, percentageFilled });
		} catch (error) {
			console.error('Error updating profile:', error);
		}
	};

	const [quotaHide, setQuotaHide] = useState<boolean>(true);

	useEffect(() => {
		if (initCollegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F') {
			if (params?.type?.toUpperCase() === 'UG') {
				setQuotaHide(true);
			}
			if (params?.type?.toUpperCase() === 'PG') {
				setQuotaHide(false);
			}
		}
	}, [initCollegeId, params]);

	useEffect(() => {
		if (['COL-4E56593776BE-41C3-982A-0B6DC20E44A0'].includes(initCollegeId)) {
			setNecttosPay(true);
		} else {
			setNecttosPay(false);
		}
	}, [initCollegeId]);

	const { data: minorData = [] } = useQuery('getAllMinor', getAllMinor(initCollegeId));

	const DSC_B = minorData?.filter((x: any) => x.type === 'DSC_B' && x.semester === 'Semester 1');
	const DSC_C = minorData?.filter((x: any) => x.type === 'DSC_C' && x.semester === 'Semester 1');
	const MDC = minorData?.filter((x: any) => x.type === 'MDC' && x.semester === 'Semester 1');

	const [showModalPayment, setShowModalPayment] = useState(false);

	const getQRCodeUrl = useMemo(() => {
		switch (billAmount) {
			case 300:
				return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FAPPL.jpeg?alt=media&token=2be456e4-58ca-47cb-87f3-721ec7888260';
			case 500:
				if (values?.admissionNo?.communityQuata) {
					return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FCOMMUNITY%20MERIT.jpeg?alt=media&token=1cbc124b-cd32-42ee-a6ea-d3ac6f5032bf';
				} else {
					return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FMGMT.jpeg?alt=media&token=f87b9fc2-e1f1-4bf8-9390-2735efba4b5f';
				}
			case 200:
				return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FCOMT.jpeg?alt=media&token=7efb7486-fd86-4271-bd43-8142f71388ef';
			case 150:
				return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FSCST.jpeg?alt=media&token=5554aaa0-7438-4b27-9e0f-a3fffd6040c6';
			case 800:
				return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FMERIT%20MANAGEMENT.jpeg?alt=media&token=df7f71ca-0f64-478b-80fd-89b99458313e';
			case 700:
				return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FCOMMUNITY%20MANAGEMENT.jpeg?alt=media&token=6c89957b-c22b-4e08-85da-79f773253ee3';
			case 1000:
				return 'https://firebasestorage.googleapis.com/v0/b/necttos-production.appspot.com/o/STXAVIERSQRCODE%2FMERIT%20MANAGEMENT%20COMMUNITY.jpeg?alt=media&token=83bd342e-718a-4942-849f-c77d211841db';
			default:
				return '';
		}
	}, [billAmount, values?.admissionNo?.communityQuata]);

	const handleOpenModal = () => {
		setShowModalPayment(true);
	};

	const handleCloseModal = () => {
		setShowModalPayment(false);
	};

	useEffect(() => {
		onAddClick('family');
	}, []);
	useEffect(() => {
		if (values?.family?.length > 0) {
			let fatherExists = values.family.some((x: any) => x.relation === 'Father');
			let motherExists = values.family.some((x: any) => x.relation === 'Mother');

			if (!fatherExists || !motherExists) {
				let family = [...values.family];

				if (!fatherExists) {
					family.push({
						relation: 'Father',
						name: '',
						occupation: '',
						phoneNumber: '',
					});
				}

				if (!motherExists) {
					family.push({
						relation: 'Mother',
						name: '',
						occupation: '',
						phoneNumber: '',
					});
				}

				setValues({ ...values, family });
			}
		}
	}, [values?.family]);

	return showModalPayment ? (
		<div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center'>
			<div className='bg-white p-5 rounded-lg'>
				<h3 className='text-lg font-bold mb-4'>Scan QR Code to Pay</h3>
				<img src={getQRCodeUrl} alt='QR Code' />
				<div className='text-center mt-4'>
					<button onClick={handleCloseModal} className='px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition duration-150 ease-in-out'>
						Close
					</button>
				</div>
			</div>
		</div>
	) : (
		<div className='flex justify-center lg:h-[100%] row'>
			<div className='bg-[#013E7F] shadow-lg sticky top-0 z-50 w-full'>
				{!values?.finalizedApplication ? (
					<>
						<div className='flex col items-center justify-between px-1 py-1'>
							<div className='relative w-16 h-16'>
								<CircularProgressbar
									value={100}
									styles={buildStyles({
										pathColor: '#4caf50',
										trailColor: '#d6d6d6',
										textColor: '#212529',
										textSize: '0px',
										pathTransitionDuration: 0.5,
									})}
								/>
								<div className='absolute inset-0 flex justify-center self-center items-center'>
									<div className='w-[80%]'>
										<div className='text-[8px] text-gray-50 font-medium text-center'>All Data Saved @ {apiResponds}</div>
									</div>
								</div>
							</div>
							<a className='flex-shrink-0'>
								<img className='h-12 w-auto sm:h-12 md:h-16' src={collegeData?.dashBoardLink} alt='College Logo' />
							</a>
							<div className='relative w-16 h-16'>
								<CircularProgressbar
									value={percentageFilled}
									styles={buildStyles({
										pathColor: '#4caf50',
										trailColor: '#d6d6d6',
										textColor: '#212529',
										textSize: '0px',
										pathTransitionDuration: 0.5,
									})}
								/>
								<div className='absolute inset-0 flex justify-center items-center'>
									<div className='w-[80%]'>
										<div className='text-[8px] text-gray-50 font-medium text-center'>Appl. Completed {percentageFilled}%</div>
									</div>
								</div>
							</div>
						</div>
						<div className='grid grid-cols-6 md:grid-cols-6 lg:grid-cols-6 gap-2 bg-[#78b7fb39] justify-center items-center'>
							<a href='#basicDetails' className='text-[8px] text-center sm:text-base font-medium  text-white hover:text-gray-900'>
								Personal Details
							</a>
							<a href='#education' className='text-[8px] text-center sm:text-base font-medium bg-[#00bcfb8a]  text-white hover:text-gray-900'>
								Educational Qualification
							</a>
							<a href='#certificates' className='text-[8px] text-center sm:text-base font-medium text-white hover:text-gray-900'>
								Documents Uploads
							</a>
							<a href='#preference' className='text-[8px] text-center sm:text-base font-medium bg-[#00bcfb8a]  text-white hover:text-gray-900'>
								Programme Selection
							</a>
							<a href='#payments' className='text-[8px] text-center sm:text-base font-medium text-white hover:text-gray-900'>
								Fees Payments
							</a>
							<a onClick={handleLogout} className='text-[8px] text-center sm:text-base  bg-[#fb00008a]  font-medium text-white hover:text-gray-900'>
								{userId ? 'Go Previous Menu' : 'Logout'}
							</a>
						</div>
					</>
				) : (
					<a href='/' className='flex  justify-center items-center mt-[10%]'>
						<img className='h-12 w-auto sm:h-12 md:h-16' src={collegeData?.dashBoardLink} alt='College Logo' />
					</a>
				)}
			</div>
			<>
				{values?.finalizedApplication ? (
					<div className='flex flex-col items-center p-6 max-w-2xl mx-auto bg-white rounded-lg shadow-md'>
						<svg xmlns='http://www.w3.org/2000/svg' className='h-16 w-16 text-green-500' fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth='2'>
							<path strokeLinecap='round' strokeLinejoin='round' d='M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
						</svg>
						<h1 className='text-xl font-semibold text-gray-800 mt-4'>Application Successfully Finalized!</h1>
						<p className='text-center text-gray-600 mt-2'>Your application has been completed and finalized. We appreciate your effort and enthusiasm throughout this process.</p>
						<p className='text-center text-gray-600 mt-2'>Please wait for the allotment day. We are excited to connect with you and support your future goals!</p>
						<p className='text-center text-lg font-bold text-green-900 mt-2'>Your registered mobile number: {values?.phoneNumber}</p>
						<p className='text-center text-lg font-bold text-green-900 mt-2'>Your application number: {params.type.toUpperCase() === 'MCA' ? values?.mcaApplicationNo : appNo}</p>
						<p className='text-center text-gray-600 mt-2'>Application form, allotment memo, and seat confirmation slip will be available here soon.</p>
						<p className='text-center text-gray-600 mt-4 font-medium'>Thank you for choosing us. We look forward to your achievements!</p>
						<div id='status' className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-10 gap-4'>
							{sections.map((section, index) => (
								<ProgressCard key={index} title={section.title} keyName={section?.keyName} array={section?.array} object={section?.object} values={values} color={section.color} />
							))}
						</div>
						<div className='flex'>
							<Button type='close' onClick={handleLogout} children={userId ? 'Go Previous Menu' : 'Logout'} />
							<Button type='save' onClick={updateProfilePercentage} children='Update Percentage' />
							<Button width='100%' type='print' tableRef={componentRef2.current}>
								Print Application
							</Button>
						</div>
						{params?.type?.toUpperCase() === 'MCA' ? <MCAApplication collegeData={collegeData} classes={classes} type={params?.type} minorData={minorData} values={values} getQuotaTypes={getQuotaTypes} componentRef2={componentRef2} /> : <ApplicationPrint collegeData={collegeData} classes={classes} type={params?.type} minorData={minorData} values={values} getQuotaTypes={getQuotaTypes} componentRef2={componentRef2} />}
						{params?.type?.toUpperCase() === 'MCA' && (
							<div className='flex flex-col items-center p-6  mx-auto bg-gray rounded-lg shadow-md w-[100%]'>
								<div className='w-[100%]'>
									<SectionHeader key='' title={'Documents Uploads'} keyName={documentKey} values={values} object={'certificates'} />
								</div>

								<div id='certificates' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
									{certificateOptions?.map((certificate: any, index: any) => (
										<label key={index} className='flex items-center space-x-3'>
											<input disabled={viewMode} type='checkbox' name={certificate?.key} checked={values?.certificates?.[certificate?.key] || false} onChange={handleCheckboxChange} className='form-checkbox h-5 w-5' />
											<span className='text-gray-700'>{certificate?.title}</span>
										</label>
									))}
								</div>
								<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
									{certificateOptions?.map((certificate: any, index: any) => (
										<React.Fragment key={index}>
											{values?.certificates?.[certificate?.key] && (
												<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
													{isLoadingObj[`${certificate?.key}URL`] ? (
														<div className='flex justify-center items-center h-full'>
															<progress value={uploadProgress[`${certificate?.key}URL`] || 0} max='100'></progress>
															<div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
															<p className='text-gray-700 text-lg mt-2'>
																Uploading Image...
																{uploadProgress[`${certificate?.key}URL`]?.toFixed(0)}%
															</p>
														</div>
													) : (
														<>
															<label className='block text-sm font-medium text-gray-700'>Upload {certificate?.title} *</label>
															<input disabled={viewMode} type='file' onChange={(event) => handleFileChange(event, `${certificate?.key}URL`)} accept='image/*,application/pdf' className='file-input' />
															{values?.certificates?.[certificate?.key + 'URL'] && renderFilePreview(values?.certificates?.[certificate?.key + 'URL'], certificate?.title)}
														</>
													)}
												</div>
											)}
										</React.Fragment>
									))}
								</div>
							</div>
						)}
					</div>
				) : (
					<>
						{verification ? (
							<div id='firstDive' className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
								<div id='basicPreviewDetails' className='h-60'></div>

								<div className='w-full bg-white overflow-y-auto '>
									{params?.type?.toUpperCase() === 'MCA' ? <MCAApplication collegeData={collegeData} classes={classes} type={params?.type} minorData={minorData} values={values} getQuotaTypes={getQuotaTypes} componentRef2={componentRef2} /> : <ApplicationPrint collegeData={collegeData} classes={classes} type={params?.type} minorData={minorData} values={values} getQuotaTypes={getQuotaTypes} componentRef2={componentRef2} />}
									<SectionHeader title={'Application Status'} percentage={percentageFilled} />
									<div id='status' className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-10 gap-4'>
										{sections.map((section, index) => (
											<ProgressCard key={index} title={section.title} keyName={section?.keyName} array={section?.array} object={section?.object} values={values} color={section.color} />
										))}
									</div>
									{autonumus && (
										<div id='payments' className='max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg'>
											<h2 className='text-center font-semibold text-xl mb-4'>Fee Payment ₹{billAmount}/-</h2>
											{isLoadingObj?.paymentLoading ? (
												<div className='flex flex-col justify-center items-center space-y-2'>
													<div className='animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-green-600'></div>
													<p className='text-gray-600 text-sm'>Redirecting to payment gateway...</p>
												</div>
											) : (
												<>
													{values?.applicationAmount?.recieved ? (
														<div className='bg-white p-6 rounded-lg shadow-lg mx-auto max-w-md transform transition-all hover:scale-105 duration-300'>
															<h3 className='text-lg font-semibold text-center text-gray-800'>Payment Receipt</h3>
															<div className='mt-4 space-y-4'>
																<p>
																	<strong className='block text-sm font-medium text-gray-500'>Amount:</strong>
																	<span className='text-gray-700'>₹{values?.applicationAmount?.totalAmount}</span>
																</p>
																<p>
																	<strong className='block text-sm font-medium text-gray-500'>Payment ID:</strong>
																	<span className='text-gray-700'>{values?.applicationAmount?.order?.paymentId}</span>
																</p>
																<p>
																	<strong className='block text-sm font-medium text-gray-500'>Date:</strong>
																	<span className='text-gray-700'>{moment(values?.applicationAmount?.date).format('DD-MM-YYYY hh:mm A')}</span>
																</p>
																<p>
																	<strong className='block text-sm font-medium text-gray-500'>Name of the Student:</strong>
																	<span className='text-gray-700'>{values?.name}</span>
																</p>
															</div>
														</div>
													) : (
														<>
															{['COL-08B6B8276F75-46EE-A69C-77177AAA488B'].includes(initCollegeId) ? (
																<div className='z-30'>
																	<button onClick={handleOpenModal} className='mt-6 w-full bg-cyan-600 text-white py-3 px-6 rounded hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-300 focus:ring-opacity-50 transition duration-150 ease-in-out text-lg'>
																		View Payment QR Code
																	</button>
																	<p className='text-sm mt-4 text-gray-700'>Please note: After successfully making your payment, kindly send a screenshot of the payment confirmation along with your application number and name to the WhatsApp number 8281128840. Allow up to 1 hour for processing time. After this period, please check the payment status on our site. We appreciate your cooperation. Thank you!</p>
																</div>
															) : (
																<button onClick={handlePaymentRazorpay} className='mt-6 w-full bg-cyan-600 text-white py-3 px-6 rounded hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-300 focus:ring-opacity-50 transition duration-150 ease-in-out text-lg'>
																	Pay Application Fee ₹{billAmount}/-
																</button>
															)}
														</>
													)}
												</>
											)}
											{!values?.applicationAmount?.recieved && razorpay?.displayRazorpay && <RenderRazorpay userId={values?._id} refech={refetch} collegeId={initCollegeId} accountId={collegeData?.onlineFeeId} name={values?.name} billAmount={billAmount} amount={razorpay?.order?.amount} currency={razorpay?.order?.currency} orderId={razorpay?.order?.id} keyId={`${collegeData?.paymentGateway?.key_id}`} keySecret={`${collegeData?.paymentGateway?.key_secret}`} handleClickbackButton={{}} data={{}} />}
										</div>
									)}

									<div className='flex flex-col md:flex-row justify-between items-center px-6 py-4'>
										<StandardButton onClick={() => setVerification(false)} className='mt-4 md:mt-0 md:ml-8'>
											EDIT
										</StandardButton>

										{percentageFilled === 100 ? (
											<>
												{!showModal ? (
													<StandardButton2
														href='verifivatyo'
														disabled={percentageFilled < 100}
														onClick={() => {
															setShowModal(true);
														}}>
														SUBMIT APPLICATION
													</StandardButton2>
												) : (
													<div className=' flex items-center justify-center px-4 py-4 bg-opacity-50 z-50'>
														<div className='bg-white w-full max-w-lg p-8 rounded-2xl shadow-xl space-y-6 overflow-hidden mx-auto my-4'>
															<h2 className='text-xl md:text-2xl font-bold text-gray-900'>Confirm Finalization</h2>
															<div className='overflow-y-auto text-gray-600 max-h-60'>
																<p className='text-justify'>I have gone through the application and accept the conditions set forth therein and do hereby promise to abide by the rules and regulations of the college. I am also aware that the campus politics and atrocities against other students like ragging, manhandling etc. are banned in the college campus and the violation of rules pertaining to these are criminal offence and hence punishable. I also declare that the details furnished are true to my knowledge and beliefs.</p>
															</div>
															<label className='flex items-center space-x-3'>
																<input
																	type='checkbox'
																	name='diclaration'
																	checked={diclarationFinal}
																	onChange={() => {
																		setDiclarationFinal(!diclarationFinal);
																	}}
																	className='form-checkbox h-[30px] w-[30px]'
																/>
																<span className='text-gray-700'>I Agree</span>
															</label>

															<div className='flex justify-end space-x-1'>
																<button onClick={() => setShowModal(false)} className='px-4 py-2 rounded-lg bg-gray-200 text-gray-800 hover:bg-gray-300 transition duration-200 ease-in-out'>
																	Cancel
																</button>
																<button
																	disabled={!diclarationFinal}
																	onClick={() => {
																		finalyseAPICall();
																	}}
																	className={`px-4 py-2 rounded-lg text-white transition duration-200 ease-in-out ${!diclarationFinal ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}>
																	Confirm
																</button>
															</div>
														</div>
													</div>
												)}
											</>
										) : (
											<div className='flex items-center justify-center px-4 py-4 bg-gray-800 bg-opacity-75 z-50'>
												<div className='bg-white w-full max-w-md p-8 rounded-lg shadow-xl space-y-4 overflow-hidden mx-auto'>
													<h2 className='text-xl md:text-2xl font-bold text-red-600'>Application Incomplete!</h2>
													<h2 className='text-xl md:text-xl font-bold text-green-600'>only {percentageFilled}% completed</h2>
													<div className='flex items-center space-x-2 text-red-600'>
														<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' className='w-6 h-6'>
															<path stroke-linecap='round' stroke-linejoin='round' d='M12 9v2m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
														</svg>
														<p className='text-sm md:text-base'>Please complete your application. Review the sections indicated in red on your dashboard to find incomplete areas.</p>
													</div>
													<div className='flex justify-end space-x-2'>
														<StandardButton onClick={() => setVerification(false)} className='mt-4 md:mt-0 md:ml-8'>
															Please Complete Application
														</StandardButton>
													</div>
												</div>
											</div>
										)}
									</div>

									<div className='h-60'></div>
								</div>
							</div>
						) : (
							<div className='flex justify-center row '>
								<div className='flex flex-col  w-full max-w-6xl h-auto bg-white shadow-xl rounded-lg'>
									<div className='w-full p-1 bg-gray-50 '>
										<div className='space-y-6 pt-5'>
											<div id='status' className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-10 gap-4'>
												{sections?.map((section, index) => (
													<ProgressCard key={index} title={section.title} keyName={section?.keyName} array={section?.array} object={section?.object} values={values} color={section.color} />
												))}
											</div>
											<SectionHeader key='d1' title={autonumus ? ' Select Your Application Quota : ' + billAmount : 'Fill Details Asper Admit Card'} keyName={dscAPercentage} values={values} />

											{autonumus ? (
												<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
													{quotaHide && (
														<>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='meritQuata' checked={values?.admissionto?.meritQuata} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-[30px] w-[30px]' />
																<span className='text-gray-700'>Merit Quota (All candidates are ranked as per their index mark)</span>
															</label>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='communityQuata' checked={values?.admissionto?.communityQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
																<span className='text-gray-700'>Community Merit Quota ( {initCollegeId === 'COL-08B6B8276F75-46EE-A69C-77177AAA488B' ? 'Reserved for Latin Catholic Other than Anglo Indian' : 'Reserved for RCSC-Kothamangalam Diocese only '})</span>
															</label>
														</>
													)}

													<label className='flex items-center space-x-3'>
														<input disabled={viewMode} type='checkbox' name='managementQuata' checked={values?.admissionto?.managementQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
														<span className='text-gray-700'>Management Quota</span>
													</label>

													{quotaHide && (
														<>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='reservedQuata' checked={values?.admissionto?.reservedQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
																<span className='text-gray-700'>Reservation Quota (SC/ST/SEBC/ OBC/OBX/OBH/ETB/ MUSLIM/Latin Catholics other than Anglo Indians)</span>
															</label>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='sportsQuata' checked={values?.admissionto?.sportsQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-[30px] w-[30px]' />
																<span className='text-gray-700'>Sports Quota</span>
															</label>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='culturalQuata' checked={values?.admissionto?.culturalQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
																<span className='text-gray-700'>Cultural Quota</span>
															</label>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='ewsQuata' checked={values?.admissionto?.ewsQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
																<span className='text-gray-700'>EWS (Economically Weaker Section)</span>
															</label>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='pwdQuata' checked={values?.admissionto?.pwdQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
																<span className='text-gray-700'> PwD (Persons with Disabilities)</span>
															</label>
															<label className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name='lakshadweepQuata' checked={values?.admissionto?.lakshadweepQuata || false} onChange={handleCheckboxChangeAdmision} className='form-checkbox h-8 w-8' />
																<span className='text-gray-700'>Lakshadweep Quota</span>
															</label>
														</>
													)}
												</div>
											) : (
												<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
													<Input disabled={viewMode} fieldName='Alloted Programme' setState={setValues} state={values} didntShowKey returnKey='dsc_a' optionDisplay='className' optionKey='_id' options={classes} type='drop' width='100%' />
													<Input disabled={viewMode} width='100%' fieldName='Admission Quota *' returnKey='admnType' state={values} setState={setValues} options={splitUps} type='drop' />

													{params?.type?.toUpperCase() !== 'MCA' ? (
														<>
															<Input disabled={viewMode} width='100%' fieldName='Index Mark *' returnKey='index' state={values} setState={setValues} type='number' />
															<Input disabled={viewMode} width='100%' fieldName='CAP ID *' returnKey='capId' state={values} setState={setValues} type='text' />
														</>
													) : (
														<>
															<Input disabled={viewMode} width='100%' fieldName='LBS Obtained Mark*' returnKey='lbsMcaMark' state={values} setState={setValues} type='text' />
															<Input disabled={viewMode} width='100%' fieldName='LBS Roll No*' returnKey='lbsRollNo' state={values} setState={setValues} type='text' />
															<Input disabled={viewMode} width='100%' fieldName='LBS Rank*' returnKey='lbsMcaRank' state={values} setState={setValues} type='text' />
														</>
													)}
												</div>
											)}

											<SectionHeader key='d2' title={'Personal Details'} keyName={basicKey} values={values} />
											<div id='basicDetails' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
												<Input disabled={viewMode} width='100%' fieldName='Name (as per 10th Certificate) *' returnKey='name' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Date of Birth (as per 10th Certificate) *' returnKey='dob' state={values} setState={setValues} type='date' />
												<Input disabled={viewMode} width='100%' fieldName='Name of Guardian/Parent *' returnKey='gardianName' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Relationship with guardian *' returnKey='guardianRelation' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Occupation of the guardian *' returnKey='guardianOccupation' state={values} setState={setValues} type='text' />

												<Input disabled={viewMode} width='100%' fieldName={"Guardian's/Parent Mobile No. *" + (values?.guardianMobile?.length > 0 ? ' : ' + values?.guardianMobile?.length : '')} returnKey='guardianMobile' state={values} setState={setValues} type='number' />
												<Input disabled={viewMode} min={100000000000} max={999999999999} width='100%' fieldName={'Aadhaar Number *' + (values?.aadhaarNumber?.length > 0 ? ' : ' + values?.aadhaarNumber?.length : '')} returnKey='aadhaarNumber' state={values} setState={setValues} type='number' />
												<Input disabled={viewMode} width='100%' fieldName='Email' returnKey='email' state={values} setState={setValues} type='email' />
												<Input disabled={viewMode} width='100%' fieldName={'WhatsApp Number *' + (values?.whatsappNumber?.length > 0 ? ' : ' + values?.whatsappNumber?.length : '')} returnKey='whatsappNumber' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Gender *' returnKey='gender' state={values} setState={setValues} options={dropDowns?.[0]?.gender} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='Mother Tongue *' returnKey='motherTongue' state={values} setState={setValues} options={dropDowns?.[11]?.motherTonuge} type='drop' />

												{(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA') && (
													<>
														<Input disabled={viewMode} width='100%' fieldName='Blood Group *' returnKey='bloodGroup' state={values} setState={setValues} options={dropDowns?.[1]?.bloodGroup} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Place of Birth *' returnKey='placeOfBirth' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Marital Status*' returnKey='meritalStatus' state={values} setState={setValues} options={dropDowns?.[9]?.meritalStatus} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Annual Income in Figure*' returnKey='annualIncomeFigure' state={values} setState={setValues} type='number' />
														<Input disabled={viewMode} width='100%' fieldName='Poverty line (as per Ration Card)?*' returnKey='povertyLine' state={values} setState={setValues} options={['APL', 'BPL']} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Did any of your siblings study at this College ?* ' returnKey='siblingsCollege' state={values} setState={setValues} options={['Yes', 'No']} type='drop' />
														{values?.siblingsCollege === 'Yes' && <Input width='100%' fieldName='Name of the Sibling *' returnKey='nameOfSibling' state={values} setState={setValues} type='text' />}
														{values?.siblingsCollege === 'Yes' && <Input width='100%' fieldName='Programme & Year Sibling Studied *' returnKey='programOfSibling' state={values} setState={setValues} type='text' />}
													</>
												)}

												<Input disabled={viewMode} width='100%' fieldName='Persons with Disabilities (Divyangjan)?*' returnKey='disabilities' state={values} setState={setValues} options={['Yes', 'No']} type='drop' />
												{values?.disabilities === 'Yes' && <Input width='100%' fieldName='Select your Disability*' returnKey='disabilityStatus' state={values} setState={setValues} options={['Mobility Impairments', 'Musculoskeletal Disorders', 'Visual Impairments', 'Hearing Impairments', 'Intellectual Disabilities', 'Learning Disabilities', 'Multiple Sclerosis', "Parkinson's Disease", 'Epilepsy', 'Depression', 'Anxiety Disorders', 'Schizophrenia', 'Bipolar Disorder', 'Diabetes', 'Cancer', 'Heart Disease', 'Chronic Pain Conditions', 'Autism Spectrum Disorders', 'Aphasia', 'Articulation Disorders', 'Stuttering']} type='drop' />}
												{values?.disabilities === 'Yes' && <Input width='100%' min={40} max={100} fieldName='Disability Percentage*' returnKey='disabilityPercentage' state={values} setState={setValues} type='number' />}
												<Input disabled={viewMode} width='100%' fieldName='Religion *' returnKey='religion' state={values} setState={setValues} options={dropDowns?.[2]?.RELIGION[0].RELIGIONNAME} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='Category *' returnKey='category' state={values} setState={setValues} options={categoryData} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='Caste *' returnKey='caste' state={values} setState={setValues} options={casteData} type='drop' />
												{initCollegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' && params?.type?.toUpperCase() === 'PG' && <Input width='100%' fieldName='Did you pass LBS MCA Entrance Test ?* ' returnKey='lbsMcaTest' state={values} setState={setValues} options={['Yes', 'No']} type='drop' />}
												{values?.lbsMcaTest === 'Yes' && <></>}
											</div>

											<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
												{isLoadingObj.photoURL ? (
													<div className='flex justify-center items-center h-full'>
														<progress value={uploadProgress.photoURL || 0} max='100'></progress>
														<div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
														<p className='text-gray-700 text-lg mt-2'>
															Uploading Image...
															{uploadProgress?.photoURL?.toFixed(0)}%
														</p>
													</div>
												) : (
													<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
														<label className='block text-sm font-medium text-gray-700'>Upload Passport Size Photo (150px x 200px)</label>
														<p>The photo should be clear and of passport size for optimal clarity and compliance with requirements.</p>
														<input disabled={viewMode} type='file' onChange={(event) => handlePhotoChange(event, `photoURL`)} accept='image/*' className='file-input' />
														{values?.photoURL && renderFilePreview(values?.photoURL, 'Passport Size Photo')}
													</div>
												)}
											</div>
											{params?.type?.toUpperCase() === 'MCA' && (
												<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
													{isLoadingObj.signature ? (
														<div className='flex justify-center items-center h-full'>
															<progress value={uploadProgress.signature || 0} max='100'></progress>
															<div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
															<p className='text-gray-700 text-lg mt-2'>
																Uploading Signature...
																{uploadProgress?.signature?.toFixed(0)}%
															</p>
														</div>
													) : (
														<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
															<label className='block text-sm font-medium text-gray-700'>Upload Signature (150px x 100px)</label>
															<p>The Signature should be clear and optimal clarity and compliance with requirements.</p>
															<input disabled={viewMode} type='file' onChange={(event) => handleSignatureChange(event, `signature`)} accept='image/*' className='file-input' />
															{values?.signature && renderFilePreview(values?.signature, 'Signature')}
														</div>
													)}
												</div>
											)}

											<SectionHeader key='d4' title={'Permanent Address'} keyName={permanentAddressKey} values={values} />
											<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
												<Input disabled={viewMode} width='100%' fieldName='House Name *' returnKey='permanentHouseName' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Place *' returnKey='permanentPlace' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Post Office *' returnKey='permanentPostOffice' state={values} setState={setValues} type='text' />
												{(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA') && (
													<>
														<Input disabled={viewMode} width='100%' fieldName='Village *' returnKey='permanentVillage' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Panchayat/Municipality/Corporation *' returnKey='permanentPanchayatMunicipalityCorporation' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Taluk *' returnKey='permanentTaluk' state={values} setState={setValues} type='text' />
													</>
												)}
												<Input disabled={viewMode} width='100%' fieldName='Nationality *' returnKey='permanentNationality' state={values} setState={setValues} options={dropDowns[3]?.address[0]?.nationality} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='State *' returnKey='permanentState' state={values} setState={setValues} options={dropDowns[3]?.address[0]?.state} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='District *' returnKey='permanentDistrict' state={values} setState={setValues} options={dropDowns[3]?.address[0]?.district} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='Pincode *' returnKey='permanentPinCode' state={values} setState={setValues} type='number' />
											</div>

											<SectionHeader key='d3' title={'Address for Communication'} keyName={addressKey} values={values} />
											<div className='flex items-center justify  mb-4'>
												<label htmlFor='toggle2' className={`inline-flex relative items-center cursor-pointer right`}>
													<input disabled={viewMode} type='checkbox' id='toggle2' checked={address} onChange={(e) => setAddress(e.target.checked)} className='sr-only peer' />
													<div className='w-11 h-6 bg-gray-200 rounded-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:bg-blue-600 relative transition-colors duration-200 ease-in-out'></div>
													<div className={`absolute top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out ${address ? 'right-1 ' : 'left-1'}`}></div>
												</label>
												<span className='text-gray-700 font-medium ml-3'>Same as permanent address</span>
											</div>

											<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
												<Input disabled={viewMode} width='100%' fieldName='House Name *' returnKey='houseName' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Place *' returnKey='place' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Post Office *' returnKey='postOffice' state={values} setState={setValues} type='text' />
												{(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA') && (
													<>
														<Input disabled={viewMode} width='100%' fieldName='Village *' returnKey='village' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Panchayat/Municipality/Corporation *' returnKey='panchayatMunicipalityCorporation' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Taluk *' returnKey='taluk' state={values} setState={setValues} type='text' />
													</>
												)}
												<Input disabled={viewMode} width='100%' fieldName='Nationality *' returnKey='nationality' state={values} setState={setValues} options={dropDowns[3]?.address[0]?.nationality} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='State *' returnKey='state' state={values} setState={setValues} options={dropDowns[3]?.address[0]?.state} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='District *' returnKey='district' state={values} setState={setValues} options={dropDowns[3]?.address[0]?.district} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='Pincode *' returnKey='pinCode' state={values} setState={setValues} type='number' />
											</div>

											{(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA') && (
												<>
													<SectionHeader key='d5' title={'Family Members Details'} keyName={familykey} array={'family'} values={values} />
													<div>
														{values?.family?.length >= 0 ? (
															<>
																{values?.family?.map((member: any, index: any) => (
																	<div key={member.id || index} className='bg-white shadow-md p-4 rounded-lg mb-4'>
																		<div className='flex justify-between items-center'>
																			<span className='text-lg font-bold'>Family Member {index + 1}</span>
																			<Button disabled={viewMode} type='close' onClick={() => onDelete(index, 'family')}>
																				Delete
																			</Button>
																		</div>
																		<div key={index + 1} className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='family' fieldName='Name *' returnKey='name' state={values} setState={setValues} type='text' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='family' fieldName='Qualification *' returnKey='qualification' state={values} setState={setValues} options={dropDowns[6]?.family[0]?.qualification} type='drop' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='family' fieldName='Relationship *' returnKey='relation' state={values} setState={setValues} options={dropDowns[6]?.family[0]?.relationship} type='drop' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='family' fieldName='Job *' returnKey='job' state={values} setState={setValues} options={dropDowns[6]?.family[0]?.job} type='drop' />
																			{/* {(member.relation==='Father'||member.relation==='Mother' ) && <Input disabled={viewMode} width="100%" isArray={true} indexVal={index} arrayName="family" fieldName="Annual Income *" returnKey="annualIncome" state={values} setState={setValues} type="number" />} */}
																			{/* <Input disabled={viewMode} width="100%" isArray={true} indexVal={index} arrayName="family" fieldName="Income *" returnKey="income" state={values} setState={setValues} options={dropDowns[6]?.family[0]?.income} type="drop" /> */}
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='family' fieldName='Mobile Number *' returnKey='mobile' state={values} setState={setValues} type='number' />
																		</div>
																	</div>
																))}
																<Button disabled={viewMode} type='save' onClick={() => onAddClick('family')}>
																	Add +
																</Button>
															</>
														) : (
															<>
																<span className='text-center text-gray-500 my-10'>Add Family Members</span>
																<Button disabled={viewMode} type='save' onClick={() => onAddClick('family')}>
																	Add +
																</Button>
															</>
														)}
													</div>

													<SectionHeader key='d6' title={'Extracurricular/ Cocurricular Details'} />
													<div>
														{values?.culturalActivity?.length > 0 ? (
															<>
																{values?.culturalActivity?.map((member: any, index: any) => (
																	<div key={member.id || index} className='bg-white shadow-md p-4 rounded-lg mb-4'>
																		<div className='flex justify-between items-center'>
																			<span className='text-lg font-bold'>Extracurricular/Cocurricular Details {index + 1}</span>
																			<Button disabled={viewMode} type='close' onClick={() => onDelete(index, 'culturalActivity')}>
																				Delete
																			</Button>
																		</div>
																		<div key={index + 1} className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='culturalActivity' fieldName='Type *' returnKey='type' state={values} setState={setValues} options={dropDowns[5]?.culturalActivities[0]?.culturalActivitiesList} type='drop' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='culturalActivity' fieldName='Item *' returnKey='item' state={values} setState={setValues} options={dropDowns[5]?.culturalActivities[0]?.[values?.culturalActivity[index]?.type]} type='drop' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='culturalActivity' fieldName='Achievements *' returnKey='specialised' state={values} setState={setValues} type='text' />
																		</div>
																	</div>
																))}
																<Button disabled={viewMode} type='save' onClick={() => onAddClick('culturalActivity')}>
																	Add +
																</Button>
															</>
														) : (
															<>
																<span className='text-center text-gray-500 my-10'>Add Extracurricular/Cocurricular Details</span>
																<Button disabled={viewMode} type='save' onClick={() => onAddClick('culturalActivity')}>
																	Add +
																</Button>
															</>
														)}
													</div>
													<SectionHeader key='d7' title={'Mode of Travel to College'} keyName={travelKey} array={'travelDetails'} values={values} />
													<div>
														{values?.travelDetails?.length > 0 ? (
															<>
																{values?.travelDetails?.map((member: any, index: any) => (
																	<div key={member.id || index} className='bg-white shadow-md p-4 rounded-lg mb-4'>
																		<div className='flex justify-between items-center'>
																			<span className='text-lg font-bold'>Mode of Travel and Route {index + 1}</span>
																			<Button disabled={viewMode} type='close' onClick={() => onDelete(index, 'travelDetails')}>
																				Delete
																			</Button>
																		</div>
																		<div key={index + 1} className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='travelDetails' fieldName='Select Type of Vehicle *' returnKey='typeOfVehicle' state={values} setState={setValues} options={dropDowns[7]?.vehicle} type='drop' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='travelDetails' fieldName='Travel Starts From *' returnKey='travelStart' state={values} setState={setValues} type='text' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='travelDetails' fieldName='Travel Ends at *' returnKey='travelTo' state={values} setState={setValues} type='text' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='travelDetails' fieldName='Travel Km *' returnKey='km' state={values} setState={setValues} type='text' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='travelDetails' fieldName='Type of Engine *' returnKey='typeOfEnergy' state={values} setState={setValues} options={dropDowns[7]?.engine} type='drop' />
																			<Input disabled={viewMode} width='100%' isArray={true} indexVal={index} arrayName='travelDetails' fieldName='Co-traveller if any *' returnKey='coTraveler1' state={values} setState={setValues} type='text' />
																		</div>
																	</div>
																))}
																<Button disabled={viewMode} type='save' onClick={() => onAddClick('travelDetails')}>
																	Add +
																</Button>
															</>
														) : (
															<>
																<span className='text-center text-gray-500 my-10'>Add Travel Details</span>
																<Button disabled={viewMode} type='save' onClick={() => onAddClick('travelDetails')}>
																	Add +
																</Button>
															</>
														)}
													</div>

													<SectionHeader key='d8' title={'Bank Details (for E-Grantz Scholarship)'} />
													<div id='education' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
														<Input disabled={viewMode} width='100%' fieldName='Beneficiary Name *' returnKey='bankDetails.beneficiary' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Bank Account Number *' returnKey='bankDetails.accountNo' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Bank IFSC Code *' returnKey='bankDetails.ifcs' state={values} setState={setValues} type='text' />
													</div>
												</>
											)}

											<SectionHeader key='d9' title={'10th Qualification'} keyName={sslcKey} values={values} />
											<div id='education' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
												<Input disabled={viewMode} width='100%' fieldName='Institution Name*' returnKey='sslcInstitutionName' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Register No *' returnKey='sslcRegisterNo' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Select Board *' returnKey='sslcBoard' state={values} setState={setValues} options={['CBSE', 'Board of Public Examination, Kerala', 'HSE', 'VHSE', 'ICSE', 'NIOS', 'CISCE']} type='drop' />
												{(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA') && <Input width='100%' fieldName='Percentage *' returnKey='sslcPercentage' state={values} setState={setValues} type='text' />}
												<Input disabled={viewMode} width='100%' fieldName='Month & Year of Pass *' returnKey='sslcYearOfPass' state={values} setState={setValues} type='month' />
												<Input disabled={viewMode} width='100%' fieldName='No of Chances (as given in the certificate) *' returnKey='sslcNoOfChance' state={values} setState={setValues} options={['1', '2', '3']} type='drop' />
											</div>

											<SectionHeader key='d10' title={'Plus Two Qualification'} keyName={plusTwoKey} values={values} />
											<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
												<Input disabled={viewMode} width='100%' fieldName='Institution Name *' returnKey='plusTwoInstitutionName' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Register No *' returnKey='plusTwoRegisterNo' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Institution State *' returnKey='plusTwoState' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Select Board *' returnKey='plusTwoBoard' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.PlusTwo?.Board} type='drop' />
												<Input disabled={viewMode} width='100%' fieldName='Stream *' returnKey='plusTwoStream' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.PlusTwo?.Stream} type='drop' />
												{(initCollegeId !== 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' || params?.type?.toUpperCase() === 'MCA') && <Input width='100%' fieldName='Percentage *' returnKey='plusTwoPercentage' state={values} setState={setValues} type='text' />}
												<Input disabled={viewMode} width='100%' fieldName='Marks Obtained *' returnKey='totalMarkObtaioned' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Maximum Marks *' returnKey='totalMarks' state={values} setState={setValues} type='text' />
												<Input disabled={viewMode} width='100%' fieldName='Month & Year of Pass *' returnKey='plusTwoYearOfPass' state={values} setState={setValues} type='month' />
												<Input disabled={viewMode} width='100%' fieldName='No of Chances (as given in the certificate) *' returnKey='plusTwoNoOfChance' state={values} setState={setValues} options={['1', '2', '3']} type='drop' />
												{params?.type?.toUpperCase() === 'UG' && <Input width='100%' fieldName='Are You Eligible for Bonus Marks? *' returnKey='isBonusMark' state={values} setState={setValues} options={['Yes', 'No']} type='drop' />}
											</div>

											{params?.type?.toUpperCase() === 'UG' && values?.isBonusMark === 'Yes' && (
												<>
													<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 bg-gray-200 p-6'>
														{certificateBonusOptions?.map((certificate: any, index: any) => (
															<label key={index} className='flex items-center space-x-3'>
																<input disabled={viewMode} type='checkbox' name={certificate?.key} checked={values?.certificates?.[certificate?.key] || false} onChange={handleCheckboxChange} className='form-checkbox h-5 w-5' />
																<span className='text-gray-700'>{certificate?.title}</span>
															</label>
														))}
													</div>
												</>
											)}
											{params?.type?.toUpperCase() === 'UG' && (
												<>
													<p>
														<abbr title='Obtained Marks'>OM</abbr> = Obtained Marks | <abbr title='Total Marks'>TM</abbr> = Total Marks
													</p>
													<div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
														{plustwo?.map((terms: any) => (
															<SubjectInputSet disabled={viewMode} fieldName={terms?.fieldName} returnKey={terms.returnKey} state={values} setState={setValues} options={terms?.options || []} />
														))}
													</div>
												</>
											)}
											{(params?.type?.toUpperCase() === 'PG' || params?.type?.toUpperCase() === 'MCA') && (
												<>
													<SectionHeader key='d11' title={'Degree Qualification'} keyName={degreeKey} values={values} />
													<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2'>
														<Input disabled={viewMode} width='100%' fieldName='Institution Name *' returnKey='degreeInstitutionName' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Register No *' returnKey='degreeRegisterNo' state={values} setState={setValues} type='text' />
														<Input disabled={viewMode} width='100%' fieldName='Name of University *' returnKey='degreeUniversity' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.UG?.University} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Programme Stream *' returnKey='degreeCourse' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.UG?.Course} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Specialisation *' returnKey='degreeSpecialisation' state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.UG?.Specialisation} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Mark System *' returnKey='degreeMarkSystem' state={values} setState={setValues} options={['CBCSS Pattern 2009 (CGPA out of 4)', 'Pre CBCSS Pattern', 'Conventional Mark System', 'CBCSS Pattern 2013/17 (CCPA out of 10)', 'Others']} type='drop' />
														{params?.type?.toUpperCase() === 'MCA' && (
															<>
																<Input width='100%' fieldName='Percentage *' returnKey='degreePercentage' state={values} setState={setValues} type='text' />
																<Input width='100%' fieldName='Grade *' returnKey='degreeGrade' state={values} setState={setValues} type='text' />
															</>
														)}

														<Input disabled={viewMode} width='100%' fieldName='Scheme of the Degree *' returnKey='degreeScheme' state={values} setState={setValues} options={['Single Main', 'Double Main', 'Triple Main']} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Intituition State *' returnKey='degreeInstState' state={values} setState={setValues} options={['Kerala', 'Outside Kerala', 'Outside India']} type='drop' />
														<Input disabled={viewMode} width='100%' fieldName='Month & Year of Pass *' returnKey='degreeYearOfPass' state={values} setState={setValues} type='month' />
														<Input disabled={viewMode} width='100%' fieldName='No of Chances (as given in the certificate) *' returnKey='degreeNoOfChance' state={values} setState={setValues} options={['1', '2', '3']} type='drop' />
														{params?.type?.toUpperCase() !== 'MCA' && <Input disabled={viewMode} width='100%' fieldName='Are You Eligible for Bonus Marks? *' returnKey='isBonusMark' state={values} setState={setValues} options={['Yes', 'No']} type='drop' />}
													</div>
													{values?.isBonusMark === 'Yes' && (
														<>
															<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 bg-gray-200 p-6'>
																{certificateBonusOptions?.map((certificate: any, index: any) => (
																	<label key={index} className='flex items-center space-x-3'>
																		<input disabled={viewMode} type='checkbox' name={certificate?.key} checked={values?.certificates?.[certificate?.key] || false} onChange={handleCheckboxChange} className='form-checkbox h-5 w-5' />
																		<span className='text-gray-700'>{certificate?.title}</span>
																	</label>
																))}
															</div>
														</>
													)}
													<>
														<p>
															<abbr title='Marks Awarded'>MA/CCPA</abbr> = Marks Awarded or Cumilative Credit Point Average | <abbr title='Maximum Marks'>MM/CR</abbr> = Maximum Marks or Credits
														</p>
														<div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1'>
															{degree?.map((terms: any, i: any) => (
																<DegreeSubjectInputSet disabled={viewMode} positionIndex={i} fieldName={terms?.fieldName} returnKey={terms.returnKey} state={values} setState={setValues} options={terms?.options || []} />
															))}
														</div>
													</>
												</>
											)}
											<SectionHeader key='d12' title={'Documents Uploads'} keyName={documentKey} values={values} object={'certificates'} />
											<div id='certificates' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
												{certificateOptions?.map((certificate: any, index: any) => (
													<label key={index} className='flex items-center space-x-3'>
														<input disabled={viewMode} type='checkbox' name={certificate?.key} checked={values?.certificates?.[certificate?.key] || false} onChange={handleCheckboxChange} className='form-checkbox h-5 w-5' />
														<span className='text-gray-700'>{certificate?.title}</span>
													</label>
												))}
											</div>
											<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
												{certificateOptions?.map((certificate: any, index: any) => (
													<React.Fragment key={index}>
														{values?.certificates?.[certificate?.key] && (
															<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
																{isLoadingObj[`${certificate?.key}URL`] ? (
																	<div className='flex justify-center items-center h-full'>
																		<progress value={uploadProgress[`${certificate?.key}URL`] || 0} max='100'></progress>
																		<div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
																		<p className='text-gray-700 text-lg mt-2'>
																			Uploading Image...
																			{uploadProgress[`${certificate?.key}URL`]?.toFixed(0)}%
																		</p>
																	</div>
																) : (
																	<>
																		<label className='block text-sm font-medium text-gray-700'>Upload {certificate?.title} *</label>
																		<input disabled={viewMode} type='file' onChange={(event) => handleFileChange(event, `${certificate?.key}URL`)} accept='image/*,application/pdf' className='file-input' />
																		{values?.certificates?.[certificate?.key + 'URL'] && renderFilePreview(values?.certificates?.[certificate?.key + 'URL'], certificate?.title)}
																	</>
																)}
															</div>
														)}
													</React.Fragment>
												))}
											</div>
											{values?.isBonusMark === 'Yes' && (
												<>
													<SectionHeader key='d19' title={'Bonus Mark Documents Uploads'} keyName={documentBonafiedKey} values={values} object={'certificates'} />
													<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 p-4'>
														{certificateBonusOptions?.map((certificate: any, index: any) => (
															<React.Fragment key={index}>
																{values?.certificates?.[certificate?.key] && (
																	<div className='bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3'>
																		{isLoadingObj[`${certificate?.key}URL`] ? (
																			<div className='flex justify-center items-center h-full'>
																				<progress value={uploadProgress[`${certificate?.key}URL`] || 0} max='100'></progress>
																				<div className='animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900'></div>
																				<p className='text-gray-700 text-lg mt-2'>
																					Uploading Image...
																					{uploadProgress[`${certificate?.key}URL`]?.toFixed(0)}%
																				</p>
																			</div>
																		) : (
																			<>
																				<label className='block text-sm font-medium text-gray-700'>Upload {certificate?.title} *</label>
																				<input disabled={viewMode} type='file' onChange={(event) => handleFileChange(event, `${certificate?.key}URL`)} accept='image/*' className='file-input' />
																				{values?.certificates?.[certificate?.key + 'URL'] && renderFilePreview(values?.certificates?.[certificate?.key + 'URL'], certificate?.title)}
																			</>
																		)}
																	</div>
																)}
															</React.Fragment>
														))}
													</div>
												</>
											)}

											{autonumus || params.type.toUpperCase() === 'MCA' ? (
												<>
													{params.type.toUpperCase() !== 'MCA' && (
														<>
															<SectionHeader key='d14' title={'Programmes Selection (Choose Your Programme in Priority)'} keyName={preferenceKey} values={values} />
															<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
																<Input disabled={viewMode} fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='preferedCourse1' optionDisplay='className' optionKey='_id' options={classData} optionsAll={classes} type='drop' width='100%' />
																<Input disabled={viewMode} fieldName='Priority 2' setState={setValues} state={values} didntShowKey returnKey='preferedCourse2' optionDisplay='className' optionKey='_id' options={classData} optionsAll={classes} type='drop' width='100%' />
																<Input disabled={viewMode} fieldName='Priority 3' setState={setValues} state={values} didntShowKey returnKey='preferedCourse3' optionDisplay='className' optionKey='_id' options={classData} optionsAll={classes} type='drop' width='100%' />
															</div>
															<div className='h-[20px]'></div>
														</>
													)}
													<Button
														type='fetch'
														width='100%'
														onClick={() => {
															setVerification(true);
															setTimeout(() => {
																const element = document.getElementById('basicPreviewDetails');
																if (element) {
																	element.scrollIntoView({
																		behavior: 'smooth',
																	});
																} else {
																	console.error('Element with ID "basicPreviewDetails" not found');
																}
															}, 300);
														}}
														children={'Preview Application'}
													/>
													<div className='h-[50px]'></div>
												</>
											) : (
												<>
													<>
														{neeedFYUGP && (
															<>
																<SectionHeader key='d18' title={'Minor 1'} keyName={dscbPercentage} values={values} />
																<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
																	<Input disabled={viewMode} fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='dsc_b_1' optionDisplay='subjectName' optionKey='_id' options={DSC_B} type='drop' width='100%' />
																	{/* <Input disabled={viewMode} fieldName="Priority 2" setState={setValues} state={values} didntShowKey returnKey="dsc_b_2" optionDisplay="subjectName" optionKey="_id" options={DSC_B} type="drop" width="100%" />
                                        <Input disabled={viewMode} fieldName="Priority 3" setState={setValues} state={values} didntShowKey returnKey="dsc_b_3" optionDisplay="subjectName" optionKey="_id" options={DSC_B} type="drop" width="100%" /> */}
																</div>
																<SectionHeader key='d19' title={'Minor 2'} keyName={dsccPercentage} values={values} />
																<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
																	<Input disabled={viewMode} fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='dsc_c_1' optionDisplay='subjectName' optionKey='_id' options={DSC_C} type='drop' width='100%' />
																	{/* <Input disabled={viewMode} fieldName="Priority 2" setState={setValues} state={values} didntShowKey returnKey="dsc_c_2" optionDisplay="subjectName" optionKey="_id" options={DSC_C} type="drop" width="100%" />
                                        <Input disabled={viewMode} fieldName="Priority 3" setState={setValues} state={values} didntShowKey returnKey="dsc_c_3" optionDisplay="subjectName" optionKey="_id" options={DSC_C} type="drop" width="100%" /> */}
																</div>
																<SectionHeader key='d20' title={'MDC'} keyName={mdcPercentage} values={values} />
																<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
																	<Input disabled={viewMode} fieldName='Priority 1' setState={setValues} state={values} didntShowKey returnKey='mdc_1' optionDisplay='subjectName' optionKey='_id' options={MDC} type='drop' width='100%' />
																	<Input disabled={viewMode} fieldName='Priority 2' setState={setValues} state={values} didntShowKey returnKey='mdc_2' optionDisplay='subjectName' optionKey='_id' options={MDC} type='drop' width='100%' />
																	<Input disabled={viewMode} fieldName='Priority 3' setState={setValues} state={values} didntShowKey returnKey='mdc_3' optionDisplay='subjectName' optionKey='_id' options={MDC} type='drop' width='100%' />
																</div>
																<SectionHeader key='d19' title={'AEC OL'} keyName={dsccPercentage} values={values} />
																<div id='preference' className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
																	<Input disabled={viewMode} fieldName='AEC OL (Second Language)' setState={setValues} state={values} returnKey='secondLangugae' options={['Malayalam', 'Hindi']} type='drop' width='100%' />
																</div>
															</>
														)}
													</>

													{needNecttosPay && (
														<div id='payments' className='max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg'>
															<p>Please scan the QR code and pay 280 rupees. Once the payment is complete, enter the transaction ID below for verification.</p>

															<Input disabled={viewMode} width='100%' fieldName='Transaction ID *' returnKey='transactionIdOfPayemnt' state={values} setState={setValues} type='text' />
														</div>
													)}

													<SectionHeader title={'Application Status'} percentage={percentageFilled} />
													<div id='status' className='grid grid-cols-3 md:grid-cols-5 lg:grid-cols-10 gap-4'>
														{sections.map((section, index) => (
															<ProgressCard key={index} title={section.title} keyName={section?.keyName} array={section?.array} object={section?.object} values={values} color={section.color} />
														))}
													</div>

													{percentageFilled === 100 && (
														<div
															style={{
																backgroundColor: '#f4f4f8',
																padding: '20px',
																borderRadius: '8px',
																color: '#333',
																fontFamily: 'Arial, sans-serif',
															}}>
															<h2 style={{ color: '#4A90E2' }}>Application Complete</h2>
															<p>Congratulations on completing your application form! Please proceed to your department at your earliest convenience to start the verification process with all your required documents.</p>
															<p>
																To facilitate the verification process, download the <strong>Necttos</strong> application from the <a href='https://play.google.com/store'>Google Play Store</a> or the <a href='https://www.apple.com/app-store/'>Apple App Store</a>. Log in using your registered mobile number: <strong>{values.phoneNumber?.slice(-10)}</strong>. The app will guide you through the verification timeline, provide you with a token number, and inform you about further proceedings.
															</p>
															<p>
																Thank you,
																<br />
																Team Necttos
															</p>
														</div>
													)}
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</>
		</div>
	);
};
export default ApplicationOpen;
