import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import moment from "moment";
import noPhotoAvailable from "../../../../static/images/library/noPhotoAvailable.png";


const getFieldDropDowns = (data) => async () => {


  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/library/getOpac', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data?.opacData[0];
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

const getBookDetails = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/library/getBook', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

let filterTypeDrops = [
  'All', 'Title', 'Author', 'Subjects', 'Call No', 'Accession No', 'Contents'
]
export const Movements = ({ onClose }) => {

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const [state, setState] = React.useState({});

  const componentRef = useRef();
  const { data = [], } = useQuery(['movementData', state.searchType, state.searchValue], getFieldDropDowns({ collegeId, searchType: state.searchType, searchValue: state.searchValue, }), { keepPreviousData: true })

  const { data: allData = [], refetch } = useQuery(['bookData', state.bookStocksId], getBookDetails({ collegeId, bookStocksId: state.bookStocksId }), { keepPreviousData: true })


  let bookData = allData?.bookDetails;
  let circulation = allData?.circulation;


  const [loading, setLoading] = useState(false);

  const { Option } = Select;

  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >

        {loading ? <LoadingAnimation dark /> :

          <>

            <div className={classNames.listContainer2}>

              <div style={{ width: '400px' }} >

                <div >
                  <div style={{ height: '90%', width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                    <div style={{ width: '100%', paddingLeft: 5, paddingRight: 5, display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                      <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '5%', }}>Sn</h5>
                      <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>M No</h5>
                      <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '50%' }}>Name</h5>
                      <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>Class</h5>
                      <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>Status</h5>
                    </div>


                    <div style={{
                      height: '600px',
                      width: '100%',
                      position: 'relative',

                    }} >
                      <Scrollbars  >
                        {data?.data?.map((item, i) => (
                          <>
                            <div
                              onClick={() => {
                                setState({ ...state, bookStocksId: item._id });
                                refetch()
                              }}
                              style={{ cursor: 'pointer', width: '100%', paddingLeft: 5, paddingRight: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.status === 'available' ? 'white' : '#ff8f87' }}>


                              <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '5%' }}>{i + 1}</h5>
                              <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '20%' }}>{item.accessionNumber}</h5>
                              <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '50%' }}>{item.nameOfBook}</h5>
                              <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '20%' }}>{item.nameOfAuthor}</h5>
                              <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '20%' }}>{item.status}</h5>





                            </div>
                            <div style={{
                              width: '100%',
                              display: 'flex',
                              height: 1,
                              backgroundColor: 'black',
                            }}></div>
                          </>
                        ))}
                      </Scrollbars>












                    </div>



                  </div>
                </div>
              </div>
              <div style={{ width: '880px' }} >


                <div style={{ width: '100%', hight: '30%', display: 'flex', flexDirection: 'row', borderRadius: '25px', padding: '1%' }}   >

                  <div style={{ width: '70%' }}   >
                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>Title</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.nameOfBook}</h5>
                    </div>

                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>Author</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.nameOfAuthor}</h5>
                    </div>


                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>Publisher</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.nameOfPublisher}</h5>
                    </div>


                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>Edition</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.edition}</h5>
                    </div>

                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>ISBN</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.isbn}</h5>
                    </div>


                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>Accession Number</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.accessionNumber}</h5>
                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex', }}   >
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '20%' }}>Status</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '2%' }}>:</h5>
                      <h5 style={{ marginTop: 6, color: 'black', alignSelf: 'left', fontSize: 15, width: '80%' }}>{bookData?.status}</h5>
                    </div>
                  </div>



                  <div style={{ width: '30%', hight: '100%' }} >

                    <div className={classNames.avatar}>
                      <img src={bookData?.photoURL || noPhotoAvailable} className={classNames.dp} />
                    </div>

                  </div>

                </div>



                <Scrollbars style={{}} >

                  <div ref={componentRef} className="printable">

                    <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />

                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                        <img width='12%' height='12%' src={selectedCollege?.logo} />
                        <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                          <span style={{ fontSize: '30px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>
                          <p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }} >{selectedCollege?.accreditedGrade}</p>
                          <p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}</p>
                        </div>


                      </div>
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                        <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 10, width: '5%', }}>Sn</h5>
                        <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 10, width: '10%' }}>M.No</h5>
                        <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 10, width: '20%' }}>Name</h5>
                        <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 10, width: '20%' }}>Class/Dept</h5>
                        <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 10, width: '20%' }}>Is. On</h5>
                        <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 10, width: '20%' }}>Rt. On</h5>
                      </div>


                      {circulation?.map((item, i) => (
                        <>
                          <div style={{ width: '100%', display: 'flex', height: ' 40px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.status === 'renew' ? '#c0faeb' : 'white' }}>
                            <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '5%' }}>{i + 1}</h5>
                            <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '10%' }}>{item.admisionNo}</h5>
                            <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.userName}</h5>
                            <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.position}</h5>
                            <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '23%' }}>{item.tookOn}</h5>
                            <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '23%' }}>{item.returnOn}</h5>
                          </div>
                          <div style={{
                            width: '100%',
                            display: 'flex',
                            height: 1,
                            backgroundColor: 'black',
                          }}></div>
                        </>
                      ))}


                      <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 10 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
                    </div>
                  </div>
                </Scrollbars>
              </div>


            </div>
          </>
        }

      </div>


      <div className={classNames.absoluteDiv}  >
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '30%' }} >
            <Select
              showSearch
              className={classNames.formFullWidth}
              style={{ overflow: "hidden", }}
              onChange={(val) => {
                setState({ ...state, searchType: val });
              }}
            >
              {filterTypeDrops?.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <label>Filter Type</label>
          </div>

        </Formik>
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: '80%' }}  >
            <Field
              type="text"
              style={{ width: '100%' }}
              name="year"
              id="year"
              value={state.searchValue}
              onChange={(e) => { setState({ ...state, searchValue: e.target.value }) }} />
            <label>Search by {state?.searchType ? state?.searchType : 'All'}</label></div>
        </Formik>


        <Button className={classNames.reloadButton} onClick={() => refetch()}>
          Reload
        </Button>

        <ReactToPrint
          trigger={() => <button className={classNames.reloadButton}>Print</button>}
          content={() => componentRef.current}
        />
      </div >


      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div >
  );
};
