import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import JobListing from '../../components/Website/job-listing';
import FooterBar from '../../components/Website/footerBar';

const Job_listing = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="necttos Jobs" />
        <JobListing customclass="pd-top-120 bg-none" />
        <FooterBar />
    </div>
}

export default Job_listing

