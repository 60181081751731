import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getDCBReports, getReports } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Alert from 'react-popup-alert'
import { useAccountsHeads, useAllClasses } from "../../../../queryHooks";
import Scrollbars from "react-custom-scrollbars";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";
import ReactToPrint from "react-to-print";
import './style.css'

export const exportPdfCollectionReport = async ({ postData, setLoading, setAlert }) => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.post(`/college/reportGeneration/generateCollectionReport`, postData, {
      responseType: "blob",
    });


    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (e) {
    throw new Error(
      setLoading(false),
      setAlert({
        type: 'Message',
        text: 'Chack Date / Time / Main account / Sub account',
        show: true
      }),
    );
  }
};


export const DCB = ({ onClose }) => {
  const allClasses = useAllClasses({ needAll: false, classOnly: false });
  const [state, setState] = React.useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    mainAccount: "",
    subAccount: "",
    activeStatus: 'active',
    lactive: true

  });


  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const accounts = useAccountsHeads();
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }
  let quaryData = {
    startDate: state?.startDate,
    endDate: state?.endDate,
    mainAccount: state?.mainAccount || '',
    subAccount: state?.subAccount || '',
    method: state?.method || '',
    classId: state?.classId || '',
    activeStatus: state?.activeStatus || '',
    collegeId,
    state
  }
  const { data = [], isLoading, refetch } = useQuery(['getDCBReports', state.mainAccount, state?.activeStatus, state?.subAccount, state?.method, state?.startDate, state?.endDate, state?.classId, state], getDCBReports(quaryData));


  const [loading, setLoading] = useState(false);

  let subAccount = accounts?.data?.subAccounts || []
  let mainAccount = accounts?.data?.mainAccounts || []
  if (state?.mainAccount) {
    subAccount = subAccount.filter(x => x.mainId === state?.mainAccount)
  }
  const { Option } = Select;

  let index = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

  const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

  const dcbPdfGeneretor = async () => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.get(`/college/reportGeneration/dcbPdfGenerater`)
      console.log(resp);
    } catch (e) {
      console.log(e);
    }
  }

  const onDateCollectionReport = async () => {
    setLoading(true)
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        startDate: state?.startDate,
        endDate: state?.endDate,
        startTime: state?.startTime,
        endTime: state?.endTime,
        mainAccount: state?.mainAccount || '',
        subAccount: state?.subAccount || '',
        method: state?.method || '',
        activeStatus: state?.activeStatus || '',
        collegeId,
      };
      link.download = state?.endDate;
      const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData, setLoading, setAlert });
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
      setLoading(false)

    } catch (e) { }
  };



  const columns = [
    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
    },
    {
      title: 'Student Name',
      dataIndex: 'userName',
      key: 'userName',
    },
    {
      title: 'Admn No',
      dataIndex: 'admisionNo',
      key: 'admisionNo',
    },
    {
      title: 'Opening Balance',
      dataIndex: 'openingBalance',
      key: 'openingBalance',
    },
    {
      title: 'Current Collection',
      dataIndex: 'currentCollection',
      key: 'currentCollection',
    },
    {
      title: 'Clossing Balance',
      dataIndex: 'clossingBalance',
      key: 'clossingBalance',
    }
  ];

  subAccount = subAccount.concat({ label: 'All', value: '' })
  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >

        <div className={classNames.listContainer2}>
          <div className={classNames.headerPanel}>
            <div className={classNames.paginationController}>

            </div>
          </div>

          <label className={classNames.heading}>Language</label>
          <div style={{ flexDirection: 'row', textAlign: 'center' }}>
            {['active', 'Discontinued', 'Suspended', 'Dismissed', 'Long Leave', 'TC Issue', 'College Transfer', 'Irregular'].map((categoryKey) => (
              <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                <input type="checkbox"
                  style={{ height: 12, width: 12, marginRight: 10 }}
                  defaultChecked={state['l' + categoryKey]}
                  onChange={() => setState({ ...state, ['l' + categoryKey]: !state['l' + categoryKey] })}
                />
                {categoryKey}
              </label>
            ))}
          </div>
          {isLoading ? <LoadingAnimation customHeight={1050} customWidth={1050} /> :
            <Scrollbars style={{ height: '90%' }} >
              <div ref={componentRef} className="printable">
                <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                  <img width='6%' height='6%' src={selectedCollege?.logo} />
                  <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                    <span style={{ fontSize: '20px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>
                    <p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }} >{selectedCollege?.accreditedGrade}</p>
                    <p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}> {state.mainAccountName} DCB  Report as on {moment(new Date()).format('DD MMM YYYY hh:mm A')}</p>
                  </div>
                </div>
                <table>
                  <tr>
                    <th className="main-th"></th>
                    <th className="main-th">strength of the class at the beginning of the month
                      <th>full fees</th>
                      <th>half fees</th>
                      <th>no fees</th>
                    </th>
                    <th className="main-th">admissions & withdrawals + admissions - removals
                      <th>full fees</th>
                      <th>half fees</th>
                      <th>no fees</th>
                    </th>
                    <th className="main-th">strength at the class at the end of the month
                      <th>full fees</th>
                      <th>half fees</th>
                      <th>no fees</th>
                    </th>
                    <th className="main-th">Rate of fees</th>
                    <th className="main-th">demand
                      <th style={{ width: '85px' }}>arrear up to the end of previous month(opening day)</th>
                      <th style={{ width: '85px' }}>fees due for current month/add demand (current day)</th>
                      <th style={{ width: '85px' }}>lab./lib.fee,fine and misc</th>
                      <th style={{ width: '85px' }}>total</th>
                    </th>
                    <th>colletion</th>
                    <th>collection up to and including the month</th>
                    <th>balance</th>
                    <th>remitted in to the treasury
                      <th style={{ width: '85px' }}>out of unremmitted balance of the previous month</th>
                      <th style={{ width: '85px' }}>out of the collection of the month</th>
                      <th style={{ width: '85px' }}>total</th>
                    </th>
                    <th>Remittance in to the treasury up to the end of yhe month</th>
                    <th>balance pending remittance</th>
                    <th>remarks</th>
                  </tr>
                  <tr>
                    {index.map(x => (<th style={{ position: 'static' }}>{x}</th>))}
                  </tr>
                  <tr> <h6 className="note">Tuition fee for the acaademic year 1972-73 to 2015-16 have been remitted in the government account</h6></tr>
                  <tr>
                    <td colSpan={2}><input type="text" className="year" />  Tuition fees</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td rowSpan={20}></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}><input type="text" className="year" />  Tuition fees</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}><input type="text" className="year" />  Tuition fees</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}><input type="text" className="year" />  Tuition fees</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}><input type="text" className="year" />  Tuition fees</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>

                  <tr>
                    <td colSpan={2}>duplicate fee</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>TC late fee</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>admission fee</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>library fee</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>total</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td colSpan={2}><input type="text" className="year" /></td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>i DC</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>ii DC</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>iii DC</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>i PG</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>ii PG</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>total</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                  <tr>
                    <td>grand total</td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                      <td><input type="text" className="count" /></td>
                    </td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                      <td><input type="text" style={{ width: '85px' }} /></td>
                    </td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                    <td><input type="text" style={{ width: '85px' }} /></td>
                  </tr>
                </table>

                <p style={{ fontSize: 'xx-small' }}>(I) Certified that I hane personally verified the entries with the attandance,fees and admission registers and with the counderfoil of the fee receipls and have found them correct.</p><br />
                <p style={{ fontSize: 'xx-small' }}>(II) Certified that sum ______ has been remitted in to District Treasury,_____ during the month of ________</p>
                <table className="finalTable">
                  <tr>
                    <th>item</th>
                    <th>Demand</th>
                    <th>Collection</th>
                    <th>Balance</th>
                  </tr>
                  <tr>
                    <th>Upto ______</th>
                    <td>12324793</td>
                    <td>34234234</td>
                    <td>324324</td>
                  </tr>
                  <tr>
                    <th>____</th>
                    <td>234234</td>
                    <td>234234</td>
                    <td>234234</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td>234234</td>
                    <td>234234</td>
                    <td>234234</td>
                  </tr>
                </table>
                <h6 className="lastNote">Note:- Details of fines and statement of remittance to be accompanied.<br />Tuition fee for the acaademic year 1972-73 to 2015-16 have been remitted in the government account </h6>
              </div>
            </Scrollbars>}

          <div>
            <Scrollbars >
              <table className={classNames.userTable} id="emp">
                <Table dataSource={data.collections} pagination={false} columns={columns} />
              </table>
            </Scrollbars>
          </div>
        </div>


      </div>


      <div className={classNames.absoluteDiv}  >
        <label className={classNames.heading}>DCB Report</label>

        <Formik
          enableReinitialize
        >
          <div className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="date"
              name="startDate"
              id="startDate"
              value={state.startDate}
              onChange={(e) => {
                setState({ ...state, startDate: e.target.value })
              }
              }
            />
            <label>Start Date</label>

          </div>

        </Formik>

        {/* <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="time"
              name="startTime"
              id="startTime"
              value={state.startTime}
              onChange={(e) => {
                setState({ ...state, startTime: e.target.value })
              }
              }
            />
            <label>Start Time</label>
          </div>
        </Formik> */}
        <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="date"
              name="endDate"
              id="endDate"
              value={state.endDate}
              onChange={(e) => {
                setState({ ...state, endDate: e.target.value })
              }
              }
            />
            <label>End Date</label>

          </div>

        </Formik>

        {/* <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="time"
              name="endTime"
              id="endTime"
              value={state.endTime}
              onChange={(e) => {
                setState({ ...state, endTime: e.target.value })
              }
              }
            />
            <label>End Time</label>
          </div>
        </Formik> */}




        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 180 }}>
            <Select
              showSearch
              className={classNames.formFullWidth2}
              style={{ overflow: "hidden" }}
              onChange={(val) => {
                setState({ ...state, classId: (allClasses?.data?.find((x, i) => (x.className + i) === val)).classId, className: (allClasses?.data?.find((x, i) => (x.className + i) === val)).className });
              }}
            >
              {allClasses?.data?.map((item, i) => (
                <Option key={i} value={item.className + i}>
                  {item.className}
                </Option>
              ))}
            </Select>
            <label>{state?.filterType}</label>
          </div>
        </Formik>


        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            id="from"
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}


            onChange={(val) => {
              setState({ ...state, mainAccountName: val, mainAccount: mainAccount?.find(x => x.label === val).value });
            }}
          >
            {mainAccount?.map((item) => (
              <Option key={item.label} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
          <label>Main Account</label>

        </div>
        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            id="from"
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}


            onChange={(val) => {
              setState({ ...state, subAccount: subAccount?.find(x => x.label === val).value });
            }}
          >
            {subAccount?.map((item) => (
              <Option key={item.label} value={item.label}>
                {item.label}
              </Option>
            ))}
          </Select>
          <label>Sub Account</label>

        </div>
        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}
            onChange={(val) => {
              setState({ ...state, method: val });
            }}
          >
            {['Online', 'Normal']?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
          <label>Methods</label>

        </div>
        <div className={`${classNames.group} ${classNames.formFullWidth}`}>

          <Select
            showSearch
            className={classNames.formFullWidth2}
            style={{ overflow: "hidden" }}
            onChange={(val) => {
              setState({ ...state, activeStatus: val });
            }}
          >
            {['active', 'Discontinued', 'Suspended', 'Dismissed', 'Long Leave', 'TC Issue', 'College Transfer', 'Irregular']?.map((item) => (
              <Option key={item} value={item}>
                {item.toUpperCase()}
              </Option>
            ))}
          </Select>
          <label>Active Status</label>

        </div>
        <Button className={classNames.reloadButton} onClick={() => refetch()}>
          View
        </Button>
        <ReactToPrint
          trigger={() => <button className={classNames.reloadButton}>Print</button>}
          content={() => componentRef.current}
        />
        <Button className={classNames.reloadButton} onClick={() => { refetch() }}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="emp"
            filename="collectionReport"
            sheet="tablexls"
            buttonText="EXCEL"
          />
        </Button>
        {loading ? <LoadingAnimation dark /> :
          <Button className={classNames.reloadButton} onClick={() => {
            onDateCollectionReport()
            dcbPdfGeneretor();
          }}>
            PDF
          </Button>}
      </div>


      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
