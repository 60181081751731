import React, { useContext, useEffect, useState } from "react";
import { getAxiosTokenInstance } from "../../../../../../utils/axiosInstance";
import "./style.css"
import { object } from "prop-types";
import AddToken from "./AddToken";
import { FirebaseContext } from "../../../../../../context/FirebaseContext";
import { useQuery } from "react-query";




const getDepTeachers = async (selectedCollege) => {
    const instance = await getAxiosTokenInstance();
  
    try {
      const resp = await instance.get("/college/semester/getTeachers", {
        params: {
          type: "teacher",
          collegeId: selectedCollege?.collegeId,
        },
      });
  
      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      } else {
        return resp.data?.list;
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    }
  };

  

function SubjectAllocation({  classId, semester,onClose }) {
  const [values, setValues] = useState([]);
  const [action,setAction] = useState({addToken:false})
  const [loading,setIsLoading] = useState()
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const weeks = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
  function createShortName(fullName) {
    const nameParts = fullName?.split(" ");
    return nameParts?.map(part => part[0])?.join("").toUpperCase() || "EL"
  }

  const { data: allTeachers } = useQuery("getDepTeachers", () =>
    getDepTeachers(selectedCollege)
  );


  const deleteSubFromSemester = async item => {
    const instance = await getAxiosTokenInstance();
    setIsLoading(true);
    console.log("=========>running",item)
       await instance.post(
        '/college/semester/deleteSubject',
        {},
        {
          params: {
            collegeId,
            classId,
            semester: semester - 1,
            lessonPlanId: item?.id,
          },
        },
      );

      getSubjectDetails()
      
   
  };
  
 
  
  
  const getSubjectDetails = async () => {
    const instance = await getAxiosTokenInstance();

    instance
      .get("college/semester/getSemesterSubjects", {
        params: { collegeId, classId, semester },
      })
      .then(({ data }) => {
        setValues(data?.subjectDetails);
      });
  };
  useEffect(() => {
    getSubjectDetails();
  }, []);
  return (
    <div className="flex fixed bg-white top-0 h-screen w-[90vw] left-[5vw]">
        {action?.addToken ? <AddToken val={action?.addToken} onClose={()=>setAction({...action,addToken:false})} selectedCollege={selectedCollege} subjects={values?.subjectDetails} classId={classId} semester={semester} teachers={allTeachers}/>:null}
        <div className="mt-2 p-3 overflow-auto h-[80vh] flex flex-col">
        <button onClick={onClose} className="w-20">{"<"}back</button>
            <h3>Token From Teachers</h3>
        {values?.getTokens?.[0] ? values?.getTokens?.map((x, index) => (
          <div
            key={index}
            className="flex chg-btn flex-col w-full bg-white shadow-md rounded-lg p-4 mb-4"
          >
            <div className="flex items-center justify-between mb-3">
              <h1 className="text-xl font-semibold">{x?.subjectName}</h1>
              <div>
                {/* Edit Button with SVG Icon */}
                <button className="text-blue-500 ms-1 hover:text-blue-700 mr-2">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M15.232 5.232l3.536 3.536-12 12H3v-3.536z"></path>
                    <path d="M18.364 2.636a1.5 1.5 0 00-2.121 0l-12 12a1.5 1.5 0 00-.439 1.06v3a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.06-.44l12-12a1.5 1.5 0 000-2.12z"></path>
                  </svg>
                </button>
                {/* Delete Button with SVG Icon */}
                <button  className="text-red-500 ms-1 hover:text-red-700">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="mb-2">
              
            </div>
            <h6 className="text-gray-600 text-sm mb-3">S.Y 2019</h6>
              <div
                className="flex items-center justify-between text-gray-800"
              >
                <h6 className="text-md">{x?.teacherName}</h6>
                <span className="text-sm">{x?.workLoad}H</span>
              </div>
          </div>
        )) : "No Tokens Found"}
        
        </div>
      <div className="mt-5 p-3 overflow-auto h-[80vh]">
        <h3>Subject Allocation</h3>
        {values?.subjectDetails?.map((x, index) => (
          <div
            key={index}
            className="flex chg-btn flex-col w-full bg-white shadow-md rounded-lg p-4 mb-4"
          >
            <div className="flex items-center justify-between mb-3">
              <h1 className="text-xl font-semibold">{x?.name}</h1>
              <div>
                {/* Edit Button with SVG Icon */}
                <button className="text-blue-500 hover:text-blue-700 mr-2" onClick={()=>{setAction({...action,addToken:x})}}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M15.232 5.232l3.536 3.536-12 12H3v-3.536z"></path>
                    <path d="M18.364 2.636a1.5 1.5 0 00-2.121 0l-12 12a1.5 1.5 0 00-.439 1.06v3a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.06-.44l12-12a1.5 1.5 0 000-2.12z"></path>
                  </svg>
                </button>
                {/* Delete Button with SVG Icon */}
                <button onClick={()=>deleteSubFromSemester(x)} className="text-red-500 hover:text-red-700">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div className="mb-2">
              {/* Progress Bar */}
              <div className="h-2 w-full bg-gray-200 rounded-full">
                <div
                  className="h-2 bg-green-500 rounded-full"
                  style={{ width: `${x.progressPercentage}%` }}
                ></div>
              </div>
            </div>
            <h6 className="text-gray-600 text-sm mb-3">S.Y 2019</h6>
            {x?.teachers?.map((y, idx) => (
              <div
                key={idx}
                className="flex items-center justify-between text-gray-800"
              >
                <h6 className="text-md">{y?.name}</h6>
                <span className="text-sm">{y?.workLoad}H</span>
              </div>
            ))}
          </div>
        ))}
        <button className="text-white text-[30px] w-full py-1" onClick={()=>setAction({...action,addToken:true})}>+</button>
      </div>
      <div className="overflow-auto h-[90vh] mt-5">
        <h3>Time Table</h3>
                {weeks?.map((x)=>(
                    <div className="flex flex-col shadow g-r rounded p-2 mt-2"><h1 className="text-xl">{x}</h1><div className="flex">{values?.filterTimetable?.days[x]?.map(y=>(<button className="h-14 w-14 ms-3 mt-3">
                    {y?.shortName || createShortName(y?.name)}
                    </button>))}</div></div>
                ))}
      </div>
    </div>
  );
}

export default SubjectAllocation;
