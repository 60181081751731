import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../../NecttosComp/Layout/Layout';
import Input from '../../../NecttosComp/Input/Input';
import { useProfileEditNew } from '../../../queryHooks';
import { ProgressBar, calculateProfilepPercentage } from '../../../NecttosComp/ProgressBar/ProgressBar';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';
import Loader from '../../../NecttosComp/Loader/Loader';
import Loader1 from '../../../NecttosComp/Loaders/Loader';
import { ProfileDataDisplay, postProfileSubmission } from '../../../FunctionalExport/Formate';
import { uploadFileToFirebase } from '../../../NecttosComp/APICaller/APICaller';
import { getDropdownData } from '../../../pages/college/ApiServices';
import { getAxiosInstance } from '../../../utils/axiosInstance';
import { FirebaseContext } from '../../../context/FirebaseContext';
import CSVEditor from './csvEditor';
import { Popconfirm } from 'antd';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
	modal: Boolean;
}
export interface UploadResult {
	url: string;
	fileId: string;
}

function Members({ onClose, studentId, onNext, modal }: Props) {
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);

	console.log({ dropDowns });

	const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean) } }) => {
		setNoMembers(event.target.checked);
		callProfileSubmission(event.target.checked === true ? 'nill' : 'yes');
	};

	const titleKey: any = 'membersBodies';
	const [temp, setTemp] = useState<any>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const componentRef = useRef<HTMLTableElement>(null);
	const [mode, setMode] = useState<any>(false);
	const [addNew, setAddNew] = useState<any>({ name: '', type: '', startDate: '', endDate: '', documentId: '', documentURL: '', university: '' });
	const { user, collegeId } = useContext(FirebaseContext);
	const { data: profileData, refetch: refetch } = useProfileEditNew({ titleKey, userId: user?.uid });
	const [values, setValues] = useState<any[]>(profileData);
	const [noMembers, setNoMembers] = useState(values?.[0]?.noData ? true : false);
	const [csv, setCSv] = useState(false);
	const [isTick, setIsTick] = useState<any>(null);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files && files[0]) {
			(async () => {
				try {
					const { url, fileId } = await uploadFileToFirebase(files[0], user?.uid, addNew?.documentId, (progress) => {
						// setUploadProgress((prev: any) => ({ ...prev, [documentId]: progress }));
					});
					setAddNew({ ...addNew, documentURL: url, documentId: fileId });
				} catch (error) {
					console.error('Error uploading file:', error);
				}
			})();
		}
	};

	const callProfileSubmission = async (keyData: string) => {
		await postProfileSubmission({
			keyData,
			values,
			temp,
			addNew,
			setIsLoading,
			setTemp,
			setMode,
			setAddNew,
			refetch,
			titleKey,
		});
	};

	const handleUpdate = async (addNew: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/updateByKeyData', {
				_id: addNew._id,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
				newValue: addNew,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleDelete = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.delete('college/profile/deleteByKeyData', {
				data: { _id: data._id, userId: user?.uid, collegeId, keyData: titleKey },
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleUploadCSV = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/addByKeyData', {
				data,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
	}, [profileData]);
	console.log(values?.length);
	return (
		<>
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button
							type='doc'
							onClick={() => {
								setCSv(true);
							}}
							children='CSV'
						/>
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div className='w-full '>
								<div ref={componentRef} className='w-full printable'>
									{!values?.[0]?.noData === true && (
										<Table width='100%' id={'profilePDF'} innerWidth={['10%', '20%', '10%', '10%', '10%', '30%', '10%']}>
											<Thead>
												<Tr>
													<Th position={0} children='Type' />
													<Th position={1} children='Name' />
													<Th position={2} children='University' />
													<Th position={3} children='Start Date' />
													<Th position={4} children='End Date' />
													<Th position={5} children='Supporting Document' />
													<Th position={6} children='Action' />
												</Tr>
											</Thead>
											<Tbody height='auto'>
												{values?.map((x: any, index: any) => (
													<Tr>
														<Td position={0} index={index}>
															<p>{x?.type}</p>
														</Td>
														<Td position={1} index={index}>
															<p>{x?.name}</p>
														</Td>
														<Td position={2} index={index}>
															<p>{x?.university}</p>
														</Td>
														<Td position={3} index={index}>
															<p>{x?.startDate}</p>
														</Td>
														<Td position={4} index={index}>
															<p>{x?.endDate}</p>
														</Td>
														<Td position={5} index={index}>
															<p>{x?.documentURL}</p>
														</Td>
														<Td position={6} index={index}>
															<div className='flex'>
																<Button
																	type='update'
																	onClick={() => {
																		setMode('edit');
																		setAddNew(x);
																		setTemp(x);
																	}}>
																	Edit
																</Button>
																<Popconfirm placement='topRight' title='Are you sure you want to delete?' okText='Yes' cancelText='No' onConfirm={() => handleDelete(x)}>
																	<Button type='delete'>Delete</Button>
																</Popconfirm>
															</div>
														</Td>
													</Tr>
												))}
											</Tbody>
										</Table>
									)}
								</div>
							</div>

							{!noMembers && (
								<Button
									onClick={async () => {
										setMode(!mode);
										setTemp({});
									}}
									type='update'>
									Add New
								</Button>
							)}

							{(values?.[0]?.noData === true || values?.length === 0) && (
								<>
									<label className='inline-flex items-center cursor-pointer'>
										<input type='checkbox' className=' appearance-none h-5 w-5  border border-gray-400   checked:bg-blue-600 checked:border-transparent focus:outline-none  transition duration-200  align-top bg-no-repeat bg-center bg-contain   cursor-pointer  rounded-md' checked={noMembers} onChange={handleChange} />
										<span className='ml-2 text-blue-500 text-lg font-medium'>I am not a member of any Academic or Professional Bodies</span>
									</label>
								</>
							)}
						</div>
					</div>
				</Body>
			</div>
			{mode && (
				<Main width='95%' height='95%'>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'></div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body>
						<div className='grid grid-flow-col p-2 w-full h-full k gap-2'>
							{['delete', 'edit'].includes(mode) && (
								<div className=' p-5 rounded bg-white'>
									<ProfileDataDisplay temp={temp} />
									<div className='flex float-right items-center my-1'>
										{mode === 'delete' && (
											<Button
												type='delete'
												onClick={() => {
													callProfileSubmission('delete');
												}}>
												Delete
											</Button>
										)}
									</div>
								</div>
							)}
							{mode !== 'delete' && (
								<div id='scrollBarEnable' className='px-3 py-2 w-[35vw] z-50 overflow-auto  max-h-[85vh] bg-white h-full rounded'>
									<h5 className='text-blue-900  font-bold'>You can edit your data here !</h5>
									<div className='relative z-[3]'>
										<Input width='98%' fieldName='Name of the Body ' returnKey='type' setState={setAddNew} state={addNew} type='text' />
										<p>(Academic council, BoS, Setting of question papers for UG/PG programs, Design and Development of Curriculum for Add on/ certificate/ Diploma Courses , Assessment /evaluation process of the affiliating University)</p>
									</div>
									<div className='relative z-[3]'>
										<Input width='98%' fieldName='Details' returnKey='name' setState={setAddNew} state={addNew} type='text' />
									</div>
									<div className='relative z-[99]'>
										<Input width='98%' fieldName='University' returnKey='university' options={['University of Calicut', 'University of Kerala', 'Kannur University', 'Mahatma Gandhi University', 'Others']} setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[2]'>
										<Input width='98%' fieldName='Start Date' returnKey='startDate' setState={setAddNew} state={addNew} type='date' />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName='End Date' returnKey='endDate' setState={setAddNew} state={addNew} type='date' />
									</div>

									<input type='file' onChange={handleFileChange} />

									<ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, { name: '', type: '', startDate: '', endDate: '', documentId: '', documentURL: '', university: '' })?.percentage)} />
									<Button
										width='100%'
										onClick={async () => {
											handleUpdate(addNew);
											closeAnimation();
											setTimeout(() => setMode(false), 250);
										}}
										type='save'
										children='Save'
									/>
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}
			{csv && (
				<CSVEditor
					titleKey={titleKey}
					keysNeeded={['type', 'name', 'link', 'university', 'startDate', 'endDate', 'documentURL']}
					setCsv={setCSv}
					returnData={async (data) => {
						if (data) {
							console.log(data);
							let val = [...values];
							setIsTick(false);
							await handleUploadCSV(data);
							setIsTick(true);
							setTimeout(() => {
								setIsTick(null);
								setCSv(false);
							}, 2000);
						}
					}}
				/>
			)}

			{isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null}
			{isLoading && <Loader />}
		</>
	);
}

export default Members;
