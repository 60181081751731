import React, { useState, useContext } from 'react'
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../context/FirebaseContext';



function FineTransfer(props) {
  const { collegeId } = useContext(FirebaseContext);
  const [takenDate, setTakenDate] = useState()
  const [returnDate, setReturnDate] = useState()
  const [fine, setFine] = useState({})
  const [days, setDays] = useState(0)
  const [holiday, setHoliday] = useState(0)
  const [holidaysReport, setHolidaysReport] = useState()
  const [submitBtn, setSumbitBtn] = useState(false)
  const [checkBox, setCheckBox] = useState({})


  const updateEditedData = async () => {
    const instance = await getAxiosTokenInstance();
    try {
      await instance.post('/college/library/fineRegister', {
        tookDate: takenDate, returnDate, collegeId, userId: props?.data?.userId, isFull: checkBox?.isFull
      })
      window.alert('successfully edited')
    } catch {
      window.alert('something went wrong')
    }
  }



  const daysCalculate = async (tookDate, returnDate, collegeId, userId) => {
    const instance = await getAxiosTokenInstance();
    try {
      let resp = await instance.get('/college/library/getFineDetailsData', {
        params: { tookDate, returnDate, collegeId, userId: props?.data?.userId, isFull: checkBox?.isFull },
      })
      if (resp.data.totalBooks) {
        setFine(resp.data.totalFine)
        setDays(resp.data.totalBooks)
      }
    } catch {

    }
  }


  return (
    <div>
      <Row>
        <h2 style={{ fontFamily: 'fantasy', color: 'GrayText' }}>View</h2>

        <Col>
          <div>
            <label htmlFor="">Start Date :-</label>
            <input type="checkBox" value={checkBox} onChange={(e) => {
              setCheckBox({ ...checkBox, isFull: !checkBox.isFull })
            }} />
          </div>
          <div>
            <label htmlFor="">Start Date :-</label>
            <input type="date" value={takenDate} onChange={(e) => {
              setTakenDate(e.target.value)
            }} />
          </div>
          <div>
            <label htmlFor="">End Date :-</label>
            <input type="date" value={returnDate} onChange={(e) => {
              setReturnDate(e.target.value)
            }} />
          </div>
          <button className="editBtn" style={{ marginTop: '7px' }} onClick={async () => {
            await daysCalculate(takenDate, returnDate, collegeId, props?.data?.userId)
            setSumbitBtn(true)
          }}>Load</button>
        </Col>
        <h2 style={{ fontFamily: 'times', color: 'GrayText' }}>Total Book Circulation: {days}</h2>
        <h2 style={{ fontFamily: 'times', color: 'GrayText' }}>All ready remitted fine: {fine?.transferedFine}</h2>
        <h2 style={{ fontFamily: 'times', color: 'GrayText' }}>Balance fine to be remitted: {fine?.balanceFine}</h2>
      </Row>

      <div className='centerContent'>
        {submitBtn ? <button className='editBtn animation-opacity' style={{ marginTop: '5px' }} onClick={() => {
          updateEditedData();
          setSumbitBtn(false)
        }}>Submit</button> : null}
      </div>
    </div>
  )
}

export default FineTransfer