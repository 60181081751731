import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import message from "antd/lib/message";
import { Field, ErrorMessage, Form, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getDDCSubjects, getFootPrint, getLibraryfineRegister } from "../../ApiServices";
import moment from "moment";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";

export const FineRegister = ({ onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data = [], isFetching, refetch } = useQuery("getLibraryfineRegister", getLibraryfineRegister(collegeId));
  const [isLoading, setIsLoading] = useState(false);

  const [admisionNumber, setAdmisionNumber] = useState("");

  const [state, setState] = useState({
    date: "",
    from: "",
    to: "",
    amount: "",
    naration: "",
  });

  const manageDDCSubject = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log({ admisionNumber });
      const instance = await getAxiosTokenInstance();
      let resp;
      resp = await instance.post("/college/action/postFootPrint", {
        collegeId,
        admisionNo: admisionNumber,
        method: "offline",
        type: "internal",
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setAdmisionNumber("");
        refetch();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    } finally {
      setIsLoading(false);
    }
  }, [admisionNumber]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        manageDDCSubject();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [manageDDCSubject]);

  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        <div className={classNames.listContainer2}>
          <Scrollbars style={{ height: "100%" }}>
            <div ref={componentRef} className="printable">
              <img style={{ position: "absolute", width: "80%", marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
                <h5 style={{ margin: 0, fontSize: "30px", width: "100%", alignSelf: "center" }}>Fine Register</h5>
                <div style={{ width: "100%", display: "flex", paddingRight: 12, flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 16, width: "5%" }}>Sn</h5>
                  <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 16, width: "15%" }}>Admn No</h5>
                  <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 16, width: "30%" }}>Name</h5>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "20%" }}>CLass/Dept</h5>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "10%" }}>Date</h5>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "10%" }}>Books</h5>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "10%" }}>Fine</h5>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "10%" }}>Reciept</h5>
                  <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 16, width: "20%" }}>Date of Remittence</h5>
                </div>
                {data?.map((item, i) => (
                  <>
                    <div style={{ width: "100%", paddingRight: 12, display: "flex", minHeight: "50px", flexDirection: "row", alignItems: "center", alignSelf: "center" }}>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 19, width: "5%" }}>{i + 1}</h5>
                      <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                      <h5 style={{ marginRight: 4, color: "black", alignSelf: "left", fontSize: 19, width: "15%" }}>{item.admisionNo}</h5>
                      <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                      <h5 style={{ marginRight: 4, color: "black", alignSelf: "left", fontSize: 19, width: "30%" }}>{item.name}</h5>
                      <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 19, width: "20%" }}>{item.classDept}</h5>
                      <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 19, width: "10%" }}>{item.date}</h5>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 19, width: "10%" }}>{item.totalBooks}</h5>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 19, width: "10%" }}>{item.balanceFine}</h5>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 19, width: "10%" }}>{item.billNo}</h5>
                      <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 19, width: "20%" }}>{item.dateOfRemitence}</h5>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        height: 1,
                        backgroundColor: "black",
                      }}
                    ></div>
                  </>
                ))}
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
      <Button
        className={classNames.reloadButton}
        onClick={() => {
          refetch();
        }}
      >
        Reload
      </Button>
      <ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />
      {!isLoading && (
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      )}
    </div>
  );
};
