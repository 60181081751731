import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";

function Qualification({ onClose }) {
  const { user, collegeId } = useContext(FirebaseContext);
  const [values, setValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [changes,setChanges] = useState(true)

  const getUser = async () => {
    const instance = await getAxiosTokenInstance();

    const { data } = await instance.get('/college/profile/get', {
      params: { profileId: user?.uid, groupData: true, isStaff: false, collegeId, isGrand: false },
    });

    console.log({ user, data });
    setValues({qualifications:data?.profileData?.qualifications});
    const keys = ["mark", "specialisation", "course", "yearOfPass","university"];
    for (let i in data?.profileData?.qualifications) {
      const elem = data?.profileData?.qualifications[i];
      const pendingdata = data?.profileData?.pendingData?.qualifications[i];
      for (let j of keys) {
        if (elem[j] !== pendingdata[j]) {
          setChanges({ ...changes, elem });
        }
      }
    }
  };

  useEffect(() => {
    getUser();
  }, [user]);

  const handleInputChange = (e,i) => {
    let temp = values?.travelDetails
    temp[i][e.target.name] = e.target.value
    setValues({ ...values, travelDetails:temp });
  };

  const renderField = (label, value, name,i) => {
    if (name === 'bloodGroup' && isEditing) {
      return (
        <div className="flex justify-between items-center my-2">
          <label className="text-lg font-medium">{label}:</label>
          <select 
            name={name} 
            value={value || ''} 
            onChange={handleInputChange}
            className="border border-gray-300 rounded py-1 px-2 text-lg"
          >
            <option value="">Select Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select>
        </div>
      );
    }

    return (
      <div className="flex justify-between items-center my-2">
        <label className="text-lg font-medium">{label}:</label>
        {isEditing ? (
          <input 
            type="text" 
            name={name} 
            value={value || ''} 
            onChange={(e)=>handleInputChange(e,i)}
            className="border border-gray-300 rounded py-1 px-2 text-lg"
          />
        ) : (
          <p className="text-lg">{value || "--"}</p>
        )}
      </div>
    );
  };

  return (
      <div className="bg-white overflow-auto grid grid-flow-col shadow-lg rounded-lg h-[90vh] w-[70vw] max-w-4xl">
        <div className="px-5 py-4">
          <button onClick={onClose} className="fixed right-5 top-5 text-lg">✖</button>

          {values?.qualifications?.map((x,i)=>(
            <>
          <h3 className="text-xl font-bold mb-4">{x?.type}</h3>
          {renderField('Register Number', x?.registerNo, 'registerNo',i)}
          {renderField('University', x?.university, 'university',i)}
          {renderField('Year of Pass', x?.yearOfPass, 'yearOfPass',i)}
          {renderField('Course', x?.course, 'course',i)}
          {renderField('Specialised', x?.specialisation, 'specialisation',i)}
          {renderField('Mark', x?.mark, 'mark',i)}

            </>
          ))}

          
          
          <button 
            onClick={() => setIsEditing(!isEditing)} 
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mt-4"
          >
            {isEditing ? 'Save' : 'Edit'}
          </button>
        </div>
        {changes[0] && 
        <div className="px-5 py-4">
        <h3 className="text-xl font-bold mb-4">Updated</h3>
        {changes?.map((x) => (
          <>
            {Object.entries(x).map((key) => (
              <div className="flex justify-between items-center my-2">
                <label className="text-lg font-medium">{key[0]}:</label>

                <p className="text-lg">{key[1]}</p>
              </div>
            ))}
          </>
        ))}
      </div>
        }
      </div>
  );
}



export default Qualification;
