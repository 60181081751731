import React, { useContext, useEffect, useState } from "react";
import classNames from "./exportModal.module.scss";
import "./styleData.css";
import { useMutation, useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import moment from "moment";
import CustomDropDown from "../../../../myComp/CustomDropDown";
import Button from "../../../../NecttosComp/Button/Button";
import { AcademicYears } from "../../ApiServices";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";

export const exportPdf = async ({ olddata, setLoading }) => {
  const instance = await getAxiosTokenInstance();

  let data = {
    ...olddata,
  };

  try {
    const resp = await instance.post(`/college/iqac/getNAAC_C1_1`, data, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      setLoading(false);
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    setLoading(false);
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const naacReportGeneration = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post("/college/iqac/getNAAC_C1_1", data);
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const C1_1 = ({ onClose }) => {
  const { collegeId } = useContext(FirebaseContext);

  const [stateData, setStateData] = useState(() => {
    const saved = localStorage.getItem("stateData");
    const initialValue = saved ? JSON.parse(saved) : {};
    return initialValue;
  });
  useEffect(() => {
    localStorage.setItem("stateData", JSON.stringify(stateData));
  }, [stateData]);
  const resetStateData = () => {
    const initialState = {};
    setStateData(initialState);
    localStorage.setItem("stateData", JSON.stringify(initialState));
  };

  const exportPdfMutation = useMutation(exportPdf);

  const [loading, setLoading] = useState(false);

  const { data: filteredData = [] } = useQuery(["naacReportGeneration", stateData], naacReportGeneration({ collegeId, academicYear: stateData.year }));

  console.log({ filteredData });

  const onExportExam = async () => {
    setLoading(true);

    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let olddata = {
        collegeId,
        academicYear: stateData.year,
        needExcel: true,
      };

      link.download = "NAAC File 1_1 " + moment(new Date()).format("DD_MM_YYYY HH MM");
      const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
      link.href = URL.createObjectURL(new Blob([pdfData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
      link.click();
      setLoading(false);

      onClose();
    } catch (e) {}
  };

  return (
    <Main>
      <Header>
        <div>
          <Button type="close" onClick={onClose}>
            Close
          </Button>

          <AcademicYears setAcademicYear={(x) => setStateData({ ...stateData, year: x })} />
          <Button
            type="save"
            onClick={() => {
              onExportExam();
            }}
          >
            Export To Excel
          </Button>
        </div>
      </Header>
      <Body>
        <div className="h-[70vh] w-[95vw] overflow-auto">
          {loading ? (
            <LoadingAnimation dark />
          ) : (
            <div className="flex flex-nowrap items-start">
              {filteredData?.map((element, index) => (
                <div key={element.departmentName} className={"department-container custom-scrollbar "}>
                  <h2 className="department-name">
                    {index + 1}. {element.departmentName}
                  </h2>
                  <div className="subject-details">
                    <h3 className="subject-heading">UG Subject Details:</h3>
                    <ul className="subject-list">
                      {element?.ugSubjectDetails?.map((subject) => (
                        <li key={subject?._id} className="subject-item">
                          <span className="subject-code">{subject?.subjectCode}</span> -- <span className="subject-name">{subject?.name}</span> -- <span className="subject-year">{subject?.year}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="subject-details">
                    <h3 className="subject-heading">PG Subject Details:</h3>
                    <ul className="subject-list">
                      {element?.pgSubjectDetails?.map((subject) => (
                        <li key={subject?._id} className="subject-item">
                          <span className="subject-code">{subject?.subjectCode}</span> -- <span className="subject-name">{subject?.name}</span> -- <span className="subject-year">{subject?.year}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Body>
    </Main>
  );
};
