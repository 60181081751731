import React from 'react'
import classNames from "./feesCategories.module.scss";
import { Field, Form, Formik } from "formik";
import moment from 'moment';

export default function FeesCategories({ options, feeAmounts, setFeeAmounts, feeReciept, setFeeReciept }) {

    return (
        <div className={classNames.feeCategories}>
            <Formik
                enableReinitialize
                initialValues={{}}
            >
                {({ resetForm }) => (
                    <Form className={classNames.feeForm}>
                        {
                            options?.filter(x => x.key !== 'Others')?.map(x => (
                                (x?.futureDue + x?.currentDue) > 0 &&
                                <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                                    <Field
                                        type="text"
                                        name={x.key}
                                        placeholder={x?.currentDue}
                                        style={{ color: 'green', }}
                                        value={feeAmounts[x.key]}
                                        id={x.key}
                                        onChange={(e) => {
                                            setFeeAmounts({ ...feeAmounts, [x.key]: e.target.value })
                                        }}
                                    />
                                    <Field
                                        type="text"
                                        name={x.key}
                                        placeholder={'Rt.No:' + x?.recieptNo}
                                        style={{ color: 'red', fontSize: 20 }}
                                        value={feeReciept[x.key]}
                                        id={x.key}
                                        onChange={(e) => {
                                            setFeeReciept({ ...feeReciept, [x.key]: e.target.value })
                                        }}
                                    />
                                    <label>{x.name}</label>
                                </div>

                            ))
                        }
                        {
                            options?.find(x => x.key === 'Others')?.dues?.map(x => (
                                moment(new Date()).diff(moment(x.dueDate)) >= 0 &&
                                <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                                    <Field
                                        type="text"
                                        name={x.key}
                                        style={{ color: 'green', }}
                                        placeholder={x?.currentDue}
                                        placeholderStyle={{ color: 'green', }}
                                        value={feeAmounts[x.key]}
                                        id={x.key}
                                        onChange={(e) => {
                                            setFeeAmounts({ ...feeAmounts, [x.key]: e.target.value })
                                        }}
                                    />
                                    <Field
                                        type="text"
                                        name={x.key}
                                        style={{ color: 'red', fontSize: 20 }}
                                        placeholder={'Rt.No:' + x?.recieptNo}
                                        value={feeReciept[x.key]}
                                        id={x.key}

                                        onChange={(e) => {

                                            setFeeReciept({ ...feeReciept, [x.key]: e.target.value })
                                        }}
                                    />
                                    <label>{x.key}</label>
                                </div>
                            ))
                        }
                    </Form>
                )}
            </Formik>
        </div>
    )
}
