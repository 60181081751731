import React, { useContext, useRef, useState } from "react";
import classNames from "./addFeesForm.module.scss";
import Select from "antd/lib/select";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
// import { FirebaseContext } from "../../../context/FirebaseContext";
import { useQuery, useQueryClient } from "react-query";
import { Field, ErrorMessage, Form, Formik, useFormikContext } from "formik";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { ArrowRightOutlined } from "@ant-design/icons";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useAccountsHeads, useAllClasses } from "../../../../queryHooks";
import { Button } from "antd";


const getUserList = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { type, collegeId, classId }] = queryKey;
  try {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(
      `college/users/get?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`}${classId ? `&classId=${classId}` : ""}`
    );
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data;
  } catch (err) {

    throw new Error(err || "API Error");
  }
};




const { Option } = Select;

const feesTypess = [
  { name: "Tution Fees", key: "TuitionFee" },
  { name: "Examination Fees", key: "ExamFee" },
  { name: "Other Fees", key: "Others" },
];

function AddFeesForm({
  onClose
}) {
  const ref = useRef();

  const { collegeId } = useContext(FirebaseContext);



  const { data: userList, status, error, refetch, isFetching } = useQuery(
    [`userListstudent`, { type: 'student', classId: '', collegeId }],
    getUserList
  );

  const allClasses = useAllClasses({ needAll: false, classOnly: false });

  const classList = allClasses?.data || []
  const accounts = useAccountsHeads();

  const mapFeeAcounts = accounts?.data?.subAccounts || []

  const [isLoading, setIsLoading] = useState(false);
  const [tutionFee, setTutionFee] = useState(false);



  const [state, setState] = useState({
    feesType: "",
    titleOfFee: "",
    description: "",
    dueId: "",
    isStudent: true,
    payee: "",
    amount: "",
    dueDate: ""
  });




  const onAddFees = async (data, actions) => {
    try {

      data = [{
        feeType: state.feesType,
        title: state.titleOfFee,
        dueId: state.dueId,
        description: state.description,
        amount: state.amount,
        dueDate: state.dueDate,
      }]
      setIsLoading(true);
      const postData = data?.filter(x => x.amount && x.dueDate);
      const instance = await getAxiosTokenInstance();
      let params = { collegeId };
      if (state.isStudent) {
        params['userId'] = state.payee._id;
      } else {
        params['classId'] = state.payee._id;
      }
      let resp = await instance.post("/college/fees/addDue", [...postData], { params });

      if (resp?.status !== 202) {
        throw new Error(resp?.data?.message || "API Error");
      }
      message.success(data?.message || "Fees Added");
      setState({
        feesType: "",
        titleOfFee: "",
        description: "",
        dueId: "",
        isStudent: true,
        payee: "",
        amount: "",
        dueDate: ""
      });
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);

    }
  };

  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >
        <div className={classNames.addFeeForm}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {tutionFee && <button onClick={() => setTutionFee(false)}>Back</button>}
            <div className={classNames.title}>ADD NEW</div>
          </div>

          <Formik
            enableReinitialize
            initialValues={{}}
            onSubmit={(values, formikActions) => {
              onAddFees(values, formikActions);
            }}
          >
            {({ resetForm }) => (
              <Form ref={ref} className={classNames.feeForm}>
                <Select
                  showSearch
                  value={state.feesType}
                  id="feesType"
                  className={classNames.formFullWidth}
                  style={{ overflow: "hidden" }}
                  placeholder="Select Fees Type"
                  onChange={(val) => {
                    setState({ ...state, feesType: val });
                  }}
                >
                  <label>Select Fees Type</label>
                  {feesTypess?.map((type) => (
                    <Option key={type.key} value={type.key}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
                <div className={classNames.error}>
                  <ErrorMessage name="feesType" />
                </div>



                <div className={`${classNames.group}`}>
                  <Select
                    showSearch
                    id="titleOfFee"
                    className={classNames.formFullWidth}
                    style={{ overflow: "hidden" }}
                    onChange={(val) => {
                      setState({ ...state, titleOfFee: mapFeeAcounts?.find(x => x?.label === val).label, dueId: mapFeeAcounts?.find(x => x?.label === val).value });
                    }}
                  >
                    <label>Select Account</label>
                    {mapFeeAcounts?.map((item) => (
                      <Option key={item?.label} value={item?.label}>
                        {item?.label}
                      </Option>
                    ))}
                  </Select>
                  <div className={classNames.error}>
                    <ErrorMessage name="titleOfFee" />
                  </div>
                </div>





                <div className={classNames.radioGroup}>
                  <div
                    className={classNames.radioItem}
                    onClick={() => { setState({ ...state, isStudent: true }) }}
                  >
                    <div className={classNames.radioCircle}>
                      {state.isStudent && (
                        <div className={classNames.radioInCircle}></div>
                      )}
                    </div>
                    <span>Student</span>
                  </div>
                  <div
                    className={classNames.radioItem}
                    onClick={() => { setState({ ...state, isStudent: false }) }}
                  >
                    <div className={classNames.radioCircle}>
                      {!state.isStudent && (
                        <div className={classNames.radioInCircle}></div>
                      )}
                    </div>
                    <span>Class</span>
                  </div>
                </div>
                {
                  state.isStudent ? (
                    <Select
                      showSearch
                      value={state.payee?.name + ' [' + state?.payee?.admisionNo + ']'}
                      id="payee"
                      className={classNames.formFullWidth}
                      style={{ overflow: "hidden" }}
                      placeholder="Who pay this fees?"
                      onChange={(val) => {
                        setState({ ...state, payee: userList?.list?.find(x => (x.name + ' [' + x.admisionNo + ']') === val) });
                      }}
                    >
                      <label>Who pay this fees?</label>
                      {userList?.list?.map((item) => (
                        <Option key={item._id} value={item.name + ' [' + item.admisionNo + ']'}>
                          {item.name + ' [' + item.admisionNo + ']'}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      showSearch
                      value={state.payee?.className}
                      id="payee"
                      className={classNames.formFullWidth}
                      style={{ overflow: "hidden" }}
                      placeholder="Who pay this fees?"
                      onChange={(val) => {
                        setState({ ...state, payee: classList?.find(x => x.className === val) });
                      }}
                    >
                      <label>Who pay this fees?</label>
                      {classList?.map((item) => (
                        <Option key={item?.className} value={item?.className}>
                          {item.className}
                        </Option>
                      ))}
                    </Select>
                  )
                }
                <div className={classNames.error}>
                  <ErrorMessage name="payee" />
                </div>


                <>
                  <div
                    className={`${classNames.group} ${classNames.formFullWidth}`}
                  >
                    <Field
                      type="text"
                      maxLength={10}
                      name="amount"
                      value={state.amount}
                      id="amount"
                      required
                      onChange={(e) =>
                        setState({ ...state, amount: e.target.value })
                      }
                    />
                    <label>Amount</label>
                    <div className={classNames.error}>
                      <ErrorMessage name="amount" />
                    </div>
                  </div>
                  <div
                    className={`${classNames.group} ${classNames.formFullWidth}`}
                  >
                    <Field
                      type="date"
                      maxLength={10}
                      name="dueDate"
                      value={state.dueDate}
                      id="dueDate"
                      required
                      onChange={(e) =>
                        setState({ ...state, dueDate: e.target.value })
                      }
                    />
                    <label>Due Date</label>
                    <div className={classNames.error}>
                      <ErrorMessage name="dueDate" />
                    </div>
                  </div>
                  <div className={classNames.btns}>
                    <div
                      className={classNames.btnClear}
                      onClick={() => {
                        setState({
                          ...state,
                          feesType: "",
                          titleOfFee: "",
                          description: "",
                          payee: "",
                          amount: "",
                          dueDate: "",
                        });
                        resetForm();
                      }}
                    // onClick={resetForm}
                    >
                      Clear
                    </div>
                    <div className={classNames.formButtonWidth}>
                      <button type="primary" value="Submit">
                        Submit{" "}
                        <ArrowRightOutlined
                          style={{ fontSize: 22, margin: "auto 0 auto 5px" }}
                        />
                      </button>
                    </div>
                  </div>
                </>

              </Form>
            )}
          </Formik>

          {isLoading && (
            <div className={classNames.loading}>
              <LoadingAnimation />
            </div>
          )}

        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      </div>
    </div >
  );
}
export default AddFeesForm;
