import React, { useContext, useState } from 'react';
import classNames from './WorkingFIles.module.scss';
import { CSVReader } from 'react-papaparse';
import { useMutation, useQuery } from 'react-query';
import message from 'antd/lib/message';

import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { ArrowRightOutlined } from '@ant-design/icons';
import { getDayBook } from '../../ApiServices';
import CustomDropDownKey from '../../../../myComp/CustomDropDownKey';

const addUser = async (usersData) => {
  const data = usersData?.userList;

  const chunkData = (data, chunkSize) => {
    let result = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      let chunk = data.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  };

  const instance = await getAxiosTokenInstance();

  const chunks = chunkData(data, 999);

  console.log({ chunks });

  let promises = [];

  for (let chunk of chunks) {
    const promise = instance

      .post('college/workingSpace/preApiForTransferFees', chunk)

      .then((resp) => resp.data)
      .catch((error) => {
        console.error('Error posting chunk:', error);
        throw error;
      });
    promises.push(promise);
  }

  try {
    let results = await Promise.all(promises);
    return results;
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

function WorkingFIles({ onClose }) {
  const [userList, setUserList] = useState([]);
  const [errorMessage, updateErrorMessage] = useState('');
  const { collegeId } = useContext(FirebaseContext);
  const { mutate } = useMutation(addUser, {
    onSuccess: (data) => {
      if (data.statusCode === 400) {
        let text = data.message;
        navigator.clipboard.writeText(text);
        var errorData = new Blob([text], { type: 'text/plain' });
        var url = window.URL.createObjectURL(errorData);
        window.open(url, '_blank');
        updateErrorMessage('Some Users Not Created');
      } else {
        message.success('Users Added');
      }
    },
    onError: (error) => {
      updateErrorMessage(error?.response?.data?.message || error?.message || 'API Error');
      setUserList([]);
    },
  });

  const [state, setState] = useState({
    date: '',
    from: '',
    to: '',
    amount: '',
    naration: '',
    linkId: '',
  });
  function addUsers() {
    mutate({ userList, from: state.from, to: state.to });
  }

  function parseData(csvData) {
    let userListTemp = [];
    csvData?.forEach((row) => {
      userListTemp.push(row.data);
    });
    if (userListTemp.length) {
      setUserList(userListTemp);
    } else {
      updateErrorMessage('No Data Or Invalid Data On File');
    }
  }
  const { data = [], isFetching, refetch } = useQuery('getDayBoook', getDayBook(collegeId));
  const [isLoading, setIsLoading] = useState(false);

  let fromAccounts = data.mainAccounts || [];
  let toAccounts = data.mainAccounts || [];

  return (
    <div div className={classNames.WorkingFIles}>
      <CustomDropDownKey keyData={'from'} keyObject={'name'} keyReturn={'_id'} titleData={'From Account'} dropValues={fromAccounts} stateData={state} setStateData={setState} />
      <CustomDropDownKey keyData={'to'} keyObject={'name'} keyReturn={'_id'} titleData={'To Account'} dropValues={toAccounts} stateData={state} setStateData={setState} />
      <label
        className={classNames.enterCsv}
        onClick={() => {
          updateErrorMessage('');
        }}>
        <CSVReader onDrop={parseData} onError={() => {}} onRemoveFile={() => {}} config={{ header: true }} />
        <div className={classNames.label}>{userList.length ? `${userList.length} Users Read` : 'Select CSV file here'} </div>
        <div className={classNames.btnBrowse}>Browse</div>
      </label>

      {userList && userList?.length > 0 && (
        <>
          <div className={classNames.messageLabel}>Message</div>
          <div className={classNames.message}>Ready to upload {userList?.length}</div>
        </>
      )}
      {errorMessage && errorMessage !== '' && <div className={`${classNames.message} ${classNames.error}`}>** {errorMessage} **</div>}

      <div className={classNames.btns}>
        <div className={classNames.btnClear} onClick={onClose}>
          Clear
        </div>

        <div className={classNames.formButtonWidth}>
          <button
            type='primary'
            onClick={() => {
              addUsers();
              updateErrorMessage('');
            }}>
            Submit <ArrowRightOutlined style={{ fontSize: 22, margin: 'auto 0 auto 5px' }} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorkingFIles;
