import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getSubjects } from "../../pages/college/Accademics/SubjectBank/SubjectDetails/SubjectDetails";

const getFieldDropDowns = (subjectId: any, controlledUnits: [], collegeId: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post("/college/examProcess/getQuestionPaper", {
      subjectId,
      controlledUnits,
      collegeId,
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.questionPaper;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}
interface ISubjectState {
  partA: any;
  partB: any;
  partC: any;
  [key: `m${number}partA` | `m${number}partB` | `module${number}` | `m${number}partC`]: any;
}

function QuestionPaper({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const { data: subjectData = {} } = useQuery(["getFieldSubjects", addNew?.subjectId?._id], getSubjects(addNew?.subjectId?._id));
  const ref = useRef<any>(null);

  const [val, setVal] = useState<any>({
    partA: subjectData?.partA || { questions: "", skip: "", mark: "" },
    partB: subjectData?.partB || { questions: "", skip: "", mark: "" },
    partC: subjectData?.partC || { questions: "", skip: "", mark: "" },
    m1partA: "",
    m1partB: "",
    m1partC: "",
  });

  const { data: questionPaper = {}, refetch } = useQuery(["getFieldDropDowns", addNew?.subjectId?._id], getFieldDropDowns(addNew?.subjectId?._id, val, collegeId));

  const [data, setData] = useState([]);

  const getData = async () => {
    const instance = await getAxiosTokenInstance();
    const data = await instance.get("/college/examProcess/getSubjects", {
      params: { collegeId },
    });
    setData(data?.data?.finalArray);
  };

  useEffect(() => {
    getData();
  }, []);
  let questionNumber = 1;

  const handleNestedChange = (part: string, key: string, valueObj: { name?: string | undefined; value: any }) => {
    const value = valueObj.value;
    setVal((currentVal: any) => ({
      ...currentVal,
      [part]: {
        ...currentVal[part],
        [key]: value,
      },
    }));
  };
  const handleNestedChangeModule = (part: string, valueObj: { name?: string | undefined; value: any }) => {
    const value = valueObj.value;
    setVal((currentVal: any) => ({
      ...currentVal,
      [part]: value,
    }));
  };

  useEffect(() => {
    const newState: ISubjectState = {
      partA: subjectData?.partA,
      partB: subjectData?.partB,
      partC: subjectData?.partC,
    };
    subjectData?.modules?.forEach((item: any) => {
      newState[`m${item.moduleId + 1}partA`] = item.partA;
      newState[`m${item.moduleId + 1}partB`] = item.partB;
      newState[`m${item.moduleId + 1}partC`] = item.partC;
      newState[`module${item.moduleId + 1}`] = item.points;
    });
    setVal(newState);
  }, [subjectData]);

  const handleCheckboxChange = (module: string, item: string, mode: boolean) => {
    const moduleKey = "module" + (module + 1);
    const currentModuleItems = val[moduleKey] || [];
    let updatedModuleItems;
    if (mode) {
      updatedModuleItems = currentModuleItems.filter((i: string) => i !== item);
    } else {
      if (!currentModuleItems.includes(item)) {
        updatedModuleItems = [...currentModuleItems, item];
      } else {
        updatedModuleItems = currentModuleItems;
      }
    }
    setVal({
      ...val,
      [moduleKey]: updatedModuleItems,
    });
  };

  const handleCheckboxChangeAll = (module: string, mode: boolean, points: any[]) => {
    const moduleKey = `module${parseInt(module) + 1}`;
    let updatedModuleItems: any[];
    if (!mode) {
      updatedModuleItems = [];
    } else {
      updatedModuleItems = points ? points : [];
    }
    setVal((prevVal: any) => ({
      ...prevVal,
      [moduleKey]: updatedModuleItems,
    }));
  };

  return (
    <>
      <Main title="Question Paper Creator" width="60vw" height="auto">
        <Header>
          <div className="flex">
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div className="flex flex-col w-full">
            <Input width="100%" fieldName="Select Branch" returnKey="branch" state={addNew} setState={setAddNew} options={["M.Com", "M.Sc", "M.A."]} type="drop" />
            <Input width="100%" fieldName="Select Model" returnKey="semester" state={addNew} setState={setAddNew} options={["Second Semester", "Fourth Semester"]} type="drop" />
            <Input width="100%" fieldName="Select Model" returnKey="course" state={addNew} setState={setAddNew} options={["Microbiology", "Malayalam Language and Literature", "Finance and Taxation", "English Language and Literature", "Physics"]} type="drop" />
            <Input width="100%" fieldName="Choose Subject" returnKey="subjectId" optionDisplay="name" valueShowKey="name" state={addNew} setState={setAddNew} options={data} type="drop" />
            <Input width="100%" fieldName="Select Model" returnKey="term" state={addNew} setState={setAddNew} options={["Second Internal", "First Internal", "Final Examination"]} type="drop" />

            <h1 className="text-lg font-bold text-gray-800 self-center">Overall Blue Print</h1>

            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-6">
              {["A", "B", "C"].map((part) => (
                <div key={part} className="flex column">
                  <Input width="100%" fieldName={`Part ${part} Total Questions`} state={val[`part${part}`]?.questions} onChange={(value) => handleNestedChange(`part${part}`, "questions", value)} type="number" />
                  <Input width="100%" fieldName={`Part ${part} Each Q Mark`} state={val[`part${part}`]?.mark} onChange={(value) => handleNestedChange(`part${part}`, "mark", value)} type="number" />
                  <Input width="100%" fieldName={`Part ${part} Skip`} state={val[`part${part}`]?.skip} onChange={(value) => handleNestedChange(`part${part}`, "skip", value)} type="number" />
                </div>
              ))}
            </div>
            <h1 className="text-lg font-bold text-gray-800 self-center">Module Wise</h1>
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-6">
              {subjectData?.modules?.map((part: any, ind: number) => (
                <>
                  <h1 className="text-lg font-bold text-gray-800 self-center">Module {part.moduleId + 1}</h1>
                  <div key={part} className="flex column">
                    <label key={ind} className="flex items-center mb-2 cursor-pointer">
                      <input type="checkbox" className="form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400" checked={val?.["module" + (part.moduleId + 1)] ? val["module" + (part.moduleId + 1)].length === part.points?.length : false} onChange={() => handleCheckboxChangeAll(part?.moduleId.toString(), !(val?.["module" + (part.moduleId + 1)]?.length === part?.points?.length), part?.points || [])} />
                    </label>
                    <Input width="100%" fieldName={`Part A`} state={val[`m${part.moduleId + 1}partA`]} onChange={(value) => handleNestedChangeModule(`m${part.moduleId + 1}partA`, value)} type="number" />
                    <Input width="100%" fieldName={`Part B`} state={val[`m${part.moduleId + 1}partB`]} onChange={(value) => handleNestedChangeModule(`m${part.moduleId + 1}partB`, value)} type="number" />
                    <Input width="100%" fieldName={`Part C`} state={val[`m${part.moduleId + 1}partC`]} onChange={(value) => handleNestedChangeModule(`m${part.moduleId + 1}partC`, value)} type="number" />
                  </div>

                  {part.points.map((item: any, index: any) => (
                    <label key={index} className="flex items-center mb-2 cursor-pointer">
                      <input type="checkbox" className="form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400" checked={val?.["module" + (ind + 1)]?.includes(item)} onChange={() => handleCheckboxChange(part.moduleId, item, val?.["module" + (ind + 1)]?.includes(item))} />
                      <span className="text-lg text-gray-800 ml-2">
                        {ind + 1}.{index + 1} - {item.slice(0, 170)}
                      </span>
                    </label>
                  ))}
                </>
              ))}
            </div>
            <Button
              type="save"
              onClick={() => {
                setShowForm(true);
              }}
            >
              Generate Question Paper
            </Button>
          </div>
        </Body>
      </Main>

      {showForm && (
        <Main width="auto" height="auto" title="Question Paper Generator">
          <Header>
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="fetch" onClick={refetch}>
              Get New Once
            </Button>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(() => setShowForm(false), 500);
              }}
            >
              Close
            </Button>
          </Header>
          <Body>
            <div id="printable" ref={ref} className="relative max-w-4xl mx-auto p-5 bg-white shadow-md rounded-lg print:bg-transparent">
              <style>
                {`
                  @media print {
                    body * {
                      visibility: hidden;
                    }
                    #printable, #printable * {
                      visibility: visible;
                    }
                    #printable {
                      position: absolute;
                      left: 0;
                      top: 0;
                      width: 100%;
                      height: 100%;
                    }
                    .print\\:bg-transparent {
                      background: none !important;
                    }
                  }
                  /* Simulated watermark for demonstration */
                  #printable {
                    background-size: 300px 300px;
                    background-image: 
                      linear-gradient(45deg, rgba(0,0,0,0.03) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.03) 75%, rgba(0,0,0,0.03)), 
                      linear-gradient(45deg, rgba(0,0,0,0.03) 25%, transparent 25%, transparent 75%, rgba(0,0,0,0.03) 75%, rgba(0,0,0,0.03));
                    background-position: 0 0, 150px 150px;
                  }
                `}
              </style>

              <div className="watermark absolute inset-0 pointer-events-none z-0 flex items-center justify-center font-bold text-4xl text-gray-200" style={{ writingMode: "vertical-rl" }}>
                {addNew.course}
              </div>

              <h5 className="text-sm  text-gray-800  flex flex-col items-end justify-end w-full"> Name: .....................................</h5>
              <h5 className="text-sm  text-gray-800  flex flex-col items-end justify-end w-full">Reg No: ...................................</h5>

              <div className="z-10 relative">
                <div className="text-center my-4">
                  <h1 className="text-3xl font-bold text-gray-800 mb-2">
                    {addNew.branch} Degree ({addNew.term}) Examination April 2024
                  </h1>
                  <div className="space-y-1">
                    <p className="text-xl font-semibold text-gray-800">{addNew.semester}</p>
                    <p className="text-xl font-semibold text-gray-800">{addNew.course}</p>
                    <p className="text-lg text-gray-600">Q.P. Code: {questionPaper?.questionPaperCode}</p>
                  </div>
                </div>

                <h2 className="text-xl font-bold text-gray-800  flex flex-col items-center justify-center w-full">
                  {questionPaper?.subjectCode}: {questionPaper?.name}
                </h2>
                {questionPaper?.parts?.map((part: { title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; info: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; questions: any[] }, index: React.Key | null | undefined) => (
                  <div key={index} className="mt-6">
                    <div className="flex flex-col items-center justify-center w-full">
                      <h3 className="text-sm font-semibold text-gray-800">{part.title}</h3>
                      <p className="text-gray-600 font-semibold mb-2">{part.info}</p>
                    </div>
                    <ul className=" ">
                      {part.questions.map((question, qIndex) => (
                        <p key={qIndex} className="text-gray-600 m-0 p-0 text-lg">
                          {question.type === "Theory" ? (
                            <div>
                              {questionNumber++}.{question?.question}
                            </div>
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: question?.question }}></div>
                          )}
                        </p>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default QuestionPaper;
