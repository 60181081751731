import React, { useContext, useEffect, useState } from "react";
import classNames from "./detailView.module.scss";
import { useProfileData } from "../../../../queryHooks";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { getAdmissionValidator, getIsCall } from "../../ApiServices";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";


function CallToken({ uid, isStudent, keyData, stateData, handleStartTimer, setStateData, itemsDataData, setItemsDataData }) {
  const {
    data: userDetail,
    status,
  } = useProfileData(uid, !isStudent);

  const [loading, setLoading] = useState(false)

  const { collegeId, user } = useContext(FirebaseContext);


  const { data, refetch } = useQuery(['getAdmissionValidator', collegeId, uid], getIsCall(collegeId, uid));


  const [state, setState] = useState({ type: true })
  const [isRunning, setIsRunning] = useState(false);
  const [currentTime, setCurrentTime] = useState(30);
  const [message, setMessage] = useState(false);

  const handleProceed = async () => {
    setIsRunning(true)
    setState({ ...state, callingNow: true })
    try {
      let data = {
        _id: userDetail?._id,
        type: state?.type,
        key: keyData
      }
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/admissionProcess/callStudentWithNotification", data);
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };

  const handleProceed2 = async () => {
    try {
      let data = {
        _id: userDetail?._id,
        type: false,
        key: keyData
      }
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/admissionProcess/callStudent", data);
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };





  useEffect(() => {
    let interval = null;
    if (isRunning) {
      interval = setInterval(() => {
        setCurrentTime(currentTime - 1);
      }, 1000);
      if (currentTime < 28) {
        if (!data.verification[keyData + 'Call']) {
          setState({ ...state, callingNow: false })
          setMessage(data.verification[keyData + 'CallMessage'])
          if (currentTime > 1) {
            setCurrentTime(1)
          }
          if (currentTime === 0) {
            setIsRunning(false)
            if (data.verification[keyData + 'CallMessage'] === 'Comming') {
              handleStartTimer();
              keyData === 'fees' && setItemsDataData({ ...itemsDataData, feesBilling: true, studentId: data._id })
              keyData === 'pta' && setItemsDataData({ ...itemsDataData, feesBilling: true, keyDataIsStaff: true, studentId: data._id })
              setStateData({ ...stateData, callToken: false, ...['fees', 'office'].includes(keyData) && { grand: true }, ...!(['pta', 'fees'].includes(keyData)) && { viewMode: true } })

            } else {
              setMessage(false)
              setCurrentTime(30)
              setStateData({ ...stateData, callToken: false, })
            }
          }
        }
      }

      refetch()
    }
    if (currentTime === 0) {
      setMessage(false)
      setCurrentTime(30)
      handleProceed2()
      setIsRunning(false)

    }
    return () => clearInterval(interval);
  }, [isRunning, currentTime, data]);


  const Button = ({ color, text, onClick }) => {
    return (
      <button style={{ width: 180, margin: 20, borderColor: color, fontSize: 20, color: 'white', fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
        {text}
      </button>
    );
  };
  if (!uid) {
    return (
      <div className={classNames.detailView}>
        <div className={classNames.selectmessage}>Select A User First</div>
      </div>
    );
  }
  const handleCancel = () => {


    handleProceed2()
    setStateData({ ...stateData, callToken: false, [data?._id + 'call']: false, [data?._id + 'timeStamp']: false })
  };
  const handleCancel2 = () => {
    handleProceed2()
    handleStartTimer();
    keyData === 'fees' && setItemsDataData({ ...itemsDataData, feesBilling: true, studentId: data._id })
    keyData === 'pta' && setItemsDataData({ ...itemsDataData, feesBilling: true, keyDataIsStaff: true, studentId: data._id })
    setStateData({ ...stateData, callToken: false, ...['fees', 'office'].includes(keyData) && { grand: true }, ...!(['pta', 'fees'].includes(keyData)) && { viewMode: true } })


  };

  let memberData = userDetail;




  console.log({ loading });



  return (
    <div className={classNames.container}>
      <div className={classNames.modal} style={{ overflow: 'scroll', margin: 20 }}>
        <div className={classNames.detailView}>
          <>
            <div style={{ overflow: 'scroll', margin: 20 }}>
              <div className={classNames.dataWraper} style={{ overflow: 'scroll', margin: 5, textAlign: 'center', width: '100%' }}>
                <>

                  <div className={classNames.typoContainer}>
                    <h5 className={classNames.userName}>{memberData?.name}</h5>
                    {['office', 'fees']?.includes(keyData) && <h5 className={classNames.userName}> Admision No: {memberData?.admisionNo}</h5>}
                    {['principa']?.includes(keyData) && <h5 className={classNames.userName}> Admision No: {memberData?.admisionNo}</h5>}
                  </div>

                  <view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
                    {isRunning ?
                      <view style={{ flexDirection: 'row', backgroundColor: message === 'Comming' ? 'green' : 'red', borderRadius: 200, alignItems: 'center', display: 'flex', margin: 20, ...state.callingNow && { backgroundColor: 'navy' } }}>
                        {message ?
                          <>
                            <h5 style={{ fontSize: 50, margin: 50, color: 'white' }}> {message === 'Comming' ? 'Accepted' : 'Rejected'}... {currentTime}</h5>
                          </> :
                          <h5 style={{ fontSize: 50, margin: 50, color: 'white' }}> Calling ... {currentTime}</h5>}
                      </view>
                      :
                      <>
                        <view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
                          <Button color="#FF4136" text="Cancel" onClick={handleCancel} />
                          <Button color="#2ECC40" text="Call" onClick={handleProceed} />

                        </view>
                      </>
                    }
                    <Button color="#2ECC40" text="Continue" onClick={handleCancel2} />

                  </view>
                  <img src={userDetail?.photoURL} style={{
                    border: '3px solid navy',
                    borderRadius: '10%',
                    width: '300px',
                    width: '300px',
                    margin: 10
                  }} />
                </>
              </div>

            </div>
          </>
        </div>
      </div>
    </div >
  );
}

export default CallToken;
