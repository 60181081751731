import React, { useContext, useState } from "react";
import "./style.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAxiosInstance } from "../../../../utils/axiosInstance";
import CommonListItem from "./components/CommonListItem";
import DepClassListScreen from "./Tabs/DepClassListScreen/DepClassListScreen";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { useQuery } from "react-query";

const getAllDepartments = (selectedCollege) => async () => {
  const instance = getAxiosInstance();
  try {
    const resp = await instance.get("/college/classes/getDepartment", {
      params: { collegeId: selectedCollege },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data?.departmentList;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const DepartmentMaster = ({ onClose }) => {
  const { collegeId: selectedCollege } = useContext(FirebaseContext);
  const [data, setData] = useState(false);

  const { data: departmentList } = useQuery(["getAllDepartments", selectedCollege], getAllDepartments(selectedCollege));

  console.log(departmentList);

  return (
    <Main title="Department Management" width="90vw" height="90vh">
      <Header>
        <div className="flex"></div>
        <div className="flex">
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      <Body>
        {data ? (
          <DepClassListScreen departmentId={data.departmentId} departmentName={data?.departmentName} onClose={() => setData(false)} />
        ) : (
          <div className="flex flex-col p-3">
            <ul className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-9 gap-4">
              {departmentList?.map((item) => (
                <li key={item._id} className="flex">
                  <CommonListItem
                    name={item?.departmentName}
                    userName={item?.hodName}
                    _id={item._id}
                    userPhoto={item?.hodPhoto}
                    progress={item?.progress}
                    isHod={true}
                    onPress={() => {
                      setData({
                        departmentId: item.departmentId,
                        departmentName: item.departmentName,
                      });
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </Body>
    </Main>
  );
};

export default DepartmentMaster;
