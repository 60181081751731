import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import Gallery from '../../components/Website/gallery';
import FooterBar from '../../components/Website/footerBar';

const GalleryPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Gallery" />
        <Gallery />
        <FooterBar />
    </div>
}

export default GalleryPage

