import React, { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Button from "../../NecttosComp/Button/Button";
import { Body, Footer, Header, Main } from "../../NecttosComp/Layout/Layout";
import { useProfileData } from "../../queryHooks";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { message } from "antd";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";

interface Props {
  onClose: () => void;
  studentId?: string;
}

function PhotoChanger({ onClose, studentId }: Props) {
  const { data: profileData, refetch } = useProfileData(studentId);
  const [imagePreview, setImagePreview] = useState<string | undefined | null>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const storage = getStorage();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;

    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreview(undefined);
    }
  };
  const handleDownload = () => {
    if (profileData?.photoURL) {
      const link = document.createElement("a");
      link.href = profileData?.photoURL;
      link.download = `ProfilePhoto-${studentId}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      message.error("No image available for download.");
    }
  };

  useEffect(() => {
    if (profileData?.pendingData?.photoURL) {
      setImagePreview(profileData?.pendingData?.photoURL);
    }
  }, [profileData]);

  async function signupCollege() {
    if (!selectedFile) {
      message.error("No file selected.");
      return;
    }

    const fileBlob = selectedFile instanceof Blob ? selectedFile : new Blob([selectedFile]);

    try {
      const logoImagesRef = ref(storage, `profilePhoto/${studentId}/photoURL.png`);
      const snapshot = await uploadBytes(logoImagesRef, fileBlob, {
        contentType: "image/png",
      });
      const url = await getDownloadURL(snapshot.ref);
      const instance = await getAxiosTokenInstance();
      const response = await instance.post("/college/profile/updateAvatarNew", {
        photoURL: url,
        userId: studentId,
      });
      if (response.data.statusCode !== 200) {
        throw new Error(response.data.message);
      }
      message.success("Photo uploaded successfully.");
      refetch();
    } catch (error) {
      console.error("Error on uploading", error);
      message.error("API Error");
    }
  }

  return (
    <>
      <Main title="Personal Information" height="80vh" width={"80vw"}>
        <Header width="100%" height="6%" title="Profile">
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="download" onClick={handleDownload} children="Download" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body width="100%" height="85%">
          <div className="grid grid-flow-col gap-6">
            <div className="px-3 py-2 w-full md:w-[28vw]">
              <p className="text-xl font-semibold text-gray-800 mb-3">Current Photo:</p>
              <img src={profileData?.photoURL || "default-placeholder.jpg"} alt="Current Profile" className="h-[400px] rounded-md" />
            </div>
            <div className="px-3 py-2 w-full md:w-[28vw] overflow-auto">
              <p className="text-xl font-semibold text-gray-800 mb-3">New Photo:</p>
              {imagePreview ? (
                <img src={imagePreview} alt="Preview" className="h-[400px] rounded-md" />
              ) : (
                <div className="flex items-center justify-center h-[400px] rounded-md bg-gray-100">
                  <p className="text-gray-500">No Image Selected</p>
                </div>
              )}
              <input type="file" onChange={handleFileUpload} className="mt-3" />
              <Button width="100%" onClick={signupCollege} type="save" children="Upload Photo" />
            </div>
          </div>
        </Body>
        <Footer width="100%" />
      </Main>
    </>
  );
}

export default PhotoChanger;
