import React, { useContext, useRef, useState } from 'react';
import { AcademicYears } from '../../../pages/college/ApiServices';
import Button from '../../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';
import { naacGET } from '../../../NecttosComp/APICaller/APICaller';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../context/FirebaseContext';

interface Props {
	onClose: () => void;
	studentId?: string | null | undefined;
}
type ValueItem = {
	members: any;
	department: any;
	mainData: any[];
	departmentWiseList: any[];
};

type values = ValueItem[];

function C_3_3_2({ onClose, studentId }: Props) {
	const [academicYear, setAcademicYear] = useState('2022-23');
	const [mode, setMode] = useState<any>(false);
	const [copySuccess, setCopySuccess] = useState('');
	const { collegeId } = useContext(FirebaseContext);
	function isValuesArray(data: unknown): data is values {
		return Array.isArray(data);
	}

	const rawData = useQuery(['criteria3_3_2', academicYear], naacGET({ endPoint: 'criteria3_3_2', params: { collegeId, academicYear } }));

	const values = isValuesArray(rawData?.data?.mainData) ? rawData?.data?.mainData : [];
	const departmentsData = isValuesArray(rawData?.data?.departmentWiseList) ? rawData?.data?.departmentWiseList : [];

	const componentRef = useRef<HTMLTableElement>(null);

	const formatDataForCopy = () => {
		return departmentsData
			?.map((department: { members: any[]; department: any }) => {
				const memberNames = department.members.map((member) => `- ${member.name}`).join('\n');
				return `*${department.department}*:\n${memberNames}`;
			})
			.join('\n\n');
	};

	const copyToClipboard = () => {
		const formattedText = `
  *Guidance for NAAC Data Collection 3.3.2 [${academicYear}]*
  
  *Navigate to the Necttos Website*: 

     - Visit [www.necttos.com] and sign in with your credentials.

  
  *Accessing the Publication Section*: 

     - From the homepage, access the menu by clicking on *All Menu*.

     - Use the search function to locate the *Profile* section.

     - Then, select *Publications and Patents*.
  


     Here is the list of departments, alongside the number of positions and the total publications by their respective teachers:

  ${formatDataForCopy()}
  `;

		navigator.clipboard
			.writeText(formattedText)
			.then(() => setCopySuccess('Data copied successfully! Now you can paste it into WhatsApp or any other application.'))
			.catch(() => setCopySuccess('Failed to copy the data. Please try manually.'));
	};

	return (
		<>
			<Main title={'3.3.2 - ' + academicYear} height='90vh' width={'90vw'}>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center z-50'>
						<AcademicYears setAcademicYear={setAcademicYear} />
						<Button
							type='fetch'
							onClick={() => {
								setMode(true);
							}}
							children='Not Filled Yet'
						/>
					</div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' fileName={'3.3.2 - ' + academicYear} tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>

				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div className='w-full '>
								<Table width='100%' ref={componentRef} id={'profilePDF'} innerWidth={['5%', '8%', '15%', '25%']}>
									<Thead>
										<Tr>
											<Th position={0} children='S.N' />
											<Th position={1} children='Year' />
											<Th position={2} children='Title of paper' />
											<Th position={2} children='Name of the auther/s' />
											<Th position={2} children='department of the teacher' />
											<Th position={1} children='name of the journal' />
											<Th position={2} children='year of publication' />
											<Th position={1} children='ISSN number' />
											<Th position={1} children='Link to the recognition in UGC enlistment of the Journal' />
											<Th position={3} children='First Page' />
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{values?.map((x: any, index: any) => (
											<Tr>
												<Td position={0} index={index}>
													{index + 1}
												</Td>
												<Td position={1} index={index}>
													{academicYear}
												</Td>
												<Td position={2} index={index}>
													{x?.articleTitle}
												</Td>
												<Td position={1} index={index}>
													{x?.authorName}
												</Td>
												<Td position={2} index={index}>
													{x?.deptName}
												</Td>
												<Td position={1} index={index}>
													{x?.journalTitle}
												</Td>

												<Td position={1} index={index}>
													{x?.dateOfPublication}
												</Td>
												<Td position={1} index={index}>
													{x?.issnNumber}
												</Td>
												<Td position={1} index={index}>
													{x?.link}
												</Td>

												<Td position={3} index={index}>
													{x?.documentURL}
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>
						</div>
					</div>
				</Body>
				<Footer width='100%' />
			</Main>
			{mode && (
				<Main>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'>
							<Button type='fetch' onClick={copyToClipboard}>
								Copy Data to Paste into WhatsApp
							</Button>
							{copySuccess && <p className='text-green-900 text-2xl pt-4'>{copySuccess}</p>}
						</div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body>
						<div className='grid grid-flow-col p-2 bg-slate-300 text-lg'>
							<div>
								<div>
									<h2 className='font-bold text-xl mb-4'>Guidance for NAAC Data Collection 3.3.2 [{academicYear}]</h2>
									<ol className='list-decimal ml-4'>
										<li>
											<strong>Navigate to the Necttos Website</strong>: Visit{' '}
											<a href='https://www.necttos.com' className='text-blue-500 underline'>
												www.necttos.com
											</a>
											and sign in with your credentials.
										</li>

										<li>
											<strong>Accessing the Members Section</strong>: From the homepage, access the menu by clicking on <em>All Menu</em>. Use the search function to locate the <em>Profile</em> section, and then select <em>Publications and Patents</em>.
										</li>
										<li>
											Here is the list of departments, alongside the number of positions and the total publications by their respective teachers::
											<div className='mt-2 ml-6'>
												{departmentsData?.map((department, index) => (
													<div key={index} className='mb-2'>
														<h3 className='font-semibold'>{department.department}</h3>
														<ul className='list-disc ml-6'>
															{department.members.map((member: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined }, idx: React.Key | null | undefined) => (
																<li key={idx}>{member.name}</li>
															))}
														</ul>
													</div>
												))}
											</div>
										</li>
									</ol>
									<p className='mt-4'>Follow these steps carefully to ensure accurate data collection for NAAC compliance.</p>
								</div>
							</div>
						</div>
					</Body>
				</Main>
			)}
		</>
	);
}

export default C_3_3_2;
