import React, { Fragment } from "react";
import classNames from "./masterTimetable.module.scss";

function abbrevateName(name) {
  let arr = name?.split(" ") || [];
  let ns = "";
  arr?.forEach((str) => {
    ns += str.charAt(0);
  });
  arr?.forEach((str) => {
    ns += str.charAt(1);
  });
  return ns.slice(0, 2).toUpperCase();
}

function ClassRow({ setModalOpen, list, classes, day }) {
  // const blankArray = Array(maxPeriods - classRow?.days?.Monday?.length).fill(
  //   ""
  // );
  const checkIfAnotherPeriod = (period) => {
    if (period.electiveId) {
      return period?.electiveSubjects?.some(x => x.hasAnotherPeriod);
    } else {
      return period?.hasAnotherPeriod;
    }
  }
  const getPeriodCount = (period) => {
    if (period?.electiveId) {
      return period?.electiveSubjects?.reduce((res, x) => res + (x.periodCount || 0), 0);
    } else {
      return period.periodCount;
    }
  }
  return (
    <tr className={classNames.classRow}>
      <th className={classNames.classHead}>
        <div className={classNames.className}>{day}</div>
      </th>

      {classes?.map((_clss, j) => {
        let periods = list ? list[j]?.days[day] : [''];
        return (
          <Fragment >


            {periods?.map((period, i) => (
              <td
                className={classNames.classWrap}
                onClick={() => setModalOpen({ day, period: i, classRow: list[j] })}
              >
                <div className={[classNames.class]} style={period?.electiveId && { backgroundColor: '#0e6f82' }}>
                  {
                    checkIfAnotherPeriod(period) && (
                      <div style={{ display: 'flex', width: '26px', height: '26px', borderRadius: '13px', backgroundColor: 'red', position: 'absolute', marginRight: '-50px', marginTop: '-50px', alignItems: 'center', justifyContent: 'center', fontSize: 12 }}>
                        {getPeriodCount(period)}
                      </div>
                    )
                  }
                  {period?.electiveId ? 'EL' : period.shortName || abbrevateName(period?.subjectDetails?.name) || (
                    <span>NC</span>
                  )}
                </div>
              </td>
            ))}

            {/* {blankArray?.map(() => (
              <td className={classNames.classWrap} />
            ))} */}
            <td></td>
          </Fragment>
        );
      })}
    </tr>
  );
}

export default ClassRow;


export const ClassRow2 = () => {
  return (
    <div>

    </div>
  )

} 