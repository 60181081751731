import React, { useEffect } from "react";
import message from "antd/lib/message";
import classNames from "./collegeList.module.scss";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";

function CollegeList() {
  const getListData = async ({ queryKey }) => {
    const instance = await getAxiosTokenInstance();
    let resp;
    try {
      resp = await instance.get("/register/forAproval");

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.message || error.message || "API Error"
      );
    }

    return resp.data;
  };

  const { data, status, error, refetch } = useQuery("collegeList", getListData);
  useEffect(() => {
    status === "error" && message.error(error.message);
  }, [error, status]);

  function upsdateCollegeStatus(status, clgId) {
    message.loading(
      {
        content: status ? "Approving College..." : "Rejecting College...",
        key: "status",
      },
      0
    );
    getAxiosTokenInstance().then((instance) => {
      instance
        .post("/register/approveCollege", {
          collegeId: clgId,
          status: status ? "approve" : "reject",
        })
        .then(({ data }) => {
          message.success({
            content: status ? "Approved" : "Rejected",
            key: "status",
          });
          refetch();
        })
        .catch((error) => {
          message.error({
            content:
              error?.response?.data?.message || error?.message || "API Error",
            key: "status",
          });
        });
    });
  }
  return (
    <div className={classNames.tableWrap}>
      <table className={classNames.userTable}>
        <thead>
          <tr>
            <th>#</th>
            <th>Name of the College</th>
            <th>Logo</th>
            <th>Place</th>
            <th>District</th>
            <th>Mobile Number</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.pendingColleges?.map((college, i) => (
            <tr key={college.collegeId}>
              <th>{i + 1}</th>
              <th>{college.collegeName}</th>
              <td>
                <div className={classNames.logo}>
                  <img src={college.logo} alt="" />
                </div>
              </td>
              <td>{college.address}</td>
              <td>{college.district}</td>
              <td>{college.phoneNumber}</td>
              <td>{college.status}</td>
              <td>
                <div className={classNames.btns}>
                  {college.status === "pending" && (
                    <div
                      className={classNames.btnVerify}
                      onClick={() =>
                        upsdateCollegeStatus(true, college.collegeId)
                      }
                    >
                      Verify
                    </div>
                  )}
                  <div
                    className={classNames.btnReject}
                    onClick={() =>
                      upsdateCollegeStatus(false, college.collegeId)
                    }
                  >
                    Reject
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CollegeList;
