import React, { useEffect, useState } from "react";
import { EditDetails, getDropdownData } from "../../pages/college/ApiServices";
import Button from "../../NecttosComp/Button/Button";
import { Body, Footer, Header, Main, widthChangeAnimation } from "../../NecttosComp/Layout/Layout";
import Input from "../../NecttosComp/Input/Input";
import { useProfileData } from "../../queryHooks";
import { ProgressBar, calculateProfilepPercentage } from "../../NecttosComp/ProgressBar/ProgressBar";
import Loader from "../../NecttosComp/Loader/Loader";
import { ProfileDataDisplayKeyVlaue } from "../../FunctionalExport/Formate";

interface Props {
  onClose: () => void;
  studentId?: string | null | undefined;
}

function BankDetails({ onClose, studentId }: Props) {
  const [dropDowns, setDropDowns] = useState<any[]>([{}]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: profileData, refetch } = useProfileData();

  const [values, setValues] = useState({});

  useEffect(() => {
    setValues({
      beneficiary: profileData?.pendingData?.bankDetails?.beneficiary || profileData?.bankDetails?.beneficiary,
      ifcs: profileData?.pendingData?.bankDetails?.ifcs || profileData?.bankDetails?.ifcs,
      panCard: profileData?.pendingData?.bankDetails?.panCard || profileData?.bankDetails?.panCard,
      accountNo: profileData?.pendingData?.bankDetails?.accountNo || profileData?.bankDetails?.accountNo,
    });
  }, [profileData]);

  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
    };
    getDropDowns();
  }, []);

  const placeHoldValues = {
    beneficiary: " Beneficiary",
    ifcs: "IFCS",
    panCard: "Pancard",
    accountNo: "Acc. No.",
  };

  return (
    <>
      <Main title="Bank Information" height="90vh" width={"60vw"}>
        <Header width="100%" height="6%" title="Profile">
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="print" children="Print" />
            <Button type="pdf" pdfId="accounts" children="PDF" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body width="100%" height="85%">
          <div className="grid grid-flow-col gap-6">
            <div className="px-3 py-2 w-[28vw]  ">
              <ProgressBar title="Verified Data" progress={parseInt(calculateProfilepPercentage(profileData?.bankDetails, placeHoldValues)?.percentage) || 0} />

              <ProfileDataDisplayKeyVlaue temp={profileData?.bankDetails} placeHoldValues={placeHoldValues} title="Verified Data" />
              {!isEditing ? (
                <Button
                  onClick={async () => {
                    widthChangeAnimation("90vw");
                    setIsEditing(!isEditing);
                  }}
                  type="update"
                >
                  Edit
                </Button>
              ) : null}
            </div>
            <div>
              <ProgressBar title="are Updated" progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
              <ProfileDataDisplayKeyVlaue temp={profileData?.pendingData?.bankDetails} placeHoldValues={placeHoldValues} title="Updated data" needVerification={true} />
            </div>

            {isEditing && (
              <div>
                <h5 className="text-blue-900 text-[1.6vw] font-bold">You can edit your data here !</h5>
                <div id="scrollBarEnable" className="px-3 py-2 w-[28vw] z-50 overflow-auto   max-h-[65vh]">
                  <Input width="98%" fieldName="Beneficiary" returnKey="beneficiary" setState={setValues} state={values} type="text" />
                  <Input width="98%" fieldName="IFCS" returnKey="ifcs" setState={setValues} state={values} type="text" />
                  <Input width="98%" fieldName="Pancard" returnKey="panCard" setState={setValues} state={values} type="text" />
                  <Input width="98%" fieldName="Account No." returnKey="accountNo" setState={setValues} state={values} type="text" />
                </div>

                <ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
                <Button
                  width="100%"
                  onClick={async () => {
                    setIsLoading(true);
                    await widthChangeAnimation("60vw");
                    await EditDetails({ bankDetails: values });
                    await refetch();
                    setIsEditing(!isEditing);
                    setIsLoading(false);
                  }}
                  type="save"
                  children="Save"
                />
              </div>
            )}
          </div>
        </Body>
        <Footer width="100%" />
      </Main>
      {isLoading && <Loader />}
    </>
  );
}

export default BankDetails;
