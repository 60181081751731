import React, { useContext, useEffect, useRef, useState } from 'react';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { useQuery } from 'react-query';
import Input from '../../NecttosComp/Input/Input';
import { getAllMinor, getRealTimeRankingFYUGP } from '../../pages/college/ApiServices';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import LoadingAnimation from '../../components/LoadingAnimation';
import { getCollegeDetails } from '../Form/Register';
import BasicCertificateVerification from '../Admission/BasicCertificateVerification';
import BasicCertificateVerificationPG from '../Admission/BasicCertificateVerificationPG';
import ApplicationView from '../Admission/ApplicationView';

const getCollegeDetailsee = async ({ queryKey }: any) => {
	const [_key, collegeId] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getCollegeDetails', {
			params: { collegeId },
		});

		if (data?.statusCode === 200) {
			return data.collegeData;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

function RealTimeRanking({ onClose }: { onClose: () => void }) {
	const { collegeId, user } = useContext(FirebaseContext);
	const [addNew, setAddNew] = useState<any>({});
	const [printable, setPritable] = useState<boolean>(false);
	const [filtered, setFilter] = useState<number>();
	const [subjectId, setSelectClass] = useState('');
	const [catogory, setCatogory] = useState('');
	const [type, setType] = useState('');
	const [splitUps, setSplitUps] = useState<any>([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [table, setTable] = useState<any>([]);
	const ref = useRef<any>(null);

	const { data: dataAll = [], refetch, isLoading } = useQuery(['getRealTimeRankingFYUGP', subjectId, catogory, type], getRealTimeRankingFYUGP(collegeId, subjectId, catogory, type));

	const { data: subjectList = [] } = useQuery('getAllMinor', getAllMinor(collegeId));

	const tableData = dataAll?.list || [];
	const options = dataAll?.options || [];
	const subjectname = dataAll?.subjectname || [];

	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
	const { data: classesx } = useQuery([`collegeDataddd`, collegeId], getCollegeDetails);

	useEffect(() => {
		let data = [...tableData];
		if (filtered !== undefined) {
			data = data.filter((item) => item.priorityOrder === filtered);
		}
		if (searchTerm) {
			data = data.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
		}
		setTable(data);
	}, [tableData, filtered, searchTerm]);

	const { tableItems, requestSort, getIcon } = useSortableData(table);

	const addNewForm = async () => {
		const instance = await getAxiosTokenInstance();
		try {
			const resp = await instance.post('/college/admissionProcess/addReservation', {
				studentId: addNew?.studentId,
				collegeId,
				details: addNew?.detail,
				classId: subjectId,
				userId: user?.uid,
				boolType: addNew?.boolType,
			});
			if (resp?.data?.statusCode === 200) {
				refetch();
				closeAnimation();
				setTimeout(() => setAddNew({ ...addNew, showReservation: false }), 500);
			} else {
				refetch();
			}
		} catch (error) {
			refetch();
		}
	};

	useEffect(() => {
		if (subjectId) {
			const classData = subjectList?.find((classItem: any) => classItem?._id === subjectId);

			if (classData?.splitup) {
				const filteredTitles = classData.splitup.filter((x: any) => parseInt(x.inTake, 10) > 0).map((x: any) => x.title);
				console.log(filteredTitles);

				setSplitUps(filteredTitles);
			}
		}
	}, [subjectId, subjectList]);

	const [className, setClassName] = useState<any>('');

	useEffect(() => {
		if (subjectId && subjectList) {
			let classNam = subjectList?.find((x: any) => x._id === subjectId)?.name || '';
			setClassName(classNam);
		} else {
			setClassName('');
		}
	}, [subjectId, classesx]);

	return (
		<Main title='Course-Wise Rank List' height='100%' width='100%'>
			<Header>
				<div className='flex'>
					<Input fieldName='Filter by Catogory' state={type} setState={setType} options={['DSC_B', 'DSC_C', 'MDC']} type='drop' width='10vw' />
					<Input fieldName='Filter by Course' state={subjectId} setState={setSelectClass} didntShowKey optionDisplay='subjectName' optionKey='_id' options={subjectList.filter((x: any) => x.type === type)} type='drop' width='35vw' />
					<Input fieldName='Filter by Catogory' state={catogory} setState={setCatogory} options={['SC', 'ST', 'MU', 'MUSLIM', 'MGMT', 'MGT', 'MANAGEMENT', 'EWS', 'PWD', 'COMT', 'COMMUNITY', 'SP', 'SPORTS', 'CU', 'CULTURAL']} type='drop' width='15vw' />
					<Input fieldName='Search' state={searchTerm} setState={setSearchTerm} type='text' width='15vw' />

					<view className='h-[50px]  rounded-md p-3 m-2 px-2 bg-white'>
						<h5> Total Applications: {tableItems?.length}</h5>
					</view>
					{filtered && (
						<view onClick={() => setFilter(undefined)} className='h-[50px]  rounded-md p-3 m-2 px-2 bg-red-400'>
							<h5>Show All</h5>
						</view>
					)}
				</div>
				<div className='flex'>
					<Button type={printable ? 'search' : 'update'} onClick={() => setPritable(!printable)}>
						{printable ? 'Hide Printable' : 'Show Printape'}
					</Button>
					<Button type='print' fileName={className} tableRef={ref.current}>
						Print
					</Button>
					<Button type='excel' tableRef={ref.current}>
						Excel
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			{!printable && (
				<Thead>
					<Tr>
						<Th position={0}>S.N.</Th>
						<Th position={1} onClick={() => requestSort('applicationNo')} icon={getIcon('applicationNo')}>
							Name
						</Th>
						<>
							<Th position={0}>App.No.</Th>
							{!printable && (
								<>
									<Th position={2}>Mobile</Th>
									<Th position={3}>Veiw</Th>
								</>
							)}
							<Th position={0} onClick={() => requestSort('priorityOrder')} icon={getIcon('priorityOrder')}>
								Priority
							</Th>
							<Th position={2}>Quota</Th>
							{!printable && (
								<>
									<Th position={3} onClick={() => requestSort('isReserve')} icon={getIcon('isReserve')}>
										Reservation
									</Th>
									<Th position={3} onClick={() => requestSort('isReserve')} icon={getIcon('isReserve')}>
										Reservation By
									</Th>
								</>
							)}
						</>
						{!subjectId ? (
							<Th position={0}>Class Name</Th>
						) : (
							<Th position={0} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
								Ranking
							</Th>
						)}
						<Th position={0} onClick={() => requestSort('earnedMark')} icon={getIcon('earnedMark')}>
							Index Mark
						</Th>
						{!printable && (
							<>
								<Th position={3} onClick={() => requestSort('isCorrected')} icon={getIcon('isCorrected')}>
									Verification
								</Th>
								<Th position={3} onClick={() => requestSort('isCorrected')} icon={getIcon('isCorrected')}>
									Verification By
								</Th>
							</>
						)}
					</Tr>
				</Thead>
			)}
			<>
				{isLoading ? (
					<LoadingAnimation customWidth='250px' customHeight='250px' />
				) : (
					<Body>
						<div ref={ref} className='printable overflow-auto p-4 '>
							{printable ? (
								<table className='min-w-full table-auto'>
									<thead>
										<tr>
											<td className='p-1 bg-white' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
												<img src={collegeData?.dashBoardLink} alt='College Logo' className='h-[100px] w-auto  mx-auto' />
											</td>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td className='p-1 text-center text-black bg-white text-xl font-bold' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
												Course: {subjectname}
											</td>
										</tr>

										<tr>
											<td className='p-1 text-center text-black bg-white text-lg' style={{ width: '70%', fontFamily: 'Times New Roman' }}>
												For any queries, please contact department
											</td>
										</tr>
									</tbody>
								</table>
							) : (
								<div className='p-6 bg-gray-100 rounded-md shadow-md'>
									<div className='flex flex-wrap justify-center gap-3'>
										{options.map((option: any, index: number) => (
											<span onClick={() => setFilter(index + 1)} key={index} className={`px-4 py-2 text-sm text-gray-700 rounded ${filtered === index + 1 ? 'bg-green-300' : 'bg-purple-300'}`}>
												{option.name}: {option.value}
											</span>
										))}
									</div>
								</div>
							)}

							<Table width='100%' innerWidth={['4%', '10%', '10%', '8%']}>
								{printable && (
									<Thead>
										<Tr>
											<Th position={0}>S.N.</Th>
											<Th position={1} onClick={() => requestSort('applicationNo')} icon={getIcon('applicationNo')}>
												Name
											</Th>
											<>
												<Th position={0}>Adm.No.</Th>
												{!printable && (
													<>
														<Th position={2}>Mobile</Th>
														<Th position={3}>Veiw</Th>
													</>
												)}
												<Th position={0} onClick={() => requestSort('priorityOrder')} icon={getIcon('priorityOrder')}>
													Priority
												</Th>

												<Th position={2}>Quota</Th>
												{!printable && (
													<>
														<Th position={3} onClick={() => requestSort('isReserve')} icon={getIcon('isReserve')}>
															Reservation
														</Th>
														<Th position={3} onClick={() => requestSort('isReserve')} icon={getIcon('isReserve')}>
															Reservation By
														</Th>
													</>
												)}
											</>
											{!subjectId ? (
												<Th position={0}>Class Name</Th>
											) : (
												<Th position={0} onClick={() => requestSort('rank')} icon={getIcon('rank')}>
													Ranking
												</Th>
											)}
											<Th position={0} onClick={() => requestSort('earnedMark')} icon={getIcon('earnedMark')}>
												Index Mark
											</Th>

											{!printable && (
												<>
													<Th position={0} onClick={() => requestSort('earnedMark')} icon={getIcon('earnedMark')}>
														Religion
													</Th>
													<Th position={0} onClick={() => requestSort('earnedMark')} icon={getIcon('earnedMark')}>
														Caste
													</Th>
													<Th position={3} onClick={() => requestSort('isCorrected')} icon={getIcon('isCorrected')}>
														Verification
													</Th>
													<Th position={3} onClick={() => requestSort('isCorrected')} icon={getIcon('isCorrected')}>
														Verification By
													</Th>
												</>
											)}
										</Tr>
									</Thead>
								)}
								<Tbody height='auto'>
									{tableItems?.map((x: any, i: number) => (
										<Tr>
											<Td index={i} position={0}>
												{1 + i}
											</Td>
											<Td index={i} position={1}>
												{x?.name?.toUpperCase()}
											</Td>
											<>
												<Td index={i} position={0}>
													{x?.admisionNo}
												</Td>
												{!printable && (
													<>
														<Td index={i} position={2}>
															{x?.phoneNumber}
														</Td>
														<Td index={i} position={3}>
															<Button
																type='doc'
																onClick={() => {
																	setAddNew({
																		...addNew,
																		viewMode: true,
																		_id: x._id,
																		tyeUG: x.batchName === '2024-2026' ? 'PG' : 'UG',
																	});
																}}>
																ViewApp
															</Button>
														</Td>
													</>
												)}
												<Td index={i} position={0}>
													{x?.priorityOrder}
												</Td>
												<Td index={i} position={2}>
													{x?.appliedto}
												</Td>
												{!printable && (
													<>
														{x?.isReserve ? (
															<Td index={i} position={3}>
																<Button
																	type='close'
																	onClick={() => {
																		setAddNew({
																			...addNew,
																			showReservation: true,
																			studentId: x?._id,
																			boolType: false,
																		});
																	}}>
																	Remove
																</Button>
															</Td>
														) : (
															<Td index={i} position={3}>
																<Button
																	type='save'
																	onClick={() => {
																		setAddNew({
																			...addNew,
																			showReservation: true,
																			studentId: x?._id,
																			boolType: true,
																		});
																	}}>
																	Reserve
																</Button>
															</Td>
														)}
														<Td index={i} position={3}>
															{x?.reservedBy}
														</Td>
													</>
												)}
											</>
											{!subjectId ? (
												<Td index={i} position={0}>
													{x?.subjectId?.className}
												</Td>
											) : (
												<Td index={i} position={0}>
													{x?.rank}
												</Td>
											)}

											<Td index={i} position={0}>
												{String(x.earnedMark).slice(0, 7)}
											</Td>

											{!printable && (
												<>
													<Td index={i} position={0}>
														{x?.religion}
													</Td>
													<Td index={i} position={0}>
														{x?.caste}
													</Td>
													{x?.isCorrected ? (
														<Td index={i} position={3}>
															<Button
																type='save'
																onClick={() => {
																	setAddNew({
																		...addNew,
																		...(x.batchName === '2024-2026' ? { showBasicCertificatePg: true } : { showBasicCertificate: true }),
																		details: x,
																	});
																}}>
																Verified
															</Button>
														</Td>
													) : (
														<Td index={i} position={3}>
															<Button
																type='doc'
																onClick={() => {
																	setAddNew({
																		...addNew,
																		...(x.batchName === '2024-2026' ? { showBasicCertificatePg: true } : { showBasicCertificate: true }),
																		details: x,
																	});
																}}>
																Verify
															</Button>
														</Td>
													)}
													<Td index={i} position={3}>
														{x?.correctedBy}
													</Td>
												</>
											)}
										</Tr>
									))}
								</Tbody>
							</Table>
						</div>
						<BasicCertificateVerification showWindow={addNew?.showBasicCertificate} selClass={subjectId} refectd={refetch} date={addNew} setDate={setAddNew} />
						<BasicCertificateVerificationPG showWindow={addNew?.showBasicCertificatePg} selClass={subjectId} refectd={refetch} date={addNew} setDate={setAddNew} />
						{addNew.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}

						{addNew?.showReservation && (
							<Main width='40%' height='40%' title='Reserve Students'>
								<Header>
									<Button
										type='close'
										onClick={() => {
											closeAnimation();
											setTimeout(() => setAddNew({ ...addNew, showReservation: false }), 500);
										}}>
										Close
									</Button>
								</Header>
								<Body>
									<div className='flex flex-col w-full'>
										<Input width='100%' fieldName='Details' returnKey='detail' state={addNew} setState={setAddNew} type='text' />
										<Button
											type='save'
											onClick={() => {
												addNewForm();
											}}>
											Save
										</Button>
									</div>
								</Body>
							</Main>
						)}
					</Body>
				)}
			</>
		</Main>
	);
}

export default RealTimeRanking;
