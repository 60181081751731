import React, { useContext, useEffect, useRef, useState } from "react";
import "./C2_1.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { AcademicYears, naacFetchData } from "../../ApiServices";
import { Body, Footer, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../NecttosComp/Table/Table";
import { useQuery } from "react-query";
import { naacGET } from "../../../../NecttosComp/APICaller/APICaller";
import MissingDataFinder from "../../../../NecttosComp/MissingDataFinder/MissingDataFinder";

function C2_3({ onClose }) {
  const [loading, setLoading] = useState(false);
  const [academicYear, setAcademicYear] = useState("2022-23");
  const [analyze, setAnalyze] = useState();
  const ref = useRef(null);
  const { collegeId } = useContext(FirebaseContext);

  const { data: values = [] } = useQuery(["criteria2_3", academicYear], naacGET({ endPoint: "criteria2_3", params: { collegeId, academicYear } }));

  // useEffect(() => {
  //   naacFetchData({
  //     data: { collegeId, academicYear: "2022-23" },
  //     setLoading,
  //     setValues,
  //     url: "/college/NAAC/getNAAC_C2_3",
  //   }).catch((err) => {
  //     console.log(err);
  //   });
  // }, []);

  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]">
          <LoadingAnimation />
        </div>
      ) : null}

      <Main title="2.3 Number of outgoing/final year students during the year" width="80vw" height="80vh">
        <Header>
          <div className="flex">
            <AcademicYears setAcademicYear={setAcademicYear} />
          </div>
          <div className="flex">
            <Button type="update" onClick={() => setAnalyze(true)}>
              Analyze
            </Button>
            <Button pdfId="emp" type="pdf">
              PDF
            </Button>
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="overflow-auto h-[60vh] printable">
            <Table width="100%" innerWidth={["35%", "35%", "30%"]} id="emp">
              <Thead>
                <Tr>
                  <Th position={0}>Year of passing final year exam</Th>
                  <Th position={1}>Name of students</Th>
                  <Th position={2}>Enrollment number</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {values?.map((x, i) => (
                  <Tr>
                    <Td position={0}>{x?.yearOfEnrolment}</Td>
                    <Td position={1}>{x?.name}</Td>
                    <Td position={2}>{x?.studentEnrolmentNumber}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        <Footer />
      </Main>
      {analyze && (
        <MissingDataFinder
          data={values}
          onClose={() => setAnalyze(false)}
          values={[
            { importance: true, name: "yearOfEnrolment", title: "Year of Enrollment" },
            { importance: true, name: "name", title: "Name" },
            { importance: true, name: "studentEnrolmentNumber", title: "Student enrollment number" },
          ]}
        />
      )}
      {/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        <table className="table">
          <tr>
            <th className="w-1/4">Year of passing final year exam</th>
            <th className="w-1/4">Name of students</th>
            <th className="w-1/4">Enrollment number</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>2</th>
          </tr>
        </table>
      </div> */}
    </>
  );
}

export default C2_3;
