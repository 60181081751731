import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { message, Select } from 'antd';
import { getAxiosTokenInstance } from '../../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../../context/FirebaseContext';
import Scrollbars from 'react-custom-scrollbars';
import CustomInput from '../../../../../myComp/CustomInput';
import moment from 'moment';
import CustomDropDown from '../../../../../myComp/CustomDropDown';
import { async } from '@firebase/util';
import { Body, Header, Main } from '../../../../../NecttosComp/Layout/Layout';
import Button from '../../../../../NecttosComp/Button/Button';
const { Option } = Select;

export const EditDataBills = ({ item, itemData, onClose, setStateData, stateData, refetchDemo, title }) => {
	const [state, setState] = React.useState({
		...itemData,
		dueDate: moment(itemData.dueDate).format('YYYY-MM-DD'),
	});
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [titleAndType, setTitleAndType] = useState({ title: itemData?.title, type: itemData?.feeType });
	const [amount, setAmount] = useState(itemData?.amount);
	const [paid, setPaid] = useState(itemData?.paid);
	const [balance, setBalance] = useState(0);
	const [dueDate, setDueDate] = useState(moment(new Date(itemData.dueDate)).format('yyyy-MM-DD'));

	let type = ['Others', 'ExamFee', 'TuitionFee'];

	useEffect(() => {
		setBalance(parseInt(amount - paid));
	});

	const editRecieptNumber = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp;
			resp = await instance.post('/college/action/editBillNo', {
				collegeId,
				feeId: item.feeId,
				billNo: state.billNo,
			});
			if (resp.data.statusCode !== 200) {
				throw new Error(resp.data.message || 'API Error');
			} else {
				setState({});
				refetchDemo();
				setStateData({ ...stateData, openModal: false });
			}
		} catch (error) {
			message.error(error?.response?.data?.message || error?.message || 'API Error');
		} finally {
		}
	};

	const editDues = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp = await instance.post('/college/action/editDues', {
				collegeId,
				feeId: itemData._id,
				feeType: titleAndType.type,
				title: titleAndType.title,
				accountId: titleAndType.accountId,
				amount,
				paid,
				userId: stateData._id,
				balance,
				dueDate: new Date(dueDate),
			});
			window.alert('Succesfully updated');
		} catch (error) {
			window.alert('Something went Wrong');
		}
	};

	return (
		<Main title='Due Editor' height='80vh' width='40vw'>
			<Header>
				<Button onClick={onClose} type='close'>
					Cancel
				</Button>
			</Header>
			<Body>
				<div className={classNames.modal}>
					<div className={classNames.listContainer2}>
						<div ref={componentRef} style={{ overflow: 'scroll' }}>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<div className='flex w-full rounded-2xl'>
									<div className='flex flex-col items-center justify-center w-full'>
										<h5 className='text-5xl mt-0 mb-4'>{item?.billNo}</h5>

										<label className='font-semibold'>Type:</label>
										<Select showSearch className='w-4/5 mb-4' value={titleAndType.type} onChange={(e) => setTitleAndType({ type: e, title: titleAndType.title })}>
											{type.map((x) => (
												<Option key={x} value={x}>
													{x}
												</Option>
											))}
										</Select>

										<label className='font-semibold'>Title:</label>
										<Select showSearch className='w-4/5 mb-4' value={titleAndType.title} onChange={(e) => setTitleAndType({ title: e, type: titleAndType.type, accountId: title.find((x) => x.name === e)._id })}>
											{title?.map((x) => (
												<Option key={x.name} value={x.name}>
													{x.name}
												</Option>
											))}
										</Select>

										<label className='font-semibold'>Due:</label>
										<input type='text' className='input input-bordered w-4/5 mb-4' placeholder='Enter due amount' pattern='[0-9]*' value={amount} onChange={(e) => setAmount(e.target.value)} />

										<label className='font-semibold'>Paid:</label>
										<input type='text' className='input input-bordered w-4/5 mb-4' placeholder='Enter paid amount' pattern='[0-9]*' value={paid} onChange={(e) => setPaid(e.target.value)} />

										<label className='font-semibold'>Balance:</label>
										<input type='text' className='input input-bordered w-4/5 mb-4' placeholder='Balance' pattern='[0-9]*' value={balance} readOnly />

										<label className='font-semibold'>Due Date:</label>
										<input type='date' className='input input-bordered w-4/5 mb-4' value={dueDate} onChange={(e) => setDueDate(e.target.value)} />

										<div className='flex space-x-4'>
											<Button
												type='save'
												onClick={() => {
													editDues();
													editRecieptNumber();
												}}>
												Update
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Body>
		</Main>
	);
};
