import { Modal } from 'antd';

 const ReusableModal = ({ visible, modalText, handleCancel, handleOk ,title}) => {
    
    return (
        <>
    
            <Modal
                title={title}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>{modalText}</p>
            </Modal>
        </>
    );
};

export default ReusableModal