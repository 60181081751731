import React, { Fragment } from "react";
import classNames from "./masterTimetable.module.scss";

function DayPeriodComponent({ classes, periods }) {
  return (
    <>
      <tr className={classNames.daysRow}>
        <th className={classNames.corner}></th>
        {classes?.map((cls) => (
          <Fragment key={cls}>
            <th colSpan={periods}>{cls}</th>
            <th className={classNames.space}></th>
          </Fragment>
        ))}
      </tr>
      {/* <tr className={classNames.periodRow}>
        <th className={classNames.corner}></th>
        {days?.map((day) => (
          <Fragment key={day}>
            {Array(periods)
              .fill("")
              ?.map((_, i) => (
                <th className={classNames.periodWrap} key={i}>
                  <div className={classNames.period}>{i + 1}</div>
                </th>
              ))}
            <th className={classNames.space}></th>
          </Fragment>
        ))}
      </tr> */}
    </>
  );
}

export default DayPeriodComponent;
