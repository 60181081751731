import Navbar from "../../components/Website/navbar";
import FooterBar from "../../components/Website/footerBar";
const DownloadPage = (props) => {

    const downloadPlatform = (url) => {
        const link = document.createElement("a");
        link.download = "necttos-windows.exe"
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <div>
            <Navbar />
            {/* <PageHeader headertitle="Services" /> */}
            {/* <Services customclass="pd-top-112" /> */}
            <div>
                  <div className="sbtc-service-area pd-top-112">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-xl-6">
                            <div className="section-title text-center">
                              <h2 className="title">Downloads</h2>
                            </div>
                          </div>
                        </div>
                        <div className="row custom-gutters-16 justify-content-center">
                        
                            <div  className="col-lg-4 col-sm-6">
                              <div className="single-service-2 style-three">
                                <div className="thumb ml-0">
                                  <img src="/assets/img/platform/windows-icon.svg" alt="Windows" />
                                </div>
                                <h6>Windows</h6>
                                {/* <p> { item.content } </p> */}
                                <div  className="arrow" onClick={()=>downloadPlatform("https://github.com/NECTTOS/Necttos-Desktop-App-Releases/releases/download/v1.0.2/Necttos-Setup-1.0.2.exe")}>Download Now <i className="ti-arrow-right" /></div>
                              </div>
                            </div>
                            <div  className="col-lg-4 col-sm-6">
                              <div className="single-service-2 style-three">
                                <div className="thumb ml-0">
                                  <img src="/assets/img/platform/mac-icon.svg" alt="Mac" />
                                </div>
                                <h6>Mac</h6>
                                {/* <p> { item.content } </p> */}
                                <div  className="arrow" onClick={()=>downloadPlatform("https://github.com/NECTTOS/Necttos-Desktop-App-Releases/releases/download/v1.0.2/Necttos-1.0.2.dmg")} >Download Now <i className="ti-arrow-right" /></div>
                              </div>
                            </div>
                            <div  className="col-lg-4 col-sm-6">
                              <div className="single-service-2 style-three">
                                <div className="thumb ml-0">
                                  <img src="/assets/img/platform/linux-icon.svg" alt="Linux" />
                                </div>
                                <h6>Linux</h6>
                                {/* <p> { item.content } </p> */}
                                <div  className="arrow" onClick={()=>downloadPlatform("https://github.com/NECTTOS/Necttos-Desktop-App-Releases/releases/download/v1.0.2/latest-linux.yml")} >Download Now <i className="ti-arrow-right" /></div>
                              </div>
                            </div>
                            <div  className="col-lg-4 col-sm-6">
                              <div className="single-service-2 style-three">
                                <div className="thumb ml-0">
                                  <img src="/assets/img/platform/android-icon.svg" alt="Android" />
                                </div>
                                <h6>Linux</h6>
                                {/* <p> { item.content } </p> */}
                                <a  className="arrow" href="https://play.google.com/store/apps/details?id=com.necttos" target="_blank" >Download Now <i className="ti-arrow-right" /></a>
                              </div>
                            </div>


                        </div>
                      </div>
                    </div>
              </div>
            <FooterBar />
        </div>
    )
}
export default DownloadPage;