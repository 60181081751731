import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { useMutation } from "react-query";
import message from "antd/lib/message";
import styles from "./teacherSelect.module.scss";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import LoadingAnimation from "../LoadingAnimation";
import { SearchOutlined } from "@ant-design/icons";
const updateTimetable = async (data) => {
  const instance = await getAxiosTokenInstance();
  const resp = await instance.post("/college/timetable/setTmetable", data);
  return resp.data;
};

function TimeTableTeacherSelectModal({
  onClose,
  modalOpen,
  refetch,
  data,
  loading,
}) {
  const [searchStr, setSearchStr] = useState("");
  const { mutate, isLoading } = useMutation(updateTimetable, {
    onSuccess: (data) => {
      if (data.statusCode === 400) {
        message.error(data.message || "API Error");
      } else {
        refetch();
        message.success(data.message);
        onClose();
      }
    },
    onError: (error) => {
      message.error(error.message || "API Error");
    },
  });
  const [workLoad, setWorkLoad] = useState({});
  useEffect(() => {
    let workload = {};
    Object.keys(modalOpen?.classRow?.days)?.forEach((day) => {
      const periods = modalOpen.classRow.days[day];
      periods?.forEach((period) => {
        if (period._id && period?.subjectDetails?._id)
          workload[`${period._id}-${period?.subjectDetails?._id}`] =
            workload[`${period._id}-${period?.subjectDetails?._id}`] + 1 || 1;
      });
    });
    setWorkLoad(workload);

  }, [modalOpen?.classRow?.days]);


  const clearHandler = async () => {

    const instance = await getAxiosTokenInstance();
    const resp = await instance.post("/college/timetable/clearField", { classId: modalOpen?.classRow?.classId, day: modalOpen?.day, period: modalOpen?.period, collegeId: modalOpen?.classRow?.collegeId });
    if (resp.data.statusCode === 400) {
      message.error(resp.data.message || "API Error");
    } else {
      refetch();
      message.success(resp.data.message);
      onClose();
    }

  }

  const getPostBody = (teacher) => {
    if (teacher?.subjectDetails?.isElective) {
      const electives = data?.teacherList?.filter(teach => teach?.classId === modalOpen?.classRow?.classId && teach?.subjectDetails?.electiveId === teacher?.subjectDetails?.electiveId)
      return {
        electiveId: teacher?.subjectDetails?.electiveId,
        electiveSubjects: electives?.map(teach => ({
          ...teach,
          classId: undefined
        }))
      }
    } else {
      return {
        ...teacher,
        classId: undefined,
      }
    }
  }

  return (
    <div className={styles.modalCountrySelect}>
      <div className={styles.overlayClose} onClick={() => onClose()} />
      <div className={styles.modalContent}>
        <div className={styles.head}>
          <span>
            {modalOpen?.classRow.className +
              " | " +
              modalOpen?.day +
              " | Period - "}
            {modalOpen?.period + 1}
          </span>
        </div>
        <div className={styles.content}>
          {/* <div className={styles.searchDIV}>
            <input
              value={searchStr}
              type="text"
              className={styles.searchCountry}
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <div className={styles.searchIcon}>
              <SearchOutlined />
            </div>
          </div> */}
          <Scrollbars
            className={styles.countryListScroll}
            renderThumbHorizontal={() => <div />}
            renderThumbVertical={() => <div />}
            renderView={(props) => (
              <div {...props} className={styles.countryList} />
            )}
          >
            {

              loading
                ? Array(16)
                  .fill("")
                  ?.map(() => (
                    <div className={styles.listCountry}>
                      <Skeleton className={styles.name} width={200} />
                      <Skeleton circle className={styles.flag} />
                    </div>
                  ))
                : data?.teacherList
                  ?.filter(
                    (teacher) =>
                      teacher?.classId === modalOpen?.classRow?.classId
                  )
                  ?.map((teacher, i) => (
                    <div
                      key={`${teacher._id}-${teacher?.subjectDetails?._id}`}
                      className={`${styles.listCountry} ${styles[
                        teacher?.workLoad >
                        (workLoad[
                          `${teacher._id}-${teacher?.subjectDetails?._id}`
                        ] || 0)
                      ]
                        }`}
                      onClick={() => {
                        let temp = modalOpen?.classRow;
                        temp.days[modalOpen?.day][modalOpen.period] = getPostBody(teacher);
                        if (
                          teacher?.workLoad >
                          (workLoad[
                            `${teacher._id}-${teacher?.subjectDetails?._id}`
                          ] || 0)
                        ) {
                          mutate({ ...temp, className: undefined });
                        } else {
                          message.warning(
                            "The Workload For This Teacher Is Exhausted"
                          );
                        }
                      }}
                    >
                      <div style={{ height: '100%', width: '15px', backgroundColor: teacher?.subjectDetails?.isElective ? '#ffa500' : '#002267' }} />
                      <img
                        src={teacher?.photoURL}
                        alt=""
                        className={styles.flag}
                      />
                      <div className={styles.texts}>
                        <div className={styles.name}>
                          {teacher?.name}({teacher?.shortName}
                          )
                        </div>
                        <div className={styles.subject}>
                          {teacher?.subjectDetails?.name} ({teacher?.workLoad}
                          /
                          {workLoad[
                            `${teacher._id}-${teacher?.subjectDetails?._id}`
                          ] || 0}
                          )
                        </div>
                      </div>
                    </div>
                  ))
              // : data?.teacherList
              // ?.filter(
              //   (teacher) =>
              //     teacher?.classId === modalOpen?.classRow?.classId
              // )?.filter(teacher=>teacher?.name.toUpperCase().includes(searchStr.toUpperCase()))
              // ?.map((teacher, i) => (
              //   <div
              //     key={`${teacher._id}-${teacher?.subjectDetails?._id}`}
              //     className={`${styles.listCountry} ${
              //       styles[
              //         teacher?.workLoad >
              //           (workLoad[
              //             `${teacher._id}-${teacher?.subjectDetails?._id}`
              //           ] || 0)
              //       ]
              //     }`}
              //     onClick={() => {
              //       let temp = modalOpen?.classRow;
              //       temp.days[modalOpen?.day][modalOpen.period] = {
              //         ...teacher,
              //         classId: undefined,
              //       };
              //       if (
              //         teacher?.workLoad >
              //         (workLoad[
              //           `${teacher._id}-${teacher?.subjectDetails?._id}`
              //         ] || 0)
              //       ) {
              //         mutate({ ...temp, className: undefined });
              //       } else {
              //         message.warning(
              //           "The Workload For This Teacher Is Exhausted"
              //         );
              //       }
              //     }}
              //   >
              //     <img
              //       src={`https://avatars.dicebear.com/api/male/${i}.svg`}
              //       alt=""
              //       className={styles.flag}
              //     />
              //     <div className={styles.texts}>
              //       <div className={styles.name}>
              //         {teacher?.name}({teacher?.subjectDetails?.shortName}
              //         )
              //       </div>
              //       <div className={styles.subject}>
              //         {teacher?.subjectDetails?.name} ({teacher?.workLoad}
              //         /
              //         {workLoad[
              //           `${teacher._id}-${teacher?.subjectDetails?._id}`
              //         ] || 0}
              //         )
              //       </div>
              //     </div>
              //   </div>
              // ))

              // <>
              //   <div

              //   className={`${styles.listCountry} ${
              //     styles[
              //       1 >
              //       (8 || 0)
              //     ]
              //   }`}

              //   >

              //   <img
              //     src={`https://avatars.dicebear.com/api/male/${2}.svg`}
              //     alt=""
              //     className={styles.flag}
              //     />
              //   <div className={styles.texts}>
              //     <div className={styles.name}>
              //       {"ckvaizz"}({"ck"})
              //     </div>
              //     <div className={styles.subject}>
              //       {"computer science"} ({10}/
              //       {9 || 0}
              //       )
              //     </div>
              //   </div>
              // </div>

              //     </>
            }
            {
              data?.teacherList.length === 0 ?
                <div className={styles.noListDiv} >
                  No List found
                </div>

                : searchStr !== "" ? data?.teacherList
                  ?.filter(
                    (teacher) =>
                      teacher?.classId === modalOpen?.classRow?.classId
                  )?.filter(teacher => teacher?.name.toUpperCase().includes(searchStr.toUpperCase())).length === 0 ?
                  <div className={styles.noListDiv} >
                    No search result found
                  </div> : ''
                  : ''
            }
          </Scrollbars>
          <div className={styles.Buttons}>
            <div className={styles.cancelBTN} onClick={() => onClose()}>
              Cancel
            </div>
            <div className={styles.clearBTN} onClick={clearHandler} >Clear Field</div>
          </div>
        </div>
        {isLoading && (
          <div className={styles.loading}>
            <LoadingAnimation />
          </div>
        )}
      </div>
    </div>
  );
}

export default TimeTableTeacherSelectModal;
