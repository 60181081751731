import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { DailyFeeCollectionIndividualRegister } from "./DailyFeeCollectionIndividualRegister";


const getAccounts = (data) => async () => {


  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/fees/getDailyFeeColloctionRegister', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

const getIndividualAccounts = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/fees/getSubsidiaryIndividualRegister', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {


      return resp?.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

export const DailyFeeCollectionRegister = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const [state, setState] = React.useState({
    filterValue: '',
    page: 0,
  });

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data, refetch: issuedBookRefetch } = useQuery(['DFCR0'], getAccounts({ collegeId, startDate: state.startDate, endDate: state.endDate, skip: state.page - 1 * 40, limit: 40, setLoading }));


  const { data: individualData, refetch: refetchData } = useQuery(['DFCRoIndividual', state?.selectedDate, state?.countData], getIndividualAccounts({ collegeId, date: state?.selectedDate }));

  console.log(data?.keyValueData);



  let issuedData = {}


  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <div className={classNames.listContainer2}>




          <Scrollbars style={{ height: '100%' }} >
            <div ref={componentRef} className="printable" style={{ overflow: 'scroll' }}>
              <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                  <img width='8%' height='8%' src={selectedCollege?.logo} />
                  <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                    <span style={{ fontSize: '30px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>

                    <h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Daily Fee Collection Register </h5>

                  </div>
                </div>
                <div  style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 25, marginTop: 4, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}>SN</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '15%' }}>Date</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>C</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '15%' }}>Rt.No.</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister2}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister3}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister4}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister5}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister6}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister7}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister8}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister9}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister10}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister11}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister12}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister13}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister14}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister15}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister16}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister17}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister18}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister19}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister20}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister21}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister22}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister23}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister24}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister25}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>TOTAL</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '15%' }}>Date</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                </div>
                {data?.subsidiaryRegisterData?.map((item, i) => (
                  <>

                    <div
                      onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date }) }}

                      style={{ width: '100%', display: 'flex', height: ' 48px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '4%' }}>{i + 1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '15%' }}>{item?.date}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '5%' }}>{item?.countData}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '15%' }}>{item?.recieptNumber}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister2}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister3}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister4}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister5}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister6}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister7}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister8}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister9}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister10}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister11}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister12}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister13}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister14}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister15}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister16}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister17}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister18}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister19}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister20}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister21}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister22}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister23}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister24}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister25}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.totalAmount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '15%' }}>{item?.date}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'black' }}></div>
                  </>
                ))}

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 80, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}></h5>
                  <h5 style={{ color: 'white', alignSelf: 'center', fontSize: 14, width: '15%' }}></h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}></h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '15%' }}>Total</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal1}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '6%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal2}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '6%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal3}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal4}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal5}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal6}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '6%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal7}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '6%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal8}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '6%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal9}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal10}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal11}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal12}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal13}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal14}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal15}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal16}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal17}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal18}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal19}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal20}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal21}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal22}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal23}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal24}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal25}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '8%', transform: 'rotate(-90deg)' }}>{data?.totals?.grandTotal}</h5>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '15%' }}></h5>
                </div>
                <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 16 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
              </div>
            </div>
          </Scrollbars>
        </div >

        {state.openModal && (<DailyFeeCollectionIndividualRegister date={state?.selectedDate} data={individualData} setStateData={setState} stateData={state} refetch={refetchData} onClose={() => setState({ ...state, openModal: false })} />)}


      </div>

      <div className={classNames.absoluteDiv}  >











        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }} >
            <Field
              type="date"
              name="startDate"
              id="startDate"
              value={state.startDate}
              onChange={(e) => {
                setState({ ...state, startDate: e.target.value, accessionNumber: '', admisionNo: '' });
              }} />
            <label>Start Date</label>
          </div>
        </Formik>
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }} >
            <Field
              type="date"
              name="endDate"
              id="endDate"
              value={state.endDate}
              onChange={(e) => {
                setState({ ...state, endDate: e.target.value, accessionNumber: '', admisionNo: '' });
              }} />
            <label>End Date</label>

          </div>
        </Formik>

        <Button className={classNames.reloadButton} disabled={parseInt(state.page) < 1} onClick={() => {
          setState({ ...state, page: (parseInt(state.page) - 1) })
        }}>
          Prev
        </Button>
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`}>
            <Field
              type="number"
              name="year"
              id="year"
              value={state.page}
              onChange={(e) => {
                setState({ ...state, page: e.target.value })
              }} />
            <label>Page</label>
          </div>
        </Formik>
        <Button className={classNames.reloadButton} disabled={(parseInt(state.page) * 30) > (parseInt(issuedData?.total?.[0]?.total) - 30)} onClick={() => {
          setState({ ...state, page: (parseInt(state.page) + 1) })
        }}>
          Next
        </Button>
        {loading ? <LoadingAnimation customHeight={50} customWidth={50} /> :
          <>
            <span className={classNames.heading}>Showing Result : {parseInt(state.page) * 30} - {(parseInt(state.page) * 30) + 30} out of {issuedData?.total?.[0]?.total} </span>
            <Button className={classNames.reloadButton} onClick={() => {
              issuedBookRefetch()
            }}>
              Load
            </Button>
          </>
        }
        <ReactToPrint
          trigger={() => <button className={classNames.reloadButton}>Print</button>}
          content={() => componentRef.current}
        />



      </div >
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div >
  );
};
