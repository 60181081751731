import React, { useEffect, useContext, useState } from "react";
import classNames from "./studentTable.module.scss";
import { Table, Popconfirm } from "antd";
import Scrollbars from "react-custom-scrollbars";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import { useQuery } from "react-query";
import { getPenddingAdmission } from "../../../ApiServices";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import moment from "moment";
import { Checkmark } from "react-checkmark";
import ViewStudentData from "../../ViewStudentData";
import ModalWrapper from "../../../../../components/ModalWrapper";





function AddStudentTableStudents({ refreshStatus, classId, selectUser, toggleRefresh }) {
  const { collegeId, user } = useContext(FirebaseContext);

  const [state, setState] = useState({})

  const { data, refetch, status } = useQuery(['getPenddingAdmissions', classId], getPenddingAdmission(collegeId, classId, user.uid));



  let userList = data?.list || []
  let isTeacher = false



  useEffect(() => {
    refetch && refetch();
  }, [refreshStatus, refetch]);


  console.log({ userList });

  const removeUser = async (data, collegeId) => {
    try {
      const instance = await getAxiosTokenInstance();
      let resp = await instance.put("/college/users/deleteUser", { collegeId, id: data._id });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      }
      message.success(data?.message || "User Removed");
      toggleRefresh();
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    }
  };



  let tableColumns = [

    {
      title: "SN",
      render: (_, item, i) => i + 1,
      key: "createdOn",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
    },
    {
      title: "Details",
      render: (_, data) => (
        <button
          style={{ cursor: "pointer", width: 40, height: 40, borderRadius: 20, padding: 12, textAlign: 'center', backgroundColor: 'green' }}
          onClick={() => { setState({ ...state, items: data, studentId: data?._id, viewMode: true }) }}  >
          <div >
            <div style={{ alignContent: 'center', cursor: "pointer", fontSize: 20, color: 'white', fontWeight: 900 }}>V</div>
          </div>
        </button>
      ),
    },
    {
      title: "App",
      render: (_, data) => (
        data?.percentageOfCompletion === 100 ? (
          <div >
            <Checkmark size={'40'} />
          </div>
        ) : (
          (data?.percentageOfCompletion || 0) + '%'
        )
      ),
    },
    {
      title: "DV",
      render: (_, data) => (
        data?.verification?.department && (
          <div >
            <Checkmark size={'40'} />
          </div>
        )
      ),
    },
    {
      title: "NV",
      render: (_, data) => (
        data?.verification?.nodal ? (
          <div >
            <Checkmark size={'40'} />
          </div>
        ) : (
          (data?.verification?.nodalToken || '')
        )
      ),
    },

    {
      title: "PV",
      render: (_, data) => (
        data?.verification?.principal ? (
          <div >
            <Checkmark size={'40'} />
          </div>
        ) : (
          (data?.verification?.principalToken || '')
        )
      ),
    },
    {
      title: "OV",
      render: (_, data) => (
        data?.verification?.office ? (
          <div >
            <Checkmark size={'40'} />
          </div>
        ) : (
          (data?.verification?.officeToken || '')
        )
      ),
    },

    {
      title: "AV",
      render: (_, data) => (
        !data?.disabled && (
          <div >
            <Checkmark size={'40'} />
          </div>
        )
      )
    },

    ...isTeacher ?
      [
        {
          title: "Action",
          render: (_, data) => (
            <div style={{ cursor: "pointer" }} onClick={() => selectUser(data)}>
              View
            </div>
          ),
        }
      ]
      :
      [
      ],





    {
      title: "Edit",
      render: (_, data) => (
        <div style={{ cursor: "pointer" }} onClick={() => selectUser(data)}>
          Edit
        </div>
      ),
    },
    {
      title: "Delete",
      render: (_, data) => (
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to delete this student" okText="Yes" cancelText="No"
          onConfirm={() => removeUser(data, collegeId)}
        >
          <span style={{ cursor: "pointer" }}>Delete</span>
        </Popconfirm>
      ),
    }
  ];








  return (
    <>
      <div
        className={classNames.tableView}
        style={{ backgroundColor: "white" }}
      >
        <div className={classNames.tableHeader}>
          <h2>Progress Table</h2>
        </div>

        {status === "loading" && <LoadingAnimation dark />}
        {status === "success" && (
          <Scrollbars className={classNames.tableWrap}>
            <div className={classNames.userTable}>
              <Table
                columns={tableColumns}
                dataSource={userList && userList?.length > 0 ? userList : []}
                pagination={false}
              // bordered
              />
            </div>
          </Scrollbars>
        )}
        {state.viewMode && (<ModalWrapper><ViewStudentData uid={state?.studentId} onClose={() => {
          setState({ ...state, viewMode: false });
        }} /></ModalWrapper>)}
      </div>
    </>
  );
}

export default AddStudentTableStudents;
