import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import Services from '../../components/Website/services';
import PricingTable from '../../components/Website/pricing-table';
import TestimonialV2 from '../../components/Website/testimonial';
import Subscribe from '../../components/Website/subscribe';
import FooterBar from '../../components/Website/footerBar';

const Service = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Services" />
        <Services customclass="pd-top-112" />
        <PricingTable />
        <TestimonialV2 />
        <Subscribe />
        <FooterBar />
    </div>
}

export default Service

