import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../NecttosComp/Table/Table";
import { getNewClasses, getSeatReservation } from "../../pages/college/ApiServices";
import moment from "moment";
import ApplicationView from "./ApplicationView";

interface invoiceManagementProps {
  onClose: () => void;
}

function SeatReservationDetails({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();

  const [showForm, setShowForm] = useState(false);

  const { collegeId, user } = useContext(FirebaseContext);

  const ref = useRef<any>(null);

  const [selectedClass, setSelectClass] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  const { data: tableData = [], refetch } = useQuery(["getAlddddleeeessddseeeEnquiry", selectedClass, searchTerm], getSeatReservation(collegeId, selectedClass, searchTerm));

  const { data: classes } = useQuery("getNeeeewCladfdsfsdsses", getNewClasses(collegeId, user?.uid));

  const [filterdata, setFilter] = useState<any>([]);

  const [filterKey, setFilterKey] = useState("ALL");

  useEffect(() => {
    if (!filterKey || !tableData) return;

    switch (filterKey) {
      case "UG":
        setFilter(tableData.filter((data: any) => ["2024-2028", "2024-2027", "2024-2029"].includes(data.batchName)));
        break;
      case "PG":
        setFilter(tableData.filter((data: any) => ["2024-2026"].includes(data.batchName)));
        break;
      case "ALL":
        setFilter([...tableData]);
        break;
      default:
        break;
    }
  }, [filterKey, tableData]);

  const { tableItems, requestSort, getIcon } = useSortableData(filterdata);

  const addNewForm = async () => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post("/college/admissionProcess/addReservation", {
        studentId: addNew?.studentId,
        collegeId,
        details: addNew?.detail,
        classId: selectedClass,
        userId: user?.uid,
        boolType: false,
      });
      if (resp?.data?.statusCode === 200) {
        refetch();
        closeAnimation();
        setTimeout(() => setAddNew({ ...addNew, showReservation: false }), 500);
      } else {
        refetch();
      }
    } catch (error) {
      refetch();
    }
  };

  return (
    <>
      <Main title="Applications Initiated" width="100%" height="100%">
        <Header>
          <div className="flex">
            <Input fieldName="Filter by Course" state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="15vw" />
            <Input fieldName="Search" state={searchTerm} setState={setSearchTerm} type="text" width="15vw" />
            <view className="h-[50px]  rounded-md p-3 m-2 px-2 bg-white">
              <h5> Total Applications: {tableItems?.length}</h5>
            </view>
          </div>
          <div className="flex">
            <Button
              type="save"
              onClick={() => {
                setFilterKey("ALL");
                setSelectClass("");
              }}
            >
              Show All
            </Button>
            <Button
              type="fetch"
              onClick={() => {
                setFilterKey("UG");
                setSelectClass("");
              }}
            >
              Show UG
            </Button>
            <Button
              type="doc"
              onClick={() => {
                setFilterKey("PG");
                setSelectClass("");
              }}
            >
              Show PG
            </Button>
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <Thead>
            <Tr>
              <Th position={0}>Sl.No.</Th>
              <Th position={1} onClick={() => requestSort("createdOn")} icon={getIcon("createdOn")}>
                Date
              </Th>
              <Th position={0} onClick={() => requestSort("applicationNo")} icon={getIcon("applicationNo")}>
                Appl. No
              </Th>
              <Th position={2} onClick={() => requestSort("name")} icon={getIcon("name")}>
                Name
              </Th>
              <Th position={1}>Mob 1 w</Th>
              <Th position={1}>Mob 2 G</Th>
              <Th position={1}>Mob 3 S</Th>
              <Th position={0} onClick={() => requestSort("reservedBy")} icon={getIcon("reservedBy")}>
                Reserved By
              </Th>
              <Th position={1} onClick={() => requestSort("coursePreference")} icon={getIcon("coursePreference")}>
                Course Preference
              </Th>
              <Th position={1}>Details</Th>
              <Th position={1}>Action</Th>
              <Th position={1}>Vew Application</Th>
            </Tr>
          </Thead>
          <div ref={ref} className="h-[85vh] pt-2 printable overflow-auto">
            <Table width="100%" innerWidth={["5%", "10%", "15%", "25%", "10%", "10%"]}>
              <Tbody height="auto">
                {tableItems?.map((x: any, i: number) => (
                  <Tr>
                    <Td index={i} position={0}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {moment(x?.createdOn).format("DD-MM-YYYY")}
                    </Td>
                    <Td index={i} position={0}>
                      {x?.applicationNo}
                    </Td>
                    <Td index={i} position={2}>
                      {x?.name}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.whatsappNumber}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.guardianMobile}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.phoneNumber}
                    </Td>
                    <Td index={i} position={0}>
                      {x?.reservedBy}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.reservedCourse}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.details}
                    </Td>
                    <Td index={i} position={4}>
                      <Button
                        type="close"
                        onClick={() => {
                          setAddNew({ ...addNew, showReservation: true, studentId: x?._id, boolType: false });
                        }}
                      >
                        Remove
                      </Button>
                    </Td>
                    <Td index={i} position={1}>
                      <Button
                        type="doc"
                        onClick={() => {
                          setAddNew({ ...addNew, viewMode: true, _id: x._id });
                        }}
                      >
                        ViewApp
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        {addNew?.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: "ug" }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
      </Main>
      {addNew?.showReservation && (
        <Main width="40%" height="40%" title="Reserve Students">
          <Header>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(() => setAddNew({ ...addNew, showReservation: false }), 500);
              }}
            >
              Close
            </Button>
          </Header>
          <Body>
            <div className="flex flex-col w-full">
              <Input width="100%" fieldName="Details" returnKey="detail" state={addNew} setState={setAddNew} type="text" />
              <Button
                type="save"
                onClick={() => {
                  addNewForm();
                }}
              >
                Save
              </Button>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default SeatReservationDetails;
