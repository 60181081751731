import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button } from "antd";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import Scrollbars from "react-custom-scrollbars";
import { getQuestionnairPercentageAnalysis } from "../../../ApiServices";




export const Statistics = ({ onClose, questionnaireId }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [state, setState] = React.useState({});
  const { data: allData = [], refetch } = useQuery(['getQuestionnairPercentatgeAnalysis', collegeId, questionnaireId], getQuestionnairPercentageAnalysis(collegeId, questionnaireId));

  console.log({ allData });




  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <>
          <div className={classNames.listContainer2}>
            <div style={{ color: 'red', fontWeight: '900', textAlign: 'center', fontSize: 40 }}> {state.departmentName?.toUpperCase()} {state.departmentName ? 'students' : 'Students'} Profile Statistics</div>


            <div style={{ height: '5%', width: '80%', flexDirection: 'row', display: 'flex', alignSelf: 'center' }}>


              <div style={{ height: '100%', margin: 10, backgroundColor: '#007aa3', width: '120%', flexDirection: 'row', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>
                Total Data  {((state.unverified2 || state.unverified || allData?.percentage))?.toFixed(2)}%
              </div>



              {state?.deptBack && <div
                onClick={() => setState({ ...state, intividualData: false, subDataData: false, deptBack: false, classBack: false, verified: '', unverified: '', })}
                style={{ height: '100%', margin: 10, backgroundColor: 'grey', width: '50%', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>
                back
              </div>}
              {state?.classBack && <div
                onClick={() => setState({ ...state, intividualData: true, deptBack: true, classBack: false, subDataData: false, subData: [], verified2: '', unverified2: '', })}

                style={{ height: '100%', margin: 10, backgroundColor: 'grey', width: '50%', alignItems: 'center', color: 'white', textAlign: 'center', borderRadius: 25, fontSize: 24, fontWeight: 900 }}>
                back
              </div>}
            </div>
            <Scrollbars   >
              {
                state?.subDataData ?
                  <div style={{ height: '70.5%', width: '200%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
                    {state?.subData?.map((x) => (
                      <div onClick={() => setState({ ...state, intividualData: false, subDataData: false, deptBack: false, classBack: false, departmentName: '', verified: '', unverified: '', verified2: '', unverified2: '', })}
                        style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center', }}>
                        <div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 102, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)' }}> {x.name}
                        </div>
                        <div style={{ height: `${(x.percentage / 2)}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentage?.toFixed(2)}%</div>
                      </div>
                    ))
                    }
                  </div>
                  :
                  <>
                    {state?.intividualData ?
                      <div style={{ height: '70.5%', width: '200%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
                        {state?.dataData?.map((x) => (
                          <div
                            onClick={() => setState({ ...state, deptBack: false, classBack: true, intividualData: false, subDataData: true, subData: (x?.data), verified2: x.percentage, unverified2: x.percentage, departmentName: x?.title })}
                            style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center', }}>
                            <div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 102, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)', }}>{x.title}</div>
                            <div style={{ height: `${x.percentage}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentage?.toFixed(2)}%</div>
                          </div>
                        ))}
                      </div>
                      :
                      <div style={{ height: '70.5%', width: '200%', flexDirection: 'row', display: 'flex', alignItems: 'end' }}>
                        {allData?.departmentData?.map((x) => (
                          <div
                            onClick={() => setState({ ...state, deptBack: true, classBack: false, intividualData: true, dataData: (x?.data), verified: x.percentage, unverified: x.percentage, departmentName: x?.title })}
                            style={{ cursor: 'pointer', height: '79%', margin: '1%', width: 52, color: 'red', fontWeight: '900', textAlign: 'center', }}>
                            <div style={{ height: '8%', position: 'absolute', bottom: '6%', width: 102, color: 'black', alignItems: 'end', fontWeight: '900', fontSize: 12, transform: 'rotate(-90deg)', }}>{x.title.slice(14)}</div>
                            <div style={{ height: `${x.percentage}%`, backgroundColor: 'green', position: 'absolute', bottom: '19%', width: 52, color: '#ffdfbf', fontWeight: '900', textAlign: 'center' }}>{x.percentage?.toFixed(2)}%</div>
                          </div>
                        ))}
                      </div>
                    }
                  </>}
            </Scrollbars>
          </div>
        </>
      </div >
      <Button className={classNames.reloadButton} >
        Refresh
      </Button>
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div >
  );
};
