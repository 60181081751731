import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getInternalAssessment } from '../../pages/college/ApiServices';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import { useAllClasses } from '../../queryHooks';
import CustomDropDownKey from '../../myComp/CustomDropDownKey';

export const InternalAssessment = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef(null);
	const [state, setState] = useState({});
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
	const allClasses = useAllClasses({ needAll: true, classOnly: true });
	const allSemester = [
		{ _id: '1', name: 'Semester 1' },
		{ _id: '2', name: 'Semester 2' },
		{ _id: '3', name: 'Semester 3' },
		{ _id: '4', name: 'Semester 4' },
		{ _id: '5', name: 'Semester 5' },
		{ _id: '6', name: 'Semester 6' },
		{ _id: '7', name: 'Semester 7' },
		{ _id: '8', name: 'Semester 8' },
		{ _id: '9', name: 'Semester 9' },
		{ _id: '10', name: 'Semester 10' },
	];
	const { data: assessmentData, isLoading } = useQuery([`getInternalAssessment`, collegeId, state?.classId, state?.semester], getInternalAssessment({ collegeId, classId: state?.classId, semester: state?.semester }));

	console.log({ assessmentData: assessmentData });

	const dummyData = [
		{
			registerNumber: '180021026137',
			name: 'JITHU BIJU',
			punctuality: 10,
			experimentation: 10,
			knowledge: 10,
			report: 10,
			total: 20,
		},
		{
			registerNumber: '180021026137',
			name: 'JITHU BIJU',
			punctuality: 10,
			experimentation: 10,
			knowledge: 10,
			report: 10,
			total: 20,
		},
	];

	const Theory = ({ title, data }) => {
		return (
			<div className='p-10 max-w-7xl mx-auto bg-white rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-left font-bold'>
								Form:A1
							</th>
							<th colSpan='16' className='border border-black p-2 text-center font-bold ' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='2' className='border border-black p-2 text-left font-bold'>
								Programme :.....
							</th>
							<th colSpan='10' className='border border-black p-2 text-center font-bold'>
								{'INTERNAL ASSESSMENT ( THEORY ) U.G. PROGRAMME - CBCS'}
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								{'...Year : 2020-2021'}
							</th>
							<th colSpan='2' className='border border-black p-2 text-center font-bold'>
								{'...Semester :'}
							</th>
						</tr>
						<tr>
							<th colSpan='2' className='border border-black p-2 text-left font-bold'>
								Course Code & Title:
							</th>
							<th colSpan='15' className='border border-black p-2 text-center font-bold'>
								{'...... CH6PR01 : PROJECT AND INDUSTRIAL VISIT'}
							</th>
						</tr>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '150px' }}>
								Register Number
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold print:w-full' rowSpan='2'>
								Name
							</th>
							<th colSpan='4' className='border border-black p-2 text-center font-bold'>
								Attendance (wt: ....5)
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Assignments / Seminar / viva (wt: ....2)
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Test 1 (wt: .....4)
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Test 2 (wt: ....4)
							</th>
							<th className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total (wt: ........15)
							</th>
							<th className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '70px' }}>
								Remarks
							</th>
						</tr>
						<tr>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								No of working days
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Days present
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								%
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-left'>{row?.hallTicketNo}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.name}</td>

								<td className='font-medium border border-black p-2 text-center'>{row?.atts?.workingDays}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.atts?.presents}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.atts?.percentage}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.atts?.mark}</td>

								<td className='font-medium border border-black p-2 text-center'>{row?.assg?.total}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.assg?.obtained}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.assg?.mark}</td>

								<td className='font-medium border border-black p-2 text-center'>{row?.testOne?.total}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.testOne?.obtained}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.testOne?.mark}</td>

								<td className='font-medium border border-black p-2 text-center'>{row?.testTwo?.total}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.testTwo?.obtained}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.testTwo?.mark}</td>

								<td className='font-medium border border-black p-2 text-center'>{row?.totalMark}</td>
								<td className='font-medium border border-black p-2 text-center'>{''}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='17' className='border border-black p-2 text-center'>
								{'Certified that 1. The entries are verified with the records and there is no error or omission. 2. These assesments were published and no grievances from the students are pending'}
							</td>
						</tr>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='4' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='7' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='6' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	};
	const Practical = ({ title, data }) => {
		return (
			<div className='p-10 max-w-7xl mx-auto bg-white shadow-lg rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold', fontSize: '12px' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-left font-bold'>
								Form:A2
							</th>
							<th colSpan='13' className='border border-black p-2 text-center font-bold ' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='2' className='border border-black p-2 text-left font-bold'>
								Programme :.....
							</th>
							<th colSpan='7' className='border border-black p-2 text-center font-bold'>
								{'INTERNAL ASSESSMENT ( PROJECT ) U.G. PROGRAMME - CBCS'}
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								{'.....Year : 2020-2021'}
							</th>
							<th colSpan='2' className='border border-black p-2 text-center font-bold'>
								{'.....Semester :'}
							</th>
						</tr>
						<tr>
							<th colSpan='2' className='border border-black p-2 text-left font-bold'>
								Course Code & Title:
							</th>
							<th colSpan='12' className='border border-black p-2 text-left font-bold'>
								{'....CH6PR01 : PROJECT AND INDUSTRIAL VISIT'}
							</th>
						</tr>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '150px' }}>
								Register Number
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold print:w-full' rowSpan='2'>
								Name
							</th>
							<th colSpan='4' className='border border-black p-2 text-center font-bold'>
								Attendance (wt: ...2)
							</th>

							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Record / Lab Involvement (wt: ...4)
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Test 1 (wt: ...4)
							</th>
							<th className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total (wt: ....10)
							</th>
							<th className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '70px' }}>
								Remarks
							</th>
						</tr>
						<tr>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								No of working days
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Days present
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								%
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-left'>{row?.registerNumber}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.name} </td>

								<td className='font-medium border border-black p-2 text-center'>{65}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.present}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.percentage}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.present / 10) * (5 / 1)}`}</td>

								<td className='font-medium border border-black p-2 text-center'>{10}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.lab}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.lab / 10) * (5 / 1)}`} </td>

								<td className='font-medium border border-black p-2 text-center'>{10}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.test}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.test / 10) * (5 / 1)}`} </td>

								<td className='font-medium border border-black p-2 text-center'>{row?.total}</td>
								<td className='font-medium border border-black p-2 text-center'>{''}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='14' className='border border-black p-2 text-center'>
								{'Certified that 1. The entries are verified with the records and there is no error or omission. 2. These assesments were published and no grievances from the students are pending'}
							</td>
						</tr>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='3' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='6' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='5' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	};
	const Project = ({ title, data }) => {
		return (
			<div className='p-10 max-w-7xl mx-auto bg-white shadow-lg rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark screen:text-lg' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold', fontSize: '12px' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-left font-bold'>
								Form:A2
							</th>
							<th colSpan='15' className='border border-black p-2 text-center font-bold ' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
						</tr>
						<tr>
							<th colSpan='2' className='border border-black p-2 text-left font-bold'>
								Programme :.....
							</th>
							<th colSpan='9' className='border border-black p-2 text-center font-bold'>
								{'INTERNAL ASSESSMENT ( PROJECT ) U.G. PROGRAMME - CBCS'}
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								{'....Year : 2020-2021'}
							</th>
							<th colSpan='2' className='border border-black p-2 text-center font-bold'>
								{'....Semester :'}
							</th>
						</tr>
						<tr>
							<th colSpan='2' className='border border-black p-2 text-left font-bold'>
								Course Code & Title:
							</th>
							<th colSpan='14' className='border border-black p-2 text-left font-bold'>
								{'CH6PR01 : PROJECT AND INDUSTRIAL VISIT'}
							</th>
						</tr>
						<tr>
							<th colSpan='1' className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ width: '150px' }}>
								Register Number
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold print:w-full' rowSpan='2'>
								Name
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Punctuality (wt:... 5)
							</th>

							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Experimentation / Data Collection (wt: ...5)
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Knowledge (wt: ...5)
							</th>
							<th colSpan='3' className='border border-black p-2 text-center font-bold'>
								Report (wt: ...5)
							</th>
							<th className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total (wt: ....20)
							</th>
							<th className='border border-black p-2 text-center font-bold' rowSpan='2' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '70px' }}>
								Remarks
							</th>
						</tr>
						<tr>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Total Mark
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Mark Obtained
							</th>
							<th className='border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '50px' }}>
								Weightage
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='font-medium border border-black p-2 text-left'>{row?.registerNumber}</td>
								<td className='font-medium border border-black p-2 text-left'>{row?.name}</td>
								<td className='font-medium border border-black p-2 text-center'>{10}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.punctuality}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.punctuality / 10) * (5 / 1)}`}</td>
								<td className='font-medium border border-black p-2 text-center'>{10}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.experimentation}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.experimentation / 10) * (5 / 1)}`}</td>
								<td className='font-medium border border-black p-2 text-center'>{10}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.knowledge}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.knowledge / 10) * (5 / 1)}`}</td>
								<td className='font-medium border border-black p-2 text-center'>{10}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.report}</td>
								<td className='font-medium border border-black p-2 text-center'>{`${(row?.report / 10) * (5 / 1)}`}</td>
								<td className='font-medium border border-black p-2 text-center'>{row?.total}</td>
								<td className='font-medium border border-black p-2 text-center'>{''}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='16' className='border border-black p-2 text-center'>
								{'Certified that 1. The entries are verified with the records and there is no error or omission. 2. These assesments were published and no grievances from the students are pending'}
							</td>
						</tr>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='4' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='6' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='6' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	};
	const ConsolidatedTable = ({ title, data }) => {
		return (
			<div className='p-10 max-w-7xl mx-auto bg-white shadow-lg rounded-lg mb-8 print:p-12 print:shadow-none print:bg-transparent break-after-page text-dark' style={{ fontFamily: 'Times New Roman', fontWeight: 'extrabold', fontSize: '12px' }}>
				<table className='w-full border-collapse border border-black print:border-none'>
					<thead>
						{collegeData?.affiliated && (
							<tr>
								<th colSpan='13' className='border border-black p-2 text-left font-bold'>
									{collegeData?.affiliated}
								</th>
							</tr>
						)}
						<tr>
							<th colSpan='1' className='border border-black p-2 text-left font-bold'>
								Form:A2
							</th>
							<th colSpan='11' className='border border-black p-2 text-center font-bold ' style={{ fontSize: '20px' }}>
								{collegeData?.collegeName}
							</th>
							<th colSpan='1' className='border border-black p-2 text-center font-bold ' style={{ fontSize: '20px' }}>
								{''}
							</th>
						</tr>
						<tr>
							<th colSpan='13' className='border border-black p-2 text-center font-bold'>
								{'INTERNAL ASSESSMENT ( PROJECT ) U.G. PROGRAMME - CBCS'}
							</th>
						</tr>
						<tr>
							<th colSpan='5' className='border border-black p-2 text-left font-bold'>
								Programme :.....
							</th>

							<th colSpan='4' className='border border-black p-2 text-center font-bold'>
								{'....Year : 2020-2021'}
							</th>
							<th colSpan='4' className='border border-black p-2 text-center font-bold'>
								{'....Semester :'}
							</th>
						</tr>

						<tr>
							<th colSpan='1' className='border border-black p-2 text-left font-bold' rowSpan='2' style={{ width: '150px' }}>
								Register Number
							</th>
							<th colSpan='1' className='border border-black p-2 text-left font-bold print:w-full' rowSpan='2'>
								Name
							</th>
							<th colSpan={'11'} className='border border-black p-2 text-center font-bold'>
								crt
							</th>
						</tr>
						<tr>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								CRT{' '}
							</th>
							<th className=' border border-black p-2 text-center font-bold' style={{ transform: 'rotate(180deg)', writingMode: 'vertical-lr', whiteSpace: 'nowrap', width: '60px' }}>
								Remarks
							</th>
						</tr>
					</thead>
					<tbody>
						{data.map((row, index) => (
							<tr key={index} className='bg-white even:bg-gray-100 print:bg-transparent'>
								<td className='border border-black p-2 text-center'>{row?.registerNumber}</td>
								<td className='border border-black p-2 text-center'>{row?.name}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>
								<td className='border border-black p-2 text-center'>{''}</td>

								<td className='border border-black p-2 text-center'>{''}</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='13' className='border border-black p-2 text-center'>
								{'Certified that 1. The entries are verified with the records and there is no error or omission. 2. These assesments were published and no grievances from the students are pending'}
							</td>
						</tr>
						<tr className='bg-white even:bg-gray-100 print:bg-transparent'>
							<td colSpan='3' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='6' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
							<td colSpan='4' className='border border-black p-2 text-center'>
								{'teachers'}
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	};

	return (
		<Main title='Smart Analytics & Data Exporter' width='100vw' height='100vh'>
			<Header>
				<div className='row flex'>
					<view style={{ width: 550 }}>
						<CustomDropDownKey keyData={'classId'} keyObject={'className'} keyReturn={'_id'} titleData={'Select Class'} dropValues={allClasses?.data || []} stateData={state} setStateData={setState} />
					</view>
					<view style={{ width: 550 }}>
						<CustomDropDownKey keyData={'semester'} keyObject={'name'} keyReturn={'_id'} titleData={'Select Semester'} dropValues={allSemester} stateData={state} setStateData={setState} />
					</view>
				</div>
				<div className='flex gap-2'>
					<Button type='print' tableRef={ref.current}>
						print
					</Button>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
			</Header>
			{isLoading ? (
				<div className='flex items-center justify-center min-h-screen bg-gray-100'>
					<div className='flex flex-col items-center'>
						<div className='loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4'></div>
						<p className='text-gray-700 text-lg font-semibold'>Loading data, please wait...</p>
					</div>
				</div>
			) : !state?.classId || !state?.semester || assessmentData?.consolidatedInternalMark.length === 0 ? ( // Error or no data case
				<div className='flex items-center justify-center min-h-screen bg-red-50'>
					<div className='text-center p-6 rounded-lg shadow-md bg-white max-w-md'>
						<div className='flex items-center justify-center mb-4'>
							<svg className='w-12 h-12 text-red-500' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
								<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M6 18L18 6M6 6l12 12' />
							</svg>
						</div>
						<p className='text-red-600 text-xl font-bold mb-2'>Oops! Data Not Found</p>
						<p className='text-gray-700 mb-6'>It seems some required information is missing or there’s an issue fetching the data. Please check the inputs and try again.</p>
						<button onClick={() => window.location.reload()} className='bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300'>
							Refresh Page
						</button>
						<div className='mt-4'>
							<p className='text-sm text-gray-500'>
								Still having trouble?{' '}
								<a href='/help' className='text-red-500 hover:underline'>
									Get Help
								</a>
							</p>
						</div>
					</div>
				</div>
			) : (
				<Body>
					<div className='container mx-auto p-4'>
						<section ref={ref} className='printable bg-white p-6  '>
							{assessmentData?.generalSubject?.map((i) => (
								<Theory key={i._id} title={i?.name} data={i?.studentList} />
							))}
							{assessmentData?.generalSubject?.map((i) => (
								<Practical key={i._id} title={i?.name} data={i?.studentList} />
							))}
							{assessmentData?.generalSubject?.map((i) => (
								<Project key={i._id} title={i?.name} data={i?.studentList} />
							))}
							<ConsolidatedTable title='Consolidated Subject Marks' data={assessmentData?.consolidatedInternalMark} />
						</section>
					</div>
				</Body>
			)}
		</Main>
	);
};
