import { useStudentAttendance } from "../../queryHooks";
import Space from "antd/lib/space";
import Collapse from "antd/lib/collapse";
import classNames from "./detailView.module.scss";
import LoadingAnimation from "../LoadingAnimation";

const { Panel } = Collapse;

const StudentAttendance = ({ uid }) => {
  const { data, status } = useStudentAttendance(uid);

  return (
    <>
      <div className={classNames.subTitle}>Attendance Details</div>
      {status === "loading" && <LoadingAnimation dark />}
      {status === "success" && (
        <Space direction="vertical" className={classNames.space}>
          <Collapse collapsible="header" accordion>
            {data?.attedenceList?.map((item) => (
              <Panel
                key={item.semester}
                header={
                  <>
                    <span>Semester {item.semester}</span>
                    <span>{item.percentage}%</span>
                  </>
                }
              >
                {item.semesterWiseList?.map((monthItem) => (
                  <div
                    key={monthItem.month}
                    className={classNames.attendanceMonth}
                  >
                    <span>{monthItem.month}</span>
                    <span>{item.percentage}%</span>
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        </Space>
      )}
    </>
  );
};

export default StudentAttendance;
