import React from 'react';
import Navbar from './navbar';
import PageHeader from './page-header';
import ContactForm from './contact-form';
import ContactInfo from './contact-info';
import FooterBar from './footerBar';

const Contact = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact" />
        <ContactForm />
        <ContactInfo />
        <FooterBar />
    </div>
}

export default Contact

