import React, { useContext, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import classNames from "./addBookModal.module.scss";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";

const getFieldDropDowns = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getUserNames", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list[0].data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

function SearchableUserInputPhoto({ primoryKey, secondaryKey, tertiaryKey, collection, stateData, setStateData, titleData }) {
  const { collegeId } = useContext(FirebaseContext);
  const [showList, setShowList] = React.useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowList(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const { data: nameOfAuthorDrop = [] } = useQuery(["dataDataData", stateData[primoryKey], collection, primoryKey, secondaryKey, tertiaryKey], getFieldDropDowns({ collegeId, collection, primoryKey, secondaryKey, tertiaryKey, search: stateData[primoryKey], photo: true }));

  const handleInputChange = (e) => {
    const value = e.target.value;
    setStateData({ ...stateData, [primoryKey]: value });

    // If the input is cleared, reset student details
    if (value === "") {
      setStateData((prevState) => ({
        ...prevState,
        collegeId: null,
        [primoryKey]: "",
        _id: null,
        data: [],
      }));
      setShowList(false);
    } else {
      setShowList(true);
    }
  };

  return (
    <div ref={wrapperRef} className="relative">
      <div className={classNames.group}>
        <input
          type="text"
          className="input-field"
          onChange={handleInputChange}
          onFocus={() => {
            setShowList(true);
            setStateData({
              ...stateData,
              _id: "",
              data: [],
              isBilling: false,
              isDues: false,
              isBill: false,
              isAction: false,
            });
          }}
          value={stateData[primoryKey]}
        />
        <label>{titleData}</label>
      </div>
      {showList && stateData[primoryKey] && nameOfAuthorDrop.length ? (
        <div className="h-[90vh] m-6 p-6 w-[96vw] rounded-md bg-[#fdfdfd67] overflow-scroll">
          <div className="flex flex-wrap justify-center gap-5 h-max-96" style={{ marginTop: "2%", justifyContent: "center", justifyItems: "center" }}>
            {nameOfAuthorDrop.map((item) => (
              <div
                key={item?._id}
                className={`w-48 ${item?.activeStatus ? "bg-green-100" : "bg-red-100"} rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer`}
                onClick={() => {
                  setStateData({ ...stateData, collegeId: item?.collegeId[0], [primoryKey]: item[secondaryKey], _id: item?._id, data: [], isBilling: true });
                  setShowList(false);
                }}
              >
                <div className="text-center mb-2">{item?.admisionNo}</div>
                <img src={item?.photoURL || "https://upload.wikimedia.org/wikipedia/commons/thumb/5/50/User_icon-cp.svg/828px-User_icon-cp.svg.png"} alt={item[secondaryKey]} className="rounded-t-lg object-cover w-full h-32" />
                <div className="text-center text-sm p-2"></div>
                <div className="text-center font-bold p-2">{item?.name}</div>
                <div className="text-center mb-2">{item?.shortName}</div>
                <div className="text-center mb-2">{item?.batchName}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SearchableUserInputPhoto;
