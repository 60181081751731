import React, { useContext, useState } from "react";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import SubjectDetails from "./SubjectDetails/SubjectDetails";
import AddSubject from "./components/AddSubject";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { useQuery } from "react-query";

const getSubjects = (collegeId, searchItems) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/subject/getSubjectByFilter", {
      params: { collegeId, searchItems, limit: 50 },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.finalArray;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function SubjectBank({ onClose }) {
  const { collegeId, user } = useContext(FirebaseContext);
  const [subject, setSubject] = useState();
  const [addSubject, setAddSubject] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");

  const { data: subjectBank = [], refetch } = useQuery(["getSubdddjects", collegeId, search], getSubjects(collegeId, search));

  console.log({ subjectBank });

  return (
    <>
      <Main width="auto" height="auto " title="Subject Bank">
        <Header>
          <div className="flex justify-self-center">
            <input
              type="search"
              placeholder="search"
              onChange={(e) => {
                setSearch(e.target.value.toLowerCase());
              }}
            />
            <Button type="save" onClick={() => setAddSubject(true)}>
              Add New Subject
            </Button>
          </div>

          <div className="flex">
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 p-4">
            {subjectBank?.map((x) => (
              <div
                key={x?._id}
                onClick={async () => {
                  const instance = await getAxiosTokenInstance();
                  const { data } = await instance.get("/college/subject/getSubjectsWithId", {
                    params: {
                      id: x?._id,
                    },
                  });
                  setSubject(data?.subjectObject);
                }}
                className="cursor-pointer bg-white rounded-lg border border-gray-200 shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out overflow-hidden flex flex-col"
              >
                <div className="w-full aspect-square overflow-hidden">
                  <img src={x.creatorPhotoURL || "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"} alt="User" className="object-cover object-center w-full h-full" />
                </div>

                <div className="flex-grow p-4 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 ease-in-out">
                  <h5 className="text-xl font-bold text-gray-900 mb-4">{x?.name}</h5>
                  <ul className="text-sm text-gray-700 list-disc list-inside space-y-2">
                    <li>
                      <strong className="text-gray-900">SY:</strong> {x.year}
                    </li>
                    <li>
                      <strong className="text-gray-900">CODE:</strong> {x.subjectCode}
                    </li>
                    <li>
                      <strong className="text-gray-900">Added:</strong> {new Date(x.createdOn).toLocaleDateString()}
                    </li>
                    <li>
                      <strong className="text-gray-900">By:</strong> {x.creatorName}
                    </li>
                    <li>
                      <strong className="text-gray-900">College:</strong> {x.collegeName}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </Body>
      </Main>
      {subject ? <SubjectDetails onClose={() => setSubject()} data={subject} /> : null}
      {addSubject ? <AddSubject onClose={() => setAddSubject()} /> : null}
    </>
  );
}

export default SubjectBank;
