import React, { useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { EditDetails } from "../../ApiServices";

function BankDetails({ onClose }) {
  const [values, setValues] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [changes,setChanges] = useState(true)

  const { user, collegeId, collegeList } = useContext(FirebaseContext);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const placeHoldValues = {
    panCard: "Pancard",
          ifsc: "IFSC",
          accountNo:"Account Number",
          beneficiary:"Beneficiary"
  }

  const getUser = async () => {
    const instance = await getAxiosTokenInstance();

    const { data } = await instance.get("/college/profile/get", {
      params: {
        profileId: user?.uid,
        groupData: true,
        isStaff: false,
        collegeId,
        isGrand: false,
      },
    });

    console.log({ user, data });
    setValues({
      bankDetails: {
          panCard: data?.profileData?.bankDetails?.panCard,
          ifsc: data?.profileData?.bankDetails?.ifsc,
          accountNo: data?.profileData?.bankDetails?.accountNo,
          beneficiary: data?.profileData?.bankDetails?.beneficiary,
      },
  });
  
  let changesDetected = {};
  const bankDetailsKeys = ['panCard', 'ifsc', 'accountNo', 'beneficiary'];
  
  bankDetailsKeys.forEach(key => {
      if (data?.profileData?.pendingData?.bankDetails?.[key] !== data?.profileData?.bankDetails?.[key] && data?.profileData?.pendingData?.bankDetails?.[key] !== undefined) {
          
          changesDetected[key] = data?.profileData?.pendingData?.bankDetails?.[key];
      }
  });
  
  setChanges(changesDetected);
  
  };

  useEffect(() => {
    getUser();
  }, [user]);

  const handleInputChange = (e) => {
    setValues({
      ...values,
      bankDetails: { ...values?.bankDetails, [e.target.name]: e.target.value },
    });
  };

  const renderField = (label, value, name) => {
    if (name === "bloodGroup" && isEditing) {
      return (
        <div className="flex justify-between items-center my-2">
          <label className="text-lg font-medium">{label}:</label>
          <select
            name={name}
            value={value || ""}
            onChange={handleInputChange}
            className="border border-gray-300 rounded py-1 px-2 text-lg"
          >
            <option value="">Select Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
          </select>
        </div>
      );
    }

    return (
      <div className="flex justify-between items-center my-2">
        <label className="text-lg font-medium">{label}:</label>
        {isEditing ? (
          <input
            type="text"
            name={name}
            value={changes?.[name] || values?.bankDetails?.[name]}
            onChange={handleInputChange}
            className="border border-gray-300 rounded py-1 px-2 text-lg"
          />
        ) : (
          <p className="text-lg">{value || "--"}</p>
        )}
      </div>
    );
  };

  return (
      <div className="bg-white grid grid-flow-col shadow-lg rounded-lg overflow-hidden w-[70vw] max-w-4xl">
        <div className="px-5 py-4">
          <button onClick={onClose} className="fixed right-5 top-5 text-lg">
            ✖
          </button>
          <h3 className="text-xl font-bold mb-4">Bank Details</h3>
          {Object.entries(values?.bankDetails).map((key)=>(
               <div className="flex justify-between items-center my-2">
               <label className="text-lg font-medium">{placeHoldValues?.[key[0]]}:</label>
               
                 <p className="text-lg">{key[1]}</p>
               
             </div>
            ))}

          <button
            onClick={async() => {
              if(isEditing){
                await EditDetails(values,selectedCollege)
              }
              setIsEditing(!isEditing)
            }}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 mt-4"
          >
            {isEditing ? "Save" : "Edit"}
          </button>
        </div>
        {(Object.entries(changes)[0] || isEditing) &&
         <div className="px-5 py-4">
          <h3 className="text-xl font-bold mb-4">Update Information</h3>
            {Object.entries(!isEditing ? changes : values).map((key)=>(
            
            <>
            {renderField(placeHoldValues?.[key[0]], changes?.[key[0]], key[0])}
            </>
            ))}
          
        </div>
}
      </div>
  );
}

export default BankDetails;
