import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select, Table } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
// import { getReports } from "../../feesManagementServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Alert from 'react-popup-alert'
import { useAccountsHeads } from "../../../../queryHooks";
import Scrollbars from "react-custom-scrollbars";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export const exportPdfCollectionReport = async ({ postData, setLoading, setAlert }) => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post(`/college/reportGeneration/categoryList`, postData, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (e) {
    throw new Error(
      setLoading(false),
      setAlert({
        type: 'Message',
        text: 'Chack Date / Time / Main account / Sub account',
        show: true
      }),
    );
  }
};

export const getReports = (params) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/reportGeneration/categoryList', { params: params });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}
function sumWithKey(data, key) {
  return data.reduce((x, y) => x + y[key], 0);
}
export const CatogoryListData = ({ onClose }) => {

  const { collegeId } = useContext(FirebaseContext);
  const [state, setState] = React.useState({
    startDate: "",
    endDate: "",
    startTime: "",
    year: "",
    mainAccount: "",
    subAccount: "",

  });
  const [alert, setAlert] = React.useState({
    type: 'error',
    text: 'This is a alert message',
    show: false
  })
  const accounts = useAccountsHeads();

  const onCloseAlert = () => {
    setAlert({
      type: '',
      text: '',
      show: false
    })
  }
  let quaryData = {
    startDate: state?.startDate,
    endDate: state?.endDate,
    year: state?.year,
    collegeId
  }
  const { data = [], isLoading, refetch } = useQuery('getcatergory', getReports(quaryData
  ));

  // console.log(data.data.data);






  const [loading, setLoading] = useState(false);
  let subAccount = accounts?.data?.subAccounts || []
  let mainAccount = accounts?.data?.mainAccounts || []
  if (state?.mainAccount) {
    subAccount = subAccount?.filter(x => x.mainId === state?.mainAccount)
  }
  const { Option } = Select;



  const exportPdfMutationDateCollectionReport = useMutation(exportPdfCollectionReport);

  const onDateCollectionReport = async () => {
    setLoading(true)
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        startDate: state?.startDate,
        endDate: state?.endDate,
        startTime: state?.startTime,
        year: state?.year,
        mainAccount: state?.mainAccount || '',
        subAccount: state?.subAccount || '',
        collegeId,
      };

      link.download = state?.endDate;
      const pdfData = await exportPdfMutationDateCollectionReport.mutateAsync({ postData, setLoading, setAlert });
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
      setLoading(false)

    } catch (e) { }
  };








  const columns = [


    {
      title: 'SN',
      dataIndex: 'SN',
      render: (_, data, i) => <div>{i + 1}</div>
    },
    {
      title: 'Class Name',
      dataIndex: 'className',
      key: 'className',
      columnWidth: '15%'
    },
    {
      title: 'Strenth',
      dataIndex: 'strenth',
      key: 'strenth',
      columnWidth: '15%'
    },
    {
      title: 'TC',
      dataIndex: 'inActive',
      key: 'inActive',
      columnWidth: '15%'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      columnWidth: '5%'
    },
    {
      title: 'Female',
      dataIndex: 'female',
      key: 'female',
      columnWidth: '15%'
    },
    {
      title: 'Male',
      dataIndex: 'male',
      key: 'male',
      columnWidth: '15%'
    },
    {
      title: 'General',
      dataIndex: 'general',
      key: 'general',
      columnWidth: '15%'
    },
    {
      title: 'OBC',
      dataIndex: 'obc',
      key: 'obc',
      columnWidth: '15%'
    },
    {
      title: 'OEC',
      dataIndex: 'oec',
      key: 'oec',
      columnWidth: '15%'
    },
    {
      title: 'SC',
      dataIndex: 'sc',
      key: 'sc',
      columnWidth: '15%'
    },
    {
      title: 'ST',
      dataIndex: 'st',
      key: 'st',
      columnWidth: '15%'
    }
  ];






  subAccount = subAccount.concat({ label: 'All', value: '' })
  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >


        {isLoading ? <LoadingAnimation dark /> :

          (

            !alert.show ?
              <>
                <div className={classNames.listContainer2}>
                  <Scrollbars >
                    <table className={classNames.userTable} id="emp">

                      <Table dataSource={data?.data?.data || []} pagination={false} columns={columns} />
                    </table>
                  </Scrollbars>
                </div>


                <div className={classNames.listContainer}>
                  <div className={classNames.denominations} style={{ marginTop: 15, height: '110mm' }}>
                    <label className={classNames.heading}>Gender Wise [ {sumWithKey(data?.data?.genderdata, 'strenth')}]</label>
                    {data?.data?.genderdata?.map((day) => (
                      <div className={classNames.subDiv}>
                        <div>
                          <label className={classNames.subHeads}>{day?.key}</label>
                        </div>
                        <div>
                          <label className={classNames.sub}>:</label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.strenth} </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={classNames.denominations} style={{ marginTop: 15, height: '150mm' }}>
                    <label className={classNames.heading}>Batch - Gender Wise [ {sumWithKey(data?.data?.yearWiseGender, 'strenth')}]</label>
                    <div className={classNames.subDiv}>
                      <div>
                        <label className={classNames.subHeads}>Batch</label>
                      </div>
                      <div>
                        <label className={classNames.sub}>:</label>
                      </div>
                      <div>
                        <label className={classNames.count}> Male </label>
                      </div>
                      <div>
                        <label className={classNames.sub}></label>
                      </div>
                      <div>
                        <label className={classNames.count}> Female </label>
                      </div>
                      <div>
                        <label className={classNames.sub}></label>
                      </div>
                      <div>
                        <label className={classNames.count}> Total </label>
                      </div>
                    </div>
                    {data?.data?.yearWiseGender?.map((day) => (
                      <div className={classNames.subDiv}>
                        <div>
                          <label className={classNames.subHeads}>{day?.key}</label>
                        </div>
                        <div>
                          <label className={classNames.sub}>:</label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.male} </label>
                        </div>
                        <div>
                          <label className={classNames.sub}></label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.female} </label>
                        </div>
                        <div>
                          <label className={classNames.sub}></label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.strenth} </label>
                        </div>
                      </div>
                    ))}
                  </div>


                  <div className={classNames.denominations} style={{ marginTop: 15, height: '250mm' }}>
                    <label className={classNames.heading}>Category - Gender Wise [ {sumWithKey(data?.data?.categorydata, 'strenth')}]</label>
                    <div className={classNames.subDiv}>
                      <div>
                        <label className={classNames.subHeads}>Batch</label>
                      </div>
                      <div>
                        <label className={classNames.sub}>:</label>
                      </div>
                      <div>
                        <label className={classNames.count}> Male </label>
                      </div>
                      <div>
                        <label className={classNames.sub}></label>
                      </div>
                      <div>
                        <label className={classNames.count}> Female </label>
                      </div>
                      <div>
                        <label className={classNames.sub}></label>
                      </div>
                      <div>
                        <label className={classNames.count}> Total </label>
                      </div>
                    </div>
                    {data?.data?.categorydata?.map((day) => (
                      <div className={classNames.subDiv}>
                        <div>
                          <label className={classNames.subHeads}>{day?.key}</label>
                        </div>
                        <div>
                          <label className={classNames.sub}>:</label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.male} </label>
                        </div>
                        <div>
                          <label className={classNames.sub}></label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.female} </label>
                        </div>
                        <div>
                          <label className={classNames.sub}></label>
                        </div>
                        <div>
                          <label className={classNames.count}> {day?.strenth} </label>
                        </div>
                      </div>
                    ))}
                  </div>












                </div>








              </>






              : <Alert
                header={alert.type}
                btnText={'Close'}
                text={alert.text}
                type={alert.type}
                show={alert.show}
                onClosePress={onCloseAlert}
                pressCloseOnOutsideClick={true}
                showBorderBottom={true}
                alertStyles={{ height: 200, width: 800, margin: '50%', backgroundColor: 'red', borderRadius: 10, }}
                headerStyles={{ color: 'white', width: '100%', textAlign: 'center' }}
                textStyles={{ color: 'white', width: '100%', textAlign: 'center', fontSize: 20 }}
                buttonStyles={{ color: 'white', backgroundColor: 'green', padding: 20, borderRadius: 10, margin: '47%' }}

              />






          )
        }

      </div>


      <div className={classNames.absoluteDiv}  >
        <label className={classNames.heading}>Filter </label>

        <Formik
          enableReinitialize
        >
          <div className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="date"
              name="startDate"
              id="startDate"
              value={state.startDate}
              onChange={(e) => {
                setState({ ...state, startDate: e.target.value })
              }
              }
            />
            <label>Start Date</label>

          </div>

        </Formik>


        <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="date"
              name="endDate"
              id="endDate"
              value={state.endDate}
              onChange={(e) => {
                setState({ ...state, endDate: e.target.value })
              }
              }
            />
            <label>End Date</label>

          </div>

        </Formik>

        <Formik
          enableReinitialize
        >
          <div
            className={`${classNames.group} ${classNames.formHalfWidth}`}
          >
            <Field
              type="number"
              name="year"
              id="year"
              value={state.year}
              onChange={(e) => {
                setState({ ...state, year: e.target.value })
              }
              }
            />
            <label>Year of Admn</label>
          </div>
        </Formik>


        <Button className={classNames.reloadButton} onClick={() => refetch()}>
          View
        </Button>
        <Button className={classNames.reloadButton} onClick={() => { refetch() }}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="emp"
            filename="collectionReport"
            sheet="tablexls"
            buttonText="EXCEL"
          />
        </Button>
        {loading ? <LoadingAnimation dark /> :
          <Button className={classNames.reloadButton} onClick={() => { onDateCollectionReport() }}>
            PDF
          </Button>}
      </div>


      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
