import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAllHolidays, getBookRecomentation } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import SearchableImput from "../AddBook/DropDownComponent";
import CustomInput from "../../../../myComp/CustomInput";
import moment from "moment";
import { Field, Formik } from "formik";



const { Option } = Select;



export const BookRecomentation = ({ onClose }) => {

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const { data = [], refetch } = useQuery('getBookRecomentation', getBookRecomentation(collegeId));
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});

  const manageHolidays = async () => {
    try {
      setIsLoading(true);
      if (!state.nameOfBook) {
        throw new Error('Book Name is Empty');
      }
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/action/postBookRecomentation", {
        collegeId,
        nameOfBook: state?.nameOfBook,
        nameOfAuthor: state?.nameOfAuthor,
        yearOfPublication: state?.yearOfPublication,
        nameOfPublisher: state?.nameOfPublisher,
        userId: state?.userId,
        _id: state?._id
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setState({
          ...state,
          nameOfBook: '',
          nameOfAuthor: '',
          yearOfPublication: '',
          nameOfPublisher: '',
          userId: '',
          _id: '',
        })
        refetch()
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getUserList = async ({ queryKey }) => {
    const [_, { type, collegeId, classId }] = queryKey;
    try {
      const instance = await getAxiosTokenInstance();
      const resp = await instance.get(
        `college/users/get?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`}${classId ? `&classId=${classId}` : ""}`
      );
      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
      return resp.data;
    } catch (err) {

      throw new Error(err || "API Error");
    }
  };

  const { data: userList } = useQuery(
    [`userListstudent`, { type: 'student', classId: '', collegeId }],
    getUserList
  );









  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >

        <div className={classNames.listContainer}>

          {isLoading ? <LoadingAnimation dark /> :
            <>
              <div className={classNames.list}>
                {
                  state.alert ?
                    <label className={classNames.heading}>{state.alert}</label> :
                    <label className={classNames.heading}>Add New</label>
                }

                < SearchableImput
                  keyData={'nameOfBook'}
                  titleData={'Title of Book'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />

                < SearchableImput
                  keyData={'nameOfAuthor'}
                  titleData={'Subject'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />
                < SearchableImput
                  keyData={'nameOfPublisher'}
                  titleData={'Name of Publisher'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />
                < CustomInput
                  keyData={'yearOfPublication'}
                  titleData={'Year Of Publication'}
                  typeData={'number'}
                  stateData={state}
                  setStateData={setState}
                />

                <div> <label>Recomended By</label><br />
                  <Select
                    showSearch
                    value={state.name}
                    id="payee"
                    className='inputBox1'
                    style={{ overflow: "hidden" }}
                    placeholder="Full Name"
                    onChange={(val) => {
                      setState({
                        ...state,
                        name: (userList.list.find((x, i) => (i + '-' + x.name + '-' + x.admisionNo) === val)).name,
                        userId: (userList.list.find((x, i) => (i + '-' + x.name + '-' + x.admisionNo) === val))._id
                      });
                    }}
                  >
                    {userList?.list?.map((item, i) => (
                      <Option key={i + '-' + item.name + '-' + item.admisionNo} value={i + '-' + item.name + '-' + item.admisionNo} >
                        {item.admisionNo + '-' + item.name}
                      </Option>
                    ))}
                  </Select></div>


                <div style={{ height: '50px', width: '74%', alignSelf: 'center' }}  >
                  <Button className={classNames.cancelBotton} onClick={() => {
                    setState({
                      ...state,
                      name: '',
                      nameOfAuthor: '',
                      yearOfPublication: '',
                      nameOfPublisher: '',
                      userId: '',
                      _id: '',
                    })
                  }}>  Cancel </Button>
                  <Button className={classNames.submitBotton} onClick={() => { manageHolidays() }}>
                    Submit
                  </Button>
                </div>
              </div>

            </>
          }
        </div>
        <div className={classNames.listContainer2}>




          <Scrollbars style={{ height: '100%' }} >
            <div style={{ position: 'relative', padding: '2%' }}>
              <img style={{ position: 'absolute', width: '80%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', opacity: 0.1, zIndex: -1 }} src={selectedCollege?.logo} alt="College Logo" />

              <div style={{ borderRadius: '25px', backgroundColor: '#f5f5f5', padding: '1%' }}>
                <h5 style={{ margin: '20px 0', fontSize: '24px', textAlign: 'center', fontWeight: 'bold' }}>Book Recommendation Management</h5>

                <div style={{ display: 'flex', paddingRight: 12, flexDirection: 'row', height: 40, margin: '10px 0', backgroundColor: '#444', alignItems: 'center', justifyContent: 'space-between', borderRadius: 5 }}>
                  <h5 style={{ color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>Sn</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '30%' }}>Book Name</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '20%' }}>Subject</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}>Year Of Publication</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '20%' }}>Name Of Publisher</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '20%' }}>Recommended By</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}>Recommended on</h5>
                  <h5 style={{ color: 'white', textAlign: 'left', fontSize: 16, width: '7%' }}></h5>
                </div>

                {data?.map((item, i) => (
                  <div key={i} style={{ borderBottom: '1px solid #ddd', padding: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <h5 style={{ color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>
                      <h5 style={{ color: 'black', textAlign: 'left', fontSize: 19, width: '30%' }}>{item?.nameOfBook}</h5>
                      <h5 style={{ color: 'black', textAlign: 'left', fontSize: 19, width: '20%' }}>{item?.nameOfAuthor}</h5>
                      <h5 style={{ color: 'black', textAlign: 'left', fontSize: 19, width: '10%' }}>{item?.yearOfPublication}</h5>
                      <h5 style={{ color: 'black', textAlign: 'left', fontSize: 19, width: '20%' }}>{item?.nameOfPublisher}</h5>
                      <h5 style={{ color: 'black', textAlign: 'left', fontSize: 19, width: '20%' }}>{item?.userName}</h5>
                      <h5 style={{ color: 'black', textAlign: 'left', fontSize: 19, width: '10%' }}>{moment(new Date(item?.createdOn)).format('ddd, DD MMM YYYY')}</h5>
                      <Button onClick={() => {
                        setState({
                          ...state,
                          name: item?.name || '',
                          nameOfAuthor: item?.nameOfAuthor || '',
                          yearOfPublication: item?.yearOfPublication || '',
                          nameOfPublisher: item?.nameOfPublisher || '',
                          userId: item?.recomendedBy || '',
                          _id: item?._id || '',
                        })
                      }}>
                        Edit
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>

      <Button className={classNames.reloadButton} onClick={() => { refetch() }}>
        Reload
      </Button>

      <ReactToPrint
        trigger={() => <button className={classNames.printBotton}>Print</button>}
        content={() => componentRef.current}
      />

      {!isLoading && <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>}

    </div>
  );
};
