import React, { useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import classNames from "./superAdmin.module.scss";
import LoginView from "../../components/LoginView";
import DashLayout from "../../layout/DashLayout";
import CollegeList from "../../components/CollegeList";

function SuperAdmin() {
  const auth = getAuth();
  const [logged, setLogged] = useState(false);
  onAuthStateChanged(auth, (user) => {
    if (user?.displayName === "Super Admin") {
      setLogged(true);
    } else {
      setLogged(false);
    }
  });
  return (
    <>
      {logged ? (
        <DashLayout
          footers={[]}
          title="necttos"
          className={classNames.superAdmin}
          superAdmin
        >
          <CollegeList />
        </DashLayout>
      ) : (
        <LoginView />
      )}
    </>
  );
}

export default SuperAdmin;
