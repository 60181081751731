import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import { Field, Formik } from "formik";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import ReactToPrint from "react-to-print";


const getAccounts = (data) => async () => {


  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/fees/getSubsidiaryRegister', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {


      return resp?.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}


export const SubsidiaryRegisterDays = ({ onClose, date }) => {





  let dateNew = new Date(date);

  console.log(date);

  let startDate = moment(dateNew).format('YYYY-MM-DD');
  let endDate = moment(dateNew).format('YYYY-MM-DD');


  const [loading, setLoading] = useState(false);

  const [state, setState] = React.useState({
    filterValue: '',
    page: 0,
  });

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data, refetch: issuedBookRefetch } = useQuery(['pdRegister555', startDate, endDate], getAccounts({ collegeId, startDate, endDate, formate: 'All', setLoading }));

  let issuedData = {}

  const { Option } = Select;

  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <div className={classNames.listContainer2}>

          <Scrollbars style={{ height: '100%' }} >
            <div ref={componentRef} className="printable" style={{ overflow: 'scroll' }}>
              <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                  <img width='8%' height='8%' src={selectedCollege?.logo} />
                  <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                    <span style={{ fontSize: '30px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>

                    <h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Subsidiary Register </h5>

                  </div>
                </div>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 25, marginTop: 4, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>SN</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '15%' }}>Class</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '25%' }}>Name</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>Admn</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Rt.No.</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee2}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee3}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee4}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee5}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee6}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee7}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee8}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee9}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee10}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee11}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee12}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee13}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>TOTAL</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>PAY</h5>
                </div>
                {data?.subsidiaryRegisterData?.map((item, i) => (
                  <>
                    {/* <div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'black', }}></div> */}

                    <div style={{ width: '100%', display: 'flex', height: ' 38px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '5%' }}>{i + 1}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 10, width: '15%' }}>{item?.className}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '25%' }}>{item?.date}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '10%' }}>{item?.countData}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, fontStyle: 'arial', width: '10%' }}>{item?.recieptNumber}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, fontStyle: 'times', width: '8%' }}>{item?.specialFee1}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee2}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee3}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee4}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee5}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee6}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee7}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee8}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee9}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee10}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee11}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee12}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee13}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item.totalAmount}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item.paymentAmount}</h5>
                      <div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    </div>
                    <div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'black' }}></div>

                  </>
                ))}

                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}></h5>
                  <h5 style={{ marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 14, width: '15%' }}></h5>
                  <h5 style={{ marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 14, width: '25%' }}></h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}></h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}></h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal1}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal2}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal3}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal4}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal5}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal6}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal7}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal8}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal9}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal10}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal11}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal12}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal13}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.grandTotal}</h5>
                  <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.grandPayment}</h5>
                </div>
                <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 16 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>




              </div>
            </div>

          </Scrollbars>
        </div >

      </div>

      <div className={classNames.absoluteDiv}  >











        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }} >
            <Field
              type="date"
              name="startDate"
              id="startDate"
              value={state.startDate}
              onChange={(e) => {
                setState({ ...state, startDate: e.target.value, accessionNumber: '', admisionNo: '' });
              }} />
            <label>Start Date</label>
          </div>
        </Formik>
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`} style={{ width: 140 }} >
            <Field
              type="date"
              name="endDate"
              id="endDate"
              value={state.endDate}
              onChange={(e) => {
                setState({ ...state, endDate: e.target.value, accessionNumber: '', admisionNo: '' });
              }} />
            <label>End Date</label>

          </div>
        </Formik>

        <Button className={classNames.reloadButton} disabled={parseInt(state.page) < 1} onClick={() => {
          setState({ ...state, page: (parseInt(state.page) - 1) })
        }}>
          Prev
        </Button>
        <Formik enableReinitialize>
          <div className={`${classNames.group} ${classNames.formHalfWidth}`}>
            <Field
              type="number"
              name="year"
              id="year"
              value={state.page}
              onChange={(e) => {
                setState({ ...state, page: e.target.value })
              }} />
            <label>Page</label>
          </div>
        </Formik>
        <Button className={classNames.reloadButton} disabled={(parseInt(state.page) * 30) > (parseInt(issuedData?.total?.[0]?.total) - 30)} onClick={() => {
          setState({ ...state, page: (parseInt(state.page) + 1) })
        }}>
          Next
        </Button>
        {loading ? <LoadingAnimation customHeight={50} customWidth={50} /> :
          <>
            <span className={classNames.heading}>Showing Result : {parseInt(state.page) * 30} - {(parseInt(state.page) * 30) + 30} out of {issuedData?.total?.[0]?.total} </span>
            <Button className={classNames.reloadButton} onClick={() => {
              issuedBookRefetch()
            }}>
              Load
            </Button>
          </>
        }
        <ReactToPrint
          trigger={() => <button className={classNames.reloadButton}>Print</button>}
          content={() => componentRef.current}
        />



      </div >
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div >
  );
};
