import React, { useContext, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import classNames from "./addBookModal.module.scss";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import Field from "../Components/Field";
import { usePersistentState } from "../../../../../utils";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuid } from "uuid";
import toast, { Toaster } from "react-hot-toast";
import { getAxiosInstance, getAxiosTokenInstance } from "../../../../../utils/axiosInstance";


function SettupQuestionnaire2({ itemData, onCloseModal,questionnaireId }) {
  
  const uploadFile = async (file) => {
    const storage = getStorage();
    let url = "";

    const uploadTask = await ref(storage, `files/${uuid()}/${file.name}`);
    await uploadBytes(uploadTask, file)
    

    return await getDownloadURL(ref(storage, uploadTask));
  };

  const { collegeId,user } = useContext(FirebaseContext);

  const [data, setData] = useState([[]]);

  useEffect(()=>{
    const val = sessionStorage.getItem(questionnaireId)
    if(val){
      setData(JSON.parse(val))
    }
  },[])

  useEffect(()=>{
    if(data){
      sessionStorage.setItem(questionnaireId,JSON.stringify(data))
    }
  },[data])

  

  useEffect(() => {
    console.log(itemData);
  }, [data]);

  return (
    <div className={classNames.addBookModal}>
      <Toaster />
      <div className={classNames.head}>
        <span>Form</span>

        <button onClick={onCloseModal} className={classNames.cancel}>
          Cancel
        </button>
      </div>
      <Scrollbars style={{ padding: 10 }}>
        <div className={classNames.fillForm}>
          {itemData?.questionnaire?.map((item, c) => (
            <div className={classNames.fillForm}>
              <div className="flex">
                <h1 className="underline text-3xl">{item?.question}</h1>
              </div>
              <div className="mt-3">
                {item?.subQuestions?.map((x, k) => (
                  <div className={classNames.div}>
                    <label htmlFor={x}>{x?.subQuestion}</label>
                    <Field
                      type={x?.type}
                      onChange={(e) => {
                        let temp = data;
                        if (!temp[c]) {
                          temp[c] = [];
                        }
                        temp[c][k] = { _id: x?._id, answer: e?.target?.value };
                        setData([...temp]);
                      }}
                      data={data?.[c]?.[k]?.answer}
                      nature={x?.nature}
                      option={x?.option}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          <button
            onClick={async () => {
              let temp = data;
              let completed = false;

              for (const i in itemData?.questionnaire) {
                for (const j in itemData?.questionnaire[i]?.subQuestions) {
                  if (
                    itemData?.questionnaire[i]?.subQuestions[j]?.nature ===
                    "Mandatory"
                  ) {
                    completed = false;
                    console.log(temp?.[i]?.[j]?.answer);
                    if (temp?.[i]?.[j]?.answer) {
                      completed = true;
                    } else {
                      break;
                    }
                  }
                }
              }
              // console.log(completed)
              if (completed) {
                for (let i in temp) {
                  for (let j in temp[i]) {
                    if (temp[i][j]?.answer?.name) {
                      temp[i][j].answer = await uploadFile(temp[i][j]?.answer);
                    }
                  }
                }
                console.log(temp);
                const instance =await getAxiosTokenInstance()
                 instance.post("/college/iqac/naacSurveyDataCollection",{collegeId,questionnaireId,uid:user.uid,answers:temp})
                 sessionStorage.removeItem(questionnaireId)
                 onCloseModal()
              } else {
                toast.error("Please Fill the Mandatory Field");
              }
            }}
            type="submit"
            className="px-3 py-2 self-center"
          >
            Submit
          </button>
        </div>
      </Scrollbars>
    </div>
  );
}

export default SettupQuestionnaire2;
