import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button } from "antd";
import { useMutation, useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { getLibraryCount, getLibraryStatistics } from "../../ApiServices";
import CustomInput from "../../../../myComp/CustomInput";

export const exportPdf = async ({ olddata, setLoading }) => {
  const instance = await getAxiosTokenInstance();
  console.log(olddata);
  let data = {
    ...olddata
  }
  try {
    const resp = await instance.post(`/college/action/getLibrarySelectedDataToExcel`, data, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      setLoading(false)
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    setLoading(false)
    throw new Error(
      error?.response?.data?.message || error.message || "API Error"
    );
  }
};

export const LibraryStatisticsExport = ({ onClose }) => {


  const { collegeId } = useContext(FirebaseContext);

  const [stateData, setStateData] = React.useState({
    tBook: true
  });

  const exportPdfMutation = useMutation(exportPdf);

  const [loading, setLoading] = useState(false);



  const { data: subjectsData = [], } = useQuery(['subjectsData', stateData], getLibraryStatistics({ collegeId, collection: 'bookStocks', key: 'subject', primoryKey: 'true' }))
  const { data: fundData = [], } = useQuery(['fundData1'], getLibraryStatistics({ collegeId, collection: 'bookStocks', key: 'fund', primoryKey: 'true' }))
  const { data: statusData = [], } = useQuery(['statusData2'], getLibraryStatistics({ collegeId, collection: 'bookStocks', key: 'status', primoryKey: 'true' }))
  const { data: categoryData = [], } = useQuery(['categoryData'], getLibraryStatistics({ collegeId, collection: 'bookStocks', key: 'category', primoryKey: 'true' }))



  const { data: languageData = [], } = useQuery(['languageData'], getLibraryStatistics({ collegeId, collection: 'books', key: 'language', primoryKey: 'true' }))
  const { data: typeOfBook = [], } = useQuery(['typeOfBook'], getLibraryStatistics({ collegeId, collection: 'books', key: 'bookType', primoryKey: 'true' }))








  const { data: count = [], } = useQuery(['count', stateData], getLibraryCount({ collegeId, collection: 'bookStocks', stateData }))

  console.log(stateData);




  const onExportExam = async () => {
    setLoading(true)

    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let olddata = {
        collegeId,
        collection: 'bookStocks',
        stateData
      };


      link.download = 'Exam Reg Suport';
      const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      );
      link.click();
      setLoading(false)

      onClose();

    } catch (e) { }
  };










  return (
    <div className={classNames.container}>
      {loading ? <LoadingAnimation dark /> :
        <div className={classNames.modal} >
          <Button className={classNames.closeButton} onClick={onClose}>
            Close
          </Button>

          <label className={classNames.count}>{count}</label>
          <div className={classNames.subModal} >

            {!stateData?.ufootprint &&
              <>
                <label className={classNames.heading}>Type Stacks</label>
                <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                  {typeOfBook.map((categoryKey) => (
                    <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                      <input type="checkbox"
                        style={{ height: 12, width: 12, marginRight: 10 }}
                        defaultChecked={stateData['t' + categoryKey]}
                        onChange={() => setStateData({ ...stateData, ['t' + categoryKey]: !stateData['t' + categoryKey] })}
                      />
                      {categoryKey?.toUpperCase()}
                    </label>
                  ))}
                </div>

              </>}



            <label className={classNames.heading}>Registers</label>
            <div style={{ flexDirection: 'row', textAlign: 'center' }}>
              {['titles', 'active', 'issue', 'renew', 'return', 'footprint'].map((categoryKey) => (
                <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                  <input type="checkbox"
                    style={{ height: 12, width: 12, marginRight: 10 }}
                    defaultChecked={stateData['u' + categoryKey]}
                    onChange={() => setStateData({ ...stateData, ['u' + categoryKey]: !stateData['u' + categoryKey] })}
                  />
                  {categoryKey?.toUpperCase()}
                </label>
              ))}
            </div>












            {stateData.utitles || stateData.urenew || stateData?.ufootprint || stateData.uissue || stateData.ureturn || stateData.uactive ?
              <>
                <label className={classNames.heading}>Date of Invoice</label>
                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', width: '400px' }} >
                  < CustomInput
                    keyData={'xxStartDate'}
                    titleData={'Start Date'}
                    typeData={'date'}
                    stateData={stateData}
                    setStateData={setStateData}
                  />
                  < CustomInput
                    keyData={'xxEndDate'}
                    titleData={'End Date'}
                    typeData={'date'}
                    stateData={stateData}
                    setStateData={setStateData}
                  />
                </div>


                {
                  stateData.utitles && <>
                    <label className={classNames.heading}>Language</label>
                    <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                      {languageData.map((categoryKey) => (
                        <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                          <input type="checkbox"
                            style={{ height: 12, width: 12, marginRight: 10 }}
                            defaultChecked={stateData['l' + categoryKey]}
                            onChange={() => setStateData({ ...stateData, ['l' + categoryKey]: !stateData['l' + categoryKey] })}
                          />
                          {categoryKey}
                        </label>
                      ))}
                    </div>
                  </>
                }
              </>

              :
              <>
                <label className={classNames.heading}>Accession Number</label>
                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', width: '400px' }} >
                  < CustomInput
                    keyData={'zzstartNo'}
                    titleData={'Start Accession Number'}
                    typeData={'number'}
                    stateData={stateData}
                    setStateData={setStateData}
                  />
                  < CustomInput
                    keyData={'zzendNo'}
                    titleData={'End Accession Number'}
                    typeData={'number'}
                    stateData={stateData}
                    setStateData={setStateData}
                  />
                </div>

                <label className={classNames.heading}>Date of Invoice</label>
                <div style={{ flexDirection: 'row', display: 'flex', alignSelf: 'center', width: '400px' }} >
                  < CustomInput
                    keyData={'xxStartDate'}
                    titleData={'Start Date'}
                    typeData={'date'}
                    stateData={stateData}
                    setStateData={setStateData}
                  />
                  < CustomInput
                    keyData={'xxEndDate'}
                    titleData={'End Date'}
                    typeData={'date'}
                    stateData={stateData}
                    setStateData={setStateData}
                  />
                </div>

                <label className={classNames.heading}>Nature of Books</label>
                <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                  {statusData.map((categoryKey) => (
                    <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                      <input type="checkbox"
                        style={{ height: 12, width: 12, marginRight: 10 }}
                        defaultChecked={stateData['s' + categoryKey]}
                        onChange={() => setStateData({ ...stateData, ['s' + categoryKey]: !stateData['s' + categoryKey] })}
                      />
                      {categoryKey?.toUpperCase()}
                    </label>
                  ))}
                </div>

                <label className={classNames.heading}>Nature of Category</label>
                <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                  {categoryData.map((categoryKey) => (
                    <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                      <input type="checkbox"
                        style={{ height: 12, width: 12, marginRight: 10 }}
                        defaultChecked={stateData['c' + categoryKey]}
                        onChange={() => setStateData({ ...stateData, ['c' + categoryKey]: !stateData['c' + categoryKey] })}
                      />
                      {categoryKey?.toUpperCase()}
                    </label>
                  ))}
                </div>






                <label className={classNames.heading}>Types of Fund</label>
                <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                  {fundData.map((categoryKey) => (
                    <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                      <input type="checkbox"
                        style={{ height: 12, width: 12, marginRight: 10 }}
                        defaultChecked={stateData['f' + categoryKey]}
                        onChange={() => setStateData({ ...stateData, ['f' + categoryKey]: !stateData['f' + categoryKey] })}
                      />
                      {categoryKey}
                    </label>
                  ))}
                </div>

                <label className={classNames.heading}>Subjects</label>
                <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                  {subjectsData.map((categoryKey) => (
                    <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                      <input type="checkbox"
                        style={{ height: 12, width: 12, marginRight: 10 }}
                        defaultChecked={stateData['b' + categoryKey]}
                        onChange={() => setStateData({ ...stateData, ['b' + categoryKey]: !stateData['b' + categoryKey] })}
                      />
                      {categoryKey}
                    </label>
                  ))}
                </div>
                <label className={classNames.heading}>Select Field To Export</label>
                <div style={{ flexDirection: 'row', textAlign: 'center' }}>
                  {[
                    'Accession Number',
                    'Call No',
                    'Name of The Book',
                    'Subject',
                    'Status',
                    'ISBN',
                    'Name of Author',
                    'Type',
                    'Name of Author 2',
                    'Type 2',
                    'Name of Author 3',
                    'Type 3',
                    'Edition',
                    'Volume',
                    'Pages',
                    'Name Of Publisher',
                    'Place Of Publication',
                    'Language',
                    'Series',
                    'Contents',
                    'Price',
                    'Discount',
                    'Book Details',
                    'Year Of Publication',
                    'DDC Subject',
                    'Fund',
                    'InvoiceNumber',
                    'date',
                    'Vendor',
                    'Category',
                    'Status'
                  ].map((categoryKey) => (
                    <label style={{ fontSize: 12, fontFamily: 'bold', color: '#004518', margin: 10 }}  >
                      <input type="checkbox"
                        style={{ height: 12, width: 12, marginRight: 10 }}
                        defaultChecked={stateData['y' + categoryKey]}
                        onChange={() => setStateData({ ...stateData, ['y' + categoryKey]: !stateData['y' + categoryKey] })}
                      />
                      {categoryKey}
                    </label>
                  ))}
                </div>
              </>
            }




            <Button className={classNames.reloadButton} onClick={() => { onExportExam() }}>
              Export To Excel
            </Button>

          </div>
        </div>
      }
    </div>
  );
};
