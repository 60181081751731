import React, { useEffect, useRef, useState } from 'react';
import { getAxiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';
import Onboarding from '../Form/Onboarding';
import { useParams } from 'react-router-dom';
import OnboardingDataLose from '../Form/OnboardingDataLose';

interface RegisterProps {
	setLogin: (val: boolean) => void;
	collegeData: {
		collegeName: string;
		lastApplicationNo: string;
		logo: string;
	};
	login?: boolean;
}

interface FilledData {
	mobile: string;
	name: string;
	houseName: string;
	place: string;
	postOffice: string;
	pinCode: string;
	plusTwoDegreeStream: string;
	coursePreference: string;
}

export const getCollegeDetails = async ({ queryKey }: any) => {
	const [_key, collegeId, year, optionalSubjects] = queryKey;

	try {
		const instance = await getAxiosInstance();
		const { data } = await instance.get('/college/admissionProcess/getClasses', {
			params: { collegeId, year },
		});
		if (data?.statusCode === 200) {
			return data.list;
		} else {
			throw new Error(data?.message || 'Failed to fetch college details.');
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

const FYUGPRegister: React.FC<RegisterProps> = ({ setLogin }) => {
	const [isSendOtp, setSendOtp] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
	const [otpFull, setOtpFull] = useState<string>('');
	const [confirm, setConfirm] = useState<string | null>(null);

	const [params, setParams] = useState<{ id: string }>({
		id: '',
	});

	let param: { id: string } = useParams();

	useEffect(() => {
		if (param.id) {
			let newParams = { id: '' };
			if (param.id.toUpperCase() === 'GVC') {
				newParams.id = 'COL-4E56593776BE-41C3-982A-0B6DC20E44A0';
			}
			if (param.id.toUpperCase() === 'smc_SF') {
				newParams.id = 'COL-3FAA19D25E23-4558-AE0C-D89C4D37D418';
			}
			if (param.id.toUpperCase() === 'SMC_AIDED') {
				newParams.id = 'COL-D209492D4FB7-4E07-848E-A317A1BC9196';
			}
			if (param.id.toUpperCase() === 'WMO') {
				newParams.id = 'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0';
			}
			if (param.id.toUpperCase() === 'TKMM') {
				newParams.id = 'COL-9E8AF41AB347-4BD6-8A1D-43D02D39738E';
			}
			setParams(newParams);
		}
	}, [param]);

	const [errror, setError] = useState(null);

	const [filledData, setFilledData] = useState<FilledData>({
		mobile: '',
		name: '',
		houseName: '',
		place: '',
		postOffice: '',
		pinCode: '',
		plusTwoDegreeStream: '',
		coursePreference: '',
	});

	const inputRefs = useRef<HTMLInputElement[]>([]);

	const verifyOtp = async (otp: string) => {
		if (!otp) {
			toast.error('OTP is required');
			return;
		}

		setIsLoading(true);
		try {
			const instance = await getAxiosInstance();
			const response = await instance.post(`/auth/verify-otp`, {
				token: confirm,
				otp,
			});

			const { jwtToken, collegeId, phoneNumber, userName, uid } = response.data;

			if (jwtToken) {
				localStorage.setItem('userToken', jwtToken);
			} else {
				throw new Error('JWT token is missing');
			}

			if (collegeId) {
				localStorage.setItem('collegeId', JSON.stringify(collegeId));
			}

			if (phoneNumber && userName && uid) {
				localStorage.setItem(
					'userDetails',
					JSON.stringify({
						phoneNumber,
						username: userName,
						displayName: userName,
						uid,
					}),
				);
			} else {
				throw new Error('User details are incomplete');
			}

			setLogin(true);
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	const sendOtp = async () => {
		const instance = await getAxiosInstance();
		if (filledData.mobile) {
			instance
				.post(`/auth/sent-otp`, {
					phoneNumber: `+91${filledData.mobile}`,
				})
				.then((response) => {
					setError(null);
					const elem = document.getElementById('loginForm');
					if (elem) {
						elem.style.height = '350px';
						const otp = document.getElementById('otp');
						if (otp) {
							otp.style.display = 'flex';
						}
					}
					let second = 300;
					let intervalId = setInterval(() => {
						second = second - 1;
						setSendOtp(second);
						if (second <= 0) {
							clearInterval(intervalId);
						}
					}, 1000);
					setConfirm(response.data.token);

					toast.success('OTP Sended');
				})
				.catch((err) => {
					setError(err?.response?.data?.message);
					toast.error('Number Already Exist');
				});
		} else {
			toast.error('Please give Phone number');
		}
	};

	const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
		const newOtp = [...otp];
		newOtp[index] = e.target.value;
		setOtp(newOtp);

		if (e.target.value && index < otp.length - 1) {
			inputRefs.current[index + 1]?.focus();
		}

		if (newOtp.every((value) => value.length === 1)) {
			setOtpFull(newOtp.join(''));
			verifyOtp(newOtp.join(''));
		}
	};

	const styleNmae = 'block w-full px-4 py-3 mb-2 text-gray-700 bg-white border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ';

	const [selectAll, setSelectAll] = useState(false);
	const [openQuota, setOpenQuota] = useState(false);
	const [sportsQuota, setSportsQuota] = useState(false);
	const [communityQuota, setCommunityQuota] = useState(false);
	const [managementQuota, setManagementQuota] = useState(false);

	useEffect(() => {
		setOpenQuota(selectAll);
		setSportsQuota(selectAll);
		setCommunityQuota(selectAll);
		setManagementQuota(selectAll);
	}, [selectAll]);

	useEffect(() => {
		if (openQuota && sportsQuota && communityQuota && managementQuota) {
			setSelectAll(true);
		} else if (!openQuota && !sportsQuota && !communityQuota && !managementQuota) {
			setSelectAll(false);
		}
	}, [openQuota, sportsQuota, communityQuota, managementQuota]);

	return (
		<div className='max-w-lg  p-2 bg-[#ffffff] rounded-lg shadow-lg'>
			<h2 className='text-2xl  font-nunito font-bold text-center text-gray-800 '>Login to your FYUGP Account</h2>

			<label htmlFor='mobile' className='block mb-2 text-sm font-nunito font-medium text-gray-700'>
				Phone Number
			</label>
			<input min={1000000000} max={9999999999} id='mobile' type='number' placeholder='Enter your phone number' className={styleNmae} value={filledData.mobile} onChange={(e) => setFilledData({ ...filledData, mobile: e.target.value })} />
			{errror && (
				<>
					<OnboardingDataLose collegeId={params?.id} type={'student'} fyugp={true} phoneNumber={filledData.mobile}></OnboardingDataLose>
				</>
			)}
			<button onClick={sendOtp} disabled={isLoading || isSendOtp > 0} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-600'}`}>
				{isLoading ? 'Sending...' : 'Login'}
			</button>

			<div id='otp' className={`grid grid-cols-6 gap-4 ${isSendOtp > 0 ? 'block' : 'hidden'}`}>
				{otp.map((value, index) => (
					<input key={index} type='text' maxLength={1} className='w-full px-2 py-2 text-center text-gray-700 bg-white border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent' value={value} onChange={(e) => handleInputChange(index, e)} ref={(el) => (inputRefs.current[index] = el as HTMLInputElement)} />
				))}
			</div>
			{isSendOtp > 0 && (
				<>
					<div className='mt-4 text-center'>
						<p className='text-sm text-gray-600'>
							OTP expires in: <span className='font-nunito font-semibold'>{isSendOtp}</span> seconds
						</p>
					</div>
					<button onClick={() => verifyOtp(otpFull)} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-600'}`}>
						Verify OTP
					</button>
				</>
			)}
		</div>
	);
};

export default FYUGPRegister;
