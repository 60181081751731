import React from "react";
import { Form, Input, Button, message } from "antd";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import classNames from "./loginView.module.scss";
import { getAxiosInstance } from "../../utils/axiosInstance";

function LoginView() {
  const instance = getAxiosInstance();
  const auth = getAuth();
  const onFinish = (values) => {
    message.loading({ content: "Checking User...", key: "login" }, 0);
    signOut(auth).then(() => {
      instance
        .post("/necttos/checkAdmin", {
          email: values.username,
        })
        .then(({ data }) => {
          if (data.statusCode === 200) {
            message.loading({ content: "Loging In User...", key: "login" }, 0);
            signInWithEmailAndPassword(auth, values.username, values.password)
              .then(() => {})
              .catch((error) => {
                message.error({ content: error.message, key: "login" });
              });
          }
        });
    });
  };

  const onFinishFailed = (errorInfo) => {
  };
  return (
    <div className={classNames.loginView}>
      <div className={classNames.adminTitle}>Admin Login</div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginView;
