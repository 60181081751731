import React, { useState, useContext } from 'react';
import Select from 'antd/lib/select';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useAllClasses } from '../../queryHooks';
import { Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';

const { Option } = Select;

function InstantTc({ onClose, refetch, setLoading }) {
	const history = useHistory();
	const { collegeId } = useContext(FirebaseContext);
	const [ClsName, setClsName] = useState('');
	const [SemesterfromLeaving, setSemesterfromLeaving] = useState('');
	const [semester] = useState(['l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester']);
	const [reason] = useState(['Course Completed', 'Discontinued', 'College Transfer', 'Higher Studies']);
	const [name, setName] = useState('');
	const [number, setNumber] = useState('');
	const [Exams] = useState(['Not attended', 'l Semester', 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester']);
	const [Catogorys] = useState(['GENERAL', 'OBC', 'OBC (H)', 'OEC', 'ST', 'SC']);
	const [Dob, setDob] = useState('');
	const [DoAdmission, setDoAdmission] = useState('');
	const [DoLeaving, setDoLeaving] = useState('');
	const [RegisteNo, setRegisterNo] = useState('');
	const [Exam, setExam] = useState('');
	const [Catogory, setCatogory] = useState('');
	const [AdNumber, setAdnumber] = useState('');
	const [Streason, setStreason] = useState('');
	const [Letter, setLetter] = useState('');

	const adsUserTc = async () => {
		if (!AdNumber) {
			throw new Error('Admission Number Required');
		}

		setLoading(true);
		const instance = await getAxiosTokenInstance();
		const resp = await instance.post('/college/transferCertificate/instantTCNew', {
			type: 'student',
			collegeId: collegeId,
			fullName: name,
			uid: '',
			phoneNumber: number,
			dob: Dob,
			classId: ClsName,
			doa: DoAdmission,
			category: Catogory,
			admisionNo: AdNumber,
			dol: DoLeaving,
			regNo: RegisteNo,
			requestLetter: Letter,
			reasonForLeaving: Streason,
			exam: Exam,
			leaving: SemesterfromLeaving,
		});

		if (resp.data.statusCode !== 200) {
			setLoading(false);
			throw new Error(resp.data.message || 'API Error');
		} else {
			setLoading(false);
		}
		refetch();
		setLoading(false);
		onClose();
	};

	const allClasses = useAllClasses({ needAll: true, classOnly: true });

	return (
		<Main width='60vw' height='100vh'>
			<div className='bg-white p-8 rounded-lg shadow-lg max-w-4xl mx-auto overflow-y-scroll h-full relative w-full'>
				<div className='absolute right-0 top-0'>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
				<h2 className='text-2xl font-semibold mb-6 text-center'>Instant TC Form</h2>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-full'>
					<div>
						<label className='block mb-2 font-medium'>Class Name</label>
						<Select className='w-full p-2 border rounded-md' value={ClsName} onChange={(e) => setClsName(e)}>
							{allClasses?.data?.map((classes) => (
								<Option key={classes.className} value={classes.classId}>
									{classes.className}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Full Name (as per SSLC)</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Official Mobile Number</label>
						<input type='tel' className='w-full p-2 border rounded-md' placeholder='10 digit Mobile number' value={number} onChange={(e) => setNumber(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Category</label>
						<Select className='w-full p-2 border rounded-md' value={Catogory} onChange={(e) => setCatogory(e)}>
							{Catogorys.map((cat) => (
								<Option key={cat} value={cat}>
									{cat}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Admission Number</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='Admission Number' value={AdNumber} onChange={(e) => setAdnumber(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Date of Admission</label>
						<input type='date' className='w-full p-2 border rounded-md' value={DoAdmission} onChange={(e) => setDoAdmission(e.target.value)} />
					</div>

					<div>
						<label className='block mt-4 mb-2 font-medium'>Register Number</label>
						<input type='text' className='w-full p-2 border rounded-md' placeholder='Ex:- Hallticket Number' value={RegisteNo} onChange={(e) => setRegisterNo(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Date of Birth</label>
						<input type='date' className='w-full p-2 border rounded-md' value={Dob} onChange={(e) => setDob(e.target.value)} />

						<label className='block mb-2 font-medium'>Date of Leaving</label>
						<input type='date' className='w-full p-2 border rounded-md' value={DoLeaving} onChange={(e) => setDoLeaving(e.target.value)} />

						<label className='block mt-4 mb-2 font-medium'>Semester from Leaving</label>
						<Select className='w-full p-2 border rounded-md' value={SemesterfromLeaving} onChange={(e) => setSemesterfromLeaving(e)}>
							{semester.map((sem) => (
								<Option key={sem} value={sem}>
									{sem}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Examination last Appeared</label>
						<Select className='w-full p-2 border rounded-md' value={Exam} onChange={(e) => setExam(e)}>
							{Exams.map((exam) => (
								<Option key={exam} value={exam}>
									{exam}
								</Option>
							))}
						</Select>

						<label className='block mt-4 mb-2 font-medium'>Reason for Leaving</label>
						<Select className='w-full p-2 border rounded-md' value={Streason} onChange={(e) => setStreason(e)}>
							{reason.map((res) => (
								<Option key={res} value={res}>
									{res}
								</Option>
							))}
						</Select>
					</div>

				</div>
					<div>
						<label className='block mb-2 font-medium'>Write a Letter for release TC and CC to the Principal</label>
						<textarea className='w-full p-2 border rounded-md' placeholder='Write your letter here...' value={Letter} onChange={(e) => setLetter(e.target.value)} rows='5' />
					</div>

				<div className='mt-6 flex justify-center'>
					<button className='bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition duration-200' onClick={adsUserTc}>
						Submit
					</button>
				</div>
			</div>
		</Main>
	);
}

export default InstantTc;
