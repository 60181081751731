/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../context/FirebaseContext';
import { getAxiosInstance, getAxiosTokenInstance } from '../utils/axiosInstance';
import Cookies from 'js-cookie';

const getProfileEdit = async ({ queryKey }) => {
	const [_key, keyData, collegeId] = queryKey;
	const instance = await getAxiosTokenInstance();

	const { data } = await instance
		.get('/college/profile/getProfileData', {
			params: { keyData, collegeId },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data.profileData;
	} else {
		throw new Error(data?.message);
	}
};
export const useProfileEdit = (keyData) => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery([`profileDataEdit${keyData}`, keyData, collegeId], getProfileEdit);
	return query;
};

const gerProfileData = async ({ queryKey }) => {
	const [_key, uid, isStaff, collegeId] = queryKey;
	const instance = await getAxiosTokenInstance();
	const { data } = await instance
		.get('/college/profile/get', {
			params: { profileId: uid, groupData: true, isStaff, collegeId },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data.profileData;
	} else {
		throw new Error(data?.message);
	}
};

export const useProfileData = (uid = '', isStaff = false, keyData) => {
	const { collegeId } = useContext(FirebaseContext);

	const query = useQuery([`profileData${uid} ${keyData}`, uid, isStaff, collegeId], gerProfileData);
	return query;
};

const getAllClasses = async ({ queryKey }) => {
	const [_key, collegeId, needAll, classOnly] = queryKey;
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/classes/getClass', {
			params: { collegeId, needAll, classOnly },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data?.list;
	} else {
		throw new Error(data?.message);
	}
};
export const useAllClasses = ({ needAll, classOnly, subId }) => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['allClasses', subId ? subId : collegeId, needAll, classOnly], getAllClasses);
	return query;
};



const getAccountsHead = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/fees/getAccountsHeads', {
			params: { collegeId },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});
	if (data?.statusCode === 200) {
		return data;
	} else {
		throw new Error(data?.message);
	}
};

export const useAccountsHeads = () => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['useAllSubAccounts', collegeId], getAccountsHead);
	return query;
};

const getAllTrancferCertificate = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/requestAprovals/getTcCcRequest', {
			params: { collegeId },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data?.list;
	} else {
		throw new Error(data?.message);
	}
};
export const useAllTrancferCertificate = () => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['allTransferCertificate', collegeId], getAllTrancferCertificate);
	return query;
};

const getAllSubjectsInSemster = async ({ queryKey }) => {
	const [_key, collegeId, classId, semester] = queryKey;
	const instance = getAxiosInstance();
	const { data } = await instance
		.get('/college/classes/getSemWiseSubjects', {
			params: { collegeId, classId, semester },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});
	if (data?.statusCode === 200) {
		return data?.subjectsList;
	} else {
		throw new Error(data?.message);
	}
};
export const useAllSubjectsInSemster = (classId, semester) => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['allClassesSubjects', collegeId, classId, semester], getAllSubjectsInSemster);
	return query;
};

const getStudentAttendance = async ({ queryKey }) => {
	const [_key, studentId, collegeId] = queryKey;

	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/college/attendance/report/studentAttendance', {
		params: { studentId, collegeId },
	});

	if (data.statusCode !== 200) {
		throw new Error(data.message);
	}

	if (data?.attedenceList.length <= 0) {
		throw new Error(`No Attendance data found for ${data?.userData?.name || ''}`);
	}

	return {
		attedenceList: data.attedenceList,
		attenanceReport: data.attenanceReport,
		userData: data.userData,
	};
};
export const useStudentAttendance = (studentId) => {
	const { collegeId, user } = useContext(FirebaseContext);

	const userId = studentId || user?.uid;

	const query = useQuery([`studentAttendance${studentId}`, userId, collegeId], getStudentAttendance);

	return query;
};

const getClassStatistics = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;

	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/college/classes/getClassStatistics', {
		params: { collegeId },
	});

	if (data.statusCode !== 200) {
		throw new Error(data.message);
	}

	return data.list;
};
export const useClassStatistics = () => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['classStatistics', collegeId], getClassStatistics);
	return query;
};

const getPendingStudents = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;
	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/college/admission/getPendingStudents', {
		params: { collegeId },
	});
	return data?.list;
};
export const usePendingStudents = () => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['pendingStudents', collegeId], getPendingStudents);
	return query;
};

const getAllPendingStudents = async ({ queryKey }) => {
	const [_key, collegeId] = queryKey;
	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/college/admission/allPendingAdmission', {
		params: { collegeId },
	});
	return data?.list;
};
export const useAllPendingStudents = () => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery(['allPendingStudents', collegeId], getAllPendingStudents);
	return query;
};

const getDropDowns = async ({ queryKey }) => {
	const [_key, key] = queryKey;
	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/college/action/getDropDowns', {
		params: { key },
	});

	return data?.list;
};

export const useDropDowns = (key) => {
	const query = useQuery(['dropDown', key], getDropDowns);
	return query;
};

const getDropdownData = async () => {
	const instance = await getAxiosTokenInstance();
	const { data } = await instance.get('/dropDown/dropdownList');
	if (data.statusCode !== 200) {
		throw new Error(data.message || 'API Error');
	}
	return data?.dropdownList;
};

export const useDropdownData = () => {
	const query = useQuery(['genderData'], getDropdownData);
	return query;
};

export const userAction = (payLoad) => {
	return new Promise(async (resolve, reject) => {
		const instance = await getAxiosTokenInstance();

		instance
			.post('/college/role/userAction', payLoad)
			.then((d) => {
				if (d.statusCode !== 400) resolve({ resData: 'success' });
				else return reject({ err: 'error' });
			})
			.catch((e) => reject({ err: 'error' }));
	});
};

export const getCollegesReg = () => {
	return new Promise(async (resolve, reject) => {
		const instance = await getAxiosInstance();
		instance
			.get('/registration/getregistration')
			.then((d) => resolve(d.data))
			.catch((e) => reject());
	});
};

export const getDetailstoList = (type, Id) => {
	return new Promise(async (resolve, reject) => {
		const instance = await getAxiosTokenInstance();
		instance
			.get(`/college/action/getClassBycollege?type=${type}&collegeId=${Id}`)
			.then((d) => resolve(d.data?.list))
			.catch((e) => reject());
	});
};

const getDesktopAppLinks = async () => {
	const OWNERS = 'necttos';
	const REPO_NAME = 'necttos-Desktop-App-Releases';
	const { data } = await axios.get(`https://api.github.com/repos/${OWNERS}/${REPO_NAME}/releases/latest`, { headers: { Accept: 'application/vnd.github.v3+json' } });
	return data;
};
export const useDesktopAppLinks = () => {
	const query = useQuery('desktopAppLinks', getDesktopAppLinks);
	return query;
};

export const getProfile = async ({ queryKey }) => {
	const [_key, collegeId, userId] = queryKey;

	console.log({ collegeId, userId });

	try {
		const instance = await getAxiosTokenInstance();
		const { data } = await instance.get('/college/profile/getProfile', {
			params: { collegeId, userId },
		});
		if (data?.statusCode === 200) {
			return data;
		} else if (data.message === "Cannot destructure property 'uid' of 'decodedToken' as it is null.") {
			localStorage.clear();
			Cookies.remove('userToken');
		} else {
			throw new Error(data?.message || 'Failed to fetch profile data.');
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

export const getStudentsFromRoles = async ({ queryKey }) => {
	const [_key, collegeId, selected, type] = queryKey;
	try {
		const instance = await getAxiosTokenInstance();
		const { data } = await instance.get('/college/profile/getStudentsFromRoles', {
			params: { collegeId, selected, type },
		});
		if (data?.statusCode === 200) {
			return data;
		} else if (data.message === "Cannot destructure property 'uid' of 'decodedToken' as it is null.") {
			localStorage.clear();
			Cookies.remove('userToken');
		} else {
			throw new Error(data?.message || 'Failed to fetch profile data.');
		}
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	}
};

const getProfileEditNew = async ({ queryKey }) => {
	const [_key, keyData, collegeId] = queryKey;

	const instance = await getAxiosTokenInstance();

	const { data } = await instance
		.get('/college/profile/getProfileDataByKey', {
			params: { ...keyData, collegeId },
		})
		.catch((error) => {
			throw new Error(error?.response?.data?.message || error.message || 'API Error');
		});

	if (data?.statusCode === 200) {
		return data.profileData;
	} else {
		throw new Error(data?.message);
	}
};
export const useProfileEditNew = (keyData) => {
	const { collegeId } = useContext(FirebaseContext);
	const query = useQuery([`profileDataEditNew${keyData}`, keyData, collegeId], getProfileEditNew);
	return query;
};
