import React, { useContext, useEffect, useRef, useState } from 'react';
import { getDropdownData } from '../../../pages/college/ApiServices';
import Button from '../../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, closeAnimation } from '../../../NecttosComp/Layout/Layout';
import Input from '../../../NecttosComp/Input/Input';
import { useProfileEdit, useProfileEditNew } from '../../../queryHooks';
import { ProgressBar, calculateProfilepPercentage } from '../../../NecttosComp/ProgressBar/ProgressBar';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';
import Loader from '../../../NecttosComp/Loader/Loader';
import { ColorAbbreviationListOfProfile, getProfileUpdateColors, postProfileSubmission } from '../../../FunctionalExport/Formate';
import { FirebaseContext } from '../../../context/FirebaseContext';
import CSVEditor from './csvEditor';
import { getAxiosInstance } from '../../../utils/axiosInstance';
import { Popconfirm } from 'antd';
import { ProfileDataDisplay } from '../../NewProfile';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
}

function CulturalActivies({ onClose, studentId, onNext }: Props) {
	const titleKey = 'culturalActivity';
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [temp, setTemp] = useState<any>();
	const [csv, setCSv] = useState(false);
	const [isTick, setIsTick] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const componentRef = useRef<HTMLTableElement>(null);
	const [mode, setMode] = useState<any>(false);
	const [addNew, setAddNew] = useState<any>({ name: '', type: '', startDate: '', endDate: '' });
	const { user, collegeId } = useContext(FirebaseContext);

	// const { data: profileData, refetch } = useProfileEdit(titleKey);
	const { data: profileData, refetch: refetch } = useProfileEditNew({ titleKey, userId: user?.uid });

	const [values, setValues] = useState<any[]>(profileData);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
		};
		getDropDowns();
	}, []);
	const callProfileSubmission = async (keyData: string) => {
		await postProfileSubmission({
			keyData,
			values,
			temp,
			addNew,
			setIsLoading,
			setTemp,
			setMode,
			setAddNew,
			refetch,
			titleKey,
		});
	};

	const handleUpdate = async (addNew: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/updateByKeyData', {
				_id: addNew._id,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
				newValue: addNew,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleDelete = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.delete('college/profile/deleteByKeyData', {
				data: { _id: data?._id, userId: user?.uid, collegeId, keyData: titleKey },
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};
	const handleUploadCSV = async (data: any) => {
		try {
			const instance = getAxiosInstance();
			const res = await instance.post('college/profile/addByKeyData', {
				data,
				userId: user?.uid,
				collegeId,
				keyData: titleKey,
			});
			console.log(res);
			refetch();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
	}, [profileData]);

	return (
		<>
			{/* <Main title="Cultural Activies" height="90vh" width={"90vw"}> */}
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						<Button type='print' tableRef={componentRef.current} children='Print' />
						<Button type='pdf' pdfId='profilePDF' children='PDF' />
						<Button type='excel' tableRef={componentRef.current} pdfId='profilePDF' children='EXCEL' />
						<Button
							type='doc'
							onClick={() => {
								setCSv(true);
							}}
							children='CSV'
						/>
						<Button type='fetch' onClick={onNext} children='Next' />
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='85%'>
					<div className='w-full  overflow-auto'>
						<div className='w-full float-left p-2'>
							<div className='w-full '>
								<Table ref={componentRef} id={'profilePDF'} width='100%' innerWidth={['25%', '30%', '20%', '10%', '15%']}>
									<Thead>
										<Tr>
											<Th position={0} children='Type' />
											<Th position={1} children='Item' />
											<Th position={2} children='Specialised' />
											<Th position={3} children='Strength' />
											<Th position={4} children='Action' />
										</Tr>
									</Thead>
									<Tbody height='auto'>
										{values?.map((x: any, index: any) => (
											<Tr>
												<Td position={0} index={index}>
													<p>{x?.type}</p>
												</Td>
												<Td position={1} index={index}>
													<p>{x?.item}</p>
												</Td>
												<Td position={2} index={index}>
													<p>{x?.specialised}</p>
												</Td>
												<Td position={3} index={index}>
													<p>{x?.strength}</p>
												</Td>
												<Td position={4} index={index}>
													<div className='flex'>
														<Button
															type='update'
															onClick={() => {
																setMode('edit');
																setAddNew(x);
																setTemp(x);
															}}>
															Edit
														</Button>
														<Popconfirm placement='topRight' title='Are you sure you want to delete?' okText='Yes' cancelText='No' onConfirm={() => handleDelete(x)}>
															<Button type='delete'>Delete</Button>
														</Popconfirm>
													</div>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>

							<Button
								onClick={async () => {
									setMode(!mode);
									setTemp({});
								}}
								type='update'>
								Add New Skill
							</Button>
						</div>
					</div>
				</Body>
				<Footer width='100%' />
			</div>
			{/* </Main> */}

			{mode && (
				<Main>
					<Header width='100%' height='6%' title='Profile'>
						<div className='flex flex-wrap items-center'></div>
						<div className='flex flex-wrap windows-center'>
							<Button
								type='close'
								onClick={() => {
									closeAnimation();
									setTimeout(() => setMode(false), 250);
								}}>
								Close
							</Button>
						</div>
					</Header>
					<Body>
						<div className='grid grid-flow-col p-2'>
							{['delete', 'edit'].includes(mode) && (
								<div className=' aspect-[2/1] p-5 rounded'>
									<ProfileDataDisplay temp={temp} />

									<div className='flex float-right items-center my-1'>
										{mode === 'delete' && (
											<Button
												type='delete'
												onClick={() => {
													callProfileSubmission('delete');
												}}>
												Delete
											</Button>
										)}
									</div>
								</div>
							)}
							{mode !== 'delete' && (
								<div id='scrollBarEnable' className='px-3 py-2 w-[28vw] z-50 overflow-auto  max-h-[65vh]'>
									<h5 className='text-blue-900  font-bold'>You can edit your data here !</h5>
									<div className='relative z-[3]'>
										<Input width='98%' fieldName='Type' options={dropDowns[5]?.culturalActivities[0]?.culturalActivitiesList} returnKey='type' setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[2]'>
										<Input width='98%' fieldName='Item' returnKey='item' options={dropDowns[5]?.culturalActivities[0]?.allItem} setState={setAddNew} state={addNew} type='drop' />
									</div>
									<div className='relative z-[1]'>
										<Input width='98%' fieldName='Strength' returnKey='strength' options={dropDowns[5].culturalActivities[0].strength} setState={setAddNew} state={addNew} type='drop' />
									</div>
									<Input width='98%' fieldName='Specialised' returnKey='specialised' setState={setAddNew} state={addNew} type='text' />
									<ProgressBar progress={parseInt(calculateProfilepPercentage(addNew, { type: '', item: '', strength: '', specialised: '' })?.percentage)} />
									{!isLoading && (
										<Button
											width='100%'
											onClick={async () => {
												handleUpdate(addNew);
												closeAnimation();
												setTimeout(() => setMode(false), 250);
											}}
											type='save'
											children='Save'
										/>
									)}
								</div>
							)}
						</div>
					</Body>
				</Main>
			)}
			{csv && (
				<CSVEditor
					titleKey={titleKey}
					keysNeeded={['type', 'item', 'strength', 'specialised']}
					specific
					setCsv={setCSv}
					returnData={async (data) => {
						if (data) {
							console.log(data);
							let val = [...values];
							setIsTick(false);
							await handleUploadCSV(data);
							setIsTick(true);
							setTimeout(() => {
								setIsTick(null);
								setCSv(false);
							}, 2000);
						}
					}}
				/>
			)}
			{/* {isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null} */}
			{isLoading && <Loader />}
		</>
	);
}

export default CulturalActivies;
