import React, { useContext, useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";
import { useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import { Body, Footer, Header, Main, closeAnimation } from "../../../../../NecttosComp/Layout/Layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../../NecttosComp/Table/Table";
import Button from "../../../../../NecttosComp/Button/Button";

import Loader from "../../../../../NecttosComp/Loaders/Loader";
import { AcademicYears } from "../../../ApiServices";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const naacReportGeneration = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post("/college/iqac/customeQuestionairResponds", data);
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const DataSheet = ({ onClose, questionnaireId, titleOfHead }) => {
  const [academicYear, setAcademicYear] = useState("2022-23");
  const [mode, setMode] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const [copySuccess, setCopySuccess] = useState("");
  const [answers, setAnswers] = useState([]);
  const [values, setValues] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [subQn, setSubQn] = useState([]);
  const componentRef = useRef(null);

  const { data: filteredData = [] } = useQuery(["customeQuestionairResponds", academicYear], naacReportGeneration({ collegeId, questionnaireId, academicYear }));

  useEffect(() => {
    setValues(filteredData.list);
    console.log(filteredData);
  }, [filteredData]);

  useEffect(() => {
    let subStoreQn = [];
    for (let i in values?.dataQuestion?.questionnaire) {
      const elem = values?.dataQuestion?.questionnaire[i];
      for (let j in elem.subQuestions) {
        subStoreQn.push(elem?.subQuestions[j]);
      }
    }
    setSubQn([...subStoreQn]);
    subStoreQn = [];
    for (let i in values?.dataNaac) {
      let ans = [];
      const elem = values?.dataNaac[i];
      for (let j in elem?.answers) {
        const elem2 = elem?.answers[j];
        for (let k in elem2) {
          ans.push(elem2[k]);
        }
      }
      subStoreQn.push([...ans]);
    }
    console.log(subStoreQn);
    setAnswers([...subStoreQn]);
  }, [values]);

  return (
    <>
      <Main height="auto" width="atuo" title={titleOfHead}>
        <Header>
          <div className="flex flex-wrap items-center z-50">
            <AcademicYears setAcademicYear={setAcademicYear} />
            <Button
              type="fetch"
              onClick={() => {
                setMode(true);
              }}
              children="Filled Details"
            />
          </div>
          <div className="flex flex-wrap windows-center">
            <Button type="print" tableRef={componentRef.current} children="Print" />
            <Button type="pdf" pdfId="profilePDF" children="PDF" />
            <Button type="excel" fileName={titleOfHead + " - " + academicYear} tableRef={componentRef.current} pdfId="profilePDF" children="EXCEL" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body>
          <div className="overflow-auto">
            <Table width={subQn?.length * 200 + "px"} ref={componentRef} id={"profilePDF"}>
              <Thead>
                <Tr>
                  {values?.dataQuestion?.questionnaire?.map((x) => (
                    <Th width={x.subQuestions?.length * 200 + "px"} position={0}>
                      {x?.question}
                    </Th>
                  ))}
                </Tr>
                <Tr>
                  {subQn?.map((x) => (
                    <Th width="200px">{x?.subQuestion}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody height="65vh">
                {values?.dataNaac?.map((x, index) => (
                  <Tr>
                    {subQn?.map((x, i) => (
                      <Td index={index} width="200px">
                        {x?.type === "Period" ? (
                          <div className="w-[150px]">
                            <div className="w-[150px]">
                              <div className="flex">From: {answers?.[index]?.[i]?.answer?.from?.toString()}</div>
                              <div className="flex">To: {answers?.[index]?.[i]?.answer?.to?.toString()}</div>
                            </div>
                          </div>
                        ) : x?.type === "Image" ? (
                          <img src={answers?.[index]?.[i]?.answer} height={200} alt="No File" srcset="" />
                        ) : x?.type === "PDF" ? (
                          <p>{answers?.[index]?.[i]?.answer}</p>
                        ) : (
                          <p>{answers?.[index]?.[i]?.answer}</p>
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        <Footer />
      </Main>
      {mode && (
        <Main>
          <Header width="100%" height="6%" title="Profile">
            <div className="flex flex-wrap items-center">
              <Button type="fetch">Copy Data to Paste into WhatsApp</Button>
              {copySuccess && <p className="text-green-900 text-2xl pt-4">{copySuccess}</p>}
            </div>
            <div className="flex flex-wrap windows-center">
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setMode(false), 250);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div className="grid grid-flow-col p-2 bg-slate-300 text-lg"></div>
          </Body>
        </Main>
      )}
      {isLoading ? <Loader loading={isLoading} /> : null}
    </>
  );
};
