import React, { useContext, useEffect, useRef, useState } from "react";

import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import moment from "moment";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Input from "../../../../NecttosComp/Input/Input";
import Button from "../../../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../../../NecttosComp/Table/Table";
import ReactToPrint from "react-to-print";
import FineTransfer from "./FineTransfer";
import Edit from "./Edit";
const getFieldDropDowns = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/library/getClassesLibrary", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.classesData;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const getAllMembers = (data) => async () => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("/college/library/getAllMembers", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.membersList;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};
const getMemberBooksHistory = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/library/getMemberBooksHistory", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.memberHistory;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const Members = ({ onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const [state, setState] = React.useState({});
  const [edit, setEdit] = useState(false);
  const [fineTransfer, setFineTransfer] = useState(false);
  const [dataPassingToEditPage, setDataPassingToEditPage] = useState({ tookOn: "", returnedOn: "" });
  const [libraryBlocked, setLibraryBlocked] = useState(false);

  const toggleLibraryAccess = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      let resp = await instance.post("/college/action/postLibraryAccess", {
        collegeId,
        userId: state.userId,
        libraryBlocked: !libraryBlocked,
      });

      if (resp.data.statusCode && resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setLibraryBlocked(!libraryBlocked);
      }
    } catch (error) {
      console.error("Failed to toggle library access:", error.message);
      alert(`Error: ${error.message}`);
    }
  };

  const componentRef = useRef();

  const { data = [] } = useQuery(["opacData"], getFieldDropDowns({ collegeId }), { keepPreviousData: true });
  const { data: allMembers = [] } = useQuery(["members", libraryBlocked, state?.classDetails?.classId, state.searchValue], getAllMembers({ collegeId, classId: state?.classDetails?.classId, searchValue: state.searchValue }), { keepPreviousData: true });
  const { data: memberBookHistory = [], refetch } = useQuery(["memberBookHistory", state.userId, state.startDate, state.endDate], getMemberBooksHistory({ collegeId, userId: state.userId, startDate: state.startDate, endDate: state.endDate }), { keepPreviousData: true });

  useEffect(() => {
    setLibraryBlocked(memberBookHistory.libraryBlocked);
  }, [memberBookHistory.libraryBlocked]);

  const [loading, setLoading] = useState(false);

  const { tableItems, requestSort, getIcon } = useSortableData(allMembers);

  return (
    <>
      <Main width="auto" hight="auto" title="Members Register">
        <Header>
          <div className="flex items-center">
            <Input fieldName="Select Class" valueShowKey="className" setState={setState} state={state} returnKey="classDetails" didntShowKey optionDisplay="className" options={data} type="drop" width="15vw" />
            <Input width="25vw" fieldName="Search includes keys title author" returnKey="searchValue" state={state} setState={setState} type="text" />

            <Input width="18vw" type="date" state={state} fieldName="Start Date" returnKey="startDate" setState={setState} />
            <Input width="18vw" type="date" state={state} returnKey="endDate" fieldName="End Date" setState={setState} />
            <Button type="fetch" onClick={() => refetch()}>
              Reload
            </Button>
            <ReactToPrint trigger={() => <button>Print</button>} content={() => componentRef.current} />
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          {fineTransfer ? (
            <div>
              <div className="cover"></div>{" "}
              <div className="instTc">
                <FineTransfer data={dataPassingToEditPage} />
                <button className="closes" onClick={() => setFineTransfer(!fineTransfer)}>
                  X
                </button>
              </div>
            </div>
          ) : null}
          {edit ? (
            <div>
              <div className="cover"></div>{" "}
              <div className="instTc">
                <Edit data={dataPassingToEditPage} />
                <button className="closes" onClick={() => setEdit(!edit)}>
                  X
                </button>
              </div>
            </div>
          ) : null}
          {state.userId && (
            <div className="flex w-full h-48 bg-white rounded-2xl shadow-xl overflow-hidden">
              <div className="w-1/4 flex flex-col justify-center items-center p-4 space-y-3">
                <img src={memberBookHistory?.photoURL || "path/to/default/avatar.png"} alt="Profile" className="w-32 h-32 rounded-full border-4 border-gray-300 shadow-lg" />
                <button onClick={toggleLibraryAccess} className={`text-sm font-semibold py-2 px-4 rounded-full shadow ${!libraryBlocked ? "bg-green-500 hover:bg-green-600 text-white" : "bg-red-500 hover:bg-red-600 text-white"} transition ease-in-out duration-200`}>
                  {!libraryBlocked ? "Block Access" : "Unblock Access"}
                </button>
              </div>

              <div className="flex-grow p-4">
                <div className="grid grid-cols-3 gap-4 text-black">
                  {[
                    { label: "Name", value: memberBookHistory.name, icon: "user" },
                    { label: "Class", value: memberBookHistory.className, icon: "chalkboard" },
                    { label: "Department", value: memberBookHistory.departmentName, icon: "building" },
                    { label: "M. No.", value: memberBookHistory.admisionNo, icon: "id-card" },
                    { label: "Email", value: memberBookHistory.email, icon: "envelope" },
                    { label: "Ad. Date", value: moment(new Date(memberBookHistory.dateOfAdmission)).format("dddd, MMMM D, YYYY"), icon: "calendar-alt" },
                    { label: "Address", value: memberBookHistory.address, icon: "home" },
                    { label: "Phone", value: memberBookHistory.phoneNumber, icon: "phone" },
                    { label: "Status", value: memberBookHistory.activeStatus, icon: "info-circle" },
                  ].map((item, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <i className={`fas fa-${item.icon} text-xl text-gray-700`}></i>
                      <div>
                        <div className="text-xs font-semibold">{item.label}</div>
                        <div className="text-sm">{item.value}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="flex row overflow-scroll">
            <div style={{ width: "30vw", hight: "auto" }}>
              <Table id={"accounts"} width={"95%"} innerWidth={["10%", "50%", "20%", "20%"]}>
                <Thead>
                  <Tr>
                    <Th position={0} item={"Sn"} />
                    <Th position={1} onClick={() => requestSort("userName")} icon={getIcon("userName")} item={"Name"} />
                    <Th position={2} onClick={() => requestSort("className")} icon={getIcon("className")} item={"Class"} />
                    <Th position={3} onClick={() => requestSort("status")} icon={getIcon("status")} item={"Status"} />
                    <Th position={3} onClick={() => requestSort("libraryBlocked")} icon={getIcon("libraryBlocked")} item={"Library Blocked"} />
                  </Tr>
                </Thead>
                <Tbody height={"50vh"}>
                  {tableItems?.map((item, i) => (
                    <Tr
                      key={i}
                      onClick={() => {
                        setState({ ...state, userId: item.userId });
                        refetch();
                      }}
                      style={{ cursor: "pointer", backgroundColor: item.status === "active" ? "white" : "#ff8f87" }}
                    >
                      <Td position={0} index={i} item={i + 1} />
                      <Td position={1} textAlign={"left"} index={i} item={item.userName} />
                      <Td position={2} textAlign={"right"} index={i} item={item.className} />
                      <Td position={3} textAlign={"right"} index={i} item={item.status || "--"} />
                      <Td position={4} textAlign={"right"} index={i} item={item.libraryBlocked || "--"} />
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>

            <div style={{ width: "70%" }}>
              <div ref={componentRef} className="printable">
                <img style={{ position: "absolute", width: "80%", marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
                  <div style={{ width: "100%", display: "flex", flexDirection: "row", borderRadius: "25px" }}>
                    <img width="12%" height="12%" src={selectedCollege?.logo} />
                    <div style={{ width: "80%", alignItems: "center", margin: 0, textAlign: "center" }}>
                      <span style={{ fontSize: "30px", margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
                      <p style={{ fontSize: "25px", margin: 0, fontWeight: 900 }}>{selectedCollege?.accreditedGrade}</p>
                      <p style={{ fontSize: "14px", margin: 0, fontWeight: 900 }}>
                        {selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}
                      </p>
                    </div>
                  </div>
                  <h3 style={{ marginTop: 12, textAlign: "left", color: "black", fontSize: 20 }}>Account Holder : {memberBookHistory.name + "   " + "[" + (memberBookHistory.className || memberBookHistory.departmentName) + "]" + (memberBookHistory.admisionNo ? "[" + memberBookHistory.admisionNo + "]" : "")} </h3>
                  <div style={{ width: "100%", display: "flex", flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "5%" }}>Sn</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "center", fontSize: 10, width: "10%" }}>Acc No</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 10, width: "45%" }}>Title</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 10, width: "15%" }}>Author</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "9%" }}>Is. On</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "9%" }}>Rt. On</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "5%" }}>Days</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "5%" }}>Fine</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "5%" }}>H.Days</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 10, width: "5%" }}>options</h5>
                  </div>

                  {memberBookHistory.history?.map((item, i) => (
                    <>
                      <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignSelf: "center", backgroundColor: item.status === "renew" ? "#c0faeb" : "white" }}>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 14, width: "5%" }}>{i + 1}</h5>
                        <h5 style={{ marginRight: 4, color: "black", alignSelf: "center", fontSize: 14, width: "10%" }}>{item.accessionNumber}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "45%" }}>{item.nameOfBook}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "15%" }}>{item.nameOfAuthor}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 10, width: "9%" }}>{moment(new Date(item?.tookOn)).format("DD MM YY")}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 10, width: "9%" }}>{item?.returnedOn ? moment(new Date(item?.returnedOn)).format("DD MM YY") : ""}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 10, width: "5%" }}>{item.days}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 10, width: "5%" }}>{item.fine}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 10, width: "5%" }}>{item?.holidaysReport?.daysOff}</h5>
                        <button
                          className="editBtn"
                          style={{ fontSize: "12px" }}
                          onClick={() => {
                            setEdit(!edit);
                            setDataPassingToEditPage({ tookOn: item?.tookOn, returnedOn: item?.returnedOn, userId: state.userId, bookId: item?._id });
                          }}
                        >
                          View
                        </button>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          height: 1,
                          backgroundColor: "black",
                        }}
                      ></div>
                    </>
                  ))}
                  <h3 style={{ marginTop: 12, textAlign: "right", color: "black", fontSize: 40 }}>Total Fine = {memberBookHistory.totalFine}</h3>

                  <button
                    className="editBtn"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      setFineTransfer(!fineTransfer);
                      setDataPassingToEditPage({ userId: state.userId });
                    }}
                  >
                    Fine Transfer
                  </button>
                  <h5 style={{ marginTop: 12, textAlign: "right", color: "black", fontSize: 10 }}>Seal & Signature {moment(new Date()).format("DD MMM YYYY hh:mm A")}</h5>
                </div>
              </div>
            </div>
          </div>
        </Body>
      </Main>
    </>
  );
};
