import { Field, Formik } from "formik";
import React, { useContext, useRef, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import message from "antd/lib/message";
import Select from "antd/lib/select";
import { useMutation, useQuery } from "react-query";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import classNames from "./addBookModal.module.scss";
import bookCoverPlaceholder from "../../../../../static/images/placeholders/bookCoverPlaceholder.png";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import SearchableImput from "./DropDownComponent";


const getDDCSubjects = (collegeId, key) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/action/getDDCSubjects', {
      params: { collegeId, key }
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

const getSubjects = (collegeId, key) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/action/getSubjects', {
      params: { collegeId, key }
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}

































function EditBook({ onSuccess, stockId, onCloseModal, editableData, refectOrgin }) {
  const { Option } = Select;
  const { collegeId } = useContext(FirebaseContext);



  const [showList, setShowList] = useState(false);
  const [showIsbnList, setShowIsbnList] = useState(false);



  const [searchBook, setSearch] = useState("");
  const [isbnVal, setIsbn] = useState("");
  const [stateData, setStateData] = React.useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleISBNSearch = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      const resp = await instance.get(`/college/library/getBookDetails?stockId=${stockId}`);
      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
      if (resp.data.statusCode === 200) {
        console.log({ resp });
        setStateData(resp.data.bookDetails)
        setSearch(resp.data.bookDetail && resp.data.bookDetail.nameOfBook)
        setIsbn(resp.data.bookDetail && resp.data.bookDetail.isbn)
      }
    } catch (err) {
      console.log(err);
      message.error("No books found");

    }
  }

  const halndeDelete = async () => {
    try {

      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/libraryOld/deleteBook", {
        collegeId,
        stockId
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        onCloseModal()
        refectOrgin()
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };




  const { data: subjects = [], } = useQuery(['SubjectDrops'], getSubjects(collegeId), { keepPreviousData: true })

  const { data: ddcDrops = [], } = useQuery(['ddcDrops', stateData?.subject], getDDCSubjects(collegeId, stateData?.subject), { keepPreviousData: true })











  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setStateData({ ...stateData, image: file, previewUrl: url });
    }
  };







  const manageSubject = async () => {
    try {
      setIsLoading(true);
      let newImage = stateData?.image;
      const instance = await getAxiosTokenInstance();


      if (newImage instanceof File) {
        const storage = getStorage();

        const logoImagesRef = ref(storage, `books/smc/${stateData?.bookId}/coverPhoto.png`);
        const buffer = await newImage.arrayBuffer();

        await uploadBytes(logoImagesRef, buffer, {
          contentType: "image/png",
        });


        newImage = await getDownloadURL(logoImagesRef);
        setStateData({ ...stateData, image: newImage });
      }
      let resp;
      resp = await instance.post("/college/libraryOld/editBook", { collegeId, ...stateData, image: newImage });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        onSuccess();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  };







  return (
    <>
      {isLoading ? (
        <div className={classNames.loading}>
          <LoadingAnimation />
        </div>
      ) : (
        <div className={classNames.addBookModal}>
          <div className={classNames.head}>
            <span>Edit Book</span>
            <button onClick={() => { handleISBNSearch() }} className={classNames.load} >Load Selected Book Data </button>
            <button onClick={onCloseModal} className={classNames.cancel} >Cancel</button>
            <button onClick={() => { halndeDelete() }} className={classNames.delete} >Delete</button>

          </div>
          <Scrollbars className={classNames.content}>
            <Formik

              enableReinitialize
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>

                  <div style={{ flexDirection: 'row', display: 'flex' }}>

                    <div className={classNames.titlesSub} style={{ width: 400 }} >
                      < SearchableImput
                        keyData={'isbn'}
                        titleData={'ISBN'}
                        collection={'books'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      < SearchableImput
                        keyData={'nameOfBook'}
                        titleData={'Title of Book'}
                        collection={'books'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <div style={{ width: 300 }}>
                          < SearchableImput
                            keyData={'nameOfAuthor'}
                            titleData={'First Author'}
                            collection={'books'}
                            stateData={stateData}
                            setStateData={setStateData}
                          />
                        </div>
                        <div style={{ width: 150 }}>
                          < SearchableImput
                            keyData={'authorType'}
                            titleData={'Type'}
                            collection={'books'}
                            stateData={stateData}
                            setStateData={setStateData}
                          />

                        </div>
                      </div>
                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <div style={{ width: 300 }}>
                          < SearchableImput
                            keyData={'nameOfAuthor2'}
                            titleData={'Second Author'}
                            collection={'books'}
                            stateData={stateData}
                            setStateData={setStateData}
                          />
                        </div>
                        <div style={{ width: 150 }}>
                          < SearchableImput
                            keyData={'authorType2'}
                            titleData={'Type'}
                            collection={'books'}
                            stateData={stateData}
                            setStateData={setStateData}
                          />

                        </div>
                      </div>

                      <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <div style={{ width: 300 }}>
                          < SearchableImput
                            keyData={'nameOfAuthor3'}
                            titleData={'Third Author'}
                            collection={'books'}
                            stateData={stateData}
                            setStateData={setStateData}
                          />
                        </div>
                        <div style={{ width: 150 }}>
                          < SearchableImput
                            keyData={'authorType3'}
                            titleData={'Type'}
                            collection={'books'}
                            stateData={stateData}
                            setStateData={setStateData}
                          />

                        </div>
                      </div>
                      <div className={classNames.group} >
                        <Select
                          showSearch
                          className={classNames.formFullWidth2}
                          style={{ overflow: "hidden" }}
                          value={stateData?.subject}

                          onChange={(val) => {
                            setStateData({ ...stateData, subject: val, });
                          }}
                        >
                          {subjects?.map((item, i) => (
                            <Option key={i} value={item}>
                              <div>
                                {item}
                              </div>
                            </Option>
                          ))}
                        </Select>
                        <label>Subject</label>
                      </div>
                      <div className={classNames.group} >
                        <Select
                          showSearch
                          className={classNames.formFullWidth2}
                          style={{ overflow: "hidden" }}
                          value={stateData?.ddcSubject}
                          onChange={(val) => {
                            setStateData({ ...stateData, ddcSubject: val, ddcNumber: ddcDrops?.find((x) => x?.ddcSubject === val)?.ddcNumber, callNo: (ddcDrops?.find((x) => x?.ddcSubject === val)?.ddcNumber) + ' ' + stateData?.nameOfAuthor?.toUpperCase()?.slice(0, 3) + '/' + stateData?.nameOfBook?.toUpperCase()?.slice(0, 1) });
                          }}
                        >
                          {ddcDrops?.map((item, i) => (
                            <Option key={i} value={item?.ddcSubject}>
                              {item?.ddcNumber + ' - ' + item?.ddcSubject}
                            </Option>
                          ))}
                        </Select>
                        <label>DDC subject</label>

                      </div>
                      < SearchableImput
                        keyData={'nameOfPublisher'}
                        titleData={'Name of Publisher'}
                        collection={'books'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      < SearchableImput
                        keyData={'placeOfPublication'}
                        titleData={'Place of Publication'}
                        collection={'books'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      <div className={classNames.group} >
                        <Field
                          type="number"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.yearOfPublication}
                          onChange={(e) =>
                            setStateData({ ...stateData, yearOfPublication: e.target.value })
                          }
                        />
                        <label>Year of Publication</label>

                      </div>
                    </div>

                    <div className={classNames.titlesSub} style={{ width: 250 }}>

                      <div className={classNames.group}>
                        <Field
                          type="text"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.callNo}
                          onChange={(e) =>
                            setStateData({ ...stateData, callNo: e.target.value })
                          }
                        />
                        <label>Call Number</label>

                      </div>

                      <div className={classNames.group}>
                        <Field
                          type="text"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.edition}
                          onChange={(e) =>
                            setStateData({ ...stateData, edition: e.target.value })
                          }
                        />
                        <label>Edition</label>
                      </div>

                      <div className={classNames.group}>
                        <Field
                          type="text"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.volume}
                          onChange={(e) => setStateData({ ...stateData, volume: e.target.value })}
                        />
                        <label>Volume</label>
                      </div>

                      <div className={classNames.group}>
                        <Field
                          type="text"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.series}
                          onChange={(e) =>
                            setStateData({ ...stateData, series: e.target.value })
                          }
                        />
                        <label>Series</label>

                      </div>

                      <div className={classNames.group} >
                        <Field
                          type="number"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.pages}
                          onChange={(e) =>
                            setStateData({ ...stateData, pages: e.target.value })
                          }
                        />
                        <label>Pages</label>

                      </div>

                      <div className={classNames.group}>
                        <Field
                          type="number"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.price}
                          onChange={(e) =>
                            setStateData({ ...stateData, price: e.target.value })
                          }
                        />
                        <label>Actual Price</label>

                      </div>
                      <div className={classNames.group}>
                        <Field
                          type="number"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.discount}
                          onChange={(e) =>
                            setStateData({ ...stateData, discount: e.target.value })
                          }
                        />
                        <label>Discount Price</label>

                      </div>
                      <div className={classNames.group}>
                        <Field
                          type="text"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.invoiceNumber}
                          onChange={(e) =>
                            setStateData({ ...stateData, invoiceNumber: e.target.value })
                          }
                        />
                        <label>Invoice Number</label>

                      </div>
                      <div className={classNames.group}>
                        <Field
                          type="date"
                          onKeyPress={e => { e.which === 13 && e.preventDefault() }}
                          value={stateData?.date}
                          onChange={(e) =>
                            setStateData({ ...stateData, date: e.target.value })
                          }
                        />
                        <label>Invoice Date</label>
                      </div>
                      < SearchableImput
                        keyData={'vendor'}
                        titleData={'Vendor'}
                        collection={'bookStocks'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      < SearchableImput
                        keyData={'fund'}
                        titleData={'Fund'}
                        collection={'bookStocks'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                    </div>

                    <div className={classNames.titlesSub} style={{ width: 230 }}>
                      < SearchableImput
                        keyData={'category'}
                        titleData={'Category'}
                        collection={'bookStocks'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      < SearchableImput
                        keyData={'language'}
                        titleData={'Language'}
                        collection={'books'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />
                      <div className={classNames.group} style={{ width: 200 }}>
                        <Field
                          type="number"
                          value={stateData?.accessionNumber}
                          onChange={(e) =>
                            setStateData({ ...stateData, accessionNumber: e.target.value })
                          }
                        />

                        <label>Accession Number</label>

                      </div>




                      < SearchableImput
                        keyData={'status'}
                        titleData={'Status'}
                        collection={'bookStocks'}
                        stateData={stateData}
                        setStateData={setStateData}
                      />


                      <div className={classNames.group} style={{ width: 200, height: 260, borderRadius: '20px' }}>
                        <label>Contents</label>
                        <textarea
                          value={stateData?.contents}
                          style={{ width: 200, height: 230 }}
                          onChange={(e) =>
                            setStateData({ ...stateData, contents: e.target.value })
                          }
                        />


                      </div>


                      <div>
                        <div className={classNames.group} style={{ width: 200, height: 70 }}>
                          <label>Remarks</label>
                          <textarea
                            value={stateData?.bookDetails}
                            onChange={(e) =>
                              setStateData({ ...stateData, bookDetails: e.target.value })
                            }
                          />

                        </div>
                      </div>

                    </div>

                    <div style={{ width: 150 }}>
                      <label className={classNames.imgWrap}>

                        <input
                          type="file"
                          style={{ fontSize: 20, fontWeight: 900, borderRadius: 20, height: 60, margin: 5, padding: 12 }}

                          onChange={handleFileChange}
                          accept="image/png"
                        />
                        <img src={stateData.previewUrl ? stateData.previewUrl : stateData.image} alt="Uploaded Preview" width="200" />
                      </label>
                      <div style={{ width: 260, height: 200 }}>
                        <button
                          onClick={() => { manageSubject() }}

                          style={{ fontSize: 20, backgroundColor: 'green', color: 'white', fontWeight: 900, width: 200, borderRadius: 20, height: 60, margin: 5, padding: 12 }}


                          className={classNames.add}
                        >Save</button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </Scrollbars>

        </div>

      )
      }

    </>
  );
}

export default EditBook;
