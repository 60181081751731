import React from 'react';
import Navbar from './navbar';
import PageHeader from './page-header';
import TestimonialV2 from './testimonial';
import Pricing from './pricing-page';
import Subscribe from './subscribe';
import FooterBar from './footerBar';

const PricingPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Pricing" />
        <Pricing customclass="pd-top-112" />
        <TestimonialV2 />
        <Subscribe />
        <FooterBar />
    </div>
}

export default PricingPage

