import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import { Field, Formik } from "formik";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import { getActiveBook, getBookWithMember } from "../../ApiServices";
import moment from "moment";
import ReactToPrint from "react-to-print";

export const ActiveRegister = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const [state, setState] = React.useState({
    filterValue: '',
    page: 0,
  });

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data: activeBook, refetch: activeBookRefetch } = useQuery(['activeBookData'], getActiveBook({ collegeId, state, setLoading }));


  let issuedData = activeBook?.issuedData || {};


  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
       
        <div className={classNames.listContainer2}>
          <div className={classNames.headerPanel}>
            <div className={classNames.paginationController}>
              <Button className={classNames.reloadButton} disabled={parseInt(state.page) < 1} onClick={() => {
                setState({ ...state, page: (parseInt(state.page) - 1) })
              }}>
                Prev
              </Button>
              <Formik enableReinitialize>
                <div className={`${classNames.group} ${classNames.formHalfWidth}`}>
                  <Field
                    type="number"
                    name="year"
                    id="year"
                    value={state.page}
                    onChange={(e) => {
                      setState({ ...state, page: e.target.value })
                    }} />
                  <label>Page</label>
                </div>
              </Formik>
              <Button className={classNames.reloadButton} disabled={(parseInt(state.page) * 30) > (parseInt(issuedData?.total?.[0]?.total) - 30)} onClick={() => {
                setState({ ...state, page: (parseInt(state.page) + 1) })
              }}>
                Next
              </Button>
              {loading ? <LoadingAnimation customHeight={50} customWidth={50} /> :
                <>
                  <span className={classNames.heading}>Showing Result : {parseInt(state.page) * 30} - {(parseInt(state.page) * 30) + 30} out of {issuedData?.total?.[0]?.total} </span>

                </>
              }
              <Button className={classNames.reloadButton} onClick={() => {
                activeBookRefetch()
              }}>
                Load
              </Button>
              <ReactToPrint
                trigger={() => <button className={classNames.reloadButton}>Print</button>}
                content={() => componentRef.current}
              />
            </div>
          </div>
          <Scrollbars style={{ height: '90%' }} >
            <div ref={componentRef} className="printable">
              <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                  <img width='12%' height='12%' src={selectedCollege?.logo} />
                  <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                    <span style={{ fontSize: '30px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>
                    <p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }} >{selectedCollege?.accreditedGrade}</p>
                    <p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}</p>
                  </div>
                </div>
                <h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'left' }}>Active Register</h5>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%', }}>Sn</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 14, width: '10%' }}>Acc No</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '32%' }}>Title</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '16%' }}>Author</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '9%' }}>DateTime</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>Days</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>Fine</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Mn No</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 14, width: '10%' }}>Class/Dept.</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '15%' }}>Mn Name</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 14, width: '15%' }}>Issued By</h5>
                </div>
                {issuedData?.data?.map((item) => (
                  <>
                    <div style={{ width: '100%', display: 'flex', height: ' 40px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.status === 'renew' ? '#c0faeb' : 'white' }}>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '10%' }}>{item.sn}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', alignSelf: 'center', fontSize: 14, width: '10%' }}>{item.accessionNumber}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '32%' }}>{item.title}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '16%' }}>{item.author}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '9%' }}>{moment(new Date(item?.tookOn)).format('DD MMM YYYY hh:mm A')}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '5%' }}>{item.days}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 10, width: '5%' }}>{item.fine}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.admisionNo}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '10%' }}>{item.className}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '15%' }}>{item.issuedTo}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '15%' }}>{item.issuedBy}</h5>
                    </div>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      height: 1,
                      backgroundColor: 'black',
                    }}></div>
                  </>
                ))}
                <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 10 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div >




      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div >
  );
};
