import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { getAxiosInstance } from '../../utils/axiosInstance';
import { useAllClasses } from '../../queryHooks';
import { AxiosResponse } from 'axios';
import { useQuery, QueryFunctionContext } from 'react-query';
import { getCollegeDetails } from './Register';
import SelectField from '../../NecttosComp/SelectField';
import LoadingAnimation from '../../components/LoadingAnimation';
import { useParams } from 'react-router-dom';

interface StudentOption {
	value: string;
	label: string;
	admisionNo: string;
	capId: string;
	adnType: string;
	rcGroup: string;
	rcMark: string;
	place: string;
	index: string;
	guardianName: string;
	guardianMobile: string;
	dateOfAdmission: string;
}

interface ClassOption {
	value: string;
	label: string;
	_id: string;
}

interface FormState {
	phoneNumber: string;
	name: string;
	usersData: any;
	department: any;
	classes: ClassOption | null;
	roles: any;
	role: any;
	aadhaarNumber: string;
	district: string;
	capId: string;
	adnType: string;
	rcGroup: string;
	rcMark: string;
	dob: string;
	gender: string;
	houseName: string;
	admisionNo: string;
	nationality: string;
	pinCode: string;
	place: string;
	postOffice: string;
	state: string;
	bloodGroup: string;
	guardianName: string;
	guardianMobile: string;
	guardianRelation: string;
	dateOfAdmission: string;
	dateOfJoin: string;
	guideName: string;
}

const SuccessToastContent = () => (
	<div style={{ display: 'flex', alignItems: 'center', color: 'blue' }}>
		<span style={{ marginRight: '10px' }}>✔️</span>
		<span>Form submitted successfully!</span>
	</div>
);

const getUserList = async (context: QueryFunctionContext): Promise<any> => {
	const [queryKey, { type, collegeId, classId }] = context.queryKey as [string, { type: string; collegeId: string; classId?: string }];

	try {
		const instance = await getAxiosInstance();
		const resp: AxiosResponse<any> = await instance.get(`/college/users/getByClass?collegeId=${collegeId}${type === 'all' ? '' : `&type=${type}`}${classId ? `&classId=${classId}` : ''}`);
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		}
		return resp?.data?.list || [];
	} catch (err: any) {
		console.log({ err });
		throw new Error(err.message || 'API Error');
	}
};

interface OnboardingProps {
	collegeId?: string;
	type?: any;
	fyugp?: any;
	phoneNumber?: any;
}

const OnboardingDataLose: React.FC<OnboardingProps> = ({ collegeId, fyugp, type, phoneNumber }) => {
	const [dropDown, setDropDown] = useState<any>([]);

	let param: { id: string; role: string } = useParams();

	const [params, setParams] = useState<{ id: string; role: string }>({ id: '', role: '' });
	const [formState, setFormState] = useState<FormState>({
		phoneNumber: '',
		name: '',
		department: '',
		classes: null,
		usersData: '',
		roles: '',
		role: '',
		aadhaarNumber: '',
		district: '',
		dob: '',
		gender: '',
		houseName: '',
		admisionNo: '',
		nationality: '',
		pinCode: '',
		place: '',
		postOffice: '',
		state: '',
		bloodGroup: '',
		guardianName: '',
		guardianMobile: '',
		guardianRelation: '',
		capId: '',
		adnType: '',
		rcGroup: '',
		rcMark: '',
		dateOfAdmission: '',
		dateOfJoin: '',
		guideName: '',
	});

	const [droRoles, setDropRoles] = useState<any>([]);
	const [dropTeachers, setDropTeachers] = useState<any>([]);
	const [respondsData, setResponseData] = useState<any>({});
	const [mobileFetchError, setMobileFetchError] = useState('');
	const [userDetected, setUserDetected] = useState(false);
	const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(false);

	useEffect(() => {
		if (collegeId) {
			const newParams = {
				id: collegeId,
				role: type,
				fyugp,
			};
			setParams(newParams);
			setFormState((prevState) => ({ ...prevState, role: type, phoneNumber }));
		}
	}, [collegeId, fyugp, type, phoneNumber]);

	useEffect(() => {
		if (param.role) {
			const newParams = {
				id: param.id,
				role: param.role,
			};
			setParams(newParams);
			setFormState((prevState) => ({ ...prevState, role: param.role }));
		}
	}, [param]);

	const handleMobileValidate = async (e: any) => {
		e.preventDefault();
		setMobileFetchError('');
		try {
			const instance = await getAxiosInstance();
			const { data } = await instance.get('/college/action/getFirebaseByNumber', { params: { phoneNumber: formState.phoneNumber } });
			console.log('data', data);
			setUserDetected(true);
			setPhoneNumberDisabled(true);
			console.log('formStae', formState);
		} catch (error) {
			setMobileFetchError('Not registered. Please enter registered mobile number');
		}
	};

	const drop: any = {
		_id: 'DD-58T7733G272C-99B6-RBTF-CLANGUAGE458',
		secondLanguage: ['Malayalam', 'Hindi', 'Arabic', 'Sanskrit', 'Syriac', 'Tamil'],
		'COL-D209492D4FB7-4E07-848E-A317A1BC9196': ['Malayalam', 'Hindi', 'Syriac'],
		'COL-D4F1E2B6FCE5-457C-AB5D-3D0F80462CF0': ['Malayalam', 'Hindi', 'Arabic'],
		'COL-4E56593776BE-41C3-982A-0B6DC20E44A0': ['Malayalam', 'Hindi', 'Arabic', 'Sanskrit', 'Syriac', 'Tamil'],
		'COL-F0AF67159D59-4FDF-9915-25CFD728DCA6': ['Malayalam', 'Hindi', 'Sanskrit'],
		'COL-62A06826B305-4AAA-AD96-2DA8F547C914': ['Malayalam', 'Hindi'],
	};

	const [secondLanguageArray, setSecondLanguageArray] = useState([]);

	useEffect(() => {
		const selectedLanguages = drop[params?.id] || drop.secondLanguage;
		setSecondLanguageArray(selectedLanguages);
	}, [params?.id]);

	const { data } = useQuery(
		[
			`userList${formState?.classes?.value}`,
			{
				type: params?.role,
				classId: formState?.classes?.value,
				collegeId: params.id,
			},
		],
		getUserList,
	);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = event.target;
		setFormState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const getTeachers = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/iqac/getTeachers?collegeId=${encodeURIComponent(params.id)}`;
			const res = await instance.get(url);
			setDropTeachers(res.data.list);
		} catch (error) {
			console.error(error);
		}
	};

	const departments = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/classes/getDepartmentForm?collegeId=${encodeURIComponent(params.id)}`;
			const res = await instance.get(url);
			setDropDown(res.data.data);
		} catch (error) {
			console.error(error);
		}
	};

	console.log({ dropDown });

	const [studentsInfo, setStudentIfo] = useState([]);
	const [loading, setLoading] = useState(false);

	const getClasses = async (classId: any, collegeId: any) => {
		setLoading(true);
		try {
			const instance = await getAxiosInstance();
			const url = `/college/action/getStudentsByclass?collegeId=${encodeURIComponent(collegeId)}&classId=${encodeURIComponent(classId)}`;
			const res = await instance.get(url);
			setStudentIfo(res.data.classData);
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		getClasses(formState?.classes?.value, params.id);
	}, [formState?.classes?.value, params.id]);

	const allClasses = useAllClasses({
		needAll: false,
		classOnly: true,
		subId: params.id,
	});

	const { data: classesUG } = useQuery([`collegeData`, params.id, '2024-2028', ' ', 'UG'], getCollegeDetails);

	useEffect(() => {
		departments();
		getTeachers();
	}, [params]);

	console.log({ dropTeachers });

	const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const re = /^[0-9\b]+$/;
		if (value === '' || (re.test(value) && value.length <= 10)) {
			setFormState((prevState) => ({
				...prevState,
				phoneNumber: value,
			}));
		}
	};

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFormState((prevState) => ({
			...prevState,
			name: event.target.value,
		}));
	};

	interface AxiosError {
		response?: {
			data?: {
				error?: string;
			};
			status?: number;
			message?: string;
		};
	}
	const [classes, setClasses] = useState('');

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!userDetected) {
			return;
		}

		if (formState?.phoneNumber?.length !== 10) {
			toast.error('Please Enter 10 digits');
			return;
		}

		if (params?.role === 'student' && !formState?.classes) {
			setClasses('Please Select class');
			toast.error('Please Select class');
			return;
		}

		try {
			const instance = await getAxiosInstance();
			let data = {
				...formState,
				...params,
				userId: formState?.usersData?._id,
				collegeId: params.id,
			};
			data.phoneNumber = data?.phoneNumber?.replace('+91', '');

			const dataValues = await instance.post('/college/users/addNewUserForm', data);

			if (dataValues) {
				setResponseData(dataValues);
			}

			toast.success(<SuccessToastContent />, {
				position: 'top-center',
				duration: 10000,
			});
			setFormState({
				phoneNumber: '',
				name: '',
				department: '',
				classes: null,
				usersData: '',
				roles: '',
				role: params?.role || '',
				aadhaarNumber: '',
				district: '',
				dob: '',
				admisionNo: '',
				gender: '',
				houseName: '',
				nationality: '',
				pinCode: '',
				place: '',
				postOffice: '',
				state: '',
				bloodGroup: '',
				guardianName: '',
				guardianMobile: '',
				guardianRelation: '',
				capId: '',
				adnType: '',
				rcGroup: '',
				rcMark: '',
				dateOfAdmission: '',
				dateOfJoin: '',
				guideName: '',
			});
		} catch (error) {
			console.error(error);

			const axiosError = error as AxiosError;

			if (axiosError.response && axiosError.response.data && axiosError.response.data.error) {
				toast.error(axiosError.response.data.error);
			} else {
				toast.error('Sorry...Something went wrong');
			}
		}
	};

	dropDown.sort((a: any, b: any) => a.name.localeCompare(b.name));

	const roles = [
		{ title: 'Director', _id: 'director' },
		{ title: 'Manager', _id: 'manager' },
		{ title: 'Office Staff', _id: 'office' },
		{ title: 'Librarian', _id: 'library' },
		{ title: 'Library Assistant', _id: 'libraryAssistant' },
		{ title: 'Mechanic', _id: 'mechanic' },
		{ title: 'Cashier', _id: 'cashier' },
		{ title: 'Clerk', _id: 'clerk' },
		{ title: 'Gardener', _id: 'gardner' },
		{ title: 'Head Accountant', _id: 'headAccountant' },
		{ title: 'Herbarium Keeper', _id: 'herbariumKeeper' },
		{ title: 'Lab Assistant', _id: 'labAssistant' },
		{ title: 'Office Attendant', _id: 'officeAttendant' },
		{ title: 'Superintendent', _id: 'superintend' },
		{ title: 'Sweeper', _id: 'sweepers' },
		{ title: 'Part time Sweeper', _id: 'partTimeSweeper' },
		{ title: 'LD Typist', _id: 'ldTypist' },
		{ title: 'Night Watchman', _id: 'watchman' },
		{ title: 'Technical Assistant', _id: 'technicalAssistant' },
		{ title: 'Computer Assistant', _id: 'computerAssistant' },
		{ title: 'Store Keeper', _id: 'storeKeeper' },
		{ title: 'Driver', _id: 'driver' },
		{ title: 'Senior Clerk', _id: 'seniorClerk' },
		{ title: 'Clerical Assistant', _id: 'clericalAssistant' },
		{ title: 'Cook', _id: 'cook' },
		{ title: 'Sanitation Worker', _id: 'sanitationWorker' },
		{ title: 'Marker', _id: 'marker' },
	];

	const rolesTeachers = [{ title: 'Head of Department', _id: 'hod' }];

	useEffect(() => {
		if (formState.role === 'teacher') {
			setDropRoles(rolesTeachers);
		} else if (formState.role === 'staff') {
			setDropRoles(roles);
		} else {
			setDropRoles([]);
		}
	}, [formState.role]);

	const options: StudentOption[] = studentsInfo?.map((student: { name: string; admisionNo: string; capId: string; adnType: string; rcGroup: string; rcMark: string; place: string; index: string; guardianName: string; guardianMobile: string; dateOfAdmission: string }) => ({
		name: student.name,
		admisionNo: student.admisionNo,
		capId: student.capId,
		adnType: student.adnType,
		rcGroup: student.rcGroup,
		rcMark: student.rcMark,
		value: student.name,
		label: student.name,
		place: student.place,
		index: student.index,
		guardianName: student.guardianName,
		guardianMobile: student.guardianMobile,
		dateOfAdmission: student.dateOfAdmission,
	}));

	const handleNameChangeDrop = (selectedOption: any) => {
		setFormState((prevState) => ({
			...prevState,
			name: selectedOption.name || '',
			admisionNo: selectedOption.admisionNo || '',
			capId: selectedOption.capId || '',
			adnType: selectedOption.adnType || '',
			rcGroup: selectedOption.rcGroup || '',
			rcMark: selectedOption.rcMark || '',
			place: selectedOption.place || '',
			index: selectedOption.index || '',
			guardianName: selectedOption.guardianName || '',
			guardianMobile: selectedOption.guardianMobile || '',
			dateOfAdmission: selectedOption.dateOfAdmission || '',
		}));
	};

	const classOptions: ClassOption[] = (fyugp ? classesUG : allClasses?.data)?.map((classItem: { classId: string; className: string }) => ({
		value: classItem.classId,
		label: classItem.className,
		_id: classItem.classId,
	}));

	const handleClassChangeDrop = (selectedOption: any) => {
		setFormState((prevState) => ({
			...prevState,
			classes: selectedOption || null,
			name: '',
			admisionNo: '',
			capId: '',
			adnType: '',
			rcGroup: '',
			rcMark: '',
		}));
	};

	console.log({ formState });

	return respondsData?.data?.message === 'Successfully updated' ? (
		<>
			{!fyugp ? (
				<div className='flex items-center '>
					<div className='max-w-lg w-full bg-white shadow-lg rounded-lg overflow-hidden'>
						<div className='px-8 py-6'>
							<div className=''>
								<div className='bg-green-100 border-l-4 border-green-500 text-green-700 p-4' role='alert'>
									<h3 className='font-bold text-lg'>Registration Successful!</h3>
									<p className='text-sm mt-2'>Please take a screenshot of the steps below for future reference.</p>
									<div className='mt-4'>
										<p className='font-bold'>What's next:</p>
										<ol className='list-decimal ml-5 mt-2 text-sm'>
											<li>Go to the Play Store or App Store.</li>
											<li>Search for "Necttos" to find the app.</li>
											<li>Download and install it.</li>
											<li>
												Login using your phone number: <span className='font-semibold'>{respondsData?.data?.isUser?.phoneNumber}</span>
											</li>
										</ol>
									</div>
								</div>
							</div>
							<div className='text-gray-700 text-base text-center'>
								<p>
									If you encounter any issues or have questions, please don't hesitate to
									<a href='tel:+919400001733' className='text-blue-700 hover:text-blue-900 ml-1'>
										contact support
									</a>
									for assistance.
								</p>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='flex items-center justify-center mt-6'>
					<div className='max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden'>
						<div className='px-6 py-4'>
							<div className='mb-2'>
								<div className='bg-green-100 border-l-4 border-green-500 text-green-700 p-4' role='alert'>
									<p className='font-bold'>Success!</p>
									<p>Your registration was completed successfully. Continue Login </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	) : (
		<>
			{respondsData?.data?.isUser?.phoneNumber ? (
				<>
					{!fyugp ? (
						<div className='flex items-center justify-center mt-6'>
							<div className='max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden'>
								<div className='px-6 py-4'>
									<div className='mb-2'>
										<div className='bg-green-100 border-l-4 border-green-500 text-green-700 p-4' role='alert'>
											<p className='font-bold'>Success!</p>
											<p>Your registration was completed successfully. Please download our mobile app and login using your registered mobile number.</p>
										</div>
									</div>
									<p className='text-gray-700 text-base mb-4'>For any assistance, feel free to contact our support team.</p>
									<div className='flex items-center justify-between'>
										<a href='https://example.com/download-app' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-150 ease-in-out'>
											Download App
										</a>
										<a href='tel:+919400001733' className='text-blue-700 hover:text-blue-900 font-semibold py-2 px-4 focus:outline-none focus:underline transition-colors duration-150 ease-in-out'>
											Contact Support
										</a>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className='flex items-center justify-center mt-6'>
							<div className='max-w-sm w-full bg-white shadow-md rounded-lg overflow-hidden'>
								<div className='px-6 py-4'>
									<div className='mb-2'>
										<div className='bg-green-100 border-l-4 border-green-500 text-green-700 p-4' role='alert'>
											<p className='font-bold'>Success!</p>
											<p>Your registration was completed successfully. Continue Login </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			) : (
				<div className='flex items-center justify-center  bg-gray-100'>
					<Toaster />
					<form className='w-full max-w-md bg-white rounded-lg shadow-md p-2' onSubmit={handleSubmit}>
						<span className='block text-lg font-semibold mb-4 text-center py-2 px-4 bg-blue-100 text-blue-800 rounded-lg shadow'>Registery check Necttos!</span>
						{['student', 'scholars', 'staff', 'teacher'].includes(params?.role) ? (
							<div></div>
						) : (
							<div className='mb-4'>
								<span className='block text-gray-700 text-sm font-bold mb-2'>Type of Users</span>
								<div className='flex items-center mb-2'>
									<input type='radio' id='student' name='role' value='student' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'student'} />
									<label htmlFor='student' className='ml-2 text-gray-700 text-sm'>
										UG & PG Students
									</label>
								</div>

								<div className='flex items-center'>
									<input type='radio' id='scholars' name='role' value='scholars' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'scholars'} />
									<label htmlFor='scholars' className='ml-2 text-gray-700 text-sm'>
										Research Scholars
									</label>
								</div>

								<div className='flex items-center'>
									<input type='radio' id='staff' name='role' value='staff' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'staff'} />
									<label htmlFor='staff' className='ml-2 text-gray-700 text-sm'>
										Non-Teaching Staff
									</label>
								</div>
								<div className='flex items-center'>
									<input type='radio' id='teacher' name='role' value='teacher' onChange={handleInputChange} className='form-radio h-5 w-5 text-blue-600' checked={formState.role === 'teacher'} />
									<label htmlFor='teacher' className='ml-2 text-gray-700 text-sm'>
										Teaching Staff
									</label>
								</div>
							</div>
						)}

						{formState.role === 'student' && formState?.usersData?._id && (
							<div className='mb-4'>
								<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
									Registered Phone Number (for receiving OTP)
								</label>
								<input type='number' id='phoneNumber' name='phoneNumber' value={formState.phoneNumber} onChange={handlePhoneNumberChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required disabled={phoneNumberDisabled} />
							</div>
						)}

						<div className='mb-4'>
							<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
								Registered Phone Number (for receiving OTP)
							</label>
							<input type='number' id='phoneNumber' name='phoneNumber' value={formState.phoneNumber} onChange={handlePhoneNumberChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required disabled={phoneNumberDisabled} />
						</div>

						<div className='flex items-center justify-center'>
							<button onClick={handleMobileValidate} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
								Check Mobile Number
							</button>
						</div>

						{userDetected ? (
							<>
								{['teacher']?.includes(formState.role) && (
									<div className='mb-4'>
										<div className='mb-4'>
											<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
												Name of the Teacher
											</label>
											<input type='text' id='name' name='name' value={formState.name} onChange={handleNameChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
										</div>

										<label htmlFor='department' className='block text-gray-700 text-sm font-bold mb-2'>
											Select Department
										</label>

										<select
											id='department'
											name='department'
											value={formState?.department?.name}
											onChange={(e) => {
												const selectedRole = dropDown.find((drop: any) => drop.name === e.target.value);
												setFormState((prevState) => ({
													...prevState,
													department: selectedRole,
												}));
											}}
											className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
											required>
											<option value=''>Choose...</option>
											{dropDown.map((role: any, index: number) => (
												<option key={index} value={role.name}>
													{role.name}
												</option>
											))}
										</select>
									</div>
								)}
								{['scholars']?.includes(formState.role) && (
									<div className='mb-4'>
										<label htmlFor='department' className='block text-gray-700 text-sm font-bold mb-2'>
											Select Department
										</label>

										<select
											id='department'
											name='department'
											value={formState?.department?.name}
											onChange={(e) => {
												const selectedRole = dropDown.find((drop: any) => drop.name === e.target.value);
												setFormState((prevState) => ({
													...prevState,
													department: selectedRole,
												}));
											}}
											className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
											required>
											<option value=''>Choose...</option>
											{dropDown.map((role: any, index: number) => (
												<option key={index} value={role.name}>
													{role.name}
												</option>
											))}
										</select>
										<div className='mb-4'>
											<label htmlFor='dateOfJoin' className='block text-gray-700 text-sm font-bold mb-2'>
												Date of Joining
											</label>
											<input type='date' id='dateOfJoin' name='dateOfJoin' value={formState.dateOfJoin} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
										</div>

										<label htmlFor='department' className='block text-gray-700 text-sm font-bold mb-2'>
											Select Guide
										</label>
										<select
											id='guideName'
											name='guideName'
											value={formState?.guideName}
											onChange={(e) => {
												// const selectedRole = dropTeachers.find((drop: any) => drop._id === e.target.value);
												setFormState((prevState) => ({
													...prevState,
													guideName: e.target.value,
												}));
											}}
											className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
											required>
											<option value=''>Choose...</option>
											{dropTeachers.map((role: any, index: number) => (
												<option key={index} value={role._id}>
													{role.name}
												</option>
											))}
										</select>
										{/* <div className='mb-4'>
											<label htmlFor='guideName' className='block text-gray-700 text-sm font-bold mb-2'>
												Guide Name
											</label>
											<input type='text' id='guideName' name='guideName' value={formState.guideName} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
										</div> */}
									</div>
								)}

								{['staff']?.includes(formState.role) && (
									<div className='mb-4'>
										<label htmlFor='phoneNumber' className='block text-gray-700 text-sm font-bold mb-2'>
											Name of the Staff
										</label>
										<input type='text' id='name' name='name' value={formState.name} onChange={handleNameChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
									</div>
								)}

								{['student']?.includes(formState.role) && <SelectField id='class' name='class' label='Select Class' options={classOptions} value={classOptions.find((option) => option.value === formState.classes?.value) || null} onChange={handleClassChangeDrop} placeholder='Select a class' />}

								{['student', 'scholars']?.includes(formState.role) && (
									<>
										{loading ? (
											<LoadingAnimation customHeight={60} customWidth={60} />
										) : (
											<>
												{options.length === 0 ? (
													<div className='mb-4 mt-8'>
														<label htmlFor='name' className='block text-gray-700 text-sm font-bold mb-2'>
															Name of the student
														</label>
														<input type='text' id='name' name='name' value={formState.name} onChange={handleNameChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
													</div>
												) : (
													<SelectField id='name' name='name' label='Name of the student' options={options} value={options.find((option, index) => option.value === formState.name) || null} onChange={handleNameChangeDrop} placeholder='Select a student' />
												)}
												<div className='mb-4'>
													<label htmlFor='admisionNo' className='block text-gray-700 text-sm font-bold mb-2'>
														Admission No
													</label>
													<input type='text' id='admisionNo' name='admisionNo' value={formState.admisionNo} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='dob' className='block text-gray-700 text-sm font-bold mb-2'>
														Date of Birth
													</label>
													<input type='date' id='dob' name='dob' value={formState.dob} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='gender' className='block text-gray-700 text-sm font-bold mb-2'>
														Gender
													</label>
													<select id='gender' name='gender' value={formState.gender} onChange={handleInputChange} required className='block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'>
														<option value=''>Select Gender</option>
														<option value='male'>Male</option>
														<option value='female'>Female</option>
														<option value='other'>Other</option>
													</select>
												</div>
												<div className='mb-4'>
													<label htmlFor='guardianName' className='block text-gray-700 text-sm font-bold mb-2'>
														Guardian Name
													</label>
													<input type='text' id='guardianName' name='guardianName' value={formState.guardianName} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>

												<div className='mb-4'>
													<label htmlFor='guardianMobile' className='block text-gray-700 text-sm font-bold mb-2'>
														Guardian Mobile
													</label>
													<input type='number' id='guardianMobile' minLength={10} maxLength={10} pattern='\d{10}' name='guardianMobile' value={formState.guardianMobile} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='guardianRelation' className='block text-gray-700 text-sm font-bold mb-2'>
														Guardian Relation
													</label>
													<select id='guardianRelation' name='guardianRelation' value={formState.guardianRelation} onChange={handleInputChange} required className='block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'>
														<option value=''>Select Relation</option>
														<option value='Father'>Father</option>
														<option value='Mother'>Mother</option>
														<option value='Brother'>Brother</option>
														<option value='Sister'>Sister</option>
														<option value='Uncle'>Uncle</option>
														<option value='Aunt'>Aunt</option>
														<option value='Grandfather'>Grandfather</option>
														<option value='Grandmother'>Grandmother</option>
													</select>
												</div>
												<div className='mb-4'>
													<label htmlFor='houseName' className='block text-gray-700 text-sm font-bold mb-2'>
														House Name
													</label>
													<input type='text' id='houseName' name='houseName' value={formState.houseName} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='place' className='block text-gray-700 text-sm font-bold mb-2'>
														Place
													</label>
													<input type='text' id='place' name='place' value={formState.place} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='postOffice' className='block text-gray-700 text-sm font-bold mb-2'>
														Post Office
													</label>
													<input type='text' id='postOffice' name='postOffice' value={formState.postOffice} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='pinCode' className='block text-gray-700 text-sm font-bold mb-2'>
														PIN Code
													</label>
													<input type='number' id='pinCode' name='pinCode' value={formState.pinCode} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='district' className='block text-gray-700 text-sm font-bold mb-2'>
														District
													</label>
													<input type='text' id='district' name='district' value={formState.district} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='state' className='block text-gray-700 text-sm font-bold mb-2'>
														State
													</label>
													<input type='text' id='state' name='state' value={formState.state} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='nationality' className='block text-gray-700 text-sm font-bold mb-2'>
														Nationality
													</label>
													<input type='text' id='nationality' name='nationality' value={formState.nationality} onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' required />
												</div>
												<div className='mb-4'>
													<label htmlFor='bloodGroup' className='block text-gray-700 text-sm font-bold mb-2'>
														Blood Group
													</label>
													<select id='bloodGroup' name='bloodGroup' value={formState.bloodGroup} onChange={handleInputChange} required className='block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'>
														<option value=''>Select Blood Group</option>
														<option value='A+'>A+</option>
														<option value='A-'>A-</option>
														<option value='B+'>B+</option>
														<option value='B-'>B-</option>
														<option value='AB+'>AB+</option>
														<option value='AB-'>AB-</option>
														<option value='O+'>O+</option>
														<option value='O-'>O-</option>
													</select>
												</div>
											</>
										)}
									</>
								)}

								{['staff', 'teacher']?.includes(formState.role) && (
									<div className='mb-4'>
										<label htmlFor='roles' className='block text-gray-700 text-sm font-bold mb-2'>
											Assign Additional Roles: Select Optional Secondary Positions
										</label>
										<select
											id='roles'
											name='roles'
											value={formState?.roles?.title}
											onChange={(e) => {
												const selectedRole = droRoles?.find((roles: any) => roles.title === e.target.value);
												setFormState((prevState) => ({
													...prevState,
													roles: selectedRole,
												}));
											}}
											className='block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'>
											<option value=''>Choose...</option>
											{droRoles?.map((roles: any, index: any) => (
												<option key={index} value={roles.title}>
													{roles.title}
												</option>
											))}
										</select>
									</div>
								)}
								{classes && (
									<>
										<p className='block text-red-400 text-sm font-bold mb-2 '>Please select your class </p>
									</>
								)}

								<div className='flex items-center justify-center'>
									<button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
										Submit
									</button>
								</div>
							</>
						) : (
							<>
								{mobileFetchError ? (
									<div className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative' role='alert'>
										<strong className='font-bold'>Notice:</strong>
										<span className='block sm:inline'> This number is not registered. Please use your registered mobile number.</span>
										<span className='absolute top-0 bottom-0 right-0 px-4 py-3'>
											<svg className='fill-current h-6 w-6 text-red-500' role='button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
												<title>Close</title>
												<path d='M14.348 5.652a1 1 0 00-1.415 0L10 8.586 7.067 5.652a1 1 0 00-1.415 1.415L8.586 10l-2.934 2.934a1 1 0 001.415 1.415L10 11.414l2.934 2.934a1 1 0 001.415-1.415L11.414 10l2.934-2.934a1 1 0 000-1.415z' />
											</svg>
										</span>
									</div>
								) : (
									<div className='bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative' role='alert'>
										<strong className='font-bold'>Notice:</strong>
										<span className='block sm:inline'> Please enter your registered mobile number and press the "Check Mobile Number" button to verify.</span>
										<span className='absolute top-0 bottom-0 right-0 px-4 py-3'>
											<svg className='fill-current h-6 w-6 text-green-500' role='button' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
												<title>Close</title>
												<path d='M14.348 5.652a1 1 0 00-1.415 0L10 8.586 7.067 5.652a1 1 0 00-1.415 1.415L8.586 10l-2.934 2.934a1 1 0 001.415 1.415L10 11.414l2.934 2.934a1 1 0 001.415-1.415L11.414 10l2.934-2.934a1 1 0 000-1.415z' />
											</svg>
										</span>
									</div>
								)}
							</>
						)}
					</form>
				</div>
			)}
			;
		</>
	);
};

export default OnboardingDataLose;
