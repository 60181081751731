import React, { useContext, useEffect, useState } from "react";
import { RenderFilePreview, SubjectInputSet, plustwo, renderFilePreview } from "../Form/Component";
import { Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useProfileData } from "../../queryHooks";
import { getDropdownData } from "../../pages/college/ApiServices";

function BasicCertificateVerification({ showWindow, selClass, refectd, date, setDate }) {
  const [dropDowns, setDropDowns] = useState([{}]);

  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
    };
    getDropDowns();
  }, []);
  const { collegeId, user } = useContext(FirebaseContext);
  const [diclarationFinal, setDiclarationFinal] = useState(false);
  const { data: userDetail } = useProfileData(user?.uid, false);
  const [values, setValues] = useState({});
  const [indexData, setIndexData] = useState({});
  const certificateBonusOptions = dropDowns?.[21]?.bonusCertificate || [];

  useEffect(() => {
    if (date && date.details) {
      const newValue = {
        plusTwoNoOfChance: date?.details?.plusTwoNoOfChance,
        isBonusMark: date?.details?.isBonusMark,
        certificates: date?.details?.certificates,
        plusTwoStream: date?.details?.plusTwoStream,
        subject1ObtainedMark: date?.details?.subject1ObtainedMark,
        subject1TotalMark: date?.details?.subject1TotalMark,
        subject2ObtainedMark: date?.details?.subject2ObtainedMark,
        subject2TotalMark: date?.details?.subject2TotalMark,
        subject3ObtainedMark: date?.details?.subject3ObtainedMark,
        subject3TotalMark: date?.details?.subject3TotalMark,
        subject4ObtainedMark: date?.details?.subject4ObtainedMark,
        subject4TotalMark: date?.details?.subject4TotalMark,
        subject5ObtainedMark: date?.details?.subject5ObtainedMark,
        subject5TotalMark: date?.details?.subject5TotalMark,
        subject6ObtainedMark: date?.details?.subject6ObtainedMark,
        subject6TotalMark: date?.details?.subject6TotalMark,
        subject7ObtainedMark: date?.details?.subject7ObtainedMark,
        subject7TotalMark: date?.details?.subject7TotalMark,
        totalMarkObtaioned: date?.details?.totalMarkObtaioned,
        subject1Name: date?.details?.subject1Name,
        subject2Name: date?.details?.subject2Name,
        subject3Name: date?.details?.subject3Name,
        subject4Name: date?.details?.subject4Name,
        subject5Name: date?.details?.subject5Name,
        subject6Name: date?.details?.subject6Name,
        subject7Name: date?.details?.subject7Name,
        totalMarks: date?.details?.totalMarks,
        plusTwoYearOfPass: date?.details?.plusTwoYearOfPass,
        plusTwoInstitutionName: date?.details?.plusTwoInstitutionName,
        plusTwoRegisterNo: date?.details?.plusTwoRegisterNo,
        plusTwoState: date?.details?.plusTwoState,
        plusTwoBoard: date?.details?.plusTwoBoard,
      };
      setValues((prevValues) => ({ ...prevValues, ...newValue }));
      setIndexData(date?.details);
    }
  }, [date?.details]);

  const toFixedTwo = (value) => {
    if (typeof value === "number") {
      return value.toFixed(2);
    }
    const parsed = parseFloat(value);
    return isNaN(parsed) ? "N/A" : parsed.toFixed(2);
  };

  const bonusCertificate = ["class_12_marksheet", "degree_marksheet", "ex_military_certificate", "ncc_a_certificate", "ncc_b_certificate", "nss_certificate", "scouts_guides_certificate", "student_police_cadet_certificate", "class_10_marksheet", "income_certificate", "community_caste_certificate", "sports_quota_proof", "cultural_quota_proof", "disability_quota_certificate", "lakshadweep_nomination_letter", "non_creamy_layer_certificate", "ews_certificate", "andaman_nicobar_quota_certificate", "third_gender_quota_certificate", "community_quota_certificate", "other_boards_eligibility_certificate"];

  if (!showWindow) {
    return null;
  }
  const handleCheckboxChange = (event) => {
    setValues({
      ...values,
      certificates: {
        ...values?.certificates,
        [event.target.name]: event.target.checked,
      },
    });
  };

  const addNewForm = async () => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post("/college/admissionProcess/markCorrection", {
        studentId: date?.details?._id,
        collegeId,
        values,
        userId: user?.uid,
      });
      if (resp?.data?.statusCode === 200) {
        setDate("");
        refectd();
      } else {
        setDate("");
        refectd();
      }
    } catch (error) {
      setDate("");
      refectd();
    }
  };

  const recalculate = async () => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post("/college/admissionProcess/calculateIndexMark", {
        studentId: date?.details?._id,
        collegeId,
        values: { certificates: date?.details?.certificates, ...values },
        classId: selClass,
        userId: user?.uid,
      });
      if (resp?.data?.statusCode === 200) {
        setIndexData(resp?.data.list);
      } else {
      }
    } catch (error) {}
  };

  return (
    <>
      <Main width="100%" height="100%">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Certificate and Marks</h2>

          <button onClick={() => setDate({ ...date, showBasicCertificate: false })} className="text-sm bg-red-600 hover:bg-red-700 text-white font-bold py-1 px-3 rounded">
            Close
          </button>
        </div>
        <div className="flex flex-col lg:flex-row overflow-x-auto">
          <div className="bg-white w-2/3 ">
            <div className="w-full bg-white shadow rounded p-4">
              <h1 className="text-lg font-semibold mb-4 self-center text-red-600"> Name of student: {date?.details?.name}</h1>
              <h3 className="text-lg font-semibold mb-4">Certificate</h3>

              {bonusCertificate?.map((certificate, index) => (
                <React.Fragment key={index}>
                  {values?.certificates?.[certificate] && (
                    <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center space-y-3">
                      <label className="block text-sm font-medium text-gray-700"> {certificate} </label>
                      {values?.certificates?.[certificate + "URL"] && <RenderFilePreview fileURL={values?.certificates?.[certificate + "URL"]} />}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className=" w-1/3 p-3">
            <div className="w-full bg-white shadow-lg rounded-lg p-4">
              <h3 className="text-lg font-semibold mb-4 text-gray-800">Mark List</h3>
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
                <Input width="100%" fieldName="Institution Name *" returnKey="plusTwoInstitutionName" state={values} setState={setValues} type="text" />
                <Input width="100%" fieldName="Register No *" returnKey="plusTwoRegisterNo" state={values} setState={setValues} type="text" />
                <Input width="100%" fieldName="Institution State *" returnKey="plusTwoState" state={values} setState={setValues} type="text" />
                <Input width="100%" fieldName="Select Board *" returnKey="plusTwoBoard" state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.PlusTwo?.Board} type="drop" />
                <Input width="100%" fieldName="Stream *" returnKey="plusTwoStream" state={values} setState={setValues} options={dropDowns?.[4]?.qualification?.[0]?.PlusTwo?.Stream} type="drop" />
                <Input width="100%" fieldName="Marks Obtained *" returnKey="totalMarkObtaioned" state={values} setState={setValues} type="text" />
                <Input width="100%" fieldName="Maximum Marks *" returnKey="totalMarks" state={values} setState={setValues} type="text" />
                <Input width="100%" fieldName="Month & Year of Pass *" returnKey="plusTwoYearOfPass" state={values} setState={setValues} type="month" />
                <Input width="100%" fieldName="No of Chances*" returnKey="plusTwoNoOfChance" state={values} setState={setValues} options={["1", "2", "3"]} type="drop" />
              </div>

              <>
                <p>
                  <abbr title="Obtained Marks">OM</abbr> = Obtained Marks | <abbr title="Total Marks">TM</abbr> = Total Marks
                </p>
                <div className="grid grid-cols-1  md:grid-cols-1 lg:grid-cols-1">
                  {plustwo?.map((terms) => (
                    <SubjectInputSet fieldName={terms?.fieldName} returnKey={terms.returnKey} state={values} setState={setValues} options={terms?.options || []} />
                  ))}
                </div>
              </>
              <Input width="100%" fieldName="Are You Eligible for Bonus Marks? *" returnKey="isBonusMark" state={values} setState={setValues} options={["Yes", "No"]} type="drop" />
              {values?.isBonusMark === "Yes" && (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 bg-gray-200 p-6">
                    {certificateBonusOptions?.map((certificate, index) => (
                      <label key={index} className="flex items-center space-x-3">
                        <input type="checkbox" name={certificate?.key} checked={values?.certificates?.[certificate?.key] || false} onChange={handleCheckboxChange} className="form-checkbox h-5 w-5" />
                        <span className="text-gray-700">{certificate?.title}</span>
                      </label>
                    ))}
                  </div>
                </>
              )}
              <div className="p-4 bg-white shadow rounded-lg">
                <Button
                  type="save"
                  onClick={() => {
                    recalculate();
                  }}
                >
                  Re Calculate
                </Button>
                <h2 className="text-lg font-semibold text-gray-800 mb-4">Index Mark Calculation Details</h2>
                <table className="w-full text-sm text-left text-gray-700">
                  <tbody>
                    <tr>
                      <td className="px-4 py-2  bg-green-100 font-semibold text-lg">Particulars:</td>
                      <td className="px-4 py-2 bg-green-100 font-bold text-lg">Actual Mark</td>
                      <td className="px-4 py-2 bg-green-100 font-bold text-lg">Final Index Mark</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Total Possible:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.totalPossible}</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.totalPossibleCon}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Total Obtained:</td>
                      <td className="px-4 py-2  bg-gray-50 font-bold text-lg">{indexData?.totalObtained?.toFixed(2)}</td>
                      <td className="px-4 py-2  bg-gray-50 font-bold text-lg">+{indexData?.totalObtainedCon?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Add Additional Subjects:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.indexMarkOfAddSub?.toFixed(2)}</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">+{indexData?.indexMarkOfAddSubCon?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Add Second Additional Subjects:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{indexData?.indexMarkOfAddSub2?.toFixed(2)}</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">+{indexData?.indexMarkOfAddSubCon2?.toFixed(2)}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50  font-semibold text-lg">Bonus Mark:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg"></td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">+{indexData?.bonusMark}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Handicap Mark:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg"></td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">-{indexData?.handicapedMark}</td>
                    </tr>
                    <tr className="bg-gray-50">
                      <td className="px-4 py-2 bg-gray-50 font-semibold text-lg">Index Mark:</td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg"></td>
                      <td className="px-4 py-2 bg-gray-50 font-bold text-lg">{toFixedTwo(indexData?.earnedMark)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="bg-white p-6 rounded-lg shadow-lg">
                <div className="flex items-center space-x-4">
                  <img src={userDetail?.photoURL} alt={userDetail?.name} className="w-16 h-16 rounded-full" />
                  <span className="font-semibold">{userDetail?.name}</span>
                </div>
                <div className="mt-4 text-gray-600">
                  <p className="text-sm">I hereby certify that the marks detailed above have been accurately verified against the Certificate / Mark List provided. I understand that any discrepancies may lead to disciplinary actions or the revocation of any benefits derived from these results.</p>
                </div>
                <Input width="100%" fieldName="Further Details (Notes)" returnKey="details" state={values} setState={setValues} type="text" />
                <label className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="diclaration"
                    checked={diclarationFinal}
                    onChange={() => {
                      setDiclarationFinal(!diclarationFinal);
                    }}
                    className="form-checkbox h-[30px] w-[30px]"
                  />
                  <span className="text-gray-700">I Verified</span>
                </label>
                <Button
                  type="save"
                  disabled={!diclarationFinal}
                  onClick={() => {
                    addNewForm();
                  }}
                >
                  Submit Verified Details
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </>
  );
}

export default BasicCertificateVerification;
