import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';
import TextArea from 'antd/lib/input/TextArea';

const exportPdf = async ({ olddata, setLoading }) => {
	const instance = await getAxiosTokenInstance();
	try {
		const response = await instance.post(`/college/reportGeneration/idCardPrinting`, olddata, {
			responseType: 'blob',
		});
		if (response.data.statusCode === 400) {
			throw new Error(response.data.message || 'API Error');
		}
		return response.data;
	} catch (error) {
		throw new Error(error?.response?.data?.message || error.message || 'API Error');
	} finally {
		setLoading(false);
	}
};

export const IdCardPrinting = ({ onClose }) => {
	const { collegeId } = useContext(FirebaseContext);
	const [loading, setLoading] = useState(false);
	const exportPdfMutation = useMutation(exportPdf);

	const onExport = async (year) => {
		setLoading(true);
		try {
			const olddata = { collegeId, year };
			const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
			const link = document.createElement('a');
			link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
			link.download = 'Exam_Reg_Support.pdf';
			link.target = '_blank';
			link.click();
		} catch (error) {
			console.error(error.message);
		} finally {
			setLoading(false);
			onClose();
		}
	};

	const handleExport = (event) => {
		event.preventDefault();
		const year = event.target.elements.admissionNumber.value;
		onExport(year);
	};

	const FormField = ({ label, name, placeholder }) => (
		<div className='mb-6'>
			<label className='block text-sm font-medium text-gray-700'>{label}</label>
			<TextArea name={name} placeholder={placeholder} style={{ height: '390px', width: 'full', fontSize: '30px', lineHeight: '35px' }} />
		</div>
	);

	return (
		<Main height='80%' width='80%' title='ID Card Printing'>
			{loading ? (
				<LoadingAnimation dark />
			) : (
				<>
					<form onSubmit={handleExport}>
						<FormField label='Admission Number' name='admissionNumber' placeholder='Enter Admission No' />
						<div className='flex items-center justify-between'>
							<Button type='submit' className='inline-flex items-center px-6 py-3 text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
								Download
							</Button>
							<Button onClick={onClose} type='close'>
								Close
							</Button>
						</div>
					</form>
				</>
			)}
		</Main>
	);
};
