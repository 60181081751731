import React, { useContext, useEffect, useState } from 'react';
import DashLayout from '../../layout/DashLayout';
import issue from '../../static/images/library/issue.png';
import returnBook from '../../static/images/library/return.png';
import reniew from '../../static/images/library/reniew.png';
import activeRegister from '../../static/images/library/activeRegister.png';
import addBook from '../../static/images/library/addBook.png';
import ddc from '../../static/images/library/ddc.png';
import exportPng from '../../static/images/library/export.png';
import footprint from '../../static/images/library/footprint.png';
import master from '../../static/images/library/master.png';
import members from '../../static/images/library/members.png';
import movement from '../../static/images/library/movement.png';
import opac from '../../static/images/library/opac.png';
import sb from '../../static/images/library/sb.png';
import applicationForm from '../../static/images/accademics/applicationForm.png';
import suscribe from '../../static/images/library/suscribe.png';
import './style.css';
import lost from '../../static/images/library/lost.png';
import labels from '../../static/images/library/labels.png';
import holyday from '../../static/images/library/holyday.png';
import subsidiaryRegister from '../../static/images/report/subsidiaryRegister.png';
import hierarchy from '../../static/images/report/hierarchy.png';
import { NaacDataCollection } from '../../pages/college/NAAC/NaacDataCollection';
import accounts from '../../static/images/report/accounts.png';
import dues from '../../static/images/report/dues.png';
import collectedFee from '../../static/images/report/collectedFee.png';
import dayBook from '../../static/images/report/dayBook.png';
import cashBook from '../../static/images/report/cashBook.png';
import scholarship from '../../static/images/report/scholarship.png';
import electoralRoll from '../../static/images/report/electoralRoll.png';
import transferCertificate from '../../static/images/report/transferCertificate.png';
import statistics from '../../static/images/report/statistics.png';
import attendanceRegister from '../../static/images/accademics/attendanceRegister.png';
import file1 from '../../static/images/naac/File 1.png';
import file2 from '../../static/images/naac/File 2.png';
import file3 from '../../static/images/naac/File 3.png';
import file4 from '../../static/images/naac/File 4.png';
import file5 from '../../static/images/naac/File 5.png';
import file6 from '../../static/images/naac/File 6.png';
import file7 from '../../static/images/naac/File 7.png';
import atts from '../../static/images/iqac/atts.png';
import feedback from '../../static/images/iqac/feedBack.png';
import allocation from '../../static/images/iqac/allocation.png';
import master2 from '../../static/images/iqac/master.png';
import verified from '../../static/images/iqac/verified.png';
import pending from '../../static/images/iqac/pending.png';
import student from '../../static/images/iqac/student.png';
import staff from '../../static/images/iqac/staff.png';
import ModalWrapper from '../../components/ModalWrapper';
import { IssueBook } from '../../pages/college/LibraryModals/IssueBook';
import { RenewBook } from '../../pages/college/LibraryModals/RenewBook';
import { ReturnBook } from '../../pages/college/LibraryModals/ReturnBook';
import AddBook from '../../pages/college/LibraryModals/AddBook';
import { Opac } from '../../pages/college/LibraryModals/Opac';
import { Master } from '../../pages/college/LibraryModals/Master';
import { Members } from '../../pages/college/LibraryModals/Members';
import { Movements } from '../../pages/college/LibraryModals/Movements';
import { Labels } from '../../pages/college/LibraryModals/Labels';
import { DDCSubjects } from '../../pages/college/LibraryModals/DDCSubjects';
import { Holidays } from '../../pages/college/LibraryModals/Holidays';
import { useProfileData } from '../../queryHooks';
import { FirebaseContext } from '../../context/FirebaseContext';
import { usePersistentState, useRoles, validateRole } from '../../utils';
import { MainAccounts } from '../../CollegeApp/Accounts/MainAccount';
import { CollectionReports } from '../../pages/college/Accounts/CollectionReports';
import { DuesReport } from '../../pages/college/Accounts/DuesReport';
import { Scholarship } from '../../pages/college/Accounts/Scholarship';
import { NameSlip } from '../../pages/college/ReportModals/NameSlip';
import { PhotoCkeck } from '../../pages/college/ReportModals/PhotoCkeck';
import { FootPrint } from '../../pages/college/LibraryModals/FootPrint';
import { Statistics } from '../../CollegeApp/Office/Statistics';
import { ActiveRegister } from '../../pages/college/LibraryModals/ActiveRegister';
import WorkingFIles from '../../pages/college/Adminitration/WorkingFIles';
import { Attendance } from '../../pages/college/Accademics/Attendance';
import { LibraryStatisticsExport } from '../../pages/college/LibraryModals/LibraryStatisticsExport';
import { RollView } from '../../pages/college/ReportModals/RollView';
import FeeBilling from '../../pages/college/Accounts/FeesBilling';
import { DCB } from '../../pages/college/Accounts/DCB';
import { StrengthDetails } from '../../pages/college/ReportModals/StrengthDetails';
import { Periodicals } from '../../pages/college/LibraryModals/Periodicals';
import { DashPage } from '../../pages/MasterTable';
import { PeriodicalsEntry } from '../../pages/college/LibraryModals/PeriodicalsEntry';
import { IncomeExpenditure } from '../../CollegeApp/Accounts/RecieptPayment';
import { TransferSertificateCC } from '../../pages/college/ReportModals/TransferSertificateCC';
import PostBulkJournals from '../../pages/college/Accounts/WorkingFIles';
import { DailyFeeCollectionRegister } from '../../pages/college/Accounts/DailyFeeCollectionRegister';
import { SubAccounts } from '../../CollegeApp/Accounts/SubAccounts';
import InstanceFeeCollection from '../../pages/college/Accounts/InstanceFeeCollection';
import { EResources } from '../../pages/college/LibraryModals/EResources';
import { MasterTimeTables } from '../../pages/college/ReportModals/MasterTimeTables';
import { SubsidiaryRegister } from '../../pages/college/Accounts/SubsidiaryRegister';
import { EarnLeaveCalculator } from '../../pages/college/Accounts/EarnLeaveCalculator';
import { FineRegister } from '../../pages/college/LibraryModals/FineRegister';
import FeeStructure from '../../CollegeApp/Accounts/FeeStructure';
import ApplicationForm from '../../pages/college/Accademics/ApplicationForm/ApplicationForm';
import { Internal } from '../../pages/college/Accademics/Internal';
import { APC } from '../../pages/college/Accademics/APC';
import { CatogoryListData } from '../../pages/college/ReportModals/CategoryList';
import { TeacherProfile } from '../../pages/college/IQAC/TeacherProfile';
import { RoleManagements } from '../../pages/college/Adminitration/RoleManagements';
import { StaffProfile } from '../../pages/college/IQAC/StaffProfile';
import { StudentProfile } from '../../pages/college/IQAC/StudentProfile';
import { FDCB } from '../../pages/college/Accounts/FDCB';
import { CashBankReports } from '../../pages/college/Accounts/CashBankReports';
import EDLBilling from '../../pages/college/Accounts/EDLBIlling/EDLBilling';
import { FeedBackReports } from '../../pages/college/IQAC/FeedBackReports';
import { IdCardPrinting } from '../../pages/college/ReportModals/IdCardPrinting/IDCardPrinting';
import { Numbers } from '../../pages/college/Accounts/Numbers';
import { CreateAccount } from '../../pages/college/Admission/CreateAccount';
import { OpenCourse } from '../../pages/college/Accademics/OpenCourse';
import { DepartmentVery } from '../../pages/college/Admission/DepartmentVery';

import NodalVery from '../../pages/college/Admission/NodalVery';
import Principal from '../../pages/college/Admission/Principal';
import Office from '../../pages/college/Admission/Office';
import Fees from '../../pages/college/Admission/Fees';
import University from '../../pages/college/Admission/University';
import PTA from '../../pages/college/Admission/PTA';
import DashBoard from '../../CollegeApp/Admission/AdmissionDashBoard';
import Controller from '../../CollegeApp/Admission/AdmissionController';
import Splitups from '../../CollegeApp/Admission/Splitups';
import EGrantz from '../../pages/college/Admission/EGrantz';
import AdmissionRegister from '../../pages/college/Admission/AdmissionRegister';
import { Help } from '../../pages/college/Adminitration/Help';
import EPosMirgration from '../../pages/college/Admission/EPosMirgration';
import EGrantManagement from '../../pages/college/Admission/EGrantManagement';
import { RankList } from '../../pages/college/Accademics/RankList';
import { AttendanceMonthly } from '../../pages/college/Accademics/AttendanceMonthly';
import { PostElection } from '../../pages/college/Election/SubAccounts';
import ElectoralRoll from '../../pages/college/ReportModals/ElectoralRoll';
import MenteePDF from '../../pages/college/Election/Mentee';
import { SubjectWiseAttendance } from '../../pages/college/Accademics/SbjectWiseAttendance';
import { ClassWiseAttendance } from '../../pages/college/Accademics/ClassWiseAttendance';
import { C1_1 } from '../../pages/college/NAAC/C1_1';
import { BookRecomentation } from '../../pages/college/LibraryModals/BookRecomentation';
import SubjectBank from '../../pages/college/Accademics/SubjectBank';
import C2_1 from '../../pages/college/NAAC/C2/C2_1';
import C2_2 from '../../pages/college/NAAC/C2/C2_2';
import C2_3 from '../../pages/college/NAAC/C2/C2_3';
import C2_4 from '../../pages/college/NAAC/C2/C2_4';
import C2_5 from '../../pages/college/NAAC/C2/C2_5';
import C2_6 from '../../pages/college/NAAC/C2/C2_6';
import C2_7 from '../../pages/college/NAAC/C2/C2_7';
import C2_8 from '../../pages/college/NAAC/C2/C2_8';
import C2_9 from '../../pages/college/NAAC/C2/C2_9';
import C2_10 from '../../pages/college/NAAC/C2/C2_10';
import C3_1a from '../../pages/college/NAAC/C3/C3_1a';
import C3_1b from '../../pages/college/NAAC/C3/C3_1b';
import C3_1_1 from '../../pages/college/NAAC/C3/C3_1_1';
import C3_2_2 from '../../pages/college/NAAC/C3/C3_2_2';
import C3_3_2 from '../../pages/college/NAAC/C3/C3_3_2';
import C3_3_3 from '../../pages/college/NAAC/C3/C3_3_3';
import C3_4_2 from '../../pages/college/NAAC/C3/C3_4_2';
import C3_4_3 from '../../pages/college/NAAC/C3/C3_4_3';
import C3_5_1 from '../../pages/college/NAAC/C3/C3_5_1';
import C3_5_2 from '../../pages/college/NAAC/C3/C3_5_2';
import TimeTablePage from '../../pages/TimeTablePage';
import AddUserPage from '../../pages/AddUserPage';
import FeeManagement from '../../pages/FeeManagement';
import SupperVision from '../../pages/supperVission';
import DepartmentMaster from '../../pages/college/Office/Department Master/DepartmentMaster';
import NIFR from '../../pages/college/NIFR/NIFR';
import RolloutManagement from '../../pages/college/Accademics/RolloutManagement/RolloutManagement';
import { CashBook } from '../../CollegeApp/Accounts/CashBook';
import { FullScreenContext } from '../../context/FullScreenContext';
import Profile from '../Profile';
import { ProgressBar } from '../../NecttosComp/ProgressBar/ProgressBar';
import { icons } from './Icons';
import InvoiceManagement from '../../CollegeApp/Library/InvoiceManagement';
import { closeAnimation } from '../../NecttosComp/Layout/Layout';
import LoastRegister from '../../CollegeApp/Library/LostRegister';
import HungryFreeCampus from '../../CollegeApp/Office/HungryFreeCampus';
import Button from '../../NecttosComp/Button/Button';
import { useQuery } from 'react-query';
import { BankCash } from '../../CollegeApp/Accounts/BankCash';
import BEDStudent from '../../CollegeApp/Academics/BEDStudent';
import C2 from '../../CollegeApp/NaaC/C2';
import ProFacMonitor from '../../CollegeApp/Academics/ProFacMonitor';
import AccademicHoliday from '../../CollegeApp/Academics/AccademicHoliday';
import DeleteUser from '../../CollegeApp/Office/DeleteUser';
import MentorMentee from '../../CollegeApp/Academics/MentorMentee';
import MasterStockRegister from './MasterStockRegister';
import QuestionBankCreator from '../../CollegeApp/QuestionPaperManagement/QuestionBankCreator';
import QuestionBankCreatorVerification from '../../CollegeApp/QuestionPaperManagement/QuestionBankCreatorVerification';
import QuestionPaper from '../../CollegeApp/QuestionPaperManagement/QuestionPaper';
import QuetionBankManager from '../../CollegeApp/QuestionPaperManagement/QuetionBankManager';
import C1 from '../../CollegeApp/NaaC/C1';
import C3 from '../../CollegeApp/NaaC/C3';
import { Analytics } from '../../CollegeApp/Office/Analystics';
import ApplicationRecieved from '../../CollegeApp/Admission/CompletedApplications';
import EnquriyForm from '../../CollegeApp/Admission/EnquiriesReceived';
import InsertData from '../../CollegeApp/Office/InsertData';
import UpdateData from '../../CollegeApp/Office/UpdateData';
import AllotmentList from '../../CollegeApp/Admission/AllotmentList';
import RankListRules from '../../CollegeApp/Admission/RankListRules';
import RecheckPayments from '../../CollegeApp/Accounts/RecheckPayments';
import PyementWaitingSubmition from '../../CollegeApp/Admission/PendingApplicationswithFeesPaid';
import CordinatorShipAdmin from '../../CollegeApp/EventsManagment/CordinatorShipAdmin';
import CordinatorShipForm from '../../CollegeApp/EventsManagment/CordinatorShipForm';
import ClubCellsAccounts from '../../CollegeApp/EventsManagment/ClubCellsAccounts';
import CourseManagement from '../../CollegeApp/Office/CourseManagement';
import ApplicationStarted from '../../CollegeApp/Admission/ApplicationsInitiated';
import CourseWiseRankList from '../../CollegeApp/Admission/CourseWiseRankList';
import SeatReservationDetails from '../../CollegeApp/Admission/SeatReservationDetails';
import Input from '../../NecttosComp/Input/Input';
import DueRegister from '../../CollegeApp/Library/DueRegister';
import PriorityChanger from '../../CollegeApp/Admission/PriorityChanger';
import Certificate from '../../CollegeApp/Office/Certificate';
import AdmissionRegister2 from '../../CollegeApp/Admission/AdmissionRegister';
import PhotoVerification from '../../CollegeApp/Admission/PhotoVerification';
import AdmissionRegister3 from '../../CollegeApp/Admission/AdmissionRegister3';
import ExamInit from '../../CollegeApp/Examination/ExamInit';
import ExamNotification from '../../CollegeApp/Examination/Notification';
import Condonation from '../../CollegeApp/Examination/Condonation';
import ElectiveChanger from '../../CollegeApp/Academics/ElectiveChanger';
import AccountActiveFirebase from '../../CollegeApp/Accounts/AccountActiveFirebase';
import PhotoDownloader from '../../CollegeApp/Office/PhotoDownloader';
import Numbering from '../../CollegeApp/Office/Numbering';
import AnswerSheetBundleManagement from '../../CollegeApp/Examination/AnswerSheetBundleManagement';
import ExamRegistration from '../../CollegeApp/Examination/ExamRegistration';
import TimeTableManagement from '../../CollegeApp/Examination/TimeTableManagement';
import SeatingPlan from '../../CollegeApp/Examination/SeatingPlan';
import RoomArrangement from '../../CollegeApp/Examination/RoomArrangement';
import InvigilationManagement from '../../CollegeApp/Examination/InvigilationManagement';
import HalltiketDistribution from '../../CollegeApp/Examination/HalltiketDistribution';
import RoomDocuments from '../../CollegeApp/Examination/RoomDocuments';
import BarcodeDownload from '../../CollegeApp/Examination/BarcodeDownload';
import ValuationManagement from '../../CollegeApp/Examination/ValuationManagement';
import ResultPublication from '../../CollegeApp/Examination/ResultPublication';
import FalseNumbering from '../../CollegeApp/Examination/FalseNumbering';
import StudentResponds from '../../CollegeApp/FYUGP/StudentResponds';
import SubjectRegistry from '../../CollegeApp/FYUGP/SubjectRegistry';
import RealTimeAllotment from '../../CollegeApp/FYUGP/RealTimeAllotment';
import RealTimeRanking from '../../CollegeApp/FYUGP/RealTimeRanking';
import DataLoader from '../../CollegeApp/Office/DataLoader';
import StudentsAllotmentClassWise from '../../CollegeApp/FYUGP/StudentsAllotmentClassWise';
import FYUGPDashBoard from '../../CollegeApp/FYUGP/FYUGPDashBoard';
import ConfirmedClassWiseAllotment from '../../CollegeApp/FYUGP/ConfirmedClassWiseAllotment';
import ConfirmedCourseWiseAllotment from '../../CollegeApp/FYUGP/ConfirmedCourseWiseAllotment';
import BulkFeeData from '../../CollegeApp/Academics/BulkFeeData';
import { MonthlyFeeCollectionRegister } from '../../CollegeApp/Accounts/MonthlyFeeCollectionRegister';
import { RecieptsPayments } from '../../CollegeApp/Accounts/RecieptPaymentNew';
import { DayBook } from '../../pages/college/Accounts/DayBook';
import { SubsidiaryRegisterYearWise } from '../../CollegeApp/Accounts/SubsidiaryRegisterYearWise';
import { CollectioReportsNew } from '../../CollegeApp/Accounts/CollectioReportsNew';
import { DFCR } from '../../CollegeApp/Accounts/DFCR';
import SubjectAllocationAuto from '../../CollegeApp/FYUGP/SubjectAllocationAuto';
import SportAdmission from '../../CollegeApp/FYUGP/SportAdmission';
import UniversityUploadList from '../../CollegeApp/FYUGP/UniversityUploadList';
import TagOrder from '../../CollegeApp/Office/TagOrder';
import { AttendanceCorrectionBulk } from '../../CollegeApp/Office/AttendanceCorrectionBulk';
import { NewNameSlip } from '../../pages/college/ReportModals/NameSlip/NameSlip';
import NewTransferCertificate from '../../CollegeApp/Admission/NewTransferCertificate';
import DataManagementSystem from '../../CollegeApp/Admission/DataManagementSystem';
import NAAC2024 from '../../CollegeApp/NaaC/NAAC2024';
import { FeeConcession } from '../../pages/college/Accounts/feeConcession';
import { InternalAssessment } from '../../CollegeApp/Office/InternalAssessment';
import { Releaving } from '../../CollegeApp/Office/Releaving';
import NewProfile from '../NewProfile';
import SemesterWiseStudentData from '../../CollegeApp/Academics/SemesterWiseStudentData';
import { LeaveReports } from '../../CollegeApp/Office/LeaveReports';
import Cookies from 'js-cookie';
import { NEWDCB } from '../../CollegeApp/Accounts/newDCB';


function getFragmentParams(fragment) {
	const params = new URLSearchParams(fragment);
	return Array.from(params.keys()).reduce((acc, key) => {
		acc[key] = params.get(key);
		return acc;
	}, {});
}

function LibraryReports() {
	const { data } = useProfileData();
	const { collegeId, collegeList } = useContext(FirebaseContext);

	const [tokenDetails, setTokenDetails] = useState({});

	useEffect(() => {
		const fragment = window.location.hash.substr(1); // Remove the '#' symbol
		const fragmentParams = getFragmentParams(fragment);
		setTokenDetails(fragmentParams);
	}, []);

	const [criteria, setCriteria] = usePersistentState('homePage', {});
	const [head, setHead] = usePersistentState('showPage', 'All Menu Items');
	const [windows, setWindows] = usePersistentState('homePage', {
		issueBook: false,
		renewBook: false,
		returnBook: false,
		opac: false,
		master: false,
		questionBankCreator: false,
	});

	const [classification, setClassification] = useState('');

	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

	const { isFullScreen, togleFullScreen } = useContext(FullScreenContext);

	useEffect(() => {
		document.addEventListener('DOMContentLoaded', () => {
			const appWindow = document.querySelector('.app-window');
			appWindow.classList.add('open');
		});
		return () => {
			document.removeEventListener('DOMContentLoaded', () => {
				appWindow.classList.add('open');
				const appWindow = document.querySelector('.app-window');
			});
		};
	}, []);

	let verificationWindows = selectedCollege?.verificationWindows;

	let allBookList = [];

	let MENU_ITEMS = [
		//Office
		{
			classification: 'Office',
			keyData: 'rollView',
			title: 'Roll View',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'office', 'principal', 'teacher'],
		},
		{
			classification: 'Office',
			keyData: 'dataLoader',
			title: 'Data Loader & Checker',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'office', 'principal', 'teacher'],
		},
		{
			classification: 'Office',
			keyData: 'nameSlip',
			title: 'Name Slip',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'superintend', 'office', 'teacher', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'newNameSlip',
			title: 'New Name Slip',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'superintend', 'office', 'teacher', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'statistics',
			title: 'Statistics',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal', 'teacher', 'library'],
		},
		{
			classification: 'Office',
			keyData: 'internalAssessment',
			title: 'Internal Assessment',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal', 'teacher', 'library'],
		},
		{
			classification: 'Office',
			keyData: 'leaveReports',
			title: 'Leave Reports',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal', 'teacher', 'library'],
		},
		{
			classification: 'Office',
			keyData: 'releaving',
			title: 'Releaving',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal', 'teacher', 'library'],
		},
		{
			classification: 'Office',
			keyData: 'photoCheck',
			title: 'Photo Check',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'tcCc',
			title: 'Transfer Certificate',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'catogoryList',
			title: 'CatogoryList',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'attendance', 'office', 'superintend', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'strengthDetails',
			title: 'Strength Details',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'attendance', 'office', 'superintend', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'earnLeaveCalculator',
			title: 'Earn Leave Calculator',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'masterTable',
			title: 'Master Table',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'cashier', 'office'],
		},
		{
			classification: 'Office',
			keyData: 'supperVission',
			title: 'Super Vision',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'addUser',
			title: 'Add New',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher'],
		},
		{
			classification: 'Office',
			keyData: 'deleteUser',
			title: 'Delete User',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal'],
		},
		{
			classification: 'Office',
			keyData: 'tagOrder',
			title: 'Tag Order',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'office'],
		},
		{
			classification: 'Office',
			keyData: 'idCardPrinting',
			title: 'ID Card Printing',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal', 'office', 'teacher'],
		},
		{
			classification: 'Office',
			keyData: 'hungerFreeCampus',
			title: 'Hunger Free Campus',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['principal', 'teacher'],
		},
		// { classification: "Office", keyData: "analytics", title: "Analytics", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["principal", "teacher"] },
		{
			classification: 'Office',
			keyData: 'insertUsers',
			title: 'Insert Users',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['principal', 'teacher', 'office'],
		},
		{
			classification: 'Office',
			keyData: 'attendanceCorrectionBulk',
			title: 'Attendance Correction Bulk',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['principal', 'office'],
		},

		{
			classification: 'Office',
			keyData: 'updateUsers',
			title: 'Update Users',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['principal', 'teacher', 'office'],
		},
		{
			classification: 'Office',
			keyData: 'courseManagement',
			title: 'Course Management',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['principal', 'teacher', 'office'],
		},
		{
			sortKey: '0044',
			classification: 'Office',
			keyData: 'certificate',
			title: 'Certificate',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'Office'],
		},
		{
			classification: 'Office',
			keyData: 'accountActiveFirebase',
			title: 'Account Active Firebase',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'clerk', 'teacher', 'Office'],
		},
		{
			classification: 'Office',
			keyData: 'photoDownload',
			title: 'Photo Download',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office'],
		},
		{
			classification: 'Office',
			keyData: 'rollNumberDistributer',
			title: 'Roll Number Distributer',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office'],
		},

		//photoDownload //rollNumberDistributer
		//admission
		// ...(verificationWindows?.creatAccountFromSinglePoint ? [{ classification: "Admission", keyData: "createAccount", title: "1. Create Account", img: applicationForm, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "creatAccountFromSinglePoint", "headAccountant", "clerk", "superintend", "principal", "teacher", "office"] }] : []),
		// ...(verificationWindows?.applicationForm ? [{ classification: "Admission", keyData: "applicationForm", title: "2. Application Form", img: applicationForm, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "applicationForm", "headAccountant", "superintend", "clerk", "principal", "teacher", "office", "student"] }] : []),
		// ...(verificationWindows?.nodalVerification ? [{ sortKey: "009", classification: "Admission", keyData: "nodalVerificationTemp", title: "Nodel Temp Verification", img: applicationForm, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "superintend", "nodalVerification", "headAccountant", "principal", "nodal"] }] : []),

		...(verificationWindows?.departmentVerfication
			? [
					{
						sortKey: '007',
						classification: 'Admission',
						keyData: 'departmentVerification',
						title: 'Department Verification',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'departmentVerfication', 'superintend', 'principal', 'teacher'],
					},
			  ]
			: []),
		...(verificationWindows?.nodalVerification
			? [
					{
						sortKey: '008',
						classification: 'Admission',
						keyData: 'nodalVerification',
						title: collegeId === 'COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F' ? 'Department Senior Verification' : 'Nodal Verification',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'nodalVerification', 'teacher', 'headAccountant', 'principal', 'nodal'],
					},
			  ]
			: []),
		...(verificationWindows?.collegeFundCollection
			? [
					{
						sortKey: '010',
						classification: 'Admission',
						keyData: 'ptaVerification',
						title: 'PTA Collection',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'collegeFundCollection', 'headAccountant', 'principal', 'office', 'nodal'],
					},
			  ]
			: []),
		...(verificationWindows?.principalVerification
			? [
					{
						sortKey: '011',
						classification: 'Admission',
						keyData: 'principalVerification',
						title: 'Principal Verification',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'principalVerification', 'superintend', 'principal', 'office', 'nodal'],
					},
			  ]
			: []),
		...(verificationWindows?.officeVerification
			? [
					{
						sortKey: '012',
						classification: 'Admission',
						keyData: 'officeVerification',
						title: 'Office Verification',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'officeVerification', 'headAccountant', 'principal', 'clerk'],
					},
			  ]
			: []),
		...(verificationWindows?.govermentFeeCollection
			? [
					{
						sortKey: '014',
						classification: 'Admission',
						keyData: 'feesVerification',
						title: 'Fees Collection',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'headAccountant', 'govermentFeeCollection', 'cashier', 'principal'],
					},
			  ]
			: []),
		...(verificationWindows?.universityUploads
			? [
					{
						sortKey: '015',
						classification: 'Admission',
						keyData: 'universityUploading',
						title: 'University Upload',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'universityUploads', 'teacher', 'nodal'],
					},
			  ]
			: []),
		...(verificationWindows?.grandVerification
			? [
					{
						sortKey: '013',
						classification: 'Admission',
						keyData: 'grandVerification',
						title: 'E-Grantz Verification',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'grandVerification', 'cashier'],
					},
			  ]
			: []),
		...(verificationWindows?.enquiriesReceived
			? [
					{
						sortKey: '001',
						classification: 'Admission',
						keyData: 'enquryForm',
						title: 'Enquiries Received',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
					},
			  ]
			: []),
		...(verificationWindows?.applicationsInitiated
			? [
					{
						sortKey: '002',
						classification: 'Admission',
						keyData: 'applicationStated',
						title: 'Applications Initiated',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
					},
			  ]
			: []),
		...(verificationWindows?.pendingApplicationswithFeesPaid
			? [
					{
						sortKey: '003',
						classification: 'Admission',
						keyData: 'pyementWaitingSubmition',
						title: 'Pending Applications with Fees Paid',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
					},
			  ]
			: []),
		...(verificationWindows?.completedApplications
			? [
					{
						sortKey: '004',
						classification: 'Admission',
						keyData: 'applicationRecieved',
						title: 'Completed Applications',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
					},
			  ]
			: []),
		...(verificationWindows?.courseWiseRankList
			? [
					{
						sortKey: '005',
						classification: 'Admission',
						keyData: 'indexMarkListed',
						title: 'Course-Wise Ranking',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher'],
					},
			  ]
			: []),
		...(verificationWindows?.seatReservationDetails
			? [
					{
						sortKey: '005',
						classification: 'Admission',
						keyData: 'seatReservation',
						title: 'Seat Reservation Details',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher'],
					},
			  ]
			: []),
		...(verificationWindows?.officeVerification
			? [
					{
						sortKey: '0044',
						classification: 'Admission',
						keyData: 'priorityChanger',
						title: 'Priority Changer',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher'],
					},
			  ]
			: []),
		...(verificationWindows?.allotmentList
			? [
					{
						sortKey: '006',
						classification: 'Admission',
						keyData: 'rankListAuto',
						title: 'Real-Time Allotment',
						img: applicationForm,
						instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
						allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
					},
			  ]
			: []),

		{
			classification: 'Admission',
			keyData: 'photoVerification',
			title: 'Photo Verification',
			img: applicationForm,
			instituitionType: ['aidedCollege', 'selfCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'staff'],
		},
		{
			classification: 'Admission',
			keyData: 'rankList',
			title: 'Rank List PDF',
			img: applicationForm,
			instituitionType: ['aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'staff'],
		},
		{
			classification: 'Admission',
			keyData: 'tcCc',
			title: 'Transfer Certificate',
			img: transferCertificate,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal'],
		},
		{
			classification: 'Admission',
			keyData: 'controller',
			title: 'Controller',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'clerk', 'principal', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'dashBoard',
			title: 'Dash Board',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'splitups',
			title: 'Splitups',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'admissionRegister',
			title: 'Admission Register',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'teacher', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'admissionRegister2',
			title: 'Admission Register 2',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'teacher', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'admissionRegister3',
			title: 'Admission Register 3',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'teacher', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'epos',
			title: 'E-POS Migration',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'rankListRules',
			title: 'Rank List Rules',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
		},
		{
			classification: 'Admission',
			keyData: 'newTransferCertificate',
			title: 'New Transfer Certificate',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'teacher', 'nodal'],
		},
		{
			classification: 'Admission',
			keyData: 'dataManagementSystem',
			title: 'Data Management System',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'teacher', 'nodal'],
		},
		{
			classification: 'Adminitration',
			keyData: 'hierarchy',
			title: 'Hierarchy',
			img: hierarchy,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'superintend'],
		},
		{
			classification: 'Adminitration',
			keyData: 'postBulkJournals',
			title: 'Post Bulk Journals',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin'],
		},
		{
			classification: 'Adminitration',
			keyData: 'workingFile',
			title: 'Working File',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin'],
		},
		{
			classification: 'Adminitration',
			keyData: 'profile',
			title: 'Profile',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin'],
		},
		{
			classification: 'Adminitration',
			keyData: 'newProfile',
			title: 'Profile',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin'],
		},

		{
			classification: 'Accounts',
			keyData: 'feeStructure',
			title: '01. Fee Structure',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'instanceFeeCollection',
			title: '02. Fee Demand',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'feesBilling',
			title: '03. Fee Receipt',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'eGrantManagement',
			title: '05. E-Grantz Management',
			img: applicationForm,
			instituitionType: ['aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintend', 'principal', 'clerk', 'grandVerification', 'cashier'],
		},
		{
			classification: 'Accounts',
			keyData: 'dailyFeeCollectionRegister',
			title: '06. DFCR',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'newDCB',
			title: ' DCB',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'newDFCR',
			title: 'NEW DFCR',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'dayBook',
			title: '07. Day Books',
			img: dayBook,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'collectionReports',
			title: '08. Collection Reports',
			img: collectedFee,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'collectioReportsNew',
			title: 'Collection Reports',
			img: collectedFee,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			fileName: 'IncomeExpenditure',
			keyData: 'incomeExpenditure',
			title: '09. Income & Expenditure',
			img: cashBook,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			fileName: 'ReceiptPayments',
			keyData: 'receiptPayments',
			title: 'Receipts & Payments Accounts',
			img: cashBook,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'subsidiaryRegister',
			title: '10. Subsidiary Register',
			img: subsidiaryRegister,
			instituitionType: ['aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'subsidiaryRegisterYearWise',
			title: 'New Subsidiary Register',
			img: subsidiaryRegister,
			instituitionType: ['aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'headAccountant', 'cashier', 'superintend', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'dcb',
			title: '12. DCB',
			img: subsidiaryRegister,
			instituitionType: ['aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'cashBook',
			title: '11. Cash Books',
			img: cashBook,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'mainAccounts',
			title: 'Main Accounts',
			img: accounts,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'subAccounts',
			title: 'Sub Accounts',
			img: accounts,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'cashBankReports',
			title: 'Cash Bank Reciepts',
			img: collectedFee,
			instituitionType: [],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'fdcb',
			title: '13. Due Checker P Wise (FDCB)',
			img: collectedFee,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'duesReport',
			title: 'Due Reports',
			img: dues,
			instituitionType: ['selfCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'scholarship',
			title: 'Scholarship',
			img: scholarship,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'feeConcession',
			title: 'Fee Concession',
			img: scholarship,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'numbers',
			title: 'Numbers',
			img: scholarship,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'superintend', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'feeManagement',
			title: 'Fee Management',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'bankCash',
			title: 'Bank / Cash',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'reChecker',
			title: 'Re Check Payemnts',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Accounts',
			keyData: 'tutionFeeData',
			title: 'Bulk Student Fee Data',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},

		{
			classification: 'NAAC',
			keyData: 'naacDataCollection',
			title: 'NAAC Questionnair',
			img: feedback,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c1',
			title: 'Criteria 1',
			img: file1,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c2',
			title: 'Criteria 2',
			img: file2,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c3',
			title: 'Criteria 3',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c4',
			title: 'Criteria 4',
			img: file4,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c5',
			title: 'Criteria 5',
			img: file5,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c6',
			title: 'Criteria 6',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'c7',
			title: 'Criteria 7',
			img: file7,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},

		{
			classification: 'c1',
			keyData: 'c1_1',
			title: '1.1',
			img: file1,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'c1',
			keyData: 'c1',
			title: '1.1.3',
			img: file2,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'NAAC',
			keyData: 'naac2024',
			title: 'NAAC 2024',
			img: file1,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'c1',
			keyData: 'c1',
			title: '1.2.1',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'c1',
			keyData: 'c1',
			title: '1.2.2 & 1.2.3',
			img: file4,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'c1',
			keyData: 'c1',
			title: '1.3.2',
			img: file5,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},
		{
			classification: 'c1',
			keyData: 'c1',
			title: '1.3.3',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'iqac', 'teacher'],
		},

		{
			classification: 'c2',
			keyData: 'c2_1',
			title: '2.1',
			img: file1,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_4',
			title: '2.1.1',
			img: file2,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_5',
			title: '2.1.2',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_9',
			title: '2.1.2 & 2.1.1',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_2',
			title: '2.2',
			img: file4,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2',
			title: '2.2.1',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_3',
			title: '2.3',
			img: file5,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_10',
			title: '2.3.3',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2',
			title: '2.3.2',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_6',
			title: '2.4.1',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_7',
			title: '2.4.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_6',
			title: '2.4.3',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2',
			title: '2.5.1',
			img: file3,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},
		{
			classification: 'c2',
			keyData: 'c2_8',
			title: '2.6.3',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac', 'naac'],
		},

		{
			classification: 'c3',
			keyData: 'c3_1a',
			title: '3.1(a)',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_1b',
			title: '3.1(b)',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c2_7',
			title: '3.1.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c2_7',
			title: '3.3.1',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_2',
			title: '3.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_1_1',
			title: '3.1.1 & 3.1.3',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_2_2',
			title: '3.2.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_3_2',
			title: '3.3.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_3_3',
			title: '3.3.3',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_4_2',
			title: '3.4.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_4_3',
			title: '3.4.3 & 3.4.4',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_5_1',
			title: '3.5.1',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},
		{
			classification: 'c3',
			keyData: 'c3_5_2',
			title: '3.5.2',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal', 'iqac'],
		},

		{
			classification: 'NIFR',
			keyData: 'NIFR',
			title: 'NIRF',
			img: file6,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'nirf', 'iqac', 'naac'],
		},

		{
			classification: 'IQAC',
			keyData: 'staffProfile',
			title: 'Staff Profile',
			img: staff,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'teacherProfile',
			title: 'Teacher Profile',
			img: staff,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'studentProfile',
			title: 'Student Profile',
			img: student,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'pendingDocument',
			title: 'Pending Documents',
			img: pending,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'verifiedDocuments',
			title: 'Verified Documents',
			img: verified,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'masterTimetable',
			title: 'Master Timetable',
			img: master2,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'subjectAllocation',
			title: 'Subject Allocation',
			img: allocation,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'attendanceData',
			title: 'Attendance',
			img: atts,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},
		{
			classification: 'IQAC',
			keyData: 'feedBackReports',
			title: 'Feed Back Report',
			img: feedback,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'iqac'],
		},

		{
			classification: 'Accademics',
			keyData: 'attendanceMonthlyRegister',
			title: 'Attendance Monthly Register',
			img: attendanceRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'masterTimeTables',
			title: 'Master Timetables',
			img: transferCertificate,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'apc',
			title: 'APC',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'subjectWiseAttendance',
			title: 'Subject Wise Attendance',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'classWiseAttendance',
			title: 'Class Wise Attendance',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'rolloutManagement',
			title: 'Rollout Management',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'internal',
			title: 'Internal Form',
			img: applicationForm,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'openCourse',
			title: 'Open Course',
			img: scholarship,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'menteeForm',
			title: 'Mentee Profile PDF',
			img: scholarship,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'subjectBank',
			title: 'Subject Bank',
			img: sb,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'timetable',
			title: 'Time Table',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'departmentMaster',
			title: 'Department Master',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher'],
		},
		{
			classification: 'Accademics',
			keyData: 'profac',
			title: 'Profac Monitor',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal'],
		},
		{
			classification: 'Accademics',
			keyData: 'accadamicHoliday',
			title: 'Accadamic Holiday Management',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Accademics',
			keyData: 'semesterWiseStudentData',
			title: 'SemesterWise Student Data',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Accademics',
			keyData: 'essentialData',
			title: 'Student Data Management',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Accademics',
			keyData: 'mentorMentee',
			title: 'Mentor Mentee',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Accademics',
			keyData: 'masterstockregister',
			title: 'Master Stock Register',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Accademics',
			keyData: 'electiveChanger',
			title: 'Elective Changer',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},

		{
			sortKey: '001',
			classification: 'FYUGP',
			keyData: 'subjectRegistry',
			title: 'Registered Courses',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			sortKey: '002',
			classification: 'FYUGP',
			keyData: 'studentResponds',
			title: 'Student Responses',
			img: 'statistics',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			sortKey: '003',
			classification: 'FYUGP',
			keyData: 'fyugpStatistics',
			title: 'FYUGP Data Analytics',
			img: 'statistics',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			sortKey: '004',
			classification: 'FYUGP',
			keyData: 'standardizationMetrics',
			title: 'FYUGP Standardization Metrics',
			img: 'statistics',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			sortKey: '005',
			classification: 'FYUGP',
			keyData: 'realTimeRanking',
			title: 'FYUGP Live Ranking',
			img: 'applicationForm',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintendent', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
		},
		{
			sortKey: '006',
			classification: 'FYUGP',
			keyData: 'realTimeAllotment',
			title: 'FYUGP Live Allotment',
			img: 'applicationForm',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintendent', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
		},
		{
			sortKey: '007',
			classification: 'FYUGP',
			keyData: 'courseSchedule',
			title: 'FYUGP Course Schedule',
			img: 'applicationForm',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'superintendent', 'principal', 'clerk', 'nodal', 'teacher', 'staff'],
		},
		{
			sortKey: '008',
			classification: 'FYUGP',
			keyData: 'allotmentListClassWise',
			title: 'Allotment Report Class Wise',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			sortKey: '009',
			classification: 'FYUGP',
			keyData: 'fyugpDashBoard',
			title: 'Allotment Dash Board',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			sortKey: '010',
			classification: 'FYUGP',
			keyData: 'confirmedClassWiseAllotment',
			title: 'Confirmed Class Wise Allotment ',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			sortKey: '011',
			classification: 'FYUGP',
			keyData: 'confirmedCourseWiseAllotment',
			title: 'Confirmed Course Wise Allotment',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			sortKey: '012',
			classification: 'FYUGP',
			keyData: 'sportAdmission',
			title: 'Sport Admission',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			sortKey: '013',
			classification: 'FYUGP',
			keyData: 'universityUploadList',
			title: 'University Upload List',
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'office', 'superintendent', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},

		{
			classification: 'Events',
			keyData: 'cordinatorShipAdmin',
			title: 'Co-ordinator Ship Admin',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Events',
			keyData: 'cordinatorShipForm',
			title: 'Co-ordinator Ship Form',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},
		{
			classification: 'Events',
			keyData: 'clubCellsAccounts',
			title: 'Club Cells Accounts',
			img: statistics,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'teacher', 'staff'],
		},

		{
			classification: 'Library',
			keyData: 'opac',
			title: 'OPAC',
			img: opac,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant', 'libraryMember', 'teacher', 'student', 'staff', 'principal'],
		},
		{
			classification: 'Library',
			keyData: 'issueBook',
			title: 'Issue',
			img: issue,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant'],
		},
		{
			classification: 'Library',
			keyData: 'renewBook',
			title: 'Renew',
			img: reniew,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant', 'libraryMember'],
		},
		{
			classification: 'Library',
			keyData: 'returnBook',
			title: 'Return',
			img: returnBook,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant'],
		},
		{
			classification: 'Library',
			keyData: 'activeRegister',
			title: 'Active Register',
			img: activeRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant', 'principal'],
		},
		{
			classification: 'Library',
			keyData: 'members',
			title: 'Members',
			img: members,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant'],
		},
		{
			classification: 'Library',
			keyData: 'master',
			title: 'Master',
			img: master,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'lostRegister',
			title: 'Lost register',
			img: lost,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'periodicals',
			title: 'Periodicals',
			img: suscribe,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'periodicalsEntry',
			title: 'Periodicals Entry',
			img: suscribe,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant'],
		},
		{
			classification: 'Library',
			keyData: 'movements',
			title: 'Movement',
			img: movement,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant'],
		},
		{
			classification: 'Library',
			keyData: 'fineRegister',
			title: 'Fine Register',
			img: subsidiaryRegister,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant', 'principal', 'libraryMember'],
		},
		{
			classification: 'Library',
			keyData: 'footPrint',
			title: 'Foot Print',
			img: footprint,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant', 'libraryMember'],
		},
		{
			classification: 'Library',
			keyData: 'addBook',
			title: 'Add Books',
			img: addBook,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'labels',
			title: 'Labels',
			img: labels,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant'],
		},
		{
			classification: 'Library',
			keyData: 'ddcSubjects',
			title: 'DDC',
			img: ddc,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'holidays',
			title: 'Holiday',
			img: holyday,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'eResources',
			title: 'E-Resources',
			img: holyday,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'libraryStatisticsExport',
			title: 'Export',
			img: exportPng,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'bookRecomentation',
			title: 'Book Recomentation',
			img: holyday,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'invoiceManagement',
			title: 'Invoice Management',
			img: holyday,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},
		{
			classification: 'Library',
			keyData: 'dueRegister',
			title: 'Due Register',
			img: holyday,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library'],
		},

		{
			classification: 'Election',
			keyData: 'post',
			title: 'Post ',
			img: accounts,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Election',
			keyData: 'nomination',
			title: 'Nomination ',
			img: accounts,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Election',
			keyData: 'counting',
			title: 'Counting ',
			img: accounts,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'cashier', 'office', 'principal'],
		},
		{
			classification: 'Election',
			keyData: 'electoralRoll',
			title: 'Electoral Roll',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'office', 'principal'],
		},

		{
			classification: 'QuestionPaper',
			keyData: 'subjectBank',
			title: '01. Subject Bank',
			img: sb,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			classification: 'QuestionPaper',
			keyData: 'questionBankCreator',
			title: '02. Question Bank Creator',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			classification: 'QuestionPaper',
			keyData: 'questionBankCreatorVerification',
			title: '03. Question Bank Verification',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			classification: 'QuestionPaper',
			keyData: 'questionBankManager',
			title: '04. Question Bank Manager',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			classification: 'QuestionPaper',
			keyData: 'questionBank',
			title: '04. Question Paper',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			classification: 'QuestionPaper',
			keyData: 'questionPaperCreation',
			title: 'Question Paper Creation',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},

		{
			sortKey: '001',
			classification: 'Examination',
			keyData: 'examInit',
			title: 'Exam Init',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '002',
			classification: 'Examination',
			keyData: 'examNotification',
			title: 'Exam Notification',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '003',
			classification: 'Examination',
			keyData: 'condonation',
			title: 'Condonation',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '004',
			classification: 'Examination',
			keyData: 'examRegistration',
			title: 'Exam Registration',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '005',
			classification: 'Examination',
			keyData: 'timeTableManagement',
			title: 'Time Table Management',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '006',
			classification: 'Examination',
			keyData: 'seatingPlan',
			title: 'Seating Arragement',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '007',
			classification: 'Examination',
			keyData: 'roomArrangement',
			title: 'Room Arrangement',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '008',
			classification: 'Examination',
			keyData: 'invigilationManagement',
			title: 'Invigilation Management',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '009',
			classification: 'Examination',
			keyData: 'halltiketDistribution',
			title: 'Halltiket Distribution',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '011',
			classification: 'Examination',
			keyData: 'roomDocuments',
			title: 'Room Documents Download',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '0111',
			classification: 'Examination',
			keyData: 'falseNumbering',
			title: 'False  Numbering',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '012',
			classification: 'Examination',
			keyData: 'barcodeDownload',
			title: 'Barcode Download',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '0122',
			classification: 'Examination',
			keyData: 'answerSheetBundleManagement',
			title: 'Answer Sheet Bundle Management',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '013',
			classification: 'Examination',
			keyData: 'valuationManagement',
			title: 'Valuation Management',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},
		{
			sortKey: '014',
			classification: 'Examination',
			keyData: 'resultPublication',
			title: 'Result  Publication',
			img: electoralRoll,
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'teacher', 'principal'],
		},

		// { sortKey: "015", classification: "Examination", keyData: "revaluation", title: "Revaluation/Scrutony Management", img: electoralRoll, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
		// { sortKey: "016", classification: "Examination", keyData: "suplimentory", title: "Suplimentory/Improvement Management", img: electoralRoll, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
		// { sortKey: "017", classification: "Examination", keyData: "tabulation", title: "Tabulation & Sending University", img: electoralRoll, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
	];

	useEffect(() => {
		let currentDraggedElement = null;

		for (const i in MENU_ITEMS) {
			const item = MENU_ITEMS[i];
			const elem = document.getElementById(item.keyData);

			if (elem) {
				elem.addEventListener('dragstart', (e) => {
					currentDraggedElement = elem;
					e.dataTransfer.setData('text/plain', '');
				});

				elem.addEventListener('dragover', (e) => {
					if (currentDraggedElement === elem) {
					}
				});

				elem.addEventListener('dragend', (e) => {
					const newX = e.clientX - elem.offsetWidth / 2;
					const newY = e.clientY - elem.offsetHeight / 2;

					elem.style.position = 'absolute';
					elem.style.left = `${newX}px`;
					elem.style.top = `${newY}px`;
					currentDraggedElement = null;
				});
			}
		}
	}, []);

	MENU_ITEMS = MENU_ITEMS?.filter((x) => x?.instituitionType?.includes(selectedCollege?.type));

	MENU_ITEMS = MENU_ITEMS?.sort((a, b) => a?.title?.localeCompare(b?.title));
	MENU_ITEMS = MENU_ITEMS?.sort((a, b) => a?.sortKey?.localeCompare(b?.sortKey));

	let classificationArray = [
		{
			title: 'All Menu',
			fullName: 'All Menu',
			keyData: 'all',
			values: MENU_ITEMS.filter((x) => !['c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7'].includes(x.classification)),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'principal', 'student', 'teacher', 'staff'],
		},
		{
			title: 'Office',
			fullName: 'Office',
			keyData: 'office',
			values: MENU_ITEMS.filter((x) => x.classification === 'Office'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'teacher', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk'],
		},
		{
			title: 'Admission',
			fullName: 'Admission',
			keyData: 'admission',
			values: MENU_ITEMS.filter((x) => x.classification === 'Admission'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'pricipal', 'teacher', 'office', 'superintend', 'headAccountant', 'cashier'],
		},
		{
			title: 'Accounts',
			fullName: 'Accounts',
			keyData: 'accounts',
			values: MENU_ITEMS.filter((x) => x.classification === 'Accounts'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'superintend', 'headAccountant', 'cashier'],
		},
		{
			title: 'Academics',
			fullName: 'Academics',
			keyData: 'academics',
			values: MENU_ITEMS.filter((x) => x.classification === 'Accademics'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			title: 'FYUGP',
			fullName: 'FOUR YEAR UNDER GRADUATE PROGRAMME',
			keyData: 'FYUGP',
			values: MENU_ITEMS.filter((x) => x.classification === 'FYUGP'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			title: 'Events',
			fullName: 'Events Administrations',
			keyData: 'events',
			values: MENU_ITEMS.filter((x) => x.classification === 'Events'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant', 'cashier', 'typist', 'technicalAssistant', 'computerAssistant', 'clerk', 'teacher'],
		},
		{
			title: 'Library',
			fullName: 'Library',
			keyData: 'library',
			values: MENU_ITEMS.filter((x) => x.classification === 'Library'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'library', 'libraryAssistant', 'libraryMember'],
		},
		{
			title: 'Election',
			fullName: 'Election',
			keyData: 'election',
			values: MENU_ITEMS.filter((x) => x.classification === 'Election'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'pricipal', 'office', 'superintend', 'headAccountant'],
		},
		{
			title: 'Examination',
			fullName: 'Examination',
			keyData: 'examination',
			values: MENU_ITEMS.filter((x) => x.classification === 'Examination'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'pricipal', 'teacher'],
		},
		{
			title: 'NAAC',
			keyData: 'naac',
			fullName: 'National Assessment and Accreditation Council.',
			values: MENU_ITEMS.filter((x) => x.classification === 'NAAC'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'teacher', 'principal', 'naac', 'iqac'],
		},
		{
			title: 'NIRF',
			keyData: 'nirf',
			fullName: 'National Institutional Ranking Framework.',
			values: MENU_ITEMS.filter((x) => x.classification === 'NIFR'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'teacher', 'principal', 'nirf', 'iqac'],
		},
		{
			title: 'KIRF',
			keyData: 'kirf',
			fullName: 'Kerala Institutional Ranking Framework.',
			values: MENU_ITEMS.filter((x) => x.classification === 'NIFR'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'teacher', 'principal', 'kirf', 'iqac'],
		},
		{
			title: 'AISHE',
			keyData: 'aishe',
			fullName: 'All India Survey on Higher Education.',
			values: MENU_ITEMS.filter((x) => x.classification === 'NIFR'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'teacher', 'principal', 'aishe', 'iqac'],
		},
		{
			title: 'IQAC',
			keyData: 'iqac',
			fullName: 'Internal Quality Assurance Cell.',
			values: MENU_ITEMS.filter((x) => x.classification === 'IQAC'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'director', 'manager', 'teacher', 'principal', 'iqac'],
		},
		{
			title: 'Settings',
			keyData: 'settings',
			fullName: 'Settings of College',
			values: MENU_ITEMS.filter((x) => x.classification === 'Adminitration'),
			instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'],
			allowedUsers: ['admin', 'principal', 'superintend'],
		},
	];

	const [values, setValues] = useState();

	let [menu, setMenu] = usePersistentState('menu', MENU_ITEMS);
	let [color, setColor] = usePersistentState('color', 'green');
	const [search, setSearch] = usePersistentState('search', MENU_ITEMS);

	useEffect(() => {
		if (values?.keyData) {
			const newSet = classificationArray?.find((x) => x.title === values?.keyData);
			setMenu(newSet?.values);
			setSearch(newSet?.values);
			setHead(newSet?.fullName);
			setWindows({});
			setClassification('');
		}
	}, [values?.keyData]);

	return (
		<DashLayout>
			<div className='subContainer h-[100vh] mainContainer blur-custom'>
				<div className='sideBar h-[100vh] hidden md:block'>
					<div className='logo sticky top-0 bg-white z-10'>
						<img src={selectedCollege?.logo} alt='' className='collegeIcon' />
					</div>
					<div className='grid grid-cols-1 py-20 gap-2 overflow-y-auto h-[90vh]'>
						{classificationArray?.map(
							(x, i) =>
								validateRole(x.allowedUsers, data?.collegeRoles, collegeId) && (
									<div
										onClick={() => {
											setMenu(x.values);
											setSearch(x.values);
											setWindows({});
											setColor(i);
											setClassification('');
											setHead(x?.fullName);
										}}
										className='icons'
										style={{
											cursor: 'pointer',
											backgroundColor: i === color ? '#0234a8' : '#002267',
										}}
										key={i}>
										<img src={icons[x?.keyData]} />
										<span>{x.title}</span>
									</div>
								),
						)}
					</div>
				</div>

				<div className='w-[100%] h-[95vh] flex flex-col '>
					<div className='w-[95%] self-center  lg:hidden block'>
						<Input fieldName='Filter' setState={setValues} state={values} returnKey='keyData' optionKey='title' optionDisplay='fullName' options={classificationArray} type='drop' width='100%' />
					</div>

					<div className='relative  w-2/6 blur-custom bg-[#40404065] self-center my-[2vh] h-[25px] text-gray-50 font-bold text-center rounded-lg shadow-sm flex justify-center items-center'>
						<div
							className='absolute top-[15%] right-3 cursor-pointer h-[2vh] w-[1.5vw] bg-[#ada8a835] bg-opacity-20 text-[#8282827f] text-opacity-80 rounded-full flex justify-center items-center'
							onClick={() => {
								setSearch(menu);
								const elem = document.getElementById('search');
								if (elem) {
									elem.value = '';
								}
							}}>
							<span className=' text-[#ffffff7f] text-opacity-80 align-middle'>x</span>
						</div>

						<input
							className=' text-center  bg-transparent font-bold outline-none border-none placeholder-[#b4b4b4e6] w-full'
							placeholder='Search'
							id='search'
							type='search'
							onChange={(e) => {
								const filteredMenu = menu.filter((item) => {
									return item?.title?.toLowerCase()?.includes(e?.target?.value?.toLowerCase());
								});
								setSearch(filteredMenu);
							}}
						/>
					</div>

					<div className=' w-full pt-2 text-[2vh]  text-[#ffffffb5] ps-[20px] font-extrabold h-[7vw] '>{head} </div>

					<div className='w-[100%] flex  justify-center  h-[100%]'>
						<div className={'components  h-[90vh] grid-auto overflow-y-auto'}>
							{search?.map(
								(item, _i) =>
									validateRole(item.allowedUsers, data?.collegeRoles, collegeId) && (
										<>
											<div className={'roleCards m-[2vh]  float-left'} id={item?.keyData} draggable={true}>
												<div
													onClick={() => {
														setWindows({ [item?.keyData]: true });
														setClassification(item?.keyData);
													}}
													className={'icons'}>
													<div key={item.key}>
														<img src={icons[item.keyData] || item?.img} />
													</div>
												</div>
												<h2 className='text-center mt-[.11vh] text-shadow'>{item?.title}</h2>
											</div>
										</>
									),
							)}
						</div>

						{/* {["c1", "c2", "c3", "c4", "c5", "c6", "c7"].includes(classification) && (
              <div className="components overflow-auto w-[80%]">
                {MENU_ITEMS?.filter((x) => x.classification === classification).map(
                  (item, _i) =>
                    validateRole(item.allowedUsers, data?.collegeRoles, collegeId) && (
                      <>
                        <div className={"roleCards m-[2vw] float-left shadow-sm "} id={item?.keyData} draggable={true}>
                          <div onClick={() => setCriteria({ [item?.keyData]: true })} className={"roleCards"}>
                            <div key={item.key}>
                              <img src={item?.img} />
                            </div>
                            <h2 className="text-center mt-3">{item?.title}</h2>
                          </div>
                        </div>
                      </>
                    )
                )}
              </div>
            )} */}
					</div>
				</div>

				<div className='posterContainer hidden md:block'>
					<a href='https://orcid.org/oauth/authorize?client_id=APP-Y30P3X5HD0RJTOL6&response_type=code&scope=/authenticate&redirect_uri=http://127.0.0.1:3000/app'>
						<Button type='fetch'>Link My ORCID Account</Button>
					</a>
					<div className=' mt-10'>
						<section className='w-full  bg-[#20354b62]  rounded-2xl px-[2vh] py-[2vh] shadow-lg'>
							<div className='flex windows-center justify-between'>
								<span className='text-gray-400 text-sm cursor-pointer flex  text-[1.3vh]' onClick={togleFullScreen}>
									{!isFullScreen ? 'Full Screen' : 'Exit Full Screen'}
								</span>
								<span>
									<svg xmlns='http://www.w3.org/2000/svg' width={'2vh'} height={'2vh'} viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255,70%)' strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' className='feather feather-settings'>
										<circle cx={12} cy={12} r={3} />
										<path d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z' />
									</svg>
								</span>
								<span className='cursor-pointer' onClick={() => setWindows({ profile: true })}>
									<svg style={{ color: 'white' }} xmlns='http://www.w3.org/2000/svg' width={'2vh'} height={'2vh'} fill='currentColor' className='bi bi-person-lines-fill' viewBox='0 0 16 16'>
										<path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z' fill='white' />{' '}
									</svg>
								</span>
								<span className='cursor-pointer' onClick={() => setWindows({ newProfile: true })}>
									<svg style={{ color: 'white' }} xmlns='http://www.w3.org/2000/svg' width={'2vh'} height={'2vh'} fill='currentColor' className='bi bi-person-lines-fill' viewBox='0 0 16 16'>
										<path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z' fill='white' />{' '}
									</svg>
								</span>
								<span
									onClick={() => {
										localStorage.clear();
										Cookies.remove('userToken');
										window.location.href = '/'; // Redirects to the home screen
										window.location.reload(true);
									}}
									className='text-emerald-400 cursor-pointer text-[1.3vh]'>
									Log out
								</span>
							</div>
							<div className='mt-[1vh] w-full mx-auto'>
								<img src={data?.photoURL} className='rounded w-full ' alt='profile picture' srcSet />
							</div>
							<div className='mt-8 '>
								<h2 className='text-white font-bold text-[2vh] tracking-wide'>{data?.name}</h2>
							</div>
							<div className='flex flex-wrap '>
								{useRoles()?.map((x) => (
									<div className='text-emerald-400  border-custom m-[1vh] ps-1 rounded text-[1.6vh]  py-1 pe-2 capitalize'>{x}</div>
								))}
							</div>
							<p className='text-emerald-400 font-semibold text-[2vh]  mt-2.5'>Active</p>
							<div className='mt-3 mb-1 text-white text-sm'>
								<span className='text-gray-400 font-semibold text-[2vh]'>Profile Completion:</span>
							</div>
							<ProgressBar progress={data?.percentageOfCompletion || 0} /> 
						</section>
					</div>
				</div>
			</div>
			{windows.feedBackReports && <FeedBackReports onClose={() => setWindows({ ...windows, feedBackReports: false })} />}
			{windows.naacDataCollection && <NaacDataCollection onClose={() => setWindows({ ...windows, naacDataCollection: false })} />}
			{windows.openCourse && <OpenCourse onClose={() => setWindows({ ...windows, openCourse: false })} />}
			{windows.teacherProfile && <TeacherProfile onClose={() => setWindows({ ...windows, teacherProfile: false })} />}
			{windows.studentProfile && <StudentProfile onClose={() => setWindows({ ...windows, studentProfile: false })} />}
			{windows.staffProfile && <StaffProfile onClose={() => setWindows({ ...windows, staffProfile: false })} />}
			{windows.hierarchy && <RoleManagements onClose={() => setWindows({ ...windows, hierarchy: false })} />}
			{windows.help && <Help onClose={() => setWindows({ ...windows, help: false })} />}
			{windows.attendanceRegister && <Attendance onClose={() => setWindows({ ...windows, attendanceRegister: false })} />}
			{windows.attendanceMonthlyRegister && <AttendanceMonthly onClose={() => setWindows({ ...windows, attendanceMonthlyRegister: false })} />}
			{windows.feeStructure && <FeeStructure onClose={() => setWindows({ ...windows, feeStructure: false })} />}
			{windows.eDLBilling && <EDLBilling onClose={() => setWindows({ ...windows, eDLBilling: false })} />}
			{windows.issueBook && <IssueBook onClose={() => setWindows({ ...windows, issueBook: false })} />}
			{windows.c2 && <C2 onClose={() => setWindows({ ...windows, c2: false })} />}
			{windows.profac && <ProFacMonitor onClose={() => setWindows({ ...windows, profac: false })} />}
			{windows?.deleteUser && <DeleteUser onClose={() => setWindows({ ...windows, deleteUser: false })} />}
			{windows.invoiceManagement && (
				<InvoiceManagement
					onClose={() => {
						closeAnimation();
						setTimeout(() => setWindows({ ...windows, invoiceManagement: false }), 500);
					}}
				/>
			)}
			{windows.lostRegister && (
				<LoastRegister
					onClose={() => {
						closeAnimation();
						setTimeout(() => setWindows({ ...windows, lostRegister: false }), 500);
					}}
				/>
			)}
			{windows.hungerFreeCampus && (
				<HungryFreeCampus
					onClose={() => {
						closeAnimation();
						setTimeout(() => setWindows({ ...windows, hungerFreeCampus: false }), 500);
					}}
				/>
			)}
			{windows.renewBook && <RenewBook onClose={() => setWindows({ ...windows, renewBook: false })} />}
			{windows.activeRegister && <ActiveRegister onClose={() => setWindows({ ...windows, activeRegister: false })} />}
			{windows.returnBook && <ReturnBook onClose={() => setWindows({ ...windows, returnBook: false })} />}
			{windows.addBook && (
				<ModalWrapper>
					<AddBook
						onCloseModal={() => setWindows({ ...windows, addBook: false })}
						allBookList={allBookList}
						onSuccess={() => {
							setWindows({ ...windows, addBook: false });
						}}
					/>
				</ModalWrapper>
			)}
			{windows.opac && <Opac onClose={() => setWindows({ ...windows, opac: false })} />}
			{windows.master && <Master onClose={() => setWindows({ ...windows, master: false })} />}
			{windows.members && <Members onClose={() => setWindows({ ...windows, members: false })} />}
			{windows.movements && <Movements onClose={() => setWindows({ ...windows, movements: false })} />}
			{windows.labels && <Labels onClose={() => setWindows({ ...windows, labels: false })} />}
			{windows.ddcSubjects && <DDCSubjects onClose={() => setWindows({ ...windows, ddcSubjects: false })} />}
			{windows.holidays && <Holidays onClose={() => setWindows({ ...windows, holidays: false })} />}
			{windows.eResources && <EResources onClose={() => setWindows({ ...windows, eResources: false })} />}
			{windows.periodicals && <Periodicals onClose={() => setWindows({ ...windows, periodicals: false })} />}
			{windows.periodicalsEntry && <PeriodicalsEntry onClose={() => setWindows({ ...windows, periodicalsEntry: false })} />}
			{windows.fineRegister && <FineRegister onClose={() => setWindows({ ...windows, fineRegister: false })} />}
			{windows.footPrint && <FootPrint onClose={() => setWindows({ ...windows, footPrint: false })} />}
			{windows.libraryStatisticsExport && <LibraryStatisticsExport onClose={() => setWindows({ ...windows, libraryStatisticsExport: false })} />}
			{windows.rollView && <RollView onClose={() => setWindows({ ...windows, rollView: false })} />}
			{windows.nameSlip && <NameSlip onClose={() => setWindows({ ...windows, nameSlip: false })} />}
			{windows.newNameSlip && <NewNameSlip onClose={() => setWindows({ ...windows, newNameSlip: false })} />}
			{windows.strengthDetails && <StrengthDetails onClose={() => setWindows({ ...windows, strengthDetails: false })} />}
			{windows.catogoryList && <CatogoryListData onClose={() => setWindows({ ...windows, catogoryList: false })} />}
			{windows.electoralRoll && <ElectoralRoll onClose={() => setWindows({ ...windows, electoralRoll: false })} />}
			{windows.menteeForm && <MenteePDF onClose={() => setWindows({ ...windows, menteeForm: false })} />}
			{windows.photoCheck && <PhotoCkeck onClose={() => setWindows({ ...windows, photoCheck: false })} />}
			{windows.tcCc && <TransferSertificateCC onClose={() => setWindows({ ...windows, tcCc: false })} />}
			{windows.masterTimeTables && <MasterTimeTables onClose={() => setWindows({ ...windows, masterTimeTables: false })} />}
			{windows.statistics && <Statistics onClose={() => setWindows({ ...windows, statistics: false })} />}
			{windows.internalAssessment && <InternalAssessment onClose={() => setWindows({ ...windows, internalAssessment: false })} />}
			{windows.leaveReports && <LeaveReports onClose={() => setWindows({ ...windows, leaveReports: false })} />}
			{windows.releaving && <Releaving onClose={() => setWindows({ ...windows, releaving: false })} />}
			{windows.dailyFeeCollectionRegister && <DailyFeeCollectionRegister onClose={() => setWindows({ ...windows, dailyFeeCollectionRegister: false })} />}
			{windows.newDCB && <NEWDCB onClose={() => setWindows({ ...windows, newDCB: false })} />}
			{windows.newDFCR && <DFCR onClose={() => setWindows({ ...windows, newDFCR: false })} />}
			{windows.subsidiaryRegister && <SubsidiaryRegister onClose={() => setWindows({ ...windows, subsidiaryRegister: false })} />}
			{windows.subsidiaryRegisterYearWise && <SubsidiaryRegisterYearWise onClose={() => setWindows({ ...windows, subsidiaryRegisterYearWise: false })} />}
			{windows.applicationForm && (
				<div className='fixed inset-0 bg-[#00000085] flex justify-center windows-center'>
					<ApplicationForm onClose={() => setWindows({ ...windows, applicationForm: false })} />
				</div>
			)}
			{windows.c1 && <C1 onClose={() => setWindows({ ...windows, c1: false })} />}
			{windows.c3 && <C3 onClose={() => setWindows({ ...windows, c3: false })} />}

			{windows.mentorMentee && (
				<MentorMentee
					onClose={() => {
						closeAnimation();
						setTimeout(() => setWindows({ ...windows, mentorMentee: false }), 500);
					}}
				/>
			)}
			{windows.postBulkJournals && (
				<ModalWrapper>
					<PostBulkJournals onClose={() => setWindows({ ...windows, postBulkJournals: false })} />{' '}
				</ModalWrapper>
			)}
			{windows.mainAccounts && <MainAccounts onClose={() => setWindows({ ...windows, mainAccounts: false })} />}
			{windows.numbers && <Numbers onClose={() => setWindows({ ...windows, numbers: false })} />}
			{windows.subAccounts && <SubAccounts onClose={() => setWindows({ ...windows, subAccounts: false })} />}
			{windows.cashBook && <CashBook onClose={() => setWindows({ ...windows, cashBook: false })} />}
			{windows.incomeExpenditure && <IncomeExpenditure onClose={() => setWindows({ ...windows, incomeExpenditure: false })} />}
			{windows.receiptPayments && <RecieptsPayments onClose={() => setWindows({ ...windows, receiptPayments: false })} />}
			{windows.dayBook && <DayBook onClose={() => setWindows({ ...windows, dayBook: false })} />}
			{windows.collectionReports && <CollectionReports onClose={() => setWindows({ ...windows, collectionReports: false })} />}
			{windows.collectioReportsNew && <CollectioReportsNew onClose={() => setWindows({ ...windows, collectioReportsNew: false })} />}
			{windows.cashBankReports && <CashBankReports onClose={() => setWindows({ ...windows, cashBankReports: false })} />}
			{windows.dcb && <DCB onClose={() => setWindows({ ...windows, dcb: false })} />}
			{windows.fdcb && <FDCB onClose={() => setWindows({ ...windows, fdcb: false })} />}
			{windows.duesReport && <DuesReport onClose={() => setWindows({ ...windows, duesReport: false })} />}
			{windows.scholarship && <Scholarship onClose={() => setWindows({ ...windows, scholarship: false })} />}
			{windows.feeConcession && <FeeConcession onClose={() => setWindows({ ...windows, feeConcession: false })} />}
			{windows.profile && <Profile onClose={() => setWindows({ ...windows, profile: false })} setWindows={setWindows} windows={windows} />}
			{windows.newProfile && <NewProfile onClose={() => setWindows({ ...windows, newProfile: false })} setWindows={setWindows} windows={windows} />}
			{windows.earnLeaveCalculator && <EarnLeaveCalculator onClose={() => setWindows({ ...windows, earnLeaveCalculator: false })} />}
			{windows.instanceFeeCollection && <InstanceFeeCollection onClose={() => setWindows({ ...windows, instanceFeeCollection: false })} />}
			{windows.essentialData && <BEDStudent onClose={() => setWindows({ ...windows, essentialData: false })} />}
			{windows.tutionFeeData && <BulkFeeData onClose={() => setWindows({ ...windows, tutionFeeData: false })} />}
			{windows.workingFile && (
				<ModalWrapper>
					<WorkingFIles onClose={() => setWindows({ ...windows, workingFile: false })} />
				</ModalWrapper>
			)}
			{windows.apc && (
				<ModalWrapper>
					<APC onClose={() => setWindows({ ...windows, apc: false })} />
				</ModalWrapper>
			)}
			{windows.subjectWiseAttendance && (
				<ModalWrapper>
					<SubjectWiseAttendance onClose={() => setWindows({ ...windows, subjectWiseAttendance: false })} />
				</ModalWrapper>
			)}
			{windows.classWiseAttendance && (
				<ModalWrapper>
					<ClassWiseAttendance onClose={() => setWindows({ ...windows, classWiseAttendance: false })} />
				</ModalWrapper>
			)}
			{windows?.subjectBank && (
				<ModalWrapper>
					<SubjectBank onClose={() => setWindows({ ...windows, subjectBank: false })} />
				</ModalWrapper>
			)}
			{windows?.rolloutManagement && (
				<div className='fixed inset-0 bg-[#00000045] flex justify-center windows-center'>
					<RolloutManagement onClose={() => setWindows({ ...windows, rolloutManagement: false })} />
				</div>
			)}
			{windows.internal && (
				<ModalWrapper>
					<Internal onClose={() => setWindows({ ...windows, internal: false })} />
				</ModalWrapper>
			)}
			{windows.idCardPrinting && (
				<ModalWrapper>
					<IdCardPrinting onClose={() => setWindows({ ...windows, idCardPrinting: false })} />
				</ModalWrapper>
			)}
			{windows.createAccount && (
				<ModalWrapper>
					<CreateAccount onClose={() => setWindows({ ...windows, createAccount: false })} />
				</ModalWrapper>
			)}
			{windows.departmentVerification && (
				<ModalWrapper>
					<DepartmentVery onClose={() => setWindows({ ...windows, departmentVerification: false })} />
				</ModalWrapper>
			)}

			{windows.nodalVerification && (
				<ModalWrapper>
					<NodalVery isTemp={false} onClose={() => setWindows({ ...windows, nodalVerification: false })} />
				</ModalWrapper>
			)}
			{windows.nodalVerificationTemp && (
				<ModalWrapper>
					<NodalVery isTemp={true} onClose={() => setWindows({ ...windows, nodalVerificationTemp: false })} />
				</ModalWrapper>
			)}
			{windows.ptaVerification && (
				<ModalWrapper>
					<PTA setItemsData={setWindows} itemsData={windows} onClose={() => setWindows({ ...windows, ptaVerification: false })} />
				</ModalWrapper>
			)}
			{windows.principalVerification && (
				<ModalWrapper>
					<Principal checkAdmin={verificationWindows?.giveAdmissionNumber} onClose={() => setWindows({ ...windows, principalVerification: false })} />
				</ModalWrapper>
			)}
			{windows.officeVerification && (
				<ModalWrapper>
					<Office checkAdmin={verificationWindows?.giveAdmissionNumber} onClose={() => setWindows({ ...windows, officeVerification: false })} />
				</ModalWrapper>
			)}
			{windows.feesVerification && (
				<ModalWrapper>
					<Fees setItemsData={setWindows} itemsData={windows} checkAdmin={verificationWindows?.giveAdmissionNumber} studentId={windows?.studentId} onClose={() => setWindows({ ...windows, feesVerification: false })} />
				</ModalWrapper>
			)}
			{windows.grandVerification && (
				<ModalWrapper>
					<EGrantz onClose={() => setWindows({ ...windows, grandVerification: false })} />
				</ModalWrapper>
			)}
			{windows.eGrantManagement && (
				<ModalWrapper>
					<EGrantManagement onClose={() => setWindows({ ...windows, eGrantManagement: false })} />
				</ModalWrapper>
			)}
			{windows.admissionRegister && (
				<ModalWrapper>
					<AdmissionRegister onClose={() => setWindows({ ...windows, admissionRegister: false })} />
				</ModalWrapper>
			)}
			{windows.universityUploading && (
				<ModalWrapper>
					<University onClose={() => setWindows({ ...windows, universityUploading: false })} />
				</ModalWrapper>
			)}
			{windows.dashBoard && (
				<ModalWrapper>
					<DashBoard onClose={() => setWindows({ ...windows, dashBoard: false })} />
				</ModalWrapper>
			)}
			{windows.controller && (
				<ModalWrapper>
					<Controller onClose={() => setWindows({ ...windows, controller: false })} />
				</ModalWrapper>
			)}
			{windows.splitups && (
				<ModalWrapper>
					<Splitups onClose={() => setWindows({ ...windows, splitups: false })} />
				</ModalWrapper>
			)}
			{windows.feesBilling && (
				<ModalWrapper>
					<FeeBilling setItemsData={setWindows} keyDataIsStaff={windows?.keyDataIsStaff} itemsData={windows} studentId={windows?.studentId} onClose={() => setWindows({ ...windows, feesBilling: false })} />
				</ModalWrapper>
			)}
			{windows.epos && (
				<ModalWrapper>
					<EPosMirgration onClose={() => setWindows({ ...windows, epos: false })} />
				</ModalWrapper>
			)}
			{windows.rankList && (
				<ModalWrapper>
					<RankList onClose={() => setWindows({ ...windows, rankList: false })} />
				</ModalWrapper>
			)}
			{windows.post && (
				<ModalWrapper>
					<PostElection onClose={() => setWindows({ ...windows, post: false })} />
				</ModalWrapper>
			)}
			{criteria?.c1_1 && (
				<ModalWrapper>
					<C1_1 onClose={() => setCriteria({ ...criteria, c1_1: false })} />
				</ModalWrapper>
			)}
			{/* c2 */}
			{criteria?.c2_1 && (
				<C2_1
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_1: false });
						}, 250);
					}}
				/>
			)}
			{criteria?.c2_2 && (
				<C2_2
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_2: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_3 && (
				<C2_3
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_3: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_4 && (
				<C2_4
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_4: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_5 && (
				<C2_5
					onClose={() => {
						closeAnimation();

						setTimeout(() => {
							setCriteria({ ...criteria, c2_5: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_6 && (
				<C2_6
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_6: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_7 && (
				<C2_7
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_7: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_8 && (
				<C2_8
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_8: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_9 && (
				<C2_9
					onClose={() => {
						closeAnimation();
						setTimeout(() => {
							setCriteria({ ...criteria, c2_9: false });
						}, 1000);
					}}
				/>
			)}
			{criteria?.c2_10 && (
				<ModalWrapper>
					<C2_10
						onClose={() => {
							closeAnimation();
							setTimeout(() => {
								setCriteria({ ...criteria, c2_10: false });
							}, 1000);
						}}
					/>
				</ModalWrapper>
			)}

			{criteria?.c3_1a && (
				<ModalWrapper>
					<C3_1a onClose={() => setCriteria({ ...criteria, c3_1a: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_1b && (
				<ModalWrapper>
					<C3_1b onClose={() => setCriteria({ ...criteria, c3_1b: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_1_1 && (
				<ModalWrapper>
					<C3_1_1 onClose={() => setCriteria({ ...criteria, c3_1_1: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_2_2 && (
				<ModalWrapper>
					<C3_2_2 onClose={() => setCriteria({ ...criteria, c3_2_2: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_3_2 && (
				<ModalWrapper>
					<C3_3_2 onClose={() => setCriteria({ ...criteria, c3_3_2: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_3_3 && (
				<ModalWrapper>
					<C3_3_3 onClose={() => setCriteria({ ...criteria, c3_3_3: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_4_2 && (
				<ModalWrapper>
					<C3_4_2 onClose={() => setCriteria({ ...criteria, c3_4_2: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_4_3 && (
				<ModalWrapper>
					<C3_4_3 onClose={() => setCriteria({ ...criteria, c3_4_3: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_5_1 && (
				<ModalWrapper>
					<C3_5_1 onClose={() => setCriteria({ ...criteria, c3_5_1: false })} />
				</ModalWrapper>
			)}
			{criteria?.c3_5_2 && (
				<ModalWrapper>
					<C3_5_2 onClose={() => setCriteria({ ...criteria, c3_5_2: false })} />
				</ModalWrapper>
			)}
			{windows?.NIFR && (
				<ModalWrapper>
					<NIFR onClose={() => setWindows({ ...windows, NIFR: false })} />
				</ModalWrapper>
			)}
			{windows?.masterTable && (
				<ModalWrapper>
					<DashPage onClose={() => setWindows({ ...windows, masterTable: false })} />
				</ModalWrapper>
			)}
			{windows?.timetable && (
				<ModalWrapper>
					<TimeTablePage onClose={() => setWindows({ ...windows, timetable: false })} />
				</ModalWrapper>
			)}
			{windows?.addUser && <AddUserPage onClose={() => setWindows({ ...windows, addUser: false })} />}
			{windows?.feeManagement && (
				<ModalWrapper>
					<FeeManagement onClose={() => setWindows({ ...windows, feeManagement: false })} />
				</ModalWrapper>
			)}
			{windows?.supperVission && (
				<ModalWrapper>
					<SupperVision onClose={() => setWindows({ ...windows, supperVission: false })} />
				</ModalWrapper>
			)}
			{windows?.departmentMaster && (
				<ModalWrapper>
					<DepartmentMaster onClose={() => setWindows({ ...windows, departmentMaster: false })} />
				</ModalWrapper>
			)}
			{windows.bookRecomentation && <BookRecomentation onClose={() => setWindows({ ...windows, bookRecomentation: false })} />}
			{windows.bankCash && <BankCash onClose={() => setWindows({ ...windows, bankCash: false })} />}
			{windows.accadamicHoliday && <AccademicHoliday onClose={() => setWindows({ ...windows, accadamicHoliday: false })} />}
			{windows.semesterWiseStudentData && <SemesterWiseStudentData onClose={() => setWindows({ ...windows, semesterWiseStudentData: false })} />}
			{windows.masterstockregister && <MasterStockRegister onClose={() => setWindows({ ...windows, masterstockregister: false })} />}
			{windows.questionBankCreator && <QuestionBankCreator onClose={() => setWindows({ ...windows, questionBankCreator: false })} />}
			{windows.questionBankCreatorVerification && <QuestionBankCreatorVerification onClose={() => setWindows({ ...windows, questionBankCreatorVerification: false })} />}
			{windows.questionPaperCreation && <QuestionPaper onClose={() => setWindows({ ...windows, questionPaperCreation: false })} />}
			{windows.examInit && <ExamInit onClose={() => setWindows({ ...windows, examInit: false })} />}
			{windows.answerSheetBundleManagement && <AnswerSheetBundleManagement onClose={() => setWindows({ ...windows, answerSheetBundleManagement: false })} />}
			{windows.examNotification && <ExamNotification onClose={() => setWindows({ ...windows, examNotification: false })} />}
			{windows.condonation && <Condonation onClose={() => setWindows({ ...windows, condonation: false })} />}
			{windows.electiveChanger && <ElectiveChanger onClose={() => setWindows({ ...windows, electiveChanger: false })} />}
			{windows.questionBankManager && <QuetionBankManager onClose={() => setWindows({ ...windows, questionBankManager: false })} />}
			{windows.analytics && <Analytics onClose={() => setWindows({ ...windows, analytics: false })} />}
			{windows.applicationRecieved && <ApplicationRecieved onClose={() => setWindows({ ...windows, applicationRecieved: false })} />}
			{windows.enquryForm && <EnquriyForm onClose={() => setWindows({ ...windows, enquryForm: false })} />}
			{windows.pyementWaitingSubmition && <PyementWaitingSubmition onClose={() => setWindows({ ...windows, pyementWaitingSubmition: false })} />}
			{windows.cordinatorShipAdmin && <CordinatorShipAdmin onClose={() => setWindows({ ...windows, cordinatorShipAdmin: false })} />}
			{windows.cordinatorShipForm && <CordinatorShipForm onClose={() => setWindows({ ...windows, cordinatorShipForm: false })} />}
			{windows.clubCellsAccounts && <ClubCellsAccounts onClose={() => setWindows({ ...windows, clubCellsAccounts: false })} />}
			{windows.courseManagement && <CourseManagement onClose={() => setWindows({ ...windows, courseManagement: false })} />}
			{windows.applicationStated && <ApplicationStarted onClose={() => setWindows({ ...windows, applicationStated: false })} />}
			{windows.insertUsers && <InsertData onClose={() => setWindows({ ...windows, insertUsers: false })} />}
			{windows.updateUsers && <UpdateData onClose={() => setWindows({ ...windows, updateUsers: false })} />}
			{windows.rankListAuto && <AllotmentList onClose={() => setWindows({ ...windows, rankListAuto: false })} />}
			{windows.rankListRules && <RankListRules onClose={() => setWindows({ ...windows, rankListRules: false })} />}
			{windows.reChecker && <RecheckPayments onClose={() => setWindows({ ...windows, reChecker: false })} />}
			{windows.indexMarkListed && <CourseWiseRankList onClose={() => setWindows({ ...windows, indexMarkListed: false })} />}
			{windows.seatReservation && <SeatReservationDetails onClose={() => setWindows({ ...windows, seatReservation: false })} />}
			{windows.priorityChanger && <PriorityChanger onClose={() => setWindows({ ...windows, priorityChanger: false })} />}
			{windows.certificate && <Certificate onClose={() => setWindows({ ...windows, certificate: false })} />}
			{windows.dueRegister && <DueRegister onClose={() => setWindows({ ...windows, dueRegister: false })} />}
			{windows.subjectRegistry && <SubjectRegistry onClose={() => setWindows({ ...windows, subjectRegistry: false })} />}
			{windows.admissionRegister2 && <AdmissionRegister2 onClose={() => setWindows({ ...windows, admissionRegister2: false })} />}
			{windows.admissionRegister3 && <AdmissionRegister3 onClose={() => setWindows({ ...windows, admissionRegister3: false })} />}
			{windows.photoVerification && <PhotoVerification onClose={() => setWindows({ ...windows, photoVerification: false })} />}
			{windows.studentResponds && <StudentResponds onClose={() => setWindows({ ...windows, studentResponds: false })} />}
			{windows.accountActiveFirebase && <AccountActiveFirebase onClose={() => setWindows({ ...windows, accountActiveFirebase: false })} />}
			{windows.photoDownload && <PhotoDownloader onClose={() => setWindows({ ...windows, photoDownload: false })} />}
			{windows.rollNumberDistributer && <Numbering onClose={() => setWindows({ ...windows, rollNumberDistributer: false })} />}
			{windows.examRegistration && <ExamRegistration onClose={() => setWindows({ ...windows, examRegistration: false })} />}
			{windows.timeTableManagement && <TimeTableManagement onClose={() => setWindows({ ...windows, timeTableManagement: false })} />}
			{windows.seatingPlan && <SeatingPlan onClose={() => setWindows({ ...windows, seatingPlan: false })} />}
			{windows.roomArrangement && <RoomArrangement onClose={() => setWindows({ ...windows, roomArrangement: false })} />}
			{windows.invigilationManagement && <InvigilationManagement onClose={() => setWindows({ ...windows, invigilationManagement: false })} />}
			{windows.halltiketDistribution && <HalltiketDistribution onClose={() => setWindows({ ...windows, halltiketDistribution: false })} />}
			{windows.roomDocuments && <RoomDocuments onClose={() => setWindows({ ...windows, roomDocuments: false })} />}
			{windows.barcodeDownload && <BarcodeDownload onClose={() => setWindows({ ...windows, barcodeDownload: false })} />}
			{windows.valuationManagement && <ValuationManagement onClose={() => setWindows({ ...windows, valuationManagement: false })} />}
			{windows.resultPublication && <ResultPublication onClose={() => setWindows({ ...windows, resultPublication: false })} />}
			{windows.falseNumbering && <FalseNumbering onClose={() => setWindows({ ...windows, falseNumbering: false })} />}
			{windows.realTimeAllotment && <RealTimeAllotment onClose={() => setWindows({ ...windows, realTimeAllotment: false })} />}
			{windows.allotmentListClassWise && <StudentsAllotmentClassWise onClose={() => setWindows({ ...windows, allotmentListClassWise: false })} />}
			{windows.realTimeRanking && <RealTimeRanking onClose={() => setWindows({ ...windows, realTimeRanking: false })} />}
			{windows.dataLoader && <DataLoader onClose={() => setWindows({ ...windows, dataLoader: false })} />}
			{windows.fyugpDashBoard && <FYUGPDashBoard onClose={() => setWindows({ ...windows, fyugpDashBoard: false })} />}
			{windows.confirmedClassWiseAllotment && <ConfirmedClassWiseAllotment onClose={() => setWindows({ ...windows, confirmedClassWiseAllotment: false })} />}
			{windows.confirmedCourseWiseAllotment && <ConfirmedCourseWiseAllotment onClose={() => setWindows({ ...windows, confirmedCourseWiseAllotment: false })} />}
			{windows.courseSchedule && <SubjectAllocationAuto onClose={() => setWindows({ ...windows, courseSchedule: false })} />}
			{windows.sportAdmission && <SportAdmission onClose={() => setWindows({ ...windows, sportAdmission: false })} />}
			{windows.universityUploadList && <UniversityUploadList onClose={() => setWindows({ ...windows, universityUploadList: false })} />}
			{windows.tagOrder && <TagOrder onClose={() => setWindows({ ...windows, tagOrder: false })} />}
			{windows.attendanceCorrectionBulk && <AttendanceCorrectionBulk onClose={() => setWindows({ ...windows, attendanceCorrectionBulk: false })} />}
			{windows.newTransferCertificate && <NewTransferCertificate onClose={() => setWindows({ ...windows, newTransferCertificate: false })} />}
			{windows.dataManagementSystem && <DataManagementSystem onClose={() => setWindows({ ...windows, dataManagementSystem: false })} />}
			{windows.naac2024 && <NAAC2024 onClose={() => setWindows({ ...windows, naac2024: false })} />}
		</DashLayout>
	);
}
export default LibraryReports;
