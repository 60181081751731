import React, { useCallback, useContext, useState, StyleSheet, useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import classNames from "./addBookModal.module.scss";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import { getQuestionnairAnalysis } from "../../../ApiServices";
import { useQuery } from "react-query";
import moment from "moment";
import ReactToPrint from "react-to-print";



function AnalysisQuestionnaire({ onSuccess, itemData, onCloseModal, questionnaireId, refectOrgin }) {


  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data = [], refetch } = useQuery(['getQuestionnairAnalysis', collegeId, questionnaireId], getQuestionnairAnalysis(collegeId, questionnaireId));





  return (
    <div className={classNames.addBookModal}>
      <div className={classNames.head}>
        <span>Questionnaire Analysis</span>
        <button onClick={onCloseModal} className={classNames.cancel} >Cancel</button>
        <ReactToPrint
          trigger={() => <button className={classNames.load}>Print</button>}
          content={() => componentRef.current}
        />

      </div>
      <Scrollbars style={{ height: '90%' }} >
        <div ref={componentRef} className="printable">
          <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
              <img width='12%' height='12%' src={selectedCollege?.logo} />
              <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                <span style={{ fontSize: '30px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>
                <p style={{ fontSize: '25px', margin: 0, fontWeight: 900 }} >{selectedCollege?.accreditedGrade}</p>
                <p style={{ fontSize: '14px', margin: 0, fontWeight: 900 }}>{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}</p>
              </div>
            </div>
            <h5 style={{ margin: 0, fontSize: '30px', width: '100%', padding: '10px', alignSelf: 'left' }}>{data.title} - Analysis Report {data?.academicYear} - {data?.semester}</h5>
            <div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
              <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '20%', }}>Sn</h5>
              <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '25%' }}>Mean</h5>
              <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '25%' }}>Standard Deviation</h5>
              <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '30%' }}>Frequency</h5>

            </div>
            {data?.questionnaire?.map((item, i) => (
              <>
                <div style={{ width: '100%', paddingRight: 12, display: 'flex', padding: '4px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                  <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 50, width: '20%' }}>{i + 1}</h5>
                  <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 50, width: '25%' }}>{item?.mean?.toFixed(2)}</h5>
                  <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 50, width: '25%' }}>{item?.standardDeviation?.toFixed(2)}</h5>
                  <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 50, width: '30%' }}>{item?.frequency}</h5>
                </div>
                <div style={{
                  width: '100%',
                  display: 'flex',
                  height: 1,
                  backgroundColor: 'black',
                }}></div>

                <div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '10%', }}>Sn</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '50%' }}>Question</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>Type</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>Mean</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>SD</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>Frequency</h5>
                </div>
                {item?.subQuestions?.map((item, i) => (
                  <>
                    <div style={{ width: '100%', paddingRight: 12, display: 'flex', padding: '5px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '10%' }}>{i + 1}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '50%' }}>{item?.subQuestion}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '10%' }}>{item?.type}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '10%' }}>{item?.mean?.toFixed(2)}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '10%' }}>{item?.standardDeviation?.toFixed(2)}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '10%' }}>{item?.frequency}</h5>
                    </div>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      height: 1,
                      backgroundColor: 'black',
                    }}></div>
                  </>
                ))}
              </>
            ))}
            <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 20 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
          </div>
    
        </div>

      </Scrollbars>
    </div>
  );
}

export default AnalysisQuestionnaire;

const styles = {
  buttonStyle: {
    backgroundColor: 'green', color: 'white', fontSize: 40, borderRadius: 12, height: 50
  }



};


