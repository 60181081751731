import React, { useEffect, useContext, useState, useRef } from "react";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useQuery } from "react-query";
import { getEGrandRegister, getNewClasses } from "../../ApiServices";
import ModalWrapper from "../../../../components/ModalWrapper";
import ViewStudentData from "../ViewStudentData";
import { Body, Header, Main, closeAnimation } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import Input from "../../../../NecttosComp/Input/Input";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../NecttosComp/Table/Table";
import { useDropDowns } from "../../../../queryHooks";
import EditGrand from "./EditGrand";
import Messaging from "./Messaging";
import Saction from "./Saction";
import Withdraw from "./Withdraw";

interface EGrantManagementProps {
  refreshStatus: boolean;
  onClose: () => void;
}

interface State {
  year?: string;
  startDate?: string;
  endingDate?: string;
  classId?: string;
  status?: string;
  grandId?: string;
  viewMode?: boolean;
  studentId?: string;
  openModal?: boolean;
  edit?: boolean;
  data?: any;
  search?: any;
  student?: any;
  msg?: boolean;
}

const EGrantManagement: React.FC<EGrantManagementProps> = ({ refreshStatus, onClose }) => {
  const { collegeId, user } = useContext(FirebaseContext);
  const [state, setState] = useState<State>({});
  const [searchText, setSearchText] = useState("All");
  const componentRef = useRef<HTMLTableElement>(null);

  const [saction, setSanction] = useState(false);
  const status = ["All", "Eligible", "Not_Applied", "Applied", "Application_Submitted", "Application_Sented", "Processing", "Rejected", "Approved", "Sanctioned", "Withdrawal", "Distributed"];
  const { data, refetch } = useQuery(["getEGrantManagement", state.classId, searchText, state.edit, state.year], getEGrandRegister(collegeId, state.startDate, state.endingDate, state.classId, searchText, state.year));
  const { data: dropDown } = useDropDowns("eGrantz");
  const { data: classData } = useQuery("getNewClasses", getNewClasses(collegeId, user?.uid));
  const [admisionNo, setAdmissionNo] = useState<any[]>([]);
  const [sactionTyped, setSanctionTyped] = useState("");
  const [sactionStudents, setSactionStudents] = useState<any[]>([]);

  const handleSearch = (e: { value: string }) => {
    const sort = e.value?.toLowerCase();
    if (sort === "all") {
    } else {
      let searchResult = data?.list?.filter((data: any) => data?.eGrantz?.status?.status?.toLowerCase() === sort);
      setStudentList(searchResult);
    }
  };

  const didHaveEgrant = (x: any) => {
    if (x?.eGrantz?.isGrant) {
      return true;
    } else {
      return false;
    }
  };
  const fy = (x: { eGrantz: { isGrant: any; status: any[] } }, term: string) => {
    if (x?.eGrantz?.isGrant && Array.isArray(x?.eGrantz?.status)) {
      return x.eGrantz.status.find((item) => item?.year === term)?.status || "";
    } else {
      return false;
    }
  };

  const fyc = (x: { eGrantz: { isGrant: any; status: any[] } }, term: string) => {
    if (x?.eGrantz?.isGrant && Array.isArray(x?.eGrantz?.status)) {
      const applicationStatus = x.eGrantz.status.find((item) => item?.year === term)?.status || "";
      switch (applicationStatus) {
        case "Not_Applied":
          return "#808080";
        case "Applied":
          return "#FFD700";
        case "Processing":
          return "#0000FF";
        case "Approved":
          return "#008000";
        case "Rejected":
          return "#FF0000";
        case "Sanctioned":
          return "#006400";
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [collegeId]);

  let userList = data?.list || [];

  const [studentList, setStudentList] = useState(userList);

  useEffect(() => {
    refetch && refetch();
  }, [refreshStatus, refetch]);

  useEffect(() => {
    setStudentList(userList);
  }, [data]);

  const handleSearchTextChange = ({ value: text }: { value: string }) => {
    setSearchText(text);
    let searchResult = data?.list?.filter((data: any) => data?.name.toLowerCase()?.includes(text.toLowerCase()));
    setStudentList(searchResult);
  };

  useEffect(() => {
    if (state.grandId) {
      let searchResult = data?.list?.filter((data: any) => data?.eGrantz?._id === state?.grandId);
      setStudentList(searchResult);
    }
  }, [state]);

  return (
    <>
      <Main title="E-Grand Management" width="95vw">
        <Header>
          <div className="flex justify-evenly">
            <Input fieldName="Select the type" state={searchText} setState={setSearchText} type="drop" onChange={handleSearch} options={status} />
            <Input type="drop" options={classData?.list || []} fieldName="Class" optionDisplay="className" optionKey="_id" returnKey="classId" didntShowKey state={state} setState={setState} />
            {!["all"].includes(searchText.toLowerCase()) && <Input fieldName="Year" state={state} returnKey="year" setState={setState} type="drop" onChange={handleSearch} options={["First_Year", "Second_Year", "Third_Year"]} />}

            {searchText.toLowerCase() === "approved" && (
              <Button type="update" onClick={() => setSanction(!saction)}>
                Sanction
              </Button>
            )}

            {searchText.toLowerCase() === "sanctioned" && (
              <Button type="update" onClick={() => setSanction(!saction)}>
                Withdraw
              </Button>
            )}
            <Input returnKey="search" state={state} onChange={handleSearchTextChange} type="text" fieldName="Search" />

            <Input type="drop" options={dropDown?.[collegeId] || []} fieldName="Catogory Wise" optionKey="_id" optionDisplay="type" didntShowKey returnKey="grandId" state={state} setState={setState} />
          </div>

          <div className="flex">
            <Button
              type="update"
              onClick={() => {
                setState((prevData) => ({ ...prevData, msg: true }));
              }}
              children={"Messaging"}
            />
            <Button type="print" tableRef={componentRef.current} children="Print" />
            <Button type="pdf" pdfId="profilePDF" children="PDF" />
            <Button type="excel" tableRef={componentRef.current} pdfId="profilePDF" children="EXCEL" />
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body width="100%" height="70vh">
          <Table width="100%" ref={componentRef} id={"profilePDF"} innerWidth={["10%", "8%", "8%", "8%", "6%", "7%", "7%", "5%", "8%", "8%", "8%", "5%", "5%", "5%"]}>
            <Thead>
              <Tr>
                <Th position={0}>Name</Th>
                <Th position={1}>Admn No.</Th>
                <Th position={2}>Annual Income</Th>
                <Th position={3}>Batch</Th>
                <Th position={4}>Course</Th>
                <Th position={5}>Category</Th>
                <Th position={6}>E-Cty</Th>
                <Th position={7}>Status</Th>
                <Th position={8}>F-Y</Th>
                <Th position={9}>S-Y</Th>
                <Th position={10}>T-Y</Th>
                <Th position={11}>{searchText.toLowerCase() === "all" ? "Grand" : "Sl.No."}</Th>
                <Th position={12}>Action</Th>
                <Th position={13}>Details</Th>
              </Tr>
            </Thead>
            <Tbody height="60vh">
              {studentList?.map((x: any, i: number) => (
                <Tr>
                  <Td position={0} index={i}>
                    {x?.name}
                  </Td>
                  <Td position={1} index={i}>
                    {x?.admisionNo}
                  </Td>
                  <Td position={2} index={i}>
                    {x?.annualIncome}
                  </Td>
                  <Td position={3} index={i}>
                    {x?.batchName}
                  </Td>
                  <Td position={4} index={i}>
                    {x?.shortName}
                  </Td>
                  <Td position={5} index={i}>
                    {x?.category?.slice(0, 4)}
                  </Td>

                  <Td position={6} index={i}>
                    {dropDown?.[collegeId]?.filter((y: any) => y?._id === x?.eGrantz?._id)[0]?.type?.slice(0, 6)}
                  </Td>
                  <Td position={7} index={i}>
                    {x?.activeStatus}
                  </Td>
                  <Td position={8} highlight={fyc(x, "First_Year") + ""} color={"white"} index={i}>
                    {fy(x, "First_Year")}
                  </Td>
                  <Td position={9} highlight={fyc(x, "Second_Year") + ""} color={"white"} index={i}>
                    {fy(x, "Second_Year")}
                  </Td>
                  <Td position={10} highlight={fyc(x, "Third_Year") + ""} color={"white"} index={i}>
                    {fy(x, "Third_Year")}
                  </Td>
                  <Td position={11} index={i}>
                    {searchText.toLowerCase() === "all" ? (
                      didHaveEgrant(x) ? (
                        <svg width={50} height={50} viewBox="0 0 15 15" fill="green" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M11.3904 5.31232L7.07178 10.7106L3.67991 7.88408L4.32009 7.11586L6.92822 9.2893L10.6096 4.68762L11.3904 5.31232Z" fill="green" />
                        </svg>
                      ) : (
                        <svg width={50} height={50} viewBox="0 0 15 15" fill="red" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.5 3.793L3.793 4.5 7.293 8 3.793 11.5 4.5 12.207 8 8.707 11.5 12.207 12.207 11.5 8.707 8 12.207 4.5 11.5 3.793 8 7.293 4.5 3.793Z" fill="red" />
                        </svg>
                      )
                    ) : (
                      i + 1
                    )}
                  </Td>
                  <Td position={12} index={i}>
                    {searchText.toLowerCase() !== "approved" && (
                      <Button type="update" onClick={() => setState({ ...state, edit: true, student: x })}>
                        Edit
                      </Button>
                    )}
                  </Td>
                  <Td position={13} index={i}>
                    <Button type="fetch" onClick={() => setState({ ...state, viewMode: true, studentId: x?._id })}>
                      View
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Body>
      </Main>

      {/* {status === "loading" && <LoadingAnimation  />} */}

      {state.viewMode && (
        <ModalWrapper>
          <ViewStudentData
            uid={state?.studentId}
            onClose={() => {
              setState({ ...state, viewMode: false });
            }}
          />
        </ModalWrapper>
      )}

      {state?.edit && <EditGrand sortedValue={searchText} collegeId={collegeId} userData={{ ...state?.student, year: state.year }} onClose={() => setState((prevData) => ({ ...prevData, edit: false }))} />}
      {state?.msg && <Messaging onClose={() => setState({ ...state, msg: false })} />}
      {saction && (
        <>
          {sactionStudents?.[0] ? (
            searchText.toLowerCase() === "approved" ? (
              <Saction
                userDatas={sactionStudents}
                collegeId={collegeId}
                onClose={() => {
                  closeAnimation();
                  setTimeout(() => {
                    setSactionStudents([]);
                    setSanction(false);
                  }, 1000);
                }}
              />
            ) : (
              searchText.toLowerCase() === "sanctioned" && (
                <Withdraw
                  onClose={() => {
                    closeAnimation();
                    setTimeout(() => {
                      setSactionStudents([]);
                      setSanction(false);
                    }, 1000);
                  }}
                  userData={sactionStudents}
                />
              )
            )
          ) : (
            <div className="flex justify-center items-center absolute top-0 left-0 h-screen w-screen bg-[#0000009a]">
              <div className="p-3 max-w-[80vw] rounded bg-blue-500">
                <Button type="close" onClick={() => setSanction(false)}>
                  Close
                </Button>
                <div className="flex flex-wrap">
                  {admisionNo.map((x) => (
                    <p className="bg-emerald-500  px-3 m-2 rounded py-2">{x}</p>
                  ))}
                </div>
                <textarea
                  cols={30}
                  rows={10}
                  className="w-full rounded"
                  value={sactionTyped}
                  onChange={(e) => {
                    setSanctionTyped(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    console.log(e.key);

                    if (e.key === "," || e.key === "Backspace") {
                      const values = sactionTyped.split(",");
                      console.log(values);
                      setAdmissionNo(values);
                    }
                  }}
                  onPaste={(e) => {
                    const pastedData = e.clipboardData.getData("text");
                    const values = pastedData.split(",");
                    console.log(values);
                    setAdmissionNo(values);
                  }}
                />
                <Button
                  type="save"
                  onClick={() => {
                    const students = userList.filter((x: any) => admisionNo.includes(`${x.admisionNo}`));
                    setSactionStudents(students);
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default EGrantManagement;
