import React, { useState, useContext, useEffect } from "react";
import { Select } from "antd";
import Button from "antd/lib/button";
import { useQuery, useMutation } from "react-query";
import classNames from "./masterTable.module.scss";
import DashLayout from "../../layout/DashLayout";
import StudentTable from "../../components/StudentTable";
import StudentDetail from "../../components/StudentDetail";
import { FOOTER_ICONS_MAIN, ROLES } from "../../config/constants";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { capitalizeFirstLetter } from "../../utils/index";
import LoadingAnimation from "../../components/LoadingAnimation";
import { useAllClasses } from "../../queryHooks";
import { ExportModal } from "./ExportModal";

const getUserList = async ({ queryKey }) => {
  const [_, { type, collegeId, classId }] = queryKey;
  try {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(
      `/college/users/get?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`
      }${classId ? `&classId=${classId}` : ""}`
    );
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }

    return resp.data;
  } catch (err) {
    console.log({ err });
    throw new Error(err || "API Error");
  }
};


export const exportPdf = async (data) => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.post(`/college/tag/generatePdf`, data, {
      responseType: "blob",
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || "API Error"
    );
  }
};

  export const DashPage = ({ onClose }) => {
    
  const [userType, setUserType] = useState("student");
  const [classId, setClassId] = useState("");
  const [className, setClassName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const allClasses = useAllClasses({ needAll: false, classOnly: false });







  const { collegeId } = useContext(FirebaseContext);
  const { data, status, error, refetch } = useQuery(
    [`userList${userType}`, { type: userType, classId, collegeId }],
    getUserList
  );

  const exportPdfMutation = useMutation(exportPdf);
  const { Option } = Select;

  const [uid, setUid] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUid(data?.list[0]?.uid);
  }, [data?.list]);
  const onExport = async (templateId, additionalData = {}) => {
    setModalVisible(false);
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let fileName = userType?.toUpperCase() || "";
      let postData = {
        collegeId,
        templateId,
      };
      if (userType === "student") {
        postData["type"] = "student";
        postData["classId"] = classId;
        fileName += className;
      } else {
        postData["type"] = "staff";
        postData["users"] = data?.list?.map((x) => x.uid);
      }
      postData = {
        ...postData,
        ...additionalData,
      };
      link.download = className + '.pdf';
      const pdfData = await exportPdfMutation.mutateAsync(postData);
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
    } catch (e) { }
  };




  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={classNames.container}>

    <div className={classNames.modal} >

      <div
        className={classNames.tableView}
        style={{ backgroundColor: status !== "success" ? "white" : "" }}
      >
        <div className={classNames.tableHeader}>
          <h2>Master Table</h2>
          <div className={classNames.searchWrap}>
            <input
              type="text"
              placeholder="Search User"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className={classNames.dropDown}>
            <div className={`${classNames.group} ${classNames.formFullWidth}`}>
              <Select
                showSearch
                className={classNames.formFullWidth2}
                style={{ overflow: "hidden" }}
                onChange={(val) => {
                  setUserType(val);
                  setClassId("");
                }}
              >
                {ROLES?.map((item, i) => (
                  <Option key={i} value={item}>
                    {capitalizeFirstLetter(item)}
                  </Option>
                ))}
              </Select>
              <label>Type</label>

            </div>
          </div>
          {userType === "student" && (
            <div className={classNames.dropDown}>
              <div className={`${classNames.group} ${classNames.formFullWidth}`}>
                <Select
                  showSearch
                  className={classNames.formFullWidth2}
                  style={{ overflow: "hidden" }}
                  onChange={(val) => {
                    setClassId(allClasses?.data?.find(x => x.className === val)._id);
                    setClassName(val);
                  }}
                >
                  {allClasses?.data?.map((item, i) => (
                    <Option key={i} value={item.className}>
                      {item.className}
                    </Option>
                  ))}
                </Select>
                <label>Select Class</label>

              </div>
            </div>
          )}
          {(userType !== "student" || classId) && (
            <div
              className={classNames.exportBtn}
              onClick={() => setModalVisible(true)}
            >
              <Button>{"Export"}</Button>
            </div>
          )}
        </div>
        {(status === "loading" || exportPdfMutation.isLoading) && (
          <LoadingAnimation dark />
        )}
        {status === "success" && (
          <StudentTable
            list={data?.list}
            index={uid}
            setIndex={setUid}
            refetch={refetch}
            search={search}
            userType={userType}
          />
        )}
      </div>
      <StudentDetail uid={uid} isStudent={userType === "student"} />
      {modalVisible && (
        <ExportModal
          type={userType === "student" ? "student" : "staff"}
          onExport={onExport}
          onClose={() => setModalVisible(false)}
        />
      )}


       
  {isModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg relative">
        <button 
          className="absolute top-2 right-2 text-black"
          onClick={handleCloseModal}
        >
          &times;
        </button>
        <h2 className="text-2xl mb-4">Modal Title</h2>
        <p>This is a modal.</p>
      </div>
    </div>
  )}


      </div>
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
      </div>
  
  );
}

export default DashPage;
