import React, { useContext, useRef, useState } from 'react';
import { getAxiosInstance } from '../../utils/axiosInstance';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Input from '../../NecttosComp/Input/Input';
import { useAllClasses } from '../../queryHooks';
import { FirebaseContext } from '../../context/FirebaseContext';

interface RegisterProps {
  setLogin: (val: boolean) => void;
  collegeData: {
    collegeName: string;
    lastApplicationNo: string;
    logo: string;
  };
  login?: boolean;
}

interface FilledData {
  mobile: string;
  name: string;
  houseName: string;
  place: string;
  postOffice: string;
  pinCode: string;
  plusTwoDegreeStream: string;
  coursePreference: string;
}

export const getCollegeDetails = async ({ queryKey }: any) => {
  const [_key, collegeId, year, optionalSubjects] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get('/college/admissionProcess/getClasses', {
      params: { collegeId, year },
    });
    if (data?.statusCode === 200) {
      return data.list;
    } else {
      throw new Error(data?.message || 'Failed to fetch college details.');
    }
  } catch (error) {
    throw new Error('API Error');
  }
};

const FYUGPRegister: React.FC<RegisterProps> = ({ setLogin }) => {
  const [isSendOtp, setSendOtp] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const [otpFull, setOtpFull] = useState<string>('');
  const [confirm, setConfirm] = useState<string | null>(null);
  const { collegeId } = useContext(FirebaseContext);
  const [errror, setError] = useState(null);

  const [filledData, setFilledData] = useState<FilledData>({
    mobile: '',
    name: '',
    houseName: '',
    place: '',
    postOffice: '',
    pinCode: '',
    plusTwoDegreeStream: '',
    coursePreference: '',
  });

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const verifyOtp = async (otp: string) => {
    if (!otp) {
      toast.error('OTP is required');
      return;
    }

    setIsLoading(true);
    try {
      const instance = await getAxiosInstance();
      const response = await instance.post(`/auth/verify-otp`, {
        token: confirm,
        otp,
      });

      const { jwtToken, collegeId, phoneNumber, userName, uid } = response.data;

      if (jwtToken) {
        localStorage.setItem('userToken', jwtToken);
      } else {
        throw new Error('JWT token is missing');
      }

      if (collegeId) {
        localStorage.setItem('collegeId', JSON.stringify(collegeId));
      }

      if (phoneNumber && userName && uid) {
        localStorage.setItem(
          'userDetails',
          JSON.stringify({
            phoneNumber,
            username: userName,
            displayName: userName,
            uid,
          }),
        );
      } else {
        throw new Error('User details are incomplete');
      }

      setLogin(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const sendOtp = async () => {
    const instance = await getAxiosInstance();
    if (filledData.mobile) {
      instance
        .post(`/auth/sent-otpWithCheck`, {
          phoneNumber: `+91${filledData.mobile}`,
        })
        .then((response) => {
          setError(null);
          const elem = document.getElementById('loginForm');
          if (elem) {
            elem.style.height = '350px';
            const otp = document.getElementById('otp');
            if (otp) {
              otp.style.display = 'flex';
            }
          }
          let second = 300;
          let intervalId = setInterval(() => {
            second = second - 1;
            setSendOtp(second);
            if (second <= 0) {
              clearInterval(intervalId);
            }
          }, 1000);
          setConfirm(response.data.token);

          toast.success('OTP Sended');
        })
        .catch((err) => {
          setError(err?.response?.data?.message);
          toast.error('Number Already Exist');
        });
    } else {
      toast.error('Please give Phone number');
    }
  };

  const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newOtp.every((value) => value.length === 1)) {
      setOtpFull(newOtp.join(''));
      verifyOtp(newOtp.join(''));
    }
  };

  const styleNmae = 'block w-full px-4 py-3 mb-2 text-gray-700 bg-white border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ';
  //

  const [dropDown, setDropDown] = useState<any>([]);
  const params: { id: string; role: string } = useParams();
  const [droRoles, setDropRoles] = useState<any>([]);
  const [respondsData, setResponseData] = useState<any>({});
  const [success, setSuccess] = useState<any>({});
  const [mobileFetchError, setMobileFetchError] = useState('');
  const [userDetected, setUserDetected] = useState(false);
  const [phoneNumberDisabled, setPhoneNumberDisabled] = useState(false);
  const [classes, setClasses] = useState('');

  const [formState, setFormState] = useState<any>({
    phoneNumber: '',
    name: '',
    department: '',
    classes: '',
    usersData: '',
    roles: '',
    role: params?.role || '',
    aadhaarNumber: '',
    district: '',
    dob: '',
    gender: '',
    houseName: '',
    admisionNo: '',
    nationality: '',
    pinCode: '',
    place: '',
    postOffice: '',
    state: '',
    bloodGroup: '',
    guardianName: '',
    guardianMobile: '',
    guardianRelation: '',
  });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState((prevState: any) => ({
      ...prevState,
      name: event.target.value,
    }));
  };

  const handleInputChange2 = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setFormState((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

 
  const SuccessToastContent = () => (
    <div style={{ display: 'flex', alignItems: 'center', color: 'blue' }}>
      <span style={{ marginRight: '10px' }}>✔️</span>
      <span>Form submitted successfully!</span>
    </div>
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!userDetected) {
      return;
    }

    if (formState?.phoneNumber?.length !== 10) {
      toast.error('Please Enter 10 digits');
      return;
    }

    if (!formState?.classes) {
      setClasses('Please Select class');
      toast.error('Please Select class');
      return;
    }

    try {
      const instance = await getAxiosInstance();
      let data = {
        ...formState,
        ...params,
        userId: formState?.usersData?._id,
        // collegeId: params.id,
        collegeId,
      };
      data.phoneNumber = data?.phoneNumber?.replace('+91', '');

      const dataValues = await instance.post('/college/users/addNewUserForm', data);

      if (dataValues) {
        setResponseData(dataValues);
      }

      toast.success(<SuccessToastContent />, {
        position: 'top-center',
        duration: 10000,
      });
      setFormState({
        phoneNumber: '',
        name: '',
        department: '',
        classes: '',
        usersData: '',
        roles: '',
        role: params?.role || '',
        aadhaarNumber: '',
        district: '',
        dob: '',
        admisionNo: '',
        gender: '',
        houseName: '',
        nationality: '',
        pinCode: '',
        place: '',
        postOffice: '',
        state: '',
        bloodGroup: '',
        guardianName: '',
        guardianMobile: '',
        guardianRelation: '',
      });
    } catch (error) {
      console.error(error);

      const axiosError = error as AxiosError;

      if (axiosError.response && axiosError.response.data && axiosError.response.data.error) {
        toast.error(axiosError.response.data.error);
      } else {
        toast.error('Sorry...Something went wrong');
      }
    }
  };

  const allClasses = useAllClasses({
    needAll: false,
    classOnly: true,
    subId: params.id,
  });

  interface AxiosError {
    response?: {
      data?: {
        error?: string;
      };
      status?: number;
      message?: string;
    };
  }
  //
  return (
    <div className="pt-4 h-[100vh]" style={{ alignItems: 'center' }}>
      <div>
        <div className="max-w-lg  mx-auto p-6 bg-[#ffffff] rounded-lg shadow-lg">
          <h2 className="text-2xl  font-nunito font-bold text-center text-gray-800 ">Login to your FYUGP Account</h2>

          <div className="mb-6">
            <label htmlFor="mobile" className="block mb-2 text-sm font-nunito font-medium text-gray-700">
              Phone Number
            </label>
            <input min={1000000000} max={9999999999} id="mobile" type="number" placeholder="Enter your phone number" className={styleNmae} value={filledData.mobile} onChange={(e) => setFilledData({ ...filledData, mobile: e.target.value })} />

            {errror !== 'NO ACC WITH UID' && (
              <>
                <div className="bg-re-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                  <strong className="font-bold">Notice:</strong>
                  <span className="block sm:inline"> Please enter your registered mobile number and press the "Check Mobile Number" button to verify.</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <title>Close</title>
                      <path d="M14.348 5.652a1 1 0 00-1.415 0L10 8.586 7.067 5.652a1 1 0 00-1.415 1.415L8.586 10l-2.934 2.934a1 1 0 001.415 1.415L10 11.414l2.934 2.934a1 1 0 001.415-1.415L11.414 10l2.934-2.934a1 1 0 000-1.415z" />
                    </svg>
                  </span>
                </div>
                <button onClick={sendOtp} disabled={isLoading || isSendOtp > 0} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-600'}`}>
                  {isLoading ? 'Sending...' : 'Login'}
                </button>
              </>
            )}

            {errror === 'NO ACC WITH UID' && (
              <div className="flex items-center justify-center pt-36 p-1 bg-gray-100 ">
                <Toaster />
                <form className="w-full max-w-md bg-white rounded-lg shadow-md p-6" onSubmit={handleSubmit}>
                  {params?.role === 'student' && <p className="mb-4 text-center text-md text-gray-700">Dear students, we are excited to introduce a new app designed to streamline your academic and administrative tasks through automation. To ensure you have full access to its features, please register using your personal mobile number. This will allow us to tailor the experience specifically for you and keep you updated with important notifications.</p>}
                  <>
                    <div className="mb-4 mt-8">
                      <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
                        Name of the student
                      </label>
                      <input type="text" id="name" name="name" value={formState.name} onChange={handleNameChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="admisionNo" className="block text-gray-700 text-sm font-bold mb-2">
                        Admission No
                      </label>
                      <input type="text" id="admisionNo" name="admisionNo" value={formState.admisionNo} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="dob" className="block text-gray-700 text-sm font-bold mb-2">
                        Date of Birth
                      </label>
                      <input type="date" id="dob" name="dob" value={formState.dob} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="gender" className="block text-gray-700 text-sm font-bold mb-2">
                        Gender
                      </label>
                      <select id="gender" name="gender" value={formState.gender} onChange={handleInputChange2} required className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="bloodGroup" className="block text-gray-700 text-sm font-bold mb-2">
                        Blood Group
                      </label>
                      <select id="bloodGroup" name="bloodGroup" value={formState.bloodGroup} onChange={handleInputChange2} required className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option value="">Select Blood Group</option>
                        <option value="A+">A+</option>
                        <option value="A-">A-</option>
                        <option value="B+">B+</option>
                        <option value="B-">B-</option>
                        <option value="AB+">AB+</option>
                        <option value="AB-">AB-</option>
                        <option value="O+">O+</option>
                        <option value="O-">O-</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="guardianName" className="block text-gray-700 text-sm font-bold mb-2">
                        Guardian Name
                      </label>
                      <input type="text" id="guardianName" name="guardianName" value={formState.guardianName} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="guardianMobile" className="block text-gray-700 text-sm font-bold mb-2">
                        Guardian Mobile
                      </label>
                      <input type="number" id="guardianMobile" minLength={10} maxLength={10} pattern="\d{10}" name="guardianMobile" value={formState.guardianMobile} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="guardianRelation" className="block text-gray-700 text-sm font-bold mb-2">
                        Guardian Relation
                      </label>
                      <select id="guardianRelation" name="guardianRelation" value={formState.guardianRelation} onChange={handleInputChange2} required className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option value="">Select Relation</option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Brother">Brother</option>
                        <option value="Sister">Sister</option>
                        <option value="Uncle">Uncle</option>
                        <option value="Aunt">Aunt</option>
                        <option value="Grandfather">Grandfather</option>
                        <option value="Grandmother">Grandmother</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <label htmlFor="houseName" className="block text-gray-700 text-sm font-bold mb-2">
                        House Name
                      </label>
                      <input type="text" id="houseName" name="houseName" value={formState.houseName} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="place" className="block text-gray-700 text-sm font-bold mb-2">
                        Place
                      </label>
                      <input type="text" id="place" name="place" value={formState.place} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="postOffice" className="block text-gray-700 text-sm font-bold mb-2">
                        Post Office
                      </label>
                      <input type="text" id="postOffice" name="postOffice" value={formState.postOffice} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="pinCode" className="block text-gray-700 text-sm font-bold mb-2">
                        PIN Code
                      </label>
                      <input type="number" id="pinCode" name="pinCode" value={formState.pinCode} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="district" className="block text-gray-700 text-sm font-bold mb-2">
                        District
                      </label>
                      <input type="text" id="district" name="district" value={formState.district} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="state" className="block text-gray-700 text-sm font-bold mb-2">
                        State
                      </label>
                      <input type="text" id="state" name="state" value={formState.state} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="nationality" className="block text-gray-700 text-sm font-bold mb-2">
                        Nationality
                      </label>
                      <input type="text" id="nationality" name="nationality" value={formState.nationality} onChange={handleInputChange2} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                    </div>
                  </>
                  {classes && (
                    <>
                      <p className="block text-red-400 text-sm font-bold mb-2 ">Please select your class </p>
                    </>
                  )}
                </form>
              </div>
            )}

            {errror !== 'NO ACC WITH UID' && (
              <button onClick={sendOtp} disabled={isLoading || isSendOtp > 0} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-600'}`}>
                {isLoading ? 'Sending...' : 'Login'}
              </button>
            )}
          </div>
          <div id="otp" className={`grid grid-cols-6 gap-4 ${isSendOtp > 0 ? 'block' : 'hidden'}`}>
            {otp.map((value, index) => (
              <input key={index} type="text" maxLength={1} className="w-full px-2 py-2 text-center text-gray-700 bg-white border rounded-md shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" value={value} onChange={(e) => handleInputChange(index, e)} ref={(el) => (inputRefs.current[index] = el as HTMLInputElement)} />
            ))}
          </div>
          {isSendOtp > 0 && (
            <>
              <div className="mt-4 text-center">
                <p className="text-sm text-gray-600">
                  OTP expires in: <span className="font-nunito font-semibold">{isSendOtp}</span> seconds
                </p>
              </div>

              <button onClick={() => verifyOtp(otpFull)} className={`w-full text-white font-nunito font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-colors duration-200 ${isLoading || isSendOtp > 0 ? 'bg-blue-400' : 'bg-blue-500 hover:bg-blue-600'}`}>
                Verify OTP
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FYUGPRegister;
