import React, { useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import file1 from "../../static/images/naac/File 1.png";
import file2 from "../../static/images/naac/File 2.png";
import file3 from "../../static/images/naac/File 3.png";
import file4 from "../../static/images/naac/File 4.png";
import file5 from "../../static/images/naac/File 5.png";
import file6 from "../../static/images/naac/File 6.png";
import file7 from "../../static/images/naac/File 7.png";
import C2_1 from "../../pages/college/NAAC/C2/C2_1";
import C2_2 from "../../pages/college/NAAC/C2/C2_2";
import C2_3 from "../../pages/college/NAAC/C2/C2_3";
import C2_4 from "../../pages/college/NAAC/C2/C2_4";
import C2_5 from "../../pages/college/NAAC/C2/C2_5";
import C2_6 from "../../pages/college/NAAC/C2/C2_6";
import C2_7 from "../../pages/college/NAAC/C2/C2_7";
import C2_8 from "../../pages/college/NAAC/C2/C2_8";
import C2_9 from "../../pages/college/NAAC/C2/C2_9";

interface PropsC2 {
  onClose: () => void;
}
function C2({ onClose }: PropsC2) {
  const [criteria, setCriteria] = useState<any>();
  const folders = [
    { classification: "c2", keyData: "c2_1", title: "2.1", img: file1, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_4", title: "2.1.1", img: file2, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_5", title: "2.1.2", img: file3, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_9", title: "2.1.2 & 2.1.1", img: file3, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_2", title: "2.2", img: file4, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2", title: "2.2.1", img: file3, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_3", title: "2.3", img: file5, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_10", title: "2.3.3", img: file3, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2", title: "2.3.2", img: file3, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_6", title: "2.4.1", img: file6, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_7", title: "2.4.2", img: file6, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_6_1", title: "2.4.3", img: file6, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2", title: "2.5.1", img: file3, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
    { classification: "c2", keyData: "c2_8", title: "2.6.3", img: file6, instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "principal", "iqac", "naac"] },
  ];
  return (
    <>
      <Main width="auto" height="auto">
        <Header>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </Header>
        <Body>
          <div className="h-[65vh] overflow-auto">
            {folders?.map((item) => (
              <>
                <div onClick={() => setCriteria({ [item.keyData]: true })} className={"roleCards m-[2vh]  float-left"} id={item?.keyData} draggable={true}>
                  <div className={"icons"}>
                    <div key={item.keyData}>
                      <img src={item?.img} />
                    </div>
                  </div>
                  <h2 className="text-center mt-[.11vh] text-shadow">{item?.title}</h2>
                </div>
              </>
            ))}
          </div>
        </Body>
      </Main>

      {criteria?.c2_1 && (
        <C2_1
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_1: false });
            }, 250);
          }}
        />
      )}
      {criteria?.c2_2 && (
        <C2_2
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_2: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_3 && (
        <C2_3
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_3: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_4 && (
        <C2_4
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_4: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_5 && (
        <C2_5
          onClose={() => {
            closeAnimation();

            setTimeout(() => {
              setCriteria({ ...criteria, c2_5: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_6 && (
        <C2_6
          title={" 2.4.1 Number of full time teacher against sanctioned posts during the year"}
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_6: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_6_1 && (
        <C2_6
          title={" 2.4.3 Number of full time teacher against sanctioned posts during the year"}
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_6_1: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_7 && (
        <C2_7
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_7: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_8 && (
        <C2_8
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_8: false });
            }, 1000);
          }}
        />
      )}
      {criteria?.c2_9 && (
        <C2_9
          onClose={() => {
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_9: false });
            }, 1000);
          }}
        />
      )}
      {/* {criteria?.c2_10 && (
        <ModalWrapper>
          <C2_10 onClose={() =>{
            closeAnimation();
            setTimeout(() => {
              setCriteria({ ...criteria, c2_10: false });
            }, 1000);
            }} />
        </ModalWrapper>
      )} */}
    </>
  );
}

export default C2;
