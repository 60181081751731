import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getFootPrint } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import CustomInput from "../../../../myComp/CustomInput";
import CustomDropDown from "../../../../myComp/CustomDropDown";

const exportPdf = async ({ olddata, setLoading }) => {
  try {
    console.log('==2======>');

    const { collegeId, method, dateFrom, dateTo } = olddata;
    console.log('==3======>');
    const instance = await getAxiosTokenInstance();

    const resp = await instance.post('/college/reportGeneration/footPrint', { collegeId, method, dateFrom, dateTo }, { responseType: 'blob' });
    console.log('==4======>');
    if (resp.data.statusCode === 400) {
      setLoading(false);
    
      throw new Error(resp.data.message || 'API Error');
    }
   
    return resp.data;
  } catch (error) {
    setLoading(false);
    throw new Error(error?.response?.data?.message || error.message || 'API Error');
  }
};


const { Option } = Select;
export const FootPrint = ({ onClose }) => {

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const [loading, setLoading] = useState(false);

  const [isPrinting, setIsPrinting] = useState(false);
  const exportPdfMutation = useMutation(exportPdf);

  const onExportExam = async () => {
    try {
      setLoading(true);

      console.log('==0======>');
      const { method, dateFrom, dateTo } = state;
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = `Foot Print`;

      console.log('==1======>');
      const pdfData = await exportPdfMutation.mutateAsync({ olddata: { collegeId, method, dateFrom, dateTo }, setLoading });

      link.href = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));

      link.click();

      setLoading(false);
      onClose();
    } catch (e) {
    }
  };

  const [state, setState] = useState({
    dateTo: "",
    dateFrom: "",
    method: 'All'
  });

  const { data = [], refetch } = useQuery(['getFootPrint', state.dateFrom, state.dateTo], getFootPrint(collegeId, state.dateFrom, state.dateTo));
  const [isLoading, setIsLoading] = useState(false);

  const [admisionNumber, setAdmisionNumber] = useState('');

  const [student, setStudent] = useState({ name: '', photo: '', class: '' });
  const [showDetails, setShowDetails] = useState(false);

  const filteredData = data.filter(item => {
    return state.method === 'All' || item.method === state.method;
  });
  const manageDDCSubject = useCallback(async () => {
    try {
      setIsLoading(true);
      const instance = await getAxiosTokenInstance();
      let resp = await instance.post("/college/action/postFootPrint", {
        collegeId,
        admisionNo: admisionNumber,
        method: 'offline',
        type: 'internal',
      });

      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        // Assuming resp.data has student details


        setStudent({
          name: resp?.data?.userIdDoc?.name,
          photo: resp?.data?.userIdDoc?.photoURL,
        });
        setShowDetails(true);
        setTimeout(() => setShowDetails(false), 40000); // hide after 40 seconds

        setAdmisionNumber('');
        refetch();
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  }, [admisionNumber]);

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        manageDDCSubject()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [manageDDCSubject]);


  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >

        <div className={classNames.listContainer}>

          {isLoading ? <LoadingAnimation dark /> :
            <>
              <div className={classNames.list}>
                {
                  state.alert ?
                    <label className={classNames.heading}>{state.alert}</label> :
                    <label className={classNames.heading}>Gate Entry</label>
                }
                <div >
                  <div style={{ marginLeft: 90 }}>
                    <h5>{'Admission Number'}</h5>
                    <input
                      type={'number'}
                      autoFocus
                      style={{ fontSize: 20, fontWeight: 900, width: 200, borderRadius: 20, height: 40, margin: 5, padding: 12 }}
                      onChange={(e) => setAdmisionNumber(e.target.value)}
                      value={admisionNumber}
                    />
                  </div>
                </div >

                <div style={{ margin: 12, height: '50px', width: '74%', alignSelf: 'center' }}  >
                  <Button className={classNames.cancelBotton} onClick={() => {
                    setAdmisionNumber('')
                  }}>  Clear </Button>
                  <Button className={classNames.submitBotton} onClick={() => { manageDDCSubject() }}>
                    Submit
                  </Button>
                </div>


                {showDetails && (
                  <div>
                    <img src={student.photo} alt="Student's Photo" />
                    <p>Name: {student.name}</p>
                  </div>
                )}
              </div>

            </>
          }
        </div>
        <div className={classNames.listContainer2}>
          <Scrollbars style={{ height: '100%' }} >
            <div ref={componentRef} className="printable">
              <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                <h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Foot Prints</h5>
                <div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 16, width: '5%', }}>Sn</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '15%' }}>Admn No</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 16, width: '30%' }}>Name</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '20%' }}>Class/Dept</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}>Date</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 16, width: '10%' }}>Time</h5>
                </div>
                {filteredData?.map((item, i) => (
                  <>
                    <div style={{ width: '100%', paddingRight: 12, display: 'flex', minHeight: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 19, width: '5%' }}>{i + 1}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '15%' }}>{item.admisionNo}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', alignSelf: 'left', fontSize: 19, width: '30%' }}>{item.name}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '20%' }}>{item.classDept}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '10%' }}>{item.date}</h5>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 19, width: '10%' }}>{item.time}</h5>
                    </div>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      height: 1,
                      backgroundColor: 'black',
                    }}></div>
                  </>
                ))}
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
      {!isPrinting && (
        <>
          <Button className={classNames.reloadButton} onClick={() => { refetch() }}>
            Reload
          </Button>

          <Button className={classNames.closeButton} onClick={onClose}>
            Close
          </Button>

        </>
      )}

      {isPrinting && <p>Printing in progress...</p>}

      <div style={{
        height: '40px',
        display: 'flex',
        width: '800px',
        flexDirection: 'row',
        position: 'absolute',
        right: '15%',
        top: '1%'
      }}>
        <Button className={classNames.button} onClick={onExportExam}>
          Download
        </Button>
        <div style={{ width: 400 }}>
          <CustomDropDown
            keyData={'method'}
            titleData={'Select method'}
            dropValues={["All", "online", "offline"]}
            stateData={state}
            setStateData={setState}
          />
        </div>

        < CustomInput
          keyData={'dateFrom'}
          titleData={'Start Date'}
          typeData={'date'}
          stateData={state}
          setStateData={setState}
        />



        < CustomInput
          keyData={'dateTo'}
          titleData={'End Date'}
          typeData={'date'}
          stateData={state}
          setStateData={setState}
        />
      </div>

      <ReactToPrint
        onBeforeGetContent={() => setIsPrinting(true)}
        onAfterPrint={() => setIsPrinting(false)}
        trigger={() => <button className={classNames.printBotton}>Print</button>}
        content={() => componentRef.current}
      />

    </div>
  );
};

