import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import moment from "moment";
import { useMutation } from "react-query";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { AcademicYears, naacExcelGenerator, naacFetchData } from "../../ApiServices";


function C3_1a({ val, onClose }) {
  const [values, setValues] = useState();
  const { collegeId } = useContext(FirebaseContext);
  const [academicYear,setAcademicYear]=useState("2022-23")
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    naacFetchData({
      data: { collegeId, academicYear},
      url: "/college/NAAC/getNAAC_C3_1a",
      setLoading,
      setValues,
    });
  }, []);
  return (
    <>
      <div className="  z-10 w-4/5 bg-slate-100 h-4/5 px-3 overflow-auto">
        {loading ? (
          <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]">
            <LoadingAnimation />
          </div>
        ) : null}
        <table className="table">
          <thead>
            <th
              colSpan={7}
              style={{ background: "rgb(241 245 249)", border: "none" }}
            >
              <h1 className="text-xl flex justify-between sticky">
                3.1 a{")"} Number of full time teachers during the year
                <div>
                  <AcademicYears setAcademicYear={setAcademicYear}/>
                  <button
                    className="bg-green-500 text-sm"
                    onClick={()=>naacExcelGenerator({data:{collegeId,academicYear},setLoading,url:"/college/NAAC/getNAAC_C3_1a"})}
                  >
                    Dowload
                  </button>
                  <button
                    className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]"
                    onClick={onClose}
                  >
                    X
                  </button>
                </div>
              </h1>
            </th>
            <tr>
              <th className="w-1/4">Name</th>
              <th className="w-1/4">ID number/Aadhar number (not mandatory)</th>
              <th className="w-1/4">Email</th>
              <th style={{width:"70px"}}>Gender</th>
              <th className="w-1/4">Designation</th>
              <th className="w-1/4">Date of joining institution</th>
              <th>
                Number of sanctioned posts during the five year
              </th>
            </tr>
          </thead>
          <tbody>
            {values?.map((x) => (
              <tr>
                <td>{x?.name}</td>
                <td>{x?.aadhaarNumber}</td>
                <td>{x?.email}</td>
                <td style={{width:"70px"}}>{x?.gender}</td>
                <td>{x?.designation}</td>
                <td>{x?.dateOfJoin}</td>
                <td>{""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default C3_1a;
