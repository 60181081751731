import React, { useContext, useEffect, useRef, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getSplitups } from "../../pages/college/ApiServices";
import { useQuery } from "react-query";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import Input from "../../NecttosComp/Input/Input";

const getFieldDropDowns = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getFieldDropDowns", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

function Splitups({ onClose }) {
  const { collegeId } = useContext(FirebaseContext);
  const componentRef = useRef();
  const [batch, setBatch] = useState("2024-2028");
  const { data: incomingData, refetch } = useQuery(["getSplitups", batch], getSplitups(collegeId, batch));
  const [search, setSearch] = useState("");

  const [data, setData] = useState([]);
  const [splitupsKey, setSplitupsKey] = useState(incomingData?.keys || []);

  const handleChange = (e, index, field) => {
    const updatedData = [...data];
    updatedData[index][field] = parseInt(e.target.value);
    let coppyData = { ...updatedData[index] };
    delete coppyData.id;
    delete coppyData.course;
    delete coppyData.total;
    const totalPrice = Object.values(coppyData).reduce((acc, value) => acc + parseInt(value), 0);
    updatedData[index]["total"] = totalPrice;
    setData(updatedData);
  };

  const { data: batchesDrop = [] } = useQuery(["nameOfAuthorData", search], getFieldDropDowns({ collegeId, search, collection: "classes", key: "batchName" }), { keepPreviousData: true });

  const handleSave = async () => {
    try {
      const axiosInstance = await getAxiosTokenInstance();
      const reformattedData = [];
      for (let item of data) {
        const splitup = [];
        for (let key in item) {
          if (splitupsKey.includes(key)) {
            splitup.push({
              title: key,
              inTake: parseInt(item[key], 10) || 0,
            });
          }
        }
        reformattedData.push({ _id: item?.id, splitup });
      }
      await axiosInstance.post("/college/admissionProcess/updateSplitup", {
        data: reformattedData,
        collegeId,
      });
      refetch();
      onClose();
    } catch (error) {
      console.error("Error during save operation:", error);
      alert("Failed to save data. Check the console for more details.");
    }
  };

  const setSplutups = () => {
    let data = [];

    incomingData?.classArray?.forEach((element) => {
      let dataObj = [];
      dataObj.id = element?._id;
      dataObj.course = element?.shortName;
      let total = 0;
      element?.splitup?.forEach((spl) => {
        dataObj[spl.title] = spl?.inTake;
        total += parseInt(spl?.inTake);
        if (!splitupsKey?.includes(spl?.title)) {
          splitupsKey.push(spl.title);
        }
      });
      dataObj.total = total;
      data?.push(dataObj);
    });

    setData(data);
  };

  const deleteItem = (customKey) => {
    // splitupsKey = splitupsKey.filter(item => item !== );

    data = data.map((obj) => {
      const { [customKey]: removedKey, ...rest } = obj; // Destructure the custom key and capture the rest of the properties
      return rest; // Return the modified object without the custom key
    });
    setData(data);
  };

  useEffect(() => {
    setSplutups();
  }, [incomingData]);

  return (
    <>
      <Main height="100%" width="100%" title="Split UP / Seat Metrix Managment">
        <Header>
          <div className="flex items-center">
            <Input fieldName="Batch Name" width="15vw" type="drop" typable onChange={(e) => setSearch(e.value)} options={batchesDrop} setState={setBatch} state={batch} />
          </div>
          <div className="flex items-center">
            <Button type="update" onClick={handleSave}>
              Save Now
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body height="100%">
          <div className="printable h-auto w-full overflow-auto flex flex-row">
            <Table width={70 * splitupsKey.length + 80 + 100 + "px"}>
              <Thead>
                <Tr>
                  <Th width="100px" fontSize="14px">
                    Name
                  </Th>
                  <Th width="80px" fontSize="14px">
                    Total
                  </Th>
                  {splitupsKey.map((sk, skKey) => (
                    <Th width="70px" fontSize="12px">
                      <input
                        type="text"
                        className="text-black"
                        value={sk}
                        onChange={(e) => {
                          const temp = [...splitupsKey];
                          temp[skKey] = e.target.value;
                          setSplitupsKey([...temp]);
                        }}
                      />
                      <button
                        className="text-red-500 w-2 text-xs bg-none outline-none border-none m-1 font-bold flex justify-center items-center"
                        onClick={() => {
                          const temp = [...splitupsKey];
                          temp.splice(skKey, 1);
                          setSplitupsKey(temp);
                        }}
                      >
                        X
                      </button>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data.map((row, index) => (
                  <Tr>
                    <Td width="100px" fontSize="12px">
                      {row.course}
                    </Td>
                    <Td width="80px">{row.total}</Td>

                    {splitupsKey.map((sk, skKey) => (
                      <Td width="70px">
                        <input style={{ textAlign: "center" }} type="number" value={row[sk]} onChange={(e) => handleChange(e, index, [sk])} />
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Button type="save" onClick={() => setSplitupsKey([...splitupsKey, ""])}>
              Add Column
            </Button>
          </div>
        </Body>
      </Main>
    </>
  );
}

export default Splitups;
