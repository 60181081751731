import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import SemesterListItem from "./SemesterListItem";
import "./Semester.css";
import { getAxiosTokenInstance } from "../../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../../context/FirebaseContext";
import EditOrAdd from "./EditOrAdd";
import SubjectAllocation from "../SubjectAllocation/SubjectAllocation";
import Button from "../../../../../../NecttosComp/Button/Button";

const getDepCourseDetails = async (selectedCollege, classId) => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("college/semester/getSemesters", {
      params: { collegeId: selectedCollege?.collegeId, classId },
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.classData;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const getDepTeachers = async (selectedCollege) => {
  const instance = await getAxiosTokenInstance();

  try {
    const resp = await instance.get("/college/semester/getTeachers", {
      params: {
        type: "teacher",
        collegeId: selectedCollege?.collegeId,
      },
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data?.list;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const styles = {
  container: {
    flex: 1,
    marginTop: 17,
  },
  contentContainer: {
    paddingTop: 11,
    paddingBottom: 20,
  },
};

const Semester = ({ classId, className, goBack }) => {
  const [assign, setAssign] = useState();
  const [subjectAlo, setSubjectAlo] = useState();
  const { collegeList, collegeId } = useContext(FirebaseContext);
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const navigate = useHistory();

  const { data = [], status, refetch, error, isFetching } = useQuery(`courseDetail${classId}`, () => getDepCourseDetails(selectedCollege, classId));
  useEffect(() => {
    console.log({ data, status, refetch, error, isFetching });
  }, [data, status, refetch, error, isFetching]);

  const { data: allTeachers } = useQuery("getDepTeachers", () => getDepTeachers(selectedCollege));
  console.log(allTeachers);

  return (
    <div className="p-3">
      {subjectAlo ? (
        <SubjectAllocation onClose={() => setSubjectAlo()} collegeId={collegeId} classId={classId} semester={subjectAlo} />
      ) : (
        <>
          {assign ? (
            <EditOrAdd refetch={refetch} setAssign={setAssign} classId={classId} collegeId={collegeId} tutor={allTeachers} semester={assign?.semester} data={assign} />
          ) : (
            <>
              <div className="self-end">
                <Button type="fetch" onClick={goBack}>
                  Back
                </Button>
              </div>
              <div className="flex flex-col p-3">
                <ul className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {data?.semester?.map((x) => (
                    <li key={x.semester} className="flex flex-col gap-4">
                      {!x?.startingDate ? (
                        <div className="bg-red-200 w-full p-4 flex items-center text-gray-800 text-xl rounded-lg shadow">
                          <p>Semester {x?.semester}</p>
                          <p className="ml-auto">
                            {className} Sem{x?.semester}
                          </p>
                          <button className="ml-4 text-red-500 hover:text-red-600 focus:outline-none" onClick={() => setAssign(x)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2} className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div className={`w-full rounded-lg p-4 shadow-md ${new Date(x?.startingDate) < new Date() && new Date(x?.closingDate) > new Date() ? "bg-green-200" : "bg-red-200"}`}>
                          <div className="flex justify-between items-center">
                            <p className="text-gray-800 font-semibold">Semester {x?.semester}</p>
                            <p className="text-gray-800 font-semibold">
                              {className} Semester {x?.semester}
                            </p>
                            <button className="text-gray-800 hover:text-gray-900 focus:outline-none" onClick={() => setAssign(x)}>
                              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                              </svg>
                            </button>
                          </div>
                          <div className="flex flex-col sm:flex-row mt-4">
                            <div className="flex-1 flex flex-col items-center justify-center space-y-2">
                              <img src={x?.hodPhoto} className="h-16 w-16 rounded-full" alt={x?.hodName} />
                              <p className="text-sm text-gray-800">{x?.hodName}</p>
                            </div>
                            <div className="flex-1 flex flex-col items-center justify-center space-y-2 mt-4 sm:mt-0">
                              <img src={x?.classTeacherPhoto} className="h-16 w-16 rounded-full" alt={x?.classTeacherName} />
                              <p className="text-sm text-gray-800">{x?.classTeacherName}</p>
                            </div>
                            <div className="flex-2 flex flex-col items-center justify-center space-y-1 mt-4 sm:mt-0">
                              <p className="text-sm text-gray-800">{new Date(x?.startingDate).toLocaleDateString()}</p>
                              <p className="text-sm text-gray-800">{x?.days}</p>
                              <p className="text-sm text-gray-800">{new Date(x?.closingDate).toLocaleDateString()}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Semester;
