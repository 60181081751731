import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import VideoV3 from '../../components/Website/video';
import Offer from '../../components/Website/offer';
import WhatWeOffer from '../../components/Website/what-we-offer';
import Subscribe from '../../components/Website/subscribe';
import FooterBar from '../../components/Website/footerBar';

const OfferPage = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Offer" />
        <WhatWeOffer customclass="pd-top-112"/>
        <VideoV3 />
        <Offer />
        <Subscribe />
        <FooterBar />
    </div>
}

export default OfferPage

