import React, { useContext, useState } from 'react'
import { Table, Avatar } from "antd";
import classNames from "./feesPrintTable.module.scss";
import Scrollbars from "react-custom-scrollbars";
import ReusableModal from '../../../../components/ReusableModal';
import { useQuery } from 'react-query';
import { getCollectedFees } from '../../feesManagementServices';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance';
import { useMutation } from "react-query";

export const exportPdfBillPrinting = async (data) => {
    const instance = await getAxiosTokenInstance();
    try {
        const resp = await instance.post(`/college/reportGeneration/printBills`, data, {
            responseType: "blob",
        });

        if (resp.data.statusCode === 400) {
            throw new Error(resp.data.message || "API Error");
        } else {
            return resp.data;
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error.message || "API Error"
        );
    }
};

export default function FeesPrintTable({ search, handleCollect }) {

    const { collegeId } = useContext(FirebaseContext);
    const exportPdfMutationBillPrint = useMutation(exportPdfBillPrinting);

    const [, setCount] = useState(16);
    const [isPrintOpen, setisPrintOpen] = useState(false)
    const [printData, setPrintData] = useState({})
    const [page, setPage] = useState(1);

    const limit = 10;
    const skip = (page - 1) * limit;

    const { data = [], status, isFetching } = useQuery(['collectedFees', page, search], getCollectedFees(collegeId, skip, limit, search), { keepPreviousData: true })


    let feeDetails = data.data
    let hasNext = data.hasNext
    const handleOpenPrint = (newFes) => {
        setPrintData(newFes)
        setisPrintOpen(true)
    }

    const columns = [
        {
            title: 'Photo',
            dataIndex: 'Photo',
            render: (_, feeDetails, i) => <div className={classNames.avatar}>
                <img src={feeDetails?.photoURL} className={classNames.dp} />
            </div>
        },
        {
            title: 'Name',
            dataIndex: 'userName',
            key: 'userName',
        },
        {
            title: 'Class',
            dataIndex: 'className',
            key: 'className',
        },
        {
            title: 'Type',
            dataIndex: 'feeName',
            key: 'feeName',
        },
        {
            title: 'Date & Time',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: '',
            dataIndex: '',
            render: (_, data, i) => <button className={classNames.printButton} onClick={() => { handleOpenPrint(feeDetails[i]) }}>P</button>,
        },
        {
            title: '',
            dataIndex: '',
            render: (_, data, i) => <button className={classNames.refundButton} onClick={() => { handleCollect(feeDetails[i]) }}>R</button>,
        },
    ];

    const handleCancelPrint = () => {
        setisPrintOpen(false)
    }
    const handlePrint = async () => {
        setisPrintOpen(false)
        try {
            const link = document.createElement("a");
            link.target = "_blank";
            let postData = {
                collegeId,
                userId: printData.userId,
                billNumber: printData.billNumber,
                collectionId: printData.collectionId
            };

            link.download = data?.endDate;
            const pdfData = await exportPdfMutationBillPrint.mutateAsync(postData);
            link.href = URL.createObjectURL(
                new Blob([pdfData], { type: "application/pdf" })
            );
            link.click();
        } catch (e) { }
    }

    const onLoadPrev = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const onLoadNext = () => {
        setPage(page + 1)
    }

    return (
        <>
            {status === 'loading' ? <LoadingAnimation dark /> :
                <div className={classNames.tableWrap}>


                    <ReusableModal
                        visible={isPrintOpen}
                        modalText="Do you want to print this student details?"
                        title="Do you want to print?"
                        handleCancel={handleCancelPrint}
                        handleOk={handlePrint}
                    />
                    <Scrollbars
                        onScrollFrame={(feeDetails) => {
                            setCount((count) => count + 10);
                        }}>

                        <table className={classNames.userTable}>
                            <Table dataSource={feeDetails} pagination={false} columns={columns} bordered />
                        </table>
                        <div style={{ flex: 1, flexDirection: 'row' }}>
                            <button style={{ margin: '20px' }} onClick={onLoadPrev}>Load Prev</button>
                            <label style={{ backgroundColor: 'white', paddingLeft: '20px', paddingRight: '20px' }}>{page}</label>
                            <button style={{ margin: '20px' }} onClick={onLoadNext} disabled={hasNext === false}>Load Next</button>
                        </div>
                    </Scrollbars >
                </div>
            }
        </>
    )
}
