import React, { useContext, useEffect, useState } from "react";
import "./C2_1.css";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useMutation } from "react-query";
import moment from "moment";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../NecttosComp/Table/Table";

const exportPdf = async ({ olddata, setLoading }) => {
  const instance = await getAxiosTokenInstance();

  let data = {
    ...olddata,
  };

  try {
    const resp = await instance.post(`/college/NAAC/getNAAC_C2_9`, data, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      setLoading(false);
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    setLoading(false);
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

function C2_9({ onClose }) {
  const [values, setValues] = useState();
  const { collegeId } = useContext(FirebaseContext);
  const exportPdfMutation = useMutation(exportPdf);
  const [loading, setLoading] = useState(true);
  const onExportExam = async () => {
    setLoading(true);

    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let olddata = {
        collegeId,
        academicYear: "2022-23",
        needExcel: true,
      };

      link.download = "NAAC File 1_1 " + moment(new Date()).format("DD_MM_YYYY HH MM");
      const pdfData = await exportPdfMutation.mutateAsync({
        olddata,
        setLoading,
      });
      link.href = URL.createObjectURL(
        new Blob([pdfData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      link.click();
      setLoading(false);

      onClose();
    } catch (e) {
      setLoading(false);
    }
  };
  const fetchData = async () => {
    const instance = await getAxiosTokenInstance();
    const { data } = await instance
      .post("/college/NAAC/getNAAC_C2_9", {
        academicYear: "2022-23",
        collegeId,
      })
      .catch((x) => console.log(x));
    console.log(data);
    setLoading(false);
    setValues(data?.list);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]">
          <LoadingAnimation />
        </div>
      ) : null}

      <Main height="80vh" width="80vw" title="ADMISSION LIST WITH CATEGORIES FOR ADMITTED STUDENTS ( 2022-23)">
        <Header>
          <div className="flex">
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <Table width="100%" innerWidth={["20%"]}>
            <Thead>
              <Tr>
                <Th position={0}>Programme</Th>
                <Th position={0}>Admission number</Th>
                <Th position={0}>Name of student</Th>
                <Th position={0}>Admitted category</Th>
                <Th position={0}>Reservation category</Th>
              </Tr>
            </Thead>
            <Tbody height="60vh">
              {values?.map((x, i) => (
                <Tr>
                  <Td position={0} index={i}>
                    1
                  </Td>
                  <Td position={0} index={i}>
                    1
                  </Td>
                  <Td position={0} index={i}>
                    {x?.name}
                  </Td>
                  <Td position={0} index={i}>
                    1
                  </Td>
                  <Td position={0} index={i}>
                    1
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Body>
      </Main>
      {/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        
        <table className="table">
          <tr>
            <th className="w-1/4">Programme</th>
            <th className="w-1/4">Admission number</th>
            <th className="w-1/4">Name of student</th>
            <th className="w-1/4">Admitted category</th>
            <th className="w-1/4">Reservation category</th>
          </tr>
          <tr>
            <th>1</th>
            <th>2</th>
            <th>2</th>
            <th>2</th>
            <th>1</th>
          </tr>
        </table>
      </div> */}
    </>
  );
}

export default C2_9;
