import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { paymentStatus } from "../pages/college/ApiServices";

function PaymentReturn() {
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const RETURNMAC = queryParameters.get("RETURNMAC");
  const hostedCheckoutId = queryParameters.get("hostedCheckoutId");

  const [status, setStatus] = useState<boolean | null>(null);
  const [message, setMessage] = useState<string>("");
  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    async function fetchPaymentStatus() {
      try {
        const result = await paymentStatus(RETURNMAC, hostedCheckoutId);
        setStatus(result.status);
        const messageOutput = result.status ? "SUCCESS" : "FAILURE";
        setMessage(messageOutput);
        setLink(result.pageInitialized);
      } catch (e) {
        setStatus(false);
        setMessage("Sorry, Something went wrong");
      }
    }

    fetchPaymentStatus();
  });

  return (
    <div className="payment-return-container max-w-md mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <h3 className="text-lg font-semibold text-center text-gray-700 mb-4">Payment Status</h3>

      {status === null && (
        <div className="status-message text-center">
          <span className="loading-indicator text-blue-500">Loading...</span>
        </div>
      )}

      {status === true && (
        <div className="status-message row text-center p-4 bg-green-100 rounded-lg">
          <span className="icon-success inline-block bg-green-500 text-white p-2 rounded-full mr-2">
            <i className="fas fa-check-circle"></i>
          </span>
          <span className="text-green-800">{message}</span>
          {link && (
            <a href={link} className="cta-button inline-block bg-green-500 text-white mt-4 px-4 py-2 rounded hover:bg-green-600 transition-colors">
              View Details
            </a>
          )}
        </div>
      )}

      {status === false && (
        <div className="status-message row text-center p-4 bg-red-100 rounded-lg">
          <span className="icon-failure inline-block bg-red-500 text-white p-2 rounded-full mr-2">
            <i className="fas fa-times-circle"></i>
          </span>
          <span className="text-red-800">{message}</span>
          <a href="#" className="cta-button inline-block bg-red-500 text-white mt-4 px-4 py-2 rounded hover:bg-red-600 transition-colors">
            Try Again
          </a>
        </div>
      )}
    </div>
  );
}

export default PaymentReturn;
