import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Input, Select } from "antd";
import { useMutation } from "react-query";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { useAllClasses } from "../../../../queryHooks";
import { Formik } from "formik";

export const exportPdf = async ({ olddata, setLoading }) => {
  const instance = await getAxiosTokenInstance();
  console.log(olddata);
  let data = {
    collegeId: olddata.collegeId,
    classId: olddata.classId,

  }
  console.log(data);

  try {
    const resp = await instance.post(`/college/reportGeneration/idCardCheckList`, data, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      setLoading(false)
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    setLoading(false)
    throw new Error(

      error?.response?.data?.message || error.message || "API Error"
    );
  }
};




export const PhotoCkeck = ({ onClose }) => {


  const { collegeId } = useContext(FirebaseContext);

  const [custom, setCustom] = React.useState(false);
  const [stateData, setStateData] = React.useState({});

  const exportPdfMutation = useMutation(exportPdf);

  const [loading, setLoading] = useState(false);

  const allClasses = useAllClasses({ needAll: false, classOnly: false });

  const onExportExam = async () => {
    setLoading(true)

    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let olddata = {
        collegeId,
        classId: stateData.classId

      };


      link.download = 'Exam Reg Suport';
      const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
      setLoading(false)

      onClose();

    } catch (e) { }
  };

  const { Option } = Select;

  const onTemplateExport = (event) => {
    event?.preventDefault();
    const target = event?.currentTarget;
    const year = target?.acc1?.value
    onExportExam(year);
  };







  const FormField = ({ labelString, type, placeholder, ...props }) => {
    return (
      <div className={classNames.formField}>
        <label>{labelString}</label>
        <Input type={type} placeholder={placeholder} required {...props} />
      </div>
    );
  };
  return (





    <div className={classNames.container}>
      {loading ? <LoadingAnimation dark /> :
        <div className={classNames.modal} >
          <Button className={classNames.closeButton} onClick={onClose}>
            Close
          </Button>


          <div className={classNames.listContainer}>
            <label className={classNames.heading}>Electoral Roll</label>
            <label style={{ fontSize: 30 }}  >
              <input type="checkbox"
                style={{ height: 20, width: 20, marginRight: 10 }}
                defaultChecked={custom}
                onChange={() => setCustom(!custom)}
              />
              Custom
            </label>
            {custom ?
              <div className={classNames.group} style={{ width: 500, height: 100, margin: 10 }}>
                <input
                  type="text"
                  aria-multiline={true}
                  onChange={(e) => setStateData({ ...stateData, year: e.target.value })}
                  value={stateData.year}
                /><label>START</label>
              </div>
              :
              <div style={{ width: 400, height: 100 }}>
                <Formik enableReinitialize>
                  <div className={`${classNames.group} ${classNames.formHalfWidth}`} >
                    <Select
                      showSearch
                      className={classNames.formFullWidth}
                      style={{ overflow: "hidden" }}
                      onChange={(val) => {
                        setStateData({ ...stateData, classId: (allClasses?.data?.find((x, i) => (x.className + i) === val)).classId, className: (allClasses?.data?.find((x, i) => (x.className + i) === val)).className });
                      }}
                    >
                      {allClasses?.data?.map((item, i) => (
                        <Option key={i} value={item.className + i}>
                          {item.className}
                        </Option>
                      ))}
                    </Select>
                    <label>Select Class</label>

                  </div>
                </Formik>
              </div>
            }
            <button className={classNames.button} onClick={() => { onExportExam() }}>
              Electoral Roll PDF
            </button>
          </div>
        </div>}


    </div>
  );
};
