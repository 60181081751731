import React, { useContext } from "react";
import { useQuery } from "react-query";

import MasterTimetable from "../../components/MasterTimetable";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";

const getTimetable = async ({ queryKey }) => {
  // eslint-disable-next-line
  const [_, { collegeId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  let resp;
  if (collegeId)
    try {
      resp = await instance.get("college/timetable/getTmetable", {
        params: { collegeId },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error) {
      throw new Error(error?.response?.data?.message || error.message || "API Error");
    }
  return resp?.data;
};

function TimeTablePage({ onClose }) {
  const { collegeId } = useContext(FirebaseContext);
  const { data, refetch, isLoading } = useQuery(["timetable", { collegeId: collegeId }], getTimetable);

  return (
    <Main height="auto" width="auto">
      <Header>
        <Button type="close" onClick={onClose}>Close</Button>
      </Header>
      <Body>
        <MasterTimetable data={data} refetch={refetch} />
      </Body>
    </Main>
  );
}
export default TimeTablePage;
