import React from "react";

const CommonListItem = ({ name, _id, userName, isHod, userPhoto, progress, onPress }) => {
  const navigateToProfile = (e) => {
    e.stopPropagation(); // Prevents the list item's onClick from being triggered when the profile is clicked
    // Implement navigation logic for web
  };

  return (
    <div className="bg-gray-50 shadow-lg  hover:shadow-xl transition-shadow duration-300 ease-in-out rounded-lg overflow-hidden flex flex-col items-center w-full cursor-pointer" onClick={onPress}>
      <div className="w-full">
        {userPhoto ? (
          <div className="w-full h-56" onClick={navigateToProfile}>
            <img src={userPhoto} alt={userName} className="w-full h-full object-cover" />
          </div>
        ) : (
          <div className="bg-indigo-500 flex justify-center items-center w-full h-56">
            <span className="text-white text-6xl">{userName?.charAt(0) || "N"}</span>
          </div>
        )}
      </div>
      <div className="p-4 w-full text-center">
        <h3 className="text-lg font-semibold text-gray-900 ">{name}</h3>
        {userName ? <p className="text-sm text-gray-600">{userName}</p> : <p className="text-sm text-red-500">{isHod ? "No HOD Assigned!" : "No Tutor Assigned!"}</p>}
        <div className="w-full bg-gray-200 rounded-full h-2.5 mt-3">
          <div className="bg-indigo-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
        </div>
        <span className="text-xs font-medium text-indigo-600">{progress}%</span>
      </div>
    </div>
  );
};

export default CommonListItem;
