import React from 'react'
import './TC.css'
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import Select from "antd/lib/select";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';


const { Option } = Select;


function ApplyTc({ refetch, onClose, setLoading }) {
  const history = useHistory()
  const { collegeId } = useContext(FirebaseContext);
  const [ClsName, setClsName] = useState('')
  const [SemesterfromLeaving, setSemesterfromLeaving] = useState('')
  const [semester, setsemester] = useState(["l Semester", 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester'])
  const [reason, setReason] = useState(['Course Completed', 'Discontinued', 'college Transfer', 'Higher Studies'])
  const [name, setName] = useState({})
  const [number, setnumber] = useState('')
  const [Exams, setExams] = useState(['Not attended', "l Semester", 'll Semester', 'lll Semester', 'lV Semester', 'V Semester', 'Vl Semester', 'Vll Semester', 'Vlll Semester', 'lX Semester', 'X Semester'])
  const [DoLeaving, setDoLeaving] = useState('')
  const [Exam, setExam] = useState('')
  const [Streason, setStreason] = useState('')
  const [Letter, setLetter] = useState('')


  const getClassesList = async ({ queryKey }) => {
    // eslint-disable-next-line
    const [_, { collegeId }] = queryKey;
    const instance = await getAxiosTokenInstance();
    let resp;
    if (collegeId)
      try {
        resp = await instance.get("/college/classes/getClass", {
          params: { collegeId },
        });

        if (resp.data.statusCode === 400) {
          throw new Error(resp.data.message || "API Error");
        }
      } catch (error) {
        throw new Error(
          error?.response?.data?.message || error.message || "API Error"
        );
      }
    return resp?.data;
  };
  const classQuery = useQuery(["classes", { collegeId }], getClassesList);


  const getUserList = async ({ queryKey }) => {
    const [_, { type, collegeId, classId }] = queryKey;
    try {
      const instance = await getAxiosTokenInstance();
      const resp = await instance.get(
        `college/users/get?collegeId=${collegeId}${type === "all" ? "" : `&type=${type}`}${classId ? `&classId=${classId}` : ""}`
      );
      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
      return resp.data;
    } catch (err) {

      throw new Error(err || "API Error");
    }
  };

  const { data: userList } = useQuery(
    [`userListstudent`, { type: 'student', classId: '', collegeId }],
    getUserList
  );






  return (
    <div>
      <h2>Apply for TC</h2>
      <Row >
        <Col>

          <div className='part1'>

            <div> <label>full Name</label><br />
              <Select
                showSearch
                value={name.name}
                id="payee"
                className='inputBox1'
                style={{ overflow: "hidden" }}
                placeholder="Full Name"
                onChange={(val) => {
                  setName({
                    name: (userList.list.find((x, i) => (i + '-' + x.name + '-' + x.admisionNo) === val)).name,
                    userId: (userList.list.find((x, i) => (i + '-' + x.name + '-' + x.admisionNo) === val))._id
                  });
                }}
              >
                {userList?.list?.map((item, i) => (
                  <Option key={i + '-' + item.name + '-' + item.admisionNo} value={i + '-' + item.name + '-' + item.admisionNo} >
                    {item.admisionNo + '-' + item.name}
                  </Option>
                ))}
              </Select></div>


            <div><label htmlFor="">Date of Leaving</label><br />
              <input type="date" className='inputBox' value={DoLeaving} placeholder='Date of Leaving' onChange={(e) => setDoLeaving(e.target.value)} /></div>

            <div><label htmlFor="">Semester from Leaving</label><br />
              <Select className='inputBox1' value={SemesterfromLeaving} onChange={(e) => setSemesterfromLeaving(e)}>

                {semester?.map((classes) => (
                  <Option key={classes} value={classes}>
                    {classes}
                  </Option>
                ))}

              </Select></div>


          </div>
          <div className="part2">
            <div>
              <label >Examination last Appeared</label><br />
              <Select className='inputBox1' value={Exam} onChange={(e) => setExam(e)}>

                {Exams?.map((classes) => (
                  <Option key={classes} value={classes}>
                    {classes}
                  </Option>
                ))}

              </Select>
            </div>

            <label htmlFor="">Reason for Leaving</label><br />
            <Select className='inputBox1' value={Streason} onChange={(e) => setStreason(e)}>

              {reason?.map((classes) => (
                <Option key={classes} value={classes}>
                  {classes}
                </Option>
              ))}

            </Select>
          </div>

        </Col>
        <Col>
          <label>Write a Letter for release TC and CC to the Principal</label><br />
          <input type="text" className='letter' value={Letter} onChange={(e) => setLetter(e.target.value)} /><br />
        </Col>
      </Row>
      <div className='editTc'>
        <button className='submitBtn' style={{ marginTop: 10 }} onClick={async () => {
          setLoading(true)
          const instance = await getAxiosTokenInstance();
          instance.post('/college/requestAprovals/requestForTcCc', {
            collegeId: collegeId,
            userId: name.userId,
            dateOfLeaving: DoLeaving,
            requestLetter: Letter,
            reasonForLeaving: Streason,
            exam: Exam,
            leaving: SemesterfromLeaving
          })
          setName('')
          setClsName('')
          setDoLeaving('')
          setSemesterfromLeaving('')
          setExam('')
          setStreason('')
          setLetter('')
          refetch()
          setLoading(false)
          onClose()
        }}>Submit</button></div>

    </div>
  )
}

export default ApplyTc