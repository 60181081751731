import React, { useEffect, useContext, useState } from "react";
import classNames from "./studentTable.module.scss";
import { Table, Popconfirm, Input } from "antd";
import Scrollbars from "react-custom-scrollbars";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import { useQuery } from "react-query";
import { getPenddingAdmission } from "../../../ApiServices";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import moment from "moment";
import { Checkmark } from "react-checkmark";
import StudentData from "../../StudentData";
import ModalWrapper from "../../../../../components/ModalWrapper";
import GiveWay from "../../GiveWay";
import Verification from "../../Verification";
import CallToken from "../../CallToken";
import UploadImage from "../../UploadImage";
import UploadPhoto from "../../../../../components/UploadPhoto";
import ApplicationView from "../../../../../CollegeApp/Admission/ApplicationView";
import Button from "../../../../../NecttosComp/Button/Button";

function AddStudentTableStudents({
  refreshStatus,
  setSelectedUser,
  selectUser,
  toggleRefresh,
}) {
  const { collegeId, user } = useContext(FirebaseContext);

  let classId = "";

  const { data, refetch, status } = useQuery(
    "getPenddingAdmissions",
    getPenddingAdmission(collegeId, classId, user.uid, "department")
  );
  const [state, setState] = useState({});

  let keyData = "department";

  let userList = data?.list || [];
  let isTeacher = false;

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [collegeId]);

  console.log({ userList });

  const Timer = ({ start, stop, date, style }) => {
    const [startTime, setStartTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
      let interval = null;

      if (isRunning) {
        interval = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isRunning]);

    const handleStart = (date) => {
      setStartTime(new Date(date));
      setCurrentTime(new Date());
      setIsRunning(true);
    };

    const handleStop = () => {
      setIsRunning(false);
      setStartTime(null);
      setCurrentTime(null);
    };

    useEffect(() => {
      if (start) {
        handleStart(date);
      }
    }, [start, date]);

    useEffect(() => {
      if (stop) {
        handleStop();
      }
    }, [stop]);

    const getElapsedTime = () => {
      if (!startTime || !currentTime) {
        return "00:00:00";
      }

      const elapsedMilliseconds = currentTime - startTime;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const hours = Math.floor(elapsedSeconds / 3600);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    return (
      <div>
        <label style={{ ...style }}>{getElapsedTime()}</label>
      </div>
    );
  };

  const [timerStart, setTimerStart] = useState(false);

  const handleStartTimer = () => {
    setTimerStart(true);
  };

  const handleStopTimer = () => {
    setTimerStart(false);
  };

  const [searchTerm, setSearchTerm] = useState("");

  let filteredUserList = userList;

  console.log({ userList });

  if (searchTerm) {
    filteredUserList = userList.filter((user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  let tableColumns = [
    {
      title: "Application",
      render: (_, data) =>
        data?.percentageOfCompletion === 100 ? (
          <div>
            <Checkmark size={"40"} />
          </div>
        ) : (
          (data?.percentageOfCompletion || 0) + "%"
        ),
    },

    {
      title: "Action",
      render: (_, data) => (
        <>
          <Button
            type="doc"
            onClick={() => {
              setState({
                ...state,
                viewMode2: true,
                studentId: data._id,
                type:
                  data?.dsc_a === "CLASS-195C8894984F-4A61-81D6-52D3093A13AF"
                    ? "MCA"
                    : data?.batchName === "2024-2026"
                    ? "PG"
                    : "UG",
              });
            }}
          >
            ViewApp & Edit
          </Button>
        </>
      ),
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
    },

    {
      title: "Action",
      render: (_, data) => (
        <>
          <button
            disabled={data?.percentageOfCompletion === 100}
            onClick={() => {
              setState({
                ...state,
                studentId: data._id,
                uploadImage: true,
                [data?._id + "call"]: true,
                [data?._id + "timeStamp"]: new Date(),
              });
            }}
          >
            <div
              style={{
                cursor: "pointer",
                width: 100,
                height: 50,
                borderRadius: 20,
                padding: 12,
                textAlign: "center",
                backgroundColor:
                  data?.percentageOfCompletion === 100 ? "red" : "green",
              }}
            >
              <div
                style={{
                  alignContent: "center",
                  cursor: "pointer",
                  fontSize: 20,
                  color: "white",
                  fontWeight: 900,
                }}
              >
                U.Photo
              </div>
            </div>
          </button>
        </>
      ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <>
          <button
            disabled={!(data?.percentageOfCompletion === 100)}
            onClick={() => {
              setState({
                ...state,
                studentId: data._id,
                callToken: true,
                [data?._id + "call"]: true,
                [data?._id + "timeStamp"]: new Date(),
              });
            }}
          >
            <div
              style={{
                cursor: "pointer",
                width: 100,
                height: 50,
                borderRadius: 20,
                padding: 12,
                textAlign: "center",
                backgroundColor:
                  !(data?.percentageOfCompletion === 100) ||
                  state[data?._id + "call"]
                    ? "red"
                    : "green",
              }}
            >
              <div
                style={{
                  alignContent: "center",
                  cursor: "pointer",
                  fontSize: 20,
                  color: "white",
                  fontWeight: 900,
                }}
              >
                {state[data?._id + "call"] ? "Called" : "Call"}
              </div>
            </div>
          </button>
        </>
      ),
    },
    {
      title: "Count Down",
      render: (_, data) =>
        state[data._id + "timeStamp"] && (
          <Timer
            start={timerStart}
            style={{
              fontSize: 30,
              fontFamily: "bold",
              color: "red",
              margin: 20,
            }}
            stop={!timerStart}
            date={state[data._id + "timeStamp"]}
          />
        ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          disabled={!state[data._id + "call"]}
          onClick={() => {
            setState({ ...state, viewMode: true, studentId: data._id });
          }}
          style={{
            cursor: "pointer",
            width: 100,
            height: 50,
            borderRadius: 20,
            padding: 10,
            textAlign: "center",
            backgroundColor: state[data._id + "call"] ? "green" : "grey",
          }}
        >
          <div>
            <div
              style={{
                alignContent: "center",
                cursor: "pointer",
                fontSize: 20,
                color: "white",
                fontWeight: 800,
              }}
            >
              View
            </div>
          </div>
        </button>
      ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          disabled={state[data._id + "proceed"] && !state[data._id + "call"]}
          onClick={() => {
            setState({ ...state, giveWay: true, studentId: data._id });
          }}
          style={{
            cursor: "pointer",
            width: 100,
            height: 50,
            borderRadius: 20,
            padding: 10,
            textAlign: "center",
            backgroundColor:
              !state[data._id + "proceed"] && state[data._id + "call"]
                ? "green"
                : "grey",
          }}
        >
          <div>
            <div
              style={{
                alignContent: "center",
                cursor: "pointer",
                fontSize: 20,
                color: "white",
                fontWeight: 800,
              }}
            >
              Give
            </div>
          </div>
        </button>
      ),
    },
    {
      title: "Action",
      render: (_, data) => (
        <button
          disabled={!state[data._id + "proceed"]}
          onClick={() => {
            setState({ ...state, verification: true, studentId: data._id });
          }}
          style={{
            cursor: "pointer",
            width: 190,
            height: 50,
            borderRadius: 20,
            padding: 10,
            textAlign: "center",
            backgroundColor: state[data._id + "proceed"] ? "green" : "grey",
          }}
        >
          <div>
            <div
              style={{
                alignContent: "center",
                cursor: "pointer",
                fontSize: 20,
                color: "white",
                fontWeight: 800,
              }}
            >
              Verify & Finish
            </div>
          </div>
        </button>
      ),
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "number",
      sorter: (a, b) =>
        a?.phoneNumber > b?.phoneNumber
          ? 1
          : b?.phoneNumber > a?.phoneNumber
          ? -1
          : 0,
    },
    {
      title: "Course",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "Index",
      dataIndex: "index",
      key: "number",
    },
    {
      title: "RC",
      dataIndex: "admType",
      key: "admType",
    },
  ];
  return (
    <>
      <div
        className={classNames.tableView}
        style={{ backgroundColor: "white" }}
      >
        <div className={classNames.tableHeader}>
          <h2>Department Verification</h2>
          <Input
            style={{ width: 500, height: 50, margin: 5, marginRight: 200 }}
            placeholder="Search by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {status === "loading" && <LoadingAnimation dark />}
        {status === "success" && (
          <Scrollbars className={classNames.tableWrap}>
            <div className={classNames.userTable}>
              <Table
                columns={tableColumns}
                dataSource={
                  filteredUserList && filteredUserList.length > 0
                    ? filteredUserList
                    : []
                }
                pagination={false}
                // bordered
              />
            </div>
          </Scrollbars>
        )}
      </div>
      <div className="z-30">
        {state?.viewMode2 && (
          <ApplicationView
            uid={state?.studentId}
            viewMode={false}
            params={{ type: state?.type }}
            setSta={setState}
            onClose={() => setState({ ...state, viewMode2: false })}
          />
        )}
      </div>
      {state.viewMode && (
        <ModalWrapper>
          <StudentData
            uid={state?.studentId}
            isStudent={true}
            keyData={keyData}
            TimerFuncion={Timer}
            stateData={state}
            setStateData={setState}
          />
        </ModalWrapper>
      )}
      {state.giveWay && (
        <ModalWrapper>
          <GiveWay
            uid={state?.studentId}
            isStudent={true}
            keyData={keyData}
            TimerFuncion={Timer}
            stateData={state}
            setStateData={setState}
          />
        </ModalWrapper>
      )}
      {state.callToken && (
        <ModalWrapper>
          <CallToken
            uid={state?.studentId}
            isStudent={true}
            keyData={keyData}
            handleStartTimer={handleStartTimer}
            TimerFuncion={Timer}
            stateData={state}
            setStateData={setState}
          />
        </ModalWrapper>
      )}
      {state.uploadImage && (
        <ModalWrapper>
          <UploadPhoto
            onClose={() => setState({ ...state, uploadImage: false })}
            uid={state?.studentId}
            isStudent={true}
            keyData={keyData}
            handleStartTimer={handleStartTimer}
            TimerFuncion={Timer}
            stateData={state}
            setStateData={setState}
          />
        </ModalWrapper>
      )}
      {state.verification && (
        <ModalWrapper>
          <Verification
            uid={state?.studentId}
            isStudent={true}
            keyData={keyData}
            refectData={refetch}
            TimerFuncion={Timer}
            stateData={state}
            setStateData={setState}
          />
        </ModalWrapper>
      )}
    </>
  );
}

export default AddStudentTableStudents;
