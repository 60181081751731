import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";

export const getCollectedFees = (collegeId, skip, limit, search) => async () => {
    const instance = await getAxiosTokenInstance();

    try {
        const resp = await instance.get('/college/fees/getCollectedFees', {
            params: {
                collegeId,
                skip,
                limit,
                search
            }
        });
        if (resp.data.statusCode === 400) {
            throw new Error(resp.data.message || 'API Error');
        } else {

            return resp?.data
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error.message || 'API Error',
        );
    }
}
export const getEditData = (collegeId, userId) => async () => {
    if (userId) {
        const instance = await getAxiosTokenInstance();
        try {
            const resp = await instance.get('/college/action/getEditFees', {
                params: {
                    collegeId, userId
                }
            });
            if (resp.data.statusCode === 400) {
                throw new Error(resp.data.message || 'API Error');
            } else {
                return resp?.data.list || {}
            }
        } catch (error) {
            throw new Error(
                error?.response?.data?.message || error.message || 'API Error',
            );
        }
    }
}
export const getFeeDueList = (collegeId, noNeedTotals, userId, isGrandFeeBilling) => async () => {

    if (userId) {
        const instance = await getAxiosTokenInstance();
        try {
            const resp = await instance.get('/college/fees/getDues', {
                params: {
                    collegeId, noNeedTotals, userId, isGrandFeeBilling
                }
            });

            if (resp.data.statusCode === 400) {
                throw new Error(resp.data.message || 'API Error');
            } else {
                return resp?.data.data || {}

            }
        } catch (error) {
            throw new Error(
                error?.response?.data?.message || error.message || 'API Error',
            );
        }
    }

}

export const collectFee = async ({ collegeId, userId, userName, data, setIsLoading, setAlert }) => {
    const instance = await getAxiosTokenInstance();

    try {
        const resp = await instance.post('/college/fees/collectFee', data, {
            params: { collegeId, userId, userName },
        });

        if (resp?.data?.statusCode === 400) {
            throw new Error(resp?.data?.message || 'API Error');
        } else {
            return resp?.data;
        }
    } catch (error) {
        throw new Error(
            setIsLoading(false),
            setAlert({
                type: 'Guide Line',
                text: error?.response?.data?.message || error?.message || 'API Error',
                show: true
            }),
            error?.response?.data?.message || error?.message || 'API Error',
        );
    }
}

export const refundFee = async ({ collegeId, userId, collectionId, data }) => {


    const instance = await getAxiosTokenInstance();

    try {
        const resp = await instance.post('/college/fees/refundFee', data, {
            params: { collegeId, userId, collectionId },
        });

        if (resp?.data?.statusCode === 400) {
            throw new Error(resp?.data?.message || 'API Error');
        } else {
            return resp?.data;
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error?.message || 'API Error',
        );
    }
}
export const getFastCashTokens = (collegeId) => async () => {
    const instance = await getAxiosTokenInstance();

    try {
        const resp = await instance.get('/college/fastCash/getTokens', {
            params: {
                collegeId
            }
        });

        if (resp.data.statusCode === 400) {
            throw new Error(resp.data.message || 'API Error');
        } else {
            return resp?.data?.data;
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error.message || 'API Error',
        );
    }
}


export const getDayBook = (collegeId, date) => async () => {

    const instance = await getAxiosTokenInstance();

    try {
        const resp = await instance.get('/college/fees/getDayBook', {
            params: {
                collegeId, date

            }
        });

        if (resp.data.statusCode === 400) {
            throw new Error(resp.data.message || 'API Error');
        } else {
            return resp?.data
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error.message || 'API Error',
        );
    }
}


export const getReports = (params) => async () => {
    const instance = await getAxiosTokenInstance();
    try {
        const resp = await instance.get('/college/fees/getReports', { params: params });

        if (resp.data.statusCode === 400) {
            throw new Error(resp.data.message || 'API Error');
        } else {
            return resp?.data
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error.message || 'API Error',
        );
    }
}


export const getCashBook = (collegeId, date, type, subType) => async () => {

    const instance = await getAxiosTokenInstance();

    try {
        const resp = await instance.get('/college/fees/getCashBook', {
            params: {
                collegeId, date, type, subType

            }
        });

        if (resp.data.statusCode === 400) {
            throw new Error(resp.data.message || 'API Error');
        } else {
            return resp?.data
        }
    } catch (error) {
        throw new Error(
            error?.response?.data?.message || error.message || 'API Error',
        );
    }
}