import React from 'react';
import Navbar from '../../components/Website/navbar';
import Slider from '../../components/Website/slider';
import SoftBoxAbout from '../../components/Website/soft-box-manage';
import Services from '../../components/Website/services';
import SoftBoxLeftsideImage from '../../components/Website/soft-box-leftside-image';
import Video from '../../components/Website/video';
import Testimonial from '../../components/Website/testimonial';
import Team from '../../components/Website/team';
import Subscribe from '../../components/Website/subscribe';
import FooterV1 from '../../components/Website/footerBar';

const Home = () => {
    return <div>
        <Navbar />
        <Slider />
        <Services />
        <SoftBoxAbout />
        <SoftBoxLeftsideImage />
        <Video />
        <Testimonial /> 
        <FooterV1 />
    </div>
}

export default Home

