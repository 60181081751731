import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAllPeriodicals, getAllPeriodicalsEntry } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import SearchableImput from "../AddBook/DropDownComponent";
import CustomInput from "../../../../myComp/CustomInput";
import moment from "moment";
import CustomDropDown from "../../../../myComp/CustomDropDown";
import CustomDropDownKey from "../../../../myComp/CustomDropDownKey";

export const PeriodicalsEntry = ({ onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const [state, setState] = useState({});

  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const { data = [] } = useQuery(["getAllPeriodicals", state.bookType], getAllPeriodicals(collegeId, state.bookType));
  const { data: subData = [], refetch } = useQuery(["getAllPeriodicalsEntry", state.bookItem], getAllPeriodicalsEntry(collegeId, state.bookItem));

  const [isLoading, setIsLoading] = useState(false);

  const managePeriodicals = async () => {
    try {
      setIsLoading(true);

      const instance = await getAxiosTokenInstance();
      let resp;
      resp = await instance.post("/college/action/postPeriodicalsEntry", {
        collegeId,
        bookId: state.bookItem,
        bookType: state.bookType,
        bookDetails: state.bookDetails,
        issue: state.issue,
        volume: state.volume,
        year: state.year,
        fromDate: state.fromDate,
        endDate: state.endDate,
        price: state.price,
        date: state.date,
        _id: state?._id,
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setState({
          ...state,
          bookDetails: "",
          price: "",
          issue: "",
          year: "",
          volume: "",
          date: "",
          fromDate: "",
          endDate: "",
          _id: "",
        });
        refetch();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    } finally {
      setIsLoading(false);
    }
  };

  const { Option } = Select;

  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        <div className={classNames.listContainer}>
          {isLoading ? (
            <LoadingAnimation dark />
          ) : (
            <>
              <div className={classNames.list}>
                {state.alert ? <label className={classNames.heading}>{state.alert}</label> : <label className={classNames.heading}>Add New</label>}
                <CustomInput keyData={"Year of Account"} titleData={"Year"} typeData={"text"} stateData={state} setStateData={setState} />
                <CustomDropDown keyData={"bookType"} titleData={"Type of Periodicals"} dropValues={["Journals", "Magazines", "Dailies"]} stateData={state} setStateData={setState} />
                <CustomDropDownKey keyData={"bookItem"} keyObject={"nameOfBook"} keyReturn={"_id"} titleData={"Select Item"} dropValues={data} stateData={state} setStateData={setState} />
                <CustomInput keyData={"date"} titleData={"Received Date"} typeData={"date"} stateData={state} setStateData={setState} />
                <CustomInput keyData={"volume"} titleData={"Volume Number"} typeData={"text"} stateData={state} setStateData={setState} />
                <CustomInput keyData={"issue"} titleData={"Issue"} typeData={"text"} stateData={state} setStateData={setState} />
                <SearchableImput keyData={"bookDetails"} titleData={"Other Details if any"} collection={"bookStocks"} stateData={state} setStateData={setState} />
                <CustomInput keyData={"fromDate"} titleData={"From Date"} typeData={"date"} stateData={state} setStateData={setState} />
                <CustomInput keyData={"endDate"} titleData={"End Date"} typeData={"date"} stateData={state} setStateData={setState} />
                <CustomInput keyData={"price"} titleData={"Price"} typeData={"number"} stateData={state} setStateData={setState} />
                <div style={{ height: "50px", width: "74%", alignSelf: "center" }}>
                  <Button
                    className={classNames.cancelBotton}
                    onClick={() => {
                      setState({
                        ...state,
                        bookDetails: "",
                        bookType: "",
                        bookItem: "",
                        price: "",
                        date: "",
                        issue: "",
                        fromDate: "",
                        endDate: "",
                        volume: "",
                        _id: "",
                      });
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                  <Button
                    className={classNames.submitBotton}
                    onClick={() => {
                      managePeriodicals();
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className={classNames.listContainer2}>
          <Scrollbars style={{ height: "100%" }}>
            <div ref={componentRef} className="printable">
              <img style={{ position: "absolute", width: "80%", marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
              {state.bookItem ? (
                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
                  {console.log({ state })}
                  {state.bookItemval && <h5 style={{ margin: 0, fontSize: "30px", width: "100%", alignSelf: "center" }}>'Periodicals Management'</h5>}
                  <h5 style={{ margin: 0, fontSize: "30px", width: "100%", alignSelf: "center" }}>{state.bookItemval.slice(0, 5).replace(/[0-9]/g, "") + state.bookItemval.slice(5) || "Periodicals Management"}</h5>
                  <div style={{ width: "100%", display: "flex", paddingRight: 12, flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 12, width: "5%" }}>Sn</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 12, width: "5%" }}>Year</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "50%" }}>Date of Received</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Issue</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Volume</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Details</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>From Date</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>End Date</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "10%" }}>Price</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "8%" }}></h5>
                  </div>
                  {subData?.map((item, i) => (
                    <>
                      <div style={{ width: "100%", paddingRight: 12, display: "flex", minHeight: "50px", flexDirection: "row", alignItems: "center", alignSelf: "center" }}>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 12, width: "5%" }}>{i + 1}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 12, width: "5%" }}>{item?.year}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "50%" }}>{moment(item.date).format("dddd,  DD MMMM YYYY")}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "20%" }}>{item.issue}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "20%" }}>{item.volume}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "20%" }}>{item.bookDetails}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "20%" }}>{item.fromDate && moment(item.fromDate).format("dddd,  DD MMMM YYYY")}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "20%" }}>{item.endDate && moment(item.endDate).format("dddd,  DD MMMM YYYY")}</h5>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "10%" }}>{item.price}</h5>
                        <Button
                          className={classNames.submitBotton}
                          onClick={() => {
                            setState({
                              ...state,
                              _id: item?._id || "",
                              bookDetails: item.bookDetails,
                              bookType: item.bookType,
                              bookItem: item.bookId,
                              date: moment(item.date).format("YYYY-MM-DD"),
                              price: item.price,
                              issue: item.issue,
                              volume: item.volume,
                              fromDate: moment(item.fromDate).format("YYYY-MM-DD"),
                              endDate: moment(item.endDate).format("YYYY-MM-DD"),
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          height: 1,
                          backgroundColor: "black",
                        }}
                      ></div>
                    </>
                  ))}
                </div>
              ) : (
                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderRadius: "25px", padding: "1%" }}>
                  <h5 style={{ margin: 0, fontSize: "30px", width: "100%", alignSelf: "center" }}>Periodicals Management</h5>
                  <div style={{ width: "100%", display: "flex", paddingRight: 12, flexDirection: "row", height: 30, margin: 0, backgroundColor: "black", alignItems: "center", alignSelf: "center", marginBottom: 12 }}>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "center", fontSize: 12, width: "5%" }}>Sn</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Year</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Title</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Periodicals</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Price</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Vendor</h5>
                    <h5 style={{ marginTop: 6, color: "white", textAlign: "left", fontSize: 12, width: "55%" }}>V Mobile</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Type</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Subject</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Language</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Archives</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Postal Address</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>DD Address</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>ISSN Number</h5>
                    <h5 style={{ marginTop: 6, color: "white", alignSelf: "left", fontSize: 12, width: "20%" }}>Book Details</h5>
                  </div>
                  {data?.map((item, i) => (
                    <>
                      <div style={{ width: "100%", paddingRight: 12, display: "flex", minHeight: "50px", flexDirection: "row", alignItems: "center", alignSelf: "center" }}>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 12, width: "5%" }}>{i + 1}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "center", fontSize: 12, width: "20%" }}>{item?.year}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.nameOfBook}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.piriodicity}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.price}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.vendor}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.vendorMobile}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.bookType}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.subject}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.language}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.arcgives}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.postalAddress}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.ddAddress}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.issnNumber}</h5>
                        <div style={{ height: "100%", display: "flex", margin: "5px", width: "1px", backgroundColor: "black" }}></div>
                        <h5 style={{ marginRight: 4, color: "black", textAlign: "left", fontSize: 12, width: "55%" }}>{item.bookDetails}</h5>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          height: 1,
                          backgroundColor: "black",
                        }}
                      ></div>
                    </>
                  ))}
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>

      <Button
        className={classNames.reloadButton}
        onClick={() => {
          refetch();
        }}
      >
        Reload
      </Button>

      <ReactToPrint trigger={() => <button className={classNames.printBotton}>Print</button>} content={() => componentRef.current} />

      {!isLoading && (
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      )}
    </div>
  );
};
