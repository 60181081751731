import React, { useEffect, useState } from "react";
import { EditDetails, getDropdownData } from "../../pages/college/ApiServices";
import Button from "../../NecttosComp/Button/Button";
import { Body, Footer, Header, Main, widthChangeAnimation } from "../../NecttosComp/Layout/Layout";
import Input from "../../NecttosComp/Input/Input";
import { useProfileData } from "../../queryHooks";
import { ProgressBar, calculateProfilepPercentage } from "../../NecttosComp/ProgressBar/ProgressBar";
import Loader from "../../NecttosComp/Loader/Loader";
import { ProfileDataDisplayKeyVlaue } from "../../FunctionalExport/Formate";

interface Props {
  onClose: () => void;
  studentId?: string | null | undefined;
  userRole?: string;
}

function PersonalInfo({ onClose, studentId }: Props) {
  const [dropDowns, setDropDowns] = useState<any[]>([{}]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: profileData, refetch } = useProfileData();

  const [values, setValues] = useState({});

  useEffect(() => {
    setValues({
      name: profileData.pendingData?.name || profileData.name || "",
      dob: profileData.pendingData?.dob || profileData.dob || "",
      gender: profileData.pendingData?.gender || profileData.gender || "",
      annualIncome: profileData.pendingData?.annualIncome || profileData.annualIncome || "",
      provertyLine: profileData.pendingData?.provertyLine || profileData.provertyLine || "",
      disabilities: profileData.pendingData?.disabilities || profileData.disabilities || "",
      meritalStatus: profileData.pendingData?.meritalStatus || profileData.meritalStatus || "",
      email: profileData.pendingData?.email || profileData.email || "",
      bloodGroup: profileData.pendingData?.bloodGroup || profileData.bloodGroup || "",
      aadhaarNumber: profileData.pendingData?.aadhaarNumber || profileData.aadhaarNumber || "",
      category: profileData.pendingData?.category || profileData.category || "",
      caste: profileData.pendingData?.caste || profileData.caste || "",
      religion: profileData.pendingData?.religion || profileData.religion || "",
    });
  }, [profileData]);

  const [categoryData, setCategoryData] = useState([]);
  const [casteData, setCasteData] = useState([]);
  const [selectedReligion, setSelectedReligion] = useState("");

  const onReligionSelect = ({ value: item }: { value: string }) => {
    setSelectedReligion(item);
    setCasteData([]);
    setCategoryData(dropDowns[2].RELIGION[0][item + "RESERVATIONCATEGORY"]);
    return setValues({ ...values, religion: item, category: "", caste: "" });
  };

  const onCategorySelect = ({ value: item }: { value: string }) => {
    setCasteData(dropDowns[2]?.RELIGION?.[0]?.[selectedReligion + "CAST"]?.[0]?.[item]);
    return setValues({ ...values, category: item, caste: "" });
  };

  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
    };
    getDropDowns();
  }, []);

  const [placeHoldValues, setPlaceHolderValues] = useState<{}>({});
  let userRole: string = "";
  type CollegeRole = {
    type: string;
  };
  const rolesArray: string[] = ["student", "teacher", "staff", "admin"];
  userRole = rolesArray.find((element) => profileData.collegeRoles.some((x: CollegeRole) => x.type === element)) || userRole;
  const staffKeys = {
    name: "Name",
    dob: "Date of Birth",
    gender: "Gender",
    bloodGroup: "Blood Group",
    disabilities: "Disabilities",
    meritalStatus: "Merital Status",
    email: "Email",
    aadhaarNumber: "Aadhaar Number",
    religion: "Religion",
    category: "Category",
    caste: "Caste",
  };

  const studentsKeys = {
    name: "Name",
    dob: "Date of Birth",
    gender: "Gender",
    bloodGroup: "Blood Group",
    disabilities: "Disabilities",
    meritalStatus: "Merital Status",
    provertyLine: "Proverty Line",
    annualIncome: "Annual Income",
    email: "Email",
    aadhaarNumber: "Aadhaar Number",
    religion: "Religion",
    category: "Category",
    caste: "Caste",
  };

  const teacherKeys = {
    name: "Name",
    dob: "Date of Birth",
    gender: "Gender",
    bloodGroup: "Blood Group",
    disabilities: "Disabilities",
    meritalStatus: "Merital Status",
    email: "Email",
    aadhaarNumber: "Aadhaar Number",
    religion: "Religion",
    category: "Category",
    caste: "Caste",
  };

  useEffect(() => {
    if (userRole === "student") {
      setPlaceHolderValues(studentsKeys);
    } else if (userRole === "teacher") {
      setPlaceHolderValues(teacherKeys);
    } else if (userRole === "staff") {
      setPlaceHolderValues(staffKeys);
    } else {
      setPlaceHolderValues(staffKeys);
    }
  }, [userRole]);

  return (
    <>
      <Main title="Personal Information" height="90vh" width={"60vw"}>
        <Header width="100%" height="6%" title="Profile">
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="print" children="Print" />
            <Button type="pdf" pdfId="accounts" children="PDF" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body width="100%" height="85%">
          <div className="grid grid-flow-col gap-6">
            <div className="px-3 py-2 w-[28vw]  ">
              <ProgressBar title="Verified Data" progress={parseInt(calculateProfilepPercentage(profileData, placeHoldValues)?.percentage) || 0} />

              <ProfileDataDisplayKeyVlaue temp={profileData} placeHoldValues={placeHoldValues} title="Verified Data" />

              {!isEditing ? (
                <Button
                  onClick={async () => {
                    widthChangeAnimation("90vw");
                    setIsEditing(!isEditing);
                  }}
                  type="update"
                >
                  Edit
                </Button>
              ) : null}
            </div>
            <div>
              <ProgressBar title="are Updated" progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
              <ProfileDataDisplayKeyVlaue temp={profileData?.pendingData} placeHoldValues={placeHoldValues} title="Updated data" needVerification={true} />
            </div>

            {isEditing && (
              <div>
                <h5 className="text-blue-900 text-[1.6vw] font-bold">You can edit your data here !</h5>
                <div id="scrollBarEnable" className="px-3 py-2 w-[28vw] z-50 overflow-auto  max-h-[65vh]">
                  <Input width="98%" fieldName="Name" returnKey="name" setState={setValues} state={values} type="text" />
                  <Input width="98%" maxLength={12} fieldName="Aadhaar Number" returnKey="aadhaarNumber" setState={setValues} state={values} type="text" />
                  <Input width="98%" fieldName="Gender" returnKey="gender" options={dropDowns[0]?.gender} setState={setValues} state={values} type="drop" />
                  <Input width="98%" fieldName="Date of Birth" returnKey="dob" setState={setValues} state={values} type="date" />
                  <div className="relative z-[7]">
                    <Input width="98%" fieldName="Blood Group" returnKey="bloodGroup" options={dropDowns[1]?.bloodGroup} setState={setValues} state={values} type="drop" />
                  </div>
                  <div className="relative z-[6]">
                    <Input width="98%" fieldName="Disabilities" returnKey="disabilities" options={["Yes", "No"]} setState={setValues} state={values} type="drop" />
                  </div>
                  <div className="relative z-[5]">
                    <Input width="98%" fieldName="Merital Status" returnKey="meritalStatus" options={dropDowns[9]?.meritalStatus} setState={setValues} state={values} type="drop" />
                  </div>
                  {userRole === "student" && (
                    <>
                      <div className="relative z-[4]">
                        <Input width="98%" fieldName="Proverty Line" returnKey="provertyLine" options={dropDowns[13]?.provertyLine} setState={setValues} state={values} type="drop" />
                      </div>
                      <div className="relative z-[3]">
                        <Input width="98%" fieldName="Annual Income" returnKey="annualIncome" options={dropDowns[12]?.income} setState={setValues} state={values} type="drop" />
                      </div>
                    </>
                  )}
                  <Input width="98%" fieldName="Email" returnKey="email" setState={setValues} state={values} type="email" />
                  <div className="relative z-[2]">
                    <Input width="98%" onChange={onReligionSelect} fieldName="Religion" returnKey="religion" options={dropDowns[2].RELIGION[0].RELIGIONNAME} setState={setValues} state={values} type="drop" />
                  </div>
                  <div className="relative z-[1]">
                    <Input width="98%" onChange={onCategorySelect} fieldName="Category" returnKey="category" options={categoryData} setState={setValues} state={values} type="drop" />{" "}
                  </div>
                  <div className=" relative z-[0]">
                    <Input width="98%" fieldName="Caste" returnKey="caste" options={casteData} setState={setValues} state={values} type="drop" />{" "}
                  </div>
                </div>

                <ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />

                <Button
                  width="100%"
                  onClick={async () => {
                    setIsLoading(true);
                    await widthChangeAnimation("60vw");
                    await EditDetails(values);
                    await refetch();
                    setIsEditing(!isEditing);
                    setIsLoading(false);
                  }}
                  type="save"
                  children="Save"
                />
              </div>
            )}
          </div>
        </Body>
        <Footer width="100%" />
      </Main>
      {isLoading && <Loader />}
    </>
  );
}

export default PersonalInfo;
