import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import ReactToPrint from "react-to-print";
import CustomDropDown from "../../../../myComp/CustomDropDown";
import { SubsidiaryRegisterMonths } from "./SubsidiaryRegisterMonths";

const getAccounts = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/fees/getSubsidiaryRegister", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const SubsidiaryRegister = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const [newStartDate, setnewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [state, setState] = React.useState({
    filterValue: "",
    page: 0,
  });
  let dataToFinancial = state?.year?.split("-");
  let startDate = dataToFinancial?.[0] + "-06-01";
  let endDate = dataToFinancial?.[1] + "-05-31";
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const { data, refetch: issuedBookRefetch } = useQuery(["pdReg777ister", state?.year], getAccounts({ collegeId, formate: "MMMM-YYYY", startDate:newStartDate?newStartDate:startDate, endDate:newEndDate?newEndDate:endDate, setLoading }));
  const { Option } = Select;




const handleChange=(e)=>{
const {name,value}=e.target
if(name==="startDate"){
  setnewStartDate(value)
}else{
  setNewEndDate(value)
}
}

  return (
		<div className={classNames.container}>
			<div className={classNames.modal}>
				<div className={classNames.listContainer2}>
					<Scrollbars style={{ height: '100%' }}>
						<div ref={componentRef} className='printable' style={{ overflow: 'scroll' }}>
							<img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: 0.12, zIndex: -1 }} src={selectedCollege?.logo} />
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px' }}>
									<img width='8%' height='8%' src={selectedCollege?.logo} />
									<div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
										<span style={{ fontSize: '30px', margin: 0, fontWeight: 900 }}>{selectedCollege?.collegeShortName}</span>
										<h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Subsidiary Register </h5>
									</div>
								</div>
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 25, marginTop: 4, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}>SN</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '22%' }}>Date</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '5%' }}>C</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '16%' }}>Rt.No.</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee1}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee2}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee3}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee4}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee5}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee6}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee7}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee8}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee9}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee10}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee11}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee12}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.specialFee13}</h5>
									<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>TOTAL</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>PAY</h5>
								</div>
								{data?.subsidiaryRegisterData?.map((item, i) => (
									<>
										<div
											onClick={() => {
												setState({ ...state, openModal: true, selectedDate: item.dateActual });
											}}
											style={{ width: '100%', display: 'flex', height: ' 50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '5%' }}>{i + 1}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 16, width: '22%' }}>{item?.date}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '5%' }}>{item?.countData}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, fontStyle: 'arial', width: '16%' }}>{item?.recieptNumber}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, fontStyle: 'times', width: '8%' }}>{item?.specialFee1}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee2}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee3}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee4}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee5}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee6}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee7}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee8}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee9}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee10}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee11}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee12}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.specialFee13}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item.totalAmount}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
											<h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item.paymentAmount}</h5>
											<div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
										</div>
										<div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'black' }}></div>
									</>
								))}
								<div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '5%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 14, width: '16%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '5%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'left', fontSize: 16, width: '22%' }}></h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal1}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal2}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal3}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal4}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal5}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal6}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal7}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal8}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal9}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal10}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal11}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal12}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.specialFeeMainTotal13}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.grandTotal}</h5>
									<h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>{data?.totals?.grandPayment}</h5>
								</div>
								<h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 16 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
							</div>
						</div>
					</Scrollbars>
				</div>
				{state.openModal && <SubsidiaryRegisterMonths date={state?.selectedDate} onClose={() => setState({ ...state, openModal: false })} />}
			</div>
			<div className={classNames.absoluteDiv}>
				<div>
					<label className='p-0 m-0 leading-3 text-white' htmlFor=''>
						Start Date
					</label>
					<input type='date' name='startDate' value={newStartDate} onChange={handleChange} className='w-auto h-10 mt-0 rounded' />
				</div>
				<div>
					<label className='p-0 m-0 leading-3 text-white' htmlFor=''>
						End Date
					</label>
          <input type="date" name="endDate" value={newEndDate} onChange={handleChange} className="w-auto h-10 mt-0 rounded"/>
				</div>
				<div style={{ width: 200 }}>
					<CustomDropDown keyData={'year'} titleData={'Financial Year'} dropValues={['2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026']} stateData={state} setStateData={setState} />
				</div>
				{loading ? (
					<LoadingAnimation customHeight={50} customWidth={50} />
				) : (
					<>
						<Button
							className={classNames.reloadButton}
							onClick={() => {
								issuedBookRefetch();
							}}>
							Load
						</Button>
					</>
				)}
				<ReactToPrint trigger={() => <button className={classNames.reloadButton}>Print</button>} content={() => componentRef.current} />
			</div>
			<Button className={classNames.closeButton} onClick={onClose}>
				Close
			</Button>
		</div>
	);
};
