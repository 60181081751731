import React, { useContext, useState } from "react";
import classNames from "./addFeesForm.module.scss";
import Select from "antd/lib/select";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery, } from "react-query";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useAccountsHeads, useAllClasses } from "../../../../queryHooks";
import { Button } from "antd";
import CustomDropDownKey from "../../../../myComp/CustomDropDownKey";
import CustomInput from "../../../../myComp/CustomInput";
import { getFeeStructure, getStudentsWithDues } from "../../ApiServices";
import moment from "moment";
import Scrollbars from "react-custom-scrollbars";
import { v4 as uuidv4 } from 'uuid';

const createRadomId = (prefix = '') => {
  const uuid = uuidv4().toUpperCase();
  const parsedUuid = uuid.replace('-', '');

  return `${prefix ? `${prefix}-` : ''}${parsedUuid}`;
};






const { Option } = Select;

const feesTypess = [
  { name: "Goverment Fees", key: "TuitionFee" },
  { name: "Examination Fees", key: "ExamFee" },
  { name: "Other Fees", key: "Others" },
];
const years = [
  { name: "First Year", key: "1y" },
  { name: "Second Year", key: "2y" },
  { name: "Third Year", key: "3y" },
  { name: "Year not Specified", key: undefined },
];



function sumWithKey(data, key) {
  return data?.reduce((x, y) => x + y[key], 0);
}


function EDLBilling({ onClose,classData, userId, loadFee }) {
  const { collegeId } = useContext(FirebaseContext);
  const allClasses = useAllClasses({ needAll: false, classOnly: false });
  const classList = allClasses?.data || []
  const accounts = useAccountsHeads();
  const mapFeeAcounts = accounts?.data?.mainAccounts || []
  const [isLoading, setIsLoading] = useState(false);


  const [stateConst, setStateConst] = useState({ list: { dues: [] } })
  const [students, setStudents] = useState([])

  const [state, setState] = useState({
    _id: '',
    ...loadFee && { payee: classData }
  });


  

  let { data } = useQuery(['getFeeStructure', state?.payee?.classId, classData?.classId], getFeeStructure(collegeId, (state?.payee?.classId)), { keepPreviousData: true })
  let { data: data2 } = useQuery(['getFeeStructure2', state?.payeeCopy?.classId], getFeeStructure(collegeId, state?.payeeCopy?.classId), { keepPreviousData: true })

  let feeDataStructure = [
    {
      title: 'First Year',
      value: stateConst?.list?.dues?.filter(x => x.year === '1y'),
      sum: sumWithKey(stateConst?.list?.dues?.filter(x => x.year === '1y'), 'amount')
    },
    {
      title: 'Second Year',
      value: stateConst?.list?.dues?.filter(x => x.year === '2y'),
      sum: sumWithKey(stateConst?.list?.dues?.filter(x => x.year === '2y'), 'amount')
    },
    {
      title: 'Third Year',
      value: stateConst?.list?.dues?.filter(x => x.year === '3y'),
      sum: sumWithKey(stateConst?.list?.dues?.filter(x => x.year === '3y'), 'amount')
    },
    {
      title: 'Year not Specified',
      value: stateConst?.list?.dues?.filter(x => x.year === undefined),
      sum: sumWithKey(stateConst?.list?.dues?.filter(x => x.year === undefined), 'amount')
    }
  ]












  const proceedBill = async () => {
    setStudents([])
    setIsLoading(true);

    const instance = await getAxiosTokenInstance();

    try {
      const resp = await instance.get('/college/action/getStudentsWithDues', {
        params: {
          collegeId,
          admisionNo: state.admisionNo,
          year: state.feesYear,
          main1: state.main1,
          main2: state.main2,
          main3: state.main3,
        }
      });
      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || 'API Error');
      } else {
        setStudents(resp?.data?.list)
      }


    } catch (error) {
      setIsLoading(true);

      throw new Error(
        error?.response?.data?.message || error.message || 'API Error',
      );
    }
  }
  console.log(state);


  const deleteItems = async (_id) => {

    stateConst.list.dues.forEach((x, i) => {
      if (x._id === _id) {
        stateConst.list.dues.splice(i, 1);
      }
    })
    setStateConst({ ...stateConst })
  }


  const onAddFees = async () => {
    try {
      setIsLoading(true);

      let filterData = []

      years.forEach(item => {


        if (state[item.name]) {
          filterData.push(item.key)
        }
      })


      console.log({ filterData });

      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/action/postFeeStructure", {


        ...loadFee ? { dues: stateConst?.list?.dues?.filter(x => filterData.includes(x.year)) } : { dues: stateConst?.list?.dues },
        collegeId,
        loadFee,
        userId,
        classId: state.payee.classId
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setState({
          ...state,
          feesType: "",
          titleOfFee: "",
          description: "",
          dueId: "",
          isStudent: true,
          payee: "",
          amount: "",
          dueDate: "",
          date: "",
          recieptNo: "",
          _id: ''
        })
        onClose()
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <div className={classNames.addFeeForm}>
          <view style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <view style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <view style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                < CustomInput
                  keyData={'date'}
                  titleData={'Due Date'}
                  typeData={'date'}
                  stateData={state}
                  setStateData={setState}
                />
                < CustomInput
                  keyData={'recieptNo'}
                  titleData={'Reciept No'}
                  typeData={'text'}
                  stateData={state}
                  setStateData={setState}
                />
                <view style={{ width: 350 }}>
                  <CustomDropDownKey
                    keyData={'feesYear'}
                    keyObject={'name'}
                    keyReturn={'key'}
                    titleData={'Select Fees Year'}
                    dropValues={years}
                    stateData={state}
                    setStateData={setState}
                  />
                </view>
                <view style={{ width: 350 }}>
                  <CustomDropDownKey
                    keyData={'main1'}
                    keyObject={'label'}
                    keyReturn={'value'}
                    titleData={'Select Account 1'}
                    dropValues={mapFeeAcounts}
                    stateData={state}
                    setStateData={setState}
                  />
                </view>
                <view style={{ width: 350 }}>
                  <CustomDropDownKey
                    keyData={'main2'}
                    keyObject={'label'}
                    keyReturn={'value'}
                    titleData={'Select Account 2'}
                    dropValues={mapFeeAcounts}
                    stateData={state}
                    setStateData={setState}
                  />
                </view>
                <view style={{ width: 350 }}>
                  <CustomDropDownKey
                    keyData={'main3'}
                    keyObject={'label'}
                    keyReturn={'value'}
                    titleData={'Select Account 3'}
                    dropValues={mapFeeAcounts}
                    stateData={state}
                    setStateData={setState}
                  />
                </view>
              </view>
              <view style={{ width: 1750 }}>
                < CustomInput
                  keyData={'admisionNo'}
                  titleData={'Admision Numbers'}
                  typeData={'text'}
                  stateData={state}
                  setStateData={setState}
                />
              </view>
            </view>
            <div
              className={classNames.btnClear}
              onClick={() => {

                proceedBill()

              }}
            >
              Proceed
            </div>
          </view>


          <div >Fees Details</div>
          <Scrollbars >
            <view style={{ display: 'flex', flexDirection: 'row', margin: 0, alignSelf: 'center', margin: 12, marginTop:'20' }}>

              {feeDataStructure.map((item) => (

                <div style={{ width: 400, margin: 12 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', }}>

                    <label style={{ fontSize: 20, margin: 12 }}  >
                      <input type="checkbox"
                        style={{ height: 20, width: 20 }}
                        defaultChecked={state[item.title]}
                        onChange={() => { setState({ ...state, [item.title]: !state[item.title] }) }}
                      />
                    </label>
                    <div style={{ fontSize: 20, color: 'green', margin: 8 }}>{item.title + ' - ' + item?.sum}</div>
                  </div>

                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                    <h5 style={{ marginTop: 6, marginLeft: 16, color: 'white', textAlign: 'left', fontSize: 11, width: '10%', }}>SN</h5>
                    <h5 style={{ marginTop: 6, marginLeft: 16, color: 'white', textAlign: 'left', fontSize: 11, width: '30%', }}>Heads</h5>
                    <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 11, width: '40%' }}>Title</h5>
                    <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 11, width: '20%' }}>Amount</h5>
                    <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 11, width: '20%' }}>Due Date</h5>
                  </div>


                  {item?.value?.map((item, i) => (
                    <>
                      <div
                        style={{ cursor: 'pointer', marginTop: 6, width: '100%', display: 'flex', height: ' 40px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center', backgroundColor: item.elective === 'Discountined' ? '#ff8f87' : '' }}>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '10%', }}>{i + 1}</h5>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 15, width: '30%', }}>{item.feeType}</h5>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 10, width: '40%', }}>{item.title}</h5>
                        <div
                          className={classNames.delete}
                          onClick={() => {

                            deleteItems(item._id)

                          }}
                        >
                          D
                        </div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'right', fontSize: 20, width: '20%' }}>{item.amount}</h5>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'right', fontSize: 10, width: '30%' }}>{moment(item.dueDate).format('DD-MM-YY')}</h5>
                        <div
                          className={classNames.delete}
                          onClick={() => {
                            setState({
                              ...state,
                              feesType: item.feeType,
                              feesTypeval: item.feeType,
                              dueId: item.accountId,
                              _id: item._id,
                              feesYear: item.year,
                              feesYearval: item.year,
                              dueIdval: item.title,
                              amount: item.amount,

                              date: moment(item.dueDate).format('YYYY-MM-DD'),
                            });

                          }}
                        >
                          E
                        </div>

                      </div>
                      <div style={{
                        width: '100%',
                        display: 'flex',
                        height: 1,
                        backgroundColor: 'black',
                      }}></div>
                    </>
                  ))}
                </div>
              ))}
            </view>
          </Scrollbars>
          {isLoading && (
            <div className={classNames.loading}>
              <LoadingAnimation />
            </div>
          )}

        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
        <Button className={classNames.closeButton2} onClick={() => {
          onAddFees()
        }}>
          FInal Submit
        </Button>
      </div >





    </div >
  );
}
export default EDLBilling;
