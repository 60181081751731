
import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Checkbox, Form } from 'antd';
import FeesCategories from '../FeesCategories';
import classNames from "./studentFeeDetails.module.scss";
import CollectFee from '../CollectFee';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { useMutation } from 'react-query';
import { refundFee } from '../../feesManagementServices';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { BillNumberModal } from '../BillNumberModal';
import { Field, Formik } from 'formik';

export default function RefundFeeDetails({ data, handleClickbackButton, ...props }) {

    const { collegeId } = useContext(FirebaseContext);
    const [online, setOnline] = React.useState(false);
    const [recievedBank, setRecievedBank] = useState(null);
    const [unbill, setUnbill] = React.useState(false);
    const [feeSummary, setFeeSummary] = useState(false);
    const [billNumberModal, setBillNumberModal] = useState(false)
    const [successData, setSuccessData] = useState({})
    const [arrayOfItems, setArrayOfItems] = useState({})
    const mutation = useMutation(refundFee);
    const [refundData, setRefundData] = useState();







    const backButtonClick = () => {
        if (feeSummary) {
            setFeeSummary(false);

        } else {
            handleClickbackButton()
        }
    }

    const nextButtonClick = () => {
        let arrayOfSelected = []

        if (refundData) {
            let lengthOfSelectedFees = Object.values(refundData)
            lengthOfSelectedFees?.forEach(key => {
                data?.dues?.forEach(du => {
                    if (key.amount > 0) {

                        if (du.dueId === key.dueId) {
                            let amount = 0
                            if (du.amount < key.amount) {
                                amount = du.amount
                            } else {
                                amount = key.amount
                            }

                            arrayOfSelected.push({
                                title: du.title,
                                dueId: du.dueId,
                                amount
                            })
                        }
                    }

                })
            })
        }
        if (arrayOfSelected.length > 0) {
            setFeeSummary(true)
            setArrayOfItems(arrayOfSelected)
        }
    }


    const onCollectFee = async () => {

        try {
            if (arrayOfItems.length) {
                let newdata = await mutation.mutateAsync({ collegeId, userId: data.userId, collectionId: data.collectionId, data: arrayOfItems });
                setSuccessData({
                    billNumber: newdata?.billNumber,
                    userName: data?.userName,
                    className: data?.className,
                    photoURL: data?.photoURL,
                })
                setBillNumberModal(true)
            }
        } catch (e) { }
    }



    return (
        <div className={online ? classNames.studentFeeDetails2 : classNames.studentFeeDetails}>
            {mutation.isLoading && <LoadingAnimation dark />}
            {!billNumberModal && <div className={classNames.container}>
                <div className={classNames.titleContainer}>
                    <div className={classNames.avatar}>
                        <img src={data?.photoURL} className={classNames.dp} />
                    </div>
                    <div className={classNames.typoContainer}>
                        <h1>{data?.userName}</h1>
                        <p>{data?.className}</p>
                    </div>
                </div>
                <div className={classNames.section}>
                    <div className={classNames.feeCategories}>
                    </div>
                    {feeSummary && <div className={classNames.collectFeeModule}>
                        <div className={classNames.list}>
                            <Formik
                                enableReinitialize
                                initialValues={{}}
                            >
                                {({ resetForm }) => (
                                    <Form >
                                        <div className={classNames.subList}>
                                            {arrayOfItems?.map((item, x) => (
                                                <div className={classNames.optionBox}>
                                                    <label>{item.title}</label>
                                                    <div className={classNames.optionBox}>
                                                        <label>{item.amount}</label>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>

                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    }
                    {!feeSummary &&
                        <div className={classNames.collectFeeModule}>
                            <div className={classNames.list}>
                                <Formik
                                    enableReinitialize
                                    initialValues={{}}
                                >
                                    {({ resetForm }) => (
                                        <Form >
                                            <div className={classNames.subList}>
                                                {data?.dues?.map((item, x) => (
                                                    <div className={classNames.optionBox}>
                                                        <label>{item.title}</label>
                                                        <Field
                                                            type="text"
                                                            name={x}
                                                            placeholder={item?.amount}
                                                            value={refundData && refundData[x]?.amount}
                                                            id={x}
                                                            onChange={(e) => {
                                                                setRefundData({ ...refundData, [x]: { amount: e.target.value, dueId: item.dueId, title: x } })
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    }
                    <div className={classNames.submitButtonsContainer}>
                        <button className={classNames.backButton} onClick={backButtonClick}>Back</button>

                        {feeSummary ?
                            (<button className={classNames.printButton} onClick={onCollectFee}>Refund</button>) :
                            <button className={classNames.printButton} onClick={nextButtonClick}>Next</button>}
                        <label>
                            <input type="checkbox"
                                style={{ height: 40, width: 40 }}
                                defaultChecked={unbill}
                                onChange={() => setUnbill(!unbill)}
                            />
                        </label>
                        <label style={{ fontSize: 30 }}  >
                            <input type="checkbox"
                                style={{ height: 40, width: 40 }}
                                defaultChecked={online}
                                onChange={() => setOnline(!online)}
                            />
                            Online
                        </label>
                    </div>
                    {online && <div className={classNames.section} style={{ marginLeft: 30 }}  >
                        <label style={{ fontSize: 30 }} >{'Recieved Bank (Optional)'}</label>
                        <input
                            type="text"
                            style={{ height: 40, width: 400, fontSize: 30, fontWeight: 600 }}

                            value={recievedBank}
                            onChange={(e) => {
                                setRecievedBank(e.target.value)
                            }}
                        />
                    </div>}
                </div>


            </div>}

            {
                billNumberModal && (
                    <BillNumberModal
                        data={successData}
                        onClose={() => { setBillNumberModal(false); handleClickbackButton() }}
                    />
                )
            }


        </div >
    )
}
