import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL + '/'
        let imgattr = 'logo'
        let anchor = '#'
        return (
            <div className='text-black'>
                <nav className="navbar navbar-area navbar-expand-lg nav-style-01 text-black">
                    <div className="container nav-container">
                        <div className="responsive-mobile-menu">
                            <div className="logo-wrapper mobile-logo">
                                <a href={anchor} className="logo">
                                    <img src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                                </a>
                            </div>
                            <div className="nav-right-content">
                                <ul>
                                    <li className="search">
                                        <i className="ti-search" />
                                    </li>
                                </ul>
                            </div>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#necttosDash_main_menu"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggle-icon">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                </span>
                            </button>
                        </div>
                        <div className="collapse navbar-collapse" id="necttosDash_main_menu">
                            <div className="logo-wrapper desktop-logo" >
                                <a href="\" className="logo">
                                    <img style={{width:'50%'}} src={publicUrl + "assets/img/logo.png"} alt={imgattr}/>
                                </a>
                            </div>
                            <ul className="navbar-nav">
                                {/*<li>
                                    <Link to="/home">Home</Link>
                                </li>*/}
                                <li>
                                    <Link to="/login">Login</Link>
                                </li>
                                <li>
                                    <Link to="/register">Register</Link>
                                </li>
                                <li>
                                    <Link to="/download">Download</Link>
                                </li>
                               
                                <li className="menu-item-has-children">
                                    <Link to={'#'}>New Features</Link>
                                    <ul className="sub-menu">
                                        {/*<li><Link to="/about">About</Link></li>
                                        <li><Link to="/services">Service</Link></li>
                                        <li><Link to="/team">Team</Link></li>
                                        <li><Link to="/pricing">Pricing</Link></li>
                                        <li><Link to="/offer">Offer</Link></li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/faq">Faq</Link></li>*/}
                                        <li><Link to={`/admission/${'VICTORIA'}`}>Admission</Link></li>
                                        <li><Link to={`/herbarium/${'VICTORIA'}`}>Herberium</Link></li>
                                        
                                        {/*<li><Link to="/comming-soon">Comming Soon</Link></li>
                                        <li><Link to="/error">Error 404</Link></li>*/}
                                    </ul>
                                </li>
                                {/*<li>
                                    <Link to="/job-listing">Career</Link>
                                </li>*/}
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        {/*<div className="nav-right-content">
                            <ul>
                                <li className="search">
                                    <i className="ti-search" />
                                </li>
                                <li className="cart">
                                </li>
                                <li className="notification">
                                    <a href="\">
                                        <i className="fa fa-heart-o" />
                                        <span className="notification-count">667</span>
                                    </a>
                                </li>
                            </ul>
                        </div>*/}
                    </div>
                </nav>

                <div className="body-overlay" id="body-overlay"></div>
                <div className="search-popup" id="search-popup">
                    <form action="index.html" className="search-form">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Search....." />
                        </div>
                        <button type="submit" className="submit-btn"><i className="fa fa-search"></i></button>
                    </form>
                </div>
            </div>
        )
    }
}


export default Navbar