import moment from "moment";
import React, { useEffect, useState } from "react";
import { degree, getClassName, getMinorName, plustwo, priorityFields } from "./Component";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { getAllMinor } from "../../pages/college/ApiServices";
import { useQuery } from "react-query";
interface RecalculateProps {
  classId: string;
}

interface ResponseData {
  statusCode: number;
  list: {
    indexMark: any;
    earnedMark: number;
  };
}
function ApplicationPrint({ values, componentRef2, classes, type, collegeData, getQuotaTypes }: any) {
  const tableContent = "px-3 py-2 bg-white whitespace-wrap text-left text-lg fw-bold text-black bor";
  const tableContent2 = "px-3 py-2 bg-gray-300 whitespace-wrap text-left text-lg fw-bold text-black";

  const [enable, setEnable] = useState<boolean>(false);
  const [bonasClasses, setBonasClasses] = useState<any>({});

  const Tablehelper = ({ title, value }: any) => {
    return (
      <div className="flex h-[30px]">
        <div className="w-[20%]"> {title} </div>
        <div className="w-[80%]">: {value} </div>
      </div>
    );
  };
  const [appNo, setAppNo] = useState<string>("");

  const { data: minorData = [] } = useQuery("getAllMinor", getAllMinor(collegeData?.collegeId));

  useEffect(() => {
    let appNio = "";
    if (collegeData?.collegeId === "COL-08B6B8276F75-46EE-A69C-77177AAA488B") {
      appNio = values?.applicationNo?.toString();
    } else if (collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F") {
      const paddedApplicationNo = values?.applicationNo?.toString().padStart(4, "0");
      appNio = collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F" ? (type?.toUpperCase() === "UG" ? "241" + values?.applicationNo?.toString().padStart(4, "0") : "242" + values?.applicationNo?.toString().padStart(4, "0")) : values?.applicationNo;
    } else {
      setAppNo(values?.applicationNo);
    }
    setAppNo(appNio);
  }, [values?.applicationNo, collegeData?.collegeId, type]);

  useEffect(() => {
    if (collegeData?.collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F") {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [collegeData?.collegeId]);

  const recalculate = async ({ classId }: RecalculateProps) => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post<ResponseData>(type?.toUpperCase() === "UG" ? "/college/admissionProcess/calculateIndexMark" : "/college/admissionProcess/calculateIndexMarkPG", {
        studentId: values?._id,
        collegeId: collegeData?.collegeId,
        values,
        classId: classId,
      });
      console.log({ resp });

      if (resp?.data?.statusCode === 200) {
        setBonasClasses((prevBonasClasses: any) => ({
          ...prevBonasClasses,
          [classId]: resp?.data?.list?.earnedMark?.toFixed(2),
        }));
      }
    } catch (error) {
      console.error("Error calculating index mark:", error);
    }
  };
  
  useEffect(() => {
    for (let priorityOrder = 1; priorityOrder <= 3; priorityOrder++) {
      const classId = values?.[`preferedCourse${priorityOrder}`];
      if (classId) {
        recalculate({ classId });
      }
    }
  }, [values]);

  return (
    <div ref={componentRef2} id="printable" className="printable page-break bg-white">
      <div className="watermark">SAMPLE WATERMARK</div>
      <div className="p-10">
        <table className="min-w-full">
          <tbody>
            <tr>
              <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto rounded-full" />
              </td>
              <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                <div className="relative w-26 h-26 ">
                  <CircularProgressbar
                    value={values?.percentageFilled}
                    styles={buildStyles({
                      pathColor: "#4caf50",
                      trailColor: "#d6d6d6",
                      textColor: "#212529",
                      textSize: "0px",
                      pathTransitionDuration: 0.5,
                    })}
                  />
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-[80%]">
                      <div className="text-[15px] text-green-900 font-medium text-center">Appl. Completed {values?.percentageFilled?.toFixed(2)}%</div>
                    </div>
                  </div>
                </div>
              </td>
              <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                <img src={values?.photoURL} alt="Student Photo" className="h-full w-auto rounded-md" />
              </td>
            </tr>
          </tbody>
        </table>
        <h2 className="text-center text-1xl font-bold m-3">APPLICATION FORM</h2>
        <div>
          <table className="min-w-full">
            <tbody className="min-w-full border-white ">
              <tr className="min-w-full border-white ">
                <td className="px-3 py-2 bg-white whitespace-wrap text-left text-lg fw-bold text-black" style={{ width: "20%", fontFamily: "Times New Roman" }}>
                  <Tablehelper title="Name" value={values?.name?.toUpperCase()} />
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Appl. No. : {appNo}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Fees Recieved : {values?.applicationAmount?.totalAmount}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Date : {moment(values?.applicationAmount?.date).format("DD-MM-YY HH:mm A")}
                </td>

                {enable && values?.bloodGroup && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    Blood Group : {values?.bloodGroup}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Date of Birth : {values?.dob}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Mob : {values?.phoneNumber?.slice(-10)}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Adhaar No : {values?.aadhaarNumber}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Gender : {values?.gender?.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Email : {values?.email}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Name of Guardian : {values?.gardianName?.toUpperCase()}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Guardian Mob : {values?.guardianMobile?.slice(-10)}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Whatsapp : {values?.whatsappNumber?.slice(-10)}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Quota Applied for : {getQuotaTypes(values?.admissionto)}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Religion : {values?.religion}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Category : {values?.category}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Caste : {values?.caste}
                </td>
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Mother Tongue Language : {values?.motherTongue}
                </td>
                {enable && values?.placeOfBirth && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    Place of Birth : {values?.placeOfBirth}
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                  Disabilities : {values?.disabilities}
                </td>
                {values?.disabilities === "Yes" && (
                  <>
                    <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                      Disability Status : {values?.disabilityStatus}
                    </td>
                    <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                      Disability Percentage : {values?.disabilityPercentage}
                    </td>
                  </>
                )}
              </tr>
            </tbody>
          </table>
          {enable && (
            <table className="min-w-full">
              <tbody>
                <tr>
                  <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                    Siblings College : {values?.siblingsCollege}
                  </td>
                  {values?.siblingsCollege === "Yes" && (
                    <>
                      <td className={tableContent} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                        Program of Sibling : {values?.programOfSibling}
                      </td>
                      <td className={tableContent} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                        Name of Sibling : {values?.nameOfSibling}
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          )}
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                  Communication Address
                </td>
                <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                  {values?.houseName}, {values?.place}, {values?.postOffice}, {values?.district}, {values?.state}, {values?.nationality}, Pin: {values?.pinCode}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                  Permanent Address
                </td>
                <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                  {values?.permanentHouseName}, {values?.permanentPlace}, {values?.permanentPostOffice}, {values?.permanentDistrict}, {values?.permanentState}, {values?.permanentNationality}, Pin:{values?.permanentPinCode}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-4 mb-2 font-bold fs-5">10th Qualification</div>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Reg No : {values?.sslcRegisterNo}
                </td>

                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Institution Name : {values?.sslcInstitutionName}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Board : {values?.sslcBoard}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Month & Year of Pass : {values?.sslcYearOfPass}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                {values?.sslcNoOfChance && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    No of Chances : {values?.sslcNoOfChance}
                  </td>
                )}
                {enable && values?.sslcPercentage && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    Percentage : {values?.sslcPercentage}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <div className="pageBreak"></div>

          <div className="mt-4 mb-2 font-bold fs-5">12th Qualification</div>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Reg No : {values?.plusTwoRegisterNo}
                </td>

                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Institution Name : {values?.plusTwoInstitutionName}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Board : {values?.plusTwoBoard}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Month & Year of Pass : {values?.plusTwoYearOfPass}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  No of Chances : {values?.plusTwoNoOfChance}
                </td>
                {enable && values?.plusTwoPercentage && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    Percentage : {values?.plusTwoPercentage}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Institution State : {values?.plusTwoState}
                </td>
                <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                  Stream : {values?.plusTwoStream}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="min-w-full">
            <tbody>
              <tr>
                {values?.totalMarkObtaioned && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    Marks Obtained : {values?.totalMarkObtaioned}
                  </td>
                )}
                {values?.totalMarks && (
                  <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                    Maximum Marks : {values?.totalMarks}
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          {type?.toUpperCase() === "UG" && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Plus Two Mark List</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                      Subject
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Obtained Mark
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Total Mark
                    </td>
                  </tr>
                </tbody>
              </table>
              {plustwo?.map((x: any) => (
                <table className="min-w-full">
                  <tbody>
                    <tr>
                      <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "Name"]}
                      </td>
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "ObtainedMark"]}
                      </td>
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        {values?.[x?.returnKey + "TotalMark"]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </>
          )}
          {type?.toUpperCase() === "PG" && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Degree Qualification</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeRegisterNo && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Reg No : {values?.degreeRegisterNo}
                      </td>
                    )}
                    {values?.degreeYearOfPass && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Month & Year of Pass : {values?.degreeYearOfPass}
                      </td>
                    )}
                    {values?.degreeNoOfChance && (
                      <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                        No of Chances : {values?.degreeNoOfChance}
                      </td>
                    )}
                    {values?.degreeScheme && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Stream : {values?.degreeScheme}
                      </td>
                    )}
                    {values?.degreeInstState && (
                      <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                        Institution State : {values?.degreeInstState}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeUniversity && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        University : {values?.degreeUniversity}
                      </td>
                    )}
                    {values?.degreeInstitutionName && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        Institution Name : {values?.degreeInstitutionName}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    {values?.degreeSpecialisation && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Specialisation : {values?.degreeSpecialisation}
                      </td>
                    )}
                    {values?.degreeMarkSystem && (
                      <td className={tableContent} style={{ width: "50%", fontFamily: "Times New Roman" }}>
                        Mark System : {values?.degreeMarkSystem}
                      </td>
                    )}
                    {values?.degreeCourse && (
                      <td className={tableContent} style={{ width: "25%", fontFamily: "Times New Roman" }}>
                        Programme Stream : {values?.degreeCourse}
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              <div className="mt-4 mb-2 font-bold fs-5">Degree Grade List</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                      Field Name
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Marks Awarded / CCPA
                    </td>
                    <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Maximum Marks / Credit
                    </td>
                  </tr>
                </tbody>
              </table>
              {degree?.map(
                (x: any) =>
                  values?.[x?.returnKey + "MaximumMarksCredit"] && (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                            {x?.fieldName}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {values?.[x?.returnKey + "MarksAwardedCCPA"]}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {values?.[x?.returnKey + "MaximumMarksCredit"]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
              )}
              <div className="pageBreak"></div>
            </>
          )}
          {enable && (
            <>
              {values?.family?.length > 0 && (
                <>
                  <div className="mt-4 mb-2 font-bold fs-5">Family Member</div>
                  <table className="min-w-full">
                    <tbody>
                      <tr>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Name
                        </td>
                        <td className={tableContent2} style={{ width: "10%", fontFamily: "Times New Roman" }}>
                          Relation
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Qualification
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Occupation
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Income
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Mobile No
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {values?.family?.map((x: any) => (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.name}
                          </td>
                          <td className={tableContent} style={{ width: "10%", fontFamily: "Times New Roman" }}>
                            {x?.relation}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.qualification}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.job}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.income}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.mobile}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
              {values?.travelDetails?.length > 0 && (
                <>
                  <div className="mt-4 mb-2 font-bold fs-5">Mode of Travel to College</div>
                  <table className="min-w-full">
                    <tbody>
                      <tr>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Vehicle Type
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Starts From
                        </td>
                        <td className={tableContent2} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                          Ends at
                        </td>
                        <td className={tableContent2} style={{ width: "5%", fontFamily: "Times New Roman" }}>
                          Km
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Energy Used
                        </td>
                        <td className={tableContent2} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                          Co-traveller
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {values?.travelDetails?.map((x: any) => (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.typeOfVehicle}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.travelStart}
                          </td>
                          <td className={tableContent} style={{ width: "15%", fontFamily: "Times New Roman" }}>
                            {x?.travelTo}
                          </td>
                          <td className={tableContent} style={{ width: "5%", fontFamily: "Times New Roman" }}>
                            {x?.km}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.typeOfEnergy}
                          </td>
                          <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                            {x?.coTraveler1}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
              {values?.culturalActivity?.length > 0 && (
                <>
                  <div className="mt-4 mb-2 font-bold fs-5">Extracurricular/ Cocurricular Details</div>
                  <table className="min-w-full">
                    <tbody>
                      <tr>
                        <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                          Type
                        </td>
                        <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                          Item
                        </td>
                        <td className={tableContent2} style={{ width: "40%", fontFamily: "Times New Roman" }}>
                          Achievements
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {values?.culturalActivity?.map((x: any) => (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                            {x?.type}
                          </td>
                          <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                            {x?.item}
                          </td>
                          <td className={tableContent} style={{ width: "60%", fontFamily: "Times New Roman" }}>
                            {x?.specialised}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </>
              )}
            </>
          )}

          {priorityFields?.length > 0 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Priorities Courses</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent2} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                      Priority
                    </td>
                    <td className={tableContent2} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      Course
                    </td>
                    <td className={tableContent2} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      Index
                    </td>
                  </tr>
                </tbody>
              </table>
              {priorityFields?.map(
                (x: any) =>
                  values?.[x.key] && (
                    <table className="min-w-full">
                      <tbody>
                        <tr>
                          <td className={tableContent} style={{ width: "30%", fontFamily: "Times New Roman" }}>
                            {x?.title}
                          </td>
                          <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                            {getClassName(values?.[x.key], classes)}
                          </td>
                          <td className={tableContent} style={{ width: "70%", fontFamily: "Times New Roman" }}>
                            {bonasClasses[values?.[x.key]]}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )
              )}
            </>
          )}

          {values?.dsc_a && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Major (DSC A)</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Selected : {getClassName(values?.dsc_a, classes)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {values?.dsc_c_1 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Minor 1 (DSC B)</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Selected : {getMinorName(values?.dsc_b_1, minorData)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {values?.dsc_c_1 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">Minor 2 (DSC C)</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Selected : {getMinorName(values?.dsc_c_1, minorData)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {values?.mdc_1 && (
            <>
              <div className="mt-4 mb-2 font-bold fs-5">MDC</div>
              <table className="min-w-full">
                <tbody>
                  <tr>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Priority 1 : {getMinorName(values?.mdc_1, minorData)}
                    </td>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Priority 2 : {getMinorName(values?.mdc_2, minorData)}
                    </td>
                    <td className={tableContent} style={{ width: "20%", fontFamily: "Times New Roman" }}>
                      Priority 3 : {getMinorName(values?.mdc_3, minorData)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApplicationPrint;
