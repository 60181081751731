//menu
import accountsIcon from "../../NecttosAssets/Menu/accounts.png";
import admissionIcon from "../../NecttosAssets/Menu/admission.png";
import allIcon from "../../NecttosAssets/Menu/all.png";
import electionIcon from "../../NecttosAssets/Menu/election.png";
import iqacIcon from "../../NecttosAssets/Menu/iqac.png";
import kirfIcon from "../../NecttosAssets/Menu/kirf.png";
import libraryIcon from "../../NecttosAssets/Menu/library.png";
import naacIcon from "../../NecttosAssets/Menu/naac.png";
import nirfIcon from "../../NecttosAssets/Menu/nirf.png";
import officeIcon from "../../NecttosAssets/Menu/office.png";
import settingsIcon from "../../NecttosAssets/Menu/settings.png";
import aisheIcon from "../../NecttosAssets/Menu/survey.png";
import accademicsIcon from "../../NecttosAssets/Menu/accademic.png";
import examination from "../../NecttosAssets/Menu/examination.png";
import FYUGP from "../../NecttosAssets/Menu/FYUGP.png";
import events from "../../NecttosAssets/Menu/events.png";

//office
import rollViewIcon from "../../NecttosAssets/Office/rollView.png";
import nameSlipIcon from "../../NecttosAssets/Office/nameSlip.png";
import statisticsIcon from "../../NecttosAssets/Office/statistics.png";
import photoCheckIcon from "../../NecttosAssets/Office/photoCheck.png";
import transferCertificateIcon from "../../NecttosAssets/Office/tcCc.png";
import categoryListIcon from "../../NecttosAssets/Office/catogoryList.png";
import strengthDetailsIcon from "../../NecttosAssets/Office/strengthDetails.png";
import earnLeaveCalculatorIcon from "../../NecttosAssets/Office/earnLeaveCalculator.png";
import masterTableIcon from "../../NecttosAssets/Office/masterTable.png";
import supervisionIcon from "../../NecttosAssets/Office/supperVission.png";
import addUserIcon from "../../NecttosAssets/Office/addNew.png";
import idCardPrintingIcon from "../../NecttosAssets/Office/idCardPrinting.png";
import hungerFreeCampus from "../../NecttosAssets/Office/hungerFreeCampus.png";
import deleteUser from "../../NecttosAssets/Office/deleteUser.png";
import courseManagement from "../../NecttosAssets/Office/courseManagement.png";
import dataLoader from "../../NecttosAssets/Office/dataLoader.png";
import updateUsers from "../../NecttosAssets/Office/updateUsers.png";
import insertUsers from "../../NecttosAssets/Office/insertUsers.png";
import photoDownload from "../../NecttosAssets/Office/photoDownload.png";
import rollNumberDistributer from "../../NecttosAssets/Office/rollNumberDistributer.png";
import AccountActiveFirebase from "../../NecttosAssets/Office/AccountActiveFirebase.png";
import certificate from "../../NecttosAssets/Office/certificate.png";

//accounts
import feeStructure from "../../NecttosAssets/Accounts/feeStructure.png";
import instanceFeeCollection from "../../NecttosAssets/Accounts/instanceFeeCollection.png";
import feesBilling from "../../NecttosAssets/Accounts/feesBilling.png";
import eGrantManagement from "../../NecttosAssets/Accounts/eGrantManagement.png";
import dailyFeeCollectionRegister from "../../NecttosAssets/Accounts/dailyFeeCollectionRegister.png";
import collectionReports from "../../NecttosAssets/Accounts/collectionReports.png";
import cashBook from "../../NecttosAssets/Accounts/cashBook.png";
import dcb from "../../NecttosAssets/Accounts/dcb.png";
import numbers from "../../NecttosAssets/Accounts/numbers.png";
import bankCash from "../../NecttosAssets/Accounts/bankCash.png";
import scholarship from "../../NecttosAssets/Accounts/scholarship.png";
import feeManagement from "../../NecttosAssets/Accounts/feeManagement.png";
import subsidiaryRegister from "../../NecttosAssets/Accounts/subsidiaryRegister.png";
import fdcb from "../../NecttosAssets/Accounts/fdcb.png";
import reChecker from "../../NecttosAssets/Accounts/reChecker.png";

//profile
import personalInfoIcon from "../../NecttosAssets/Profile/personalInfo.png";
import officialInfoIcon from "../../NecttosAssets/Profile/officialInfo.png";
import addressInfoIcon from "../../NecttosAssets/Profile/addressInfo.png";
import experienceIcon from "../../NecttosAssets/Profile/experience.png";
import qualificationIcon from "../../NecttosAssets/Profile/qualfication.png";
import responsibilityIcon from "../../NecttosAssets/Profile/responsibility.png";
import photoIcon from "../../NecttosAssets/Profile/photo.png";
import membershipIcon from "../../NecttosAssets/Profile/membership.png";
import seminarsIcon from "../../NecttosAssets/Profile/seminars.png";
import workshopIcon from "../../NecttosAssets/Profile/workshop.png";
import fdpIcon from "../../NecttosAssets/Profile/fdp.png";
import bankIcon from "../../NecttosAssets/Profile/bank.png";
import cocurricularIcon from "../../NecttosAssets/Profile/cocuricular.png";
import travelingIcon from "../../NecttosAssets/Profile/travel.png";
import publicationsIcon from "../../NecttosAssets/Profile/publications.png";
import familyIcon from "../../NecttosAssets/Profile/family.png";
import documentIcon from "../../NecttosAssets/Profile/document.png";
import essentialData from "../../NecttosAssets/Accadamic/essentialData.png";
import incomeExpenditure from "../../NecttosAssets/Accounts/IncomeExpenditure.png";

//Admission
import applicationForm from "../../NecttosAssets/Admission/applicationForm.png";
import createAccount from "../../NecttosAssets/Admission/createAccount.png";
import departmentVerification from "../../NecttosAssets/Admission/departmentVerification.png";
import nodalVerification from "../../NecttosAssets/Admission/nodalVerification.png";
import grandVerification from "../../NecttosAssets/Admission/grandVerification.png";
import officeVerification from "../../NecttosAssets/Admission/officeVerification.png";
import feesVerification from "../../NecttosAssets/Admission/feesVerification.png";
import universityUploading from "../../NecttosAssets/Admission/universityUploading.png";
import admissionRegister from "../../NecttosAssets/Admission/admissionRegister.png";
import dashBoard from "../../NecttosAssets/Admission/Dash Board.png";
import controller from "../../NecttosAssets/Admission/controller.png";
import epos from "../../NecttosAssets/Admission/epos.png";
import nodalVerificationTemp from "../../NecttosAssets/Admission/nodalVerificationTemp.png";
import principalVerification from "../../NecttosAssets/Admission/principalVerification.png";
import ptaVerification from "../../NecttosAssets/Admission/ptaVerification.png";
import rankList from "../../NecttosAssets/Admission/rankList.png";
import splitups from "../../NecttosAssets/Admission/splitups.png";
import enquryForm from "../../NecttosAssets/Admission/enquryForm.png";
import applicationStated from "../../NecttosAssets/Admission/applicationStated.png";
import pyementWaitingSubmition from "../../NecttosAssets/Admission/pyementWaitingSubmition.png";
import applicationRecieved from "../../NecttosAssets/Admission/applicationRecieved.png";
import indexMarkListed from "../../NecttosAssets/Admission/indexMarkListed.png";
import rankListAuto from "../../NecttosAssets/Admission/rankListAuto.png";
import seatReservation from "../../NecttosAssets/Admission/seatReservation.png";
import admissionRegister2 from "../../NecttosAssets/Admission/admissionRegister2.png";
import admissionRegister3 from "../../NecttosAssets/Admission/admissionRegister3.png";
import photoVerification from "../../NecttosAssets/Admission/photoVerification.png";
import priorityChanger from "../../NecttosAssets/Admission/priorityChanger.png";
import rankListRules from "../../NecttosAssets/Admission/rankListRules.png";



//Academics
import subjectWiseAttendance from "../../NecttosAssets/Accadamic/subjectWiseAttendance.png";
import masterTimetable from "../../NecttosAssets/Accadamic/masterTimetable.png";
import accadamicHoliday from "../../NecttosAssets/Accadamic/accadamicHoliday.png";
import classWiseAttendance from "../../NecttosAssets/Accadamic/classWiseAttendance.png";
import internal from "../../NecttosAssets/Accadamic/internal.png";
import departmentMaster from "../../NecttosAssets/Accadamic/departmentMaster.png";
import menteeForm from "../../NecttosAssets/Accadamic/menteeForm.png";
import mentorMentee from "../../NecttosAssets/Accadamic/mentorMentee.png";
import openCourse from "../../NecttosAssets/Accadamic/openCourse.png";
import profac from "../../NecttosAssets/Accadamic/profac.png";
import rolloutManagement from "../../NecttosAssets/Accadamic/rolloutManagement.png";
import attendanceRegister from "../../NecttosAssets/Accadamic/attendanceRegister.png";
import apc from "../../NecttosAssets/Accadamic/apc.png";
import attendanceMonthlyRegister from "../../NecttosAssets/Accadamic/attendanceMonthlyRegister.png";
import masterstockregister from "../../NecttosAssets/Accadamic/masterstockregister.png";
import electiveChanger from "../../NecttosAssets/Accadamic/electiveChanger.png";

//Library
import bookRecomentation from "../../NecttosAssets/Library/bookRecomentation.png";
import ddcSubjects from "../../NecttosAssets/Library/ddcSubjects.png";
import eResources from "../../NecttosAssets/Library/eResources.png";
import libraryStatisticsExport from "../../NecttosAssets/Library/libraryStatisticsExport.png";
import holidays from "../../NecttosAssets/Library/holidays.png";
import invoiceManagement from "../../NecttosAssets/Library/invoiceManagement.png";

//Examination
import halltiketDistribution from "../../NecttosAssets/Examiniation/halltiketDistribution.png";
import questionPaperCreation from "../../NecttosAssets/Examiniation/questionPaperCreation.png";
import roomDocuments from "../../NecttosAssets/Examiniation/roomDocuments.png";
import valuationManagement from "../../NecttosAssets/Examiniation/valuationManagement.png";
import resultPublication from "../../NecttosAssets/Examiniation/resultPublication.png";
import revaluation from "../../NecttosAssets/Examiniation/revaluation.png";
import suplimentory from "../../NecttosAssets/Examiniation/suplimentory.png";
import tabulation from "../../NecttosAssets/Examiniation/tabulation.png";
import subjectBank from "../../NecttosAssets/Examiniation/subjectBank.png";
import questionBankCreator from "../../NecttosAssets/Examiniation/questionBankCreator.png";
import questionBankCreatorVerification from "../../NecttosAssets/Examiniation/questionBankCreatorVerification.png";
import questionBank from "../../NecttosAssets/Examiniation/questionBank.png";
import examInit from "../../NecttosAssets/Examiniation/examInit.png";
import examRegistration from "../../NecttosAssets/Examiniation/examRegistration.png";
import timeTableManagement from "../../NecttosAssets/Examiniation/timeTableManagement.png";
import seatingPlan from "../../NecttosAssets/Examiniation/seatingPlan.png";
import roomArrangement from "../../NecttosAssets/Examiniation/roomArrangement.png";
import invigilationManagement from "../../NecttosAssets/Examiniation/invigilationManagement.png";
import barcodeDownload from "../../NecttosAssets/Examiniation/barcodeDownload.png";
import subjectRegistry from "../../NecttosAssets/FYUGP/subjectRegistry.png";
import studentResponds from "../../NecttosAssets/FYUGP/studentResponds.png";
import fyugpStatistics from "../../NecttosAssets/FYUGP/fyugpStatistics.png";
import standardizationMetrics from "../../NecttosAssets/FYUGP/standardizationMetrics.png";
import realTimeRanking from "../../NecttosAssets/FYUGP/realTimeRanking.png";
import realTimeAllotment from "../../NecttosAssets/FYUGP/realTimeAllotment.png";
import courseSchedule from "../../NecttosAssets/FYUGP/courseSchedule.png";
import allotmentListClassWise from "../../NecttosAssets/FYUGP/allotmentListClassWise.png";
import fyugpDashBoard from '../../NecttosAssets/FYUGP/fyugpDashBoard.png';
import confirmedCourseAllotmentClassWise from '../../NecttosAssets/FYUGP/confirmedCourseAllotmentClassWise.png';
import ConfirmedCourseAllotmentCourseWise from '../../NecttosAssets/FYUGP/ConfirmedCourseAllotmentCourseWise.png';
//events
import clubCellsAccounts from "../../NecttosAssets/Events/ClubCellsAccounts.png"
import cordinatorShipForm from "../../NecttosAssets/Events/cordinatorShipForm.png"
import cordinatorShipAdmin from "../../NecttosAssets/Events/cordinatorShipAdmin.png"
//election
import post from "../../NecttosAssets/Election/post.png"
import nomination from "../../NecttosAssets/Election/nomination.png"
import counting from "../../NecttosAssets/Election/counting.png"

export const icons: { [key: string]: string } = {

  //menu
  accounts: accountsIcon,
  admission: admissionIcon,
  all: allIcon,
  election: electionIcon,
  iqac: iqacIcon,
  kirf: kirfIcon,
  library: libraryIcon,
  naac: naacIcon,
  nirf: nirfIcon,
  office: officeIcon,
  settings: settingsIcon,
  aishe: aisheIcon,
  academics: accademicsIcon,
  examination: examination,
  FYUGP:FYUGP,
  events:events,

  //FYUGP
  subjectRegistry: subjectRegistry,
  studentResponds: studentResponds,
  fyugpStatistics: fyugpStatistics,
  standardizationMetrics: standardizationMetrics,
  realTimeRanking: realTimeRanking,
  realTimeAllotment: realTimeAllotment,
  courseSchedule: courseSchedule,
  allotmentListClassWise:allotmentListClassWise,
  fyugpDashBoard: fyugpDashBoard,
  confirmedClassWiseAllotment: confirmedCourseAllotmentClassWise,
  confirmedCourseWiseAllotment: ConfirmedCourseAllotmentCourseWise,
  
  //office
  rollView: rollViewIcon,
  nameSlip: nameSlipIcon,
  statistics: statisticsIcon,
  photoCheck: photoCheckIcon,
  tcCc: transferCertificateIcon,
  catogoryList: categoryListIcon,
  strengthDetails: strengthDetailsIcon,
  earnLeaveCalculator: earnLeaveCalculatorIcon,
  masterTable: masterTableIcon,
  supperVission: supervisionIcon,
  addUser: addUserIcon,
  idCardPrinting: idCardPrintingIcon,
  hungerFreeCampus: hungerFreeCampus,
  deleteUser: deleteUser,
  analytics: statisticsIcon,
  courseManagement: courseManagement,
  dataLoader:dataLoader,
  updateUsers:updateUsers,
  insertUsers:insertUsers,
  photoDownload:photoDownload,
  rollNumberDistributer:rollNumberDistributer,
  accountActiveFirebase:AccountActiveFirebase,
  certificate:certificate,

  //accounts
  feeStructure: feeStructure,
  instanceFeeCollection: instanceFeeCollection,
  feesBilling: feesBilling,
  eGrantManagement: eGrantManagement,
  dailyFeeCollectionRegister: dailyFeeCollectionRegister,
  collectionReports: collectionReports,
  cashBook: cashBook,
  dcb: dcb,
  incomeExpenditure: incomeExpenditure,
  numbers: numbers,
  bankCash: bankCash,
  scholarship: scholarship,
  feeManagement: feeManagement,
  subsidiaryRegister: subsidiaryRegister,
  fdcb: fdcb,
  reChecker:reChecker,

  //profile
  profileInformation: personalInfoIcon,
  officialInformation: officialInfoIcon,
  addressDetails: addressInfoIcon,
  experienceAndServiceDetails: experienceIcon,
  educationalQualification: qualificationIcon,
  additionResponsibilities: responsibilityIcon,
  profilePhoto: photoIcon,
  membershipInAcademic: membershipIcon,
  seminars: seminarsIcon,
  workshops: workshopIcon,
  fdp: fdpIcon,
  bankInformations: bankIcon,
  curriculamActivies: cocurricularIcon,
  travelingHistory: travelingIcon,
  publications: publicationsIcon,
  booksChapters: publicationsIcon,
  family: familyIcon,
  document: documentIcon,
  essentialData: essentialData,

  //Admission
  applicationForm: applicationForm,
  createAccount: createAccount,
  departmentVerification: departmentVerification,
  nodalVerification: nodalVerification,
  grandVerification: grandVerification,
  officeVerification: officeVerification,
  feesVerification: feesVerification,
  universityUploading: universityUploading,
  admissionRegister: admissionRegister,
  dashBoard: dashBoard,
  controller: controller,
  epos: epos,
  nodalVerificationTemp: nodalVerificationTemp,
  principalVerification: principalVerification,
  ptaVerification: ptaVerification,
  rankList: rankList,
  enquryForm: enquryForm,
  applicationStated: applicationStated,
  pyementWaitingSubmition: pyementWaitingSubmition,
  applicationRecieved: applicationRecieved,
  indexMarkListed: indexMarkListed,
  rankListAuto: rankListAuto,
  seatReservation: seatReservation,
  splitups: splitups,
  admissionRegister2:admissionRegister2,
  admissionRegister3:admissionRegister3,
  photoVerification:photoVerification,
  priorityChanger:priorityChanger,
  rankListRules:rankListRules,

  //Academics
  subjectWiseAttendance: subjectWiseAttendance,
  timetable: masterTimetable,
  accadamicHoliday: accadamicHoliday,
  classWiseAttendance: classWiseAttendance,
  internal: internal,
  departmentMaster: departmentMaster,
  menteeForm: menteeForm,
  mentorMentee: mentorMentee,
  openCourse: openCourse,
  profac: profac,
  rolloutManagement: rolloutManagement,
  attendanceRegister: attendanceRegister,
  apc: apc,
  attendanceMonthlyRegister: attendanceMonthlyRegister,
  masterstockregister:masterstockregister,
  electiveChanger:electiveChanger,

  //Library
  bookRecomentation: bookRecomentation,
  ddcSubjects: ddcSubjects,
  eResources: eResources,
  libraryStatisticsExport: libraryStatisticsExport,
  holidays: holidays,
  invoiceManagement: invoiceManagement,

  //Examination
  halltiketDistribution: halltiketDistribution,
  questionPaperCreation: questionPaperCreation,
  roomDocuments: roomDocuments,
  valuationManagement: valuationManagement,
  resultPublication: resultPublication,
  revaluation: revaluation,
  suplimentory: suplimentory,
  tabulation: tabulation,
  subjectBank: subjectBank,
  questionBankCreator: questionBankCreator,
  questionBankCreatorVerification: questionBankCreatorVerification,
  questionBank: questionBank,
  examInit: examInit,
  examNotification: examInit,
  condonation: examInit,
  examRegistration: examRegistration,
  timeTableManagement: timeTableManagement,
  barcodeDownload: barcodeDownload,
  seatingPlan: seatingPlan,
  roomArrangement: roomArrangement,
  invigilationManagement: invigilationManagement,

  //Events
  clubCellsAccounts:clubCellsAccounts,
  cordinatorShipForm:cordinatorShipForm,
  cordinatorShipAdmin:cordinatorShipAdmin,
  //Election
  post:post,
  nomination:nomination,
  counting:counting,
};
