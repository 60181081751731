import React, { useContext, useState } from "react";
import Button from "../../../../NecttosComp/Button/Button";
import { useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";

interface messageProp {
  onClose: () => void;
}

const getDepartmentList = async ({ queryKey }: any) => {
  const [_, { collegeId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  let resp;
  if (collegeId) {
    try {
      resp = await instance.get("/college/classes/getComponent", {
        params: { type: "departments", collegeId },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message || "API Error");
    }
  }
  return resp?.data?.list;
};



const getClassesList = async ({ queryKey }: any) => {
  const [_, { collegeId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  let resp;
  if (collegeId) {
    try {
      resp = await instance.get("/college/classes/getClass", {
        params: { collegeId, needProgress: true },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error.message || "API Error");
    }
  }
  return resp?.data?.list;
};

function Messaging({ onClose }: messageProp) {
  const [selectAud, setSelectAud] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const [enabled, setEnabled] = useState<any>({});
  const [msg, setMsg] = useState({ subject: "", message: "",expDate:"",effectFrom:"",type:"",audienceType:"" });
  const [whoCanSee, setAudience] = useState<{ department: string[]; class: string[]; status: "collected" | "in progress" | "balance" | "all" }>({ department: [], class: [], status: "collected" });
  const student = ["All", "Department", "Class"];
  const status: any = ["Collected", "In Progress", "Balance", "All"];
  const { data: departmentData } = useQuery(["departments", { collegeId }], getDepartmentList);
  const { data: classes } = useQuery(["classes", { collegeId }], getClassesList);

  const type = ["Important","Notify","General"]
  const TimeOptions = ["24 hours", "48 hours", "7 days", "1 month", "3 months", "6 months", "1 year", "2 years", "3 years"];
  return (
    <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#d6d5d57a]">
      {selectAud ? (
        <div className="p-6 bg-white shadow-lg rounded-lg w-[50vw] max-h-[75vh]">
          <Button type="close" onClick={() => setSelectAud(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 512 512" fill="white">
              <polyline points="244 400 100 256 244 112" style={{ fill: "none", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 48 }} />
              <line x1={120} y1={256} x2={412} y2={256} style={{ fill: "none", stroke: "white", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 48 }} />
            </svg>
          </Button>
          <div className="h-[40vh] overflow-auto">
            <div className="flex flex-col">
              {student?.map((x) => (
                <label className="grid grid-cols-2 items-center">
                  <input
                    checked={enabled?.[x]}
                    type="checkbox"
                    name=""
                    id=""
                    className="h-3"
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (x === "All") {
                        setEnabled({ ...enabled, [x]: !enabled?.[x], Department: false, Class: false });
                        setMsg({...msg,audienceType:x})
                      } else {
                        setEnabled({ ...enabled, [x]: !enabled?.[x], All: false });
                        setMsg({...msg,audienceType:x})
                      }
                    }}
                  />
                  {x}
                </label>
              ))}
              {status?.map((x: any) => (
                <label className="grid grid-cols-2 items-center">
                  <input
                    type="radio"
                    name="status"
                    id=""
                    className="h-3"
                    onChange={() => {
                      setAudience({ ...whoCanSee, status: x?.toLowerCase() });
                    }}
                  />
                  {x}
                </label>
              ))}
            </div>

            {enabled?.Department && (
              <div className="flex flex-col ps-5">
                <h6 className="text-center">Department</h6>
                {departmentData?.map((x: any) => (
                  <label className="grid grid-cols-2 items-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="h-3"
                      onChange={() => {
                        const temp = [...whoCanSee?.department];
                        const index = temp.indexOf(x?._id);
                        if (index >= 0) {
                          temp.splice(index);
                        } else {
                          temp.push(x?._id);
                        }
                        setAudience({ ...whoCanSee, department: [...temp] });
                      }}
                    />
                    {x?.name}
                  </label>
                ))}
              </div>
            )}

            {enabled?.Class && (
              <div className="flex flex-col ps-5">
                <h6 className="text-center">Class</h6>
                {classes?.map((x: any) => (
                  <label className="grid grid-cols-2 items-center">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="h-3"
                      onChange={() => {
                        const temp = [...whoCanSee?.class];
                        const index = temp.indexOf(x?._id);
                        if (index >= 0) {
                          temp.splice(index);
                        } else {
                          temp.push(x?._id);
                        }
                        setAudience({ ...whoCanSee, class: [...temp] });
                      }}
                    />
                    {x?.className}
                  </label>
                ))}
              </div>
            )}
          </div>

     
          <button
            type="button"
            onClick={async () => {
              const instance = await getAxiosTokenInstance();
              instance.post("/college/admissionProcess/notify", { data: { ...msg, whoCanSee },collegeId }).then((x)=>{
                onClose()
              }).catch((err)=>{
                console.log(err)
                onClose()
              })
            }}
            className="w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition duration-500 hover:scale-105"
          >
            Send
          </button>
        </div>
      ) : (
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <Button type="close" onClick={onClose}>
            close
          </Button>
          <select onChange={(e)=>setMsg({...msg,type:e.target.value})} className="form-select appearance-none block w-full px-3 py-1.5 mt-4 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Select duration">
            <option value="">Message Type</option>
            {type.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <input type="text" onChange={(e) => setMsg({ ...msg, subject: e.target.value })} placeholder="subject" className="w-full p-2 border mt-2 border-gray-300 rounded-md focus:border-blue-500 focus:outline-none" />
          <textarea name="" onChange={(e) => setMsg({ ...msg, message: e.target.value })} placeholder="Message" id="" cols={30} rows={10} className="mt-4 w-full p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none"></textarea>
          <select onChange={(e)=>setMsg({...msg,expDate:e.target.value})} className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" aria-label="Select duration">
            <option value="">Expire Date</option>
            {TimeOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <input type="date" onChange={(e)=>setMsg({...msg,effectFrom:e.target.value})} className="form-input w-full mt-3 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />


          <button type="button" onClick={() => setSelectAud(true)} className="w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transform transition duration-500 hover:scale-105">
            Select Audience
          </button>
        </div>
      )}
    </div>
  );
}

export default Messaging;
