


import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { message } from "antd";





const Contact_Form = () => {
    const [state, setState] = useState({});
    const makeEntry = async () => {
        try {
            let data = {
                name: state.name,
                mobile: state.mobile,
                subject: state.subject,
                message: state.message,
            }
            const postData = data;
            const instance = await getAxiosTokenInstance();
            let resp = await instance.post("/website/contactUs", postData);
            message.success(resp?.message || "Indury Posted");
            setState({
                name: '',
                mobile: '',
                subject: '',
                message: '',
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message || error?.message || "API Error"
            );
        } finally { }
    };

    let anchor = '#'
    let imgalt = 'image'
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <div>
            <div className="contact-form-area pd-top-112">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center w-100">
                                <h2 className="title">Send you <span>inquary</span></h2>
                                <p>Our product is the best quality product in the world. We customize for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-5">
                            <img src={publicUrl + 'assets/img/others/21.png'} alt={imgalt} />
                        </div>
                        <div className="col-lg-7 offset-xl-1">
                            <form className="necttos-form-wrap mt-5 mt-lg-0">
                                <div className="row custom-gutters-16">
                                    <div className="col-md-6">
                                        <label>Name</label>

                                        <div className="single-input-wrap">

                                            <input
                                                onChange={(e) => setState({ ...state, name: e.target.value })}
                                                value={state.name}
                                                type="text" className="single-input" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-6">
                                    <label>Mobile</label>

                                        <div className="single-input-wrap">
                                            <input
                                                onChange={(e) => setState({ ...state, mobile: e.target.value })}
                                                value={state.mobile}
                                                type="text" className="single-input" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    <label>Subject</label>

                                        <div className="single-input-wrap">
                                            <input

                                                onChange={(e) => setState({ ...state, subject: e.target.value })}
                                                value={state.subject}
                                                type="text" className="single-input" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                    <label>Message</label>

                                        <div className="single-input-wrap">
                                            <textarea
                                                onChange={(e) => setState({ ...state, message: e.target.value })}
                                                value={state.message}
                                                className="single-input textarea" cols="20"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <a className="btn btn-red mt-0" onClick={makeEntry} href={anchor}>Send</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Contact_Form
