import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../NecttosComp/Table/Table";
import { getAdmissionRegister, getNewClasses } from "../../pages/college/ApiServices";
import moment from "moment";
import ApplicationView from "./ApplicationView";
import { getCollegeDetailsee } from "./RemoveAction";
import { getCollegeDetails } from "../Form/Register";
import AllotmentMemo from "../Form/AllotmentMemo";

interface invoiceManagementProps {
  onClose: () => void;
}

function AdmissionRegister2({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const { collegeId, user } = useContext(FirebaseContext);
  const ref = useRef<any>(null);
  const componentRef2 = useRef();
  const [selectedClass, setSelectClass] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { data, refetch, status } = useQuery(["getAdmissionRegister", addNew?.startDate, addNew?.endingDate, selectedClass], getAdmissionRegister(collegeId, addNew?.startDate, addNew?.endingDate, selectedClass));
  const { data: classes } = useQuery("getNewClasses", getNewClasses(collegeId, user?.uid));
  const tableData = data?.list;
  const [filterData, setFilter] = useState<any>([]);
  const [filterKey, setFilterKey] = useState("ALL");
  const [printable, setPritable] = useState<boolean>(false);
  const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
  const { data: classesx } = useQuery([`collegeDataddd`, collegeId], getCollegeDetails);
  console.log({ collegeData });

  useEffect(() => {
    if (!tableData) return;

    let filtered = tableData;

    if (filterKey !== "ALL") {
      filtered = filtered.filter((data: any) => (filterKey === "UG" ? ["2024-2028", "2024-2027", "2024-2029"].includes(data.batchName) : ["2024-2026"].includes(data.batchName)));
    }

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      filtered = filtered.filter((data: any) => data.name.toLowerCase().includes(lowerCaseQuery) || data.phoneNumber?.includes(searchQuery) || data.admisionNo.includes(searchQuery));
    }

    setFilter(filtered);
  }, [filterKey, tableData, searchQuery]);

  const { tableItems, requestSort, getIcon } = useSortableData(filterData);

  const handleDelete = async (_id: string) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .delete("/college/action/deleteAccadamicHoliday/" + _id, { params: { collegeId } })
        .then(() => {
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/action/postAccadamicHolidays", { ...addNew, collegeId })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
      closeAnimation();
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const [className, setClassName] = useState<any>("");

  useEffect(() => {
    if (selectedClass && classesx) {
      let classNam = classesx?.find((x: any) => x._id === selectedClass)?.className || "";
      setClassName(classNam);
    } else {
      setClassName("");
    }
  }, [selectedClass, classesx]);

  return (
    <>
      <Main title="Applications Initiated" width="100%" height="100%">
        <Header>
          <div className="flex">
            <Input fieldName="Start Date *" returnKey="startDate" state={addNew} setState={setAddNew} type="date" width="200px" />
            <Input fieldName="End Date *" returnKey="endingDate" state={addNew} setState={setAddNew} type="date" width="200px" />
            <Input fieldName="Filter by Course" state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="15vw" />
            <Input fieldName="Search" state={searchQuery} setState={setSearchQuery} onChange={(event) => setSearchQuery(event.value)} type="text" width="15vw" />
          </div>
          <div className="flex">
            <Button type={printable ? "search" : "update"} onClick={() => setPritable(!printable)}>
              {printable ? "Hide Printable" : "Show Printape"}
            </Button>
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="printable overflow-auto h-full ">
            {printable && (
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    <td className="p-1 bg-white" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto  mx-auto" />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-1 text-center text-black bg-white text-xl font-bold" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                      Course: {className}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <Table width={printable ? "100%" : "4250px"}>
              <Thead>
                <Tr>
                  {printable && (
                    <Th width={printable ? "5%" : "150px"} position={0} onClick={() => requestSort("admisionNo")} icon={getIcon("admisionNo")}>
                      S.N.
                    </Th>
                  )}
                  <Th width={printable ? "10%" : "150px"} position={0} onClick={() => requestSort("admisionNo")} icon={getIcon("admisionNo")}>
                    Admission Number
                  </Th>
                  {!printable && (
                    <>
                      <Th width="150px" position={0} onClick={() => requestSort("admisionNumber")} icon={getIcon("admisionNumber")}>
                        Admision Number only
                      </Th>
                    </>
                  )}

                  <Th width={printable ? "20%" : "150px"} onClick={() => requestSort("name")} icon={getIcon("name")}>
                    Name
                  </Th>
                  <Th width={printable ? "10%" : "150px"} onClick={() => requestSort("admType")} icon={getIcon("admType")}>
                    Admitted Quota
                  </Th>
                  <Th width="150px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                    Mobile No
                  </Th>

                  {!printable && (
                    <>
                      <Th width="150px" onClick={() => requestSort("mobileNo")} icon={getIcon("mobileNo")}>
                        Mobile No
                      </Th>
                      <Th width="150px" onClick={() => requestSort("gender")} icon={getIcon("gender")}>
                        Gender
                      </Th>
                      <Th width="150px" onClick={() => requestSort("birthplace")} icon={getIcon("birthplace")}>
                        Place of Birth
                      </Th>
                      <Th width="150px" onClick={() => requestSort("dob")} icon={getIcon("dob")}>
                        Date of Birth
                      </Th>
                      <Th width="200px" onClick={() => requestSort("district")} icon={getIcon("district")}>
                        District
                      </Th>
                      <Th width="150px" onClick={() => requestSort("state")} icon={getIcon("state")}>
                        State
                      </Th>
                      <Th width="200px" onClick={() => requestSort("sslcRegNo")} icon={getIcon("sslcRegNo")}>
                        SSLC Reg NO & YYYY-MM
                      </Th>
                      <Th width="200px" onClick={() => requestSort("plustwoRegNo")} icon={getIcon("plustwoRegNo")}>
                        Plustwo Reg NO & YYYY-MM
                      </Th>
                      <Th width="200px" onClick={() => requestSort("degreeRegNo")} icon={getIcon("degreeRegNo")}>
                        UG Reg NO & YYYY-MM
                      </Th>
                      <Th width="450px" onClick={() => requestSort("guardianInfo")} icon={getIcon("guardianInfo")}>
                        Guardian Info
                      </Th>
                    </>
                  )}
                  {printable && (
                    <Th width={printable ? "10%" : "200px"} onClick={() => requestSort("category")} icon={getIcon("category")}>
                      Category
                    </Th>
                  )}
                  {!printable && (
                    <>
                      <Th width={printable ? "20%" : "200px"} onClick={() => requestSort("casteReligion")} icon={getIcon("casteReligion")}>
                        Caste And Religion
                      </Th>
                      <Th width="200px" onClick={() => requestSort("lastSchool")} icon={getIcon("lastSchool")}>
                        Last School/College Attended
                      </Th>
                    </>
                  )}
                  <Th width={printable ? "10%" : "150px"} onClick={() => requestSort("admissionDate")} icon={getIcon("admissionDate")}>
                    Date of Admission
                  </Th>
                  <Th width={printable ? "20%" : "200px"} onClick={() => requestSort("classGroup")} icon={getIcon("classGroup")}>
                    Course Name
                  </Th>
                  {!printable && (
                    <>
                      <Th width="150px" onClick={() => requestSort("secondLanguage")} icon={getIcon("secondLanguage")}>
                        Second Language
                      </Th>
                      <Th width="200px" position={0} onClick={() => requestSort("publicExam")} icon={getIcon("publicExam")}>
                        Public Examination
                      </Th>
                      <Th width="200px" position={0} onClick={() => requestSort("passingDetails")} icon={getIcon("passingDetails")}>
                        Passing Details
                      </Th>
                      <Th width="150px" position={0} onClick={() => requestSort("leavingDate")} icon={getIcon("leavingDate")}>
                        Date of Leaving
                      </Th>
                    </>
                  )}
                  <Th width="200px" position={0} onClick={() => requestSort("tcDetails")} icon={getIcon("tcDetails")}>
                    TC Number and Issued Date
                  </Th>
                  {!printable && (
                    <>
                      <Th width="150px" position={0} onClick={() => requestSort("remarks")} icon={getIcon("remarks")}>
                        Remarks
                      </Th>
                      <Th width="100px" position={0}>
                        Action
                      </Th>
                    </>
                  )}
                  {selectedClass && (
                    <Th width={printable ? "20%" : "150px"} onClick={() => requestSort("name")} icon={getIcon("name")}>
                      Actions
                    </Th>
                  )}
                </Tr>
              </Thead>
              <Tbody height="100vh">
                {tableItems?.map((item, index) => (
                  <Tr key={index}>
                    {printable && (
                      <Td width={printable ? "5%" : "150px"} index={index} position={0}>
                        {index + 1}
                      </Td>
                    )}
                    <Td width={printable ? "10%" : "150px"} index={index} position={0}>
                      {item.admisionNo}
                    </Td>
                    {!printable && (
                      <Td width="150px" index={index} position={0}>
                        {item.admisionNumber}
                      </Td>
                    )}

                    <Td width={printable ? "20%" : "150px"} index={index} position={0}>
                      {item?.name?.toUpperCase()}
                    </Td>
                    <Td width={printable ? "10%" : "150px"} index={index} position={0}>
                      {item.admType}
                    </Td>
                    <Td width="150px" index={index} position={0}>
                      {item.phoneNumber}
                    </Td>
                    {!printable && (
                      <>
                        <Td width="150px" index={index} position={0}>
                          {item.phoneNumber}
                        </Td>
                        <Td width="150px" index={index} position={0}>
                          {item.gender}
                        </Td>
                        <Td width="150px" index={index} position={0}>
                          {item.placeOfBirth}
                        </Td>
                        <Td width="150px" index={index} position={0}>
                          {item.dob}
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          {item.district}
                        </Td>
                        <Td width="150px" index={index} position={0}>
                          {item.state}
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          <p>{item.sslcRegisterNo}</p>
                          <p>{item.sslcYearOfPass}</p>
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          <p>{item.plusTwoRegisterNo}</p>
                          <p>{item.plusTwoYearOfPass}</p>
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          <p>{item.degreeRegisterNo}</p>
                          <p>{item.degreeYearOfPass}</p>
                        </Td>
                        <Td index={index} width="450px">
                          {`${item.guardianName || ""}; ${item.guardianRelation || ""}; ${item.guardianMobile || ""}; ${item.guardianQualification || ""}`}
                          <div>
                            <b>Address:</b>
                            {` ${item.houseName || ""}, ${item.place || ""}, ${item.postOffice || ""}, ${item.pinCode || ""} ${item.district || ""}`}
                          </div>
                        </Td>{" "}
                      </>
                    )}
                    {printable && (
                      <Td width={printable ? "10%" : "200px"} index={index} position={0}>
                        {item.category}
                      </Td>
                    )}
                    {!printable && (
                      <>
                        <Td width={printable ? "20%" : "200px"} index={index} position={0}>
                          {item.caste}/{item.religion}
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          {item?.degreeInstitutionName || item.plusTwoInstitutionName}
                        </Td>
                      </>
                    )}
                    <Td width={printable ? "10%" : "150px"} index={index} position={0}>
                      {moment(item.dateOfAdmission).format("DD-MM-YYYY")}
                    </Td>
                    <Td width={printable ? "20%" : "200px"} index={index} position={0}>
                      {item?.shortName}
                    </Td>
                    {!printable && (
                      <>
                        <Td width="150px" index={index} position={0}>
                          {item?.secondLanguage}
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          {item?.publicExam}
                        </Td>
                        <Td width="200px" index={index} position={0}>
                          {item?.passingDetails}
                        </Td>
                      </>
                    )}
                    <Td width="200px" index={index} position={0}>
                      {item?.tcNumber} {item?.tcDate && ", " + moment(item?.tcDate).format("DD-MM-YYYY")}
                    </Td>
                    {!printable && (
                      <>
                        <Td width="150px" index={index} position={0}>
                          {item.remarks}
                        </Td>
                        <Td width="100px" index={index} position={0}>
                          <Button type="doc" onClick={() => setAddNew({ viewMode: true, _id: item._id, typeUG: item.batchName === "2024-2028" ? "UG" : "PG" })}>
                            ViewApp
                          </Button>
                        </Td>
                      </>
                    )}
                    {selectedClass && (
                      <Td index={index} position={2}>
                        <Button
                          type="doc"
                          onClick={() => {
                            setAddNew({ ...addNew, showConfirmation: true, ...item, className: className, typeUG: item.batchName === "2024-2028" ? "UG" : "PG" });
                          }}
                        >
                          ViewApp
                        </Button>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        {addNew?.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.typeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
      </Main>

      {addNew?.showConfirmation && (
        <Main width="100%" height="100%" title="Make Decision">
          <Body>
            <div className="p-6 space-y-6">
              <div className="flex justify-between items-center">
                <Button
                  type="close"
                  onClick={() => {
                    closeAnimation();
                    setTimeout(() => setAddNew({ ...addNew, showConfirmation: false }), 500);
                  }}
                >
                  Close
                </Button>
              </div>

              <div className="flex flex-row w-full space-x-6">
                <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 flex flex-col items-center">
                  <h2 className={addNew?.color}>Applicant Decided to:</h2>
                  <h2 className={addNew?.color}>{addNew.status}</h2>
                  <Button type="print" tableRef={componentRef2.current}>
                    Print Allotment Memo
                  </Button>
                  <Body>
                    <AllotmentMemo componentRef={componentRef2} collegeData={collegeData} classId={selectedClass} className={addNew?.classNam} quota={addNew?.admType} classes={classesx} type={addNew?.typeUG} indexMark={addNew?.index} values={addNew} getQuotaTypes={addNew?.getQuotaTypes} />
                  </Body>
                </div>
              </div>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default AdmissionRegister2;
