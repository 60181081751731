import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Body,
  Header,
  Main,
  closeAnimation,
} from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import moment from "moment";
import { getProfile } from "../../queryHooks";


interface invoiceManagementProps {
  onClose: () => void;
}

function RoomManagement({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>({});
  const { collegeId, user } = useContext(FirebaseContext);
  const [values, setValues] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [roomData, setRoomData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef<any>(null);
  const { data } = useQuery(
    [`profileData`, collegeId, addNew?._id],
    getProfile
  );
  const [search, setsearch] = useState("");
  const [mode, setMode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>(null);

  const initialFormData = {
    roomNo: "",
    noOfBenches: "",
    noOfSeatsOnRoom: "",
    nameOfBlock: "",
    typeOfRoom: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (data) {
      setValues(data);
      setFormData({ ...formData });
    }
  }, [data]);

  const handleFormChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prv) => ({
      ...prv,
      [name]: value,
    }));
  };

  const postRoomManagement = async () => {
    
    try {
      
      if(formData.typeOfRoom===""){
        return
      }
      // console.log("fornaData", formData);
      setIsLoading(true);
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postRoomManagement", {
          ...formData,
          collegeId,
        })
        .then((res) => {
          setAddNew({});
          setFormData(initialFormData);
          setRefresh(!refresh);
          setIsLoading(false);
          console.log("res", res);

        })
        .catch((err: any) => {
          console.log("errrrrrrrre", err);

          setIsLoading(false);
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };
  const updateRoom = async (id: any) => {
    try {
      setIsLoading(true);
      console.log("editItem", id);

      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postRoomManagement", {
          ...formData,
          _id: id,
        })
        .then(() => {
          setRefresh(!refresh);
          setIsLoading(false);
          setFormData(initialFormData);
          setEditItem(null);
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.error(err);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };
  const deleteRoom = async (id: any) => {
    try {
      setIsLoading(true);
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/examProcess/postRoomManagement", {
          mode: "delete",
          _id: id,
        })
        .then(() => {
          setRefresh(!refresh);
          setIsLoading(false);

        })
        .catch((err: any) => {
          console.error(err);
          setIsLoading(false);
        });
    } catch (err) {
      throw new Error("API Error");
    }
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    postRoomManagement();
  };
  const getRoomData = async () => {
    setIsLoading(true);
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .get("/college/examProcess/getRoomManagement", {
          params: { collegeId },
        })
        .then((res: any) => {
          console.log("roomData", res.data);
          setRoomData(res.data.list);
          setIsLoading(false);
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
        });
    } catch (err) {
      throw new Error("API Error Bundle Data");
    }
  };

  useEffect(() => {
    getRoomData();
  }, [refresh]);

  const handleEditClick = (item: any) => {
    setEditItem(item);
    setFormData(item);
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setIsModalOpen(false);
  };
  const handleCancel = async () => {
    setFormData(initialFormData);
    setEditItem(null);
  };

  const typeOfRoomArray = [
    "Room",
    "Conference",
    "ITC enabled",
    "Room",
    "Internet Enabled",
  ];

  return (
    <>
      <Main title="Room Management" width="100vw" height="100vw">
        <Header>
          <div className=" flex w-50%">
          </div>
          <div className="flex">
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          {values && (
            <div className="my-2 font-bold">
              <div className="w-full h-auto mb-2 pb-2 bg-gray-200 rounded flex">
                <div className="">
                  <div className="md:flex h-auto sm:h-[100%] gap-2 pt-6">
                    <div className="flex pl-4">
                      <div className=" gap-2 content-center">
                        <label htmlFor="search text-lg fw-bold">Room No.</label>
                        <input
                          type="number"
                          id="search"
                          className="w-[140px] mb-2 transparent border rounded shadow "
                          value={formData.roomNo}
                          name="roomNo"
                          onChange={handleFormChange}
                        />
                      </div>
                      <div className="px-2 gap-2 content-center">
                        <label htmlFor="search text-lg fw-bold">
                          No of Benches
                        </label>
                        <input
                          type="number"
                          id="search"
                          className="w-[140px] mb-2 transparent border rounded shadow "
                          value={formData.noOfBenches}
                          name="noOfBenches"
                          onChange={handleFormChange}
                        />
                      </div>
                      <div className="px-2 gap-2 content-center">
                        <label htmlFor="search text-lg fw-bold">
                          No of Seats
                        </label>
                        <input
                          type="number"
                          id="search"
                          className="w-[140px] mb-2 transparent border rounded shadow "
                          value={formData.noOfSeatsOnRoom}
                          name="noOfSeatsOnRoom"
                          onChange={handleFormChange}
                        />
                      </div>
                    </div>
                    <div className="flex sm:pl-4 ">
                      <div className="px-2 gap-2 content-center">
                        <label htmlFor="search text-lg fw-bold">
                          Block Name
                        </label>
                        <input
                          type="text"
                          id="search"
                          className="w-[140px] mb-2 transparent border rounded shadow "
                          value={formData.nameOfBlock}
                          name="nameOfBlock"
                          onChange={handleFormChange}
                        />
                      </div>
                      <div className="px-2 gap-2 content-center">
                        <label htmlFor="search text-lg fw-bold">
                          Type of Room
                        </label>
                        <select
                          // type="number"
                          id="search"
                          className="w-[140px] mb-2 transparent border rounded shadow h-7"
                          value={formData.typeOfRoom}
                          name="typeOfRoom"
                          onChange={handleFormChange}
                          required={true}
                        >
                          <option value="">Choose</option>
                          {typeOfRoomArray.map((roomType) => (
                            <option value={roomType}>{roomType}</option>
                          ))}
                        </select>
                      </div>
                      <div className="px-2 gap-2 content-center">
                        <div className="flex flex-column gap-2">
                          <button
                            className="bg-red-200 w-full md:w-24 rounded"
                            onClick={handleCancel}
                          >
                            Reset
                          </button>
                          {editItem !== null ? (
                            <button
                              disabled={isLoading}
                              className="bg-green-700 w-full md:w-24 rounded text-light"
                              onClick={() => updateRoom(editItem._id)}
                            >
                              UPDATE
                            </button>
                          ) : (
                            <button
                              disabled={isLoading}
                              className="bg-green-700 w-full md:w-24 rounded text-light"
                              onClick={handleFormSubmit}
                            >
                              ADD
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="p-4 flex items-center gap-4">
              <label htmlFor="description">Description</label>
              <input className="border w-[480px] h-8 shadow rounded" name="description" id="description" value={formData.description} onChange={handleFormChange}></input>
               </div>
               <div className=" flex px-4 gap-8 md:ml-[350px]">
               <button className="bg-red-200 w-full md:w-24 rounded" onClick={()=>setFormData(initialFormData)}>Cancel</button>
               <button disabled={isLoading || formData.teacherId!==""} className="bg-green-700 w-full md:w-24 rounded text-light" onClick={handleFormSubmit}>Assign</button>
               </div> */}
                </div>
                <div className="m-2 p-2"></div>
              </div>
            </div>
          )}
          {/* {bundleData.length>0 &&
        <div>
          <table className="table-fixed bg-white border">
  <thead>
    <tr>
      <th>Song</th>
      <th>Artist</th>
      <th>Year</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>The Sliding Mr. Bones (Next Stop, Pottersville)</td>
      <td>Malcolm Lockyer</td>
      <td>1961</td>
    </tr>
    </tbody>
    </table>
        </div>
        } */}
          {roomData.length > 0 && (
            <div className="my-2" ref={ref}>
              <Table
                width="100%"
                innerWidth={[
                  "100px",
                  "100px",
                  "100px",
                  "100px",
                  "100px",
                  "200px",
                ]}
              >
                <Thead>
                  <Tr>
                    <Th position={1}>SN</Th>
                    <Th position={1}>Room No.</Th>
                    <Th position={1}>No. of Benches</Th>
                    <Th position={1}>No. of Seats</Th>
                    <Th position={1}>Block Name</Th>
                    <Th position={1}>Room Type</Th>
                    <Th position={2}>Edit / Delete</Th>
                  </Tr>
                </Thead>
                <Tbody height="auto">
                  {roomData?.map((item: any, i: number) => (
                    <Tr key={item._id}>
                      <Td index={i} position={1}>
                        {i + 1}
                      </Td>
                      <Td index={i} position={1}>
                        {item?.roomNo}
                      </Td>
                      <Td index={i} position={1}>
                        {item?.noOfBenches}
                      </Td>
                      <Td index={i} position={1}>
                        {item?.noOfSeatsOnRoom}
                      </Td>
                      <Td index={i} position={1}>
                        {item?.nameOfBlock}
                      </Td>
                      <Td index={i} position={1}>
                        {item?.typeOfRoom}
                      </Td>

                      <Td index={i} position={2}>
                        <div className="flex gap-1">
                          <button
                            className="rounded bg-blue-400"
                            type="submit"
                            onClick={() => handleEditClick(item)}
                          >
                            Edit
                          </button>
                          <button
                            type="submit"
                            className="rounded bg-red-600 text-white"
                            onClick={() => {
                              setMode("delete");
                              deleteRoom(item._id);
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          )}
        </Body>
      </Main>
      
    </>
  );
}

export default RoomManagement;
