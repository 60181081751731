import React, { useState } from "react";
import { useQuery } from "react-query";
import "./DepClassListScreen.css";
import CommonListItem from "../../components/CommonListItem";
import LoadingAnimation from "../../../../../../components/LoadingAnimation";
import { getAxiosInstance } from "../../../../../../utils/axiosInstance";
import Semester from "./Semester";
import Button from "../../../../../../NecttosComp/Button/Button";

const getAllClassesFromDepartments = (departmentId) => async () => {
  const instance = getAxiosInstance();
  try {
    const resp = await instance.get("/college/classes/getClassesFromDepartment", {
      params: { departmentId },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data?.classListFromDepartment;
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const DepClassListScreen = ({ departmentId, departmentName, deptClassList, onClose }) => {
  const [teacher, setTeacher] = useState();
  const { data: classData, isFetching, error } = useQuery("getAllClassesFromDepartments", getAllClassesFromDepartments(departmentId));

  const renderItem = (item) => <CommonListItem key={item.classId} name={item?.className} userName={item?.tutorName} userPhoto={item?.tutorPhoto} progress={item?.progress} onPress={() => setTeacher({ classId: item.classId, className: item.className })} />;

  if (isFetching) return <LoadingAnimation />;
  if (error) return <div>Error loading classes: {error.message}</div>;

  return (
    <div className="w-full">
      {teacher ? (
        <Semester classId={teacher.classId} className={teacher.className} goBack={() => setTeacher(null)} />
      ) : (
        <div className="flex flex-col h-screen">
          <div className="flex self-end">
            <Button type="fetch" onClick={onClose}>
              Back
            </Button>
          </div>

          <div className="flex flex-col p-3">{classData?.length ? <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">{classData.map(renderItem)}</ul> : <h1 className="text-center">No classes found</h1>}</div>
        </div>
      )}
    </div>
  );
};

export default DepClassListScreen;
