import React, { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getAllMinor, getNewClasses } from '../../pages/college/ApiServices';
import { getProfile } from '../../queryHooks';

const fetchWithToken = async (url: string, params: any) => {
	const instance = await getAxiosTokenInstance();
	const resp = await instance.get(url, { params });
	return resp.data?.list || resp.data;
};

const getAllMinorsSelected = (collegeId: any, classId: any, printable: boolean) => () => fetchWithToken('/college/action/getAllMinorsSelected', { collegeId, classId, printable });

const getAdmissionDetails = (collegeId: any) => () => fetchWithToken('/college/fyugp/getAllotementStatus', { collegeId });

const Modal = ({ isVisible, onClose, student, minorData, values, minorData2, rcGroup, handleCardClick2 }: any) => {
	const DSC_B = useMemo(() => minorData?.filter((x: any) => x.type === 'DSC_B' && x.semester === 'Semester 1'), [minorData]);
	const DSC_C = useMemo(() => minorData?.filter((x: any) => x.type === 'DSC_C' && x.semester === 'Semester 1'), [minorData]);
	const MDC = useMemo(() => minorData?.filter((x: any) => x.type === 'MDC' && x.semester === 'Semester 1'), [minorData]);

	const allotments = useMemo(
		() => [
			{ ...minorData2?.find((x: any) => x.type === 'DSC_B_A'), type: 'DSC_B' },
			{ ...minorData2?.find((x: any) => x.type === 'DSC_C_A'), type: 'DSC_C' },
			{ ...minorData2?.find((x: any) => x.type === 'MDC_A'), type: 'MDC' },
		],
		[minorData2],
	);

	const selectedSubjects = allotments?.map((item) => item.unitId);

	const [activeTab, setActiveTab] = useState('');
	console.log({ rcGroup });

	if (!isVisible) return null;

	const renderSubjectList = (data: any[]) => (
		<ul>
			<div className='flex flex-wrap gap-6 justify-center'>
				{data?.map((item: any, index: number) => (
					<div
						key={index}
						onClick={() => {
							handleCardClick2(item, activeTab);
						}}
						className='border rounded-lg p-4 shadow-md flex flex-col items-center w-[300px] hover:shadow-lg transition-shadow duration-300 ease-in-out cursor-pointer'
						style={{ backgroundColor: selectedSubjects.includes(item._id) ? 'green' : 'white' }}>
						<h1>{item?.priority}</h1>
						<h3 className='text-lg font-semibold text-center'>{item.subjectNameOnly}</h3>
						<p className='text-gray-600 text-center'>{item.deptname}</p>
						<h5 style={{ color: 'red' }}>Vacancy</h5>
						<h1 style={{ color: 'red' }}>{item?.inBalance - item?.alloted}</h1>
						<p className='text-gray-600 text-center'>OPEN LAST INDEX : {item?.OPEN?.inLastIndex?.toFixed(3)}</p>
						<p className='text-gray-600 text-center'>
							{rcGroup} LAST INDEX : {item?.[rcGroup]?.inLastIndex?.toFixed(3)}
						</p>
					</div>
				))}
			</div>
		</ul>
	);

	return (
		<div className='fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50'>
			<div className='bg-white p-6 rounded-lg w-[99%] h-[99%] shadow-lg'>
				<div className='flex justify-end mt-4'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>

				<h2 className='text-xl font-semibold mb-4'>{student?.name}</h2>
				<p className='text-gray-600 mb-2'>Cap ID: {student?.capId}</p>
				<p className='text-gray-600 mb-2'>Mark: {student?.rcMark}</p>
				<p className='text-gray-600 mb-2'>Group: {student?.rcGroup}</p>

				<div className='flex space-x-4 mb-4'>
					{['MDC', 'DSC_B', 'DSC_C'].map((tab) => (
						<Button key={tab} type='save' onClick={() => setActiveTab(tab)}>
							{tab}
						</Button>
					))}
				</div>

				<div className='subject-list'>
					{activeTab === 'MDC' && (
						<>
							<h3 className='text-lg font-semibold mb-2'>MDC Subjects</h3>
							{renderSubjectList(MDC)}
						</>
					)}
					{activeTab === 'DSC_B' && (
						<>
							<h3 className='text-lg font-semibold mb-2'>DSC_B Subjects</h3>
							{renderSubjectList(DSC_B)}
						</>
					)}
					{activeTab === 'DSC_C' && (
						<>
							<h3 className='text-lg font-semibold mb-2'>DSC_C Subjects</h3>
							{renderSubjectList(DSC_C)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const Modal2 = ({ isVisible, onClose, subject, student2, collegeId, data, activeTab, selectedClass }: any) => {
	const [admitResponse, setAdmitResponse] = useState<any>(null);
	console.log({ subject });

	const admitStudent = async (keyitem: any) => {
		try {
			const postData = { student2, collegeId, keyitem, subject, activeTab, selectedClass };
			const instance = await getAxiosTokenInstance();
			const result = await instance.post('/college/fyugp/sportAdmiting', postData);
			if (data.statusCode !== 200) {
				throw new Error(data.message);
			}
			onClose();
			setAdmitResponse(result);
		} catch (error) {
			console.error('Error in admitStudent function', error);
		}
	};

	const handleAdmitConfirmation = (keyitem: any) => {
		if (window.confirm('Are you sure you want to admit this student?')) {
			admitStudent(keyitem);
		}
	};

	if (!isVisible) return null;

	return (
		<div className='fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center z-50'>
			<div className='bg-white p-6 rounded-lg w-[89%] h-[69%] shadow-lg'>
				<div className='flex justify-end mt-4'>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
				<h2 className='text-xl font-semibold mb-4'>{student2?.name}</h2>
				<p className='text-gray-600 mb-2'>Cap ID: {student2?.capId}</p>
				<p className='text-gray-600 mb-2'>Mark: {student2?.rcMark}</p>
				<p className='text-gray-600 mb-2'>Group: {student2?.rcGroup}</p>

				{admitResponse && <p className='text-green-600'>Admit Status: {admitResponse?.status}</p>}

				<ul>
					<div className='flex flex-wrap gap-6 justify-center'>
						{data?.keys?.map((keyitem: any, index: any) =>
							subject[keyitem]?.inTake ? (
								<div
									key={index}
									className='rounded-lg bg-white shadow p-6'
									onClick={() => {
										handleAdmitConfirmation(keyitem);
									}}>
									<div className='text-xl font-semibold text-gray-800 mb-2'>
										{keyitem}: {subject[keyitem]?.inTake || 0}
									</div>
									<div className='text-xl font-semibold text-green-600 mb-2'>Seats Confirmed: {subject[keyitem]?.inAdmitted ?? 0}</div>
									<div className='text-xl font-semibold text-green-600 mb-2'>Seats alloted: {subject[keyitem]?.alloted ?? 0}</div>
									<div className='text-sm font-semibold text-red-600'>Vacancy: {parseInt(subject?.[keyitem]?.inBalance || 0, 10)}</div>
									<div className='text-xs font-semibold text-gray-500 mt-2'>Last Index: {subject[keyitem]?.inLastIndex?.toFixed(3) || '--'}</div>
								</div>
							) : null,
						)}
					</div>
				</ul>
			</div>
		</div>
	);
};

function SportAdmission({ onClose }: any) {
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);

	const [selectedClass, setSelectClass] = useState('');
	const [printable, setPrintable] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);
	const [isModalVisible2, setModalVisible2] = useState(false);
	const [selectedStudent, setSelectedStudent] = useState<any>(null);
	const [selectedSubjects, setSubjects] = useState<any>(null);

	const { data: tableData = [] } = useQuery(['getAllMinorsSelected', collegeId, selectedClass, printable], getAllMinorsSelected(collegeId, selectedClass, printable));

	const { data: classes } = useQuery('getNewClasses', getNewClasses(collegeId));

	const { data: admissionDetails } = useQuery(['getAdmissionDetails', collegeId], getAdmissionDetails(collegeId), { enabled: !!collegeId });

	const userId = selectedStudent?._id;

	const { data: values } = useQuery([`profileData`, collegeId, userId, selectedStudent?._id], getProfile);

	const { data: minorData2 = [] } = useQuery(['getAttllMinor', selectedStudent?._id, selectedClass, collegeId], getAllMinor(collegeId, selectedStudent?._id, selectedClass), { enabled: !!selectedStudent });

	const minorData = admissionDetails?.admissionDetails;

	['dsc_b', 'dsc_c', 'mdc'].forEach((prefix) => {
		Array.from({ length: 30 }, (_, i) => i + 1).forEach((priority) => {
			minorData?.forEach((subject: any) => {
				const key = `${prefix}_${priority}`;
				if (values?.[key] === subject._id) {
					subject.sortKey = priority;
					subject.priority = priority;
				}
			});
		});
	});

	minorData?.sort((a: any, b: any) => (a?.sortKey || 999) - (b?.sortKey || 999));

	console.log({ minorData });

	const handleCardClick = (student: any) => {
		setSelectedStudent(student);
		setModalVisible(true);
	};

	const handleCardClick2 = (student: any, activeTab: any) => {
		setSubjects({ student, activeTab });
		setModalVisible2(true);
	};

	return (
		<>
			<Main title='Student Selected Subject List FYUGP' width='100%' height='100%'>
				<Header>
					<div className='flex justify-between items-center mb-4'>
						<Input fieldName='Filter by Course' state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay='className' optionKey='_id' options={classes?.list} type='drop' width='15vw' />
						<div className='flex space-x-4'>
							<Button type={printable ? 'search' : 'update'} onClick={() => setPrintable(!printable)}>
								{printable ? 'Hide Printable' : 'Show Printable'}
							</Button>
							<Button type='print' tableRef={ref.current}>
								Print
							</Button>
							<Button type='excel' tableRef={ref.current}>
								Excel
							</Button>
							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<div className='flex flex-wrap gap-6 justify-center'>
							{tableData?.map((item: any, index: number) => (
								<div key={index} className='border rounded-lg p-4 bg-white shadow-md flex flex-col items-center w-[200px] hover:shadow-lg transition-shadow duration-300 ease-in-out cursor-pointer' onClick={() => handleCardClick(item)}>
									<div className='w-24 h-24 mb-4'>
										<img src={item.photoURL} alt={`${item.name}'s photo`} className='rounded-full w-full h-full object-cover' loading='lazy' />
									</div>
									<h3 className='text-lg font-semibold text-center'>{item.name}</h3>
									<p className='text-gray-600 text-center'>{item.capId}</p>
								</div>
							))}
						</div>
					</div>
				</Body>

				<Modal isVisible={isModalVisible} onClose={() => setModalVisible(false)} student={selectedStudent} minorData={minorData} minorData2={minorData2} handleCardClick2={handleCardClick2} values={values} rcGroup={values?.rcGroup} />
				<Modal2 isVisible={isModalVisible2} onClose={() => setModalVisible2(false)} selectedClass={selectedClass} subject={selectedSubjects?.student} activeTab={selectedSubjects?.activeTab} collegeId={collegeId} student2={selectedStudent} values={values} rcGroup={values?.rcGroup} data={admissionDetails} />
			</Main>
		</>
	);
}

export default SportAdmission;
