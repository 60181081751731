import React, { useContext, useState } from "react";
import classNames from "./exportModal.module.scss";

import { useAllClasses } from "../../../../queryHooks";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useMutation } from "react-query";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import CustomDropDownKey from "../../../../myComp/CustomDropDownKey";
import { Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";

export const exportPdfExam = async (olddata) => {
  const instance = await getAxiosTokenInstance();
  let data = {
    collegeId: olddata.collegeId,
    classId: olddata.classId,
    type: olddata.type,
  };
  let url = [`/college/reportGeneration/checkList`];
  try {
    const resp = await instance.post(...url, data, {
      responseType: "blob",
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const RankList = ({ onClose }) => {
  const exportPdfMutationExam = useMutation(exportPdfExam);
  const [loading, setLoading] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const allClasses = useAllClasses({ needAll: true, classOnly: true });
  const allSemester = [{ _id: "Rank List", name: "Rank List" }];
  const sortBy = [
    { _id: "name", name: "Name of the student" },
    { _id: "rollNo", name: "Attendance / Roll Number" },
    { _id: "admisionNo", name: "College Admision Number" },
    { _id: "hallTicketNo", name: "Halticket / Registeration Number" },
    { _id: "capId", name: "CAP/Application Number" },
  ];
  const onInternalMark = async () => {
    setLoading(true);
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        collegeId,
        classId: state.classId,
        type: state.type,
      };

      link.download = "Internal Mark";
      const pdfData = await exportPdfMutationExam.mutateAsync(postData);
      link.href = URL.createObjectURL(new Blob([pdfData], { type: "application/pdf" }));
      link.click();
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
    }
  };

  const [state, setState] = useState({});

  return (
    <Main width="auto" height="auto" className={classNames.container}>
      <Button type="close" onClick={onClose}>
        Close
      </Button>
      <div className={`${classNames.listContainer} max-w-md mx-auto p-4 bg-white shadow-md rounded-lg`}>
        <h2 className={`${classNames.heading} text-xl font-bold text-gray-800 mb-4`}>DOWNLOAD Rank List</h2>
        {loading ? (
          <div className="text-center py-4">
            <label className={`${classNames.notice} text-gray-500`}>Your request is being processed… waiting for preparation…</label>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <CustomDropDownKey keyData="classId" keyObject="className" keyReturn="_id" titleData="Select Class" dropValues={allClasses?.data || []} stateData={state} setStateData={setState} className="w-full max-w-xs mx-auto bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500" />
            </div>
            <div>
              <CustomDropDownKey keyData="type" keyObject="name" keyReturn="_id" titleData="Select Type" dropValues={allSemester} stateData={state} setStateData={setState} className="w-full max-w-xs mx-auto bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500" />
            </div>
          </>
        )}
      </div>
      <Button type="download" onClick={onInternalMark}>
        Download
      </Button>
    </Main>
  );
};
