import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import JobDetailsPage from '../../components/Website/job-details';
import FooterBar from '../../components/Website/footerBar';

const Job_details = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="necttos Jobs" />
        <JobDetailsPage customclass="pd-top-112" />
        <FooterBar />
    </div>
}

export default Job_details

