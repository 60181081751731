import React, { useContext, useEffect, useState } from "react";
import "./C2_1.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { AcademicYears, naacFetchData, naacExcelGenerator } from "../../ApiServices";
import { Body, Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../NecttosComp/Table/Table";

function C2_7({ onClose }) {
  const [values, setValues] = useState();
  const { collegeId } = useContext(FirebaseContext);
  const [academicYear, setAcademicYear] = useState("2022-23");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    naacFetchData({
      data: { collegeId, academicYear },
      setLoading,
      setValues,
      url: "/college/NAAC/getNAAC_C2_7",
    });
  }, []);
  return (
    <>
      {loading ? (
        <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]">
          <LoadingAnimation />
        </div>
      ) : null}
      <Main height="80vh" width="80vw" title="">
        <Header>
          <AcademicYears setAcademicYear={setAcademicYear} />
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </Header>
        <Body>
          <h1 className="text-xl">2.4.2 Number of full time teacher with Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt. during year {"(consider only highest degree of count)"}</h1>
          <h1 className="text-xl">3.1.2 Number of teachers recongnized as research guides {"(latest completed academic year)"}</h1>
          <h1 className="text-xl">3.3.1 Number of Ph.Ds registered per eligible techer during the year</h1>
          <Table width="100%" innerWidth={["16%","21%","17%","10%","20%","6.5%","6.5%"]}>
            <Thead>
              <Tr>
                <Th position={0} fontSize="12px">Name of the Full-time teacher with Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt</Th>
                <Th position={1} fontSize="12px">Qualification {"(Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt)"} and Year of obtaining</Th>
                <Th position={2} fontSize="12px">Whether recognised as research Guide for Ph.D./D.M/M.Ch./D.N.B Superspeciality/D.Sc./D.Lit</Th>
                <Th position={3} fontSize="12px">Year of Recognition as Research Guide</Th>
                <Th position={4} fontSize="12px">Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution</Th>
                <Th position={5} fontSize="12px">Name of the scholar</Th>
                <Th position={6} fontSize="12px">Title of the thesis for scholar</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td position={0}>1</Td>
                <Td position={1}>1</Td>
                <Td position={2}>1</Td>
                <Td position={3}>1</Td>
                <Td position={4}>1</Td>
                <Td position={5}>1</Td>
                <Td position={6}>1</Td>
              </Tr>
            </Tbody>
          </Table>
        </Body>
      </Main>
      {/* <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        <table className="table">
          <thead>
            
            <tr>
              <th className="w-1/4">Name of the Full-time teacher with Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt</th>
              <th className="w-1/4">Qualification {"(Ph. D. /D.M / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt)"} and Year of obtaining</th>
              <th className="w-1/4">Whether recognised as research Guide for Ph.D./D.M/M.Ch./D.N.B Superspeciality/D.Sc./D.Lit</th>
              <th className="w-1/4">Year of Recognition as Research Guide</th>
              <th className="w-1/4">Is the teacher still serving the institution/If not last year of the service of Faculty to the Institution</th>
              <th className="w-1/4">Name of the scholar</th>
              <th className="w-1/4">Title of the thesis for scholar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <th>2</th>
              <th>2</th>
              <th>2</th>
              <th>1</th>
              <th>2</th>
              <th>2</th>
            </tr>
          </tbody>
        </table>
      </div> */}
    </>
  );
}

export default C2_7;
