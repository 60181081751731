import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { initializePayment } from "../../pages/college/ApiServices";
import Cookies from "js-cookie";

interface RegisterProps {
  setApply: (val: boolean) => void;
  applicationOpen: boolean;
  collegeData: {
    whatsapp: any;
    collegeName: string;
    logo: string;
    collegeTour: string;
    phoneNumber: string;
  };
  initCollegeId: string;
  dataData?: any[];
}
interface Amount {
  amount: number;
  currencyCode: string;
}
const AgreementModal = ({ isOpen, onClose, title, children }: any) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
      <div className="bg-white p-6 rounded-lg max-w-lg w-full space-y-4 overflow-hidden">
        <h2 className="text-xl font-bold text-center">{title}</h2>
        <div className="overflow-auto max-h-[400px] p-2">{children}</div>
        <div className="flex justify-between items-center mt-4">
          <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded" onClick={onClose}>
            Cancel
          </button>
          <button
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              onClose();
            }}
          >
            Agree & Sign
          </button>
        </div>
      </div>
    </div>
  );
};

const Controlls: React.FC<RegisterProps> = ({ initCollegeId, collegeData, applicationOpen = false, setApply, dataData }) => {
  const [successLogin, setLogin] = useState<boolean>(false);
  const [internet, setInternet] = useState<boolean>(navigator.onLine);
  const [safetyModalOpen, setSafetyModalOpen] = useState(false);
  const [drugFreeModalOpen, setDrugFreeModalOpen] = useState(false);

  const handlePayment = async () => {
    const amount: Amount = {
      amount: 1000,
      currencyCode: "INR",
    };
    const pageInitialized = window.location.pathname;
    const result = await initializePayment(amount, pageInitialized, initCollegeId);
    window.location.replace(result);
  };

  useEffect(() => {
    const goOnline = () => {
      setInternet(true);
    };
    const goOffline = () => {
      setInternet(false);
    };
    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);
    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  useEffect(() => {
    const token = Cookies.get("userToken");
    if (token) setLogin(true);
    else setLogin(false);
  }, []);
  const data = [
    {
      title: "Fee Payment",
      description: "Secure your spot by completing the fee payment with ease and convenience. 300 Rupees",
      action: () => handlePayment(),
      date: "Open Soon",
      buttonText: "Pay Now",
      disabled: !applicationOpen,
    },
    {
      title: "Start Application",
      description: "Embark on a journey towards academic excellence. Begin your application process today!",
      action: () => setApply(true),
      buttonText: "Apply Now",
      date: "Open Soon",
      disabled: !applicationOpen,
    },

    {
      title: "Commitment to Safety",
      description: "Join our zero-tolerance pledge against ragging.",
      action: () => setSafetyModalOpen(true),
      buttonText: "Pledge Now",
    },
    {
      title: "Drug-Free Campus Pledge",
      description: "Support our commitment to a healthy, drug-free campus.",
      action: () => setDrugFreeModalOpen(true),
      buttonText: "Support the Cause",
    },
    {
      title: "Merit Admission",
      description: "For exceptional achievers, explore the merit-based admission process to reward your academic prowess.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Sports Admission",
      description: "If you're a sporting talent, see how our sports admission can kickstart both your athletic and academic futures.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Management Admission",
      description: "Future leaders and thinkers, start your management education with our specialized admission form.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Community Admission",
      description: "Engage with our inclusive community. Begin your application process dedicated to community service and engagement.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Financial Guide",
      description: "Explore your financial options, including scholarships and aid packages, tailored to support your educational journey.",
      action: () => {},
      buttonText: "Learn More",
    },
    {
      title: "Speak to Advisors",
      description: "Connect with our advisors for personalized guidance on your educational path and admission process.",
      action: () => {},
      buttonText: "Get Advice",
    },
    {
      title: "Brochure Download",
      description: "Get an in-depth look at our academic offerings and the vibrant campus life awaiting you.",
      action: () => {},
      buttonText: "Download Brochure",
    },
    {
      title: "Prospectus Insights",
      description: "Gain comprehensive insights into our curricula and discover the right program for you.",
      action: () => {},
      buttonText: "Get Prospectus",
    },
  ];

  const data2 = [
    {
      title: "Fee Payment",
      description: "Once you complete your application, click here to securely pay your application fee and reserve your spot at St. Xavier's College! ",
      action: () => handlePayment(),
      date: "Open Soon",
      buttonText: "Pay Now",
      disabled: !applicationOpen,
    },
    {
      title: "Start Your Application",
      description: "Purpose: Begin your journey towards academic excellence at St. Xavier's College. Click here to start your application process today!",
      action: () => setApply(true),
      buttonText: "Apply Now",
      date: "Open Soon",
      disabled: !applicationOpen,
    },

    {
      title: "Rag Free Campus",
      description: "Together, we can create a supportive and inclusive environment. Click here to take the anti-ragging pledge and contribute to a Rag-Free Campus.",
      action: () => setSafetyModalOpen(true),
      buttonText: "Pledge Now",
    },
    {
      title: "Pledge Drug-Free",
      description: "Join our Drug-Free Campus initiative by taking the pledge! Together, let's create a healthy and supportive environment for all students.",
      action: () => setDrugFreeModalOpen(true),
      buttonText: "Support the Cause",
    },
    {
      title: "Merit Admission",
      description: "Exceptional achievements open doors. Explore our merit-based admission process and discover your academic future at St. Xavier's.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Sports/Arts Quota",
      description: "Don't choose between academics and your passion! Apply for our Sports/Cultural Quota and explore a program that celebrates both your academic and artistic talents.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Management Admission",
      description: "Don't miss out! Apply for Management Admission and secure your seat at St. Xavier's College. This option allows you to confirm your place before merit admissions are announced.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Community Admission",
      description: "As a member of the Latin catholic other than Anglo Indian community, you have the privilege to apply early and secure your seat at St. Xavier's College. Click here to learn more about our Community Application process and apply for any desired programme.",
      action: () => {},
      date: "Open Soon",
      buttonText: "Download Admit Card",
      disabled: !applicationOpen,
    },
    {
      title: "Financial Guide",
      description: "Don't let finances hold you back! Click here to access our Financial Guide and explore scholarships, grants, and financial aid options that can support your educational journey.",
      action: () => {},
      buttonText: "Learn More",
    },
    {
      title: "Connect to Advisors",
      description: "Have questions? Unsure about your options? Connect with our experienced advisors for personalized guidance on your honours programmes",
      action: () => {},
      buttonText: "Get Advice",
    },
    {
      title: "Get the Brochure",
      description: "Get an in-depth look at all St. Xavier's College has to offer! Download our brochure and discover our academic programs, vibrant campus life, and more.",
      action: () => {},
      buttonText: "Download Brochure",
    },
    {
      title: "Prospectus",
      description: "Discover your academic path! Download our prospectus and gain comprehensive insights into our diverse curricula and programs. Find the perfect fit for your academic goals and aspirations.",
      action: () => {},
      buttonText: "Get Prospectus",
    },
  ];

  return !internet ? (
    <div className="text-gray-900 bg-gradient-to-r from-blue-500 to-blue-300 p-5 rounded-lg m-5 text-center shadow-md transition-all ease-in-out duration-300 font-nunito font-sans" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
      <strong className="block text-lg mb-2">You are offline.</strong>
      Please check your internet connection.
      <br />
      Thank you for your attempt.
      <br />
      Sincerely,
      <br />
      Nirmala College Muvatuppuzha!
    </div>
  ) : (
    <div className="lg:w-[70vw] ">
      <div className="w-full max-w-7xl mx-auto p-6  ">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="col-span-full bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
            <p className="font-bold">Information</p>
            <p>
              Your account has been successfully registered. Waiting for open fill-up application. Please contact to feel free through WhatsApp for further queries{" "}
              <a href={`https://wa.me/${collegeData?.whatsapp}`} className="underline">
                here
              </a>
              .
            </p>
          </div>
          <AgreementModal isOpen={safetyModalOpen} onClose={() => setSafetyModalOpen(false)} title="Commitment to Safety Agreement">
            <div className="text-sm text-gray-800">
              <h3 className="font-bold text-lg mb-3">Anti-Ragging Agreement for {collegeData?.collegeName}</h3>
              <h4 className="font-semibold">Preamble</h4>
              <p>This document serves as a binding agreement between {collegeData?.collegeName}, herein referred to as "the College", and all enrolled students, to actively prevent and combat the menace of ragging on and off the campus premises. Ragging is strictly prohibited and considered a criminal offense as per the Indian Penal Code and the regulations set by the University Grants Commission.</p>

              <h4 className="font-semibold">Definition of Ragging</h4>
              <p>Ragging constitutes one or more of any of the following acts:</p>

              <ul className="list-disc ml-5 mb-3">
                <li>Any conduct by any student or students whether by words spoken or written or by an act which has the effect of teasing, treating, or handling with rudeness a fresher or any other student.</li>
                <li>Indulging in rowdy or undisciplined activities by any student or students which causes or is likely to cause annoyance, hardship, physical or psychological harm or to raise fear or apprehension thereof in any fresher or any other student.</li>
                <li>Asking any student to do any act which such student will not in the ordinary course do and which has the effect of causing or generating a sense of shame, or torment or embarrassment so as to adversely affect the physique or psyche of such fresher or any other student.</li>
                <li>Any act by a senior student that prevents, disrupts, or disturbs the regular academic activity of any other student or a fresher.</li>
                <li>Exploiting the services of a fresher or any other student for completing the academic tasks assigned to an individual or a group of seniors.</li>
                <li>Any act of financial extortion or forceful expenditure burden put on a fresher or any other student by students.</li>
                <li>Any act of physical abuse including all variants of it: sexual abuse, homosexual assaults, stripping, forcing obscene and lewd acts, gestures, causing bodily harm or any other danger to health or person.</li>
                <li>Any act or abuse by spoken words, emails, posts, public insults which would also include deriving perverted pleasure, vicarious or sadistic thrill from actively or passively participating in the discomfiture to fresher or any other student.</li>
                <li>Any act that affects the mental health and self-confidence of a fresher or any other student.</li>
              </ul>

              <h4 className="font-semibold">Measures and Actions</h4>
              <ul className="list-disc ml-5 mb-3">
                <li>
                  <strong>Awareness Programs:</strong> The college will conduct orientation programs for freshers and training sessions for seniors to foster an understanding of the seriousness of ragging and its consequences.
                </li>
                <li>
                  <strong>Monitoring Committees:</strong> The college shall form Anti-Ragging Committees and Squads which will work under the supervision of the Anti-Ragging Cell. These committees are empowered to take immediate action against any reported or noticed ragging incidents.
                </li>
                <li>
                  <strong>Counseling Services:</strong> Both victims and perpetrators of ragging will have access to psychological counseling to reform behavior and heal from traumatic experiences.
                </li>
                <li>
                  <strong>Strict Penalties:</strong> Any student found guilty of participating in or abetting ragging will face strict penalties which could include suspension from attending classes and academic privileges, and more severe measures as detailed earlier.
                </li>
              </ul>

              <h4 className="font-semibold">Declaration by the Student</h4>
              <p>I, [Student’s Name], a student of [Program and Batch], hereby acknowledge and affirm that I have read and understood the contents of this Anti-Ragging Agreement. I agree to abide by the rules and regulations against ragging established by [College Name] and the appropriate legal authorities. I understand that failure to adhere to this policy not only endangers my status as a student but also includes legal repercussions under criminal law.</p>
              <div className="mt-4">
                <label className="font-semibold">Student's Signature:</label>
                <input type="text" className="form-input mt-1 block w-full border-gray-300 shadow-sm sm:text-sm rounded-md" />
                <label className="font-semibold mt-4">Date:</label>
                <input type="date" className="form-input mt-1 block w-full border-gray-300 shadow-sm sm:text-sm rounded-md" />
              </div>
            </div>
          </AgreementModal>

          <AgreementModal isOpen={drugFreeModalOpen} onClose={() => setDrugFreeModalOpen(false)} title="Drug-Free Campus Pledge">
            <div className="text-sm text-gray-800">
              <h3 className="font-bold text-lg mb-3">Drug-Free Campus Pledge for {collegeData?.collegeName}</h3>
              <h4 className="font-semibold">Preamble</h4>
              <p>This document serves as a binding agreement between {collegeData?.collegeName}, herein referred to as "the College", and all enrolled students, to actively prevent and combat the use, possession, and distribution of drugs on and off the campus premises. The use of illicit drugs is strictly prohibited and considered a criminal offense as per local, state, and federal laws.</p>

              <h4 className="font-semibold">Definition of Prohibited Conduct</h4>
              <p>Prohibited conduct under this policy includes, but is not limited to, the following acts:</p>
              <ul className="list-disc ml-5 mb-3">
                <li>Possession, use, manufacture, or distribution of illegal drugs or controlled substances, including marijuana, heroin, cocaine, methamphetamine, and unauthorized prescription drugs.</li>
                <li>Being under the influence of illegal drugs or unauthorized controlled substances while on campus or at college-sponsored events.</li>
                <li>Providing or distributing drugs to others, including selling and trafficking.</li>
                <li>Use of substances in a manner that impairs personal or public safety, academic performance, or professional responsibilities.</li>
              </ul>

              <h4 className="font-semibold">Prevention and Education</h4>
              <ul className="list-disc ml-5 mb-3">
                <li>
                  <strong>Educational Programs:</strong> The college will provide ongoing educational programs to raise awareness about the dangers of drug use and the importance of maintaining a drug-free environment.
                </li>
                <li>
                  <strong>Resources for Assistance:</strong> Information about drug counseling, rehabilitation, re-entry programs, and assistance services will be made available to students and staff.
                </li>
              </ul>

              <h4 className="font-semibold">Penalties for Violation</h4>
              <ul className="list-disc ml-5 mb-3">
                <li>
                  <strong>Disciplinary Action:</strong> Students found in violation of this policy may face penalties ranging from probation to expulsion, depending on the severity of the offense.
                </li>
                <li>
                  <strong>Legal Consequences:</strong> Violations may also result in legal consequences as per applicable laws.
                </li>
              </ul>

              <h4 className="font-semibold">Declaration by the Student</h4>
              <p>I, [Student’s Name], a student of [Program and Batch], hereby acknowledge and affirm that I have read and understood the contents of this Drug-Free Campus Pledge. I agree to abide by the policies against drug use established by {collegeData?.collegeName} and the applicable legal authorities. I understand that failure to adhere to this policy not only jeopardizes my status as a student but also subjects me to legal repercussions.</p>
              <div className="mt-4">
                <label className="font-semibold">Student's Signature:</label>
                <input type="text" className="form-input mt-1 block w-full border-gray-300 shadow-sm sm:text-sm rounded-md" />
                <label className="font-semibold mt-4">Date:</label>
                <input type="date" className="form-input mt-1 block w-full border-gray-300 shadow-sm sm:text-sm rounded-md" />
              </div>
            </div>
          </AgreementModal>

          {(initCollegeId === "COL-08B6B8276F75-46EE-A69C-77177AAA488B" ? data2 : data)?.map((item, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out">
              <div className="p-6 space-y-4">
                <h3 className="text-xl font-semibold text-gray-800">{item.title}</h3>
                <p className="text-gray-600">{item.description}</p>
                <button disabled={item?.disabled || false} onClick={item.action} className={`w-full sm:w-auto py-2 px-4 rounded transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 ${item?.disabled ? "bg-gray-400 hover:bg-gray-400 text-white cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600 text-white font-bold"}`} aria-disabled={item?.disabled ? "true" : "false"}>
                  {item.buttonText}
                </button>
                {item?.date && <p className="text-red-600"> Start on: {item?.date}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Controlls;
