import React from "react";
import { Header, Main } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";

function RolloutManagement({ onClose }) {
  return (
    <>
      <Main title="Accadamic Holiday Management" width="90vw" height="90vh">
        <Header>
          <div className="flex">
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
      </Main>
    </>
  );
}

export default RolloutManagement;
