import React, { useState, useContext } from "react";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import Select from "antd/lib/select";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useAllClasses } from "../../../../queryHooks";

const { Option } = Select;

function InstantTc({ onClose, refetch, setLoading }) {
  const history = useHistory();
  const { collegeId } = useContext(FirebaseContext);
  const [ClsName, setClsName] = useState("");
  const [SemesterfromLeaving, setSemesterfromLeaving] = useState("");
  const [semester, setsemester] = useState(["l Semester", "ll Semester", "lll Semester", "lV Semester", "V Semester", "Vl Semester", "Vll Semester", "Vlll Semester", "lX Semester", "X Semester"]);
  const [reason, setReason] = useState(["Course Completed", "Discontinued", "college Transfer", "Higher Studies"]);
  const [name, setName] = useState("");
  const [number, setnumber] = useState("");
  const [Exams, setExams] = useState(["Not attended", "l Semester", "ll Semester", "lll Semester", "lV Semester", "V Semester", "Vl Semester", "Vll Semester", "Vlll Semester", "lX Semester", "X Semester"]);
  const [Catogorys, setCatogorys] = useState(["GENERAL", "OBC", "OBC (H)", "OEC", "ST", "SC"]);
  const [Dob, setDob] = useState("");
  const [DoAdmission, setDoAdmission] = useState("");
  const [DoLeaving, setDoLeaving] = useState("");
  const [RegisteNo, setRegisterNo] = useState("");
  const [Exam, setExam] = useState("");
  const [Catogory, setCatogory] = useState("");
  const [AdNumber, setAdnumber] = useState("");
  const [Streason, setStreason] = useState("");
  const [Letter, setLetter] = useState("");

  const adsUserTc = async () => {
    if (!AdNumber) {
      throw new Error("Admission Number Required");
    }

    setLoading(true);
    const instance = await getAxiosTokenInstance();
    let resp;
    resp = await instance.post("/college/users/instantTC", {
      type: "student",
      collegeId: collegeId,
      fullName: name,
      uid: "",
      phoneNumber: number,
      dob: Dob,
      classId: ClsName,
      doa: DoAdmission,
      category: Catogory,
      admisionNo: AdNumber,
      dol: DoLeaving,
      regNo: RegisteNo,
      requestLetter: Letter,
      reasonForLeaving: Streason,
      exam: Exam,
      leaving: SemesterfromLeaving,
    });

    if (resp.data.statusCode !== 200) {
      setLoading(false);
      throw new Error(resp.data.message || "API Error");
    } else {
      setLoading(false);
    }
    refetch();
    setLoading(false);
    onClose();
  };

  const allClasses = useAllClasses({ needAll: true, classOnly: true });

  return (
    <div>
      <Row>
        <h2 style={{ fontFamily: "fantasy" }}>Instant Tc Form</h2>
        <Col>
          <div style={{ width: "300px" }}>
            <label>Class Name</label>
            <br />
            <Select className="inputBox1" value={ClsName} onChange={(e) => setClsName(e)}>
              {allClasses?.data?.map((classes) => (
                <Option key={classes.className} value={classes.classId}>
                  <div style={{ fontSize: "20px" }}>{classes.className}</div>
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <label>Full Name(as per SSLC)</label>
            <br />
            <input type="text" className="inputBox" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} />
            <br />
          </div>
          <div>
            <label>Official Mobile Number</label>
            <br />
            <input type="tel" className="inputBox" pattern="[0-9]*" placeholder="10 digit Mobile number" value={number} onChange={(e) => setnumber(e.target.value)} />
            <br />
          </div>

          <div style={{ marginBottom: 20 }}>
            <label>Category</label>
            <br />
            <Select className="inputBox1" value={Catogory} onChange={(e) => setCatogory(e)}>
              {Catogorys?.map((classes) => (
                <Option key={classes} value={classes}>
                  {classes}
                </Option>
              ))}
            </Select>
          </div>
          <label htmlFor="Admission Number">Admission Number</label>
          <br />

          <input type="text" className="inputBox1" placeholder="Admission Number" value={AdNumber} onChange={(e) => setAdnumber(e.target.value)} />
          <br />
          <div>
            <label htmlFor="">Register Number</label>
            <br />
            <input type="text" className="inputBox" placeholder="Ex:-Hallticket Number" value={RegisteNo} onChange={(e) => setRegisterNo(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Date of Admission</label>
            <br />
            <input type="date" className="inputBox" placeholder="Date of Admission" value={DoAdmission} onChange={(e) => setDoAdmission(e.target.value)} />
          </div>
        </Col>
        <Col>
          <div>
            <label htmlFor="">Date of Leaving</label>
            <br />
            <input type="date" className="inputBox" placeholder="Date of Leaving" value={DoLeaving} onChange={(e) => setDoLeaving(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Date of Birth</label>
            <br />
            <input type="date" className="inputBox" placeholder="Date of Birth" value={Dob} onChange={(e) => setDob(e.target.value)} />
          </div>
          <div>
            <label htmlFor="">Semester from Leaving</label>
            <br />
            <Select className="inputBox1" onChange={(e) => setSemesterfromLeaving(e)} value={SemesterfromLeaving}>
              {semester?.map((classes) => (
                <Option key={classes} value={classes}>
                  {classes}
                </Option>
              ))}
            </Select>
          </div>
          <div className="part2">
            <div>
              <label>Examination last Appeared</label>
              <br />
              <Select className="inputBox1" value={Exam} onChange={(e) => setExam(e)}>
                {Exams?.map((classes) => (
                  <Option key={classes} value={classes}>
                    {classes}
                  </Option>
                ))}
              </Select>
            </div>

            <label htmlFor="">Reason for Leaving</label>
            <br />
            <Select className="inputBox1" value={Streason} onChange={(e) => setStreason(e)}>
              {reason?.map((classes) => (
                <Option key={classes} value={classes}>
                  {classes}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col>
          <label>Write a Letter for release TC and CC to the Principal</label>
          <br />
          <input type="text" className="letter" value={Letter} onChange={(e) => setLetter(e.target.value)} />
          <br />
        </Col>
      </Row>
      <div className="editTc">
        <button
          className="submitBtn"
          style={{ marginTop: 10 }}
          type="submit"
          onClick={() => {
            adsUserTc();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default InstantTc;
