import React, { useState, useRef, useEffect } from 'react';

const TypableSelect = ({ options,customHandleChange,inputValue,setInputValue,keyString }) => {
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    console.log(options)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        customHandleChange(e.target.value)
        setIsDropdownVisible(true);
    };

    const handleSelect = (value) => {
        setInputValue(value);
        setIsDropdownVisible(false);
    };

    return (
        <div className="relative z-[999]" ref={dropdownRef}>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Select an option"
            />
            {isDropdownVisible &&
                <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                    {options?.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => handleSelect(option)}
                            className="p-2 cursor-pointer hover:bg-gray-100"
                        >
                            {keyString ? option?.[keyString] : option}
                        </li>
                    ))}
                    
                </ul>
                }
        </div>
    );
};




export default TypableSelect;
