import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { AcademicYears, naacExcelGenerator, naacFetchData } from "../ApiServices";
import LoadingAnimation from "../../../components/LoadingAnimation";
import { FirebaseContext } from "../../../context/FirebaseContext";

function NIFR({ val, onClose }) {
  const [values, setValues] = useState();
  const { collegeId } = useContext(FirebaseContext);
  const [academicYear, setAcademicYear] = useState("2022-23");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    naacFetchData({
      data: { collegeId, academicYear },
      setLoading,
      setValues,
      url: "/college/NIFR/",
    });
  }, []);

  useEffect(() => {
    values?.sort((a, b) => a?.department[0]?.localeCompare(b?.department[0]));
  }, [values]);

  console.log({ values });

  return (
    <>
      <div className="  z-10 w-4/5 bg-slate-100 h-4/5 px-3 overflow-auto">
        {loading ? (
          <div className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-[#0000004f]">
            <LoadingAnimation />
          </div>
        ) : null}

        <table className="tabl">
          <thead>
            <tr>
              {/* <th style={{ background: "rgb(241 245 249)" }} colSpan={8}>
                <h1 className="text-xl flex justify-between sticky text-black ">
                  2.4.1 Number of full time teacher against sanctioned posts
                  during the year
                  <div className="flex justify-center items-center">
                    <AcademicYears setAcademicYear={setAcademicYear} />
                    <button
                      className="bg-green-500 text-sm active:bg-green-600"
                      onClick={()=>naacExcelGenerator({
                        data: { collegeId, academicYear },
                        setLoading,
                        url: "/college/NAAC/getNAAC_C2_4_1",
                      })}
                    >
                      Dowload
                    </button>
                    <button
                      className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]"
                      onClick={onClose}
                    >
                      X
                    </button>
                  </div>
                </h1>
                <h1 className="text-xl">
                  2.4.3 Number of year of teaching experience of full time
                  teachers in the same instution{" "}
                  {"(Data for the latest completed academic year)"}
                </h1>
              </th> */}
              <th colSpan={12} style={{ background: "rgb(241 245 249)" }}>
                <div className="flex justify-between">
                  <button
                    className="bg-green-500 text-sm active:bg-green-600"
                    onClick={() =>
                      naacExcelGenerator({
                        data: { collegeId, academicYear },
                        setLoading,
                        url: "/college/NIFR/",
                      })
                    }
                  >
                    Dowload
                  </button>
                  <button className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]" onClick={onClose}>
                    X
                  </button>
                </div>
              </th>
            </tr>
            <tr>
              <th className="w-1/">Sl. No.</th>
              <th className="w-1/">Department</th>
              <th className="w-1/">Name</th>
              <th className="w-1/">GENDER</th>
              <th className="w-1/">DATE OF BIRTH AGE(AS ON 31-07-2023)</th>
              <th className="w-1/">DESIGNATION</th>
              <th className="w-1/">HIGHEST QUALIFICATION</th>
              <th className="w-14">TOTAL TEACHING EXPERIENCE IN MONTHS()</th>
              <th className="w-/4">TOTAL INDUSTRY EXPERIENCE IN MONTHS()</th>
              <th className="w1/4">PAN</th>
              <th className="-1/4">WORKING OR NOT</th>
              <th className="-1/4">DATE OF JOINING IN GOVT. VICTORIA COLLEGE</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "5px" }}>
            {values?.map((x, i) => (
              <tr>
                <td>{1 + i}</td>
                <td>{x?.department?.[0]?.replace("Department of", "")}</td>
                <td>{x?.name}</td>
                <td>{x?.gender}</td>
                <td>{x?.dob}</td>
                <td>{x?.designation}</td>
                <td>{x?.highestQualification}</td>
                <td>{x?.months}</td>
                <td>{""}</td>
                <td>{x?.panCard}</td>
                <td>Yes</td>
                <td>{x?.dateOfJoin}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default NIFR;
