import React, { useState, useCallback } from "react";
import { useContext } from "react";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import ObjectiveEdit from "../components/ObjectiveEdit";
import AddModule from "../components/AddModule";
import AddSubject from "../components/AddSubject";
import { Body, Header, Main } from "../../../../../NecttosComp/Layout/Layout";
import Button from "../../../../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { useQuery } from "react-query";
const categoryStyles = {
  taxonomy: {
    Creating: "bg-purple-600",
    Evaluating: "bg-indigo-600",
    Analysing: "bg-blue-600",
    Applying: "bg-green-600",
    Understanding: "bg-yellow-600",
    Remembering: "bg-red-600",
  },
  importance: {
    "Very Important": "bg-red-500",
    Important: "bg-yellow-500",
    Normal: "bg-green-500",
  },
  difficulty: {
    Challenging: "bg-red-600",
    Medium: "bg-yellow-600",
    Easy: "bg-green-600",
  },
  type: {
    Theory: "bg-blue-500",
    Application: "bg-green-500",
  },
};

function getColorForCategory(category, value) {
  return categoryStyles[category]?.[value] || "bg-gray-400";
}

export const getSubjects = (id) => async () => {
  const instance = await getAxiosTokenInstance();
  const url = `/college/subject/getSubjectsWithId?id=${encodeURIComponent(id)}`;
  try {
    const resp = await instance.get(url);
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.subjectObject;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function convertToList(text) {
  return text
    ?.split(";")
    .map((item) => item.trim())
    .filter((item) => item)
    .map((item) => {
      const match = item.match(/^\d+\.\s*(.*)$/);
      return match ? match[1] : item;
    });
}

function SubjectDetails({ data: ids, onClose }) {
  const [edit, setEdit] = useState();
  const { data = {}, refetch } = useQuery(["getSubdddjects", ids?._id, edit], getSubjects(ids?._id));
  const { collegeId, user } = useContext(FirebaseContext);
  const [moduleData, setModuleData] = useState({});
  const getColorForCategoryMemo = useCallback(getColorForCategory, []);
  const convertToListMemo = useCallback(convertToList, []);

  return (
    <>
      <Main title={data?.name || "Selected Subject"} height="auto" width="auto">
        <Header>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </Header>
        <Body>
          <div className="bg-white p-5 rounded-lg shadow-lg my-2">
            <div className="flex justify-end mb-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  refetch();
                  setEdit("main");
                }}
              >
                Edit
              </button>
            </div>

            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-gray-800 mb-6">Subject Details</h2>
              <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="bg-white p-6 rounded-lg shadow space-y-4">
                  <h3 className="text-xl font-semibold text-gray-700">General Information</h3>
                  <p className="text-gray-600">
                    <strong>Subject Name:</strong> {data?.name}
                  </p>
                  <p className="text-gray-600">
                    <strong>Short Name:</strong> {data?.shortName}
                  </p>
                  <p className="text-gray-600">
                    <strong>Subject Code:</strong> {data?.subjectCode}
                  </p>
                  <p className="text-gray-600">
                    <strong>Lecture Hours/Week:</strong> {data?.lectureHour}
                  </p>
                  <p className="text-gray-600">
                    <strong>Credits:</strong> {data?.credit}
                  </p>
                  <p className="text-gray-600">
                    <strong>Internal Mark:</strong> {data?.internalMark}
                  </p>
                  <p className="text-gray-600">
                    <strong>External Mark:</strong> {data?.externalMark}
                  </p>
                  <p className="text-gray-600">
                    <strong>Exam Hour:</strong> {data?.examHour}
                  </p>
                  <p className="text-gray-600">
                    <strong>Syllabus Year:</strong> {data?.year}
                  </p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
                  {["A", "B", "C"].map((part) => (
                    <div key={part} className="bg-white p-6 rounded-lg shadow space-y-4">
                      <h3 className="text-xl font-semibold text-gray-700">Part {part}</h3>
                      <p className="text-gray-600">
                        <strong>Total Questions:</strong> {data[`part${part}`]?.questions ?? "N/A"}
                      </p>
                      <p className="text-gray-600">
                        <strong>Questions to Skip:</strong> {data[`part${part}`]?.skip ?? "N/A"}
                      </p>
                      <p className="text-gray-600">
                        <strong>Mark per Question:</strong> {data[`part${part}`]?.mark ?? "N/A"}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow-lg my-2">
            <div className="flex justify-end mb-4">
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => setEdit("objective")}>
                Edit
              </button>
            </div>
            <h6 className="text-xl font-semibold text-gray-800 mb-2">Objectives</h6>
            <ul className="list-disc pl-5 mb-4 text-gray-700">{convertToListMemo(data?.objectives)?.length > 0 ? convertToListMemo(data?.objectives) : [data?.objectives]?.map((objective, index) => <li key={index}>{objective}</li>)}</ul>
            <h6 className="text-xl font-semibold text-gray-800 mb-2">Reference</h6>
            {data?.references && <p className="mb-2 text-gray-600">Suggested readings:</p>}
            <ul className="list-decimal pl-5 text-gray-700">{convertToListMemo(data?.references)?.length > 0 ? convertToListMemo(data?.references) : [data?.references]?.map((reference, index) => <li key={index}>{reference}</li>)}</ul>
          </div>

          {data?.modules?.map((module, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg mb-6 overflow-hidden border-l-4 border-indigo-500">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold text-indigo-700">
                  Module {module?.moduleId + 1}: {module?.name}
                </h3>
                <button
                  aria-label="Edit Module"
                  onClick={() => {
                    refetch();
                    setEdit("addModule");
                    setModuleData({
                      moduleId: module?.moduleId,
                      subjectId: data?._id,
                      points: module.points,
                      marks: module.marks,
                      name: module.name,
                      hours: module.hours,
                      partA: module.partA,
                      partB: module.partB,
                      partC: module.partC,
                      taxonomy: module.taxonomy,
                      difficulty: module.difficulty,
                      important: module.important,
                      type: module.type,
                    });
                  }}
                  className="p-2 bg-green-500 hover:bg-green-600 rounded-full inline-flex items-center justify-center text-white shadow"
                  title="Edit Module"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                  </svg>
                </button>
              </div>
              <div className="pt-4">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 text-gray-800">
                  <div>
                    <dt className="text-sm font-medium text-indigo-600">Hours</dt>
                    <dd className="mt-1 text-sm">{module?.hours}</dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-indigo-600">Marks</dt>
                    <dd className="mt-1 text-sm">{module?.marks}</dd>
                  </div>
                  <div className="sm:col-span-2 bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                    <dt className="text-md font-semibold text-gray-800 mb-2">Key Points:</dt>
                    <dd className="mt-2 text-sm text-gray-700">
                      <ul className="list-disc pl-5 space-y-2">
                        {module?.points?.map((point, idx) => (
                          <li key={idx} className="text-gray-700">
                            {(index+1)+ '.'+(idx+1)} - {point}
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </div>

                  <div className="mb-6">
                    <h4 className="font-semibold text-gray-800 mb-4">Module Details:</h4>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                      <div>
                        <h5 className="text-md font-semibold text-gray-700 mb-2">Bloom's Taxonomy:</h5>
                        <div className="flex flex-wrap gap-2">
                          {module?.taxonomy?.map((level, idx) => (
                            <div key={idx} className={`px-4 py-2 rounded-lg text-white ${getColorForCategoryMemo("taxonomy", level)} flex items-center gap-2`}>
                              <i className="fas fa-brain"></i>
                              <span>{level}</span>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h5 className="text-md font-semibold text-gray-700 mb-2">Importance:</h5>
                        <div className="flex flex-wrap gap-2">
                          {module?.important?.map((level, idx) => (
                            <div key={idx} className={`px-4 py-2 rounded-lg text-white ${getColorForCategoryMemo("importance", level)} flex items-center gap-2`}>
                              <span>{level}</span>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h5 className="text-md font-semibold text-gray-700 mb-2">Difficulty:</h5>
                        <div className="flex flex-wrap gap-2">
                          {module?.difficulty?.map((level, idx) => (
                            <div key={idx} className={`px-4 py-2 rounded-lg text-white ${getColorForCategoryMemo("difficulty", level)} flex items-center gap-2`}>
                              <span>{level}</span>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h5 className="text-md font-semibold text-gray-700 mb-2">Type:</h5>
                        <div className="flex flex-wrap gap-2">
                          {module?.type?.map((level, idx) => (
                            <div key={idx} className={`px-4 py-2 rounded-lg text-white ${getColorForCategoryMemo("type", level)} flex items-center gap-2`}>
                              <span>{level}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4 className="font-semibold text-gray-800 mb-2">Blueprint:</h4>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div className="bg-blue-200 rounded-lg p-4 text-center">
                        <h5 className="font-bold text-blue-800">Part A</h5>
                        <p>Questions: {module?.partA}</p>
                      </div>
                      <div className="bg-green-200 rounded-lg p-4 text-center">
                        <h5 className="font-bold text-green-800">Part B</h5>
                        <p>Questions: {module?.partB}</p>
                      </div>
                      <div className="bg-red-200 rounded-lg p-4 text-center">
                        <h5 className="font-bold text-red-800">Part C</h5>
                        <p>Questions: {module?.partC}</p>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          ))}

          <Button
            type="update"
            onClick={() => {
              setEdit("addModule");
              refetch();
              setModuleData({
                moduleId: data?.modules?.length || 0,
                subjectId: data?._id,
              });
            }}
          >
            Add Module
          </Button>
        </Body>
      </Main>

      {edit === "main" ? (
        <AddSubject onClose={() => setEdit()} data={data} />
      ) : edit === "objective" ? (
        <ObjectiveEdit
          onClose={() => {
            setEdit();
            refetch();
          }}
          data={data}
        />
      ) : edit === "addModule" ? (
        <AddModule
          values={moduleData}
          onClose={() => {
            setEdit();
            refetch();
          }}
        />
      ) : null}
    </>
  );
}

export default SubjectDetails;
