import React, { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from '../../../context/FirebaseContext';
import { EditDetails, getDropdownData } from '../../../pages/college/ApiServices';
import Button from '../../../NecttosComp/Button/Button';
import { Body, Footer, Header, Main, widthChangeAnimation } from '../../../NecttosComp/Layout/Layout';
import Input from '../../../NecttosComp/Input/Input';
import { useProfileData } from '../../../queryHooks';
// import { ProgressBar, calculateProfilepPercentage } from '../../../NecttosComp/ProgressBar/ProgressBar';
import Loader from '../../../NecttosComp/Loader/Loader';
// import { ProfileDataDisplayKeyVlaue } from '../../../FunctionalExport/Formate';
import { ProfileDataDisplayKeyVlaue, ProgressBar, calculateProfilepPercentage } from '../../NewProfile';

interface Props {
	onNext: () => void;
	onClose: () => void;
	studentId?: string | null | undefined;
	modal: Boolean;
}

function OfficialInfo({ onClose, studentId, onNext, modal }: Props) {
	const [dropDowns, setDropDowns] = useState<any[]>([{}]);
	const [isEditing, setIsEditing] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { collegeId } = useContext(FirebaseContext);
	const { data: profileData, refetch } = useProfileData();

	const [values, setValues] = useState({});

	useEffect(() => {
		setValues({
			stay: profileData.pendingData?.stay || profileData.stay || '',
			secondLanguage: profileData.pendingData?.secondLanguage || profileData.secondLanguage || '',
			capId: profileData.pendingData?.capId || profileData.capId || '',
			rollNo: profileData.pendingData?.rollNo || profileData.rollNo || '',
			lastSchoolName: profileData.pendingData?.lastSchoolName || profileData.lastSchoolName || '',
			lastTcNumber: profileData.pendingData?.lastTcNumber || profileData.lastTcNumber || '',
			lastTcDate: profileData.pendingData?.lastTcDate || profileData.lastTcDate || '',
			hallTicketNo: profileData.pendingData?.hallTicketNo || profileData.hallTicketNo || '',
			shortName: profileData.pendingData?.shortName || profileData.shortName || '',
			universityId: profileData.pendingData?.universityId || profileData.universityId || '',
			penNumber: profileData.pendingData?.penNumber || profileData.penNumber || '',
			dateOfJoin: profileData.pendingData?.dateOfJoin || profileData.dateOfJoin || '',
			designationStatus: profileData.pendingData?.designationStatus || profileData.designationStatus || '',
			designation: profileData.pendingData?.designation || profileData.designation || '',
		});
	}, [profileData]);

	useEffect(() => {
		const getDropDowns = async () => {
			const val = await getDropdownData();
			setDropDowns(val);
			console.log(val);
		};
		getDropDowns();
	}, []);

	const [placeHoldValues, setPlaceHolderValues] = useState<{}>({});
	let userRole: string = '';
	type CollegeRole = {
		type: string;
	};

	const rolesArray: string[] = ['student', 'teacher', 'staff'];
	userRole = rolesArray.find((element) => profileData.collegeRoles.some((x: CollegeRole) => x.type === element)) || userRole;

	const staffKeys = {
		penNumber: 'PEN Number',
		dateOfJoin: 'Date Of Join',
		designationStatus: 'Designation Status',
		designation: 'Designation',
	};

	const studentsKeys = {
		stay: 'Stay',
		secondLanguage: 'Second Language',
		capId: 'Cap Id',
		rollNo: 'Roll No',
		lastSchoolName: 'Last School Name',
		lastTcNumber: 'Last TC Number',
		lastTcDate: 'Last Tc Date',
		hallTicketNo: 'Hall Ticket Number',
		shortName: 'Short Name',
	};

	const teacherKeys = {
		shortName: 'Short Name',
		universityId: 'University Id',
		penNumber: 'PEN Number',
		dateOfJoin: 'Date Of Join',
		designationStatus: 'Designation Status',
		designation: 'Designation',
	};

	useEffect(() => {
		if (userRole === 'student') {
			setPlaceHolderValues(studentsKeys);
		} else if (userRole === 'teacher') {
			setPlaceHolderValues(teacherKeys);
		} else if (userRole === 'staff') {
			setPlaceHolderValues(staffKeys);
		} else {
			setPlaceHolderValues(staffKeys);
		}
	}, [userRole]);

	return (
		<>
			{/* <Main title="Official Information" height="90vh" width={"60vw"}> */}
			<div className='h-full w-full'>
				<Header width='100%' height='6%' title='Profile'>
					<div className='flex flex-wrap items-center'></div>
					<div className='flex flex-wrap windows-center'>
						{/* <Button type="print" children="Print" />
            			<Button type="pdf" pdfId="accounts" children="PDF" /> */}
						{!modal && <Button type='fetch' onClick={onNext} children='Next' width='10vw' />}
						<Button type='close' onClick={onClose} children='Close' />
					</div>
				</Header>
				<Body width='100%' height='92%'>
					<div className='grid grid-flow-col gap-auto w-full h-full'>
						<div className='px-3 py-2 w-[40vw] h-full flex flex-col justify-between shadow rounded' style={{ backgroundColor: 'white', color: 'black' }}>
							<div className='w-full h-auto px-4 pt-2'>
								<ProgressBar title='Verified Data' progress={parseInt(calculateProfilepPercentage(profileData, placeHoldValues)?.percentage) || 0} />
							</div>
							<div className='w-full h-auto overflow-y-scroll flex-grow'>
								<ProfileDataDisplayKeyVlaue temp={profileData} placeHoldValues={placeHoldValues} title='Verified Data' />
							</div>
							{!isEditing ? (
								<Button
									type='update'
									onClick={async () => {
										setIsEditing(!isEditing);
									}}>
									Edit
								</Button>
							) : null}
						</div>
						{/* <div>
							<ProgressBar title='are Updated' progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
							<ProfileDataDisplayKeyVlaue temp={profileData?.pendingData} placeHoldValues={placeHoldValues} title='Updated data' needVerification={true} />
							</div> */}

						{isEditing && (
							<div className='w-full h-full p-4 flex flex-col justify-between shadow rounded' style={{ backgroundColor: 'white', color: 'black' }}>
								<div className='w-full h-auto px-4'>
									<ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} />
								</div>

								<h5 className='text-blue-900 text-[1.2vw] font-semibold text-center'>You can edit your data here !</h5>
								<div id='scrollBarEnable' className='px-3 py-2 w-auto z-50 overflow-auto h-full  max-h-[65vh]'>
									{userRole === 'student' && (
										<>
											<Input width='98%' fieldName='Stay' returnKey='stay' options={dropDowns[14]?.[collegeId || 'stayWhere'] || dropDowns[14]?.['stayWhere']} setState={setValues} state={values} type='drop' />
											<div className='relative z-[8]'>
												<Input width='98%' fieldName='Second Language' returnKey='secondLanguage' options={dropDowns[11]?.[collegeId || 'secondLanguage']} setState={setValues} state={values} type='drop' />
											</div>
											<Input width='98%' fieldName='Cap Id' returnKey='capId' setState={setValues} state={values} type='text' />
											<Input width='98%' fieldName='Roll No' returnKey='rollNo' setState={setValues} state={values} type='text' />
											<Input width='98%' fieldName='Last School Name' returnKey='lastSchoolName' setState={setValues} state={values} type='text' />
											<Input width='98%' fieldName='Last TC Number' returnKey='lastTcNumber' setState={setValues} state={values} type='text' />
											<Input width='98%' fieldName='Last TC Date' returnKey='lastTcDate' setState={setValues} state={values} type='date' />
											<Input width='98%' fieldName='Hall Ticket Number' returnKey='hallTicketNo' setState={setValues} state={values} type='text' />
										</>
									)}

									{userRole === 'teacher' && (
										<>
											<Input width='98%' fieldName='Short Name' returnKey='shortName' setState={setValues} state={values} type='text' />
											<Input width='98%' fieldName='University Id' returnKey='universityId' setState={setValues} state={values} type='text' />
										</>
									)}
									{['teacher', 'staff'].includes(userRole) && (
										<>
											<Input width='98%' fieldName='PEN Number' returnKey='penNumber' setState={setValues} state={values} type='text' />
											<Input width='98%' fieldName='Date Of Join' returnKey='dateOfJoin' setState={setValues} state={values} type='date' />
											<div className='relative z-[1]'>
												<Input width='98%' fieldName='Designation Status' returnKey='designationStatus' options={dropDowns[8]?.designationStatus?.teacher} setState={setValues} state={values} type='drop' />
											</div>
											<div className='relative z-[0]'>
												<Input width='98%' fieldName='Designation' returnKey='designation' options={dropDowns[8]?.designation?.teacher} setState={setValues} state={values} type='drop' />
											</div>
										</>
									)}
								</div>

								{/* <ProgressBar progress={parseInt(calculateProfilepPercentage(values, placeHoldValues)?.percentage) || 0} /> */}
								<Button
									width='100%'
									onClick={async () => {
										setIsLoading(true);
										await EditDetails(values);
										await refetch();
										setIsEditing(!isEditing);
										setIsLoading(false);
									}}
									type='save'
									children='Save'
								/>
							</div>
						)}
					</div>
				</Body>
			</div>
			{/* </Main> */}
			{isLoading && <Loader />}
		</>
	);
}

export default OfficialInfo;
