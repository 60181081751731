import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import LoadingAnimation from "../../components/LoadingAnimation";
import { useDropDowns } from "../../queryHooks";
import { getAditionalStatiData, getCaste, getCatogory, getStatiCount, getStatistics } from "../../pages/college/ApiServices";
import moment from "moment";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";

export const exportPdf = async ({ olddata, setLoading }) => {
  const instance = await getAxiosTokenInstance();

  let data = {
    ...olddata,
  };
  try {
    const resp = await instance.post(`/college/action/postStudentsDetailsToExcel`, data, {
      responseType: "blob",
    });
    if (resp.data.statusCode === 400) {
      setLoading(false);
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    setLoading(false);
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

const convertToNormalWords = (variableName) => {
  let normalWords = variableName?.replace(/([A-Z])/g, " $1")?.replace(/^./, function (str) {
    return str.toUpperCase();
  });
  const abbreviationMap = {
    Adm: "Admission",
    No: "Number",
    Tc: "TC",
    Dob: "DOB",
    Sslc: "SSLC",
    PlusTwo: "Plus Two",
    Pen: "PEN",
    Cap: "CAP",
    Panchayat: "Panchayat",
    Municipality: "Municipality",
    Corporation: "Corporation",
    District: "District",
    Nationality: "Nationality",
    Pin: "PIN",
    Id: "ID",
    House: "House",
    State: "State",
    Village: "Village",
    Place: "Place",
    Post: "Post",
    Office: "Office",
    Degree: "Degree",
    Course: "Course",
    Percentage: "Percentage",
    Register: "Register",
    Specialisation: "Specialisation",
    University: "University",
    Year: "Year",
    Pass: "Pass",
    Board: "Board",
    Chance: "Chance",
    Stream: "Stream",
    Blood: "Blood",
    Group: "Group",
    Gender: "Gender",
    Disabilities: "Disabilities",
    Aadhaar: "Aadhaar",
    Annual: "Annual",
    Income: "Income",
    Proverty: "Poverty",
    Line: "Line",
    Caste: "Caste",
    Category: "Category",
    Merital: "Marital",
    Status: "Status",
    Religion: "Religion",
    Phone: "Phone",
    Batch: "Batch",
    Name: "Name",
    Class: "Class",
    Date: "Date",
    Of: "of",
    Join: "Join",
    Roll: "Roll",
    Short: "Short",
    Index: "Index",
    Last: "Last",
    School: "School",
    Second: "Second",
    Language: "Language",
    Stay: "Stay",
    Travel: "Travel",
    Details: "Details",
    Designation: "Designation",
    Type: "Type",
    Email: "Email",
    eGrands: "E Grands Category",
  };

  normalWords = normalWords
    ?.split(" ")
    ?.map((word) => abbreviationMap[word] || word)
    .join(" ");

  return normalWords;
};

export const Analytics = ({ onClose }) => {
  const { collegeId } = useContext(FirebaseContext);

  const [stateData, setStateData] = useState(() => {
    const saved = localStorage.getItem("stateData");
    const initialValue = saved ? JSON.parse(saved) : {};
    return initialValue;
  });
  useEffect(() => {
    localStorage.setItem("stateData", JSON.stringify(stateData));
  }, [stateData]);

  const resetStateData = () => {
    const initialState = { key: "dd" };
    setStateData(initialState);
    localStorage.setItem("stateData", JSON.stringify(initialState));
  };

  const exportPdfMutation = useMutation(exportPdf);

  const [loading, setLoading] = useState(false);

  const { data: categoryData = [] } = useQuery(["categoryData", stateData], getCatogory({ collegeId, collection: "users", key: "category", stateData }));
  const { data: religionData = [] } = useQuery(["religionData1"], getStatistics({ collegeId, collection: "users", key: "religion" }));
  const { data: activeStatusData = [] } = useQuery(["activeStatusData"], getStatistics({ collegeId, collection: "users", key: "activeStatus" }));
  const { data: disabilitiesData = [] } = useQuery(["disabilitiesData"], getStatistics({ collegeId, collection: "users", key: "disabilities" }));
  const { data: reservationData = [] } = useQuery(["reservationData"], getStatistics({ collegeId, collection: "users", key: "admType" }));
  const { data: genderData = [] } = useQuery(["genderData1"], getStatistics({ collegeId, collection: "users", key: "gender" }));
  const { data: designationData = [] } = useQuery(["designationData", stateData], getCaste({ collegeId, collection: "users", key: "designation", stateData }));
  const { data: designationStatusData = [] } = useQuery(["designationStatusData", stateData], getCaste({ collegeId, collection: "users", key: "designationStatus", stateData }));
  const { data: casteData = [] } = useQuery(["casteData", stateData], getCaste({ collegeId, collection: "users", key: "caste", stateData }));
  const { data: stayData = [] } = useQuery(["stayData", stateData], getCaste({ collegeId, collection: "users", key: "stay", stateData }));
  const { data: secondLanguageData = [] } = useQuery(["secondLanguageData", stateData], getCaste({ collegeId, collection: "users", key: "secondLanguage", stateData }));
  const { data: provertyLineData = [] } = useQuery(["provertyLineData", stateData], getCaste({ collegeId, collection: "users", key: "provertyLine", stateData }));
  const { data: annualIncomeData = [] } = useQuery(["annualIncomeData", stateData], getCaste({ collegeId, collection: "users", key: "annualIncome", stateData }));
  const { data: stateDataUsers = [] } = useQuery(["stateData", stateData], getCaste({ collegeId, collection: "users", key: "state", stateData }));
  const { data: count = [] } = useQuery(["count", stateData], getStatiCount({ collegeId, collection: "users", stateData }));
  const { data: getAditionalStati = [] } = useQuery(["getAditionalStatiData", stateData], getAditionalStatiData({ collegeId, collection: "users", stateData }));

  const incomeDrop = ["No Income", "₹0 - ₹2,50,000", "₹2,50,001 - ₹ 5,00,000", "₹5,00,001 - ₹ 7,50,000", "₹7,50,001 - ₹ 10,00,000", "₹10,00,001 - ₹12,50,000", "₹12,50,001 - ₹15,00,000", "Above ₹ 15,00,000"];

  const onExportExam = async () => {
    setLoading(true);

    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let olddata = {
        collegeId,
        collection: "users",
        stateData,
      };

      link.download = "Smart Analytics " + moment(new Date()).format("DD MMMM YYYY HH MM");
      const pdfData = await exportPdfMutation.mutateAsync({ olddata, setLoading });
      link.href = URL.createObjectURL(new Blob([pdfData], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }));
      link.click();
      setLoading(false);

      onClose();
    } catch (e) {}
  };

  const officialKey = ["hallTicketNo", "department", "eGrands", "tcDate", "tcNumber", "courseName", "activeStatus", "travelDetails", "admisionNo", "penNumber", "designationStatus", "designation", "admType", "batchName", "class", "capId", "dateOfAdmission", "dateOfJoin", "rollNo", "shortName", "index", "lastTcDate", "lastTcNumber", "lastSchoolName", "secondLanguage", "stay", "uid"].sort((a, b) => a.localeCompare(b));

  const qualifications = ["degreeCourse", "degreePercentage", "degreeRegisterNo", "degreeSpecialisation", "degreeUniversity", "degreeYearOfPass", "plusTwoBoard", "plusTwoNoOfChance", "plusTwoPercentage", "plusTwoRegisterNo", "plusTwoStream", "plusTwoYearOfPass", "sslcBoard", "sslcNoOfChance", "sslcPercentage", "sslcRegisterNo", "sslcYearOfPass"]?.sort((a, b) => a?.localeCompare(b));

  const addressKey = ["panchayatMunicipalityCorporation", "permanentDistrict", "permanentHouseName", "permanentNationality", "permanentPanchayatMunicipalityCorporation", "permanentPinCode", "permanentPlace", "permanentPostOffice", "permanentState", "permanentVillage", "district", "houseName", "nationality", "state", "village", "pinCode", "place", "postOffice"]?.sort((a, b) => a?.localeCompare(b));

  const basicKey = ["name", "phoneNumber", "dob", "bloodGroup", "email", "gender", "disabilities", "aadhaarNumber", "annualIncome", "familyIncome", "provertyLine", "guardianMobile", "caste", "category", "meritalStatus", "religion"]?.sort((a, b) => a?.localeCompare(b));

  const { data: dropDown } = useDropDowns("eGrantz");

  const eGrandData = dropDown?.[collegeId]?.map((x) => x.type);

  const CheckboxGroup = ({ title, data, stateData, setStateData, prefix }) => {
    const handleCheckboxChange = (categoryKey) => {
      const key = prefix + (categoryKey?._id || categoryKey);
      setStateData({
        ...stateData,
        [key]: !stateData[key],
      });
    };

    const getDisplayText = (categoryKey) => {
      if (prefix === "u") {
        return convertToNormalWords(categoryKey);
      }
      if (prefix === "c") {
        return categoryKey?.slice(0, 1) + categoryKey?.slice(1, 100)?.toLowerCase();
      }

      return categoryKey?._id ? categoryKey.name : convertToNormalWords(categoryKey);
    };

    return (
      <div className="border-2 border-indigo-300 rounded-lg p-5 m-3 bg-indigo-50 shadow-xl">
        <h2 className="text-2xl font-bold text-indigo-600 mb-5">
          {title}-{count}
        </h2>
        <div className="flex flex-wrap justify-around">
          {data?.map((categoryKey, index) => (
            <label key={index} className="flex items-center mb-2 cursor-pointer">
              <input type="checkbox" className="form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400" checked={stateData[prefix + (categoryKey?._id || categoryKey)]} onChange={() => handleCheckboxChange(categoryKey)} />
              <span className="text-lg text-gray-800 ml-2">{getDisplayText(categoryKey)}</span>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const MainCheckboxGroup = ({ title, data, stateData, setStateData, prefix }) => {
    const handleCheckboxChange = (categoryKey) => {
      const key = prefix + (categoryKey?._id || categoryKey);
      setStateData({
        ...stateData,
        [key]: !stateData[key],
      });
    };

    const getDisplayText = (categoryKey) => {
      if (prefix === "u") {
        return convertToNormalWords(categoryKey);
      }
      if (prefix === "c") {
        return categoryKey?.slice(0, 1) + categoryKey?.slice(1, 100)?.toLowerCase();
      }

      return categoryKey?._id ? categoryKey.name : convertToNormalWords(categoryKey);
    };

    return (
      <div className="border-2 border-indigo-300 rounded-lg p-5 m-3 bg-indigo-50 shadow-xl column">
        <div className="flex flex-wrap ">
          <h2 className="text-2xl font-bold text-indigo-600 ">{title}: </h2>
          {data?.map((categoryKey, index) => (
            <label key={index} className="flex items-center mx-5 cursor-pointer">
              <input type="checkbox" className="form-checkbox h-6 w-6 text-pink-500 rounded-full transition duration-200 ease-in-out transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-pink-400 focus:border-pink-400" checked={stateData[prefix + (categoryKey?._id || categoryKey)]} onChange={() => handleCheckboxChange(categoryKey)} />
              <span className="text-lg text-gray-800 ml-2">{getDisplayText(categoryKey)}</span>
            </label>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Main title="Smart Analytics & Data Exporter" width="auto" height="auto">
      <Header>
        <div className="flex  "></div>
        <div className="flex">
          <Button
            type="download"
            onClick={() => {
              onExportExam();
            }}
          >
            Export Excel
          </Button>
          <Button type="save" onClick={resetStateData}>
            Reset
          </Button>
          <Button type="save" onClick={resetStateData}>
            View Data
          </Button>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>

      <div class="flex justify-center items-center">
        <label class="flex justify-center items-center">
          <span class="text-teal-900 font-bold text-7xl">{count}</span>
        </label>
      </div>
      <Body>
        <div className="h-[100%] overflow-auto">
          <MainCheckboxGroup title="Type of Users" data={["scholars", "student", "teacher", "staff"]} stateData={stateData} setStateData={setStateData} prefix="u" />

          {loading ? (
            <LoadingAnimation dark />
          ) : (
            <div className="flex">
              <div className="w-[30%]" style={{ flexDirection: "column", display: "flex", textAlign: "center" }}>
                <CheckboxGroup title="Type of Users" data={["scholars", "student", "teacher", "staff"]} stateData={stateData} setStateData={setStateData} prefix="u" />
                <CheckboxGroup title="Nature of Users" prefix="a" data={activeStatusData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Gender" prefix="g" data={genderData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Disabilities" prefix="w" data={disabilitiesData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="State" prefix="s" data={stateDataUsers} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Reservation Category" prefix="q" data={reservationData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="E Grand Category" prefix="e" data={eGrandData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Religion" prefix="r" data={religionData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Category" prefix="t" data={categoryData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Family Income" prefix="f" data={incomeDrop} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Stay" prefix="h" data={stayData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Second Language" prefix="i" data={secondLanguageData} stateData={stateData} setStateData={setStateData} />

                <CheckboxGroup title="Proverty Line" prefix="j" data={provertyLineData} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Annual Income" prefix="o" data={annualIncomeData} stateData={stateData} setStateData={setStateData} />
              </div>

              <div className="w-[60%]" style={{ flexDirection: "column", display: "flex", textAlign: "center" }}>
                {!stateData.ustudent && (
                  <>
                    <CheckboxGroup title="Designation" prefix="m" data={designationStatusData} stateData={stateData} setStateData={setStateData} />
                    <CheckboxGroup title="Designation Status" prefix="n" data={designationData} stateData={stateData} setStateData={setStateData} />
                  </>
                )}

                <CheckboxGroup title="Colleges" prefix="y" data={getAditionalStati?.collegeAll} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Department" prefix="d" data={getAditionalStati?.department} stateData={stateData} setStateData={setStateData} />

                {stateData.ustudent && (
                  <>
                    <CheckboxGroup title="Batches" prefix="b" data={getAditionalStati?.batches} stateData={stateData} setStateData={setStateData} />
                    <CheckboxGroup title="Classes" prefix="l" data={getAditionalStati?.classes} stateData={stateData} setStateData={setStateData} />
                  </>
                )}
                <CheckboxGroup title="Caste" prefix="c" data={casteData} stateData={stateData} setStateData={setStateData} />
              </div>
              <div className="w-[30%]" style={{ flexDirection: "column", display: "flex", textAlign: "center" }}>
                <CheckboxGroup title="Basic Details" prefix="z" data={basicKey} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Official Details" prefix="z" data={officialKey} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Address" prefix="z" data={addressKey} stateData={stateData} setStateData={setStateData} />
                <CheckboxGroup title="Qualifications" prefix="z" data={qualifications} stateData={stateData} setStateData={setStateData} />
              </div>
            </div>
          )}
        </div>
      </Body>
    </Main>
  );
};
