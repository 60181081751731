import React, { useEffect, useContext, useState } from "react";
import classNames from "./studentTable.module.scss";
import { Input, Table } from "antd";
import Scrollbars from "react-custom-scrollbars";

import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useQuery } from "react-query";
import { getAdmissionRegister, getNewClasses } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import moment from "moment";
import StudentData from "../StudentData";
import ModalWrapper from "../../../../components/ModalWrapper";

import { Button } from "antd";
import CustomInput from "../../../../myComp/CustomInput";
import CustomDropDownKey from "../../../../myComp/CustomDropDownKey";
import { ShortEdit } from "../ShortEdit";
import ViewStudentData from "../ViewStudentData";

function AdmissionRegister({ refreshStatus, onClose }) {
  const { collegeId, user } = useContext(FirebaseContext);
  const [state, setState] = useState({});
  const [keys, setKeys] = useState([]);
  const [searchText, setSearchText] = useState("w");

  const { data, refetch, status } = useQuery(["getAdmissionRegister", state.startDate, state.endingDate, state.classId], getAdmissionRegister(collegeId, state.startDate, state.endingDate, state.classId));
  const { data: classData } = useQuery("getNewClasses", getNewClasses(collegeId, user?.uid));
  console.log({ state });

  useEffect(() => {
    const keysValues = new Set();
    classData?.list?.forEach((x) => {
      x?.splitup?.forEach((xx) => {
        keysValues.add(xx?.title);
      });
    });
    const uniqueKeys = Array.from(keysValues);
    setKeys(uniqueKeys);
  }, [classData?.list]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      refetch();
    }, 10000);
    return () => clearInterval(interval);
  }, [collegeId]);

  let userList = data?.list || [];

  const [studentList, setStudentList] = useState(userList);

  useEffect(() => {
    refetch && refetch();
  }, [refreshStatus, refetch]);

  useEffect(() => {
    userList.forEach((element) => {
      const adminNumberStr = element.admisionNo + "";
      const match = adminNumberStr.match(/\d+$/);
      const lastDigits = match ? match[0] : "";
      const lastDigitsLength = lastDigits.length;

      element.adminhalf = adminNumberStr.slice(0, -lastDigitsLength);
      element.adminhalf2 = lastDigits;
    });

    setStudentList(userList);
  }, [userList]);

  useEffect(() => {
    let start = moment(new Date()).format("YYYY-MM-DD") + "T00:01";
    let end = moment(new Date()).format("YYYY-MM-DD") + "T23:59";
    if (!state.startDate) {
      setState({ ...state, startDate: start, endingDate: end });
    }
  }, []);

  let tableColumns = [
    {
      title: "Sl.No.",
      render: (_, item, i) => i + 1,
    },
    {
      title: "A. No.",
      dataIndex: "applicationNo",
      key: "applicationNo",
    },
    {
      title: "Date Of Admn",
      render: (_, item, i) => moment(new Date(item?.dateOfAdmission)).format("DD MMM YYYY"),
      key: "createdOn",
    },

    ...(collegeId === "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F"
      ? [
          {
            title: "Admn No. F",
            dataIndex: "adminhalf",
            key: "adminhalf",
            sorter: (a, b) => (parseInt(a?.adminhalf, 10) > parseInt(b?.adminhalf, 10) ? 1 : parseInt(b?.adminhalf, 10) > parseInt(a?.adminhalf, 10) ? -1 : 0),
          },
          {
            title: "Admn No. L",
            dataIndex: "adminhalf2",
            key: "adminhalf2",
            sorter: (a, b) => {
              const adminHalfA = parseInt(a.adminhalf2, 10);
              const adminHalfB = parseInt(b.adminhalf2, 10);
              return adminHalfA - adminHalfB;
            },
          },
        ]
      : [
          {
            title: "Admn No.",
            dataIndex: "admisionNo",
            key: "admisionNo",
            sorter: (a, b) => (parseInt(a?.admisionNo, 10) > parseInt(b?.admisionNo, 10) ? 1 : parseInt(b?.admisionNo, 10) > parseInt(a?.admisionNo, 10) ? -1 : 0),
          },
        ]),
    {
      render: (_, data) => (
        <button
          style={{ cursor: "pointer", width: 40, height: 40, borderRadius: 20, padding: 12, textAlign: "center", backgroundColor: "red" }}
          onClick={() => {
            setState({ ...state, data: data, keys: ["open"], studentId: data?._id, openModal: true });
          }}
        >
          <div>
            <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "white", fontWeight: 900 }}>E</div>
          </div>
        </button>
      ),
    },
    {
      render: (_, data) => (
        <button
          style={{ cursor: "pointer", width: 40, height: 40, borderRadius: 20, padding: 12, textAlign: "center", backgroundColor: "green" }}
          onClick={() => {
            setState({ ...state, items: data, studentId: data?._id, viewMode: true });
          }}
        >
          <div>
            <div style={{ alignContent: "center", cursor: "pointer", fontSize: 20, color: "white", fontWeight: 900 }}>V</div>
          </div>
        </button>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, data) => data?.name?.toUpperCase(),
      key: "name",
      sorter: (a, b) => (a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
    },
    {
      title: "Last Tc Number",
      dataIndex: "lastTcNumber",
      key: "lastTcNumber",
    },
    {
      title: "Index",
      dataIndex: "index",
      sorter: (a, b) => (parseInt(a?.index, 10) > parseInt(b?.index, 10) ? 1 : parseInt(b?.index, 10) > parseInt(a?.index, 10) ? -1 : 0),
    },
    {
      title: "Admin Type",
      dataIndex: "admType",
      key: "admType",
      sorter: (a, b) => (a?.admType > b?.admType ? 1 : b?.admType > a?.admType ? -1 : 0),
    },
    {
      title: "Batch",
      dataIndex: "batchName",
      key: "batchName",
    },
    {
      title: "Course",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "Cap Id",
      dataIndex: "capId",
      key: "capId",
    },

    {
      title: "Second Language",
      dataIndex: "secondLanguage",
      key: "secondLanguage",
      sorter: (a, b) => (a?.secondLanguage > b?.secondLanguage ? 1 : b?.secondLanguage > a?.secondLanguage ? -1 : 0),
    },

    {
      title: "Annual Income",
      dataIndex: "annualIncome",
      key: "annualIncome",
    },
    {
      title: "Catogory",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      sorter: (a, b) => (a?.activeStatus > b?.activeStatus ? 1 : b?.activeStatus > a?.activeStatus ? -1 : 0),
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  let filteredUserList = studentList;
  if (searchTerm) {
    filteredUserList = userList.filter((user) => user.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  const handleSearchTextChange = (text) => {
    setSearchText(text.target.value);

    let searchResult = userList?.filter((data) => data?.name.toLowerCase()?.includes(text.toLowerCase()) || data?.phoneNumber?.includes(text) || data?.admisionNo.includes(text));
    setStudentList(searchResult);
  };

  const ball = 10;
  const vellocity = 3;

  const result = 3 * 10;
  console.log({ classData });
  return (
    <>
      <div className={classNames.container}>
        <div className={classNames.modal}>
          <div className={classNames.tableView} style={{ backgroundColor: "white" }}>
            <div className={classNames.tableHeader}>
              <h2>Admission Register</h2>
              <Button
                className={classNames.nextAdmn}
                onClick={() => {
                  setState({ ...state, data: { editNextAdmn: true }, openModal: true });
                }}
              >
                Sequence
              </Button>
              <CustomInput keyData={"startDate"} titleData={"From Date and Time"} typeData={"datetime-local"} stateData={state} setStateData={setState} />
              <CustomInput keyData={"endingDate"} titleData={"To Date and Time"} typeData={"datetime-local"} stateData={state} setStateData={setState} />

              <Input style={{ width: 200, height: 50, margin: 10, borderRadius: 200 }} placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
              <view style={{ width: 300 }}>
                <CustomDropDownKey keyData={"classId"} keyObject={"className"} keyReturn={"_id"} titleData={"Course wise"} dropValues={classData?.list || []} stateData={state} setStateData={setState} />
              </view>
              <div style={{ width: "10%" }}></div>
            </div>
            {status === "loading" && <LoadingAnimation dark />}

            {status === "success" && (
              <Scrollbars className={classNames.tableWrap + " " + classNames.tableContainer}>
                <div className={classNames.userTable}>
                  <Table columns={tableColumns} dataSource={filteredUserList && filteredUserList.length > 0 ? filteredUserList : []} pagination={false} />
                </div>
              </Scrollbars>
            )}
          </div>
          {state.viewMode && (
            <ModalWrapper>
              <ViewStudentData
                uid={state?.studentId}
                onClose={() => {
                  setState({ ...state, viewMode: false });
                }}
              />
            </ModalWrapper>
          )}
          {state.openModal && (
            <ShortEdit
              item={state?.data}
              keys={keys}
              refetchDemo={refetch}
              setStateData={setState}
              stateData={state}
              onClose={() => {
                setState({ ...state, openModal: false });
              }}
            />
          )}
        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      </div>
    </>
  );
}
export default AdmissionRegister;
