import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { naacFetchData, AcademicYears, naacExcelGenerator } from "../../ApiServices";




function C3_5_2({ val, onClose }) {
  const [values, setValues] = useState()
  const { collegeId } = useContext(FirebaseContext)
  const [academicYear, setAcademicYear] = useState()
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    // naacFetchData({ data: { collegeId: academicYear }, setLoading, setValues, url: "/college/NAAC/getNAAC_C3_5_2" })
  }, [])
  return (
    <>
      <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">


        <table className="table">
          <thead>
            <tr>

            <th colSpan={6} style={{ background: "rgb(241 245 249)" }}>
              <h1 className="text-xl flex">3.5.2  Number of functional MoUs with institutions, other universities, industries, corporate houses etc. during the year  <div className="flex">
                <AcademicYears setAcademicYear={setAcademicYear} />
                <button
                  className="bg-green-500 text-sm"
                  onClick={() =>
                    naacExcelGenerator({
                      data: { collegeId, academicYear: "2022-23" },
                      url: "/college/NAAC/getNAAC_C2_7",
                      setLoading,
                    })
                  }
                >
                  Dowload
                </button>
                <button
                  className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]"
                  onClick={onClose}
                >
                  X
                </button>
              </div></h1>

            </th>
            </tr>
            <tr>
              <th className="w-1/4">Organisation with which MoU is signed</th>
              <th className="w-1/4">Name of the institution/ industry/ corporate house</th>
              <th className="w-1/4">Year of signing MoU</th>
              <th className="w-1/4">Duration</th>
              <th className="w-1/4">List the  actual  activities under each MOU year wise</th>
              <th className="w-1/4">Number of students/teachers participated under MoUs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>
      </div >
    </>
  );
}

export default C3_5_2;
