import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Checkbox } from 'antd';
import Alert from 'react-popup-alert';
import FeesCategories from '../FeesCategories';
import classNames from './studentFeeDetails.module.scss';
import CollectFee from '../CollectFee';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { useMutation } from 'react-query';
import { collectFee } from '../../feesManagementServices';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { BillNumberModal } from '../BillNumberModal';
import { Field, Formik } from 'formik';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';
import SearchableImput from '../../../college/LibraryModals/AddBook/DropDownComponent';
import CustomInput from '../../../../myComp/CustomInput';

export default function StudentFeeDetail({ data, handleClickbackButton, ...props }) {
	const { collegeId } = useContext(FirebaseContext);
	const [online, setOnline] = React.useState(false);
	const [state, setState] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [unbill, setUnbill] = React.useState(false);
	const [previewComponent, setpreviewComponent] = useState('feeCategory');
	const [feeAmounts, setFeeAmounts] = useState({});
	const [feeReciept, setFeeReciept] = useState({});
	const [date, setDate] = useState('');
	const [admisionNo, setAdmissionNo] = useState('');
	const [totalAmount, setTotalAmount] = useState(0);
	const [feeSummary, setFeeSummary] = useState(props.feeSummary);
	const mutation = useMutation(collectFee);

	const [alert, setAlert] = React.useState({
		type: 'error',
		text: 'This is a alert message',
		show: false,
	});

	const onCloseAlert = () => {
		setAlert({
			type: '',
			text: '',
			show: false,
		});
	};

	const options = data?.groups?.map((fee) => ({
		key: fee?.feeType,
		name: fee?.feeName,
		recieptNo: fee?.recieptNo,
		currentDue: fee?.totalCurrentDue,
		futureDue: fee?.totalAmount - fee?.totalAdvance - fee?.currentTotalAmount,
		dues: fee?.dues?.map((x) => ({ key: x.title, recieptNo: x?.recieptNo, currentDue: x?.balance, dueDate: x?.dueDate })),
	}));

	useEffect(() => {
		if (Object.keys(props.feeSummary).length) {
			setpreviewComponent('paymentSection');
		}
	}, []);

	const viewCategory = () => {
		switch (previewComponent) {
			case 'feeCategory':
				return <FeesCategories options={options} feeAmounts={feeAmounts} setFeeAmounts={setFeeAmounts} feeReciept={feeReciept} setFeeReciept={setFeeReciept} />;
			case 'paymentSection':
				return <CollectFee feeSummary={feeSummary} setTotalAmount={setTotalAmount} />;
			default:
				return <FeesCategories options={options} feeAmounts={feeAmounts} setFeeAmounts={setFeeAmounts} feeReciept={feeReciept} setFeeReciept={setFeeReciept} />;
		}
	};

	const backButtonClick = () => {
		if (previewComponent === 'feeCategory') handleClickbackButton();
		else if (Object.keys(props.feeSummary).length) handleClickbackButton();
		else {
			setFeeSummary({});
			setpreviewComponent('feeCategory');
		}
	};
	const submitFeeCategory = () => {
		let totalFee = feeSummary;
		Object.entries(feeAmounts)?.forEach(([key, value]) => {
			if (key === 'TuitionFee' || key === 'ExamFee') {
				const feeCategory = data?.groups?.find((x) => x.feeType === key);
				let paymentAmount = value;
				feeCategory?.dues?.forEach((due) => {
					if (paymentAmount > 0) {
						let feePaid = due.balance;
						if (paymentAmount >= due?.balance) {
							paymentAmount -= due?.balance;
						} else {
							feePaid = paymentAmount;
							paymentAmount = 0;
						}
						totalFee = {
							...totalFee,
							[key]: [
								...(totalFee[key] || []),
								{
									dueId: due?._id,
									title: due?.title,
									fee: feePaid,
								},
							],
						};
					}
				});
			} else {
				const feeCategory = data?.groups?.find((x) => x.feeType === 'Others');
				const due = feeCategory?.dues?.find((x) => x.title === key);
				totalFee = {
					...totalFee,
					[key]: [
						...(totalFee[key] || []),
						{
							dueId: due?._id,
							title: due?.title,
							fee: value,
						},
					],
				};
			}
		});
		setFeeSummary(totalFee);
		if (Object.keys(totalFee).length) {
			setpreviewComponent('paymentSection');
		}
	};
	const onCollectFee = async () => {
		try {
			setIsLoading(true);
			if (Object.keys(feeSummary).length) {
				const postData = {
					collections: Object.entries(feeSummary)
						?.filter(([_, value]) => value?.some((x) => x?.fee))
						?.map(([key, value]) => ({
							feeType: ['TuitionFee', 'ExamFee'].includes(key) ? key : 'Others',
							dues: value?.map((x) => ({
								dueId: x.dueId,
								amount: x.fee,
							})),
						})),
					method: online === true ? 'Online' : 'Normal',
					isHideBill: unbill,
					recievedBank: state['collections.recievedBank'],
					accountHolderName: state['collections.accountHolderName'],
					transactionId: state['collections.transactionId'],
					transactionDate: state.date,
					status: 'Success',
					feeReciept,
					date,
				};

				let collectedData = await mutation.mutateAsync({ collegeId, userId: data.userId, data: postData, setIsLoading, setAlert });

				handleClickbackButton({
					collectedData: collectedData?.collectedArray,
					paymentsArray: collectedData?.paymentsArray,
					userName: data?.userName,
					admnNo: data?.admnNo,
					className: data?.className,
					photoURL: data?.photoURL,
				});
				setIsLoading(false);
			}
		} catch (e) {}
	};

	return isLoading ? (
		<LoadingAnimation dark />
	) : (
		<div className={classNames.studentFeeDetails} style={{ backgroundColor: unbill ? 'red' : online ? '#80dfff' : '#ccccff' }}>
			<div className={classNames.container}>
				{!alert.show ? (
					<>
						<div className={classNames.reportBox}>
							<Scrollbars>
								{data?.groups?.map((fee) => (
									<div className={classNames.reports}>
										<div className={classNames.darkScale}>
											<p style={{ opacity: 0 }}></p>
										</div>
										<div className={classNames.reportDetails}>
											<h3>{fee?.feeName}</h3>

											<div className={classNames.detailRow}>
												<h4>
													<strong>Paid Current Amount</strong>
												</h4>
												<h4>
													<strong>{fee?.totalPaidAmount - fee?.totalAdvance}</strong>
												</h4>
											</div>

											<div className={classNames.detailRow}>
												<h4>
													<strong>Current Due Amount</strong>
												</h4>
												<h4>
													<strong>{fee?.totalCurrentDue}</strong>
												</h4>
											</div>
											<div className={classNames.detailRow}>
												<h4>
													<strong>Paid Advance Amount </strong>
												</h4>
												<h4>
													<strong>{fee?.totalAdvance}</strong>
												</h4>
											</div>
											<div className={classNames.detailRow}>
												<h4>
													<strong>Future Due Amount</strong>
												</h4>
												<h4>
													<strong>{fee?.totalAmount - fee?.totalAdvance - fee?.currentTotalAmount}</strong>
												</h4>
											</div>
											<div className={classNames.detailRow}>
												<h3>
													<strong>Total</strong>
												</h3>
												<h3>
													<strong>{fee?.totalAmount}</strong>
												</h3>
											</div>
											<h4>Splitup</h4>
											<span className={classNames.divider} />

											{fee?.dues?.map((detail) => (
												<div className={classNames.detailRow}>
													<h4>{detail?.title}</h4>
													<h4 className={classNames.hash}>{detail?.balance}</h4>
												</div>
											))}
										</div>
									</div>
								))}
								{data?.bills?.map((fee) => (
									<div className={classNames.reports}>
										<div className={classNames.darkScale}>
											<p style={{ opacity: 0 }}></p>
										</div>
										<div className={classNames.feePaidDetails}>
											<div style={{ width: '65%' }}>
												<div className={classNames.detailRow}>
													<h4>
														<strong>Bill No</strong>
													</h4>
													<h4>
														<strong>{fee?.billNumber}</strong>
													</h4>
												</div>
												<div className={classNames.detailRow}>
													<h4>
														<strong>Date</strong>
													</h4>
													<h4>
														<strong>{fee?.date}</strong>
													</h4>
												</div>
												<div className={classNames.detailRow}>
													<h4>
														<strong>Fee Type</strong>
													</h4>
													<h4>
														<strong>{fee?.feeType}</strong>
													</h4>
												</div>
												<div className={classNames.detailRow}>
													<h4>
														<strong>Method</strong>
													</h4>
													<h4>
														<strong>{fee?.method}</strong>
													</h4>
												</div>
											</div>
											<div className={classNames.amountView}>
												<h2>
													<strong>{fee?.amount}</strong>
												</h2>
											</div>
											<span className={classNames.divider} />
										</div>
									</div>
								))}
							</Scrollbars>
						</div>
						<div className={classNames.section}>
							<Scrollbars style={{ height: '95%' }}>
								<div>{viewCategory()}</div>
							</Scrollbars>

							<div className={classNames.submitButtonsContainer}>
								<button className={classNames.backButton} onClick={backButtonClick}>
									Back
								</button>
								{previewComponent === 'feeCategory' ? (
									<button className={classNames.printButton} onClick={submitFeeCategory}>
										Calculate
									</button>
								) : (
									<button className={classNames.printButton} onClick={onCollectFee}>
										Collect {totalAmount}
									</button>
								)}
								<label>
									<input type='checkbox' style={{ height: 40, width: 40 }} defaultChecked={unbill} onChange={() => setUnbill(!unbill)} />
								</label>
								<label style={{ fontSize: 30 }}>
									<input type='checkbox' style={{ height: 40, width: 40 }} defaultChecked={online} onChange={() => setOnline(!online)} />
									Online
								</label>
							</div>
						</div>

						<div className={classNames.titleContainer}>
							<Formik enableReinitialize>
								<div className={`${classNames.group2} ${classNames.formHalfWidth}`}>
									<Field
										type='date'
										name='date'
										id='date'
										value={date?.date}
										onChange={(e) => {
											setDate(e.target.value);
										}}
									/>
									<label> Choose Custome Date</label>
								</div>
							</Formik>

							<div className={classNames.avatar} style={{ height: online && '30%' }}>
								<img src={data?.photoURL} className={classNames.dp} />
							</div>
							<div className={classNames.typoContainer}>
								<h1>{data?.userName}</h1>
								<p>{data?.className}</p>
								<p>Valet: {data?.valet}</p>
							</div>
							<Formik enableReinitialize>
								<div className={`${classNames.group2} ${classNames.formHalfWidth}`}>
									<Field
										type='text'
										value={admisionNo}
										placeholder={data?.admnNo}
										onChange={(e) => {
											setAdmissionNo(e.target.value);
										}}
									/>
									<label> Change Admission Number</label>
								</div>
							</Formik>
							{online && (
								<>
									<SearchableImput keyData={'collections.recievedBank'} titleData={'Recieved Bank'} collection={'fees'} stateData={state} setStateData={setState} />
									<SearchableImput keyData={'collections.transactionId'} titleData={'Transaction ID'} collection={'fees'} stateData={state} setStateData={setState} />
									<SearchableImput keyData={'collections.accountHolderName'} titleData={'Account Holder Name'} collection={'fees'} stateData={state} setStateData={setState} />
									<CustomInput keyData={'date'} titleData={'Transaction Date'} typeData={'date'} stateData={state} setStateData={setState} />
								</>
							)}
						</div>
					</>
				) : (
					<Alert header={alert.type} btnText={'Close'} text={alert.text} type={alert.type} show={alert.show} onClosePress={onCloseAlert} pressCloseOnOutsideClick={true} showBorderBottom={true} alertStyles={{ height: 200, width: '60%', margin: '20%', backgroundColor: 'red', borderRadius: 10 }} headerStyles={{ color: 'white', width: '100%', textAlign: 'center' }} textStyles={{ color: 'white', width: '100%', textAlign: 'center', fontSize: 20 }} buttonStyles={{ color: 'white', backgroundColor: 'green', padding: 20, borderRadius: 10, margin: '47%' }} />
				)}
			</div>
		</div>
	);
}
