import React from "react";
import classNames from "./exportModal.module.scss";
import { Button } from "antd";









export const Help = ({ onClose }) => {

  const tutorialVideos = [
    {
      title: "Getting Started with App",
      link: "https://www.youtube.com/watch?v=video1",
    },
    {
      title: "Advanced Features Tutorial",
      link: "https://www.youtube.com/watch?v=video2",
    },
    {
      title: "Troubleshooting Common Issues",
      link: "https://www.youtube.com/watch?v=video3",
    },
  ];

  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >




        <div style={{ backgroundColor: "#f2f2f2", padding: "10px" }}>
          <h3 style={{ marginBottom: "10px" }}>Tutorial Videos</h3>
          <ul style={{ padding: 0, flexDirection: 'row' }}>
            {tutorialVideos.map((video, index) => (
              <li key={index} style={{ height: 300, width: 200, backgroundColor: 'red', marginBottom: "5px"   }}>
                <a
                  href={video.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  {video.title}
                </a>
              </li>
            ))}
          </ul>
        </div>



      </div>
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>

  );
};



