import React, { useState, useCallback } from 'react';
import Button from '../../../NecttosComp/Button/Button';
import toast, { Toaster } from 'react-hot-toast';
import { CSVReader } from 'react-papaparse';
import { Body, Footer, Header, Main } from '../../../NecttosComp/Layout/Layout';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../NecttosComp/Table/Table';

interface CSVEditorProp {
	setCsv: (val: boolean) => void;
	returnData: (val: any) => void;
	keysNeeded: string[];
	titleKey: string;
	specific?: boolean;
}

type CsvData = string[][];

function CSVEditor({ setCsv, returnData, keysNeeded, specific = false, titleKey }: CSVEditorProp) {
	const [csvData, setCsvData] = useState<CsvData>([]);

	const validateCSVFile = useCallback((items: any[]) => {
		const tempArray = items.map((item) => item.data);
		setCsvData(tempArray);
	}, []);

	const deleteRow = useCallback(
		(index: number) => {
			setCsvData((prevData) => prevData.filter((_, i) => i !== index));
		},
		[setCsvData],
	);

	const handleSubmit = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			const headers = csvData[0];
			const missingKeys = keysNeeded.filter((key) => !headers.includes(key));

			if (missingKeys.length > 0) {
				missingKeys.forEach((key) => toast.error(`${key} not found. Please add.`));
				return;
			}

			const dataRows = csvData.slice(1);
			const generatedData = dataRows.map((row) => {
				const rowData: { [key: string]: string } = {};
				headers.forEach((header, index) => {
					rowData[header] = row[index];
				});
				return rowData;
			});

			returnData(generatedData);
		},
		[csvData, keysNeeded, returnData],
	);

	const handleAddColumn = useCallback(() => {
		setCsvData((prevData) => prevData.map((row) => [...row, '']));
	}, []);

	const handleDeleteColumn = useCallback(
		(index: number) => {
			setCsvData((prevData) => prevData.map((row) => row.filter((_, i) => i !== index)));
		},
		[setCsvData],
	);

	const handleAddRow = useCallback(() => {
		const newRow = Array(csvData[0]?.length || 0).fill('');
		setCsvData((prevData) => [...prevData, newRow]);
	}, [csvData]);

	// Function to download a template CSV file with headers
	const handleDownloadTemplate = () => {
		const csvContent = `data:text/csv;charset=utf-8,${keysNeeded.join(',')}\n`;
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', `${titleKey} template.csv`);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<Toaster />
			<Main height='auto' width='auto'>
				<div className='min-w-[50vw] min-h-[50vh]'>
					<Header>
						{!csvData.length ? (
							<Button type='update' onClick={() => setCsvData([keysNeeded])}>
								Create
							</Button>
						) : (
							<div className='flex'>
								<Button type='delete' onClick={() => setCsvData([])}>
									Remove Data
								</Button>
								<form className='flex items-center' onSubmit={handleSubmit}>
									<label className='flex items-center text-red-800 text-[13px]'>
										<input required type='checkbox' className='w-[20px]' />
										The information presented herein is accurate.
									</label>
									<Button type='save'>Upload Data</Button>
								</form>
							</div>
						)}
						<Button type='download' onClick={handleDownloadTemplate}>
							Download Template
						</Button>
						<Button
							type='close'
							onClick={() => {
								setCsv(false);
							}}>
							Close
						</Button>
					</Header>
					<Body>
						{csvData.length > 0 ? (
							<Table width='100%'>
								<Thead>
									<Tr>
										{csvData[0].map((header, index) => (
											<Th key={`header-${index}`}>
												<div className='flex'>
													<select
														className='text-black'
														value={header}
														onChange={(e) => {
															if (!specific) {
																setCsvData((prevData) => {
																	const newData = [...prevData];
																	newData[0][index] = e.target.value;
																	return newData;
																});
															}
														}}>
														{keysNeeded.map((option, idx) => (
															<option key={option + idx} value={option}>
																{option}
															</option>
														))}
													</select>
													{!specific && (
														<button className='p-1 ml-1 bg-red-500 hover:bg-red-700 transition rounded text-[8px] h-5 w-5' onClick={() => handleDeleteColumn(index)}>
															X
														</button>
													)}
												</div>
											</Th>
										))}
										{!specific && (
											<Th>
												<Button type='update' onClick={handleAddColumn}>
													Add Column
												</Button>
											</Th>
										)}
									</Tr>
								</Thead>
								<Tbody height='auto'>
									{csvData.slice(1).map((row, rowIndex) => (
										<Tr key={`row-${rowIndex + 1}`}>
											{row.map((cell, cellIndex) => (
												<Td index={rowIndex} key={`cell-${rowIndex + 1}-${cellIndex}`}>
													<input
														type='text'
														className='text-black'
														value={cell}
														onChange={(e) => {
															setCsvData((prevData) => {
																const newData = [...prevData];
																newData[rowIndex + 1][cellIndex] = e.target.value;
																return newData;
															});
														}}
													/>
												</Td>
											))}
											<Td index={rowIndex}>
												<Button type='delete' onClick={() => deleteRow(rowIndex + 1)}>
													Delete
												</Button>
											</Td>
										</Tr>
									))}
									<Tr>
										<Td index={66}>
											<Button type='update' onClick={handleAddRow}>
												Add New Data
											</Button>
										</Td>
									</Tr>
								</Tbody>
							</Table>
						) : (
							<CSVReader onDrop={validateCSVFile}>
								<p className='text-slate-800 font-extrabold text-[20px]'>Upload</p>
							</CSVReader>
						)}
					</Body>
					<Footer />
				</div>
			</Main>
		</>
	);
}

export default CSVEditor;
