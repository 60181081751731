import React, { useContext, useRef, useState } from 'react';
import { Main } from '../../../../NecttosComp/Layout/Layout';
import Button from '../../../../NecttosComp/Button/Button';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import { getQuestionnair } from '../../../../pages/college/ApiServices';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../NecttosComp/Table/Table';
import moment from 'moment';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import ReactToPrint from 'react-to-print';
import NewQuestionnaire from '../../comp/NewQuestionaire';
import ModalWrapper from '../../../../components/ModalWrapper';
import SettupQuestionnaire from '../../comp/SettupQuestionnaire';

function Metric1of2({ onClose, enableMetric }) {
	const { collegeId } = useContext(FirebaseContext);
	const componentRef = useRef();
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState({
		addBook: false,
	});
	const [state, setState] = useState({
		filterValue: '',
		page: 0,
	});

	const { data = [], refetch } = useQuery(['getQuestionnair', collegeId, state.type], getQuestionnair(collegeId, state.filterType));

	const handleButtonClick = (item, key) => {
		setItems({
			...items,
			[key]: true,
			questionnaireId: item._id,
			itemData: {
				_id: item._id,
				collegeId: item.collegeId,
				title: item.title,
				effectFrom: moment(item.effectFrom).format('YYYY-MM-DD'),
				type: item.type,
				mode: item.mode,
				semester: item.semester,
				whoFill: item.whoFill,
				academicYear: item.academicYear,
				questionnaire: item.questionnaire,
				nature: item.nature,
				lastDate: moment(item.lastDate).format('YYYY-MM-DD'),
			},
		});
	};

	return (
		<Main width='100vw' height='100vh' title={enableMetric.subTitle.toUpperCase()}>
			<div className='flex justify-between bg-[#485056] items-center pt-2 p-1 rounded '>
				<h6 className='text-white ml-2'>{enableMetric.title}</h6>
				<h3 className='text-white'>{enableMetric.subTitle.toUpperCase()}</h3>
				<div className='flex'>
					<Button type='excel' onClick={onClose} tableRef={componentRef.current}>
						Excel
					</Button>
					<Button type='pdf' onClick={onClose} pdfId='pdf' orientation={'l'}>
						Pdf
					</Button>
					<Button type='print' onClick={onClose} tableRef={componentRef.current}>
						Print
					</Button>
					<Button type='close' onClick={onClose}>
						close
					</Button>
				</div>
			</div>
			<main className='w-full h-full flex'>
				<section className='flex-1 h-full  p-1 overflow-auto'>
					{loading ? (
						<LoadingAnimation dark />
					) : (
						<div className='w-full h-full overflow-auto' id='pdf'>
							<div ref={componentRef} className='flex flex-col border rounded-lg printable'>
								<Table width='1300px'>
									<Thead>
										<Tr>
											<Th width='100px' children='Sn' />
											<Th width='100px' children='Title of Feedback' />
											<Th width='100px' children='Collected From' />
											<Th width='100px' children='No of Q' />
											<Th width='100px' children='Type' />
											<Th width='100px' children='Who Fill' />
											<Th width='100px' children='Priority' />
											<Th width='100px' children='End Date' />
											<Th width='100px' children='Responds' />
											<Th width='200px' children='Actions' />
										</Tr>
									</Thead>
									<Tbody>
										{data?.map((item, i) => (
											<Tr key={i}>
												<Td width='100px' children={state.page * 50 + 1 + i} />
												<Td width='100px' children={item.title} />
												<Td width='100px' children={moment(item.effectFrom).format('DD MMM YYYY')} />
												<Td width='100px' children={item.length} />
												<Td width='100px' children={item.type} />
												<Td width='100px' children={item.whoFillName} />
												<Td width='100px' children={item.nature} />
												<Td width='100px' children={moment(item.lastDate).format('DD MMM YYYY')} />
												<Td width='100px' children={item.responds || '0%'} />
												<Td width='200px'>
													<div className='flex gap-2'>
														{/* <button className='bg-green-500 text-white rounded h-8 px-3' onClick={() => handleButtonClick(item, 'questionnaireAnalysis')}>
																Analysis
															</button>
															<button className='bg-green-500 text-white rounded h-8 px-3' onClick={() => handleButtonClick(item, 'statistics')}>
																Frequency
															</button> */}
														<button className='bg-blue-500 text-white rounded h-8 px-3' onClick={() => handleButtonClick(item, 'newQuestionnaire')}>
															Edit
														</button>
														<button className='bg-indigo-500 text-white rounded h-8 px-3' onClick={() => handleButtonClick(item, 'settupQuestionnaire')}>
															Add Q
														</button>
													</div>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</div>
							<Button
								type='save'
								onClick={() => {
									setItems({ ...items, newQuestionnaire: true });
								}}>
								New
							</Button>
						</div>
					)}
				</section>
				<section className='w-80 p-4 shadow-lg border-2 rounded bg-[#f0f1f1]'>
					<p className='text-lg font-semibold mb-2'>{enableMetric?.details?.description}</p>
					<ul className='list-disc ml-6'>
						{enableMetric?.details?.options?.map((x) => (
							<li key={x?.keyName} className='mb-1'>
								{x?.title}
							</li>
						))}
					</ul>
				</section>
			</main>
			{items.newQuestionnaire && (
				<ModalWrapper>
					{' '}
					<NewQuestionnaire
						onCloseModal={() => setItems({ ...items, newQuestionnaire: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, newQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
			{items.settupQuestionnaire && (
				<ModalWrapper>
					{' '}
					<SettupQuestionnaire
						onCloseModal={() => setItems({ ...items, settupQuestionnaire: false })}
						itemData={items.itemData}
						refectOrgin={refetch}
						questionnaireId={items.questionnaireId}
						onSuccess={() => {
							setItems({ ...items, settupQuestionnaire: false });
						}}
					/>{' '}
				</ModalWrapper>
			)}
		</Main>
	);
}

export default Metric1of2;
