import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Footer, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import { getAllAccademics, getSemesterWiseStudentData } from '../../pages/college/ApiServices';
// import Select from "antd/lib/select";

import moment from 'moment';
import { Select } from 'antd';
const { Option } = Select;

const getFieldDropDowns = (collegeId: any, type: any, search: any) => async () => {
	const instance = await getAxiosTokenInstance();
	try {
		const resp = await instance.get('/college/action/getHolidaysDrops', {
			params: { collegeId, type, search },
		});
		if (resp.data.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		} else {
			return resp?.data?.list;
		}
	} catch (error) {
		throw new Error('API Error');
	}
};

interface invoiceManagementProps {
	onClose: () => void;
}

function SemesterWiseStudentData({ onClose }: invoiceManagementProps) {
	const [addNew, setAddNew] = useState<any>();
	const [showForm, setShowForm] = useState(false);
	const { collegeId } = useContext(FirebaseContext);
	const ref = useRef<any>(null);
	const [filteredItems, setItems] = useState<any>([]);
	const [search, setSearch] = useState<any>('');
	const [state, setState] = useState<any>({});
	const [className, setClassName] = useState<any>();
	const [selectedSemester, setSelectedSemester] = useState<any>();
	const [classes, setClasses] = useState<any>([]);
	// const [tableData, setTableData] = useState<any>([]);

	const removeItems = (subject: any) => {
		setItems(filteredItems.filter((s: any) => s !== subject));
	};

	const handleItems = (event: any) => {
		const value = event.target.value;
		if (!filteredItems?.includes(value)) {
			setItems([...(filteredItems || []), value]);
		}
	};

	const { data: tableData = [], refetch } = useQuery(['getSemesterWiseStudentData', className?.classId, selectedSemester?.semester], getSemesterWiseStudentData({ collegeId, classId: className?.classId, semester: selectedSemester?.semester }));

	const getClassesList = async ({ queryKey }: any) => {
		// eslint-disable-next-line
		const [_, { collegeId }] = queryKey;
		const instance = await getAxiosTokenInstance();
		let resp;
		if (collegeId)
			try {
				resp = await instance.get('/college/classes/getClass', {
					params: { collegeId, needAll: true, classOnly: true },
				});

				if (resp.data.statusCode === 400) {
					throw new Error(resp.data.message || 'API Error');
				}
			} catch (error) {
				throw new Error('API Error');
			}
		setClasses(resp?.data);
		return resp?.data;
	};
	const classQuery = useQuery(['classes', { collegeId }], getClassesList);

	console.log({ className, selectedSemester });

	return (
		<>
			<Main title='SemesterWise Student Data' width='100vw' height='100vh'>
				<Header>
					<div>
						<div className='flex'>
							<Input width='200px' fieldName='Class *' returnKey='classId' state={className} setState={setClassName} optionDisplay='className' optionKey='classId' didntShowKey options={classes?.list} type='drop' />
							<Input width='150px' fieldName='Semester *' returnKey='semester' state={selectedSemester} setState={setSelectedSemester} options={['Semester 1', 'Semester 2', 'Semester 3', 'Semester 4', 'Semester 5', 'Semester 6', 'Semester 7', 'Semester 8']} type='drop' />
						</div>
					</div>
					<div className='flex'>
						<Button type='print' tableRef={ref.current}>
							Print
						</Button>
						<Button type='excel' tableRef={ref.current}>
							Excel
						</Button>
						<Button type='close' onClick={onClose}>
							Close
						</Button>
					</div>
				</Header>
				<Body>
					<div ref={ref} className='h-[75vh] pt-2 printable overflow-auto'>
						<Table width='100%' innerWidth={['5%', '15%', '25%', '15%', '10%', '10%', '20%']}>
							<Thead>
								<Tr>
									<Th width='50px'>Sl.No.</Th>
									<Th width='190px'>Name</Th>
									<Th width='100px'>Class</Th>
									<Th width='70px'>Semester</Th>
									<Th width='70px'>Roll No</Th>
									<Th width='100px'>AEC</Th>
									<Th width='100px'>AEC_OL</Th>
									<Th width='100px'>DSC_A</Th>
									<Th width='100px'>DSC_B</Th>
									<Th width='100px'>DSC_C</Th>
									<Th width='100px'>MDC</Th>
								</Tr>
							</Thead>
							<Tbody height='auto'>
								{tableData?.map((x: any, i: number) => (
									<Tr>
										<Td index={i} width='50px'>
											{i + 1}
										</Td>

										<Td index={i} width='190px'>
											{x?.name}
										</Td>
										<Td index={i} width='100px'>
											{x?.className}
										</Td>
										<Td index={i} width='70px'>
											{x?.semester}
										</Td>
										<Td index={i} width='70px'>
											{x?.rollNo}
										</Td>
										<Td index={i} width='100px'>
											{x?.aec}
										</Td>
										<Td index={i} width='100px'>
											{x?.aec_ol}
										</Td>
										<Td index={i} width='100px'>
											{x?.dsc_a}
										</Td>
										<Td index={i} width='100px'>
											{x?.dsc_b}
										</Td>
										<Td index={i} width='100px'>
											{x?.dsc_c}
										</Td>
										<Td index={i} width='100px'>
											{x?.mdc}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</div>
				</Body>
				<Footer></Footer>
			</Main>
		</>
	);
}

export default SemesterWiseStudentData;
