import React, { useContext, useEffect, useRef, useState } from "react";
import { getDropdownData } from "../../pages/college/ApiServices";
import Loader1 from "../../NecttosComp/Loaders/Loader";
import Button from "../../NecttosComp/Button/Button";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Input from "../../NecttosComp/Input/Input";
import { useProfileEdit } from "../../queryHooks";
import { ProgressBar, calculateProfilepPercentage } from "../../NecttosComp/ProgressBar/ProgressBar";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import Loader from "../../NecttosComp/Loader/Loader";
import { ColorAbbreviationListOfProfile, ProfileDataDisplay, getProfileUpdateColors, postProfileSubmission } from "../../FunctionalExport/Formate";
import { createRandomId } from "../../FunctionalExport/Utils";
import CSVEditor from "../../NecttosComp/CSVEditor/CSVEditor";
import { uploadFileToFirebase } from "../../NecttosComp/APICaller/APICaller";
import { UploadResult } from "./Members";
import { FirebaseContext } from "../../context/FirebaseContext";

interface Props {
  onClose: () => void;
  studentId?: string | null | undefined;
}

function Publications({ onClose, studentId }: Props) {
  const titleKey = "bookChapter";
  const [dropDowns, setDropDowns] = useState<any[]>([{}]);
  const [csv, setCSv] = useState(false);
  const [isTick, setIsTick] = useState<any>(null);
  const [temp, setTemp] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentRef = useRef<HTMLTableElement>(null);
  const [mode, setMode] = useState<any>(false);
  const [addNew, setAddNew] = useState<any>({});
  const { data: profileData, refetch } = useProfileEdit(titleKey);
  const [values, setValues] = useState<any[]>(profileData);
  const { user } = useContext(FirebaseContext);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const files = event.target.files;
    if (files && files[0]) {
      (async () => {
        try {
          const { url, fileId } = await uploadFileToFirebase(files[0], user?.uid, addNew?.documentId, (progress) => {
            // setUploadProgress((prev: any) => ({ ...prev, [documentId]: progress }));
          });
          setAddNew({ ...addNew, documentURL: url, documentId: fileId });
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      })();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const getDropDowns = async () => {
      const val = await getDropdownData();
      setDropDowns(val);
    };
    getDropDowns();
  }, []);
  const callProfileSubmission = async (keyData: string) => {
    await postProfileSubmission({
      keyData,
      values,
      temp,
      addNew,
      setIsLoading,
      setTemp,
      setMode,
      setAddNew,
      refetch,
      titleKey,
    });
  };

  console.log(values);

  useEffect(() => {
    setValues(profileData?.sort((a: { yearOfPass: number }, b: { yearOfPass: number }) => a.yearOfPass - b.yearOfPass));
  }, [profileData]);

  return (
    <>
      <Main title="Book / Chapter published" height="90vh" width={"90vw"}>
        <Header width="100%" height="6%" title="Profile">
          <div className="flex flex-wrap items-center"></div>
          <div className="flex flex-wrap windows-center">
            <Button type="print" tableRef={componentRef.current} children="Print" />
            <Button type="pdf" pdfId="profilePDF" children="PDF" />
            <Button type="excel" tableRef={componentRef.current} pdfId="profilePDF" children="EXCEL" />
            <Button type="close" onClick={onClose} children="Close" />
          </div>
        </Header>
        <Body width="100%" height="85%">
          <div className="w-full  overflow-auto">
            <div className="w-full float-left p-2">
              <div className="w-full ">
                <ColorAbbreviationListOfProfile />

                <Table ref={componentRef} id={"profilePDF"} width="100%" innerWidth={["10%", "10%", "10%", "10%", "10%", "7%", "8%", "8%", "8%", "8%", "11%"]}>
                  <Thead>
                    <Tr>
                      <Th position={0} children="Sl.No." />
                      <Th position={1} children="Title of the Book/Chapters Published" />
                      <Th position={2} children="Title of the paper" />
                      <Th position={3} children="Title of the proceedings of the Conference" />
                      <Th position={5} children="National/International" />
                      <Th position={4} children="Name of the Conference" />
                      <Th position={6} children="Year of Publication" />
                      <Th position={7} children="ISBN/ISSN number of the preceeding" />
                      <Th position={8} children="Affiliating Institute at the time of Publication" />
                      <Th position={9} children="Name of the publisher" />
                      <Th position={9} children="Name of Authors" />
                      <Th position={9} children="Doi (Link) Optional" />
                      <Th position={9} children="First Page" />
                      <Th position={10} children="Action" />
                    </Tr>
                  </Thead>
                  <Tbody height="auto">
                    {values?.map((x: any, index: any) => (
                      <Tr>
                        <Td position={0} index={index}>
                          <p className={getProfileUpdateColors(x)}>{index + 1}</p>
                        </Td>
                        <Td position={1} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.titleBookChapter}</p>
                        </Td>
                        <Td position={2} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.titlePaper}</p>
                        </Td>
                        <Td position={3} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.titelConference}</p>
                        </Td>
                        <Td position={4} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.type}</p>
                        </Td>
                        <Td position={3} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.nameConference}</p>
                        </Td>
                        <Td position={5} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.dateOfPublication}</p>
                        </Td>
                        <Td position={6} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.isbn}</p>
                        </Td>
                        <Td position={7} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.affiliation}</p>
                        </Td>
                        <Td position={8} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.nameOfPublisher}</p>
                        </Td>
                        <Td position={9} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.author}</p>
                        </Td>
                        <Td position={9} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.linkDoi}</p>
                        </Td>
                        <Td position={9} index={index}>
                          <p className={getProfileUpdateColors(x)}>{x?.documentURL}</p>
                        </Td>
                        <Td position={10} index={index}>
                          <div className="flex">
                            <Button
                              type="update"
                              onClick={() => {
                                setMode("edit");
                                setAddNew(x);
                                setTemp(x);
                              }}
                            >
                              Edit
                            </Button>

                            <Button
                              type="delete"
                              onClick={() => {
                                setMode("delete");
                                setTemp(x);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </div>

              <Button
                onClick={async () => {
                  setMode(!mode);
                  setTemp({});
                }}
                type="update"
              >
                Add New
              </Button>
            </div>
          </div>
        </Body>
        <Footer width="100%" />
      </Main>

      {mode && (
        <Main>
          <Header width="100%" height="6%" title="Profile">
            <div className="flex flex-wrap items-center"></div>
            <div className="flex flex-wrap windows-center">
              <Button
                type="close"
                onClick={() => {
                  closeAnimation();
                  setTimeout(() => setMode(false), 250);
                }}
              >
                Close
              </Button>
            </div>
          </Header>
          <Body>
            <div className="grid grid-flow-col p-2">
              {["delete", "edit"].includes(mode) && (
                <div className=" p-2 max-h-[70vh] overflow-auto rounded">
                  <ProfileDataDisplay temp={temp} />

                  <div className="flex float-right items-center my-1">
                    {mode === "delete" && (
                      <Button
                        type="delete"
                        onClick={() => {
                          callProfileSubmission("delete");
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {mode !== "delete" && (
                <div id="scrollBarEnable" className="px-3 py-2 w-[28vw] z-50 overflow-auto  max-h-[65vh]">
                  <h5 className="text-blue-900  font-bold">You can edit your data here !</h5>
                  <Input width="98%" fieldName="Title of the Book/Chapters Published" returnKey="titleBookChapter" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Title of the paper" returnKey="titlePaper" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Title of the proceedings of the Conference" returnKey="titelConference" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="National/International" returnKey="type" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Name of the Conference" returnKey="nameConference" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Date of Publication" returnKey="dateOfPublication" setState={setAddNew} state={addNew} type="date" />
                  <Input width="98%" fieldName="ISBN/ISSN number of the preceeding" returnKey="isbn" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Affiliating Institute at the time of Publication" returnKey="affiliation" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Name of the publisher" returnKey="nameOfPublisher" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Link to the Recognition in UGC enlistment of the Journal" returnKey="link" setState={setAddNew} state={addNew} type="text" />
                  <Input width="98%" fieldName="Doi (Link)" returnKey="linkDoi" setState={setAddNew} state={addNew} type="text" />
                  <label>Upload first page of the book/chapter</label>
                  <input type="file" onChange={handleFileChange} />

                  <ProgressBar
                    progress={parseInt(
                      calculateProfilepPercentage(addNew, {
                        titleBookChapter: "",
                        titlePaper: "",
                        titelConference: "",
                        type: "",
                        nameConference: "",
                        dateOfPublication: "",
                        affiliation: "",
                        nameOfPublisher: "",
                        isbn: "",
                        linkDoi: "",
                        documentId: "",
                        documentURL: "",
                      })?.percentage
                    )}
                  />
                  {!isLoading && (
                    <Button
                      width="100%"
                      onClick={async () => {
                        console.log({ temp });
                        if (temp?._id) {
                          callProfileSubmission("edit");
                        } else {
                          callProfileSubmission("add");
                        }
                        closeAnimation();
                        setTimeout(() => setMode(false), 250);
                      }}
                      type="save"
                      children="Save"
                    />
                  )}
                </div>
              )}
            </div>
          </Body>
        </Main>
      )}

      {csv && (
        <CSVEditor
          keysNeeded={["endDate", "startDate", "organiser", "title"]}
          setCsv={setCSv}
          returnData={async (data) => {
            if (data) {
              console.log(data);
              let val = [...values];
              setIsTick(false);
              for (let i = 0; i < data?.length; i++) {
                await postProfileSubmission({
                  keyData: "add",
                  values: val,
                  temp,
                  addNew: { ...data[i] },
                  setIsLoading,
                  setTemp,
                  setMode,
                  setAddNew,
                  refetch,
                  titleKey,
                });

                val = [...val, { ...data[i], _id: createRandomId("EQ") }];
                console.log(i);
              }
              setIsTick(true);
              setTimeout(() => {
                setIsTick(null);
                setCSv(false);
              }, 2000);
            }
          }}
        />
      )}

      {isTick === false ? <Loader1 loading={false} /> : isTick === true ? <Loader1 loading={true} /> : null}
      {isLoading && <Loader />}
    </>
  );
}

export default Publications;
