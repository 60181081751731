import React, { useContext, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "./styleData.css";
import { useMutation, useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import LoadingAnimation from "../../../../../components/LoadingAnimation";
import moment from "moment";
import { naacExcelGenerator } from "../../../ApiServices";
import { Body, Footer, Header, Main } from "../../../../../NecttosComp/Layout/Layout";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../../../../NecttosComp/Table/Table";
import Button from "../../../../../NecttosComp/Button/Button";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import Loader from "../../../../../NecttosComp/Loaders/Loader";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function extractFileName(url) {
  const parts = url?.split("/");
  const encodedFileName = parts[parts?.length - 1];
  const fileName = decodeURIComponent(encodedFileName.split("?")[0]); // Decoding and removing any query parameters
  return fileName;
}

const naacReportGeneration = (data) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.post("/college/iqac/customeQuestionairResponds", data);
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data;
    }
  } catch (error) {
    throw new Error(error?.response?.data?.message || error.message || "API Error");
  }
};

export const EditDataSheet = ({ onClose, questionnaireId }) => {
  const { collegeId } = useContext(FirebaseContext);
  const [answers, setAnswers] = useState([]);
  const [values, setValues] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [subQn, setSubQn] = useState([]);
  const [stateData, setStateData] = useState(() => {
    const saved = localStorage.getItem("stateData");
    const initialValue = saved ? JSON.parse(saved) : {};
    return initialValue;
  });

  const uploadFile = async (file, id) => {
    setIsloading(true);
    const storage = getStorage();
    let url = "";

    const uploadTask = await ref(storage, `${id}`);
    await uploadBytes(uploadTask, file);

    setIsloading(false);

    return await getDownloadURL(ref(storage, uploadTask));
  };

  const [loading, setLoading] = useState(false);

  const { data: filteredData = [] } = useQuery(["customeQuestionairResponds", stateData], naacReportGeneration({ collegeId, questionnaireId }));
  useEffect(() => {
    setValues(filteredData.list);
    console.log(filteredData);
  }, [filteredData]);

  const handleSave = async () => {
    setIsloading(false);
    const instance = await getAxiosTokenInstance();
    let data = [...values?.dataNaac];
    let ans = [...answers];

    for (let i in data) {
      for (let j in data[i]?.answers) {
        for (let k in data[i]?.answers[j]) {
          data[i].answers[j][k] = ans[i].shift();
        }
      }
    }
    console.log(data);
    instance
      .post("/college/iqac/bulkUpdatedDataNaac", { data })
      .then(() => {
        setIsloading(true);
        setTimeout(() => onClose(), 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let subStoreQn = [];
    for (let i in values?.dataQuestion?.questionnaire) {
      const elem = values?.dataQuestion?.questionnaire[i];
      for (let j in elem.subQuestions) {
        subStoreQn.push(elem?.subQuestions[j]);
      }
    }

    setSubQn([...subStoreQn]);
    subStoreQn = [];
    for (let i in values?.dataNaac) {
      let ans = [];
      const elem = values?.dataNaac[i];
      for (let j in elem?.answers) {
        const elem2 = elem?.answers[j];
        for (let k in elem2) {
          ans.push(elem2[k]);
        }
      }
      subStoreQn.push([...ans]);
    }
    console.log(subStoreQn);
    setAnswers([...subStoreQn]);
  }, [values]);

  return (
    <>
      <Main height="90vh" width="90vw">
        <Header>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
          <Button type="save" onClick={handleSave}>
            Save
          </Button>
        </Header>
        <Body>
          <div className="overflow-auto">
            <Table width={subQn?.length * 200 + "px"}>
              <Thead>
                <Tr>
                  {values?.dataQuestion?.questionnaire?.map((x) => (
                    <Th width={x.subQuestions?.length * 200 + "px"} position={0}>
                      {x?.question}
                    </Th>
                  ))}
                </Tr>
                <Tr>
                  {subQn?.map((x) => (
                    <Th width="200px">{x?.subQuestion}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody height="65vh">
                {values?.dataNaac?.map((x, index) => (
                  <Tr>
                    {subQn?.map((x, i) => (
                      <Td index={index} width="200px">
                        {x?.type === "Period" ? (
                          <div className="w-[150px]">
                            <label className="flex">
                              From:
                              <input
                                type="date"
                                placeholder="from"
                                width={100}
                                value={answers?.[index]?.[i]?.answer?.from}
                                onChange={(e) => {
                                  let temp = [...answers];
                                  if (temp[index][i]?.answer) {
                                    temp[index][i] = { answer: { from: e.target.value } };
                                  } else {
                                    temp[index][i].answer.from = e.target.value;
                                  }
                                  setAnswers([...temp]);
                                }}
                              />
                            </label>
                            <label className="flex">
                              To:
                              <input
                                width={100}
                                type="date"
                                placeholder="To"
                                value={answers?.[index]?.[i]?.answer?.to}
                                onChange={(e) => {
                                  let temp = [...answers];
                                  if (temp[index][i]?.answer) {
                                    temp[index][i] = { answer: { to: e.target.value } };
                                  } else {
                                    temp[index][i].answer.to = e.target.value;
                                  }
                                  setAnswers([...temp]);
                                }}
                              />
                            </label>
                          </div>
                        ) : x?.type === "Image" ? (
                          <label htmlFor={index + "-" + i}>
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              id={index + "-" + i}
                              className="hidden"
                              onChange={async (e) => {
                                // console.log(extractFileName(answers?.[index]?.[i]?.answer),"helllo",answers?.[index]?.[i]?.answer);
                                let url = "";
                                let temp = [...answers];
                                if (answers?.[index]?.[i]?.answer) {
                                  url = await uploadFile(e.target.files[0], extractFileName(answers?.[index]?.[i]?.answer));
                                } else {
                                  url = await uploadFile(e.target.files[0], `files/${v4()}/${e.target.files[0].name}`);
                                }
                                temp[index][i] = { answer: url };
                                setAnswers([...temp]);
                              }}
                            />
                            <img src={answers?.[index]?.[i]?.answer} height={200} alt="No File" srcset="" />
                          </label>
                        ) : x?.type === "PDF" ? (
                          <label htmlFor={index + "-" + i}>
                            <Document file={answers?.[index]?.[i]?.answer} onLoadError={console.error}>
                              <Page pageNumber={1} />
                            </Document>
                            <input
                              type="file"
                              accept="application/pdf"
                              id={index + "-" + i}
                              className="hidden"
                              onChange={async (e) => {
                                if (e?.target?.files?.[0]) {
                                  let url = "";
                                  let temp = [...answers];
                                  if (answers?.[index]?.[i]?.answer) {
                                    url = await uploadFile(e.target.files[0], extractFileName(answers?.[index]?.[i]?.answer));
                                  } else {
                                    url = await uploadFile(e.target.files[0], `files/${v4()}/${e.target.files[0].name}`);
                                  }
                                  temp[index][i] = { answer: url };
                                  setAnswers([...temp]);
                                }
                              }}
                            />
                          </label>
                        ) : (
                          <input
                            type="text"
                            value={answers?.[index]?.[i]?.answer}
                            onChange={(e) => {
                              let temp = [...answers];
                              temp[index][i] = { answer: e.target.value };
                              setAnswers([...temp]);
                            }}
                          />
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
        <Footer />
      </Main>
      {isLoading ? <Loader loading={isLoading} /> : null}
    </>
  );
};
