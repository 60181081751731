import React, { useEffect, useState } from "react";
import Button from "../../../../NecttosComp/Button/Button";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import Input from "../../../../NecttosComp/Input/Input";
import { useDropDowns } from "../../../../queryHooks";

function EditGrand({ userData, onClose, collegeId, sortedValue }: { userData?: any; onClose: () => void; collegeId: string; sortedValue: string }) {
  const [status, setStatus] = useState<any>({});
  const [msg, setMsg] = useState("");

  console.log(userData);
  const { data: dropDown } = useDropDowns("eGrantz");
  useEffect(() => {
    if (dropDown) {
      setStatus({ category: dropDown?.[collegeId]?.filter((y: any) => y?._id === userData?.eGrantz?._id)[0] });
    }
  }, [dropDown]);

  const handleSave = async () => {
    const instance = await getAxiosTokenInstance();
    instance
      .patch("/college/admissionProcess/update-eGrand-status/" + userData?._id, { eGrandCategory: status.category, typeSelected: sortedValue, collegeId, status: status.status, year: userData.year })
      .then(() => onClose())
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex fixed justify-center items-center top-0 left-0 h-screen w-screen">
      <div className="flex w-[50vw] h-[vh] justify-evenly items-center bg-slate-400 p-6">
        <div>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
          <img src={userData?.photoURL} alt="" className="h-[500px]" />
        </div>
        <div className="flex flex-col">
          <p className="text-white">Name : {userData?.name}</p>
          <p className="text-white">class : {userData?.shortName}</p>
          <p className="text-white">Admn.No. : {userData?.admisionNo}</p>
          <div className="flex flex-col justify-start items-start p-2">
            {sortedValue.toLowerCase() === "all" && <Input fieldName="Select Category" type="drop" state={status} setState={setStatus} returnKey="category" valueShowKey="type" options={dropDown?.[collegeId]} optionDisplay="type" />}
            {sortedValue.toLowerCase() === "eligible" && <Input fieldName="Select Category" type="drop" state={status} setState={setStatus} returnKey="status" options={["Remove Category", "Applied"]} />}
            {sortedValue.toLowerCase() === "applied" && <Input fieldName="Select Category" type="drop" state={status} setState={setStatus} returnKey="status" options={["Eligible", "Approved"]} />}
          </div>
          <Button type="save" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditGrand;
