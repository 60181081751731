import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button } from "antd";
import { getAxiosTokenInstance } from "../../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../../context/FirebaseContext";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { EditRecieptNo } from "../EditRecieptNo";

export const exportPdfExam = async (olddata) => {
  const instance = await getAxiosTokenInstance();
  let data = {
    collegeId: olddata.collegeId,
    date: olddata.date,


  }
  let url = [`/college/reportGeneration/generateDFCR`]
  try {
    const resp = await instance.post(...url, data, {
      responseType: "blob",
    });

    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || "API Error"
    );
  }
};


const getIndividualAccounts = (data) => async () => {


  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get('/college/fees/getSubsidiaryIndividualRegister', {
      params: { ...data }
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || 'API Error');
    } else {


      return resp?.data;
    }
  } catch (error) {
    throw new Error(
      error?.response?.data?.message || error.message || 'API Error',
    );
  }
}
export const DailyFeeCollectionIndividualRegister = ({ data, date, onClose, setStateData, stateData }) => {
  const exportPdfMutationExam = useMutation(exportPdfExam);
  const [loading, setLoading] = useState(false);
  const [state, setState] = React.useState({
    filterValue: '',
    isBillDelete: false,
    page: 0,
  });

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);

  const { data: individualData, refetch: refetchData } = useQuery(['DFCRoIndividual', state?.selectedDate, state?.countData], getIndividualAccounts({ collegeId, date }));


  const onInternalMark = async () => {
    setLoading(true)
    try {
      const link = document.createElement("a");
      link.target = "_blank";
      let postData = {
        collegeId,
        date
      };

      link.download = 'Internal Mark';
      const pdfData = await exportPdfMutationExam.mutateAsync(postData);
      link.href = URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      link.click();
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  };




  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <div className={classNames.listContainer2}>
          {loading ?
            <label className={classNames.notice}>Your request is being processed… waiting for preparation…</label>
            :
            <Scrollbars style={{ height: '100%' }} >
              <div ref={componentRef} className="printable" style={{ overflow: 'scroll' }}>
                <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '25px', }}>
                    <img width='8%' height='8%' src={selectedCollege?.logo} />
                    <div style={{ width: '80%', alignItems: 'center', margin: 0, textAlign: 'center' }}>
                      <span style={{ fontSize: '30px', margin: 0, fontWeight: 900, }}  >{selectedCollege?.collegeShortName}</span>

                      <h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Daily Fee Collection Register </h5>
                      <h5 style={{ marginTop: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}> as on {date}</h5>

                    </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 60, marginTop: 4, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>




                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}>SN</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ color: 'white', textAlign: 'left', fontSize: 14, width: '20%' }}>Name</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'left', fontSize: 20, width: '9%' }}>Class</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>Ad.No.</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 14, width: '8%' }}>Rt.No.</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister2}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister3}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister4}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister5}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister6}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister7}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister8}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '6%' }}>{data?.keyValueData?.dailyFeeCollectionRegister9}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister10}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister11}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister12}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister13}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister14}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister15}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister16}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister17}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister18}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister19}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister20}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister21}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister22}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister23}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister24}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>{data?.keyValueData?.dailyFeeCollectionRegister25}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}>TOTAL</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>









                  </div>
                  {individualData?.subsidiaryRegisterData?.map((item, i) => (
                    <>
                      <div style={{ width: '100%', display: 'flex', height: ' 48px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '4%' }}>{i + 1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, isBillDelete: true, openModal: true, selectedDate: item.date, item: { _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, admisionNo: item?.admisionNo, feeId: item?.feeId, billNo: item?.billNo } }) }} style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 14, width: '20%' }}>{item?.userName}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '9%' }}>{item?.className}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '10%' }}>{item?.admisionNo}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister1 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '8%' }}>{item?.billNo || '-'}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister1, keyData: data?.keyValueData?.dailyFeeCollectionRegister1 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister1?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister2, keyData: data?.keyValueData?.dailyFeeCollectionRegister2 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister2?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister3, keyData: data?.keyValueData?.dailyFeeCollectionRegister3 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister3?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister4, keyData: data?.keyValueData?.dailyFeeCollectionRegister4 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister4?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister5, keyData: data?.keyValueData?.dailyFeeCollectionRegister5 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister5?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister6, keyData: data?.keyValueData?.dailyFeeCollectionRegister6 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister6?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister7, keyData: data?.keyValueData?.dailyFeeCollectionRegister7 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister7?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister8, keyData: data?.keyValueData?.dailyFeeCollectionRegister8 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister8?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister9, keyData: data?.keyValueData?.dailyFeeCollectionRegister9 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '6%' }}>{item?.dailyFeeCollectionRegister9?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister10, keyData: data?.keyValueData?.dailyFeeCollectionRegister10 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister10?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister11, keyData: data?.keyValueData?.dailyFeeCollectionRegister11 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister11?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister12, keyData: data?.keyValueData?.dailyFeeCollectionRegister12 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister12?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister13, keyData: data?.keyValueData?.dailyFeeCollectionRegister13 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister13?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister14, keyData: data?.keyValueData?.dailyFeeCollectionRegister14 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister14?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister15, keyData: data?.keyValueData?.dailyFeeCollectionRegister15 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister15?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister16, keyData: data?.keyValueData?.dailyFeeCollectionRegister16 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister16?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister17, keyData: data?.keyValueData?.dailyFeeCollectionRegister17 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister17?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister18, keyData: data?.keyValueData?.dailyFeeCollectionRegister18 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister18?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister19, keyData: data?.keyValueData?.dailyFeeCollectionRegister19 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister19?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister20, keyData: data?.keyValueData?.dailyFeeCollectionRegister20 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister20?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister21, keyData: data?.keyValueData?.dailyFeeCollectionRegister21 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister21?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister22, keyData: data?.keyValueData?.dailyFeeCollectionRegister22 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister22?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister23, keyData: data?.keyValueData?.dailyFeeCollectionRegister23 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister23?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister23, keyData: data?.keyValueData?.dailyFeeCollectionRegister24 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister24?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 onClick={() => { setState({ ...state, openModal: true, selectedDate: item.date, item: { keyType: 'amount', _id: item._id, userName: item?.userName, totalAmount: item?.totalAmount, date: item?.date, className: item?.className, admisionNo: item?.admisionNo, feeId: item.feeId, billNo: item?.billNo, ...item.dailyFeeCollectionRegister24, keyData: data?.keyValueData?.dailyFeeCollectionRegister25 } }) }} style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.dailyFeeCollectionRegister25?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 16, width: '8%' }}>{item?.totalAmount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      </div >
                      <div style={{ width: '100%', display: 'flex', height: 1, backgroundColor: 'black' }}></div>
                    </>
                  ))}

                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 80, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}></h5>
                    <h5 style={{ marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 14, width: '20%' }}></h5>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '9%' }}></h5>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '10%' }}>Total : </h5>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}></h5>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '6%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal2}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '6%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal3}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal4}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal5}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal6}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '6%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal7}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '6%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal8}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '6%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal9}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal10}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal11}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal12}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal13}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal14}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal15}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal16}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal17}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal18}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal19}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal20}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal21}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal22}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal23}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal24}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.dailyFeeCollectionRegisterMainTotal25}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ transform: 'rotate(-90deg)', color: 'white', textAlign: 'center', fontSize: 20, width: '8%' }}>{data?.totals?.grandTotal}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                  </div>



                  <div style={{ width: '60%', display: 'flex', flexDirection: 'row', height: 60, marginTop: 4, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                    <h5 style={{ color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}>SN</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ color: 'white', textAlign: 'left', fontSize: 14, width: '20%' }}>Head</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ color: 'white', textAlign: 'left', fontSize: 14, width: '20%' }}>Title</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>Amount</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>Challan Number</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                    <h5 style={{ color: 'white', textAlign: 'center', fontSize: 14, width: '20%' }}>Remitted Date</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                  </div>

                  {individualData?.subTotalsDetails?.map((item, i) => (
                    <>
                      <div style={{ width: '60%', display: 'flex', height: ' 48px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '4%' }}>{i + 1}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 14, width: '20%' }}>{''}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginLeft: 4, color: 'black', textAlign: 'left', fontSize: 14, width: '20%' }}>{item?.name}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '20%' }}>{item?.amount}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '20%' }}>{''}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                        <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 14, width: '20%' }}>{''}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>
                      </div >
                      <div style={{ width: '60%', display: 'flex', height: 1, alignItems: 'center', alignSelf: 'center', backgroundColor: 'black' }}></div>
                    </>
                  ))}

                  <div style={{ width: '60%', display: 'flex', flexDirection: 'row', height: 60, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center' }}>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 14, width: '4%' }}></h5>
                    <h5 style={{ marginRight: 4, color: 'white', alignSelf: 'center', fontSize: 14, width: '20%' }}></h5>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '9%' }}></h5>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 30, width: '10%' }}>Total : </h5>
                    <h5 style={{ marginRight: 4, color: 'white', textAlign: 'center', fontSize: 16, width: '8%' }}></h5>
                    <h5 style={{ color: 'white', textAlign: 'center', fontSize: 30, width: '8%' }}>{data?.totals?.grandTotal}</h5><div style={{ height: '100%', display: 'flex', width: 1, backgroundColor: 'black' }}></div>

                  </div>





                  <h5 style={{ marginTop: 12, textAlign: 'right', color: 'black', fontSize: 16 }}>Seal & Signature {moment(new Date()).format('DD MMM YYYY hh:mm A')}</h5>
                </div>
              </div>
            </Scrollbars>
          }


        </div >
        {
          state.openModal && (<EditRecieptNo item={state?.item} isBillDelete={state?.isBillDelete} refetchDemo={refetchData} setStateData={setState} stateData={state} onClose={() => {
            setState({ ...state, openModal: false });
          }} />)
        }

      </div >

      <div className={classNames.absoluteDiv}  >




        <Button className={classNames.reloadButton} onClick={() => {
          refetchData();
          setStateData({ ...stateData, countData: (stateData.countData + 1) });
        }}>
          Load
        </Button>


        <Button className={classNames.reloadButton} onClick={() => {
          onInternalMark();
        }}>
          Download
        </Button>






        <ReactToPrint
          trigger={() => <button className={classNames.reloadButton}>Print</button>}
          content={() => componentRef.current}
        />



      </div >
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div >
  );
};
