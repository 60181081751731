import React, { useContext, useEffect, useRef, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../NecttosComp/Table/Table";
import { useQuery } from "react-query";
import Input from "../../NecttosComp/Input/Input";
import { getAutoIndexClassWise, getNewClasses } from "../../pages/college/ApiServices";
import BasicCertificateVerification from "./BasicCertificateVerification";
import { getAxiosInstance, getAxiosTokenInstance } from "../../utils/axiosInstance";
import ApplicationView from "./ApplicationView";
import LoadingAnimation from "../../components/LoadingAnimation";
import { getAdmissionNumber, getClassName } from "../Form/Component";
import { getCollegeDetails } from "../Form/Register";
import BasicCertificateVerificationPG from "./BasicCertificateVerificationPG";
const getCollegeDetailsee = async ({ queryKey }: any) => {
  const [_key, collegeId] = queryKey;

  try {
    const instance = await getAxiosInstance();
    const { data } = await instance.get("/college/admissionProcess/getCollegeDetails", {
      params: { collegeId },
    });

    if (data?.statusCode === 200) {
      return data.collegeData;
    } else {
      throw new Error(data?.message || "Failed to fetch college details.");
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function CourseWiseRankList({ onClose }: { onClose: () => void }) {
  const { collegeId, user } = useContext(FirebaseContext);
  const [addNew, setAddNew] = useState<any>({});
  const [showMarks, setShowMarks] = useState<boolean>(false);
  const [showSubject, setSubject] = useState<boolean>(false);
  const [printable, setPritable] = useState<boolean>(false);
  const [filtered, setFilter] = useState<number>();
  const [selectedClass, setSelectClass] = useState("");
  const [catogory, setCatogory] = useState("");
  const [splitUps, setSplitUps] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [table, setTable] = useState<any>([]);
  const ref = useRef<any>(null);
  const { data: dataAll = [], refetch, isLoading } = useQuery(["getAllEndSSSquiry", selectedClass, catogory], getAutoIndexClassWise(collegeId, selectedClass, "", catogory));
  const { data: classes } = useQuery("getNewClasses", getNewClasses(collegeId, user?.uid));
  const tableData = dataAll?.list || [];
  const options = dataAll?.options || [];
  const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
  const { data: classesx } = useQuery([`collegeDataddd`, collegeId], getCollegeDetails);

  useEffect(() => {
    let data = [...tableData];
    if (filtered !== undefined) {
      data = data.filter((item) => item.priorityOrder === filtered);
    }
    if (searchTerm) {
      data = data.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setTable(data);
  }, [tableData, filtered, searchTerm]);

  const { tableItems, requestSort, getIcon } = useSortableData(table);

  const addNewForm = async () => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post("/college/admissionProcess/addReservation", {
        studentId: addNew?.studentId,
        collegeId,
        details: addNew?.detail,
        classId: selectedClass,
        userId: user?.uid,
        boolType: addNew?.boolType,
      });
      if (resp?.data?.statusCode === 200) {
        refetch();
        closeAnimation();
        setTimeout(() => setAddNew({ ...addNew, showReservation: false }), 500);
      } else {
        refetch();
      }
    } catch (error) {
      refetch();
    }
  };

  useEffect(() => {
    if (selectedClass) {
      const classData = classes?.list?.find((classItem: any) => classItem?._id === selectedClass);
  

      if (classData?.splitup) {
     

        const filteredTitles = classData.splitup.filter((x: any) => parseInt(x.inTake, 10) > 0).map((x: any) => x.title);
        console.log(filteredTitles);

        setSplitUps(filteredTitles);
      }
    }
  }, [selectedClass, classes?.list]);

  const [className, setClassName] = useState<any>("");

  useEffect(() => {
    if (selectedClass && classesx) {
      let classNam = classesx?.find((x: any) => x._id === selectedClass)?.className || "";
      setClassName(classNam);
    } else {
      setClassName("");
    }
  }, [selectedClass, classesx]);

  return (
    <Main title="Course-Wise Rank List" height="100%" width="100%">
      <Header>
        <div className="flex">
          <Input fieldName="Filter by Course" state={selectedClass} setState={setSelectClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="35vw" />
          <Input fieldName="Filter by Catogory" state={catogory} setState={setCatogory} options={splitUps} type="drop" width="15vw" />
          <Input fieldName="Search" state={searchTerm} setState={setSearchTerm} type="text" width="15vw" />
          <view className="h-[50px]  rounded-md p-3 m-2 px-2 bg-white">
            <h5> Total Applications: {tableItems?.length}</h5>
          </view>
          {filtered && (
            <view onClick={() => setFilter(undefined)} className="h-[50px]  rounded-md p-3 m-2 px-2 bg-red-400">
              <h5>Show All</h5>
            </view>
          )}
        </div>
        <div className="flex">
          <Button type={showMarks ? "save" : "doc"} onClick={() => setShowMarks(!showMarks)}>
            {showMarks ? "Hide Marks" : "Show Marks"}
          </Button>
          <Button type={showSubject ? "search" : "update"} onClick={() => setSubject(!showSubject)}>
            {showSubject ? "Hide Subjects" : "Show Subjects"}
          </Button>

          <Button type={printable ? "search" : "update"} onClick={() => setPritable(!printable)}>
            {printable ? "Hide Printable" : "Show Printape"}
          </Button>
          <Button type="print" fileName={className} tableRef={ref.current}>
            Print
          </Button>
          <Button type="excel" tableRef={ref.current}>
            Excel
          </Button>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      {!printable && (
        <Thead>
          <Tr>
            <Th position={0}>S.N.</Th>
            <Th position={1} onClick={() => requestSort("applicationNo")} icon={getIcon("applicationNo")}>
              Name
            </Th>
            {!showMarks ? (
              showSubject ? (
                <>
                  <Th position={0} onClick={() => requestSort("plusTwoBoard")} icon={getIcon("plusTwoBoard")}>
                    Board
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject1Name")} icon={getIcon("subject1Name")}>
                    Sub 1
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject2Name")} icon={getIcon("subject2Name")}>
                    Sub 2
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject3Name")} icon={getIcon("subject3Name")}>
                    Sub 3
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject4Name")} icon={getIcon("subject4Name")}>
                    Sub 4
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject5Name")} icon={getIcon("subject5Name")}>
                    Sub 5
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject6Name")} icon={getIcon("subject6Name")}>
                    Sub 6
                  </Th>
                  <Th position={3} onClick={() => requestSort("subject7Name")} icon={getIcon("subject7Name")}>
                    Sub 7
                  </Th>
                </>
              ) : (
                <>
                  <Th position={0}>App.No.</Th>
                  {!printable && (
                    <>
                      <Th position={2}>Mobile</Th>
                      <Th position={3}>Veiw</Th>
                    </>
                  )}
                  <Th position={0} onClick={() => requestSort("priorityOrder")} icon={getIcon("priorityOrder")}>
                    Priority
                  </Th>
                  <Th position={2}>Quota</Th>
                  {!printable && (
                    <>
                      <Th position={3} onClick={() => requestSort("isReserve")} icon={getIcon("isReserve")}>
                        Reservation
                      </Th>
                      <Th position={3} onClick={() => requestSort("isReserve")} icon={getIcon("isReserve")}>
                        Reservation By
                      </Th>
                    </>
                  )}
                </>
              )
            ) : (
              <>
                <Th position={0} onClick={() => requestSort("plusTwoBoard")} icon={getIcon("plusTwoBoard")}>
                  Board
                </Th>
                <Th position={0} onClick={() => requestSort("subject1ObtainedMark")} icon={getIcon("subject1ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject1TotalMark")} icon={getIcon("subject1TotalMark")}>
                  TM
                </Th>
                <Th position={0} onClick={() => requestSort("subject2ObtainedMark")} icon={getIcon("subject2ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject2TotalMark")} icon={getIcon("subject2TotalMark")}>
                  TM
                </Th>
                <Th position={0} onClick={() => requestSort("subject3ObtainedMark")} icon={getIcon("subject3ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject3TotalMark")} icon={getIcon("subject3TotalMark")}>
                  TM
                </Th>
                <Th position={0} onClick={() => requestSort("subject4ObtainedMark")} icon={getIcon("subject4ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject4TotalMark")} icon={getIcon("subject4TotalMark")}>
                  TM
                </Th>
                <Th position={0} onClick={() => requestSort("subject5ObtainedMark")} icon={getIcon("subject5ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject5TotalMark")} icon={getIcon("subject5TotalMark")}>
                  TM
                </Th>
                <Th position={0} onClick={() => requestSort("subject6ObtainedMark")} icon={getIcon("subject6ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject6TotalMark")} icon={getIcon("subject6TotalMark")}>
                  TM
                </Th>
                <Th position={0} onClick={() => requestSort("subject7ObtainedMark")} icon={getIcon("subject7ObtainedMark")}>
                  OM
                </Th>
                <Th position={0} onClick={() => requestSort("subject7TotalMark")} icon={getIcon("subject7TotalMark")}>
                  TM
                </Th>
              </>
            )}
            {!selectedClass ? (
              <Th position={0}>Class Name</Th>
            ) : (
              <Th position={0} onClick={() => requestSort("rank")} icon={getIcon("rank")}>
                Ranking
              </Th>
            )}
            <Th position={0} onClick={() => requestSort("earnedMark")} icon={getIcon("earnedMark")}>
              Index Mark
            </Th>
            {!printable && (
              <>
                <Th position={3} onClick={() => requestSort("isCorrected")} icon={getIcon("isCorrected")}>
                  Verification
                </Th>
                <Th position={3} onClick={() => requestSort("isCorrected")} icon={getIcon("isCorrected")}>
                  Verification By
                </Th>
              </>
            )}
          </Tr>
        </Thead>
      )}
      <>
        {isLoading ? (
          <LoadingAnimation customWidth="250px" customHeight="250px" />
        ) : (
          <Body>
            <div ref={ref} className="printable overflow-auto ">
              {printable ? (
                <table className="min-w-full table-auto">
                  <thead>
                    <tr>
                      <td className="p-1 bg-white" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                        <img src={collegeData?.dashBoardLink} alt="College Logo" className="h-[100px] w-auto  mx-auto" />
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-1 text-center text-black bg-white text-xl font-bold" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                        Course: {className}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="p-1 text-center text-black  bg-white text-lg" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                        Course: {getClassName(selectedClass, classesx)}
                      </td>
                    </tr> */}
                    <tr>
                      <td className="p-1 text-center text-black bg-white text-lg" style={{ width: "70%", fontFamily: "Times New Roman" }}>
                        For any queries, please contact us at:
                        <br />
                        Phone: 9446600852, 9446600853, 9496435170
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="p-6 bg-gray-100 rounded-md shadow-md">
                  <div className="flex flex-wrap justify-center gap-3">
                    {options.map((option: any, index: number) => (
                      <span onClick={() => setFilter(index + 1)} key={index} className={`px-4 py-2 text-sm text-gray-700 rounded ${filtered === index + 1 ? "bg-green-300" : "bg-purple-300"}`}>
                        {option.name}: {option.value}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <Table width="100%" innerWidth={["4%", "10%", "10%", "8%"]}>
                {printable && (
                  <Thead>
                    <Tr>
                      <Th position={0}>S.N.</Th>
                      <Th position={1} onClick={() => requestSort("applicationNo")} icon={getIcon("applicationNo")}>
                        Name
                      </Th>
                      {!showMarks ? (
                        showSubject ? (
                          <>
                            <Th position={0} onClick={() => requestSort("plusTwoBoard")} icon={getIcon("plusTwoBoard")}>
                              Board
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject1Name")} icon={getIcon("subject1Name")}>
                              Sub 1
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject2Name")} icon={getIcon("subject2Name")}>
                              Sub 2
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject3Name")} icon={getIcon("subject3Name")}>
                              Sub 3
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject4Name")} icon={getIcon("subject4Name")}>
                              Sub 4
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject5Name")} icon={getIcon("subject5Name")}>
                              Sub 5
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject6Name")} icon={getIcon("subject6Name")}>
                              Sub 6
                            </Th>
                            <Th position={3} onClick={() => requestSort("subject7Name")} icon={getIcon("subject7Name")}>
                              Sub 7
                            </Th>
                          </>
                        ) : (
                          <>
                            <Th position={0}>App.No.</Th>
                            {!printable && (
                              <>
                                <Th position={2}>Mobile</Th>
                                <Th position={3}>Veiw</Th>
                              </>
                            )}
                            <Th position={0} onClick={() => requestSort("priorityOrder")} icon={getIcon("priorityOrder")}>
                              Priority
                            </Th>

                            <Th position={2}>Quota</Th>
                            {!printable && (
                              <>
                                <Th position={3} onClick={() => requestSort("isReserve")} icon={getIcon("isReserve")}>
                                  Reservation
                                </Th>
                                <Th position={3} onClick={() => requestSort("isReserve")} icon={getIcon("isReserve")}>
                                  Reservation By
                                </Th>
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <Th position={0} onClick={() => requestSort("plusTwoBoard")} icon={getIcon("plusTwoBoard")}>
                            Board
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject1ObtainedMark")} icon={getIcon("subject1ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject1TotalMark")} icon={getIcon("subject1TotalMark")}>
                            TM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject2ObtainedMark")} icon={getIcon("subject2ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject2TotalMark")} icon={getIcon("subject2TotalMark")}>
                            TM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject3ObtainedMark")} icon={getIcon("subject3ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject3TotalMark")} icon={getIcon("subject3TotalMark")}>
                            TM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject4ObtainedMark")} icon={getIcon("subject4ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject4TotalMark")} icon={getIcon("subject4TotalMark")}>
                            TM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject5ObtainedMark")} icon={getIcon("subject5ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject5TotalMark")} icon={getIcon("subject5TotalMark")}>
                            TM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject6ObtainedMark")} icon={getIcon("subject6ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject6TotalMark")} icon={getIcon("subject6TotalMark")}>
                            TM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject7ObtainedMark")} icon={getIcon("subject7ObtainedMark")}>
                            OM
                          </Th>
                          <Th position={0} onClick={() => requestSort("subject7TotalMark")} icon={getIcon("subject7TotalMark")}>
                            TM
                          </Th>
                        </>
                      )}
                      {!selectedClass ? (
                        <Th position={0}>Class Name</Th>
                      ) : (
                        <Th position={0} onClick={() => requestSort("rank")} icon={getIcon("rank")}>
                          Ranking
                        </Th>
                      )}
                      <Th position={0} onClick={() => requestSort("earnedMark")} icon={getIcon("earnedMark")}>
                        Index Mark
                      </Th>

                      {!printable && (
                        <>
                          <Th position={0} onClick={() => requestSort("earnedMark")} icon={getIcon("earnedMark")}>
                            Religion
                          </Th>
                          <Th position={0} onClick={() => requestSort("earnedMark")} icon={getIcon("earnedMark")}>
                            Caste
                          </Th>
                          <Th position={3} onClick={() => requestSort("isCorrected")} icon={getIcon("isCorrected")}>
                            Verification
                          </Th>
                          <Th position={3} onClick={() => requestSort("isCorrected")} icon={getIcon("isCorrected")}>
                            Verification By
                          </Th>
                        </>
                      )}
                    </Tr>
                  </Thead>
                )}
                <Tbody height="auto">
                  {tableItems?.map((x: any, i: number) => (
                    <Tr>
                      <Td index={i} position={0}>
                        {1 + i}
                      </Td>
                      <Td index={i} position={1}>
                        {x?.name?.toUpperCase()}
                      </Td>
                      {!showMarks ? (
                        showSubject ? (
                          <>
                            <Td index={i} position={0}>
                              {x?.plusTwoBoard.slice(0, 4)}
                            </Td>
                            <Td highlight="#1a6692" color="white" index={i} position={3}>
                              {x?.subject1Name}
                            </Td>
                            <Td highlight="#0d5c4a" color="white" index={i} position={3}>
                              {x?.subject2Name}
                            </Td>
                            <Td highlight="#1a6692" color="white" index={i} position={3}>
                              {x?.subject3Name}
                            </Td>
                            <Td highlight="#0d5c4a" color="white" index={i} position={3}>
                              {x?.subject4Name}
                            </Td>
                            <Td highlight="#1a6692" color="white" index={i} position={3}>
                              {x?.subject5Name}
                            </Td>
                            <Td highlight="#0d5c4a" color="white" index={i} position={3}>
                              {x?.subject6Name}
                            </Td>
                            <Td highlight="#1a6692" color="white" index={i} position={3}>
                              {x?.subject7Name}
                            </Td>
                          </>
                        ) : (
                          <>
                            <Td index={i} position={0}>
                              {getAdmissionNumber(collegeId, x.batchName === "2024-2026" ? "PG" : "UG", x?.applicationNo)}
                            </Td>
                            {!printable && (
                              <>
                                <Td index={i} position={2}>
                                  {x?.phoneNumber}
                                </Td>
                                <Td index={i} position={3}>
                                  <Button
                                    type="doc"
                                    onClick={() => {
                                      setAddNew({ ...addNew, viewMode: true, _id: x._id, tyeUG: x.batchName === "2024-2026" ? "PG" : "UG" });
                                    }}
                                  >
                                    ViewApp
                                  </Button>
                                </Td>
                              </>
                            )}
                            <Td index={i} position={0}>
                              {x?.priorityOrder}
                            </Td>
                            <Td index={i} position={2}>
                              {x?.appliedto}
                            </Td>
                            {!printable && (
                              <>
                                {x?.isReserve ? (
                                  <Td index={i} position={3}>
                                    <Button
                                      type="close"
                                      onClick={() => {
                                        setAddNew({ ...addNew, showReservation: true, studentId: x?._id, boolType: false });
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </Td>
                                ) : (
                                  <Td index={i} position={3}>
                                    <Button
                                      type="save"
                                      onClick={() => {
                                        setAddNew({ ...addNew, showReservation: true, studentId: x?._id, boolType: true });
                                      }}
                                    >
                                      Reserve
                                    </Button>
                                  </Td>
                                )}
                                <Td index={i} position={3}>
                                  {x?.reservedBy}
                                </Td>
                              </>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          <Td index={i} position={0}>
                            {x?.plusTwoBoard.slice(0, 4)}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject1ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject1TotalMark}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject2ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject2TotalMark}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject3ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject3TotalMark}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject4ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject4TotalMark}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject5ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject5TotalMark}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject6ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject6TotalMark}
                          </Td>
                          <Td highlight="#0d5c4a" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject7ObtainedMark}
                          </Td>
                          <Td highlight="#1a6692" color="white" textAlign="center" fontSize="15px" index={i} position={0}>
                            {x?.subject7TotalMark}
                          </Td>
                        </>
                      )}
                      {!selectedClass ? (
                        <Td index={i} position={0}>
                          {x?.selectedClass?.className}
                        </Td>
                      ) : (
                        <Td index={i} position={0}>
                          {x?.rank}
                        </Td>
                      )}

                      <Td index={i} position={0}>
                        {x && !isNaN(x.earnedMark) ? x.earnedMark?.toFixed(0) : "Invalid mark"}
                      </Td>

                      {!printable && (
                        <>
                          <Td index={i} position={0}>
                            {x?.religion}
                          </Td>
                          <Td index={i} position={0}>
                            {x?.caste}
                          </Td>
                          {x?.isCorrected ? (
                            <Td index={i} position={3}>
                              <Button
                                type="save"
                                onClick={() => {
                                  setAddNew({
                                    ...addNew,
                                    ...(x.batchName === "2024-2026" ? { showBasicCertificatePg: true } : { showBasicCertificate: true }),
                                    details: x,
                                  });
                                }}
                              >
                                Verified
                              </Button>
                            </Td>
                          ) : (
                            <Td index={i} position={3}>
                              <Button
                                type="doc"
                                onClick={() => {
                                  setAddNew({
                                    ...addNew,
                                    ...(x.batchName === "2024-2026" ? { showBasicCertificatePg: true } : { showBasicCertificate: true }),
                                    details: x,
                                  });
                                }}
                              >
                                Verify
                              </Button>
                            </Td>
                          )}
                          <Td index={i} position={3}>
                            {x?.correctedBy}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
            <BasicCertificateVerification showWindow={addNew?.showBasicCertificate} selClass={selectedClass} refectd={refetch} date={addNew} setDate={setAddNew} />
            <BasicCertificateVerificationPG showWindow={addNew?.showBasicCertificatePg} selClass={selectedClass} refectd={refetch} date={addNew} setDate={setAddNew} />
            {addNew.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}

            {addNew?.showReservation && (
              <Main width="40%" height="40%" title="Reserve Students">
                <Header>
                  <Button
                    type="close"
                    onClick={() => {
                      closeAnimation();
                      setTimeout(() => setAddNew({ ...addNew, showReservation: false }), 500);
                    }}
                  >
                    Close
                  </Button>
                </Header>
                <Body>
                  <div className="flex flex-col w-full">
                    <Input width="100%" fieldName="Details" returnKey="detail" state={addNew} setState={setAddNew} type="text" />
                    <Button
                      type="save"
                      onClick={() => {
                        addNewForm();
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </Body>
              </Main>
            )}
          </Body>
        )}
      </>
    </Main>
  );
}

export default CourseWiseRankList;
