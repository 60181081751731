import React, { useRef, useContext } from 'react';
import ReactToPrint from 'react-to-print';
import classNames from "./billNumberModal.module.scss";
import { Button, Table } from "antd";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import moment from "moment";


function NumInWords(number) {
  const first = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen'];
  const tens = [' ', ' ', 'twenty ', 'thirty ', 'forty ', 'fifty ', 'sixty ', 'seventy ', 'eighty ', 'ninety '];
  const mad = [' ', 'thousand ', 'million ', 'billion', 'trillion '];
  let word = '';

  for (let i = 0; i < mad.length; i++) {
    let tempNumber = number % (100 * Math.pow(1000, i));
    if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
      if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
        word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + '' + word;
      } else {
        word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + ' ' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + '' + word;
      }
    }

    tempNumber = number % (Math.pow(1000, i + 1));
    if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hundred ' + word;
  }
  return word + 'only';
}
function sumWithKey(data, key) {
  return data?.reduce((x, y) => x + y[key], 0);
}
export const BillNumberModal = ({ data, onClose }) => {
  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);





  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >
        <div>

          <div ref={componentRef} className="printable">
            <img style={{ position: 'absolute', width: '60%', marginTop: 50, marginLeft: 200, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />

            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '25px',
                padding: '2%'
              }}
            >
              <div

                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  borderRadius: '25px',
                }}


              >
                <img width='12%' height='12%' src={selectedCollege?.logo} />
                <div
                  style={{
                    width: '80%',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <span
                    style={{
                      fontSize: '30px',
                      fontWeight: 900,
                    }}
                  >{selectedCollege?.collegeShortName}</span>
                  <p style={{
                    fontSize: '25px',
                    fontWeight: 900,
                  }}
                  >{selectedCollege?.accreditedGrade}</p>
                  <p style={{ fontSize: '14px', fontWeight: 900 }}>{selectedCollege?.address}, Phone: {selectedCollege?.phoneNumber}</p>
                </div>
              </div>
              <h5 style={{ marginTop: 12, fontSize: '30px', alignSelf: 'center' }}>Reciept</h5>

              <div style={{ color: 'black', width: '100%', display: 'flex', flexDirection: 'row', }}>
                <p style={{ color: 'black', fontSize: '18px', margin: 0, fontWeight: 900, width: '6%' }}>To:</p>
                <p style={{ color: 'black', fontSize: '18px', margin: 0, fontWeight: 900, width: '14%' }}>{data?.admnNo}</p>
                <p style={{ color: 'black', fontSize: '18px', margin: 0, fontWeight: 900, width: '50%' }}>{data?.userName}</p>
                <p style={{ color: 'black', fontSize: '18px', margin: 0, fontWeight: 900, width: '30%' }}>{data?.className}</p>
              </div>

              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', }}>
                <p style={{ color: 'black', width: '100%', fontSize: '18px', textAlign: 'right', margin: 0, fontWeight: 900 }}>Date : {moment(new Date()).format('DD MMM YYYY hh:mm A')}</p>
              </div>

              <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                height: 30,
                margin: 0,
                backgroundColor: 'black',
                alignItems: 'center',
                alignSelf: 'center',
                marginBottom: 12,
              }}>
                <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 20, width: '15%' }}>Recp No</h5>
                <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'center', fontSize: 20, width: '50%' }}>Particulars</h5>
                <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 20, width: '15%' }}>Status</h5>
                <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 20, width: '20%' }}>Amount</h5>
              </div>
              {data?.collectedData.map((item) => (
                <div style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignSelf: 'center'
                }}>
                  <h5 style={{ textAlign: 'center', fontSize: 20, width: '15%' }}>{item.billNumber}</h5>
                  <h5 style={{ alignSelf: 'center', fontSize: 20, width: '50%' }}>{item.title}</h5>
                  <h5 style={{ textAlign: 'center', fontSize: 20, width: '15%' }}>{item.status}</h5>
                  <h5 style={{ marginRight: 4, textAlign: 'right', fontSize: 20, width: '20%' }}>{item.amount}</h5>
                </div>
              ))}
              <div style={{
                width: '100%',
                display: 'flex',
                height: 3,
                flexDirection: 'row',
                backgroundColor: 'black',
                alignItems: 'center',
                alignSelf: 'center'
              }}></div>
              <h5 style={{ marginTop: 12, alignSelf: 'center', fontSize: 25 }}>Total Amount Recieved: {sumWithKey(data?.collectedData, 'amount')}/-</h5>
              <h4 style={{ marginTop: 0, alignSelf: 'center', fontSize: 20 }}>{NumInWords(sumWithKey(data?.collectedData, 'amount'))}</h4>
              <h5 style={{ marginTop: 12, fontSize: 20 }}>Seal & Signature</h5>

            </div>
          </div>
        </div>

        <Button className={classNames.closeButton} onClick={onClose}> Close </Button>
        <ReactToPrint
          trigger={() => <button className={classNames.printButton}>Print</button>}
          content={() => componentRef.current}
        />
      </div>
    </div >
  );
};
