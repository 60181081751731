import React from 'react';
import Navbar from './navbar';
// import PageHeader from './page-header';
import JobApplyForm from './apply-form';
import FooterBar from './footerBar';

const JobApply = () => {
    return <div>
        <Navbar />
        <JobApplyForm customclass="pd-top-190" />
        <FooterBar />
    </div>
}

export default JobApply

