import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button } from "antd";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useMutation, useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getMainAccounts } from "../../ApiServices";
import Scrollbars from "react-custom-scrollbars";
import { ROLES_manage } from "../../../../config/constants";
import { Link } from "react-router-dom";
import UserRow from "../../../../components/RoleManageTable/UserRow";
import addUser from "../../../../static/images/footerIcons/addUser.png";
import LoadingAnimation from "../../../../components/LoadingAnimation";


const getUserList = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { type, collegeId, departmentId, classId }] = queryKey;
  const instance = await getAxiosTokenInstance();
  const resp = await instance.get(
    `/college/users/get?type=${type}&collegeId=${collegeId}&departmentId=${departmentId}&classId=${classId}`
  );
  if (resp.data.statusCode === 400) {
    throw new Error(resp.data.message || "API Error");
  }
  return resp.data;
};

const getClasses = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { collegeId, role }] = queryKey;
  if (role === 'tutor') {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(
      `/college/users/getClasses?collegeId=${collegeId}`
    );
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data.list;
  }

};
const getDepartment = async ({ queryKey }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { collegeId, role }] = queryKey;
  if (role === 'hod') {
    const instance = await getAxiosTokenInstance();
    const resp = await instance.get(
      `/college/users/getDepartment?collegeId=${collegeId}`
    );
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    }
    return resp.data.list;
  }
};

const addRole = async (data) => {
  const instance = await getAxiosTokenInstance();
  const resp = await instance.post("/college/role/addRole", data);
  return resp.data;
};
function SearchBox({ search, setSearch, collegeId, refetch, role, departmentId, classId }) {

  const { data } = useQuery(["userList", { type: "", collegeId }], getUserList);

  const { mutate, isLoading } = useMutation(addRole, {
    onSuccess: (data) => {
      if (data.statusCode === 400) {
        message.error(
          data.response.data.message || data.message || "API Error"
        );
      } else {
        message.success("ROLES_manage Added");
        refetch();
      }
    },
    onError: (error) => {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    },
  });

  return (
    <>
      <div className={classNames.searchBox}>
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <Scrollbars className={classNames.listWrap}>
            {data?.list
              ?.filter((user) =>
                user?.name?.toLowerCase().includes(search.toLowerCase())
              )
              ?.map((user) => (
                <div className={classNames.searchUser}>
                  <img
                    className={classNames.dp}
                    src={
                      user?.photoURL ||
                      `https://avatars.dicebear.com/api/initials/${user.name}.svg`
                    }
                    alt=""
                  />
                  <div className={classNames.name}>{user.name}</div>
                  <div
                    className={classNames.btnWrap}
                    onClick={() => {
                      mutate({
                        collegeId,
                        userId: user._id,
                        role,
                        departmentId,
                        classId
                      });
                      setSearch("");
                    }}
                  >
                    <img className={classNames.btnAdd} src={addUser} alt="" />
                  </div>
                </div>
              ))}
          </Scrollbars>
        )}
      </div>
      {isLoading && (
        <div className={classNames.loading}>
          <LoadingAnimation />
        </div>
      )}
    </>
  );
}


export const AdmissionRoll = ({ onClose, title, role }) => {

  const [departmentId, setDepartmentId] = useState('')
  const [department, setDepartment] = useState('')
  const [classId, setClassId] = useState('')
  const [className, setClass] = useState('')


  const { collegeId } = useContext(FirebaseContext);
  const removeROLES_manage = async ({ userId }) => {
    try {
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.put("/college/role/removeUserRole", { userId, role, collegeId, departmentId, classId });
      window.alert(`Deleted from ${role} successfully`)
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };
  const { data: datalist, refetch } = useQuery(
    ["userList", { type: role, collegeId, departmentId, classId }],
    getUserList
  );


  console.log({ role });


  const list = datalist?.list
  const [search, setSearch] = useState("");



  return (
    <div className={classNames.container}>
      <div className={classNames.modal} >

        <div className={classNames.listContainer2}>
          <Scrollbars style={{ height: '100%' }} >
            <SearchBox
              search={search}
              setSearch={setSearch}
              collegeId={collegeId}
              refetch={refetch}
              role={role}
              departmentId={departmentId}
              classId={classId}
            />
            <label className={classNames.heading}>{title?.toUpperCase()}</label>

            <div className={classNames.card_container}>
              {list?.map((role, i) => {
                return (
                  <div
                    className={classNames.role_card}>
                    <Button className={classNames.reloadButton} onClick={async () => {
                      await removeROLES_manage({ userId: role._id });
                    }}>
                      Remove
                    </Button>
                    <h2>{role.name}</h2>
                    <img src={role.photoURL} />
                  </div>
                );
              })}
            </div>
          </Scrollbars>
        </div>
      </div>
      <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>
    </div>
  );
};
