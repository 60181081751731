import React, { useContext, useEffect, useState } from "react";
import "./ApplicationForm.css";
import Select from "antd/lib/select";
import applicationForm from "../../../../static/images/naac/File 1.png";
import classNames from "../../Admission/StudentData/detailView.module.scss";
import * as Yup from "yup";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import CurrentAddressform from "./CurrentAddressform";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import AddressInformation from "../../Profile/AddressInformation/AddressInformation";
import Traveling from "../../Profile/Traveling/Traveling";
import Qualification from "../../Profile/Qualification/Qualification";
import Publications from "../../Profile/Publications/Publications";
import Extracurricular from "../../Profile/Extracurricular/Extracurricular";
import BankDetails from "../../Profile/BankDetails/BankDetails";
import Family from "../../Profile/Family/Family";
import Document from "../../Profile/Documents/Documents";
import { getPenddingAdmission } from "../../ApiServices";
import { useQuery } from "react-query";
import PersonalInfo from "../../../../NecttosApp/Profile/PersonalInfo";
const { Option } = Select;

function ApplicationForm({ onClose }) {
  const { collegeId, collegeList, user } = useContext(FirebaseContext);
  const [student, setStudent] = useState();
  const [memberData, setMemberData] = useState();
  const [items, setItems] = useState({});
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const { data, refetch, status } = useQuery(
    "getPenddingAdmissions",
    getPenddingAdmission(collegeId, "", user.uid, "department")
  );

  const getUser = async () => {
    const instance = await getAxiosTokenInstance();

    const { data } = await instance.get("/college/profile/get", {
      params: {
        profileId: student?._id,
        groupData: true,
        isStaff: false,
        collegeId,
        isGrand: false,
      },
    });

    console.log({ user, data });
    setMemberData(data?.profileData);
  };

  useEffect(() => {
    if (student) {
      getUser();
    }
  }, [student]);
  const icons = [

    { classification: 'Profile', keyData: 'profileInformation', title: 'Personal Information', img: "./profile/personal.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'officialInformation', title: 'Official Information', img: "./profile/official.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'addressDetails', title: 'Address Details', img: "./profile/address.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'educationalQualification', title: 'Educational Qualifications', img: "./profile/education.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'profilePhoto', title: 'Profile Photo', img: "./profile/profile.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'bankInformations', title: 'Bank Informations', img: "./profile/bank.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'curriculamActivies', title: 'Co-curriculam Activies', img: "./profile/curriculam.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'travelingHistory', title: 'Traveling History', img: "./profile/traveling.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['admin', 'teacher', 'student', 'staff', 'principal'] },
    { classification: 'Profile', keyData: 'family', title: 'Family', img: "./profile/family.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['student'] },
    { classification: 'Profile', keyData: 'document', title: 'Document', img: "./profile/document.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['student'] },
    { classification: 'Profile', keyData: 'ePayment', title: 'E-payment', img: "./profile/document.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['student'] },
    { classification: 'Profile', keyData: 'course', title: 'Course', img: "./profile/document.png", instituitionType: ['selfCollege', 'aidedCollege', 'govtCollege'], allowedUsers: ['student'] },

  ];

  return (
    <div className="applicationForm">
      <button onClick={onClose} className="fixed right-3 top-3">
        Close
      </button>
      <div className={"subContainer overflow-auto"}>
        <div
          className={"sideBar bg-transparent"}
          style={{ background: "white" }}
        >
          <div className=" grid grid-cols-1 gap-2 overflow-auto">
            {data?.list?.map((x) => (
              <div
                className={
                  "cursor-pointer bg-white shadow text-black rounded-4xl flex justify-start items-center ps-3"
                }
                style={{ background: "white" }}
                onClick={() => {
                  setStudent(x);
                }}
              >
                <span style={{ fontSize: "14px" }}>{x?.name}</span>
              </div>
            ))}
          </div>
        </div>
        <div className=" h-[95vh] w-full overflow-auto pb-3 pt-3 ps-3">
          {student && (
            <>
              <h1>{student?.name}</h1>
              <div className="w-4/5 bg-gray-200 rounded-full h-4 dark:bg-gray-700">
                <div
                  className="bg-blue-600 flex justify-center items-center text-white h-4 rounded-full"
                  style={{ width: `${80}%` }}
                >Over ll Percetange 80%</div>
              </div>
              <div className=" grid grid-cols-4 pt-3 float-left gap-2 w-2/3 ms-10 overflow-auto">
                {icons?.map((item, _i) => (
                  <>
                    <button
                      onClick={() => {
                        console.log(item?.keyData);
                        setItems({ [item?.keyData]: true });
                      }}
                      className="flex flex-col items-center justify-center w-40  futurestic-bg-hover p-4 rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
                    >
                      <div key={item.key} className="w-16 h-16 bg-white rounded-full p-2 mb-3">
                        <img
                          src={item?.img}
                          alt={item?.title}
                          className="object-cover rounded-full"
                        />
                      </div>
                      <h2 className="text-white text-lg font-semibold mb-2">{item?.title}</h2>
                      <div className="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700">
                        <div
                          className="bg-blue-600 flex justify-center items-center text-white h-4 rounded-full"
                          style={{ width: `${100}%` }}
                        >100%</div>
                      </div>
                    </button>
                  </>

                ))}
              </div>

              <div className="me-3 w-1/4 mt-2 float-right futurestic-bg shadow-lg rounded-lg p-4">
                <h3 className="text-white text-lg font-semibold mb-2">
                  Special Message for the Student
                </h3>
                <ul className="list-disc list-inside  bg-opacity-20 p-3 rounded">
                  <li className="text-white mb-1">
                    This student has not been admitted; delete their record
                    immediately.
                  </li>
                  <li className="text-white">
                    The student is admitted to another college.
                  </li>
                </ul>
              </div>
            </>
          )}
          {items?.profileInformation && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              {" "}
              <PersonalInfo
                studentId={student?._id}
                onClose={() =>
                  setItems({ ...items, profileInformation: false })
                }
              />
            </div>
          )}
          {items?.addressDetails && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <AddressInformation
                onClose={() => setItems({ ...items, addressDetails: false })}
              />
            </div>
          )}
          {items?.travelingHistory && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <Traveling
                onClose={() => setItems({ ...items, travelingHistory: false })}
              />
            </div>
          )}
          {items?.educationalQualification && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <Qualification
                onClose={() =>
                  setItems({ ...items, educationalQualification: false })
                }
              />
            </div>
          )}
          {items?.publications && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <Publications
                onClose={() => setItems({ ...items, publications: false })}
              />
            </div>
          )}
          {items?.curriculamActivies && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <Extracurricular
                onClose={() =>
                  setItems({ ...items, curriculamActivies: false })
                }
              />
            </div>
          )}
          {items?.bankInformations && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <BankDetails
                onClose={() => setItems({ ...items, bankInformations: false })}
              />
            </div>
          )}
          {items?.family && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <Family onClose={() => setItems({ ...items, family: false })} />
            </div>
          )}
          {items?.document && (
            <div className="fixed inset-0 bg-[#00000045] flex justify-center items-center">
              <Document
                onClose={() => setItems({ ...items, document: false })}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApplicationForm;

const validationSchema = Yup.object({
  name: Yup.string().trim().required("Please Enter Your Full Name"),
  dob: Yup.string().trim().required("Please Enter Your Date of Birth"),
  gender: Yup.string().trim().required("Please Seletct Your Gender"),
  bloodGroup: Yup.string().trim().required("Please Seletct Your Blood Group"),
  email: Yup.string()
    .trim()
    .email("Please Enter A Valid Email")
    .required("Please Enter Your Email"),
  religion: Yup.string().trim().required("Please Select your Religion"),
  category: Yup.string().trim().required("Please Select your Category"),
  caste: Yup.string().trim().required("Please Input your Caste"),

  secondLanguage: Yup.string()
    .trim()
    .required("Please Input your Second Language"),
  stay: Yup.string().trim().required("Please Input your Stay"),
  disabilities: Yup.string().trim().required("Please Input your Disabilities"),
  provertyLine: Yup.string().trim().required("Please Input your Proverty Line"),
  annualIncome: Yup.string().trim().required("Please Input your Annual Income"),
  meritalStatus: Yup.string()
    .trim()
    .required("Please Input your Merital Status"),
  annualIncome: Yup.string().trim().required("Please Input your Proverty Line"),
}).unknown(true);
