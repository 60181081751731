import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Header, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../NecttosComp/Table/Table';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';

interface Props {
	onClose: () => void;
}

const getUsers = (collegeId: any, search: any) => async () => {
	const instance = await getAxiosTokenInstance();
	let resp;
	let params = {
		search,
		collegeId: collegeId,
		type: 'teacher',
	};
	try {
		resp = await instance.get('/college/hungry/getUsers', {
			params,
		});
		if (resp?.data?.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		}
	} catch (error) {
		throw error;
	}
	return resp?.data?.result || [];
};

const getProfac = (collegeId: string, startDate: string, endDate: string, teacherId: string) => async () => {
	const instance = await getAxiosTokenInstance();
	let resp;
	let params = {
		startDate,
		collegeId,
		endDate,
		teacherId,
	};
	try {
		resp = await instance.get('/college/attendance/report/proFacMonitor', {
			params,
		});

		if (resp?.data?.statusCode === 400) {
			throw new Error(resp.data.message || 'API Error');
		}
	} catch (error) {
		throw error;
	}
	return resp?.data?.proFacData || [];
};

function ProFacMonitor({ onClose }: Props) {
	const ref = useRef(null);
	const [search, setSearch] = useState<string>('');
	const [date, setDate] = useState<any>({ startDate: '', endDate: '', teacher: '' });
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const selectedCollege = collegeList?.find((x: any) => x.collegeId === collegeId);
	const { data: filterSubjects } = useQuery(['getAllSubjectsSearch', search], getUsers(collegeId, search));
	const { data } = useQuery(['ProfacMonit', date.startDate, date.endDate, date?.teacher, collegeId], getProfac(collegeId, date.startDate, date.endDate, date.teacher));

	console.log(data);
	useEffect(() => {
		console.log(date);
	}, [date]);

	return (
		<Main height='80vh' width='80vw' title='Profac Monitor'>
			<Header>
				<div className='flex'>
					<Input fieldName='Start Date' state={date} setState={setDate} returnKey='startDate' type='date' />
					<Input fieldName='End Date' state={date} setState={setDate} returnKey='endDate' type='date' />
					<Input
						fieldName='Select Teacher'
						state={date}
						setState={setDate}
						onChange={(e) => {
							console.log(e.value);
							if (e.name) {
								setSearch(e.name);
							} else {
								setSearch(e.value);
							}
						}}
						returnKey='teacher'
						type='drop'
						typable
						options={filterSubjects}
						optionDisplay='name'
						optionKey='_id'
						didntShowKey
					/>
				</div>
				<div className='flex'>
					<Button type='print' tableRef={ref.current}>
						Print
					</Button>
					<Button type='excel' tableRef={ref.current}>
						Excel
					</Button>
					<Button type='pdf' pdfId='emp'>
						PDf
					</Button>
					<Button type='close' onClick={onClose}>
						Close
					</Button>
				</div>
			</Header>
			<Body>
				<div className='h-[60vh] printable overflow-auto' ref={ref}>
					<div className='flex justify-between mt-3 mb-3'>
						<div className='flex items-center'>
							<img className='w-16 h-16 mr-4' src={selectedCollege?.logo} alt='College Logo' />
							<div>
								<h1 className='text-xl font-bold h-4'>{selectedCollege?.collegeShortName}</h1>
								<p className='text-md font-semibold h-1'>{selectedCollege?.accreditedGrade}</p>
								<p className='text-md font-semibold h-1'>{selectedCollege?.address}</p>
							</div>
						</div>

						<div className='float-right'>
							<h3 className='text-black'>Profac Monitor</h3>
							<h6>{search}</h6>
							<h6 className='text-black'>{(date.startDate || date.endDate) && `${date.startDate} to ${date.endDate}`}</h6>
						</div>
					</div>
					<Table width='100%' id='emp' innerWidth={['4%', '10%', '5%']}>
						<Thead>
							<Tr>
								<Th position={0}>SN</Th>
								<Th position={1}>Date</Th>
								{[1, 2, 3, 4, 5, 6].map((y) => (
									<Th position={2}>Period {y}</Th>
								))}
								<Th position={2}>Total</Th>
							</Tr>
						</Thead>
						<Tbody height='auto'>
							{data?.map((x: any, i: number) => (
								<Tr>
									<Td index={i} position={0}>
										{i + 1}
									</Td>
									<Td index={i} position={1}>
										{x?.date}
									</Td>
									{[1, 2, 3, 4, 5, 6, 7].map((y) => (
										<Td index={i} position={2}>
											{x?.periods?.filter((x: any) => x?.period === y)[0]?.className}
										</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
				</div>
			</Body>
		</Main>
	);
}

export default ProFacMonitor;
