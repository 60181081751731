import React, { useEffect, useContext, useState } from "react";
import classNames from "./studentTable.module.scss";
import { Table, Popconfirm } from "antd";
import Scrollbars from "react-custom-scrollbars";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { useQuery } from "react-query";
import { getPenddingAdmission } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import moment from "moment";
import { Checkmark } from "react-checkmark";
import StudentData from "../StudentData";
import ModalWrapper from "../../../../components/ModalWrapper";
import GiveWay from "../GiveWay";
import Verification from "../Verification";

import { Button } from "antd";
import CallToken from "../CallToken";
import { useDropDowns } from "../../../../queryHooks";




function EGrantz({ refreshStatus, onClose }) {
  const { collegeId, user } = useContext(FirebaseContext);

  let classId = ''
  let keyData = 'fees'

  const { data, refetch, status } = useQuery('getPenddingAdmissions', getPenddingAdmission(collegeId, classId, user.uid, keyData));
  const [state, setState] = useState({})


  let userList = data?.list || []

  userList = userList?.sort((a, b) => a?.admisionNo?.localeCompare(b?.admisionNo)) || []


  useEffect(() => {
    refetch && refetch();
  }, [refreshStatus, refetch]);






  const Timer = ({ start, stop, date, style }) => {
    const [startTime, setStartTime] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
      let interval = null;

      if (isRunning) {
        interval = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isRunning]);

    const handleStart = (date) => {
      setStartTime(new Date(date));
      setCurrentTime(new Date());
      setIsRunning(true);
    };

    const handleStop = () => {
      setIsRunning(false);
      setStartTime(null);
      setCurrentTime(null);
    };

    useEffect(() => {
      if (start) {
        handleStart(date);
      }
    }, [start, date]);

    useEffect(() => {
      if (stop) {
        handleStop();
      }
    }, [stop]);

    const getElapsedTime = () => {
      if (!startTime || !currentTime) {
        return '00:00:00';
      }

      const elapsedMilliseconds = currentTime - startTime;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      const hours = Math.floor(elapsedSeconds / 3600);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
      <div>
        <label style={{ ...style }} >{getElapsedTime()}</label>
      </div>
    );
  };
  const [timerStart, setTimerStart] = useState(false);

  const handleStartTimer = () => {
    setTimerStart(true);
  };

  const handleStopTimer = () => {
    setTimerStart(false);
  };

  const { data: dropDown } = useDropDowns('eGrantz');




  let tableColumns = [

    {
      title: "Time & Date",
      render: (_, item, i) => moment(new Date(item?.createdOn)).format('DD MMM | HH:MM'),
      key: "createdOn",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "number",

    },
    {
      title: "Course",
      dataIndex: "shortName",
      key: "shortName",

    },
    {
      title: "Admission No.",
      dataIndex: "admisionNo",
      key: "admisionNo",
      sorter: (a, b) =>
        a?.phoneNumber > b?.phoneNumber
          ? 1
          : b?.phoneNumber > a?.phoneNumber
            ? -1
            : 0,
    },
    {
      title: "Type of E Grantz",
      render: (_, itemData, i) => ((dropDown?.[collegeId]?.find(item => item?._id === itemData?.eGrantz?._id))?.type || '--'),
    },






    {
      title: "Action",
      render: (_, data) => (
        <button
          onClick={() => { setState({ ...state, viewMode: true, grand: true, studentId: data._id, }) }}
          style={{ cursor: "pointer", width: 220, height: 50, borderRadius: 20, padding: 10, textAlign: 'center', backgroundColor: 'green' }}
        >
          <div >
            <div style={{ alignContent: 'center', cursor: "pointer", fontSize: 20, color: 'white', fontWeight: 800 }}>Manage E Grantz</div>
          </div>
        </button >
      ),
    },


  ];

  return (

    <>
      <div className={classNames.container}>
        <div className={classNames.modal} >

          <div
            className={classNames.tableView}
            style={{ backgroundColor: "white" }}
          >
            <div className={classNames.tableHeader}>
              <h2>E Grantz Verification</h2>
            </div>

            {status === "loading" && <LoadingAnimation dark />}
            {status === "success" && (
              <Scrollbars className={classNames.tableWrap}>
                <div className={classNames.userTable}>
                  <Table
                    columns={tableColumns}
                    dataSource={userList && userList?.length > 0 ? userList : []}
                    pagination={false}
                  />
                </div>
              </Scrollbars>
            )}
          </div>
          {state.viewMode && (<ModalWrapper><StudentData uid={state?.studentId} isStudent={true} keyData={keyData} TimerFuncion={Timer} stateData={state} setStateData={setState} /></ModalWrapper>)}
          {state.verification && (<ModalWrapper><Verification uid={state?.studentId} isStudent={true} keyData={keyData} refectData={refetch} TimerFuncion={Timer} stateData={state} setStateData={setState} /></ModalWrapper>)}
        </div>
        <Button className={classNames.closeButton} onClick={onClose}>
          Close
        </Button>
      </div>

    </>
  );
}

export default EGrantz;
