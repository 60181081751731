import React, { useEffect, useState } from "react";
import classNames from "./detailView.module.scss";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import { useProfileData } from "../../../../queryHooks";
import CustomInput from "../../../../myComp/CustomInput";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";


function GiveWay({ uid, isStudent, stateData, TimerFuncion, setStateData }) {
  const {
    data: userDetail,
    status,
  } = useProfileData(uid, !isStudent);
  const [state, setState] = useState({})



  const Button = ({ color, text, onClick }) => {
    return (
      <button style={{ width: 180, margin: 20, borderColor: color, fontSize: 20, color: 'white', fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
        {text}
      </button>
    );
  };
  if (!uid) {
    return (
      <div className={classNames.detailView}>
        <div className={classNames.selectmessage}>Select A User First</div>
      </div>
    );
  }
  const handleCancel = () => {
    setStateData({ ...stateData, [userDetail._id + 'proceed']: false, giveWay: false, })

  };

  let memberData = userDetail?.pendingData

  const handleProceed = async () => {
    try {
      let data = {
        _id: userDetail?.uid,
        extendedTime: state?.extendedTime,
        extendedReason: state?.extendedReason
      }
      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/admissionProcess/setData", data);
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setStateData({
          ...stateData,
          giveWay: false,
        })
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };








  return (
    <div className={classNames.container}>
      <div className={classNames.modal} style={{ overflow: 'scroll', margin: 20 }}>
        <div className={classNames.detailView}>
          {status === "loading" && <LoadingAnimation dark />}
          {status === "success" && (
            <>
              <div style={{ overflow: 'scroll', margin: 20 }}>
                <div className={classNames.dataWraper} style={{ overflow: 'scroll', margin: 5, textAlign: 'center', width: '100%' }}>
                  <>
                    <img src={userDetail?.photoURL} style={{
                      border: '3px solid navy',
                      borderRadius: '10%',
                      width: '90%',
                      margin: 20
                    }} />
                    <div className={classNames.typoContainer}>
                      <h5 className={classNames.userName}>{memberData?.name}</h5>
                    </div>
                    < CustomInput
                      keyData={'extendedTime'}
                      titleData={'Time Extend'}
                      typeData={'datetime-local'}
                      stateData={state}
                      setStateData={setState}
                    />
                    < CustomInput
                      keyData={'extendedReason'}
                      titleData={'Reason For Extend'}
                      typeData={'text'}
                      stateData={state}
                      setStateData={setState}
                    />
                    <TimerFuncion style={{ fontSize: 80, fontFamily: 'bold', color: '#004518', alignSelf: 'center' }} start={true} date={stateData[userDetail._id + 'timeStamp']} />
                    <view style={{ flexDirection: 'row', display: 'flex', margin: 20 }}>
                      <Button color="#FF4136" text="Cancel" onClick={handleCancel} />
                      <Button color="#2ECC40" text="Proceed" onClick={handleProceed} />
                    </view>
                  </>
                </div>

              </div>

            </>

          )}
        </div>
      </div>
    </div>
  );
}

export default GiveWay;
