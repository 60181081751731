import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./detailView.module.scss";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import {
  getProfile,
  useDropDowns,
  useProfileData,
} from "../../../../queryHooks";
import CustomInput from "../../../../myComp/CustomInput";
import Scrollbars from "react-custom-scrollbars";
import CustomDropDown from "../../../../myComp/CustomDropDown";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { message } from "antd";
import { getAdmissionValidator, getAllMinor } from "../../ApiServices";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import ViewCertificates from "../ViewCertificates";
import ModalWrapper from "../../../../components/ModalWrapper";
import CustomDropDownKey from "../../../../myComp/CustomDropDownKey";
import ApplicationPrint from "../../../../CollegeApp/Form/ApplicationPrint";
import { getCollegeDetails } from "../../../../CollegeApp/Form/Register";
import { getCollegeDetailsee } from "../../../../CollegeApp/Admission/RemoveAction";
import { getQuotaTypes } from "../../../../CollegeApp/Form/Component";
import Input from "../../../../NecttosComp/Input/Input";
import { Body } from "../../../../NecttosComp/Layout/Layout";
import Button from "../../../../NecttosComp/Button/Button";
import AdmitCard from "../../../../CollegeApp/Form/AdmitCard";
import CoverPage from "../../../../CollegeApp/Form/CoverPage";
import MCAApplication from "../../../../CollegeApp/Form/MCAApplication";

function StudentData({
  uid,
  isStudent,
  stateData,
  checkAdmin,
  TimerFuncion,
  setStateData,
  keyData,
}) {
  const { collegeId, user } = useContext(FirebaseContext);
  const [finalList, setFinalList] = useState([]);
  const {
    data: userDetail,
    status,
    refetch,
  } = useProfileData(uid, !isStudent, keyData);
  const { data: collegeData } = useQuery(
    [`collegeData`, collegeId],
    getCollegeDetailsee
  );
  const { data: classes } = useQuery(
    [`collegeDataddd`, collegeId, "2024-2028"],
    getCollegeDetails
  );
  const { data: dasaa } = useQuery(
    [`profileDdddata`, collegeId, userDetail?._id],
    getProfile
  );

  console.log(dasaa);

  const { data: tableData = [] } = useQuery(
    "alltttminoe",
    getAllMinor(collegeId)
  );
  const componentRef = useRef();
  const componentRef2 = useRef();
  const componentRef3 = useRef();
  const dscbcDrop = tableData.filter((x) => x.type === "DSCBC");
  const mdcDrop = tableData.filter((x) => x.type === "MDC");
  const fetchData = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      const { data } = await instance.get(
        "/college/admissionProcess/getCoursePriority",
        {
          params: {
            userId: userDetail?._id,
            year: "2024-2028",
            collegeId,
          },
        }
      );
      setFinalList(data.finalList);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {
    if (userDetail && userDetail._id) {
      fetchData();
    }
  }, [userDetail?._id]);

  let memberData = userDetail;
  const [state, setState] = useState({});
  const { data: dropDown } = useDropDowns("eGrantz");
  useEffect(() => {
    let eGrantzId = "";
    let eGrantzIdval = "";
    let eGrantz = false;
    dropDown?.[collegeId]?.forEach((item) => {
      if (item?._id === userDetail?.eGrantz?._id) {
        eGrantzIdval = item?.type;
        eGrantzId = item?._id;
        eGrantz = true;
      }
    });

    userDetail?.collegeRoles?.forEach((element) => {
      if (element.type === "student") {
        setState({
          ...state,
          classId: element?.classId,
          index: userDetail?.index,
          admType: userDetail?.admType,
          emergency: userDetail?.emergency,
          capId: userDetail?.capId,
          indexOrg: userDetail?.indexOrg,
          reason: userDetail?.reason,
          remarks: userDetail?.remarks,
          eGrantz: userDetail?.eGrantz,
          eGrantzIdval,
          eGrantzId,
          eGrantz,
          ...userDetail?.certificates,
        });
      }
    });
  }, [userDetail, dropDown]);

  const { data } = useQuery(
    [
      "getAdmissionValidator",
      collegeId,
      state?.classId,
      userDetail?._id,
      state?.index,
      state?.admType,
    ],
    getAdmissionValidator(
      collegeId,
      state?.classId,
      userDetail?._id,
      state?.index,
      state?.admType
    )
  );

  // const Button = ({ color, text, disabled, isFull, onClick }) => {
  //   return (
  //     <button disabled={disabled} style={{ width: isFull === "true" ? "90%" : 180, margin: 20, borderColor: color, fontSize: 16, color: "white", cursor: "pointer", fontWeight: 600, height: 50, borderRadius: 20, backgroundColor: color }} onClick={onClick}>
  //       {text}
  //     </button>
  //   );
  // };

  if (!uid) {
    return (
      <div className={classNames.detailView}>
        <div className={classNames.selectmessage}>Select A User First</div>
      </div>
    );
  }
  const handleCancel = () => {
    setStateData({
      ...stateData,
      [userDetail._id + "proceed"]: false,
      viewMode: false,
    });
  };

  const handleView = () => {
    setState({ ...state, certificatesView: true });
  };

  const handleProceed = async () => {
    try {
      let data = {
        certificates: {
          sslc: state?.sslc,
          plusTwo: state?.plusTwo,
          degree: state?.degree,
          income: state?.income,
          admitCard: state?.admitCard,
          tc: state?.tc,
          cc: state?.cc,
          nccabc: state?.nccabc,
          zService: state?.zService,
          arts: state?.arts,
          tlm: state?.tlm,
          pwdLwdpTlm: state?.pwdLwdpTlm,
          community: state?.community,
          nativity: state?.nativity,
          jawan: state?.jawan,
          scout: state?.scout,
          mandatory: state?.mandatory,
          ews: state?.ews,
        },
        ...(state.eGrantz && {
          eGrantz: {
            isGrant: state?.eGrantz,
            _id: state?.eGrantzId,
          },
        }),
        reason: state.reason,
        collegeId,
        capId: state.capId,
        remarks: state.remarks,
        emergency: state.emergency,
        index: state.index,
        indexOrg: state.indexOrg,
        admType: state.admType,
        _id: userDetail._id,
      };
      const instance = await getAxiosTokenInstance();
      let resp;
      resp = await instance.post(
        "/college/admissionProcess/verificationDetails",
        data
      );
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        refetch();
        setState({
          ...state,
          index: userDetail?.index,
          admType: userDetail?.admType,
          ...userDetail?.certificates,
        });
        setStateData({
          ...stateData,
          [userDetail._id + "proceed"]: true,
          viewMode: false,
          ...(keyData !== "fees" && { verification: true }),
        });
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
    }
  };

  let forward = true;
  if (["department", "office", "nodal"].includes(keyData)) {
    forward =
      state.index &&
      state.indexOrg &&
      state.admType &&
      ([
        "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F",
        "COL-08B6B8276F75-46EE-A69C-77177AAA488B",
      ].includes(collegeId)
        ? true
        : state.capId);
  }

  return (
    <div className={classNames.container}>
      <div className={classNames.modal}>
        <Scrollbars>
          <div className={classNames.detailView}>
            {status === "loading" && <LoadingAnimation dark />}
            {status === "success" && (
              <>
                <div
                  style={{ flexDirection: "row", display: "flex", margin: 5 }}
                >
                  <div
                    className={classNames.dataWraper}
                    style={{ width: "54%" }}
                  >
                    <Body>
                      {dasaa?.dsc_a ===
                      "CLASS-195C8894984F-4A61-81D6-52D3093A13AF" ? (
                        <Button type="print" tableRef={componentRef.current}>
                          Print Application
                        </Button>
                      ) : (
                        <>
                          {" "}
                          <Button type="print" tableRef={componentRef.current}>
                            Print Application
                          </Button>
                          <Button type="print" tableRef={componentRef2.current}>
                            Print Affirmation
                          </Button>
                          <Button type="print" tableRef={componentRef3.current}>
                            Print Cover Page
                          </Button>
                        </>
                      )}{" "}
                      {dasaa?.dsc_a ===
                      "CLASS-195C8894984F-4A61-81D6-52D3093A13AF" ? (
                        <MCAApplication
                          collegeData={collegeData}
                          componentRef2={componentRef}
                          classes={classes}
                          type={
                            dasaa?.dsc_a ===
                            "CLASS-195C8894984F-4A61-81D6-52D3093A13AF"
                              ? "MCA"
                              : dasaa?.batchName === "2024-2026"
                              ? "PG"
                              : "UG"
                          }
                          values={dasaa}
                          getQuotaTypes={getQuotaTypes}
                        />
                      ) : (
                        <>
                          <AdmitCard
                            collegeData={collegeData}
                            componentRef2={componentRef2}
                            classes={classes}
                            type={
                              dasaa?.dsc_a ===
                              "CLASS-195C8894984F-4A61-81D6-52D3093A13AF"
                                ? "MCA"
                                : dasaa?.batchName === "2024-2026"
                                ? "PG"
                                : "UG"
                            }
                            values={dasaa}
                            getQuotaTypes={getQuotaTypes}
                          />
                          <CoverPage
                            collegeData={collegeData}
                            componentRef2={componentRef3}
                            classes={classes}
                            type={
                              dasaa?.dsc_a ===
                              "CLASS-195C8894984F-4A61-81D6-52D3093A13AF"
                                ? "MCA"
                                : dasaa?.batchName === "2024-2026"
                                ? "PG"
                                : "UG"
                            }
                            values={dasaa}
                            getQuotaTypes={getQuotaTypes}
                          />
                          <ApplicationPrint
                            collegeData={collegeData}
                            componentRef2={componentRef}
                            classes={classes}
                            type={
                              dasaa?.dsc_a ===
                              "CLASS-195C8894984F-4A61-81D6-52D3093A13AF"
                                ? "MCA"
                                : dasaa?.batchName === "2024-2026"
                                ? "PG"
                                : "UG"
                            }
                            values={dasaa}
                            getQuotaTypes={getQuotaTypes}
                          />
                        </>
                      )}
                    </Body>
                  </div>
                  <div
                    className={classNames.dataWraper}
                    style={{ margin: 5, width: "18%" }}
                  >
                    <>
                      <div
                        className={classNames.subTitle}
                        style={{ background: "navy", color: "white" }}
                      >
                        Tik Attached
                      </div>
                      {![
                        "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F",
                        "COL-08B6B8276F75-46EE-A69C-77177AAA488B",
                      ].includes(collegeId) && (
                        <>
                          <label
                            style={{
                              fontSize: 16,
                              fontFamily: "bold",
                              color: "#004518",
                              margin: 5,
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ height: 20, width: 20, marginRight: 10 }}
                              defaultChecked={state.admitCard}
                              onChange={() =>
                                setState({
                                  ...state,
                                  admitCard: !state.admitCard,
                                })
                              }
                            />{" "}
                            Admit Card
                          </label>
                          <label
                            style={{
                              fontSize: 16,
                              fontFamily: "bold",
                              color: "#004518",
                              margin: 5,
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ height: 20, width: 20, marginRight: 10 }}
                              defaultChecked={state.mandatory}
                              onChange={() =>
                                setState({
                                  ...state,
                                  mandatory: !state.mandatory,
                                })
                              }
                            />{" "}
                            Mandatory Fee Paid
                          </label>
                        </>
                      )}
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.sslc}
                          onChange={() =>
                            setState({ ...state, sslc: !state.sslc })
                          }
                        />{" "}
                        SSLC Certificate
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.plusTwo}
                          onChange={() =>
                            setState({ ...state, plusTwo: !state.plusTwo })
                          }
                        />{" "}
                        Plus Two Certificate
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.degree}
                          onChange={() =>
                            setState({ ...state, degree: !state.degree })
                          }
                        />{" "}
                        Degree Certificate
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.tc}
                          onChange={() => setState({ ...state, tc: !state.tc })}
                        />{" "}
                        TC
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.cc}
                          onChange={() => setState({ ...state, cc: !state.cc })}
                        />{" "}
                        CC
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.community}
                          onChange={() =>
                            setState({ ...state, community: !state.community })
                          }
                        />{" "}
                        Community
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.nativity}
                          onChange={() =>
                            setState({ ...state, nativity: !state.nativity })
                          }
                        />{" "}
                        Nativity
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.income}
                          onChange={() =>
                            setState({ ...state, income: !state.income })
                          }
                        />{" "}
                        Income/Non-Creamy Layer
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.ews}
                          onChange={() =>
                            setState({ ...state, ews: !state.ews })
                          }
                        />{" "}
                        EWS
                      </label>
                      {["COL-4E56593776BE-41C3-982A-0B6DC20E44A0"].includes(
                        collegeId
                      ) && (
                        <label
                          style={{
                            fontSize: 16,
                            fontFamily: "bold",
                            color: "#004518",
                            margin: 5,
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ height: 20, width: 20, marginRight: 10 }}
                            defaultChecked={state.tlm}
                            onChange={() =>
                              setState({ ...state, tlm: !state.tlm })
                            }
                          />{" "}
                          TLM
                        </label>
                      )}
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.pwdLwdpTlm}
                          onChange={() =>
                            setState({
                              ...state,
                              pwdLwdpTlm: !state.pwdLwdpTlm,
                            })
                          }
                        />{" "}
                        PwD,SP,SSQ,LWDP
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.arts}
                          onChange={() =>
                            setState({ ...state, arts: !state.arts })
                          }
                        />{" "}
                        Arts Festivel
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.zService}
                          onChange={() =>
                            setState({ ...state, zService: !state.zService })
                          }
                        />{" "}
                        EX Service
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.ncc}
                          onChange={() =>
                            setState({ ...state, ncc: !state.ncc })
                          }
                        />{" "}
                        NCC/NSS/SPC/RAJYA PURASKAR/ NAMMA MUDRA/Jawan/Scout
                      </label>
                      <label
                        style={{
                          fontSize: 16,
                          fontFamily: "bold",
                          color: "#004518",
                          margin: 5,
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ height: 20, width: 20, marginRight: 10 }}
                          defaultChecked={state.nccabc}
                          onChange={() =>
                            setState({ ...state, nccabc: !state.nccabc })
                          }
                        />{" "}
                        NCC A B C
                      </label>
                      <Button
                        type="save"
                        color="navy"
                        children="View Certificates"
                        onClick={handleView}
                      />
                      <div
                        className={classNames.group}
                        style={{ width: 250, height: 70 }}
                      >
                        <label style={{ fontSize: 20, color: "red" }}>
                          Remarks
                        </label>
                        <textarea
                          value={state?.remarks}
                          style={{
                            width: 250,
                            height: 70,
                            fontSize: 20,
                            color: "red",
                          }}
                          onChange={(e) =>
                            setState({ ...state, remarks: e.target.value })
                          }
                        />
                      </div>
                    </>
                  </div>
                  <div
                    className={classNames.dataWraper}
                    style={{ margin: 5, width: "28%" }}
                  >
                    <>
                      {keyData !== "fees" && (
                        <>
                          <div
                            className={classNames.subTitle}
                            style={{ background: "navy", color: "white" }}
                          >
                            Reservation and Index Validator
                          </div>
                          <view
                            style={{
                              flexDirection: "column",
                              display: "flex",
                              margin: 5,
                            }}
                          >
                            <view style={{ width: 300 }}>
                              <CustomDropDown
                                keyData={"admType"}
                                titleData={"Reservation Category"}
                                dropValues={data?.keys || []}
                                stateData={state}
                                setStateData={setState}
                              />
                            </view>
                            <view style={{ width: 300 }}>
                              <CustomInput
                                keyData={"index"}
                                titleData={"Intex Mark in Allotment"}
                                typeData={"number"}
                                fontSize={20}
                                stateData={state}
                                setStateData={setState}
                              />
                            </view>
                            <view style={{ width: 300 }}>
                              <CustomInput
                                keyData={"indexOrg"}
                                titleData={"Intex Mark Calculated"}
                                typeData={"number"}
                                fontSize={20}
                                color={"red"}
                                stateData={state}
                                setStateData={setState}
                              />
                            </view>

                            {![
                              "COL-D6DE25F23FF5-40A6-9E76-4A2AF63D1B5F",
                              "COL-08B6B8276F75-46EE-A69C-77177AAA488B",
                            ].includes(collegeId) && (
                              <view style={{ width: 300 }}>
                                <CustomInput
                                  keyData={"capId"}
                                  titleData={"CAP ID"}
                                  typeData={"text"}
                                  fontSize={20}
                                  stateData={state}
                                  setStateData={setState}
                                />
                              </view>
                            )}
                          </view>
                          <view
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              margin: 5,
                            }}
                          >
                            <div
                              className={classNames.subTitle}
                              style={{
                                width: 200,
                                borderRadius: 20,
                                height: 100,
                                margin: 12,
                                background:
                                  data?.isIndexAllow === "notPermitted"
                                    ? "red"
                                    : "green",
                                textAlign: "center",
                                color: "white",
                              }}
                            >
                              Open Last Index: {data?.loweIndexOpen}
                            </div>
                            <div
                              className={classNames.subTitle}
                              style={{
                                width: 200,
                                borderRadius: 20,
                                height: 100,
                                margin: 12,
                                background:
                                  data?.isReservationAllow === "notPermitted"
                                    ? "red"
                                    : "green",
                                textAlign: "center",
                                color: "white",
                              }}
                            >
                              In Take of {state.admType} Count by Last:{" "}
                              {data?.inTakeKey}/ {data?.limited}
                            </div>
                          </view>
                          {
                            <TimerFuncion
                              style={{
                                fontSize: 80,
                                fontFamily: "bold",
                                color: "#004518",
                                margin: 5,
                              }}
                              start={true}
                              date={stateData[userDetail._id + "timeStamp"]}
                            />
                          }
                          <label
                            style={{
                              fontSize: 40,
                              fontFamily: "bold",
                              color: "red",
                              margin: 20,
                              alignSelf: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ height: 30, width: 30, marginRight: 30 }}
                              defaultChecked={state.emergency}
                              onChange={() =>
                                setState({
                                  ...state,
                                  emergency: !state.emergency,
                                })
                              }
                            />
                            Emergency
                          </label>
                          {state.emergency && (
                            <view style={{ width: 300 }}>
                              <CustomDropDown
                                keyData={"reason"}
                                titleData={"Reason for Emergency"}
                                dropValues={[
                                  "Distance More Than 100km",
                                  "Physical Illness or Disability",
                                  "Hailing From Attappadi / Parambikkulam / Munnar",
                                ]}
                                stateData={state}
                                setStateData={setState}
                              />
                            </view>
                          )}
                        </>
                      )}
                      <div
                        className={classNames.subTitle}
                        style={{ background: "navy", color: "white" }}
                      >
                        Major: {data?.className}
                      </div>

                      {/* <Input fieldName="DSCBC" setState={setState} state={state} didntShowKey returnKey="dscbc" optionDisplay="subjectName" optionKey="_id" options={dscbcDrop} type="drop" width="100%" /> */}
                      {/* <view style={{ width: 300 }}>
                        <CustomDropDown keyData={"dscbc"} titleData={"DSCBC"} dropValues={data?.keys || []} stateData={state} setStateData={setState} />
                      </view>
                      <view style={{ width: 300 }}>
                        <CustomDropDown keyData={"mdc"} titleData={"MDC"} dropValues={mdcDrop || []} stateData={state} setStateData={setState} />
                      </view>
                      <view style={{ width: 300 }}>
                        <CustomDropDown keyData={"secondLanguage"} titleData={"AECOL"} dropValues={["Hindi", "Malayalam", "Syriac"]} stateData={state} setStateData={setState} />
                      </view> */}

                      {keyData === "office" && memberData?.admisionNo && (
                        <>
                          <div
                            className={classNames.subTitle}
                            style={{ background: "navy", color: "white" }}
                          >
                            ADMISSION NO: {memberData?.admisionNo}
                          </div>
                        </>
                      )}
                      {keyData === checkAdmin && (
                        <>
                          <div
                            className={classNames.subTitle}
                            style={{ background: "navy", color: "white" }}
                          >
                            ADMISSION NO:{" "}
                            {memberData.isTransfer
                              ? memberData?.admisionNo
                              : data?.falseNumber?.admisionNo}
                          </div>
                          <div
                            className={classNames.subTitle}
                            style={{ background: "navy", color: "white" }}
                          >
                            APPLICATION NO:{" "}
                            {memberData.isTransfer
                              ? memberData?.applicationNo
                              : data?.falseNumber?.applicationNo}
                          </div>
                        </>
                      )}
                      {stateData?.grand && (
                        <>
                          <label
                            style={{
                              fontSize: 40,
                              fontFamily: "bold",
                              color: "red",
                              margin: 20,
                              alignSelf: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              style={{ height: 30, width: 30, marginRight: 30 }}
                              defaultChecked={state.eGrantz}
                              onChange={() =>
                                setState({ ...state, eGrantz: !state.eGrantz })
                              }
                            />
                            Sanctioning E-Grantz
                          </label>
                          {state?.eGrantz && (
                            <view style={{ width: 550 }}>
                              <CustomDropDownKey
                                keyData={"eGrantzId"}
                                keyObject={"type"}
                                keyReturn={"_id"}
                                titleData={"Select Type of Grand"}
                                dropValues={dropDown[collegeId] || []}
                                stateData={state}
                                setStateData={setState}
                              />
                            </view>
                          )}
                        </>
                      )}
                      <view
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          margin: 5,
                        }}
                      >
                        <Button
                          type="close"
                          color="#FF4136"
                          children="Cancel"
                          onClick={handleCancel}
                        />
                        <Button
                          type="save"
                          disabled={forward ? false : true}
                          children="Proceed"
                          onClick={handleProceed}
                        />
                      </view>
                    </>
                  </div>
                </div>
              </>
            )}
          </div>
        </Scrollbars>
      </div>
      {state?.certificatesView && (
        <ModalWrapper>
          <ViewCertificates
            data={memberData?.certificates}
            stateData={state}
            priotiy={finalList}
            onClose={() => setState({ ...state, certificatesView: false })}
            setStateData={setState}
          />
        </ModalWrapper>
      )}
    </div>
  );
}

export default StudentData;
