import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from './exportModal.module.scss';
import { message, Select } from 'antd';
import { getAxiosTokenInstance } from '../../../../../utils/axiosInstance';
import { FirebaseContext } from '../../../../../context/FirebaseContext';
import Scrollbars from 'react-custom-scrollbars';
import CustomInput from '../../../../../myComp/CustomInput';
import moment from 'moment';
import CustomDropDown from '../../../../../myComp/CustomDropDown';
import { async } from '@firebase/util';
import { Body, Header, Main } from '../../../../../NecttosComp/Layout/Layout';
import Button from '../../../../../NecttosComp/Button/Button';
const { Option } = Select;

export const EditBillAmount = ({ item, itemData, onClose, setStateData, stateData, refetchDemo, title }) => {
	const [state, setState] = React.useState({
		...itemData,
		dueDate: moment(itemData.dueDate).format('YYYY-MM-DD'),
	});
	const { collegeId, collegeList } = useContext(FirebaseContext);
	const componentRef = useRef();
	const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
	const [titleAndType, setTitleAndType] = useState({ title: itemData?.title, type: itemData?.feeType });
	const [amount, setAmount] = useState(itemData?.amount);
	const [billNo, setBillNo] = useState(itemData?.billNo);
	const [paid, setPaid] = useState(itemData?.paid);
	const [balance, setBalance] = useState(0);
	const [dueDate, setDueDate] = useState(moment(itemData.date, 'DD-MM-YYYY').format('YYYY-MM-DD'));
	console.log('------>', { itemData });

	useEffect(() => {
		setBalance(parseInt(amount - paid));
	});

	const editBill = async () => {
		try {
			const instance = await getAxiosTokenInstance();
			let resp = await instance.post('/college/action/editBillNo', {
				collegeId,
				feeId: itemData._id,
				amount,
				billNo,
				userId: stateData._id,
				date: dueDate,
			});
			window.alert('Succesfully updated');
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Main title='Bill Editor' height='80vh' width='40vw'>
			<Header>
				<Button onClick={onClose} type='close'>
					Cancel
				</Button>
			</Header>
			<Body>
				<div className={classNames.modal}>
					<div className={classNames.listContainer2}>
						<div ref={componentRef} style={{ overflow: 'scroll' }}>
							<div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>
								<div className='flex w-full rounded-2xl'>
									<div className='flex flex-col items-center justify-center w-full'>
										<h5 className='text-5xl mt-0 mb-4'>{item?.billNo}</h5>

										<label className='font-semibold'>Amount:</label>
										<input type='text' className='input input-bordered w-4/5 mb-4' placeholder='Enter due amount' pattern='[0-9]*' value={amount} onChange={(e) => setAmount(e.target.value)} />

										<label className='font-semibold'>Date:</label>
										<input type='date' className='input input-bordered w-4/5 mb-4' value={dueDate} onChange={(e) => setDueDate(e.target.value)} />

										<div className='flex space-x-4'>
											<Button
												type='save'
												onClick={() => {
													editBill();
												}}>
												Update
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Body>
		</Main>
	);
};
