import React, { useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../../context/FirebaseContext";
import classNames from "./college.module.scss";
import './style.css'

const CollegeList = () => {
    const history = useHistory();
    const { collegeList, setCollegeId } = useContext(FirebaseContext);

    const onCollegeClick = (college) => () => {
        console.log(college)
        setCollegeId(college?.collegeId);
        history.push('/app');
    }

    return (
        <div className={classNames.rolePage}>
            <div className={classNames.role_containerBorder}>
                <div className={classNames.role_container}>
                    <Scrollbars style={{ width: '100%', height: '100%', }}>
                        <div className={classNames.card_container}>
                            {collegeList.map((college) => (
                                <button className={'roleCards2'} >


                                    <div
                                        onClick={onCollegeClick(college)}
                                        style={{ width: 100, height: 120 }}
                                        className={classNames.icon}> <img src={college.logo} className={classNames.iconSize} />
                                    </div>

                                    <h2>{college.collegeName}</h2>
                                </button>
                            ))}
                        </div>
                    </Scrollbars>
                </div>
            </div>
        </div >
    )
}

export default CollegeList;