import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { getInternalAssessment } from '../../pages/college/ApiServices';
import { Body, Main } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import { getCollegeDetailsee } from '../Admission/RemoveAction';
import Select, { components } from 'react-select';
import { getAxiosInstance } from '../../utils/axiosInstance';
import toast from 'react-hot-toast';

export const Releaving = ({ onClose }) => {
	const { collegeId, user } = useContext(FirebaseContext);
	const { data: collegeData } = useQuery([`collegddeData`, collegeId], getCollegeDetailsee);
	const { data: AssessmentData } = useQuery([`getInternalAssessment`, collegeId], getInternalAssessment({ collegeId }));
	const [faculties, setFaculties] = useState([]);
	const [selectedFaculty, setSelectedFaculty] = useState(null);
	const [releavingDate, setReleavingDate] = useState(null);
	const [note, setNote] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const ref = useRef(null);

	const handleReset = () => {
		setSelectedFaculty(null);
		setReleavingDate(new Date());
		setNote('');
	};

	const getFaculties = async () => {
		try {
			const instance = await getAxiosInstance();
			const url = `/college/action/getCoordinators?collegeId=${collegeId}`;
			const res = await instance.get(url);
			setFaculties(res?.data?.list);
		} catch (error) {
			console.error(error);
		}
	};
	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			const instance = await getAxiosInstance();
			const url = `/college/action/releaving`;
			const data = { collegeId, userId: selectedFaculty._id, updatedBy: user?.uid, releavingDate, note };
			const res = await instance.post(url, data);
			console.log(res);
			toast.success('submitted successfull');
			setIsSubmitting(false);
			handleReset();
		} catch (error) {
			console.error(error);
			toast.error('failed to submit');
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		getFaculties();
	}, []);

	const customOption = (props) => (
		<components.Option {...props}>
			<div className='flex items-center'>
				<img src={props?.data?.photoURL} alt={props?.data?.name} className='w-6 h-6 rounded-full mr-2' />
				<span>{props?.data?.name}</span>
			</div>
		</components.Option>
	);

	return (
		<Main title='Releaving' width='100vw' height='100vh'>
			<Body width='100%' height='90%'>
				<div className='w-full h-full'>
					<header className='flex justify-between'>
						<div></div>
						<div className='flex gap-2'>
							<Button type='close' onClick={onClose}>
								Close
							</Button>
						</div>
					</header>
					<section ref={ref} className='printable w-full h-auto flex justify-center '>
						<article ref={ref} className='w-full max-w-xl shadow-md p-6 rounded-lg space-y-6' style={{ backgroundColor: 'white' }}>
							<div>
								<label htmlFor='faculty' className='block text-sm font-medium text-gray-700'>
									Select Faculty
								</label>
								<Select id='faculty' name='faculty' value={selectedFaculty} options={faculties} onChange={setSelectedFaculty} getOptionLabel={(option) => option?.name} getOptionValue={(option) => option?._id} components={{ Option: customOption }} className='mt-1' />
							</div>

							<div>
								<label htmlFor='releaving-date' className='block text-sm font-medium text-gray-700'>
									Select Releaving Date
								</label>
								<input type='date' id='releaving-date' value={releavingDate} onChange={(e) => setReleavingDate(e.target.value)} className='mt-1 w-full h-10 px-3 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500' />
							</div>

							<div>
								<label htmlFor='note' className='block text-sm font-medium text-gray-700'>
									Note
								</label>
								<textarea id='note' value={note} onChange={(e) => setNote(e.target.value)} className='mt-1 w-full h-24 p-2 border rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500'></textarea>
							</div>

							<div className='flex justify-center'>
								<Button type='submit' onClick={handleSubmit} disabled={isSubmitting || !selectedFaculty || !releavingDate} width='200px'>
									{isSubmitting ? 'Submitting...' : 'Submit'}
								</Button>
							</div>
						</article>
					</section>
				</div>
			</Body>
		</Main>
	);
};
