import React, { useContext, useRef, useState } from "react";
import { Body, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";
import { useQuery } from "react-query";
import { useProfileData } from "../../queryHooks";

interface invoiceManagementProps {
  onClose: () => void;
}

const getFieldDropDowns = (data: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getNewAllUsers", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list[0].data;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

function AccountActiveFirebase({ onClose }: invoiceManagementProps) {
  const [state, setState] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isActivated, setIsActivated] = useState(false);

  const { collegeId } = useContext(FirebaseContext);
  const ref = useRef<any>(null);

  const { data: nameOfAuthorDrop = [] } = useQuery(
    ["dataDataData000", state?.userId],
    getFieldDropDowns({
      collegeId,
      collection: "users",
      primoryKey: "phoneNumber",
      secondaryKey: "name",
      tertiaryKey: "phoneNumber",
      search: state?.userId,
    })
  );

  const { data: userDetail } = useProfileData(state?.userId);

  const createAccount = async () => {
    setIsLoading(true);
    const instance = await getAxiosTokenInstance();
    try {
      await instance.post("/college/action/createAccount", {
        userId: state?.userId,
        collegeId,
      });
      setIsActivated(true);
    } catch (err) {
      console.error("Error during API call:", err);
      throw new Error("Failed to submit form:");
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsActivated(false);
    closeAnimation();
    setTimeout(onClose, 250);
  };

  return (
    <>
      <Main title="" width="60%" height="60%">
        <Header>
          <div className="flex">
            <Input width="500px" type="drop" options={nameOfAuthorDrop} typable fieldName="Search Student name or Phone Number" optionDisplay="name" optionKey="_id" returnKey="userId" didntShowKey state={state} setState={setState} />
          </div>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={() => {
              closeAnimation();
              setTimeout(onClose, 250);
            }}
          >
            Close
          </button>
        </Header>
        <Body>
          <div className="flex row items-center space-x-4">
            <img src={userDetail?.photoURL} alt="User" className={`w-40 h-40 rounded-full ${userDetail?.className}`} />
            <span className="font-xl text-gray-700">{userDetail?.name}</span>
            <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={createAccount} disabled={isLoading}>
              {isLoading ? "Activating..." : "Activate Account"}
            </button>
          </div>
        </Body>
      </Main>

      {isActivated && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-2xl mb-4">Account Activated!</h2>
            <p className="mb-4">The account has been successfully activated.</p>
            <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default AccountActiveFirebase;
