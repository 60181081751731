import React, { useContext, useRef, useState } from "react";
import classNames from "./exportModal.module.scss";
import { Button, Select } from "antd";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import { getAllPeriodicals } from "../../ApiServices";
import LoadingAnimation from "../../../../components/LoadingAnimation";
import Scrollbars from "react-custom-scrollbars";
import ReactToPrint from "react-to-print";
import SearchableImput from "../AddBook/DropDownComponent";
import CustomInput from "../../../../myComp/CustomInput";
import moment from "moment";
import { Formik } from "formik";
import CustomDropDown from "../../../../myComp/CustomDropDown";



export const Periodicals = ({ onClose }) => {

  const { collegeId, collegeList } = useContext(FirebaseContext);
  const componentRef = useRef();
  const selectedCollege = collegeList?.find((x) => x.collegeId === collegeId);
  const { data = [], refetch } = useQuery('getAllPeriodicals', getAllPeriodicals(collegeId));
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({});

  const managePeriodicals = async () => {
    try {
      setIsLoading(true);

      const instance = await getAxiosTokenInstance();
      let resp
      resp = await instance.post("/college/action/postPeriodicals", {
        collegeId,
        bookDetails: state.bookDetails,
        vendorMobile: state.vendorMobile,
        vendor: state.vendor,
        arcgives: state.arcgives,
        bookType: state.bookType,
        language: state.language,
        subject: state.subject,
        postalAddress: state.postalAddress,
        ddAddress: state.ddAddress,
        issnNumber: state.issnNumber,
        nameOfBook: state.nameOfBook,
        piriodicity: state.piriodicity,
        price: state.price,
        _id: state?._id
      });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      } else {
        setState({
          ...state,

          bookDetails: '',
          vendorMobile: '',
          vendor: '',
          bookType: '',
          arcgives: '',
          language: '',
          subject: '',
          postalAddress: '',
          ddAddress: '',
          issnNumber: '',
          nameOfBook: '',
          piriodicity: '',
          price: '',


          _id: '',
        })
        refetch()
      }
    } catch (error) {
      message.error(
        error?.response?.data?.message || error?.message || "API Error"
      );
    } finally {
      setIsLoading(false);
    }
  };



  const { Option } = Select;







  return (
    <div className={classNames.container}>

      <div className={classNames.modal} >

        <div className={classNames.listContainer}>

          {isLoading ? <LoadingAnimation dark /> :
            <>
              <div className={classNames.list}>
                {
                  state.alert ?
                    <label className={classNames.heading}>{state.alert}</label> :
                    <label className={classNames.heading}>Add New</label>
                }
                <CustomDropDown
                  keyData={'bookType'}
                  titleData={'Type of Periodicals'}
                  dropValues={["Journals", "Magazines", "Dailies"]}
                  stateData={state}
                  setStateData={setState}
                />
                <CustomDropDown
                  keyData={'piriodicity'}
                  titleData={'Periodicity'}
                  dropValues={["Daily", "Weekly", "Fortnightly", "Semimonthly", "Monthly", "Semi-quarterly", "Bimonthly", "Quarterly", "Triennially", "Biennially", "Annually"]}
                  stateData={state}
                  setStateData={setState}
                />
                < CustomInput
                  keyData={'nameOfBook'}
                  titleData={`${state.bookType || ' '} Name`}
                  typeData={'text'}
                  stateData={state}
                  setStateData={setState}
                />
                {state.bookType !== 'Dailies' && <>
                  < CustomInput
                    keyData={'issnNumber'}
                    titleData={'ISSN No'}
                    typeData={'text'}
                    stateData={state}
                    setStateData={setState}
                  />
                  < CustomInput
                    keyData={'ddAddress'}
                    titleData={'DD Address'}
                    typeData={'text'}
                    stateData={state}
                    setStateData={setState}
                  />
                  < CustomInput
                    keyData={'postalAddress'}
                    titleData={'Postal Address'}
                    typeData={'text'}
                    stateData={state}
                    setStateData={setState}
                  />
                </>
                }

                {state.bookType === 'Journals' && < SearchableImput
                  keyData={'subject'}
                  titleData={'Subject'}
                  collection={'ddcSubjectsLibrary'}
                  stateData={state}
                  setStateData={setState}
                />}

                {state.bookType === 'Magazines' && < SearchableImput
                  keyData={'language'}
                  titleData={'Language'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />}

                {state.bookType !== 'Dailies' &&
                  <>
                    < CustomInput
                      keyData={'arcgives'}
                      titleData={'Archives'}
                      typeData={'text'}
                      stateData={state}
                      setStateData={setState}
                    />
                  </>
                }

                < SearchableImput
                  keyData={'vendor'}
                  titleData={'Vendor Name & Address'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />
                < CustomInput 
                  keyData={'price'}
                  titleData={'Price'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />
                < CustomInput
                  keyData={'vendorMobile'}
                  titleData={'Vendor Mobile'}
                  typeData={'number'}
                  stateData={state}
                  setStateData={setState}
                />
                < SearchableImput
                  keyData={'bookDetails'}
                  titleData={'Other Details if any'}
                  collection={'books'}
                  stateData={state}
                  setStateData={setState}
                />




                <div style={{ height: '50px', width: '74%', alignSelf: 'center' }}  >
                  <Button className={classNames.cancelBotton} onClick={() => {
                    setState({
                      ...state,
                      bookDetails: '',
                      vendorMobile: '',
                      vendor: '',
                      arcgives: '',
                      language: '',
                      subject: '',
                      postalAddress: '',
                      bookType: '',
                      ddAddress: '',
                      issnNumber: '',
                      nameOfBook: '',
                      piriodicity: '',
                      price: '',
                      _id: '',

                    })
                  }}>  Cancel </Button>
                  <Button className={classNames.submitBotton} onClick={() => { managePeriodicals() }}>
                    Submit
                  </Button>
                </div>
              </div>

            </>
          }
        </div>
        <div className={classNames.listContainer2}>
          <Scrollbars style={{ height: '100%' }} >
            <div ref={componentRef} className="printable">
              <img style={{ position: 'absolute', width: '80%', marginTop: 350, marginLeft: 100, marginRight: 200, opacity: .12, zIndex: -1 }} src={selectedCollege?.logo} />
              <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderRadius: '25px', padding: '1%' }}>

                <h5 style={{ margin: 0, fontSize: '30px', width: '100%', alignSelf: 'center' }}>Periodicals Management</h5>
                <div style={{ width: '100%', display: 'flex', paddingRight: 12, flexDirection: 'row', height: 30, margin: 0, backgroundColor: 'black', alignItems: 'center', alignSelf: 'center', marginBottom: 12 }}>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'center', fontSize: 12, width: '5%', }}>Sn</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '40%' }}>Title</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Periodicals</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Price</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Vendor</h5>
                  <h5 style={{ marginTop: 6, color: 'white', textAlign: 'left', fontSize: 12, width: '20%' }}>V Mobile</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Type</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Subject</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Language</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Archives</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Postal Address</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>DD Address</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>ISSN Number</h5>
                  <h5 style={{ marginTop: 6, color: 'white', alignSelf: 'left', fontSize: 12, width: '20%' }}>Other Details</h5>

                </div>
                {data?.map((item, i) => (
                  <>
                    <div style={{ width: '100%', paddingRight: 12, display: 'flex', minHeight: '50px', flexDirection: 'row', alignItems: 'center', alignSelf: 'center' }}>

                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'center', fontSize: 12, width: '5%' }}>{i + 1}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '40%' }}>{item.nameOfBook}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.piriodicity}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.price}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.vendor}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.vendorMobile}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.bookType}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.subject}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.language}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.arcgives}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.postalAddress}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.ddAddress}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.issnNumber}</h5>
                      <div style={{ height: '100%', display: 'flex', margin: '5px', width: '1px', backgroundColor: 'black' }}></div>
                      <h5 style={{ marginRight: 4, color: 'black', textAlign: 'left', fontSize: 12, width: '20%' }}>{item.bookDetails}</h5>

                      <Button className={classNames.submitBotton} onClick={() => {
                        setState({
                          ...state,
                          _id: item?._id || '',
                          bookDetails: item.bookDetails,
                          vendorMobile: item.vendorMobile,
                          vendor: item.vendor,
                          arcgives: item.arcgives,
                          bookType: item.bookType,
                          language: item.language,
                          subject: item.subject,
                          postalAddress: item.postalAddress,
                          ddAddress: item.ddAddress,
                          issnNumber: item.issnNumber,
                          nameOfBook: item.nameOfBook,
                          piriodicity: item.piriodicity,
                          price: item.price

                        })
                      }}>
                        Edit
                      </Button>
                    </div>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      height: 1,
                      backgroundColor: 'black',
                    }}></div>
                  </>
                ))}
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>

      <Button className={classNames.reloadButton} onClick={() => { refetch() }}>
        Reload
      </Button>

      <ReactToPrint
        trigger={() => <button className={classNames.printBotton}>Print</button>}
        content={() => componentRef.current}
      />

      {!isLoading && <Button className={classNames.closeButton} onClick={onClose}>
        Close
      </Button>}

    </div>
  );
};
