import React, { useContext, useEffect, useRef, useState } from "react";
import { FirebaseContext } from "../../context/FirebaseContext";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { Body, Header, Main } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from "../../NecttosComp/Table/Table";
import { useQuery } from "react-query";
import Input from "../../NecttosComp/Input/Input";
import { getAutoIndexApplications, getNewClasses } from "../../pages/college/ApiServices";
import ApplicationView from "./ApplicationView";
import { useProfileData } from "../../queryHooks";
import moment from "moment";
import LoadingAnimation from "../../components/LoadingAnimation";
import RemoveAction from "./RemoveAction";

function RankListAuto({ onClose }: { onClose: () => void }) {
  const { collegeId, user } = useContext(FirebaseContext);
  const [showModal, setShowModal] = useState(false);
  const [addNew, setAddNew] = useState<any>({});
  const [selectedClass, setSelectClass] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allowed, setAllow] = useState(false);
  const [unfinalysed, setUnFinalysed] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const confirmAction = async () => {
    setLoading(true);
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post("/college/admissionProcess/unfinalizeBulk", {
        collegeId,
        managedBy: user.uid,
        boolType: unfinalysed,
      });
      if (resp?.data?.statusCode === 200) {
        setLoading(false);
        refetch();
        setShowModal(false);
      } else {
        setLoading(false);
        refetch();
        setShowModal(false);
      }
    } catch (error) {
      setLoading(false);
      refetch();
      setShowModal(false);
    }
  };

  const ref = useRef<any>(null);
  const { data: userDetail } = useProfileData(user.uid);

  useEffect(() => {
    if (userDetail && userDetail?.collegeRoles) {
      const roleTypes = userDetail?.collegeRoles?.filter((role: any) => role.collegeId === collegeId)?.map((role: any) => role.type);
      if (roleTypes.includes("nodal")) setAllow(true);
      console.log(roleTypes);
    }
  }, [userDetail?.collegeRoles, collegeId]);

  const { data = {}, refetch } = useQuery(["getAllEndddquiry", selectedClass, searchTerm, unfinalysed], getAutoIndexApplications(collegeId, selectedClass, searchTerm, unfinalysed));
  const tableData = data?.list;
  const { data: classes } = useQuery("getNewClasses", getNewClasses(collegeId, user?.uid));
  const [filterdata, setFilter] = useState<any>([]);
  const [filterKey, setFilterKey] = useState("ALL");
  useEffect(() => {
    if (!filterKey || !tableData) return;

    switch (filterKey) {
      case "UG":
        setFilter(tableData.filter((data: any) => ["2024-2028", "2024-2027", "2024-2029"].includes(data.batchName)));
        break;
      case "PG":
        setFilter(tableData.filter((data: any) => ["2024-2026"].includes(data.batchName)));
        break;
      case "ALL":
        setFilter([...tableData]);
        break;
      default:
        break;
    }
  }, [filterKey, tableData]);
  const { tableItems, requestSort, getIcon } = useSortableData(filterdata);

  const unfinalyse = async (id: any) => {
    const instance = await getAxiosTokenInstance();
    try {
      const resp = await instance.post("/college/admissionProcess/unfinalyse", {
        _id: id,
        collegeId,
        managedBy: user?.uid,
        boolType: false,
      });
      if (resp?.data?.statusCode === 200) {
        refetch();
      } else {
      }
    } catch (error) {}
  };

  return (
    <Main title="Completed Applications" height="100%" width="100%">
      <Header>
        <div className="flex">
          <Input fieldName="Filter by Class" setState={setSelectClass} state={selectedClass} didntShowKey optionDisplay="className" optionKey="_id" options={classes?.list} type="drop" width="15vw" />
          <Input fieldName="Search" state={searchTerm} setState={setSearchTerm} type="text" width="15vw" />
          <view className="h-[50px]  rounded-md p-3 m-2 px-2 bg-white">
            <h5>Total: {tableItems?.length}</h5>
          </view>
        </div>
        <div className="flex">
          <Button
            type={!unfinalysed ? "close" : "save"}
            onClick={() => {
              setUnFinalysed(!unfinalysed);
            }}
          >
            {!unfinalysed ? "Show Unfinalized Apps" : "Show Finalized Apps"}
          </Button>
          <Button
            type="save"
            onClick={() => {
              setFilterKey("ALL");
              setSelectClass("");
            }}
          >
            Show All
          </Button>
          <Button
            type="fetch"
            onClick={() => {
              setFilterKey("UG");
              setSelectClass("");
            }}
          >
            Show UG
          </Button>
          <Button
            type="doc"
            onClick={() => {
              setFilterKey("PG");
              setSelectClass("");
            }}
          >
            Show PG
          </Button>
          <Button type="print" tableRef={ref.current}>
            Print
          </Button>
          <Button type="excel" tableRef={ref.current}>
            Excel
          </Button>
          <Button type="close" onClick={onClose}>
            Close
          </Button>
        </div>
      </Header>
      <Thead>
        <Tr>
          <Th position={0}>A.No.</Th>
          <Th position={2}>Date</Th>
          <Th position={1} onClick={() => requestSort("name")} icon={getIcon("name")}>
            Name
          </Th>
          <Th position={2}>View</Th>
          <Th position={2}>Control</Th>
          {allowed && (
            <Th position={2}>
              <div className="flex h-[40px] w-[100px]">
                <Button type="save" onClick={handleOpenModal}>
                  Un F All
                </Button>
              </div>
            </Th>
          )}
          {selectedClass ? (
            <Th position={0} onClick={() => requestSort("priorityOrder")} icon={getIcon("priorityOrder")}>
              Priority
            </Th>
          ) : (
            <Th position={0} item={"SN"}>
              SN
            </Th>
          )}

          <Th position={2}>Whatsap</Th>
          <Th position={2}>Guardian</Th>
          <Th position={2}>Registered</Th>

          <Th position={3} onClick={() => requestSort("managementQuata")} icon={getIcon("managementQuata")}>
            P1
          </Th>
          <Th position={3} onClick={() => requestSort("meritQuata")} icon={getIcon("meritQuata")}>
            P2
          </Th>
          <Th position={3} onClick={() => requestSort("communityQuata")} icon={getIcon("communityQuata")}>
            P3
          </Th>
          <Th position={3} onClick={() => requestSort("reservedQuata")} icon={getIcon("reservedQuata")}>
            P4
          </Th>
          <Th position={3} onClick={() => requestSort("sportsQuata")} icon={getIcon("sportsQuata")}>
            P5
          </Th>
          <Th position={3} onClick={() => requestSort("culturalQuata")} icon={getIcon("culturalQuata")}>
            P6
          </Th>
          <Th position={3} onClick={() => requestSort("ewsQuata")} icon={getIcon("ewsQuata")}>
            P7
          </Th>
          <Th position={3} onClick={() => requestSort("pwdQuata")} icon={getIcon("pwdQuata")}>
            P8
          </Th>
          <Th position={3} onClick={() => requestSort("lakshadweepQuata")} icon={getIcon("lakshadweepQuata")}>
            P9
          </Th>
          <Th position={3} onClick={() => requestSort("lakshadweepQuata")} icon={getIcon("lakshadweepQuata")}>
            P10
          </Th>
        </Tr>
      </Thead>
      <Body>
        <div ref={ref} className="overflow-auto">
          <Table width="100%" innerWidth={["3%", "5%", "6%", "5%"]}>
            <Tbody height="auto">
              {tableItems?.map((x: any, i: number) => (
                <Tr>
                  <Td index={i} position={0}>
                    {x?.applicationNo}
                  </Td>
                  <Td index={i} position={2}>
                    {moment(x?.createdOn).format("DD-MM, HH:MM")}
                  </Td>
                  <Td index={i} position={1}>
                    {x?.name}
                  </Td>
                  <Td index={i} position={2}>
                    <Button
                      type="doc"
                      onClick={() => {
                        setAddNew({ ...addNew, viewMode: true, _id: x._id, tyeUG: x.batchName === "2024-2026" ? "PG" : "UG" });
                      }}
                    >
                      ViewApp
                    </Button>
                  </Td>
                  <Td index={i} position={2}>
                    <Button
                      type="close"
                      onClick={() => {
                        setAddNew({ ...addNew, remove: true, _id: x._id, classId: x.dsc_a, tyeUG: x.batchName === "2024-2026" ? "PG" : "UG" });
                      }}
                    >
                      Controll
                    </Button>
                  </Td>

                  {allowed && (
                    <Td index={i} position={2}>
                      <Button
                        type="close"
                        onClick={() => {
                          unfinalyse(x?._id);
                        }}
                      >
                        Unfinalise
                      </Button>
                    </Td>
                  )}

                  <Td index={i} position={0}>
                    {selectedClass ? x?.priorityOrder : i + 1}
                  </Td>
                  <Td index={i} position={2}>
                    {x?.whatsappNumber}
                  </Td>
                  <Td index={i} position={2}>
                    {x?.guardianMobile}
                  </Td>
                  <Td index={i} position={2}>
                    {x?.phoneNumber}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer1}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer2}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer3}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer4}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer5}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer6}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer7}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer8}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer9}
                  </Td>
                  <Td index={i} textAlign="center" position={3}>
                    {x?.prefer10}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        {addNew.viewMode && <ApplicationView uid={addNew?._id} viewMode={true} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, viewMode: false })} />}
        {addNew?.remove && <RemoveAction userId={addNew?._id} completedApplication={true} classId={addNew?.classId} indexMark={addNew?.indexMark} quota={addNew.quota} params={{ type: addNew?.tyeUG }} onClose={() => setAddNew({ ...addNew, remove: false })} />}

        {showModal && (
          <Main height="30%" width="30%" title="Warning!">
            <h4 className="text-lg font-bold mb-4">Confirm Action</h4>
            <p>You are about to open {tableData?.length} applications for direct student processing. Are you sure you want to proceed?</p>

            {loading ? (
              <LoadingAnimation customHeight={200} customWidth={200} />
            ) : (
              <div className="mt-4 flex justify-between space-x-4">
                <Button type="save" onClick={confirmAction}>
                  Proceed
                </Button>
                <Button type="close" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </div>
            )}
          </Main>
        )}
      </Body>
    </Main>
  );
}

export default RankListAuto;
