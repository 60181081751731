import React, { useContext, useState } from 'react'
import { Table } from "antd";
import classNames from "./feesTable.module.scss";
import Scrollbars from "react-custom-scrollbars";
import { useQuery } from 'react-query';
import { getFeeDueList } from '../../feesManagementServices';
import { FirebaseContext } from '../../../../context/FirebaseContext';
import LoadingAnimation from '../../../../components/LoadingAnimation';


export default function FeesTable({ search, handleCollect }) {

    const { collegeId } = useContext(FirebaseContext);

    const [, setCount] = useState(16);
    const [page, setPage] = useState(1);

    const limit = 10;
    const skip = (page - 1) * limit;
    const noNeedTotals = true;
    const { data, status, error, isFetching, refetch } = useQuery(['feeDueList', page, search], getFeeDueList(collegeId, skip, limit, search, noNeedTotals), { keepPreviousData: true })

    let newdata = data?.data
    let hasNext = data?.hasNext

    const dataSource = newdata?.map((user, index) => {
        const tutionFee = user?.groups?.find(x => x.feeType === 'TuitionFee');
        const examFee = user?.groups?.find(x => x.feeType === 'ExamFee');
        const otherFee = user?.groups?.find(x => x.feeType === 'Others');
        return {
            key: index,
            name: user?.userName,
            admnNo: user?.admnNo,
            class: user?.className,
            tutionFee: `${tutionFee?.totalCurrentDue || (tutionFee?.totalAdvance ? tutionFee?.totalAdvance + ' (Adv)' : tutionFee?.totalAdvance) || '0/-'}`,
            otherFees: `${otherFee?.totalCurrentDue || (otherFee?.totalAdvance ? otherFee?.totalAdvance + ' (Adv)' : otherFee?.totalAdvance) || '0/-'}`,
            examFees: `${examFee?.totalCurrentDue || (examFee?.totalAdvance ? examFee?.totalAdvance + ' (Adv)' : examFee?.totalAdvance) || '0/-'}`,
            photo: user?.photoURL
        }
    }) || []



    const columns = [
        {
            title: 'Photo',
            dataIndex: 'Photo',
            render: (_, data, i) => <div className={classNames.avatar}>
                <img src={data?.photo || 'https://cdn1.vectorstock.com/i/1000x1000/51/05/male-profile-avatar-with-brown-hair-vector-12055105.jpg'} className={classNames.dp} />
            </div>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            dataIndex: '',
            render: (_, __, i) => <button className={classNames.printButton} onClick={() => handleCollect(newdata[i])}>Go</button>

        },
        {
            title: 'Admn No',
            dataIndex: 'admnNo',
            key: 'admnNo',
        },
        {
            title: 'Class',
            dataIndex: 'class',
            key: 'class',
        },
        {
            title: 'Tution Fees',
            dataIndex: 'tutionFee',
            key: 'tutionFee',
        },
        {
            title: 'Exam Fees',
            dataIndex: 'examFees',
            key: 'examFees',
        },
        {
            title: 'Other Fees',
            dataIndex: 'otherFees',
            key: 'otherFees',
        }

    ];

    const onLoadPrev = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    const onLoadNext = () => {
        setPage(page + 1)
    }

    return (
        <>
            {status === 'loading' ? <LoadingAnimation dark /> :
                <div className={classNames.tableWrap}>
                    <Scrollbars
                        onScrollFrame={(data) => {
                            setCount((count) => count + 10);
                        }}>
                        <table className={classNames.userTable}>
                            <Table dataSource={dataSource} pagination={false} columns={columns} bordered />
                        </table>
                    </Scrollbars >
                    <div style={{ flex: 1, flexDirection: 'row' }}>
                        <button style={{ margin: '20px' }} onClick={onLoadPrev}>Load Prev</button>
                        <label style={{ backgroundColor: 'white', paddingLeft: '20px', paddingRight: '20px' }}>{page}</label>
                        <button style={{ margin: '20px' }} onClick={onLoadNext} disabled={hasNext === false}>Load Next</button>
                    </div>
                </div>
            }
        </>
    )
}
