import React, { useEffect, useState } from 'react'
import { Body, Header, Main } from '../../../../NecttosComp/Layout/Layout'
import Button from '../../../../NecttosComp/Button/Button'
import { Table, Tbody, Td, Tfoot, TfootTd, TfootTr, Th, Thead, Tr } from '../../../../NecttosComp/Table/Table'
import { getAxiosTokenInstance } from '../../../../utils/axiosInstance'

function Saction({userDatas,onClose,collegeId}:{userDatas:any[],onClose:()=>void,collegeId:string}) {
    const [data,setData] = useState(userDatas)
    const [totalAmountSactioned,setTotalAmountSactioned] = useState(0)

    const handleSave = async () => {
        const instance = await getAxiosTokenInstance();
        instance
          .patch("/college/admissionProcess/update-eGrand-status", { data, typeSelected: "sanctioned", collegeId })
          .then(() => onClose())
          .catch((err) => console.log(err));
      };

    useEffect(()=>{
        let total = 0
        data?.forEach((x:any)=>{
            total+=x?.eGrantz?.sactionedAmount
        })
        setTotalAmountSactioned(total||0)
    },[data])
  return (
    <Main height='80vh' width='80vw' title='Saction'>
        <Header>
    <Button type='close' onClick={onClose}>Close</Button>
        </Header>
        <Body>
            <Table width='100%' innerWidth={["10%","25%","25%","25%","15%"]}>
                <Thead>
                    <Tr>
                        <Th position={0}>Sl.No.</Th>
                        <Th position={1}>Name</Th>
                        <Th position={2}>Adm.No.</Th>
                        <Th position={3}>Saction Amount</Th>
                        <Th position={4}>Reject</Th>
                    </Tr>
                </Thead>
                <Tbody height='60%'>
                    {data?.map((x:any,i:number)=>(
                        <Tr>
                            <Td position={0} index={i}>{i+1}</Td>
                            <Td position={1} index={i}>{x?.name}</Td>
                            <Td position={2} index={i}>{x?.admisionNo}</Td>
                            <Td position={3} index={i}><input type="number" className='rounded border'  placeholder='Enter the Amount' value={x?.eGrantz?.sactionedAmount} onChange={(e)=>{
                                const temp = [...data]
                                temp[i].eGrantz.sactionedAmount = parseInt(e.target.value)
                                setData([...temp])
                            }} /></Td>
                            <Td position={4} index={i}><input type="checkbox"  onClick={(e)=>{
                                const temp = [...data]
                                temp[i].eGrantz.rejection = e?.currentTarget?.checked
                                setData([...temp])
                            }}/></Td>
                        </Tr>
                    ))}
                </Tbody>
                <Tfoot>
                    <TfootTr>
                        <TfootTd position={0}>Total Amount sactioned</TfootTd>
                        <TfootTd merge={2}>{totalAmountSactioned}</TfootTd>
                    </TfootTr>
                </Tfoot>
            </Table>
            <div className='float-right'>
            <Button type='save' onClick={handleSave}>Done</Button>
            </div>
        </Body>
    </Main>
  )
}

export default Saction