import React, { useEffect, useContext } from "react";
import classNames from "./studentTable.module.scss";
import { Table, Popconfirm } from "antd";
import Scrollbars from "react-custom-scrollbars";
import { useAllPendingStudents } from "../../queryHooks";
import LoadingAnimation from "../LoadingAnimation";
import message from "antd/lib/message";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { FirebaseContext } from "../../context/FirebaseContext";

function AddStudentTable({ refreshStatus, setSelectedUser, selectUser, toggleRefresh }) {
  const { data: userList, refetch, status } = useAllPendingStudents();
  const { collegeId } = useContext(FirebaseContext);
  // const { values, setFieldValue } = useFormikContext();

  console.log({ userList });

  useEffect(() => {
    refetch && refetch();
  }, [refreshStatus, refetch]);

  const removeUser = async (data, collegeId) => {
    try {
      const instance = await getAxiosTokenInstance();
      let resp = await instance.put("/college/users/deleteUser", { collegeId, id: data.id });
      if (resp.data.statusCode !== 200) {
        throw new Error(resp.data.message || "API Error");
      }
      message.success(data?.message || "User Removed");
      toggleRefresh();
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message || "API Error");
    }
  };

  let tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0),
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "number",
      sorter: (a, b) => (a?.phoneNumber > b?.phoneNumber ? 1 : b?.phoneNumber > a?.phoneNumber ? -1 : 0),
    },
    {
      title: "Time & Date",
      dataIndex: "createdOn",
      key: "createdOn",

      sorter: (a, b) => a.createdOn - b.createdOn,
    },
    {
      title: "Application",
      dataIndex: "applicationStatus",
      render: (_, data, i) => <div className={`${classNames.status_tag} ${data.applicationStatus === "Not Filled" ? classNames.notFilled_status : data.applicationStatus === "Filled" ? classNames.active_status : data.applicationStatus === "Filling" ? classNames.filling : data.applicationStatus === "Pending" ? classNames.pending_status : classNames.delete_status}`}>{data.applicationStatus || "-"}</div>,
    },
    {
      title: "Admission",
      dataIndex: "admissionStatus",
      render: (_, data, i) => <div className={`${classNames.status_tag} ${data.admissionStatus === "Active" ? classNames.active_status : data.admissionStatus === "Suspended" ? classNames.suspend_status : data.admissionStatus === "Pending" ? classNames.pending_status : classNames.delete_status}`}>{data.admissionStatus || "-"}</div>,
    },
    {
      title: "Edit",
      render: (_, data) => (
        <div style={{ cursor: "pointer" }} onClick={() => selectUser(data)}>
          Edit
        </div>
      ),
    },
    {
      title: "Delete",
      render: (_, data) => (
        <Popconfirm placement="topRight" title="Are you sure you want to delete this student" okText="Yes" cancelText="No" onConfirm={() => removeUser(data, collegeId)}>
          <span style={{ cursor: "pointer" }}>Delete</span>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <div className={classNames.tableView} style={{ backgroundColor: "white" }}>
      

        {status === "loading" && <LoadingAnimation dark />}
        {status === "success" && (
          <div className={classNames.tableWrap}>
            <div className={classNames.userTable}>
              <Table
                columns={tableColumns}
                dataSource={userList && userList?.length > 0 ? userList : []}
                pagination={false}
                // bordered
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AddStudentTable;
