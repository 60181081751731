import React from 'react';
import Navbar from '../../components/Website/navbar';
import PageHeader from '../../components/Website/page-header';
import VideoV2 from '../../components/Website/video';
import WhatWeDo from '../../components/Website/what-we-do';
import TestimonialV2 from '../../components/Website/testimonial';
import Team from '../../components/Website/team';
import Subscribe from '../../components/Website/subscribe';
import FooterBar from '../../components/Website/footerBar';

const About = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="About" />
        <VideoV2 customclass="pd-top-120 bg-none" />
        <WhatWeDo customclass="pd-top-80" />
        <Team />
        <TestimonialV2 />
        <Subscribe />
        <FooterBar />
    </div>
}

export default About

