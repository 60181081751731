import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getAxiosTokenInstance } from "../../../../../../utils/axiosInstance";
import  toast, { Toaster } from "react-hot-toast"
import TypableSelect from "../../../../../../myComp/TypableSelect/TypableSelect";
import moment from "moment";






  


const getAllSubjectsSearch = (selectedCollege, search,) => async () => {
    const instance = await getAxiosTokenInstance();
    let resp;
    let params = {
      search,
      collegeId: selectedCollege?.collegeId,
    };
    try {
      resp = await instance.get('/college/subject/getSubjectBySearch', {
        params,
      });
  
      if (resp?.data?.statusCode === 400) {
        throw new Error(resp.data.message || 'API Error');
      }
    } catch (error) {
      throw new Error(error?.response?.data?.message || error.message || 'API Error');
    }
    return resp?.data?.finalArray || [];
  }


function AddToken({  teachers,selectedCollege,classId,semester,onClose,val }) {

  console.log(val)

    const [search,setSearch] = useState("")
    const [inputValue,setInputValue] = useState("")
    const [tchrSearch,setTchrSearch] = useState(teachers)
    const [details,setDetails] = useState({subject:{name:val?.name,_id:val?.subId},teachers:val?.teachers || [""]})
    const [language,setLanguage] = useState([])

    useEffect(() => {
      const temp = teachers?.filter((teacher) => 
        val?.teachers?.some((valTeacher) => valTeacher?.name === teacher?.name)
      );
    
      for (let teacher of temp) {
        const workLoads = val?.teachers?.filter(x => x?.name === teacher?.name);
        console.log(workLoads,teacher)
        if (workLoads?.length) {
          teacher.workLoad = workLoads[0].workLoad;
        }

        setDetails({...details,teacher:temp})
      }
    
      console.log(temp);
    }, [teachers, val?.teachers]);
    
    


    const addSubjects = async (postData, query) => {
        const instance = await getAxiosTokenInstance();
        if(postData?.isElective){
             postData.electiveAdmittingDate=details.finalDate 
        }
        const { data } = await instance.post('/college/semester/updateSubject', postData, { params: { ...query } });
        onClose()
        return data;
      };

    const getDropdownData = async () => {
        const instance = await getAxiosTokenInstance();
        const { data } = await instance.get('/dropDown/dropdownList');
        if (data.statusCode !== 200) {
          throw new Error(data.message || 'API Error');
        }
        let val =  data?.dropdownList;
        setLanguage(val[11]?.[selectedCollege?.collegeId])
      }

    useEffect(()=>{
         getDropdownData()
    },[])

    const { data: filterSubjects } = useQuery(
        ['getAllSubjectsSearch', search],
        getAllSubjectsSearch(selectedCollege, search),
      )

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <button onClick={onClose} className="fixed right-3 top-3 text-white">X</button>
      <Toaster/>
      <div className="w-1/2 bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="p-4">
          {/* Subject Select */}
          <div className="mb-4 ">
            <TypableSelect keyString={'name'}  setInputValue={(val)=>{setDetails({...details,subject:val})}} inputValue={details?.subject?.name} customHandleChange={(val)=>{setSearch(val)}} options={filterSubjects}/>
          </div>

          {/* Practical Radio Buttons */}
          <div className="flex items-center mb-4">
            <label className="mr-2">Practical:</label>
            <label className="flex items-center mr-2">
              <input type="radio" name="practical" onChange={()=>{setDetails({...details,practical:true})}} className="mr-1" /> <span>Yes</span>
            </label>
            <label className="flex items-center">
              <input type="radio" name="practical" onChange={()=>{setDetails({...details,practical:false})}} className="mr-1" /> <span>No</span>
            </label>
          </div>

          {/* Elective Radio Buttons */}
          <div className="flex items-center mb-4">
            <label className="mr-2">Elective:</label>
            <label className="flex items-center mr-2">
              <input type="radio" name="elective" onChange={()=>{setDetails({...details,elective:true})}} className="mr-1" /> <span>Yes</span>
            </label>
            <label className="flex items-center">
              <input type="radio" name="elective" onChange={()=>{setDetails({...details,elective:false})}} className="mr-1" /> <span>No</span>
            </label>
          </div>
    {details?.elective && 
          <div className="flex items-center mb-4">
            <label htmlFor="">Language Base: </label>
            <TypableSelect inputValue={details?.language} setInputValue={(val)=>setDetails({...details,language:val})}  customHandleChange={(val)=> {}} options={language}/>
            <input type="Date" placeholder="Final Date" onChange={(e)=>setDetails({...details,finalDate:e.target.value})} className="w-1/4 p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none mr-2" />
            <button className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600">Delete</button>
          </div>
        }

          {/* Teacher Select and Input */}
          {details.teachers?.map((x,index)=>(

              <div className="flex items-center mb-4">
            <TypableSelect inputValue={details?.teachers[index]?.name} setInputValue={(val) =>{
                const tchr = details.teachers
                tchr[index] = val
                setDetails({...details,teachers:tchr})}} keyString={"name"} customHandleChange={(val)=> setTchrSearch(teachers.filter(option => option?.name?.toLowerCase().includes(val.toLowerCase())))} options={tchrSearch}/>
            <input type="Number" value={x?.workLoad} placeholder="Work Load" onChange={(e)=>{
                const tchr = details.teachers
                tchr[index] = {...tchr[index],workLoad:e.target.value}
                setDetails({...details,teachers:tchr})
              }
            } className="w-1/4 p-2 border border-gray-300 rounded-md focus:border-blue-500 focus:outline-none mr-2" />
            <button className="bg-red-500 text-white p-2 rounded-md hover:bg-red-600">Delete</button>
          </div>
              ))}

          {/* Add Teacher Button */}
          <button className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600" onClick={()=>{
            const tchr = details.teachers
            tchr.push({})
            setDetails({...details,teachers:tchr})
          }}>Add Teacher</button><br />
          <button className="bg-blue-500 text-white p-2 rounded-md mt-5 hover:bg-blue-600" onClick={()=>{
            const teacher = []
            for(let i in details?.teachers){
                teacher.push({teacherId:  details?.teachers[i]?._id,workLoad:details?.teachers[i]?.workLoad})
            }
            console.log({details,teacher})
            let query = {
                collegeId: selectedCollege.collegeId,
                classId,
                semester:semester-1,
                lessonPlanId: val?.id,
              };
              let postData = {
                isElective:details?.elective,
                isPractical:details?.practical,
                isLanguage:details?.elective,
                language: details?.language,
                subjectId: details?.subject?._id,
                teachers: teacher,
              };
              if((postData?.isElective===false ||( postData?.isElective===true && postData?.language)) && (postData?.isPractical===false || postData?.isPractical===true) &&postData?.subjectId&&postData?.teachers?.[0]){
                addSubjects(postData,query)
              }else{
                toast.error("Every Field is Required")
              }
            console.log(details)
          }}>Save</button>
        </div>
      </div>
    </div>
  );
}

export default AddToken;
