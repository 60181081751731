import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import SuperAdmin from "./pages/SuperAdmin";
import DashRoutes from "./DashRoutes";
import blogdata from "./data/blogdata.json";
import Singleblogdata from "./data/single-blogdata.json";
import Home from "./pages/Website/home";
import About from "./pages/Website/about";
import Services from "./pages/Website/services";
import Blog from "./components/Website/blog";
import BlogDetails from "./components/Website/blog-details";
import Contact from "./components/Website/contact";
import pricing from "./components/Website/pricing";
import JobApply from "./components/Website/job-apply";
import JobListing from "./pages/Website/job-listing";
import JobDetails from "./pages/Website/job-details";
import team from "./pages/Website/team";
import offer from "./pages/Website/offer";
import gallery from "./pages/Website/gallery";
import faq from "./pages/Website/faq";
import Error from "./pages/Website/error";
import CommingSoon from "./pages/Website/comming-soon";
import DownloadPage from "./pages/DownloadPage/download";
import Register from "./pages/Register/register";
import CollegeList from "./pages/CollegeList";
import AdmissionDetails from "./pages/Website/work-processing";
import Herberium from "./pages/Website/herbarium/herbarium";
import ScienceFest from "./pages/Website/scienceFest/scienceFest";
import RedirectToExternalUrl from "./pages/Website/Redirect";
import CollegeLogin from "./NecttosApp/CollegeLogin";
import Admission from "./CollegeApp/Form/StXaviersAluva";
import Onboarding from "./CollegeApp/Form/Onboarding";
import PaymentReturn from "./NecttosApp/PaymentReturn";
import Enquiry from "./CollegeApp/Form/Enquiry";
import NirmalaCollege from "./CollegeApp/Form/NirmalaCollege";
import StXaviersAluva from "./CollegeApp/Form/StXaviersAluva";
import ShCollegeChalakudy from "./CollegeApp/Form/ShCollegeChalakudy";
import BasicCollege from "./CollegeApp/Form/BasicCollege";
import LastIndexMark from "./CollegeApp/Admission/LastIndexMark";
import FYUGPMain from "./CollegeApp/Form/FYUGPMain";
import FYUGPM1 from "./CollegeApp/FYUGP/FYUGPM1";
import OnboardingDataLose from "./CollegeApp/Form/OnboardingDataLose";
import PortFolio from "./components/Website/portFolio";

// import io from "socket.io-client";
// import { NECTOTS_API_URL } from "./config";

// const socket = io(NECTOTS_API_URL);

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/services" component={Services} />
      <Route
        path="/blog"
        render={() => {
          return <Blog data={blogdata} />;
        }}
      />
      <Route
        path="/blog-details"
        render={() => {
          return <BlogDetails data={Singleblogdata} />;
        }}
      />
      <Route path="/contact" component={Contact} />
      <Route path="/ceo" component={PortFolio} />
      <Route path="/job-listing" component={JobListing} />
      <Route path="/job-details" component={JobDetails} />
      <Route path="/job-apply" component={JobApply} />
      <Route path="/team" component={team} />
      <Route path="/pricing" component={pricing} />
      <Route path="/offer" component={offer} />
      <Route path="/gallery" component={gallery} />
      <Route path="/herbarium/:collegeId" component={Herberium} />
      <Route path="/shashtrayan" component={ScienceFest} />
      <Route path="/faq" component={faq} />
      <Route path="/error" component={Error} />
      <Route path="/comming-soon" component={CommingSoon} />
      <Route path="/login/:id" component={CollegeLogin} />
      <Route path="/login" component={Login} />
      <Route
        path="/nirmala-college-admissions/:type"
        component={NirmalaCollege}
      />
      <Route
        path="/college-admissions/:collegeName/:type"
        component={BasicCollege}
      />
      <Route
        path="/st-xaviers-college-admissions/:type"
        component={StXaviersAluva}
      />
      <Route
        path="/sacred-heart-college-admissions/:type"
        component={ShCollegeChalakudy}
      />
      <Route path="/FYUGP" component={FYUGPMain} />
      <Route path="/FYUGPM1/:id/" component={FYUGPM1} />
      <Route path="/admission/:id/" component={AdmissionDetails} />
      <Route path="/lastIndexMark/:id/:year/:date" component={LastIndexMark} />
      <Route path="/register" component={Register} />
      <Route path="/download" component={DownloadPage} />
      <Route path="/superAdmin" component={SuperAdmin} />
      <Route path="/app" component={DashRoutes} />
      <Route path="/college-list" component={CollegeList} />
      <Route path="/refund-policy" component={RedirectToExternalUrl} />
      <Route path="/onboardin/:id/:role" component={Onboarding} />
      <Route path="/onboarding/:id/:role" component={Onboarding} />
      <Route path="/onboardingData/:id/:role" component={OnboardingDataLose} />
      <Route path="/privacy-policy" component={RedirectToExternalUrl} />
      <Route path="/terms-and-conditions" component={RedirectToExternalUrl} />
      <Route path="/contact-us" component={RedirectToExternalUrl} />
      <Route path="/paymentreturn" component={PaymentReturn} />
      <Route path="/enquiry/:id/:type" component={Enquiry} />
      <Route path="*" component={Login} />
    </Switch>
  );
}

export default Routes;
