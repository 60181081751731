import React, { useContext, useEffect, useRef, useState } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../../NecttosComp/Layout/Layout";
import Button from "../../NecttosComp/Button/Button";
import Input from "../../NecttosComp/Input/Input";
import { getAxiosTokenInstance } from "../../utils/axiosInstance";
import { useQuery } from "react-query";
import { FirebaseContext } from "../../context/FirebaseContext";
import { Table, Tbody, Td, Th, Thead, Tr } from "../../NecttosComp/Table/Table";
import { getAllAccademics } from "../../pages/college/ApiServices";
import moment from "moment";

const getFieldDropDowns = (data: any) => async () => {
  const instance = await getAxiosTokenInstance();
  try {
    const resp = await instance.get("/college/action/getFieldDropDowns", {
      params: { ...data },
    });
    if (resp.data.statusCode === 400) {
      throw new Error(resp.data.message || "API Error");
    } else {
      return resp?.data?.list;
    }
  } catch (error) {
    throw new Error("API Error");
  }
};

interface invoiceManagementProps {
  onClose: () => void;
}

function CordinatorShipAdmin({ onClose }: invoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>();
  const [showForm, setShowForm] = useState(false);
  const { collegeId } = useContext(FirebaseContext);
  const ref = useRef<any>(null);

  const { data: tableData = [], refetch } = useQuery("getAllAccadamicHolidays", getAllAccademics(collegeId));

  const handleDelete = async (_id: string) => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .delete("/college/action/deleteAccadamicHoliday/" + _id, { params: { collegeId } })
        .then(() => {
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      instance
        .post("/college/action/postAccadamicHolidays", { ...addNew, collegeId })
        .then(() => {
          setAddNew({});
          refetch();
        })
        .catch((err: any) => {
          console.error(err);
        });
      closeAnimation();
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error("API Error");
    }
  };
  return (
    <>
      <Main title="Co-ordinator Ship Admin" width="90vw" height="90vh">
        <Header>
          <Button
            type="update"
            onClick={() => {
              setShowForm(true);
            }}
          >
            New Holiday
          </Button>
          <div className="flex">
            <Button type="print" tableRef={ref.current}>
              Print
            </Button>
            <Button type="excel" tableRef={ref.current}>
              Excel
            </Button>
            <Button type="close" onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>
        <Body>
          <div ref={ref} className="h-[75vh] pt-2 printable overflow-auto">
            <Table width="100%" innerWidth={["5%", "15%", "35%", "15%", "10%", "10%"]}>
              <Thead>
                <Tr>
                  <Th position={0}>Sl.No.</Th>
                  <Th position={1}>Name of the Teacher</Th>
                  <Th position={2}>Previously</Th>
                  <Th position={3}>Priority</Th>
                  <Th position={6}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height="auto">
                {tableData?.map((x: any, i: number) => (
                  <Tr>
                    <Td index={i} position={0}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={1}>
                      {moment(x?.startDate).format("ddd-DD-mm-yyyy")}
                    </Td>
                    <Td index={i} position={2}>
                      {moment(x?.endDate).format("ddd-DD-mm-yyyy")}
                    </Td>
                    <Td index={i} position={3}>
                      {x?.tittle}
                    </Td>
                    <Td index={i} position={4}>
                      {x?.effected}
                    </Td>
                    <Td index={i} position={5}>
                      {x?.managedBy}
                    </Td>
                    <Td index={i} position={6}>
                      <div className="flex">
                        <Button
                          type="update"
                          onClick={() => {
                            setAddNew(x);
                            setShowForm(true);
                          }}
                        >
                          Edit
                        </Button>
                        <Button type="delete" onClick={() => handleDelete(x?._id)}>
                          Delete
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            
          </div>
        </Body>
      </Main>
      {showForm && (
        <Main width="40vw">
          <Header>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(() => setShowForm(false), 500);
              }}
            >
              Close
            </Button>
          </Header>
          <Body>
            <div className="flex flex-col w-full">
              <Input width="100%" fieldName="Holiday Tittle." returnKey="tittle" state={addNew} setState={setAddNew} type="text" />
              <Input width="100%" fieldName="Start Date" returnKey="startDate" state={addNew} setState={setAddNew} type="date" />
              <Input width="100%" fieldName="End Date" returnKey="endDate" state={addNew} setState={setAddNew} type="date" />
              <Input width="100%" fieldName="Who Is Effect" returnKey="effected" state={addNew} setState={setAddNew} options={["All", "First Year", "Second Year", "Third Year"]} type="drop" />
              <Button
                type="save"
                onClick={() => {
                  addNewForm();
                }}
              >
                Save
              </Button>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default CordinatorShipAdmin;
