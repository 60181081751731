import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { FirebaseContext } from "../../../../context/FirebaseContext";
import {
  AcademicYears,
  naacExcelGenerator,
  naacFetchData,
} from "../../ApiServices";

function C3_4_3({ val, onClose }) {
  const [values, setValues] = useState();
  const { collegeId } = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  const [academicYear, setAcademicYear] = useState("2022-23");

  useEffect(() => {
    naacFetchData({
      data: { collegeId, academicYear },
      setLoading,
      setValues,
      url: "/college/NAAC/getNAAC_C3_4_3",
    });
  }, []);
  return (
    <>
      <div className="  z-10 w-4/5 bg-slate-100 h-4/5 p-3">
        <table className="table">
          <thead>
            <th colSpan={5} style={{ background: "rgb(241 245 249)" }}>
              <h1 className="text-xl flex">
                3.4.3 Number of extension and outreach Programmes conducted by
                the institution through NSS/ NCC/Government and Government
                recognized bodies (including the programmes such as Swachh
                Bharat, AIDS awareness, Gender issues etc. ) and/or those
                organised in collaboration with industry, community and NGOs
                during the year & 3.4.4 Number of students participating in
                extension activities at 3.4.3. above during
                <div className="flex">
                  <AcademicYears setAcademicYear={setAcademicYear} />
                  <button
                    className="bg-green-500 text-sm"
                    onClick={() =>
                      naacExcelGenerator({
                        data: { collegeId, academicYear: "2022-23" },
                        url: "/college/NAAC/getNAAC_C2_7",
                        setLoading,
                      })
                    }
                  >
                    Dowload
                  </button>
                  <button
                    className="closeBtn text-lg ms-3 rounded-full h-[37px] w-[37px]"
                    onClick={onClose}
                  >
                    X
                  </button>
                </div>
              </h1>
            </th>
            <tr>
              <th className="w-1/4">Name of the activity</th>
              <th className="w-1/4">
                Organising unit/ agency/ collaborating agency{" "}
              </th>
              <th className="w-1/4">Name of the scheme</th>
              <th className="w-1/4">Year of the activity </th>
              <th className="w-1/4">
                Number of students participated in such activities
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
              <td>2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default C3_4_3;
