import React, { useContext } from "react";
import { Body, Footer, Header, Main, closeAnimation } from "../NecttosComp/Layout/Layout";
import Button from "../NecttosComp/Button/Button";
import { validateRole } from "../utils";
import { useProfileData } from "../queryHooks";
import { FirebaseContext } from "../context/FirebaseContext";
import Family from "../pages/college/Profile/Family/Family";
import Document from "../pages/college/Profile/Documents/Documents";
import PersonalInfo from "./Profile/PersonalInfo";
import OfficialInfo from "./Profile/OfficialInfo";
import AddressInfo from "./Profile/AddressInfo";
import Qualification from "./Profile/Qualification";
import { icons } from "./windows/Icons";
import { ProgressBar } from "../NecttosComp/ProgressBar/ProgressBar";
import Experience from "./Profile/Experiance";
import AddtionalInformation from "./Profile/AddtionalResponsibilities";
import Members from "./Profile/Members";
import Seminars from "./Profile/Seminars";
import BankDetails from "./Profile/BankDetails";
import CulturalActivies from "./Profile/CulturalActivies";
import Traveling from "./Profile/Traveling";
import Publications from "./Profile/Publications";
import BooksChapters from "./Profile/BooksChapters";
import Photo from "./Profile/Photo";
import FDP from "./Profile/FDP";
import Workshops from "./Profile/workshop";

interface ProfileProps {
  onClose: () => void;
  windows: any;
  setWindows: (val: any) => void;
}

export default function Profile({ onClose, windows, setWindows }: ProfileProps) {
  const { collegeId } = useContext(FirebaseContext);

  const { data } = useProfileData();

  const pages = [
    { classification: "Profile", keyData: "profileInformation", title: "Personal Info", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "officialInformation", title: "Official Info", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "addressDetails", title: "Address", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "profilePhoto", title: "Profile Photo", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "educationalQualification", title: "Qualifications", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "family", title: "Family Members", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["student"] },
    { classification: "Profile", keyData: "experienceAndServiceDetails", title: "Experience And Service Details", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "staff", "principal"] },
    { classification: "Profile", keyData: "additionResponsibilities", title: "Addition Responsibilities", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "membershipInAcademic", title: "Members in Academic and Professional Bodies", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "seminars", title: "Seminars, Symposia, Conferences Attended", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "workshops", title: "Workshops Attended", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "fdp", title: "FDPs Attended", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "bankInformations", title: "Bank Informations", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "curriculamActivies", title: "Co-curriculam Activies", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "travelingHistory", title: "Traveling History", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "student", "staff", "principal"] },
    { classification: "Profile", keyData: "publications", title: "Publications and Patents", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "booksChapters", title: "Books and Chapters", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["admin", "teacher", "principal"] },
    { classification: "Profile", keyData: "document", title: "Document", instituitionType: ["selfCollege", "aidedCollege", "govtCollege"], allowedUsers: ["student"] },
  ];

  return (
    <>
      <Main title={"Profile Management"} height={"auto"} width={"auto"}>
        <Header width="100%" height="6%" title="Profile">
          <div className=" w-[70%] mt-4 items-center">
            <ProgressBar progress={data?.percentageOfCompletion || 0} /> 
          </div>
          <div className="flex flex-wrap items-center">
            <Button type="print">Print</Button>
            <Button type="doc">Word</Button>
            <Button type="excel">Excel</Button>
            <Button type="pdf" pdfId="accounts">
              PDF
            </Button>
            <Button
              type="close"
              onClick={() => {
                closeAnimation();
                setTimeout(onClose, 250);
              }}
            >
              Close
            </Button>
          </div>
        </Header>
        <Body width="100%" height="90%">
          <div className={"components   grid-auto overflow-y-auto  h-[100%]"}>
            {pages?.map(
              (item, _i) =>
                validateRole(item.allowedUsers, data?.collegeRoles, collegeId) && (
                  <>
                    <div className={"roleCards m-[2vh]  float-left"} id={item?.keyData} draggable={true}>
                      <div
                        onClick={() => {
                          setWindows({ ...windows, [item?.keyData]: true });
                        }}
                        className={"icons"}
                      >
                        <div>
                          <img src={icons[item?.keyData] || ""} />
                        </div>
                      </div>
                      <h2 className="text-center mt-[.11vh]  text-black">{item?.title}</h2>
                    </div>
                  </>
                )
            )}
          </div>
        </Body>
        <Footer width="100%" />
      </Main>
      {windows?.profileInformation && (
        <PersonalInfo
          studentId={null}
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, profileInformation: false }), 500);
          }}
        />
      )}
      {windows?.addressDetails && (
        <AddressInfo
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, addressDetails: false }), 500);
          }}
        />
      )}
      {windows?.membershipInAcademic && (
        <Members
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, membershipInAcademic: false }), 500);
          }}
        />
      )}
      {windows?.experienceAndServiceDetails && (
        <Experience
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, experienceAndServiceDetails: false }), 500);
          }}
        />
      )}
      {windows?.additionResponsibilities && (
        <AddtionalInformation
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, additionResponsibilities: false }), 500);
          }}
        />
      )}
      {windows?.seminars && (
        <Seminars
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, seminars: false }), 500);
          }}
        />
      )}
      {windows?.workshops && (
        <Workshops
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, workshops: false }), 500);
          }}
        />
      )}
      {windows?.travelingHistory && (
        <Traveling
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, travelingHistory: false }), 500);
          }}
        />
      )}
      {windows?.educationalQualification && <Qualification onClose={() => setWindows({ ...windows, educationalQualification: false })} />}
      {windows?.publications && (
        <Publications
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, publications: false }), 500);
          }}
        />
      )}
      {windows?.booksChapters && (
        <BooksChapters
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, booksChapters: false }), 500);
          }}
        />
      )}
      {windows?.curriculamActivies && (
        <CulturalActivies
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, curriculamActivies: false }), 500);
          }}
        />
      )}
      {windows?.bankInformations && (
        <BankDetails
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, bankInformations: false }), 500);
          }}
        />
      )}
      ˛
      {windows?.family && (
        <div className="fixed inset-0 bg-[#00000045] flex justify-center windows-center">
          <Family onClose={() => setWindows({ ...windows, family: false })} />
        </div>
      )}
      {windows?.document && (
        <div className="fixed inset-0 bg-[#00000045] flex justify-center windows-center">
          <Document onClose={() => setWindows({ ...windows, document: false })} />
        </div>
      )}
      {windows?.officialInformation && (
        <OfficialInfo
          studentId={null}
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, officialInformation: false }), 500);
          }}
        />
      )}
      {windows?.fdp && (
        <FDP
          studentId={null}
          onClose={() => {
            closeAnimation();
            setTimeout(() => setWindows({ ...windows, officialInformation: false }), 500);
          }}
        />
      )}
      {windows?.profilePhoto && (
        <div className="fixed inset-0 bg-[#00000045] flex justify-center windows-center">
          <Photo studentId={null} onClose={() => setWindows({ ...windows, profilePhoto: false })} />
        </div>
      )}
    </>
  );
}
