import React, { useContext, useEffect, useRef, useState } from 'react';
import { Body, Header, Main, closeAnimation } from '../../NecttosComp/Layout/Layout';
import Button from '../../NecttosComp/Button/Button';
import Input from '../../NecttosComp/Input/Input';
import { getAxiosInstance, getAxiosTokenInstance } from '../../utils/axiosInstance';
import { useQuery } from 'react-query';
import { FirebaseContext } from '../../context/FirebaseContext';
import { Table, Tbody, Td, Th, Thead, Tr, useSortableData } from '../../NecttosComp/Table/Table';
import { getVerfificationDataLoader } from '../../pages/college/ApiServices';
import SelectField from '../../NecttosComp/SelectField';
import { useAllClasses } from '../../queryHooks';
import { Modal } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import { MdEdit } from 'react-icons/md';

interface InvoiceManagementProps {
  onClose: () => void;
}

interface ClassOption {
  value: string;
  label: string;
  _id: string;
}

function DataLoader({ onClose }: InvoiceManagementProps) {
  const [addNew, setAddNew] = useState<any>({});
  const [showForm, setShowForm] = useState(false);
  const [classId, setClassId] = useState<string | null>(null);
  const [classIdNew, setClassIdNew] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTcModalOpen, setIsTcModalOpen] = useState<boolean>(false);
  const [isIdModalOpen, setIsIdModalOpen] = useState<boolean>(false);
  const [firebaseData, setFirebaseData] = useState<any>(null);
  const [tcData, setTcData] = useState<any>(null);
  const { collegeId, user } = useContext(FirebaseContext);
  const [classDetails, setClassDetails] = useState<any>(null);
  const [mobileFetchError, setMobileFetchError] = useState<any>(false);

  const ref = useRef<any>(null);

  const { data: tableData = [], refetch } = useQuery(['getVerfificationDataLoader', collegeId, classId], getVerfificationDataLoader(collegeId, classId));

  const { sheetData, necttosData, matched }: any = tableData;

  const { data: allClasses } = useAllClasses({
    needAll: false,
    classOnly: true,
    subId: collegeId,
  });

  const addNewForm = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      await instance.post('/college/action/editStudentData', { ...addNew, userId: user.uid, classId: classIdNew, collegeId });
      setAddNew({});
      refetch();
      closeAnimation();
      setTimeout(() => setShowForm(false), 500);
    } catch (err) {
      throw new Error('API Error');
    }
  };

  const classOptions: ClassOption[] = allClasses?.map((classItem: { classId: string; className: string }) => ({
    value: classItem.classId,
    label: classItem.className,
    _id: classItem.classId,
  }));

  const handleNameChangeDrop = (selectedOption: any) => {
    setClassId(selectedOption?._id);
  };
  const handleNameChangeDrop2 = (selectedOption: any) => {
    setClassIdNew(selectedOption?._id);
  };
  const { tableItems: sheetItems, requestSort: sheetRequestSort, getIcon: sheetGetIcon } = useSortableData(sheetData);
  const { tableItems: necttosItems, requestSort: necttosRequestSort, getIcon: necttosGetIcon } = useSortableData(necttosData);

  const handleOk = () => {
    console.log('vhdhjhdbj');
  };

  const [idModalData, setIdModalData] = useState<any>({
    uid: '',
    phoneNumber: '',
    updateId: '',
  });
  const [modalFormData, setModalFormData] = useState<any>({
    uid: '',
    phoneNumber: '',
    name: '',
    dateOfAdmission: '',
  });
  const [modalTcFormData, setModalTcFormData] = useState<any>({
    uid: '',
    tcNumber: '',
    name: '',
    admisionNo: '',
    classId,
  });

  const handleTcValidate = async (e: any) => {
    try {
      const instance = await getAxiosInstance();
      const { data } = await instance.get('/college/action/getIdbyTc', { params: { collegeId, tcNumber: modalTcFormData.tcNumber } });
      setTcData(data?.fetchedUser);
      setModalTcFormData({ ...modalTcFormData, uid: data?.fetchedUser?.userId });
      console.log('mobileInput', data);
    } catch (error) {
      toast.error('tc not found');

      console.log(error);
      console.error('mobile fetch error');
    }
  };

  const handleMobileValidate = async (e: any) => {
    e.preventDefault();
    console.log({ modalFormData });

    try {
      const instance = await getAxiosInstance();
      const { data } = await instance.get('/college/action/getFirebaseByNumber', { params: { phoneNumber: modalFormData?.phoneNumber?.slice(-10) } });
      setFirebaseData(data.fetchedUser.uid);
      setModalFormData({ ...modalFormData, uid: data.fetchedUser.uid });
      setIdModalData({ ...idModalData, updateId: data.fetchedUser.uid, phoneNumber: data.fetchedUser.phoneNumber });
      console.log('mobileInput', data);
      setMobileFetchError(false);
    } catch (error) {
      setMobileFetchError(true);
      console.log(error);

      console.error('mobile fetch error');
    }
  };

  const updateProfileInLoader = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      const { data } = await instance.post('/college/profile/updateProfileInLoader', { ...modalFormData, collegeId });
    } catch (error) {
    } finally {
      onClose();
    }
  };

  const updateId = async () => {
    try {
      const instance = await getAxiosTokenInstance();
      const { data } = await instance.post('/college/profile/updateId', { ...idModalData, collegeId });
      onClose();
      setIdModalData({ ...idModalData, phoneNumber: '', updateId: '' });
      setFirebaseData(null)
    } catch (error) {
      console.error(error,'update id error');
      
    } finally {

    }
  };

  const updateProfileByTcLoader = async () => {
    try {
      if (!modalTcFormData.classId) {
        toast.error('Please select a class');
        return;
      }
      if (!modalTcFormData.name || !modalTcFormData.admisionNo) {
        toast.error('fill all fields');
        return;
      }
      const instance = await getAxiosTokenInstance();
      const { data } = await instance.post('/college/profile/updateProfileByTcLoader', { ...modalTcFormData, collegeId });

      toast.success(data.message);
      setModalTcFormData({
        uid: '',
        tcNumber: '',
        name: '',
        admisionNo: '',
        classId: '',
      });
      setTcData(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      // setIsLoading(false);
    }
  };

  const getClassesList = async (collegeId: any) => {
    try {
      const instance = await getAxiosTokenInstance();
      const resp = await instance.get('/college/classes/getClass', {
        params: { collegeId },
      });

      if (resp.data.statusCode === 400) {
        throw new Error(resp.data.message || 'API Error');
      }

      setClassDetails(resp.data);
    } catch (error: any) {
      console.error('Error fetching classes: ', error?.response?.data?.message || error.message || 'API Error');
      throw new Error(error?.response?.data?.message || error.message || 'API Error');
    }
  };
  useEffect(() => {
    if (collegeId) {
      getClassesList(collegeId);
    }
  }, [collegeId]);

  console.log({ classDetails }, { modalTcFormData });

  const handleChange = (e: any) => {
    setFirebaseData(null)
    const { name, value } = e.target;
    console.log({ modalFormData });

    setModalFormData({ ...modalFormData, [name]: value });
  };
  const handleTcChange = (e: any) => {
    const { name, value } = e.target;
    console.log({ modalTcFormData });

    setModalTcFormData({ ...modalTcFormData, [name]: value });
  };

  const handleEditId = (x: any) => {
    // setFirebaseData(null)
    setIdModalData({ ...idModalData, uid: x.uid });
    setIsIdModalOpen(true);
  };

  console.log({ idModalData });

  return (
    <>
      <Main title='Data Loader & Checker' width='100vw' height='100vh'>
        <Header>
          <div className='flex flex-row'>
            <div className='w-[500px] z-40'>
              <SelectField id='class' name='class' label='Select Class' options={classOptions} value={classOptions?.find((option) => option.value === classId) || null} onChange={handleNameChangeDrop} placeholder='Select a class' />
            </div>
            <div className='flex items-center justify-center ms-5 mt-2 '>
              <Button type='fetch' width='100%' onClick={() => setIsModalOpen(true)}>
                Add by Mobile Number
              </Button>
            </div>
            <div className='flex items-center justify-center ms-5 mt-2 '>
              <Button type='fetch' width='100%' onClick={() => setIsTcModalOpen(true)}>
                Add by TC Data
              </Button>
            </div>
          </div>
          <div className='flex'>
            <Button type='close' onClick={onClose}>
              Close
            </Button>
          </div>
        </Header>

        <div>
          <Modal width={'50%'} style={{ marginTop: '-5%', overflow: 'scroll' }} title={''} open={isModalOpen} onOk={handleOk} onCancel={() => setIsModalOpen(false)} okText='Update Data' footer={null}>
            <div className=''>
              <div className=' gap-4 p-4'>
                <div className='mb-4 '>
                  <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='mobileNumber'>
                    Mobile Number
                  </label>
                  <input id='mobileNumber' name='phoneNumber' value={modalFormData.phoneNumber} onChange={handleChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter mobile number' />
                </div>
                <div className='mb-4 flex justify-center text-lg gap-2'>
                  <button className='rounded bg-blue-300' onClick={handleMobileValidate}>
                    Validate
                  </button>
                  <button
                    className='rounded bg-blue-300'
                    onClick={(e: any) => {
                      setFirebaseData(null);
                      setModalFormData({ ...modalFormData, phoneNumber: '' });
                    }}>
                    reset
                  </button>
                </div>
                <div className='mb-4'>
                  <span className='block text-gray-700 text-sm font-bold mb-2'>{firebaseData ? `${firebaseData.slice(0, 7)}.... user Exist` : `No user with this number`}</span>
                </div>
                {firebaseData && (
                  <>
                    <div className='mb-4'>
                      <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='name'>
                        Name
                      </label>
                      <input id='name' name='name' value={modalFormData.name} onChange={handleChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter name' />
                    </div>
                    <div className='mb-4'>
                      <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='admisionNo'>
                        Admission Number
                      </label>
                      <input id='admisionNo' name='admisionNo' value={modalFormData.admisionNo} onChange={handleChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter admission number' />
                    </div>
                    <div className='mb-4 flex justify-center text-lg'>
                      <button className='rounded bg-blue-600 text-white' onClick={updateProfileInLoader}>
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
          <Modal width={'50%'} style={{ marginTop: '-5%', overflow: 'scroll' }} title={''} open={isTcModalOpen} onOk={handleOk} onCancel={() => setIsTcModalOpen(false)} okText='Update Data' footer={null}>
            <Toaster />

            <div className=''>
              <div className=' gap-4 p-4'>
                <div className='mb-4 '>
                  <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='tcNumber'>
                    TC Number
                  </label>
                  <input id='tcNumber' name='tcNumber' value={modalTcFormData.tcNumber} onChange={handleTcChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter TC Number' />
                </div>
                <div className='mb-4 flex justify-center text-lg'>
                  <button className='rounded bg-blue-600 text-white' onClick={handleTcValidate}>
                    Validate Tc
                  </button>
                </div>
                <div className='mb-4'>
                  <span className='block text-gray-700 text-sm font-bold mb-2'>{tcData && tcData?.userId ? `${tcData?.userId?.slice(0, 7)}*******${tcData?.userId?.slice(35)} User id found` : `No user with this number`}</span>
                </div>
                {tcData && (
                  <>
                    <div className='mb-4'>
                      <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='name'>
                        Name
                      </label>
                      <input id='name' name='name' value={modalTcFormData.name} onChange={handleTcChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter name' />
                    </div>
                    <div className='mb-2'>
                      <label htmlFor='classId' className='block text-sm font-medium text-gray-700 mb-1'>
                        Class:
                      </label>
                      <select id='classId' name='classId' value={modalTcFormData.classId} onChange={handleTcChange} className='mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
                        <option value=''>Select class</option>
                        {classDetails &&
                          classDetails?.list &&
                          classDetails?.list.map((cls: any) => (
                            <option key={cls._id} value={cls.classId}>
                              {cls.className}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='mb-4'>
                      <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='admisionNo'>
                        Admission Number
                      </label>
                      <input id='admisionNo' name='admisionNo' value={modalFormData.admisionNo} onChange={handleTcChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter admission number' />
                    </div>
                    <div className='mb-4 flex justify-center text-lg'>
                      <button className='rounded bg-blue-600 text-white' onClick={updateProfileByTcLoader}>
                        Update
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal>
          <Modal width={'50%'} style={{ marginTop: '-5%', overflow: 'scroll' }} title={''} open={isIdModalOpen} onOk={handleOk} onCancel={() => setIsIdModalOpen(false)} okText='Update Data' footer={null}>
            <div className=''>
              <div className=' gap-4 p-4'>
                <div className='mb-4 '>
                  <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='mobileNumber'>
                    Mobile Number
                  </label>
                  <input id='mobileNumber' name='phoneNumber' value={modalFormData.phoneNumber} onChange={handleChange} type='text' className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' placeholder='Enter mobile number' />
                </div>
                <div className='mb-4 flex justify-center text-lg gap-2'>
                  <button className='rounded bg-blue-300' onClick={handleMobileValidate}>
                    Validate
                  </button>
                  <button
                    className='rounded bg-blue-300'
                    onClick={(e: any) => {
                      setFirebaseData(null);
                      setModalFormData({ ...modalFormData, phoneNumber: '' });
                      setIdModalData({ ...idModalData, phoneNumber: '', updateId: '' });
                    }}>
                    reset
                  </button>
                </div>
              <div className='flex justify-center flex-column items-center'>
                <div className='mb-4'>
                  <span className='block text-gray-700 text-sm font-bold mb-2'>{firebaseData ? `${firebaseData.slice(0, 7)} * * * * * ${firebaseData.slice(35)} User id found` : `No user with this number`}</span>
                </div>
              {idModalData.updateId && firebaseData && (
                  <button onClick={updateId} className='rounded bg-red-400 w-[40%] h-10 text-lg text-white font-bold border-0 shadow'>
                    UPDATE
                  </button>
                )}
              </div>
              </div>
            </div>
          </Modal>
        </div>

        <div className='flex col z-20'>
          <div ref={ref} className='p-1 w-2/3 pt-2'>
            <Thead>
              <Tr>
                <Th position={0}>Sn</Th>
                <Th onClick={() => necttosRequestSort('admisionNo')} icon={necttosGetIcon('admisionNo')} position={2}>
                  Admn No
                </Th>
                <Th onClick={() => necttosRequestSort('name')} icon={necttosGetIcon('name')} position={1}>
                  Name
                </Th>
                <Th position={2}>DOB</Th>
                <Th position={2}>Mob</Th>
                <Th position={0}>Action</Th>
              </Tr>
            </Thead>
          </div>
          <div ref={ref} className='p-1 w-1/3 pt-2'>
            <Thead>
              <Tr>
                <Th position={0}>Sn</Th>
                <Th onClick={() => sheetRequestSort('admisionNo')} icon={sheetGetIcon('admisionNo')} position={2}>
                  Admn No
                </Th>
                <Th onClick={() => sheetRequestSort('name')} icon={sheetGetIcon('name')} position={1}>
                  Name
                </Th>
                <Th position={2}>DOB</Th>
              </Tr>
            </Thead>
          </div>
        </div>
        <Body>
          <div className='flex col z-10 mt-[50px]'>
            <div ref={ref} className='h-[75vh] p-1 w-2/3 pt-2 printable overflow-auto'>
              <Table width='100%' innerWidth={['5%', '35%', '15%']}>
                <Tbody height='auto'>
                  {necttosItems?.map((x: any, i: number) => (
                    <Tr height='60px' key={i}>
                      <Td index={i} position={0}>
                        {i + 1}
                      </Td>
                      <Td index={i} position={2}>
                        {x?.admisionNo}
                      </Td>
                      <Td index={i} position={1}>
                        {x?.name}
                      </Td>
                      <Td index={i} position={2}>
                        {x?.dob}
                      </Td>
                      <Td index={i} position={2}>
                        {x?.phoneNumber?.slice(-10)}
                      </Td>
                      <Td index={i} position={0}>
                        <div className='flex'>
                          <Button
                            type='save'
                            onClick={() => {
                              setAddNew(x);
                              setShowForm(true);
                              setClassIdNew(classId);
                            }}>
                            Edit
                          </Button>
                          {/* {true && (
                            <button onClick={() => handleEditId(x)} className='border-0 px-1'>
                              <MdEdit />
                            </button>
                          )} */}
                          {/* {x?.phoneNumber==='+91345' && <button onClick={()=>handleEditId(x)} className='border-0 px-1'><MdEdit/></button>} */}

                        </div>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
            <div ref={ref} className='h-[75vh] p-1 w-1/3 pt-2 printable overflow-auto'>
              <Table width='100%' innerWidth={['5%', '35%', '15%']}>
                <Tbody height='auto'>
                  {sheetItems?.map((x: any, i: number) => (
                    <Tr height='60px' key={i}>
                      <Td index={i} position={0}>
                        {i + 1}
                      </Td>
                      <Td index={i} position={2}>
                        {x?.admisionNo}
                      </Td>
                      <Td index={i} position={1}>
                        {x?.name}
                      </Td>
                      <Td index={i} position={2}>
                        {x?.dob}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
          <div className='flex col z-10 mt-[50px]'>
            <Table width='100%' innerWidth={['5%', '35%', '15%']}>
              <Thead>
                <Tr>
                  <Th position={0}>Sn</Th>
                  <Th onClick={() => necttosRequestSort('admisionNo')} icon={necttosGetIcon('admisionNo')} position={2}>
                    Admn No
                  </Th>
                  <Th onClick={() => necttosRequestSort('name')} icon={necttosGetIcon('name')} position={1}>
                    Name
                  </Th>
                  <Th position={2}>DOB</Th>
                  <Th position={2}>Mob</Th>
                  <Th position={0}>Action</Th>
                </Tr>
              </Thead>
              <Tbody height='auto'>
                {matched?.map((x: any, i: number) => (
                  <Tr height='60px' key={i}>
                    <Td index={i} position={0}>
                      {i + 1}
                    </Td>
                    <Td index={i} position={2}>
                      {x?.admisionNo}
                    </Td>
                    <Td index={i} position={1}>
                      {x?.name}
                    </Td>
                    <Td index={i} position={2}>
                      {x?.dob}
                    </Td>
                    <Td index={i} position={2}>
                      {x?.phoneNumber}
                      {x?.phoneNumber.slice(0,9)==='+91100200' && <button onClick={()=>handleEditId(x)} className='border-0 px-1'><MdEdit/></button>}
                      {/* {true && <button onClick={()=>handleEditId(x)} className='border-0 px-1'><MdEdit/></button>} */}
                    </Td>
                    <Td index={i} position={0}>
                      <div className='flex'>
                        <Button
                          type='save'
                          onClick={() => {
                            setAddNew(x);
                            setShowForm(true);
                            setClassIdNew(classId);
                          }}>
                          Edit
                        </Button>
                      </div>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </Body>
      </Main>

      {showForm && (
        <Main width='40vw'>
          <Header>
            <Button
              type='close'
              onClick={() => {
                closeAnimation();
                setTimeout(() => setShowForm(false), 500);
              }}>
              Close
            </Button>
          </Header>
          <Body>
            <div className='flex flex-col w-full'>
              <Input width='100%' fieldName='Name' returnKey='name' state={addNew} setState={setAddNew} type='text' />
              <Input width='100%' fieldName='Admission No' returnKey='admisionNo' state={addNew} setState={setAddNew} type='text' />
              <SelectField id='claswws' name='class' label='Select Class' options={classOptions} value={classOptions?.find((option) => option.value === classIdNew) || null} onChange={handleNameChangeDrop2} placeholder='Select a class' />
              <Button type='save' onClick={addNewForm}>
                Save
              </Button>
            </div>
          </Body>
        </Main>
      )}
    </>
  );
}

export default DataLoader;
